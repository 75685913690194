import styled from "styled-components";

export const Container = styled.div`
  background-color: #fff;
  border-radius: 8px;
  padding: 28px;

  min-width: 660px;

  @media screen and (max-width: 768px) {
    min-width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 100px;
`;

export const Title = styled.h2`
  color: #333333;
  font-size: 20px;
  font-weight: 600;
`;

export const Description = styled.h3`
  color: #333333;
  font-size: 14px;
  font-weight: 400;
`;

export const ButtonContainer = styled.button`
  background: none;
  border: none;
`;

export const Input = styled.input`
  width: 100%;
  padding: 10px 20px;
  margin: 24px 0;

  font-size: 16px;
  font-weight: 600;
  color: #333333;

  &::placeholder {
    color: #999999;
  }

  border-radius: 8px;
  border: none;
  background-color: #f3f6f9;

  outline: 0;
`;

export const Footer = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  gap: 42px;

  a {
    text-decoration: none;
  }
`;

export const Label = styled.span`
  font-size: 14px;
  font-weight: 400;
  color: #ff3333;
  text-decoration: underline;

  cursor: pointer;
`;

export const Buttons = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

export const ButtonsFooter = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  text-align: right;
`;

export const CancelButton = styled.button`
  border: none;
  border-radius: 8px;
  padding: 8px 20px;

  background-color: #ff3333;
`;

export const ButtonLabel = styled.span`
  font-size: 16px;
  font-weight: 600;
  color: white;
`;

export const ConfirmButton = styled(CancelButton)`
  background-color: #00b333;
`;

export const QRCodeContainer = styled.div`
  width: 100%;
  margin: 22px 0;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CloseIcon = styled.i.attrs({
  className: "fas fa-times",
  color: "#333333",
})``;

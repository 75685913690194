import styled from "styled-components";

export const SaveProductContainer = styled.div`
  display: flex;
  justify-content: end;
  button {
    display: flex;
    flex-direction: row;
    width: 155px;
    height: 41px;
    padding: 0;
    justify-content: center;
    align-items: center;
  }
  label {
    color: #ffffff;
    font-weight: 600;
    margin: 0;
    font-size: 14px;
  }

  span {
    margin: 0 18px 0 0;
  }
`;

import { merchantService } from "services";

export const useFetchCategories = () => {
  const formatCategory = (category) => ({
    label: category.nome,
    value: category.id_grupo,
  });

  const fetchCategories = async () => {
    const response = await merchantService.listCategories();

    return response.obj?.map(formatCategory);
  };

  return { fetchCategories };
};

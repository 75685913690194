import React from 'react';

function ModalCadastro() {
  return (
    <div className="modal fade" id="ModalCadastro" tabIndex={-1} role="dialog" aria-hidden="true">
        <div className="modal-dialog" role="document">
        <div className="modal-content">
        <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">Cadastrar Estabelecimento</h5>
        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
            <i aria-hidden="true" className="ki ki-close" />
        </button>
        </div>
        <div className="modal-body">
        <p className="font-size-h6">Preencha os dados abaixo para que possamos entrar em contato e realizar o cadastro do seu estabelecimento no Aiboo.</p>
        <div className="form-group mt-10">
            <label className="font-size-h6 font-weight-bolder text-dark">Seu Nome</label>
            <input className="form-control form-control-solid h-auto py-5 px-6 rounded-lg" type="text" name="username" autoComplete="off" />
        </div>
        <div className="form-group mt-10">
            <label className="font-size-h6 font-weight-bolder text-dark">Nome do Estabelecimento</label>
            <input className="form-control form-control-solid h-auto py-5 px-6 rounded-lg" type="text" name="username" autoComplete="off" />
        </div>
        <div className="form-group mt-10">
            <label className="font-size-h6 font-weight-bolder text-dark">Celular para Contato</label>
            <input className="form-control form-control-solid h-auto py-5 px-6 rounded-lg" type="text" name="username" autoComplete="off" />
        </div>
        <div className="form-group mt-10">
            <label className="font-size-h6 font-weight-bolder text-dark">Email</label>
            <input className="form-control form-control-solid h-auto py-5 px-6 rounded-lg" type="text" name="username" autoComplete="off" />
        </div>
        </div>
        <div className="modal-footer">
        <button type="button" className="btn btn-light-primary font-weight-bold" data-dismiss="modal">Cancelar</button>
        <button type="button" className="btn btn-primary font-weight-bold">Solicitar cadastro</button>
        </div>
        </div>
        </div>
    </div>
  );
}

export default ModalCadastro;
import React from 'react';
import UserIcon from './UserIcon';

const name = localStorage.getItem('@ADM-Aiboo/nome') ?? '';
const level = localStorage.getItem('@ADM-Aiboo/nivel') ?? '';


export function UserDetail() {
  return (
    <div className="topbar">
        <div className="topbar-item mr-4">
            <div className="btn btn-sm btn-clean btn-text-dark-75">
                <span className="svg-icon svg-icon-lg">
                <UserIcon />
                {name} 
                </span>
            </div>
            <span className={`label label-lg font-weight-boldest label-rounded ${level == "A" ? "label-primary" : "label-warning"}`}>{level}</span>
            </div>
    </div>
  );
}
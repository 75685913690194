import styled from "styled-components";

export const FormWrapper = styled.form`
  gap: 25px;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: fit-content;
  margin-bottom: 10px;
`;

export const MonetaryInputsWrapper = styled.div`
  gap: 25px;
  display: flex;
  flex-direction: row;
`;

export const LocationInputsWrapper = styled.div`
  gap: 25px;
  display: flex;
  flex-direction: row;
  flex: 1;

  label {
    font-weight: 600;
    font-style: normal;
    font-size: ${({ labelSize }) => labelSize}px;
    margin: 0 0 3px;

    line-height: 22px;

    display: flex;
    flex: 1;
    align-items: center;
    text-align: right;

    color: #333333;

    display: flex;
    gap: 4px;
  }
  span {
    color: #ff3333;
  }
`;

export const SelectWrapper = styled.div`
  flex: 1;
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SubmitButtonWrapper = styled.div`
  display: flex;
  align-items: end;
  flex: 1;
  max-width: 120px;
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    width: 100%;
    height: 44px;
    border: none;
    border-radius: 12px;
    color: #ffffff;

    i {
      color: #ffffff;
    }

    p {
      margin: 0;
    }
  }
`;

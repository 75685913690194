import axios from "axios";
import api from "../api";
import apiV2 from "../api-laravelv2";

const listByIdOrName = async ({ param }) => {
  const body = new FormData();

  body.append("filtro", param);

  const response = await axios.post("/estabelecimentos/listar", body, {
    baseURL: api.defaults.baseURL,
    headers: {
      "content-type": "multipart/form-data",
      Authorization: api.defaults.headers["Authorization"],
    },
  });

  return response.data;
};

const getZipCodeData = async (zipCode) => {
  const response = await api.get(`estabelecimentos/consultaCep/${zipCode}`);

  return response.data;
};

const listCuisines = async () => {
  const response = await api.get("estabelecimentos/listaTipoLoja");

  return response.data;
};

const listCategories = async () => {
  const response = await api.get("estabelecimentos/listacategoria");

  return response.data;
};

const listAgents = async () => {
  const agent =
    localStorage.getItem("@ADM-Aiboo/nivel") == "R"
      ? localStorage.getItem("@ADM-Aiboo/id_representante")
      : "";

  const response = await api.post("estabelecimentos/listarepresentante", {
    representante: agent,
  });

  return response.data;
};

const updateMerchantConfig = async ({ merchantId, config }) => {
  const response = await apiV2.put(`merchants/config/${merchantId}`, config);

  return response.data;
};

const getMerchantById = async (id) => {
  const response = await apiV2.get(`merchants/${id}`);

  return response.data;
};

const createMerchant = async (data) => {
  const response = await apiV2.post("merchants", data);

  return response.data;
};

const updateMerchant = async ({ merchantId, data }) => {
  const response = await apiV2.patch(`merchants/${merchantId}`, data);

  return response.data;
};

export const merchantService = {
  listByIdOrName,
  getZipCodeData,
  listCuisines,
  listCategories,
  listAgents,
  updateMerchantConfig,
  createMerchant,
  updateMerchant,
  getMerchantById,
};

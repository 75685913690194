import React, { useState, useEffect } from 'react';
import Api from '../../../services/api';
import { withRouter } from "react-router";
import LoadingOverlay from 'react-loading-overlay';
import { Doughnut, Line, Bar } from 'react-chartjs-2';
import Select from 'react-select';
import Swal from "sweetalert2";

import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import pt from "date-fns/locale/pt-BR"; 
import { Link } from '@material-ui/core';
import { Modal } from 'react-bootstrap';
registerLocale("pt-BR", pt);

  const Painel = () => {
  //Filtro
  var d = new Date();
  d.setDate(d.getDate() - 60);
  const [ cidadeSelect, setCidadeSelect ] = useState([]);
  const [ cidade, setCidade ] = useState('53');
  //Painel Total de pedidos / mês atual 
  const [ pedidoMes , setPedidoMes ] = useState([]);
  const [ caPedidoMes, setCaPedidoMes ] = useState(false);
  //Painel Ticket Médio / Mês atual
  const [ ticketMedio , setTicketMedio ] = useState([]);
  const [ caTicketMedio, setCaTicketMedio ] = useState(false);
  //Painel Comissão 
  const [ comissao , setComissao ] = useState([]);
  const [ caComissao, setCaComissao ] = useState(false);
  //Painel Transação Projetada 
  const [ transacaoProjetada , setTransacaoProjetada ] = useState([]);
  const [ caTransacaoProjetada, setCaTransacaoProjetada ] = useState(false);
  //Painel Total de Cupons Usados 
  const [ cuponsUsados , setCuponsUsados ] = useState([]);
  const [ caCuponsUsados, setCaCuponsUsados ] = useState(false);
  //Painel Pedidos - Ultimos 30 dias
  const [ pedidosUltimos30 , setPedidosUltimos30 ] = useState([]);
  const [ pedidosUltimos30Dias , setPedidosUltimos30Dias ] = useState([]);
  const [ caPedidosUltimos30, setCaPedidosUltimos30 ] = useState(false);
  //Painel Valores - Ultimos 30 dias
  const [ valoresUltimos30 , setValoresUltimos30 ] = useState([]);
  const [ valoresUltimos30Dias , setValoresUltimos30Dias ] = useState([]);
  const [ , setCaValoresUltimos30 ] = useState(false);
  //Painel Cupons - Ultimos 30 dias
  const [ cuponsUltimos30 , setCuponsUltimos30 ] = useState([]);
  const [ cuponsUltimos30Dias , setCuponsUltimos30Dias ] = useState([]);
  const [ , setCaCuponsUltimos30 ] = useState(false);
  //Painel Transacionado Mes
  const [ transacionadoMes, setTransacionadoMes ] = useState([]);
  const [ , setCaTransacionadoMes ] = useState(false);
  const [ transacionadoMesValor , setTransacionadoMesValor ] = useState([]);
  //Painel Bairros com mais pedidos
  const [ , setMelhoresBairros ] = useState([]);
  const [ , setCaMelhoresBairros ] = useState(false);
  const [ bairroLabel , setBairroLabel ] = useState([]);
  const [ bairroValor , setBairroValor ] = useState([]);
  //Painel Usuários Cadastrados
  const [ usuariosCadastrados , setUsuariosCadastrados ] = useState([]);
  const [ caUsuariosCadastrados, setCaUsuariosCadastrados ] = useState(false);
  //Painel Usuários Sem Compras
  const [ usuariosSemCompras , setUsuariosSemCompras ] = useState([]);
  const [ caUsuariosSemCompras, setCaUsuariosSemCompras ] = useState(false);
  //Painel Usuários Compra Unica
  const [ usuariosCompraUnica , setUsuariosCompraUnica ] = useState([]);
  const [ caUsuariosCompraUnica, setCaUsuariosCompraUnica ] = useState(false);
  //Painel Usuários Pedido Unica
  const [ usuariosPedidosMaisQ1 , setUsuariosPedidosMaisQ1 ] = useState([]);
  const [ caUsuariosPedidosMaisQ1, setCaUsuariosPedidosMaisQ1 ] = useState(false);
  //Painel Usuários em Risco
  const [ usuariosEmRisco , setUsuariosEmRisco ] = useState([]);
  const [ caUsuariosEmRisco, setCaUsuariosEmRisco ] = useState(false);
  //Painel Usuários 60 Dias +
  const [ usuarios60dias , setUsuarios60dias ] = useState([]);
  const [ caUsuarios60dias, setCaUsuarios60dias ] = useState(false);
  //Painel Crescimento Usuários Cidade
  const [ usuariosMes, setUsuariosMes ] = useState([]);
  const [ caCrescimentoUsuarios, setCaCrescimentoUsuarios ] = useState(false);
  const [ usuariosMesValor , setUsuariosMesValor ] = useState([]);
  //Painel Evolução Ticket Médio
  const [ ticketEvolucaoMedioMes, setEvolucaoTicketMedioMes ] = useState([]);
  const [ caEvolucaoTicketMedio, setCaEvolucaoTicketMedio ] = useState(false);
  const [ ticketEvolucaoMedioMesValor , setEvolucaoTicketMedioMesValor ] = useState([]);
  //Painel Estabelecimentos Cadastrados
  const [ estabelecimentosCadastrados , setEstabelecimentosCadastrados ] = useState([]);
  const [ caEstabelecimentosCadastrados, setCaEstabelecimentosCadastrados ] = useState(false);
  //Painel Estabelecimentos Ativos
  const [ estabelecimentosAtivos , setEstabelecimentosAtivos ] = useState([]);
  const [ caEstabelecimentosAtivos, setCaEstabelecimentosAtivos ] = useState(false);
  //Painel Frequência Pedidos
  const [ frequenciaPedidoMes, setFrequenciaPedidoMes ] = useState([]);
  const [ , setCaFrequenciaPedido ] = useState(false);
  const [ frequenciaPedidoValor , setFrequenciaPedidoValor ] = useState([]);
  //Painel Cancelados
  const [ cancelamento, setCancelamento ] = useState([]);
  const [ caCancelamento, setCaCancelamento ] = useState(false);
  const [ modalCancelamento, setModalCancelamento ] = useState(false);
  //Painel Sem Movimentação  
  const [ estSemCompras, setEstSemCompras ] = useState([]);
  const [ casemMovimentacao, setCaSemMovimentacao ] = useState(false);
  const [ modal, setModal ] = useState(false);

  useEffect(() => {
    getCidadeSelect();
  }, []);

  function getCidadeSelect() {
    var data = {
      representante: localStorage.getItem('@ADM-Aiboo/nivel') == 'R' ? localStorage.getItem('@ADM-Aiboo/id_representante') : ''
    }

    Api.post("cidades/listarcidaderepresentante", data).then(rps => {
      var cid = rps.data.cidades;
      var nCid = [];
      nCid[nCid.length] = { value: '', label: 'Selecione...' };
      cid.forEach(e => {
        nCid[nCid.length] = { value: e.id_cidade, label: e.nome_cidade + ' - ' + e.estado };
      })
      setCidadeSelect(nCid);
    })
  }

  function getSemCompras(){
    setCaSemMovimentacao(true);
    
    var data = {
      representante: localStorage.getItem('@ADM-Aiboo/nivel') == 'R' ? localStorage.getItem('@ADM-Aiboo/id_representante') : ''
    }

    Api.post("painelcidade/semCompras", data).then(rps => {
      setEstSemCompras(rps.data.obj);
     
      setCaSemMovimentacao(false);
    })
  }

  function getCancelamento(){
    setCaCancelamento(true);
    
    var data = {
      representante: localStorage.getItem('@ADM-Aiboo/nivel') == 'R' ? localStorage.getItem('@ADM-Aiboo/id_representante') : ''
    }

    Api.post("painelcidade/cancelamento", data).then(rps => {
      setCancelamento(rps.data.obj);
     
      setCaCancelamento(false);
    })
  }

  function fecharModal(){
    setModal(false);
  }
  
  function getPedidoMes(){
    setCaPedidoMes(true); 

    var data = {
      cidade: cidade.value,
    };

    Api.post("painelcidade/totalpedidos", data).then(rps => {
      setPedidoMes(rps.data.obj.qtde);
     
      setCaPedidoMes(false);
    })
  }

  function getTicketMedio(){
    setCaTicketMedio(true); 

    var data = {
      cidade: cidade.value,
    };

    Api.post("painelcidade/ticketmedio", data).then(rps => {
      setTicketMedio(rps.data.obj.qtde);
     
      setCaTicketMedio(false);
    })
  }

  function verTodosSemMovimentacao() {
    setModal(true);
  }

  function getComissao(){
    setCaComissao(true); 

    var data = {
      cidade: cidade.value,
    };

    Api.post("painelcidade/comissao", data).then(rps => {
      setComissao(rps.data.obj.valor);
     
      setCaComissao(false);
    })
  }

  function redirecionaEst($id) {  
    sessionStorage.setItem('estabelecimento', $id);
    sessionStorage.setItem('redireciona', 'painel');
  }

  function getTransacaoProjetada(){
    setCaTransacaoProjetada(true); 

    var data = {
      cidade: cidade.value,
    };

    Api.post("painelcidade/transacaoprojetada", data).then(rps => {
      setTransacaoProjetada(rps.data.obj.valor);
     
      setCaTransacaoProjetada(false);
    })
  }

  function getCuponsUsados(){
    setCaCuponsUsados(true); 

    var data = {
      cidade: cidade.value,
    };

    Api.post("painelcidade/cuponsusados", data).then(rps => {
      setCuponsUsados(rps.data.obj.qtde);
     
      setCaCuponsUsados(false);
    })
  }

    function filtrar() {
    //setCaProcessa(true); 
      var data = {
        cidade: cidade.value,
        representante: localStorage.getItem('@ADM-Aiboo/nivel') == 'R' ? localStorage.getItem('@ADM-Aiboo/id_representante') : '' 
      };
   
      Api.post('relatorios/pesquisar', data).then((rps) => {

        if(rps.data.status==false){
          Swal.fire({
            title: "Atenção!",
            icon: "info",
            html: rps.data.erro,
            showCloseButton: true,
            showCancelButton: false,
          });
        } else {
          getSemCompras();
          getCancelamento()
          getPedidoMes();
          getTicketMedio();
          getComissao();
          getTransacaoProjetada();
          getCuponsUsados();
          getPedidosUltimos30();
          getValoresUltimos30();
          getCuponsUltimos30();
          getTransacionadoMes();
          getMelhoresBairros();
          getUsuariosCadastrados();
          getUsuariosSemCompras();
          getUsuariosCompraUnica();
          getUsuariosPedidosMaisQ1();
          getUsuariosEmRisco();
          getUsuarios60dias();
          getCrescimentoUsuarios();
          getEvolucaoTicketMedio();
          getEstabelecimentosCadastrados();
          getEstabelecimentosAtivos();
          getFrequenciaPedido();
        }
      //setCaProcessa(false); 
    });
  }

  function getPedidosUltimos30(){
    setCaPedidosUltimos30(true); 

    var data = {
      cidade: cidade.value,
    };

    Api.post("painelcidade/pedidosultimos30d", data).then(rps => {
  
      geraPedidosUltimos30(rps.data.obj);
     
      setCaPedidosUltimos30(false);
    })
  }
  
  function geraPedidosUltimos30(dados){
    //***Inicio do Grafico Ultimos 30 dias
  
    var formataQtde = [];
    var formataMes = [];
  
    dados.map( item => { 
        formataQtde.push(item.total);
        formataMes.push(item.data);
      
      return null
    })
   
    setPedidosUltimos30(formataQtde);
    setPedidosUltimos30Dias(formataMes);
    //Fim***
  }
  
  const dataPedidoUltimos = {
  labels: pedidosUltimos30Dias,
  datasets: [
    {
      data: pedidosUltimos30,
      fill: true,
      backgroundColor: "#ffec5b",
      borderColor: "#f4c430"
    },
  ]
  };
  
  const legenda = {
  display: false,
  };
  
  const options = {
  scales: {
    yAxes: [
      {
        ticks: {
          suggestedMin: 0,
          suggestedMax: 100
        }
      }
    ]
  }
  };

  function getValoresUltimos30(){
    setCaPedidosUltimos30(true); 

    var data = {
      cidade: cidade.value,
    };

    Api.post("painelcidade/valoresultimos30d", data).then(rps => {
  
      geraValoresUltimos30(rps.data.obj);
     
      setCaValoresUltimos30(false);
    })
  }
  
  function geraValoresUltimos30(dados){
    //***Inicio do Grafico Ultimos 30 dias
  
    var formataQtde = [];
    var formataMes = [];
  
    dados.map( item => { 
        formataQtde.push(item.count);
        formataMes.push(item.data);
      
      return null
    })
   
    setValoresUltimos30(formataQtde);
    setValoresUltimos30Dias(formataMes);
    //Fim***
  }

  const dataUltimos30 = {
    labels: valoresUltimos30Dias,
    datasets: [
      {
        data: valoresUltimos30,
        fill: true,
        backgroundColor: "#acd1fc",
        borderColor: "#153cb2"
      },
    ]
    };

  function getCuponsUltimos30(){
    setCaCuponsUltimos30(true); 

    var data = {
      cidade: cidade.value,
    };

    Api.post("painelcidade/cuponsultimos30d", data).then(rps => {
  
      geraCuponsUltimos30(rps.data.obj);
      
      setCaCuponsUltimos30(false);
    })
  }
    
    function geraCuponsUltimos30(dados){
      //***Inicio do Grafico Ultimos 30 dias
    
      var formataQtde = [];
      var formataMes = [];
    
      dados.map( item => { 
          formataQtde.push(item.cupons);
          formataMes.push(item.data);
        
        return null
      })
     
      setCuponsUltimos30(formataQtde);
      setCuponsUltimos30Dias(formataMes);
      //Fim***
    }

  const dataCuponsUltimos = {
    labels: cuponsUltimos30Dias,
    datasets: [
      {
        data: cuponsUltimos30,
        fill: true,
        backgroundColor: "#e9d8e6",
        borderColor: "#8f4487"
      },
    ]
    };

  function getTransacionadoMes(){
    setCaTransacionadoMes(true); 

    var data = {
      cidade: cidade.value,
    };

    Api.post("painelcidade/transacionadomes", data).then(rps => {
  
      geraTransacionadoMes(rps.data.obj);
      
      setCaTransacionadoMes(false);
    })
  }
      
  function geraTransacionadoMes(dados){
    //***Inicio do Grafico Transacionado Mes
  
    var formataQtde = [];
    var formataMes = [];
  
    dados.map( item => { 
        formataQtde.push(item.total);
        formataMes.push(item.mes);
      
      return null
    })
    
    setTransacionadoMesValor(formataQtde);
    setTransacionadoMes(formataMes);
    //Fim***
  }
    
  const dataTransacionado = {
    labels: transacionadoMes,
    datasets: [
      {
        data: transacionadoMesValor,
        fill: true,
        backgroundColor: "#acd1fc",
        borderColor: "#153cb2"
      },
    ]
  };

  function getMelhoresBairros(){
    setCaMelhoresBairros(true); 

    var data = {
      cidade: cidade.value,
    };

    Api.post("painelcidade/melhoresbairros", data).then(rps => {

      //***Inicio do valor Grafico Mais Cidades
      var formataBairrosTexto = [];
      var formataBairrosValor = [];

      setMelhoresBairros(rps.data.obj);

      rps.data.obj.forEach( (item, i) => {
        if (i <= 10) {
          formataBairrosTexto.push(item.bairro);
          formataBairrosValor.push(item.total);
        }
      })

      setBairroLabel(formataBairrosTexto);
      setBairroValor(formataBairrosValor);
     
      setCaMelhoresBairros(false);
    })
  }

  //Grafico
  const dataBairros = {
    labels: bairroLabel,
    datasets: [{
      data: bairroValor,
      backgroundColor: [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)',
        'rgba(220, 20, 60, 1)',
        'rgba(127, 255, 0, 1)',
        'rgba(189, 183, 107, 1)',
        'rgba(255, 215, 0, 1)',
        'rgba(255, 255, 0, 1)',
        'rgba(128, 0, 128, 1)',
        'rgba(175, 238, 238, 1)',
        'rgba(0, 0, 128, 1)',
        'rgba(178, 34, 34, 1)',
      ],
      hoverOffset: 4
    }]
  };

  const optionsBairros = {
    legend: {
      display: true,
      position: "left"
    }
  };

  function getUsuariosCadastrados(){
    setCaUsuariosCadastrados(true); 

    var data = {
      cidade: cidade.value,
    };

    Api.post("painelcidade/usuarioscadastrados", data).then(rps => {
      setUsuariosCadastrados(rps.data.obj.qtde);
     
      setCaUsuariosCadastrados(false);
    })
  }

  function getUsuariosSemCompras(){

    var data = {
      cidade: cidade.value,
    };
    
    setCaUsuariosSemCompras(true); 
    Api.post("painelcidade/usuariossemcompras", data).then(rps => {
      setUsuariosSemCompras(rps.data.obj.qtde);
     
      setCaUsuariosSemCompras(false);
    })
  }

  function getUsuariosCompraUnica(){

    var data = {
      cidade: cidade.value,
    };
    
    setCaUsuariosCompraUnica(true); 
    Api.post("painelcidade/usuarioscompraunica", data).then(rps => {
      setUsuariosCompraUnica(rps.data.obj.qtde);
     
      setCaUsuariosCompraUnica(false);
    })
  }

  function getUsuariosPedidosMaisQ1(){

    var data = {
      cidade: cidade.value,
    };
    
    setCaUsuariosPedidosMaisQ1(true); 
    Api.post("painelcidade/usuariospedidosmaisq1", data).then(rps => {
      setUsuariosPedidosMaisQ1(rps.data.obj.qtde);
     
      setCaUsuariosPedidosMaisQ1(false);
    })
  }

  function getUsuariosEmRisco(){

    var data = {
      cidade: cidade.value,
    };
    
    setCaUsuariosEmRisco(true); 
    Api.post("painelcidade/usuariosemrisco", data).then(rps => {
      setUsuariosEmRisco(rps.data.obj.qtde);
     
      setCaUsuariosEmRisco(false);
    })
  }

  function getUsuarios60dias(){

    var data = {
      cidade: cidade.value,
    };
    
    setCaUsuarios60dias(true); 
    Api.post("painelcidade/usuarios60dias", data).then(rps => {
      setUsuarios60dias(rps.data.obj.qtde);
     
      setCaUsuarios60dias(false);
    })
  }

  function getCrescimentoUsuarios(){
    setCaCrescimentoUsuarios(true); 

    var data = {
      cidade: cidade.value,
    };

    Api.post("painelcidade/crescimentousuarios", data).then(rps => {
  
      geraCrescimentoUsuarios(rps.data.obj);
      
      setCaCrescimentoUsuarios(false);
    })
  }
      
  function geraCrescimentoUsuarios(dados){
    //***Inicio do Grafico Crescimento Usuários
  
    var formataQtde = [];
    var formataMes = [];
  
    dados.map( item => { 
        formataQtde.push(item.total);
        formataMes.push(item.month);
      
      return null
    })
    
    setUsuariosMesValor(formataQtde);
    setUsuariosMes(formataMes);
    //Fim***
  }
    
  const dataUsuarios = {
    labels: usuariosMes,
    datasets: [
      {
        data: usuariosMesValor,
        fill: false,
        borderColor: "red"
      },
    ]
  };

  function getEvolucaoTicketMedio(){
    setCaEvolucaoTicketMedio(true); 

    var data = {
      cidade: cidade.value,
    };

    Api.post("painelcidade/evolucaoticket", data).then(rps => {
  
      geraEvolucaoTicketMedio(rps.data.obj);
      
      setCaEvolucaoTicketMedio(false);
    })
  }
      
  function geraEvolucaoTicketMedio(dados){
    //***Inicio do Grafico Crescimento Usuários
  
    var formataQtde = [];
    var formataMes = [];
  
    dados.map( item => { 
        formataQtde.push(item.avg);
        formataMes.push(item.date);
      
      return null
    })
    
    setEvolucaoTicketMedioMesValor(formataQtde);
    setEvolucaoTicketMedioMes(formataMes);
    //Fim***
  }
    
  const dataEvolucaoTicketMedio = {
    labels: ticketEvolucaoMedioMes,
    datasets: [
      {
        data: ticketEvolucaoMedioMesValor,
        fill: true,
        borderColor: "green",
        backgroundColor: "#77c897",
      },
    ]
  };

  function getFrequenciaPedido(){
    setCaFrequenciaPedido(true); 

    var data = {
      cidade: cidade.value,
    };

    Api.post("painelcidade/frequenciapedidos", data).then(rps => {
  
      geraFrequenciaPedido(rps.data.obj);
      
      setCaFrequenciaPedido(false);
    })
  }
      
  function geraFrequenciaPedido(dados){
    //***Inicio do Grafico Transacionado Mes
  
    var formataQtde = [];
    var formataMes = [];
  
    dados.map( item => { 
        formataQtde.push(item.total);
        formataMes.push(item.date);
      
      return null
    })
    
    setFrequenciaPedidoValor(formataQtde);
    setFrequenciaPedidoMes(formataMes);
    //Fim***
  }

  const dataFrequeciaPedido = {
    labels: frequenciaPedidoMes,
    datasets: [
      {
        data: frequenciaPedidoValor,
        fill: true,
        borderColor: "#803e85",
        backgroundColor: "#A04EA6"
      },
    ]
  };

  function getEstabelecimentosCadastrados(){
    setCaEstabelecimentosCadastrados(true); 

    var data = {
      cidade: cidade.value,
    };

    Api.post("painelcidade/estabelecimentoscadastrados", data).then(rps => {
      setEstabelecimentosCadastrados(rps.data.obj.qtde);
     
      setCaEstabelecimentosCadastrados(false);
    })
  }

  function verTodosCancelamentos() {
    setModalCancelamento(true);
  }

  function fecharModalCancelamento(){
    setModalCancelamento(false);
  }

  function getEstabelecimentosAtivos(){
    setCaEstabelecimentosAtivos(true); 

    var data = {
      cidade: cidade.value,
    };

    Api.post("painelcidade/estabelecimentosativos", data).then(rps => {
      setEstabelecimentosAtivos(rps.data.obj.qtde);
     
      setCaEstabelecimentosAtivos(false);
    })
  }
  
  
  
    return (
        <> 
        

            {/*begin::Row*/}
            <div className="card card-custom">
              <div className="ml-10 mr-10 mt-10">    
                <div className="row">
                  <div className="col-md-10 form-group">
                    <label>Cidade</label>
                    <Select
                      options={cidadeSelect}
                      placeholder="Selecione..." 
                      defaultValue={cidadeSelect.find(
                        x => x.value === cidade
                      )}
                      onChange={(e) => { setCidade(e) }} 
                
                    /> 
                  </div>

                  <div className="col-md-2 form-group">
                  <label>&nbsp;</label><br />
                    <button className="btn btn-block btn-primary" onClick={e=>{filtrar()}}><i className="fas fa-filter" />Filtrar</button>
                  </div> 
                </div>     
              </div>
            </div>

            <br />

            <div className="row">
                <div className="col-xl-6">
                <LoadingOverlay
                    active={casemMovimentacao}
                    spinner
                    text='Carregando...'
                  >
                {/*begin::Base Table Widget 1*/}
                <div className="card card-custom card-stretch gutter-b">
                  {/*begin::Header*/}
                  <div className="card-header border-0 pt-5">
                    <h3 className="card-title align-items-start flex-column">
                      <span className="card-label font-weight-bolder text-dark">Estabelecimentos sem movimentação</span>
                      <br />
                          <span className="text-muted mt-3 font-weight-bold font-size-sm">há mais de 30 dias</span>
                    </h3>                        
                    <div className="card-toolbar">
                      <button className='btn btn-primary' onClick={e => { verTodosSemMovimentacao(); }}>Ver todos</button>
                    </div>
                  </div>
                  {/*end::Header*/}
                  {/*begin::Body*/}
                  <div className="card-body pt-2 pb-0 mt-n3">
                        {/*begin::Table*/}
                        
                        <div className="table-responsive">
                          <table className="table table-borderless table-vertical-center">
                            <thead>
                              <tr>
                                <th className="p-0 w-50px" />
                                <th className="p-0 min-w-200px" />
                                <th className="p-0 min-w-140px" />
                              </tr>
                            </thead>
                            <tbody>
                            { estSemCompras.map((item, i) =>
                            i <= 10 && 
                              <tr>
                                <th className="pl-0 py-5">
                                  <div className="symbol symbol-50 symbol-light mr-2">
                                    <span className="symbol-label">
                                      <img src={item.logotipo} className="h-100 w-100 align-self-center" alt="" />
                                    </span>
                                  </div>
                                </th>
                                <td className="py-6 pl-0">
                                  <Link to='/estabelecimentos/estabelecimentos' 
                                        className="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg" 
                                        onClick={e => { redirecionaEst(item.id_estabelecimento) }}>[{item.id_estabelecimento}] - {item.nome}</Link>
                                  <span className="text-muted font-weight-bold d-block">{item.nome_cidade} - {item.estado}</span>
                                </td>
                                <td>
                                  <div className="d-flex flex-column w-100 mr-2">
                                    <div className="text-dark text-right mb-1 font-size-lg">
                                      {item.data}
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            )}
                            </tbody>
                          </table>
                        </div>
                        {/*end::Table*/}
                      </div>
                      {/*end::Tap pane*/}
                </div>
                {/*end::Base Table Widget 1*/} 
                </LoadingOverlay> 
                </div>
                <div className="col-xl-6">
                <LoadingOverlay
                    active={caCancelamento}
                    spinner
                    text='Carregando...'
                  >
                {/*begin::Base Table Widget 1*/}
                <div className="card card-custom card-stretch gutter-b">
                  {/*begin::Header*/}
                  <div className="card-header border-0 pt-5">
                    <h3 className="card-title align-items-start flex-column">
                      <span className="card-label font-weight-bolder text-dark">Taxa de Cancelamento</span>
                      <br />
                          <span className="text-muted mt-3 font-weight-bold font-size-sm">há mais de 30 dias</span>
                    </h3>                                                
                    <div className="card-toolbar">
                      <button className='btn btn-primary' onClick={e => { verTodosCancelamentos(); }}>Ver todos</button>
                    </div>
                  </div>
                  {/*end::Header*/}
                  {/*begin::Body*/}
                  <div className="card-body pt-2 pb-0 mt-n3">
                        {/*begin::Table*/}
                        
                        <div className="table-responsive">
                          <table className="table table-borderless table-vertical-center">
                            <thead>
                              <tr>
                                <th className="p-0 w-50px" />
                                <th className="p-0 min-w-200px" />
                                <th className="p-0 min-w-140px" />
                              </tr>
                            </thead>
                            <tbody>
                            { cancelamento.map((item, i) =>
                            (i <= 10 && item.porcetagem > 0) &&
                              <tr>
                                <th className="pl-0 py-5">
                                  <div className="symbol symbol-50 symbol-light mr-2">
                                    <span className="symbol-label">
                                      <img src={item.logotipo} className="h-100 w-100 align-self-center" alt="" />
                                    </span>
                                  </div>
                                </th>
                                <td className="py-6 pl-0">
                                  <Link to='/estabelecimentos/estabelecimentos' 
                                        className="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg" 
                                        onClick={e => { redirecionaEst(item.id_estabelecimento) }}>[{item.id_estabelecimento}] - {item.nome}</Link>
                                  <span className="text-muted font-weight-bold d-block">Efetivadas: {item.Efetivadas} - Canceladas {item.Canceladas} - <label className="text-primary">Taxa: <b>{item.porcetagem}%</b></label></span>
                                </td>
                                <td>
                                  <div className="d-flex flex-column w-100 mr-2">
                                    <div className="text-dark text-right mb-1 font-size-lg">
                                      {item.data}
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            )}
                            </tbody>
                          </table>
                        </div>
                        {/*end::Table*/}
                      </div>
                      {/*end::Tap pane*/}
                </div>
                {/*end::Base Table Widget 1*/} 
                </LoadingOverlay>  
                </div>
              </div>
              {/*end::Row*/}

            {/*begin::Row*/}
            <div className="row">
                <div className="col">
                  <LoadingOverlay
                    active={caPedidoMes}
                    spinner
                    text='Carregando...'
                  >
                    <div className="card card-custom gutter-b" style={{height: '160px'}}>
                    <div className="card-body">
                      <span className="svg-icon svg-icon-3x svg-icon-primary">
                        {/*begin::Svg Icon | path:assets/media/svg/icons/Communication/Group.svg*/}
                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                          <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                            <polygon points="0 0 24 0 24 24 0 24" />
                            <path d="M18,14 C16.3431458,14 15,12.6568542 15,11 C15,9.34314575 16.3431458,8 18,8 C19.6568542,8 21,9.34314575 21,11 C21,12.6568542 19.6568542,14 18,14 Z M9,11 C6.790861,11 5,9.209139 5,7 C5,4.790861 6.790861,3 9,3 C11.209139,3 13,4.790861 13,7 C13,9.209139 11.209139,11 9,11 Z" fill="#000000" fillRule="nonzero" opacity="0.3" />
                            <path d="M17.6011961,15.0006174 C21.0077043,15.0378534 23.7891749,16.7601418 23.9984937,20.4 C24.0069246,20.5466056 23.9984937,21 23.4559499,21 L19.6,21 C19.6,18.7490654 18.8562935,16.6718327 17.6011961,15.0006174 Z M0.00065168429,20.1992055 C0.388258525,15.4265159 4.26191235,13 8.98334134,13 C13.7712164,13 17.7048837,15.2931929 17.9979143,20.2 C18.0095879,20.3954741 17.9979143,21 17.2466999,21 C13.541124,21 8.03472472,21 0.727502227,21 C0.476712155,21 -0.0204617505,20.45918 0.00065168429,20.1992055 Z" fill="#000000" fillRule="nonzero" />
                          </g>
                        </svg>
                        {/*end::Svg Icon*/}
                      </span>
                      <div className="text-dark font-weight-bolder font-size-h2 mt-3">{pedidoMes}</div>
                      <label className="text-muted font-weight-bold font-size-lg mt-1">Total de pedidos / mês atual</label>
                    </div>
                  </div>
                  {/*end::Tiles Widget 12*/}
                  </LoadingOverlay>
                </div>

                <div className="col">
                  <LoadingOverlay
                    active={caTicketMedio}
                    spinner
                    text='Carregando...'
                  >
                    <div className="card card-custom gutter-b" style={{height: '160px'}}>
                    <div className="card-body">
                      <span className="svg-icon svg-icon-3x svg-icon-primary">
                        {/*begin::Svg Icon | path:assets/media/svg/icons/Communication/Group.svg*/}
                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                          <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                            <polygon points="0 0 24 0 24 24 0 24" />
                            <path d="M18,14 C16.3431458,14 15,12.6568542 15,11 C15,9.34314575 16.3431458,8 18,8 C19.6568542,8 21,9.34314575 21,11 C21,12.6568542 19.6568542,14 18,14 Z M9,11 C6.790861,11 5,9.209139 5,7 C5,4.790861 6.790861,3 9,3 C11.209139,3 13,4.790861 13,7 C13,9.209139 11.209139,11 9,11 Z" fill="#000000" fillRule="nonzero" opacity="0.3" />
                            <path d="M17.6011961,15.0006174 C21.0077043,15.0378534 23.7891749,16.7601418 23.9984937,20.4 C24.0069246,20.5466056 23.9984937,21 23.4559499,21 L19.6,21 C19.6,18.7490654 18.8562935,16.6718327 17.6011961,15.0006174 Z M0.00065168429,20.1992055 C0.388258525,15.4265159 4.26191235,13 8.98334134,13 C13.7712164,13 17.7048837,15.2931929 17.9979143,20.2 C18.0095879,20.3954741 17.9979143,21 17.2466999,21 C13.541124,21 8.03472472,21 0.727502227,21 C0.476712155,21 -0.0204617505,20.45918 0.00065168429,20.1992055 Z" fill="#000000" fillRule="nonzero" />
                          </g>
                        </svg>
                        {/*end::Svg Icon*/}
                      </span>
                      <div className="text-dark font-weight-bolder font-size-h2 mt-3">{ticketMedio}</div>
                      <label className="text-muted font-weight-bold font-size-lg mt-1">Ticket Médio / Mês atual</label>
                    </div>
                  </div>
                  {/*end::Tiles Widget 12*/}
                  </LoadingOverlay>
                </div>

                <div className="col">
                  <LoadingOverlay
                    active={caComissao}
                    spinner
                    text='Carregando...'
                  >
                    <div className="card card-custom gutter-b" style={{height: '160px'}}>
                    <div className="card-body">
                      <span className="svg-icon svg-icon-3x svg-icon-primary">
                        {/*begin::Svg Icon | path:assets/media/svg/icons/Communication/Group.svg*/}
                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                          <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                            <polygon points="0 0 24 0 24 24 0 24" />
                            <path d="M18,14 C16.3431458,14 15,12.6568542 15,11 C15,9.34314575 16.3431458,8 18,8 C19.6568542,8 21,9.34314575 21,11 C21,12.6568542 19.6568542,14 18,14 Z M9,11 C6.790861,11 5,9.209139 5,7 C5,4.790861 6.790861,3 9,3 C11.209139,3 13,4.790861 13,7 C13,9.209139 11.209139,11 9,11 Z" fill="#000000" fillRule="nonzero" opacity="0.3" />
                            <path d="M17.6011961,15.0006174 C21.0077043,15.0378534 23.7891749,16.7601418 23.9984937,20.4 C24.0069246,20.5466056 23.9984937,21 23.4559499,21 L19.6,21 C19.6,18.7490654 18.8562935,16.6718327 17.6011961,15.0006174 Z M0.00065168429,20.1992055 C0.388258525,15.4265159 4.26191235,13 8.98334134,13 C13.7712164,13 17.7048837,15.2931929 17.9979143,20.2 C18.0095879,20.3954741 17.9979143,21 17.2466999,21 C13.541124,21 8.03472472,21 0.727502227,21 C0.476712155,21 -0.0204617505,20.45918 0.00065168429,20.1992055 Z" fill="#000000" fillRule="nonzero" />
                          </g>
                        </svg>
                        {/*end::Svg Icon*/}
                      </span>
                      <div className="text-dark font-weight-bolder font-size-h2 mt-3">{comissao}</div>
                      <label className="text-muted font-weight-bold font-size-lg mt-1">Comissão</label>
                    </div>
                  </div>
                  {/*end::Tiles Widget 12*/}

                  </LoadingOverlay>
                </div>

                <div className="col">
                  <LoadingOverlay
                    active={caTransacaoProjetada}
                    spinner
                    text='Carregando...'
                  >
                    <div className="card card-custom gutter-b" style={{height: '160px'}}>
                    <div className="card-body">
                      <span className="svg-icon svg-icon-3x svg-icon-primary">
                        {/*begin::Svg Icon | path:assets/media/svg/icons/Communication/Group.svg*/}
                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                          <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                            <polygon points="0 0 24 0 24 24 0 24" />
                            <path d="M18,14 C16.3431458,14 15,12.6568542 15,11 C15,9.34314575 16.3431458,8 18,8 C19.6568542,8 21,9.34314575 21,11 C21,12.6568542 19.6568542,14 18,14 Z M9,11 C6.790861,11 5,9.209139 5,7 C5,4.790861 6.790861,3 9,3 C11.209139,3 13,4.790861 13,7 C13,9.209139 11.209139,11 9,11 Z" fill="#000000" fillRule="nonzero" opacity="0.3" />
                            <path d="M17.6011961,15.0006174 C21.0077043,15.0378534 23.7891749,16.7601418 23.9984937,20.4 C24.0069246,20.5466056 23.9984937,21 23.4559499,21 L19.6,21 C19.6,18.7490654 18.8562935,16.6718327 17.6011961,15.0006174 Z M0.00065168429,20.1992055 C0.388258525,15.4265159 4.26191235,13 8.98334134,13 C13.7712164,13 17.7048837,15.2931929 17.9979143,20.2 C18.0095879,20.3954741 17.9979143,21 17.2466999,21 C13.541124,21 8.03472472,21 0.727502227,21 C0.476712155,21 -0.0204617505,20.45918 0.00065168429,20.1992055 Z" fill="#000000" fillRule="nonzero" />
                          </g>
                        </svg>
                        {/*end::Svg Icon*/}
                      </span>
                      <div className="text-dark font-weight-bolder font-size-h2 mt-3">{transacaoProjetada}</div>
                      <label className="text-muted font-weight-bold font-size-lg mt-1">Transação Projetada</label>
                    </div>
                  </div>
                  {/*end::Tiles Widget 12*/}
                  </LoadingOverlay>
                </div>

                <div className="col">
                  <LoadingOverlay
                    active={caCuponsUsados}
                    spinner
                    text='Carregando...'
                  >
                    <div className="card card-custom gutter-b" style={{height: '160px'}}>
                    <div className="card-body">
                      <span className="svg-icon svg-icon-3x svg-icon-primary">
                        {/*begin::Svg Icon | path:assets/media/svg/icons/Communication/Group.svg*/}
                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                          <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                            <polygon points="0 0 24 0 24 24 0 24" />
                            <path d="M18,14 C16.3431458,14 15,12.6568542 15,11 C15,9.34314575 16.3431458,8 18,8 C19.6568542,8 21,9.34314575 21,11 C21,12.6568542 19.6568542,14 18,14 Z M9,11 C6.790861,11 5,9.209139 5,7 C5,4.790861 6.790861,3 9,3 C11.209139,3 13,4.790861 13,7 C13,9.209139 11.209139,11 9,11 Z" fill="#000000" fillRule="nonzero" opacity="0.3" />
                            <path d="M17.6011961,15.0006174 C21.0077043,15.0378534 23.7891749,16.7601418 23.9984937,20.4 C24.0069246,20.5466056 23.9984937,21 23.4559499,21 L19.6,21 C19.6,18.7490654 18.8562935,16.6718327 17.6011961,15.0006174 Z M0.00065168429,20.1992055 C0.388258525,15.4265159 4.26191235,13 8.98334134,13 C13.7712164,13 17.7048837,15.2931929 17.9979143,20.2 C18.0095879,20.3954741 17.9979143,21 17.2466999,21 C13.541124,21 8.03472472,21 0.727502227,21 C0.476712155,21 -0.0204617505,20.45918 0.00065168429,20.1992055 Z" fill="#000000" fillRule="nonzero" />
                          </g>
                        </svg>
                        {/*end::Svg Icon*/}
                      </span>
                      <div className="text-dark font-weight-bolder font-size-h2 mt-3">{cuponsUsados}</div>
                      <label className="text-muted font-weight-bold font-size-lg mt-1">Total de cupons usados</label>
                    </div>
                  </div>
                  {/*end::Tiles Widget 12*/}
                  </LoadingOverlay>
                </div>

              </div>
              {/*end::Row*/}


              {/*begin::Row*/}
              <div className="row">
                <div className="col-xl-4">
                <LoadingOverlay
                    active={caPedidosUltimos30}
                    spinner
                    text='Carregando...'
                  >
                {/*begin::Base Table Widget 1*/}
                <div className="card card-custom card-stretch gutter-b">
                  {/*begin::Header*/}
                  <div className="card-header border-0 pt-5">
                    <h3 className="card-title align-items-start flex-column">
                      <span className="card-label font-weight-bolder text-dark">Pedidos - Últimos 30 dias</span>
                    </h3>
                  </div>
                  {/*end::Header*/}
                  {/*begin::Body*/}
                    <div className="card-body pt-2 pb-0 mt-n3">
                      {/*begin::Table*/}
                      <div className="row">
                        
                        <div className="col-md-12">
                          <Line data={dataUltimos30} legend={legenda} options={options} />
                        </div>

                      </div>
    
                    </div>
                    {/*end::Tap pane*/}
                </div>
                {/*end::Base Table Widget 1*/} 
                </LoadingOverlay> 
                </div>
                
                <div className="col-xl-4">                
                  <LoadingOverlay
                      active={caPedidosUltimos30}
                      spinner
                      text='Carregando...'
                    >
                  {/*begin::Base Table Widget 1*/}
                  <div className="card card-custom card-stretch gutter-b">
                    {/*begin::Header*/}
                    <div className="card-header border-0 pt-5">
                      <h3 className="card-title align-items-start flex-column">
                        <span className="card-label font-weight-bolder text-dark">R$ - Últimos 30 dias</span>
                      </h3>
                    </div>
                    {/*end::Header*/}
                    {/*begin::Body*/}
                    <div className="card-body pt-2 pb-0 mt-n3">
                        {/*begin::Table*/}
                        <div className="row">
                          
                          <div className="col-md-12">
                            <Line data={dataPedidoUltimos} legend={legenda} options={options} />
                          </div>

                        </div>

                          
                        </div>
                        {/*end::Tap pane*/}
                  </div>
                  {/*end::Base Table Widget 1*/} 
                  </LoadingOverlay> 
                </div>

                <div className="col-xl-4">                
                  <LoadingOverlay
                      active={caPedidosUltimos30}
                      spinner
                      text='Carregando...'
                    >
                  {/*begin::Base Table Widget 1*/}
                  <div className="card card-custom card-stretch gutter-b">
                    {/*begin::Header*/}
                    <div className="card-header border-0 pt-5">
                      <h3 className="card-title align-items-start flex-column">
                        <span className="card-label font-weight-bolder text-dark">Cupons - Últimos 30 dias</span>
                      </h3>
                    </div>
                    {/*end::Header*/}
                    {/*begin::Body*/}
                    <div className="card-body pt-2 pb-0 mt-n3">
                        {/*begin::Table*/}
                        <div className="row">
                          
                          <div className="col-md-12">
                            <Line data={dataCuponsUltimos} legend={legenda} options={options} />
                          </div>

                        </div>

                          
                        </div>
                        {/*end::Tap pane*/}
                  </div>
                  {/*end::Base Table Widget 1*/} 
                  </LoadingOverlay> 
                </div>

              </div>
              {/*end::Row*/}


              {/*begin::Row*/}
              <div className="row">
                <div className="col-xl-6">
                <LoadingOverlay
                    active={caPedidosUltimos30}
                    spinner
                    text='Carregando...'
                  >
                {/*begin::Base Table Widget 1*/}
                <div className="card card-custom card-stretch gutter-b">
                  {/*begin::Header*/}
                  <div className="card-header border-0 pt-5">
                    <h3 className="card-title align-items-start flex-column">
                      <span className="card-label font-weight-bolder text-dark">Valor Transacionado mês a mês</span>
                    </h3>
                  </div>
                  {/*end::Header*/}
                  {/*begin::Body*/}
                    <div className="card-body pt-2 pb-0 mt-n3">
                      {/*begin::Table*/}
                      <div className="row">
                        
                        <div className="col-md-12">
                          <Line data={dataTransacionado} options={options} />
                        </div>

                      </div>
    
                    </div>
                    {/*end::Tap pane*/}
                </div>
                {/*end::Base Table Widget 1*/} 
                </LoadingOverlay> 
                </div>
                
                <div className="col-xl-6">                
                  <LoadingOverlay
                      active={caPedidosUltimos30}
                      spinner
                      text='Carregando...'
                    >
                  {/*begin::Base Table Widget 1*/}
                  <div className="card card-custom card-stretch gutter-b">
                    {/*begin::Header*/}
                    <div className="card-header border-0 pt-5">
                      <h3 className="card-title align-items-start flex-column">
                        <span className="card-label font-weight-bolder text-dark">Venda por bairro</span>
                      </h3>
                    </div>
                    {/*end::Header*/}
                    {/*begin::Body*/}
                    <div className="card-body pt-2 pb-0 mt-n3">
                        {/*begin::Table*/}
                        <div className="row">
                          
                          <div className="col-md-12">
                            <Doughnut data={dataBairros} options={optionsBairros} />
                          </div>

                        </div>

                          
                        </div>
                        {/*end::Tap pane*/}
                  </div>
                  {/*end::Base Table Widget 1*/} 
                  </LoadingOverlay> 
                </div>

              </div>
              {/*end::Row*/}

              {/*begin::Row*/}
              <div className="row">
                <div className="col-md-2">
                  <LoadingOverlay
                    active={caUsuariosCadastrados}
                    spinner
                    text='Carregando...'
                  >
                  <div className="card card-custom gutter-b" style={{height: '160px'}}>
                    <div className="card-body">
                      <span className="svg-icon svg-icon-3x svg-icon-primary">
                        {/*begin::Svg Icon | path:assets/media/svg/icons/Communication/Group.svg*/}
                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                          <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                            <polygon points="0 0 24 0 24 24 0 24" />
                            <path d="M18,14 C16.3431458,14 15,12.6568542 15,11 C15,9.34314575 16.3431458,8 18,8 C19.6568542,8 21,9.34314575 21,11 C21,12.6568542 19.6568542,14 18,14 Z M9,11 C6.790861,11 5,9.209139 5,7 C5,4.790861 6.790861,3 9,3 C11.209139,3 13,4.790861 13,7 C13,9.209139 11.209139,11 9,11 Z" fill="#000000" fillRule="nonzero" opacity="0.3" />
                            <path d="M17.6011961,15.0006174 C21.0077043,15.0378534 23.7891749,16.7601418 23.9984937,20.4 C24.0069246,20.5466056 23.9984937,21 23.4559499,21 L19.6,21 C19.6,18.7490654 18.8562935,16.6718327 17.6011961,15.0006174 Z M0.00065168429,20.1992055 C0.388258525,15.4265159 4.26191235,13 8.98334134,13 C13.7712164,13 17.7048837,15.2931929 17.9979143,20.2 C18.0095879,20.3954741 17.9979143,21 17.2466999,21 C13.541124,21 8.03472472,21 0.727502227,21 C0.476712155,21 -0.0204617505,20.45918 0.00065168429,20.1992055 Z" fill="#000000" fillRule="nonzero" />
                          </g>
                        </svg>
                        {/*end::Svg Icon*/}
                      </span>
                      <div className="text-dark font-weight-bolder font-size-h2 mt-3">{usuariosCadastrados}</div>
                      <label className="text-muted font-weight-bold font-size-lg mt-1">Total de usuários cadastrados</label>
                    </div>
                  </div>
                  {/*end::Tiles Widget 12*/}
                  </LoadingOverlay>
                </div>

                <div className="col-md-2">
                  <LoadingOverlay
                    active={caUsuariosSemCompras}
                    spinner
                    text='Carregando...'
                  >
                  <div className="card card-custom gutter-b" style={{height: '160px'}}>
                    <div className="card-body">
                      <span className="svg-icon svg-icon-3x svg-icon-primary">
                        {/*begin::Svg Icon | path:assets/media/svg/icons/Communication/Group.svg*/}
                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                          <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                            <polygon points="0 0 24 0 24 24 0 24" />
                            <path d="M18,14 C16.3431458,14 15,12.6568542 15,11 C15,9.34314575 16.3431458,8 18,8 C19.6568542,8 21,9.34314575 21,11 C21,12.6568542 19.6568542,14 18,14 Z M9,11 C6.790861,11 5,9.209139 5,7 C5,4.790861 6.790861,3 9,3 C11.209139,3 13,4.790861 13,7 C13,9.209139 11.209139,11 9,11 Z" fill="#000000" fillRule="nonzero" opacity="0.3" />
                            <path d="M17.6011961,15.0006174 C21.0077043,15.0378534 23.7891749,16.7601418 23.9984937,20.4 C24.0069246,20.5466056 23.9984937,21 23.4559499,21 L19.6,21 C19.6,18.7490654 18.8562935,16.6718327 17.6011961,15.0006174 Z M0.00065168429,20.1992055 C0.388258525,15.4265159 4.26191235,13 8.98334134,13 C13.7712164,13 17.7048837,15.2931929 17.9979143,20.2 C18.0095879,20.3954741 17.9979143,21 17.2466999,21 C13.541124,21 8.03472472,21 0.727502227,21 C0.476712155,21 -0.0204617505,20.45918 0.00065168429,20.1992055 Z" fill="#000000" fillRule="nonzero" />
                          </g>
                        </svg>
                        {/*end::Svg Icon*/}
                      </span>
                      <div className="text-dark font-weight-bolder font-size-h2 mt-3">{usuariosSemCompras}</div>
                      <label className="text-muted font-weight-bold font-size-lg mt-1">Usuários que fizeram 0 compra no app</label>
                    </div>
                  </div>
                  {/*end::Tiles Widget 12*/}
                  </LoadingOverlay>
                </div>

                <div className="col-md-2">
                  <LoadingOverlay
                    active={caUsuariosCompraUnica}
                    spinner
                    text='Carregando...'
                  >
                  <div className="card card-custom gutter-b" style={{height: '160px'}}>
                    <div className="card-body">
                      <span className="svg-icon svg-icon-3x svg-icon-primary">
                        {/*begin::Svg Icon | path:assets/media/svg/icons/Communication/Group.svg*/}
                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                          <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                            <polygon points="0 0 24 0 24 24 0 24" />
                            <path d="M18,14 C16.3431458,14 15,12.6568542 15,11 C15,9.34314575 16.3431458,8 18,8 C19.6568542,8 21,9.34314575 21,11 C21,12.6568542 19.6568542,14 18,14 Z M9,11 C6.790861,11 5,9.209139 5,7 C5,4.790861 6.790861,3 9,3 C11.209139,3 13,4.790861 13,7 C13,9.209139 11.209139,11 9,11 Z" fill="#000000" fillRule="nonzero" opacity="0.3" />
                            <path d="M17.6011961,15.0006174 C21.0077043,15.0378534 23.7891749,16.7601418 23.9984937,20.4 C24.0069246,20.5466056 23.9984937,21 23.4559499,21 L19.6,21 C19.6,18.7490654 18.8562935,16.6718327 17.6011961,15.0006174 Z M0.00065168429,20.1992055 C0.388258525,15.4265159 4.26191235,13 8.98334134,13 C13.7712164,13 17.7048837,15.2931929 17.9979143,20.2 C18.0095879,20.3954741 17.9979143,21 17.2466999,21 C13.541124,21 8.03472472,21 0.727502227,21 C0.476712155,21 -0.0204617505,20.45918 0.00065168429,20.1992055 Z" fill="#000000" fillRule="nonzero" />
                          </g>
                        </svg>
                        {/*end::Svg Icon*/}
                      </span>
                      <div className="text-dark font-weight-bolder font-size-h2 mt-3">{usuariosCompraUnica}</div>
                      <label className="text-muted font-weight-bold font-size-lg mt-1">Compra única</label>
                    </div>
                  </div>
                  {/*end::Tiles Widget 12*/}
                  </LoadingOverlay>
                </div>

                <div className="col-md-2">
                  <LoadingOverlay
                    active={caUsuariosPedidosMaisQ1}
                    spinner
                    text='Carregando...'
                  >
                  <div className="card card-custom gutter-b" style={{height: '160px'}}>
                    <div className="card-body">
                      <span className="svg-icon svg-icon-3x svg-icon-primary">
                        {/*begin::Svg Icon | path:assets/media/svg/icons/Communication/Group.svg*/}
                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                          <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                            <polygon points="0 0 24 0 24 24 0 24" />
                            <path d="M18,14 C16.3431458,14 15,12.6568542 15,11 C15,9.34314575 16.3431458,8 18,8 C19.6568542,8 21,9.34314575 21,11 C21,12.6568542 19.6568542,14 18,14 Z M9,11 C6.790861,11 5,9.209139 5,7 C5,4.790861 6.790861,3 9,3 C11.209139,3 13,4.790861 13,7 C13,9.209139 11.209139,11 9,11 Z" fill="#000000" fillRule="nonzero" opacity="0.3" />
                            <path d="M17.6011961,15.0006174 C21.0077043,15.0378534 23.7891749,16.7601418 23.9984937,20.4 C24.0069246,20.5466056 23.9984937,21 23.4559499,21 L19.6,21 C19.6,18.7490654 18.8562935,16.6718327 17.6011961,15.0006174 Z M0.00065168429,20.1992055 C0.388258525,15.4265159 4.26191235,13 8.98334134,13 C13.7712164,13 17.7048837,15.2931929 17.9979143,20.2 C18.0095879,20.3954741 17.9979143,21 17.2466999,21 C13.541124,21 8.03472472,21 0.727502227,21 C0.476712155,21 -0.0204617505,20.45918 0.00065168429,20.1992055 Z" fill="#000000" fillRule="nonzero" />
                          </g>
                        </svg>
                        {/*end::Svg Icon*/}
                      </span>
                      <div className="text-dark font-weight-bolder font-size-h2 mt-3">{usuariosPedidosMaisQ1}</div>
                      <label className="text-muted font-weight-bold font-size-lg mt-1">Clientes com +1 compra em 30D</label>
                    </div>
                  </div>
                  {/*end::Tiles Widget 12*/}
                  </LoadingOverlay>
                </div>

                <div className="col-md-2">
                  <LoadingOverlay
                    active={caUsuariosEmRisco}
                    spinner
                    text='Carregando...'
                  >
                  <div className="card card-custom gutter-b" style={{height: '160px'}}>
                    <div className="card-body">
                      <span className="svg-icon svg-icon-3x svg-icon-primary">
                        {/*begin::Svg Icon | path:assets/media/svg/icons/Communication/Group.svg*/}
                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                          <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                            <polygon points="0 0 24 0 24 24 0 24" />
                            <path d="M18,14 C16.3431458,14 15,12.6568542 15,11 C15,9.34314575 16.3431458,8 18,8 C19.6568542,8 21,9.34314575 21,11 C21,12.6568542 19.6568542,14 18,14 Z M9,11 C6.790861,11 5,9.209139 5,7 C5,4.790861 6.790861,3 9,3 C11.209139,3 13,4.790861 13,7 C13,9.209139 11.209139,11 9,11 Z" fill="#000000" fillRule="nonzero" opacity="0.3" />
                            <path d="M17.6011961,15.0006174 C21.0077043,15.0378534 23.7891749,16.7601418 23.9984937,20.4 C24.0069246,20.5466056 23.9984937,21 23.4559499,21 L19.6,21 C19.6,18.7490654 18.8562935,16.6718327 17.6011961,15.0006174 Z M0.00065168429,20.1992055 C0.388258525,15.4265159 4.26191235,13 8.98334134,13 C13.7712164,13 17.7048837,15.2931929 17.9979143,20.2 C18.0095879,20.3954741 17.9979143,21 17.2466999,21 C13.541124,21 8.03472472,21 0.727502227,21 C0.476712155,21 -0.0204617505,20.45918 0.00065168429,20.1992055 Z" fill="#000000" fillRule="nonzero" />
                          </g>
                        </svg>
                        {/*end::Svg Icon*/}
                      </span>
                      <div className="text-dark font-weight-bolder font-size-h2 mt-3">{usuariosEmRisco}</div>
                      <label className="text-muted font-weight-bold font-size-lg mt-1">Usuários que fizeram 0 compra no app - 30D</label>
                    </div>
                  </div>
                  {/*end::Tiles Widget 12*/}
                  </LoadingOverlay>
                </div>

                <div className="col-md-2">
                  <LoadingOverlay
                    active={caUsuarios60dias}
                    spinner
                    text='Carregando...'
                  >
                  <div className="card card-custom gutter-b" style={{height: '160px'}}>
                    <div className="card-body">
                      <span className="svg-icon svg-icon-3x svg-icon-primary">
                        {/*begin::Svg Icon | path:assets/media/svg/icons/Communication/Group.svg*/}
                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                          <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                            <polygon points="0 0 24 0 24 24 0 24" />
                            <path d="M18,14 C16.3431458,14 15,12.6568542 15,11 C15,9.34314575 16.3431458,8 18,8 C19.6568542,8 21,9.34314575 21,11 C21,12.6568542 19.6568542,14 18,14 Z M9,11 C6.790861,11 5,9.209139 5,7 C5,4.790861 6.790861,3 9,3 C11.209139,3 13,4.790861 13,7 C13,9.209139 11.209139,11 9,11 Z" fill="#000000" fillRule="nonzero" opacity="0.3" />
                            <path d="M17.6011961,15.0006174 C21.0077043,15.0378534 23.7891749,16.7601418 23.9984937,20.4 C24.0069246,20.5466056 23.9984937,21 23.4559499,21 L19.6,21 C19.6,18.7490654 18.8562935,16.6718327 17.6011961,15.0006174 Z M0.00065168429,20.1992055 C0.388258525,15.4265159 4.26191235,13 8.98334134,13 C13.7712164,13 17.7048837,15.2931929 17.9979143,20.2 C18.0095879,20.3954741 17.9979143,21 17.2466999,21 C13.541124,21 8.03472472,21 0.727502227,21 C0.476712155,21 -0.0204617505,20.45918 0.00065168429,20.1992055 Z" fill="#000000" fillRule="nonzero" />
                          </g>
                        </svg>
                        {/*end::Svg Icon*/}
                      </span>
                      <div className="text-dark font-weight-bolder font-size-h2 mt-3">{usuarios60dias}</div>
                      <label className="text-muted font-weight-bold font-size-lg mt-1">0 compras - +60D</label>
                    </div>
                  </div>
                  {/*end::Tiles Widget 12*/}
                  </LoadingOverlay>
                </div>      
              </div>
              {/*end::Row*/}


              {/*begin::Row*/}
              <div className="row">
                <div className="col-xl-6">
                <LoadingOverlay
                    active={caCrescimentoUsuarios}
                    spinner
                    text='Carregando...'
                  >
                {/*begin::Base Table Widget 1*/}
                <div className="card card-custom card-stretch gutter-b">
                  {/*begin::Header*/}
                  <div className="card-header border-0 pt-5">
                    <h3 className="card-title align-items-start flex-column">
                      <span className="card-label font-weight-bolder text-dark">Crescimento usuários - cidade</span>
                    </h3>
                  </div>
                  {/*end::Header*/}
                  {/*begin::Body*/}
                    <div className="card-body pt-2 pb-0 mt-n3">
                      {/*begin::Table*/}
                      <div className="row">
                        
                        <div className="col-md-12">
                          <Line data={dataUsuarios} options={options} />
                        </div>

                      </div>
    
                    </div>
                    {/*end::Tap pane*/}
                </div>
                {/*end::Base Table Widget 1*/} 
                </LoadingOverlay> 
                </div>
                
                <div className="col-xl-6">                
                  <LoadingOverlay
                      active={caEvolucaoTicketMedio}
                      spinner
                      text='Carregando...'
                    >
                  {/*begin::Base Table Widget 1*/}
                  <div className="card card-custom card-stretch gutter-b">
                    {/*begin::Header*/}
                    <div className="card-header border-0 pt-5">
                      <h3 className="card-title align-items-start flex-column">
                        <span className="card-label font-weight-bolder text-dark">Evolução ticket médio - cidade</span>
                      </h3>
                    </div>
                    {/*end::Header*/}
                    {/*begin::Body*/}
                    <div className="card-body pt-2 pb-0 mt-n3">
                        {/*begin::Table*/}
                        <div className="row">
                          
                          <div className="col-md-12">
                            <Line data={dataEvolucaoTicketMedio} options={options} />
                          </div>

                        </div>

                          
                        </div>
                        {/*end::Tap pane*/}
                  </div>
                  {/*end::Base Table Widget 1*/} 
                  </LoadingOverlay> 
                </div>

              </div>
              {/*end::Row*/}

              {/*begin::Row*/}
              <div className="row">
                <div className="col-xl-3">
                  <LoadingOverlay
                      active={caEstabelecimentosCadastrados}
                      spinner
                      text='Carregando...'
                    >
                    <div className="card card-custom card-body">
                      <span className="font-weight-bolder text-dark-75 font-size-h5 mb-2">Estabelecimentos cadastrados</span>
                      <div className="row">
                          <div className="col-sm-8">
                          <span className="text-dark-75 font-weight-bolder font-size-h2 mr-2">{estabelecimentosCadastrados}</span>
                          </div>
                          <div className="col-sm-4">
                          <i className="icon-xl far fa-bookmark text-primary float-right"></i>
                          </div>
                      </div>
                    </div>
                    </LoadingOverlay>
                    <br />
                    <LoadingOverlay
                      active={caEstabelecimentosAtivos}
                      spinner
                      text='Carregando...'
                    >
                    <div className="card card-custom card-body">
                      <span className="font-weight-bolder text-dark-75 font-size-h5 mb-2">Estabelecimentos ativos</span>
                      <div className="row">
                          <div className="col-sm-8">
                          <span className="text-dark-75 font-weight-bolder font-size-h2 mr-2">{estabelecimentosAtivos}</span>
                          </div>
                          <div className="col-sm-4">
                          <i className="icon-xl far fa-bookmark text-primary float-right"></i>
                          </div>
                      </div>
                    </div>
                    </LoadingOverlay>
                </div>
                
                <div className="col-xl-9">                
                  <LoadingOverlay
                      active={caEvolucaoTicketMedio}
                      spinner
                      text='Carregando...'
                    >
                  {/*begin::Base Table Widget 1*/}
                  <div className="card card-custom card-stretch gutter-b">
                    {/*begin::Header*/}
                    <div className="card-header border-0 pt-5">
                      <h3 className="card-title align-items-start flex-column">
                        <span className="card-label font-weight-bolder text-dark">Frequência pedidos - cidade</span>
                      </h3>
                    </div>
                    {/*end::Header*/}
                    {/*begin::Body*/}
                    <div className="card-body pt-2 pb-0 mt-n3">
                        {/*begin::Table*/}
                        <div className="row">
                          
                          <div className="col-md-12">
                            <Bar data={dataFrequeciaPedido} options={options} />
                          </div>

                        </div>

                          
                        </div>
                        {/*end::Tap pane*/}
                  </div>
                  {/*end::Base Table Widget 1*/} 
                  </LoadingOverlay> 
                </div>

              </div>
              {/*end::Row*/}



              <Modal size={'lg'} centered show={modal} onHide={() => fecharModal()}>
                <Modal.Header>
                <Modal.Title>Estabelecimento sem movimentação</Modal.Title>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={e => {fecharModal()}}>
                      <i aria-hidden="true" className="ki ki-close" />
                    </button>
                </Modal.Header> 

               {/*begin::Table*/}
                <div className="ml-5 mr-5">
                  <table className="table table-borderless table-vertical-center">
                    <thead>
                      <tr>
                        <th className="p-0 w-50px" />
                        <th className="p-0 min-w-200px" />
                        <th className="p-0 min-w-140px" />
                      </tr>
                    </thead>
                    <tbody>
                    { estSemCompras.map((item) => 
                      <tr>
                        <th className="pl-0 py-5">
                          <div className="symbol symbol-50 symbol-light mr-2">
                            <span className="symbol-label">
                              <img src={item.logotipo} className="h-100 w-100 align-self-center" alt="" />
                            </span>
                          </div>
                        </th>
                        <td className="py-6 pl-0">
                          <span className="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg">[{item.id_estabelecimento}] - {item.nome}</span>
                          <span className="text-muted font-weight-bold d-block">{item.nome_cidade} - {item.estado}</span>
                        </td>
                        <td>
                          <div className="d-flex flex-column w-100 mr-2">
                            <div className="text-dark text-right mb-1 font-size-lg">
                              {item.data} - (Dias )
                            </div>
                          </div>
                        </td>
                      </tr>
                    )}
                    </tbody>
                  </table>
                </div>
                {/*end::Table*/}

                <Modal.Footer>
                  <button type="button" onClick={e => { fecharModal(); }} className="btn btn-light-primary font-weight-bold" data-dismiss="modal"><i className="fas fa-door-open" />Fechar</button>
                </Modal.Footer>
              </Modal>

              <Modal size={'lg'} centered show={modalCancelamento} onHide={() => fecharModalCancelamento()}>
                <Modal.Header>
                <Modal.Title>Cancelamento de pedidos</Modal.Title>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={e => {fecharModalCancelamento()}}>
                      <i aria-hidden="true" className="ki ki-close" />
                    </button>
                </Modal.Header> 

               {/*begin::Table*/}
                <div className="ml-5 mr-5">
                  <table className="table table-borderless table-vertical-center">
                    <thead>
                      <tr>
                        <th className="p-0 w-50px" />
                        <th className="p-0 min-w-200px" />
                        <th className="p-0 min-w-140px" />
                      </tr>
                    </thead>
                    <tbody>
                    { cancelamento.map((item) => 
                      <tr>
                        <th className="pl-0 py-5">
                          <div className="symbol symbol-50 symbol-light mr-2">
                            <span className="symbol-label">
                              <img src={item.logotipo} className="h-100 w-100 align-self-center" alt="" />
                            </span>
                          </div>
                        </th>
                        <td className="py-6 pl-0">
                          <Link to='/estabelecimentos/estabelecimentos' 
                                className="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg" 
                                onClick={e => { redirecionaEst(item.id_estabelecimento) }}>[{item.id_estabelecimento}] - {item.nome}</Link>
                          <span className="text-muted font-weight-bold d-block">Efetivadas: {item.Efetivadas} - Canceladas {item.Canceladas} - <label className="text-primary">Taxa: <b>{item.porcetagem}%</b></label></span>
                        </td>
                        <td>
                          <div className="d-flex flex-column w-100 mr-2">
                            <div className="text-dark text-right mb-1 font-size-lg">
                              {item.data}
                            </div>
                          </div>
                        </td>
                      </tr>
                    )}
                    </tbody>
                  </table>
                </div>
                {/*end::Table*/}

                <Modal.Footer>
                  <button type="button" onClick={e => { fecharModalCancelamento(); }} className="btn btn-light-primary font-weight-bold" data-dismiss="modal"><i className="fas fa-door-open" />Fechar</button>
                </Modal.Footer>
              </Modal>
        </>
        );
    }
    
    export default withRouter(Painel);                  
import React, { useState, useEffect } from 'react';
import Api from '../../../services/api';
import { withRouter } from "react-router";
import LoadingOverlay from 'react-loading-overlay';
import {  Line, Bar } from 'react-chartjs-2';
import Select from 'react-select';

import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import pt from "date-fns/locale/pt-BR"; 
registerLocale("pt-BR", pt);

  const Painel = () => {
  //Filtro
  var d = new Date();
  d.setDate(d.getDate() - 60);
  const [ restauranteSelect, setRestauranteSelect ] = useState([]);
  const [ restaurante, setRestaurante ] = useState();
  // const [ dias, setDDias ] = useState();
  //Painel Ticket Médio / Mês atual
  const [ ticketMedio , setTicketMedio ] = useState([]);
  const [ caTicketMedio, setCaTicketMedio ] = useState(false);
  //Painel Restaurante - Valor Transacionado 30 dias
  const [ valorTransacionado30Qtde , setValorTransacionado30Qtde ] = useState([]);
  const [ valorTransacionado30Dias , setValorTransacionado30Dias ] = useState([]);
  const [ caValorTransacionado30, setCaValorTransacionado30 ] = useState(false);
  //Painel Restaurante - Total Pedido 30 dias
  const [ totalPedido30Qtde , setTotalPedido30Qtde ] = useState([]);
  const [ totalPedido30Dias , setTotalPedido30Dias ] = useState([]);
  const [ caTotalPedido30, setCaTotalPedido30 ] = useState(false);
  //Painel Restaurante - Total Cupom 30 dias
  const [ totalCupom30Qtde , setTotalCupom30Qtde ] = useState([]);
  const [ totalCupom30Dias , setTotalCupom30Dias ] = useState([]);
  const [ caTotalCupom30, setCaTotalCupom30 ] = useState(false);
  //Painel Restaurante - Total Cupom 30 dias
  const [ pedidoBairro30Qtde , setPedidoBairro30Qtde ] = useState([]);
  const [ pedidoBairro30Dias , setPedidoBairro30Dias ] = useState([]);
  const [ caPedidoBairro30, setCaPedidoBairro30 ] = useState(false);
  //Painel Restaurante - Valor Mes Ano
  const [ valorMesQtde , setValorMesQtde ] = useState([]);
  const [ valorMesDias , setValorMesDias ] = useState([]);
  const [ caValorMes, setCaValorMes ] = useState(false);
  
  useEffect(() => {
    getRestauranteSelect();
  }, []);

  function getRestauranteSelect() {
    var data = {
      representante: localStorage.getItem('@ADM-Aiboo/nivel') == 'R' ? localStorage.getItem('@ADM-Aiboo/id_representante') : ''
    }

    Api.post("estabelecimentos/listaNomeEstabelecimento", data).then(rps => {
      var rep = rps.data.obj;
      var nRep = [];
      nRep[nRep.length] = {value: '', label: 'Selecione...'};
      rep.forEach(e => {
        nRep[nRep.length] = {value: e.id_estabelecimento, label: e.nome};
      })
      setRestauranteSelect(nRep);
    })
  }

    function filtrar() {
      getTicketMedio();
      getValorTransacionado30();
      getTotalPedido30();
      getTotalCupom30();
      getPedidoBairro30();
      getValorMes();  
    }

  function getTicketMedio(){
    setCaTicketMedio(true); 

    var data = {
      restaurante: restaurante.value,
    };

    Api.post("painelrestaurante/ticketmedio", data).then(rps => {
      setTicketMedio(rps.data.obj.ticket);
      console.log(rps.data.obj);
     
      setCaTicketMedio(false);
    })
  }

  function getValorTransacionado30(){
    setCaValorTransacionado30(true); 

    var data = {
      restaurante: restaurante.value,
    };

    Api.post("painelrestaurante/valortransacionado30", data).then(rps => {
  
      geraValorTransacionado30(rps.data.obj);
     
      setCaValorTransacionado30(false);
    })
  }
  
  function geraValorTransacionado30(dados){
    //***Inicio do Grafico Ultimos 30 dias
  
    var formataQtde = [];
    var formataMes = [];
  
    dados.map( item => { 
        formataQtde.push(item.total);
        formataMes.push(item.data);
      
      return null
    })
   
    setValorTransacionado30Qtde(formataQtde);
    setValorTransacionado30Dias(formataMes);
    //Fim***
  }
  
  const dataValorTransacionado = {
  labels: valorTransacionado30Dias,
  datasets: [
    {
      data: valorTransacionado30Qtde,
      fill: true,
      backgroundColor: "#ffec5b",
      borderColor: "#f4c430"
    },
  ]
  };
  
  const legenda = {
  display: false,
  };
  
  const options = {
  scales: {
    yAxes: [
      {
        ticks: {
          suggestedMin: 0,
          suggestedMax: 100
        }
      }
    ]
  }
  };

  function getTotalPedido30(){
    setCaTotalPedido30(true); 

    var data = {
      restaurante: restaurante.value,
    };

    Api.post("painelrestaurante/totalpedido30", data).then(rps => {
  
      geraTotalPedido30(rps.data.obj);
     
      setCaTotalPedido30(false);
    })
  }
  
  function geraTotalPedido30(dados){
    //***Inicio do Grafico Ultimos 30 dias
  
    var formataQtde = [];
    var formataMes = [];
  
    dados.map( item => { 
        formataQtde.push(item.total);
        formataMes.push(item.data);
      
      return null
    })
   
    setTotalPedido30Qtde(formataQtde);
    setTotalPedido30Dias(formataMes);
    //Fim***
  }
  
  const totalPedido30 = {
  labels: totalPedido30Dias,
  datasets: [
    {
      data: totalPedido30Qtde,
      fill: true,
      backgroundColor: "#acd1fc",
      borderColor: "#153cb2"
    },
  ]
  };

  function getTotalCupom30(){
    setCaTotalCupom30(true); 

    var data = {
      restaurante: restaurante.value,
    };

    Api.post("painelrestaurante/totalcupom30", data).then(rps => {
  
      geraTotalCupom30(rps.data.obj);
     
      setCaTotalCupom30(false);
    })
  }
  
  function geraTotalCupom30(dados){
    //***Inicio do Grafico Ultimos 30 dias
  
    var formataQtde = [];
    var formataMes = [];
  
    dados.map( item => { 
        formataQtde.push(item.total);
        formataMes.push(item.data);
      
      return null
    })
   
    setTotalCupom30Qtde(formataQtde);
    setTotalCupom30Dias(formataMes);
    //Fim***
  }
  
  const totalCupom30 = {
  labels: totalCupom30Dias,
  datasets: [
    {
      data: totalCupom30Qtde,
      fill: true,
      backgroundColor: "#e9d8e6",
      borderColor: "#8f4487"
    },
  ]
  };

  function getPedidoBairro30(){
    setCaPedidoBairro30(true); 

    var data = {
      restaurante: restaurante.value,
    };

    Api.post("painelrestaurante/pedidobairro30", data).then(rps => {
  
      geraPedidoBairro30(rps.data.obj);
     
      setCaPedidoBairro30(false);
    })
  }
  
  function geraPedidoBairro30(dados){
    //***Inicio do Grafico Ultimos 30 dias
  
    var formataQtde = [];
    var formataMes = [];
  
    dados.map( item => { 
        formataQtde.push(item.total);
        formataMes.push(item.Bairro);
      
      return null
    })
   
    setPedidoBairro30Qtde(formataQtde);
    setPedidoBairro30Dias(formataMes);
    //Fim***
  }
  
  const pedidoBairro30 = {
  labels: pedidoBairro30Dias,
  datasets: [
    {
      data: pedidoBairro30Qtde,
      fill: true,
      backgroundColor: "#ffec5b",
      borderColor: "#f4c430"
    },
  ]
  };  

  function getValorMes(){
    setCaValorMes(true); 

    var data = {
      restaurante: restaurante.value,
    };

    Api.post("painelrestaurante/valormes", data).then(rps => {
  
      geraValorMes(rps.data.obj);
     
      setCaValorMes(false);
    })
  }
  
  function geraValorMes(dados){
    //***Inicio do Grafico Ultimos 30 dias
  
    var formataQtde = [];
    var formataMes = [];
  
    dados.map( item => { 
        formataQtde.push(item.total);
        formataMes.push(item.data);
      
      return null
    })
   
    setValorMesQtde(formataQtde);
    setValorMesDias(formataMes);
    //Fim***
  }
  
  const valorMes = {
  labels: valorMesDias,
  datasets: [
    {
      data: valorMesQtde,
      fill: true,
      backgroundColor: "#ffec5b",
      borderColor: "#f4c430"
    },
  ]
  };  
      
    return (
        <> 

            {/*begin::Row*/}
            <div className="card card-custom">
              <div className="ml-10 mr-10 mt-10">    
                <div className="row">
                  <div className="col-md-10 form-group">
                    <label>Restaurante</label>
                    <Select
                      options={restauranteSelect}
                      placeholder="Selecione..." 
                      defaultValue={restauranteSelect.find(
                        x => x.value === restaurante
                      )}
                      onChange={(e) => { setRestaurante(e) }} 
                
                    /> 
                  </div>

                  <div className="col-md-2 form-group">
                  <label>&nbsp;</label><br />
                    <button className="btn btn-block btn-primary" onClick={e=>{filtrar()}}><i className="fas fa-filter" />Filtrar</button>
                  </div> 
                </div>     
              </div>
            </div>

            <br />

            {/*begin::Row*/}
            <div className="row">
                
                <div className="col-md-3">
                  <LoadingOverlay
                    active={caTicketMedio}
                    spinner
                    text='Carregando...'
                  >
                    <div className="card card-custom gutter-b" style={{height: '160px'}}>
                    <div className="card-body">
                      <span className="svg-icon svg-icon-3x svg-icon-primary">
                        {/*begin::Svg Icon | path:assets/media/svg/icons/Communication/Group.svg*/}
                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                          <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                            <polygon points="0 0 24 0 24 24 0 24" />
                            <path d="M18,14 C16.3431458,14 15,12.6568542 15,11 C15,9.34314575 16.3431458,8 18,8 C19.6568542,8 21,9.34314575 21,11 C21,12.6568542 19.6568542,14 18,14 Z M9,11 C6.790861,11 5,9.209139 5,7 C5,4.790861 6.790861,3 9,3 C11.209139,3 13,4.790861 13,7 C13,9.209139 11.209139,11 9,11 Z" fill="#000000" fillRule="nonzero" opacity="0.3" />
                            <path d="M17.6011961,15.0006174 C21.0077043,15.0378534 23.7891749,16.7601418 23.9984937,20.4 C24.0069246,20.5466056 23.9984937,21 23.4559499,21 L19.6,21 C19.6,18.7490654 18.8562935,16.6718327 17.6011961,15.0006174 Z M0.00065168429,20.1992055 C0.388258525,15.4265159 4.26191235,13 8.98334134,13 C13.7712164,13 17.7048837,15.2931929 17.9979143,20.2 C18.0095879,20.3954741 17.9979143,21 17.2466999,21 C13.541124,21 8.03472472,21 0.727502227,21 C0.476712155,21 -0.0204617505,20.45918 0.00065168429,20.1992055 Z" fill="#000000" fillRule="nonzero" />
                          </g>
                        </svg>
                        {/*end::Svg Icon*/}
                      </span>
                      <div className="text-dark font-weight-bolder font-size-h2 mt-3">{ticketMedio}</div>
                      <label className="text-muted font-weight-bold font-size-lg mt-1">Ticket Médio </label>
                    </div>
                  </div>
                  {/*end::Tiles Widget 12*/}
                  </LoadingOverlay>
                </div>

              </div>
              {/*end::Row*/}


              {/*begin::Row*/}
              <div className="row">
                <div className="col-xl-12">
                <LoadingOverlay
                    active={caValorTransacionado30}
                    spinner
                    text='Carregando...'
                  >
                {/*begin::Base Table Widget 1*/}
                <div className="card card-custom card-stretch gutter-b">
                  {/*begin::Header*/}
                  <div className="card-header border-0 pt-5">
                    <h3 className="card-title align-items-start flex-column">
                      <span className="card-label font-weight-bolder text-dark">Valor Transacionado no mês - 30D</span>
                    </h3>
                  </div>
                  {/*end::Header*/}
                  {/*begin::Body*/}
                    <div className="card-body pt-2 pb-0 mt-n3">
                      {/*begin::Table*/}
                      <div className="row">
                        
                        <div className="col-md-12">
                          <Line data={dataValorTransacionado} legend={legenda} options={options} />
                        </div>

                      </div>
    
                    </div>
                    {/*end::Tap pane*/}
                </div>
                {/*end::Base Table Widget 1*/} 
                </LoadingOverlay> 
                </div>
              </div>
              {/*end::Row*/}


              {/*begin::Row*/}
              <div className="row">
                <div className="col-xl-6">
                <LoadingOverlay
                    active={caTotalPedido30}
                    spinner
                    text='Carregando...'
                  >
                {/*begin::Base Table Widget 1*/}
                <div className="card card-custom card-stretch gutter-b">
                  {/*begin::Header*/}
                  <div className="card-header border-0 pt-5">
                    <h3 className="card-title align-items-start flex-column">
                      <span className="card-label font-weight-bolder text-dark">Total de pedidos por dia </span>
                    </h3>
                  </div>
                  {/*end::Header*/}
                  {/*begin::Body*/}
                    <div className="card-body pt-2 pb-0 mt-n3">
                      {/*begin::Table*/}
                      <div className="row">
                        
                        <div className="col-md-12">
                          <Line data={totalPedido30} legend={legenda} options={options} />  
                        </div>

                      </div>
    
                    </div>
                    {/*end::Tap pane*/}
                </div>
                {/*end::Base Table Widget 1*/} 
                </LoadingOverlay> 
                </div>
                
                <div className="col-xl-6">                
                  <LoadingOverlay
                      active={caTotalCupom30}
                      spinner
                      text='Carregando...'
                    >
                  {/*begin::Base Table Widget 1*/}
                  <div className="card card-custom card-stretch gutter-b">
                    {/*begin::Header*/}
                    <div className="card-header border-0 pt-5">
                      <h3 className="card-title align-items-start flex-column">
                        <span className="card-label font-weight-bolder text-dark">Total de cupons</span>
                      </h3>
                    </div>
                    {/*end::Header*/}
                    {/*begin::Body*/}
                    <div className="card-body pt-2 pb-0 mt-n3">
                        {/*begin::Table*/}
                        <div className="row">
                          
                          <div className="col-md-12">
                            <Line data={totalCupom30} legend={legenda} options={options} />  
                          </div>

                        </div>

                          
                        </div>
                        {/*end::Tap pane*/}
                  </div>
                  {/*end::Base Table Widget 1*/} 
                  </LoadingOverlay> 
                </div>

              </div>
              {/*end::Row*/}

              {/*begin::Row*/}
              <div className="row">
                <div className="col-xl-12">
                <LoadingOverlay
                    active={caPedidoBairro30}
                    spinner
                    text='Carregando...'
                  >
                {/*begin::Base Table Widget 1*/}
                <div className="card card-custom card-stretch gutter-b">
                  {/*begin::Header*/}
                  <div className="card-header border-0 pt-5">
                    <h3 className="card-title align-items-start flex-column">
                      <span className="card-label font-weight-bolder text-dark">Pedido por bairro</span>
                    </h3>
                  </div>
                  {/*end::Header*/}
                  {/*begin::Body*/}
                    <div className="card-body pt-2 pb-0 mt-n3">
                      {/*begin::Table*/}
                      <div className="row">
                        
                        <div className="col-md-12">
                          <Bar data={pedidoBairro30} options={options} />
                        </div>

                      </div>
    
                    </div>
                    {/*end::Tap pane*/}
                </div>
                {/*end::Base Table Widget 1*/} 
                </LoadingOverlay> 
                </div>
              </div>
              {/*end::Row*/}

              {/*begin::Row*/}
              <div className="row">
                <div className="col-xl-12">
                <LoadingOverlay
                    active={caValorMes}
                    spinner
                    text='Carregando...'
                  >
                {/*begin::Base Table Widget 1*/}
                <div className="card card-custom card-stretch gutter-b">
                  {/*begin::Header*/}
                  <div className="card-header border-0 pt-5">
                    <h3 className="card-title align-items-start flex-column">
                      <span className="card-label font-weight-bolder text-dark">Valor mes a mes</span>
                    </h3>
                  </div>
                  {/*end::Header*/}
                  {/*begin::Body*/}
                    <div className="card-body pt-2 pb-0 mt-n3">
                      {/*begin::Table*/}
                      <div className="row">
                        
                        <div className="col-md-12">
                          <Line data={valorMes} legend={legenda} options={options} />
                        </div>

                      </div>
    
                    </div>
                    {/*end::Tap pane*/}
                </div>
                {/*end::Base Table Widget 1*/} 
                </LoadingOverlay> 
                </div>
              </div>
              {/*end::Row*/}            

        </>
        );
    }
    
    export default withRouter(Painel);                  
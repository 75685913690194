import React, { memo, useEffect, useMemo, useState } from "react";

import { Controller, useForm } from "react-hook-form";
import LoadingOverlay from "react-loading-overlay";
import DataTable from "react-data-table-component";
import ReactTooltip from "react-tooltip";
import { useToasts } from "react-toast-notifications";

import { AsyncSelect } from "../AsyncSelect";

import { useFetchCities, useMerchantManager } from "../../hooks";
import { useSetupAreaTableColumns } from "./useSetupAreaTableColumns";

import { stringUtil } from "utils";
import { Input, Select } from "components";

import Swal from "sweetalert2";

import { useFetchAreasList } from "useCases/areaUseCases/useFetchAreasList";
import { useAddNewArea } from "useCases/areaUseCases/useAddNewArea";
import { useDeleteArea } from "useCases/areaUseCases/useDeleteArea";

import * as Styled from "./styles";

const AreasTabComponent = ({
  merchantId,
  caProcessa,
  listaBairrosAtendidos,
  listarBairrosAtendidos,
}) => {
  const [cityId, setCityId] = useState(null);

  const { register, handleSubmit, control, watch, resetField } = useForm({
    mode: "onChange",
    defaultValues: {
      id_estabelecimento: merchantId,
      id_bairro: null,
      valor_minimo: null,
      valor_taxa: null,
      valor_frete_gratis: null,
    },
  });

  const { availableAreas, isError, isLoading } = useFetchAreasList(cityId);

  const { addNewArea, isLoading: isLoadingAddNewArea } = useAddNewArea({
    onSuccess: (data) => {
      addToast(data.mensagem, {
        appearance: "success",
        autoDismiss: true,
        autoDismissTimeout: 2000,
      });
      listarBairrosAtendidos(merchantId);
    },

    onError: (error) =>
      Swal.fire({
        title: "Erro!",
        icon: "error",
        html: error.erros,
        showCloseButton: true,
        showCancelButton: false,
      }),
  });

  const { deleteArea } = useDeleteArea({
    onSuccess: () => {
      addToast("Bairro excluído", {
        appearance: "success",
        autoDismiss: true,
        autoDismissTimeout: 2000,
      });
      listarBairrosAtendidos(merchantId);
    },

    onError: () =>
      addToast(
        "Algo deu errado ao excluir o bairro. Tente novamente mais tarde",
        {
          appearance: "error",
          autoDismiss: true,
          autoDismissTimeout: 2000,
        }
      ),
  });

  const { columnsBairros } = useSetupAreaTableColumns(deleteServedArea);

  const [id_bairro] = watch(["id_bairro"]);

  const { fetchCities } = useFetchCities();

  const { getValues } = useMerchantManager();

  const agentId = getValues("id_representante");

  const { addToast } = useToasts();

  const blockedSubmit = !id_bairro ? "É necessário selecionar um bairro." : "";

  const formattedAreas = useMemo(
    () =>
      availableAreas?.bairros.map((item) => {
        return {
          value: item.id_bairro,
          label: item.nome_bairro,
        };
      }),
    [availableAreas]
  );

  function addServedArea(data) {
    addNewArea(data);
  }

  function deleteServedArea(area) {
    deleteArea(area);
  }

  useEffect(() => {
    if (isError) {
      addToast(
        "Algo deu errado ao carregar a lista de bairros. Tente novamente mais tarde",
        {
          appearance: "error",
          autoDismiss: true,
          autoDismissTimeout: 2000,
        }
      );
    }
  }, [addToast, isError]);

  return (
    <div
      className="tab-pane fade"
      id="bairros"
      role="tabpanel"
      aria-labelledby="bairros"
    >
      <Styled.FormWrapper onSubmit={handleSubmit(addServedArea)}>
        <Styled.LocationInputsWrapper>
          <Styled.SelectWrapper>
            <label>
              Cidade <span>*</span>
            </label>

            <AsyncSelect
              placeholder="Selecione"
              loadOptions={fetchCities}
              loadingMessage={() => "Carregando..."}
              noOptionsMessage={() => "Cidade não encontrada"}
              onChange={(event) => {
                resetField("id_bairro");
                setCityId(event.value);
              }}
            />
          </Styled.SelectWrapper>

          <Styled.SelectWrapper>
            <label>
              Bairro <span>*</span>
            </label>

            <LoadingOverlay active={isLoading} spinner text="Carregando...">
              <Controller
                name="id_bairro"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    options={formattedAreas}
                    placeholder="Selecione uma cidade para carregar!"
                    noOptionsMessage={() => "Bairro não encontrado"}
                    defaultValue={formattedAreas?.find(
                      (x) => x.value === agentId
                    )}
                  />
                )}
              />
            </LoadingOverlay>
          </Styled.SelectWrapper>
        </Styled.LocationInputsWrapper>

        <Styled.MonetaryInputsWrapper>
          <Styled.InputWrapper>
            <Input
              label="Compra Mínima"
              labelSize={12}
              maxWidth={100}
              precision={"2"}
              placeholder="R$"
              type="text"
              {...register("valor_minimo", {
                onChange: (event) => {
                  const { value } = event.target;
                  event.target.value = stringUtil.maskToRealCurrency(value);
                },
              })}
            />
          </Styled.InputWrapper>

          <Styled.InputWrapper>
            <Input
              label="Taxa de Entrega"
              labelSize={12}
              maxWidth={100}
              precision={"2"}
              placeholder="R$"
              decimalSeparator="."
              type="text"
              {...register("valor_taxa", {
                onChange: (event) => {
                  const { value } = event.target;
                  event.target.value = stringUtil.maskToRealCurrency(value);
                },
              })}
            />
          </Styled.InputWrapper>

          <Styled.InputWrapper>
            <Input
              label="Frete Grátis em"
              labelSize={12}
              maxWidth={100}
              precision={"2"}
              placeholder="R$"
              decimalSeparator="."
              type="text"
              {...register("valor_frete_gratis", {
                onChange: (event) => {
                  const { value } = event.target;
                  event.target.value = stringUtil.maskToRealCurrency(value);
                },
              })}
            />
          </Styled.InputWrapper>
        </Styled.MonetaryInputsWrapper>

        <ReactTooltip />
        <Styled.SubmitButtonWrapper>
          <button
            className="btn-success"
            type="submit"
            disabled={!id_bairro}
            data-tip={blockedSubmit}
          >
            {isLoadingAddNewArea ? (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              <>
                <i className="fas fa-plus" />
                <p>Adicionar</p>
              </>
            )}
          </button>
        </Styled.SubmitButtonWrapper>
      </Styled.FormWrapper>

      <LoadingOverlay active={isLoading} spinner text="Carregando...">
        <DataTable
          title="Bairros Cadastrados"
          noDataComponent="Nenhum bairro cadastrado."
          columns={columnsBairros}
          data={listaBairrosAtendidos}
          striped
          pagination
        />
      </LoadingOverlay>
    </div>
  );
};

export const AreasTab = memo(AreasTabComponent);

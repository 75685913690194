import { useMutation } from "@tanstack/react-query";
import { areaService } from "services";

export const useDeleteArea = ({ onSuccess, onError }) => {
  const mutation = useMutation({
    mutationFn: (area) => areaService.deleteArea(area),

    onSuccess: (data) => {
      onSuccess?.(data);
    },

    onError: (error) => {
      onError?.(error);
    },
  });

  const deleteArea = (area) => {
    mutation.mutate(area);
  };

  return {
    deleteArea,
    isLoading: mutation.isPending,
    isError: mutation.isError,
  };
};

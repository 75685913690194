import * as Styled from "../styles";

export const Header = ({ title, description, onClose }) => {
  return (
    <Styled.HeaderContainer>
      <div>
        <Styled.Title>{title}</Styled.Title>
        <Styled.Description>{description}</Styled.Description>
      </div>

      <Styled.ButtonContainer type="button" onClick={onClose}>
        <Styled.CloseIcon />
      </Styled.ButtonContainer>
    </Styled.HeaderContainer>
  );
};

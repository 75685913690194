import styled from 'styled-components';

export const Container = styled.div`
  /*.list-group{
      display: flex;
      flex-direction: column;
      padding-left: 0;
      margin-bottom: 0;
      height: 600px;
      overflow-y: auto;
  }*/

  .conexaoOk{
      color: green !important;
  }
  .conexaoNok{
      color: red !important;
  }
`;

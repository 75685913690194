import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    align-items: flex-end;
    height: 100px;
    width: 100%;
    gap: 24px;
    padding-bottom: 26px;
`;

import React, { useState, useEffect } from 'react';
import Api from '../../../services/api';
import { withRouter } from "react-router";

import Swal from "sweetalert2";

import DataTable from 'react-data-table-component';
import Select from 'react-select';
import LoadingOverlay from 'react-loading-overlay';
import { Button } from '@material-ui/core';
import { Modal } from "react-bootstrap";

import "react-datepicker/dist/react-datepicker.css";
import DatePicker, { registerLocale } from "react-datepicker";
import InputMask from 'react-input-mask';
import pt from "date-fns/locale/pt-BR"; 
registerLocale("pt-BR", pt);

const RelAbertura = () => {
  var d = new Date();
  d.setDate(d.getDate() - 60);
  
  const [ pedidos, setPedidos ] = useState([]);
  const [ cidade, setCidade ] = useState('0');
  const [ cidadeSelect, setCidadeSelect ] = useState([]);
  const [ representante, setRepresentante ] = useState('0');
  const [ representanteSelect, setRepresentanteSelect ] = useState([]);
  const [ dataInicial, setDataInicial ] = useState(d);
  const [ dataFinal, setDataFinal ] = useState(new Date());
  const [ caProcessa, setCaProcessa ] = useState(false);
  const [ caProcessaModal, setCaProcessaModal ] = useState(false);
  const [ modal, setModal ] = useState(false);
  const [ pedidosEst, setPedidosEst ] = useState([]);
 
  const data = pedidos;
  const columns = [
    {
      name: <th>ID Est.</th>,
      selector: 'id_estabelecimento',
      sortable: true,
      width: '8%',
      right: 'true',
    },
    {
      name: <th>Nome Estabelecimento</th>,
      selector: 'nome',
      sortable: true,
      width: '20%',
    },
    {
      name: <th>Representante</th>,
      selector: 'rep',
      sortable: true,
      width: '18%',
    },
    {
      name: <th>Ped. Efetivados</th>,
      selector: 'Efetivadas',
      sortable: true,
      width: '12%',
      right: 'true',
    },
    {
      name: <th>Ped. Cancelados</th>,
      selector: 'Canceladas',
      sortable: true,
      width: '12%',
      right: 'true',
    },
    {
      name: <th>Porcetagem</th>,
      selector: 'porcetagem',
      sortable: true,
      width: '12%',
      right: 'true',
      cell: row => { if (row.porcetagem > 10) 
        { return <div><b className="text-danger">{row.porcetagem}%</b></div> }
        else
        { return <div><b className="text-success">{row.porcetagem}%</b></div> }
      }
    },
    {
      name: <th>Ações</th>,
       
      cell: (row) => <Button className="btn btn-info" onClick={e => {abrirModal(row.id_estabelecimento)}}>Ver Vendas</Button>,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      width: '17%',
      right: true,
    },
  ];

  //Tabela com os pedidos
  const dataPedidos = pedidosEst;
  const columnsPedidos = [
    {
      name: <th>Hash</th>,
      selector: 'hash_pedido',
      sortable: true,
      width: '12%',
      center: 'true',
    },
    {
      name: <th>Nome usuário</th>,
      selector: 'usuario_nome',
      sortable: true,
      width: '20%',
    },
    {
      name: <th>Telefone usuário</th>,
      selector: 'usuario_telefone',
      sortable: true,
      width: '15%',
    },
    {
      name: <th>Valor total</th>,
      selector: 'valor_total',
      sortable: true,
      width: '10%',
      right: 'true',
    },
    {
      name: <th>Motivo Cancelamento</th>,
      selector: 'motivo_cancelamento',
      sortable: true,
      cell: row => <div className="text-danger">{row.motivo_cancelamento}</div>,
    },
  ];

  useEffect(() => {
    getCidadeSelect();
    getRepresentanteSelect();
  }, []);

  function abrirModal(id_estabelecimento){ 
    setCaProcessaModal(true);
    setModal(true); 
      var data = {
        estabelecimento: id_estabelecimento,
        data_inicio: dataInicial,
        data_final: dataFinal,
      }; 
      Api.post('relatorios/vendas', data).then((rps) => {
        setPedidosEst(rps.data.obj);
        setCaProcessaModal(false);
      });
  }

  function fecharModal(){
    setModal(false);
  }

  function getCidadeSelect() {
    Api.get("cidades/listar").then(rps => {
      var cid = rps.data.cidades;
      var nCid = [];
      nCid[nCid.length] = {value: '', label: 'Selecione...'};
      cid.forEach(e => {
        nCid[nCid.length] = {value: e.id_cidade, label: e.nome_cidade + ' - ' + e.estado};
      })
      setCidadeSelect(nCid);
    })
  }

  function getRepresentanteSelect() {
    Api.get("representantes/listar").then(rps => {
      var rep = rps.data.representantes;
      var nRep = [];
      nRep[nRep.length] = {value: '', label: 'Selecione...'};
      rep.forEach(e => {
        nRep[nRep.length] = {value: e.id_representante, label: e.nome};
      })
      setRepresentanteSelect(nRep);
    })
  }

  function filtrar() {
    setCaProcessa(true); 
      var data = {
        cidade: cidade.value,
        data_inicio: dataInicial,
        data_final: dataFinal,
        representante: representante.value,
      };
   
      Api.post('relatorios/aberturaEstabelecicmento', data).then((rps) => {

        if(rps.data.status==false){
          Swal.fire({
            title: "Atenção!",
            icon: "info",
            html: rps.data.erro,
            showCloseButton: true,
            showCancelButton: false,
          });
        } else {
          setPedidos(rps.data.obj);
        }
      setCaProcessa(false); 
    });
  }

    
    return (
        <>
<div>

<div className="subheader espaco-header-pedidos subheader-transparent" id="kt_subheader">
          <div className="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
            {/*begin::Details*/}
            <div className="d-flex align-items-center flex-wrap mr-2">
              {/*begin::Title*/}
              <h5 className="text-dark font-weight-bold mt-2 mb-2 mr-5">Relatório</h5>
              {/*end::Title*/}
              {/*begin::Separator*/}
              <div className="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200" />
              {/*end::Separator*/}
              {/*begin::Title*/}
              <h5 className="text-dark font-weight-bold mt-2 mb-2 mr-5">Abertura de Estabelecimentos</h5>
              {/*end::Title*/}
              {/*begin::Separator*/}
              <div className="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200" />
              {/*end::Separator*/}
            </div>
            {/*end::Details*/}
            {/*begin::Toolbar*/}
            <div className="d-flex align-items-center">

            </div>
            {/*end::Toolbar*/}
          </div>
        </div>
        {/*begin::Entry*/}
        <div className="d-flex flex-column-fluid">
          {/*begin::Container*/}
          <div className="container">
            <div className="card card-custom">
              <div className="ml-10 mr-10 mt-10">    
                <div className="row">
                  <div className="col-md-4 form-group">
                    <label>Cidade</label>
                    <Select
                      options={cidadeSelect}
                      placeholder="Selecione..." 
                      defaultValue={cidadeSelect.find(
                        x => x.value === cidade
                      )}
                      onChange={(e) => { setCidade(e) }} 
                
                    /> 
                  </div>
                  <div className="col-md-3 form-group">
                    <label>Representante</label>
                    <Select
                      options={representanteSelect}
                      placeholder="Selecione..." 
                      defaultValue={representanteSelect.find(
                        x => x.value === representante
                      )}
                      onChange={(e) => { setRepresentante(e) }} 
                
                    /> 
                  </div>

                  <div className="col-md-2 form-group">
                    <label>Data de</label>
                      <div className="input-group">
                      <DatePicker 
                        name="dt_inicio"
                        onChange={setDataInicial}
                        isClearable
                        selectsStart
                        locale="pt-BR"
                        className="form-control"
                        placeholderText="dd/mm/aaaa" 
                        selected ={dataInicial}
                        dateFormat="dd/MM/y"
                        startDate={dataInicial}
                        endDate={dataFinal}
                        customInput={
                          <InputMask mask="99/99/9999" />}/>
                      </div>
                  </div>

                  <div className="col-md-2 form-group">
                    <label>Data até</label>
                      <div className="input-group">
                      <DatePicker 
                        name="dt_final"
                        onChange={setDataFinal}
                        isClearable
                        locale="pt-BR"
                        className="form-control"
                        placeholderText="dd/mm/aaaa" 
                        selected ={dataFinal}
                        dateFormat="dd/MM/y"
                        selectsEnd
                        startDate={dataInicial}
                        endDate={dataFinal}
                        minDate={dataInicial} 
                        customInput={
                          <InputMask mask="99/99/9999" />}/>
                      </div>
                  </div> 

                  <div className="col-md-1 form-group">
                  <label>&nbsp;</label><br />
                    <button className="btn btn-block btn-primary" onClick={e=>{filtrar()}}>Filtrar</button>
                  </div> 
                </div>     
              </div>

              <LoadingOverlay
                            active={caProcessa}
                            spinner
                            text='Carregando...'
                          >                     
              <DataTable
                title="Pedidos por cidade"
                columns={columns}
                data={data}
                striped="true"
                pagination="true"
              />
              </LoadingOverlay> 

                    
      
                  </div>
                  </div>
                  </div>
                  </div>
                  
    <Modal size={'xl'} show={modal} onHide={() => fecharModal()}>
      <Modal.Header>
      <Modal.Title>Detalhes das Vendas</Modal.Title>
          <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={e => {fecharModal()}}>
            <i aria-hidden="true" className="ki ki-close" />
          </button>
      </Modal.Header>
      <div>
      <LoadingOverlay
                            active={caProcessaModal}
                            spinner
                            text='Carregando...'
                          >                     
              <DataTable
                title="Pedidos por estabelecimento"
                columns={columnsPedidos}
                data={dataPedidos}
                striped="true"
                pagination="true"
              />
              </LoadingOverlay>                    
      </div>
      <Modal.Footer>
        <button type="button" onClick={e => { fecharModal(); }} className="btn btn-light-primary font-weight-bold" data-dismiss="modal">Fechar</button>
      </Modal.Footer>
    </Modal>         
        </>
    );

}



export default withRouter(RelAbertura);                  
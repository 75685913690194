import { useState } from "react";
import { useHistory } from "react-router";
import { useToasts } from "react-toast-notifications";
import { authService } from "services";

export const useTwoFactorAuth = () => {
  const [code, setCode] = useState("");
  const [twoFactorAuthData, setTwoFactorAuthData] = useState({
    otpUri: "",
    hasOtpSecret: false,
  });
  const [isTwoFactorAuthModalVisible, setIsTwoFactorAuthModalVisible] =
    useState(false);
  const [isLoadingCodeVerification, setIsLoadingVerificationCode] =
    useState(false);

  const { addToast } = useToasts();
  const history = useHistory();

  const USER_ALREADY_HAS_TWO_FACTOR_AUTH_INSTALLED =
    twoFactorAuthData.otpUri === "2fa_already_installed";

  const handleVerifyOtpCode = async () => {
    try {
      setIsLoadingVerificationCode(true);

      const response = await authService.verifyOtp(code);

      if (!response.code_is_valid) {
        addToast("O código inserido está incorreto.", {
          appearance: "error",
          autoDismiss: true,
          autoDismissTimeout: 2000,
        });

        setIsLoadingVerificationCode(false);
        return;
      }

      authService.updateTwoFactorAuthToken(response.user_otp_secret);

      setIsLoadingVerificationCode(false);

      history.push("/painel2");
    } catch {
      addToast("Ocorreu um erro. Por favor, tente novamente.", {
        appearance: "error",
        autoDismiss: true,
        autoDismissTimeout: 2000,
      });

      setIsLoadingVerificationCode(false);
    }
  };

  const handleAutoSubmit = (event) => {
    if (event.key === "Enter") {
      handleVerifyOtpCode();
    }
  };

  return {
    code,
    setCode,
    twoFactorAuthData,
    setTwoFactorAuthData,
    isTwoFactorAuthModalVisible,
    setIsTwoFactorAuthModalVisible,
    handleVerifyOtpCode,
    isLoadingCodeVerification,
    handleAutoSubmit,
    USER_ALREADY_HAS_TWO_FACTOR_AUTH_INSTALLED,
  };
};

import React, { useState, useEffect } from 'react';
import Api from '../../../services/api';
import { withRouter } from "react-router";

import Swal from "sweetalert2";
import { useToasts } from 'react-toast-notifications';
import LoadingOverlay from 'react-loading-overlay';

import DataTable from 'react-data-table-component';
import { Modal } from "react-bootstrap";
import Select from 'react-select';

import { Link } from 'react-router-dom';

const Categorias = () => {
  const { addToast } = useToasts();
  const [ locais, setLocais ] = useState([]);
  const [ caProcessa, setCaProcessa ] = useState(false);
  const [ buscaEstado, setBuscaEstado ] = useState();
  const [ buscaCidade, setBuscaCidade ] = useState();
  const [ buscaCodigoIbge, setBuscaCodigoIbge ] = useState();
  const [ buscaStatus, setBuscaStatus ] = useState();
  const [ buscaOculto, setBuscaOculto ] = useState();
  const [ buscaDesconto, setBuscaDesconto ] = useState();
  const [ modal, setModal ] = useState(false);
  const [ formLocal, setFormLocal ] = useState({});
  const [ representanteSelect, setRepresentanteSelect ] = useState([]);
  const [ alterouConcorrentes, setAlterouConcorrentes ] = useState('Não');
  let acesso = JSON.parse(localStorage.getItem('@ADM-Aiboo/perfil'));

  function fecharModal(){
    setFormLocal({});
    setModal(false);
  }

  const concorrentes = [
    { value: 'aiqfome', label: 'Aiqfome' },
    { value: 'bigou', label: 'Bigou' },
    { value: 'deliverymuch', label: 'Delivery Much' },
    { value: 'ifood', label: 'iFood' },
    { value: 'maisdelivery', label: 'Mais Delivery' },
    { value: 'pedeai', label: 'Pede.ai' },
    { value: 'plusdelivery', label: 'Plus Delivery' },
    { value: 'querodelivery', label: 'Quero Delivery' },
    { value: 'rapidaoapp', label: 'Rapidão App' },
    { value: 'uairango', label: 'Uai Rango' },
  ]

  const data = locais;
  const columns = [
    {
      name: <th>Estado</th>,
      selector: 'estado',
      sortable: true,
      width: '10%',
      center: true,
    },
    {
      name: <th>Cidade</th>,
      selector: 'nome_cidade',
      width: '21%',
      sortable: true,
    },
    {
      name: <th>Código IBGE</th>,
      selector: 'codigo_ibge',
      width: '10%',
      sortable: true,
    },
    {
      name: <th>Habitantes</th>,
      selector: 'habitantes',
      width: '10%',
      sortable: true,
    },
    {
      name: <th>Status</th>,
      width: '8%',
      sortable: true,
      selector: 'status',
      center: 'true',
      cell: row => { if (row.status == "S") {return <span class="label label-success label-inline mr-2">Ativo</span>}
        else {return <span class="label label-secondary label-inline mr-2">Desativado</span>}
      }
    },
    {
      name: <th>Oculto</th>,
      width: '8%',
      sortable: true,
      selector: 'oculto',
      center: 'true',
      cell: row => { if (row.oculto != "S") {return <span class="label label-secondary label-inline mr-2">Não</span>}
        else {return <span class="label label-danger label-inline mr-2">Sim</span>}
      }
    },
    {
      name: <th>Desconto</th>,
      width: '8%',
      sortable: true,
      selector: 'desconto',
      center: 'true',
      cell: row => { if (row.desconto != "1") {return <span class="label label-secondary label-inline mr-2">Não</span>}
        else {return <span class="label label-danger label-inline mr-2">Sim</span>}
      }
    },
    {
      name: <th>Data Criação</th>,
      width: '15%',
      sortable: true,
      selector: 'data_criacao',
      center: 'true'
    }, 
    {
      name: <th>Ações</th>,
      cell: (row) => 
      { return (<>
      <div class="dropdown dropdown-inline">
      <button type="button" class="btn btn-light-primary btn-icon btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <i class="ki ki-bold-more-ver"></i>
      </button>
      <div class="dropdown-menu">
        <span class="dropdown-item" onClick={e => { editarLocal(row) }}>Editar</span>
        <Link to='/estabelecimentos/locais/bairros' className="dropdown-item" onClick={e => { gerenciarLocal(row.id_cidade) }}>Gerenciar</Link>
        {acesso.est_ger_loc_ei === "1" && <>
        <div class="dropdown-divider"></div>
        <span class="dropdown-item" onClick={e => { inutilizarLocal(row) }}>Inutilizar</span>
        </>}
      </div>
    </div>
    </>)
      },
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      right: true,
    },
  ];

   useEffect(() => {
    getListar();
    getRepresentanteSelect();
  }, []);

  function getListar() { 
    setCaProcessa(true); 
    Api.get("cidades/listar").then(rps => {
      setLocais(rps.data.cidades);
      setCaProcessa(false);
    })
  }

  function getRepresentanteSelect() {
    Api.get("estabelecimentos/listarepresentante").then(rps => {
      var rep = rps.data.obj;
      var nRep = [];
      nRep[nRep.length] = { value: '', label: 'Selecione...' };
      rep.forEach(e => {
        nRep[nRep.length] = { value: e.id_representante, label: e.nome };
      })
      setRepresentanteSelect(nRep);
    })
  }
  
  function adicionarLocal(){
    setFormLocal({...formLocal, desconto: 0});
    setModal(true);
  }

  function editarLocal(local){  
    var concorrentes = "";
    try{
      concorrentes = JSON.parse(local.concorrentes);
      local.concorrentes1 = concorrentes;
    } catch(e){
      local.concorrentes1 = [];
    }

    var ca = JSON.parse(JSON.stringify(local));
    setAlterouConcorrentes('Não');
    setFormLocal(ca);
    setModal(true);            
  }

  function gerenciarLocal(cidade){  
    sessionStorage.setItem('id_cidade', cidade);         
  }

  function salvar(){
    setCaProcessa(true);
    
    if (alterouConcorrentes == 'Sim') {
      formLocal.concorrentes = JSON.stringify(formLocal.concorrentes);
    }

    Api.post('cidades/adicionar', formLocal).then(rps => {
      if (rps.data.status === true) {
        addToast(rps.data.mensagem, {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 2000
        });
        setModal(false);
        setFormLocal({});
        getListar();
      } else {
        Swal.fire({
          title: "Erro!",
          icon: "error",
          html: rps.data.erros,
          showCloseButton: true,
          showCancelButton: false,
        });
      }
      setCaProcessa(false);
    });
  }

  function habitantesData() {
    const data = new Date();
  
    return data.toLocaleDateString();
  }

  function filtrar() {
    setCaProcessa(true); 
      var data = {
        estado: buscaEstado,
        nome_cidade: buscaCidade,
        codigo_ibge: buscaCodigoIbge,
        status: buscaStatus,
        oculto: buscaOculto,
        desconto: buscaDesconto,
      };
    
      Api.post('cidades/filtrar', data).then((rps) => {

        if(rps.data.status===false){
          Swal.fire({
            title: "Atenção!",
            icon: "info",
            html: rps.data.erro,
            showCloseButton: true,
            showCancelButton: false,
          });
          setLocais({});
        } else {
          setLocais(rps.data.obj);
        }
      setCaProcessa(false); 
    });
  }

  function inutilizarLocal(id_local) {
    var ca = JSON.parse(JSON.stringify(id_local));

    setCaProcessa(true);

    Swal.fire({
        icon: "question",
        title: 'Confirmação',
        html: 'Deseja inutilizar o registro?',
        showCancelButton: true,
        confirmButtonText: `Sim, Confirmar!`,
        cancelButtonText: 'Não, Cancelar.'
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        Api.post('cidades/inutilizar', ca).then(rps => {
          
          if(rps.data.status==true){
            addToast(rps.data.mensagem, {
              appearance: "success",
              autoDismiss: true,
              autoDismissTimeout: 2000
            });
            getListar();
          } 
        })
      } 
      setCaProcessa(false);
    })
  }
    
    return (
        <>
<div>

<div className="subheader espaco-header-pedidos subheader-transparent" id="kt_subheader">
          <div className="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
            {/*begin::Details*/}
            <div className="d-flex align-items-center flex-wrap mr-2">
              {/*begin::Title*/}
              <h5 className="text-dark font-weight-bold mt-2 mb-2 mr-5">Gerenciar Locais</h5>
              {/*end::Title*/}
              {/*begin::Separator*/}
              <div className="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200" />
              {/*end::Separator*/}
            </div>
            {/*end::Details*/}
            {/*begin::Toolbar*/}
            <div className="d-flex align-items-center">
              <button className="btn btn-lg btn-success mt-3 mb-3" onClick={e => {adicionarLocal()}}><i className="fas fa-plus" />Adicionar</button>
            </div>
            {/*end::Toolbar*/}
          </div>
        </div>
        {/*begin::Entry*/}
        <div className="d-flex flex-column-fluid">
          {/*begin::Container*/}
          <div className="container">
            <div className="card card-custom">
              <div className="ml-10 mr-10 mt-10">    
                <div className="row">
                <div className="form-group col">
                  <label>Estado</label>
                  <select className="form-control" value={buscaEstado} onChange={e => {setBuscaEstado(e.target.value)}}>
                    <option value="" selected>Selecione</option>    
                    <option value="AC">Acre</option>    
                    <option value="AL">Alagoas</option> 
                    <option value="AP">Amapá</option>    
                    <option value="AM">Amazonas</option>
                    <option value="BA">Bahia</option>    
                    <option value="CE">Ceará</option> 
                    <option value="DF">Distrito Federal</option>    
                    <option value="ES">Espírito Santo</option>    
                    <option value="GO">Goiás</option>
                    <option value="MA">Mato Grosso do Sul</option>    
                    <option value="MT">Mato Grosso</option> 
                    <option value="MS">Ativo</option>    
                    <option value="MG">Minas Gerais</option>
                    <option value="PA">Pará</option>    
                    <option value="PB">Paraíba</option> 
                    <option value="PR">Paraná</option>    
                    <option value="PE">Pernambuco</option>
                    <option value="PI">Piauí</option>    
                    <option value="RJ">Rio de Janeiro</option> 
                    <option value="RN">Rio Grande do Norte</option>    
                    <option value="RS">Rio Grande do Sul</option>
                    <option value="RO">Rondônia</option>    
                    <option value="RR">Roraima</option> 
                    <option value="SC">Santa Catarina</option>    
                    <option value="SP">São Paulo</option>
                    <option value="SE">Sergipe</option>    
                    <option value="TO">Tocantins</option> 
                  </select>
                </div>
                <div className="form-group col-md-3">
                  <label>Cidade</label>
                  <input type="text" value={buscaCidade} onChange={e => {setBuscaCidade(e.target.value)}} className="form-control" />
                </div>
                <div className="form-group col">
                  <label>Código IBGE</label>
                  <input type="text" value={buscaCodigoIbge} onChange={e => {setBuscaCodigoIbge(e.target.value)}} className="form-control" />
                </div>
                <div className="form-group col">
                  <label>Status</label>
                  <select className="form-control" value={buscaStatus} onChange={e => {setBuscaStatus(e.target.value)}}>
                    <option value="" selected>Selecione</option>    
                    <option value="S">Ativo</option>    
                    <option value="N">Desativado</option>    
                  </select>
                </div>
                <div className="form-group col">
                  <label>Oculto</label>
                  <select className="form-control" value={buscaOculto} onChange={e => {setBuscaOculto(e.target.value)}}>
                    <option value="" selected>Selecione</option>    
                    <option value="S">Sim</option>    
                    <option value="N">Não</option>    
                  </select>
                </div>
                <div className="form-group col">
                  <label>Desconto 10%</label>
                  <select className="form-control" value={buscaDesconto} onChange={e => {setBuscaDesconto(e.target.value)}}>
                    <option value="" selected>Selecione</option>    
                    <option value="1">Sim</option>    
                    <option value="0">Não</option>    
                  </select>
                </div>

                  <div className="col-md-2 form-group">
                  <label>&nbsp;</label><br />
                    <button className="btn btn-primary btn-block" onClick={e=>{filtrar()}}><i className="fas fa-filter" />Filtrar</button>
                  </div> 
                </div> 
              </div>

              <LoadingOverlay
                active={caProcessa}
                spinner
                text='Carregando...'
              >                 
                <DataTable
                  title="Lista de Locais"
                  columns={columns}
                  data={data}
                  striped="true" 
                  pagination="true"
                />
              </LoadingOverlay>                    
      
                  </div>
                  </div>
                  </div>
                  </div>
                  
      <Modal size={'xl'} show={modal} onHide={() => fecharModal()}>
      <Modal.Header>
      <Modal.Title>Locais</Modal.Title>
          <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={e => {fecharModal()}}>
            <i aria-hidden="true" className="ki ki-close" />
          </button>
      </Modal.Header>
      <div className="row mt-5 ml-5 mr-5">
        <div className="form-group col-md-2">
          <label>Estado</label>
          <select className="form-control" value={formLocal?.estado} onChange={e => {setFormLocal({...formLocal, estado: e.target.value})}}>
            <option value="" selected>Selecione</option>    
            <option value="AC">Acre</option>    
            <option value="AL">Alagoas</option> 
            <option value="AP">Amapá</option>    
            <option value="AM">Amazonas</option>
            <option value="BA">Bahia</option>    
            <option value="CE">Ceará</option> 
            <option value="DF">Distrito Federal</option>    
            <option value="ES">Espírito Santo</option>    
            <option value="GO">Goiás</option>
            <option value="MA">Mato Grosso do Sul</option>    
            <option value="MT">Mato Grosso</option> 
            <option value="MS">Ativo</option>    
            <option value="MG">Minas Gerais</option>
            <option value="PA">Pará</option>    
            <option value="PB">Paraíba</option> 
            <option value="PR">Paraná</option>    
            <option value="PE">Pernambuco</option>
            <option value="PI">Piauí</option>    
            <option value="RJ">Rio de Janeiro</option> 
            <option value="RN">Rio Grande do Norte</option>    
            <option value="RS">Rio Grande do Sul</option>
            <option value="RO">Rondônia</option>    
            <option value="RR">Roraima</option> 
            <option value="SC">Santa Catarina</option>    
            <option value="SP">São Paulo</option>
            <option value="SE">Sergipe</option>    
            <option value="TO">Tocantins</option> 
          </select>
        </div>

        <div className="form-group col-md-3">
          <label>Cidade</label>
          <input type="text" value={formLocal?.nome_cidade} onChange={e => {setFormLocal({...formLocal, nome_cidade: e.target.value})}} className="form-control" />
        </div>
        
        <div className="form-group col">
          <label>Código IBGE</label>
          <input type="text" value={formLocal?.codigo_ibge} onChange={e => {setFormLocal({...formLocal, codigo_ibge: e.target.value})}} className="form-control" />
        </div>

        <div className="form-group col text-center">
          <label>Habitantes</label>
          <input type="text" 
                 value={formLocal?.habitantes} 
                 onChange={e => {setFormLocal({...formLocal, habitantes: e.target.value, habitantes_at: habitantesData()})}}
                 className="form-control" />
          <small>{formLocal?.habitantes_at}</small>
        </div>

        <div className="form-group col">
          <label>Status</label>
          <select className="form-control" value={formLocal?.status} onChange={e => {setFormLocal({...formLocal, status: e.target.value})}}>
            <option value="" selected>Selecione</option>    
            <option value="S">Ativo</option>    
            <option value="N">Desativado</option>    
          </select>
        </div>

        <div className="form-group col">
          <label>Oculto</label>
          <select className="form-control" value={formLocal?.oculto} onChange={e => {setFormLocal({...formLocal, oculto: e.target.value})}}>
            <option value="" selected>Selecione</option>    
            <option value="S">Sim</option>    
            <option value="N">Não</option>    
          </select>
        </div>

        <div className="form-group col">
          <label>Desconto 10%</label>
          <select className="form-control" value={formLocal?.desconto} onChange={e => {setFormLocal({...formLocal, desconto: e.target.value})}}>
            <option value="" selected>Selecione</option>    
            <option value="1">Sim</option>    
            <option value="0">Não</option>    
          </select>
        </div>
      
      </div>

      <div className='row ml-5 mr-5'>
        <div className="form-group col-md-12">
          <label>Concorrentes</label>
          <Select options={concorrentes}
            placeholder="Selecione..."
            isMulti
            onChange={(e) => { setFormLocal({...formLocal, concorrentes: e }); setAlterouConcorrentes('Sim') }}
            defaultValue={formLocal.concorrentes1?.map((item) => {
              return (
                concorrentes.find(
                  x => x.value === item.value
                )
              )
            }
            )}
          />
        </div>
      </div>

      <div className='row ml-5 mr-5 mb-5'>
        <div className="col-md-12">
          <label>Representante</label>
          <Select
            options={representanteSelect}
            placeholder="Selecione..."
            defaultValue={representanteSelect.find(
              x => x.value === formLocal?.id_representante
            )}
            onChange={(e) => { setFormLocal({ ...formLocal, id_representante: e.value }) }}
          />
        </div>
      </div>    
      
      <Modal.Footer>
        <button type="button" onClick={e => { fecharModal(); }} className="btn btn-light-primary font-weight-bold" data-dismiss="modal"><i className="fas fa-door-open" />Fechar</button>
        <button type="button" onClick={e => { salvar(); }} className="btn btn-success font-weight-bold"><i className="fas fa-save" />Salvar</button>
      </Modal.Footer>
    </Modal>        
                 
        </>
    );
}

export default withRouter(Categorias);                  
import React, { useState, useEffect } from 'react';
import Api from '../../../services/api';
import { withRouter } from "react-router";

import Swal from "sweetalert2";
import { useToasts } from 'react-toast-notifications';
import LoadingOverlay from 'react-loading-overlay';
import { Modal } from "react-bootstrap";

import "./styles.css";

const Paginacao = ({ currentPage, pageCount, handlePageClick }) => {

  const renderRow = row => {
    return <span>{row}</span>;
  };

  let rows = [];

  if (currentPage !== 1) {
    rows.push(
      <span 
        onClick={() => handlePageClick(currentPage - 1)} 
        className="btn btn-icon btn-sm btn-light-primary mr-2 my-1">
        <i className="ki ki-bold-arrow-back icon-xs"></i>
      </span>
    );
  } else {
    rows.push(
      <span className="btn-icon btn-sm btn-secondary disabled mr-2 my-1">
        <i className="ki ki-bold-arrow-back icon-xs"></i>
      </span>
    );
  }

  if (currentPage > 3) {
    rows.push(
      <>
        <span 
          onClick={() => handlePageClick(1)} 
          className="btn btn-icon btn-sm btn-light-primary mr-2 my-1">
          1
        </span>
        <span className="btn-icon btn-sm btn-secondary disabled mr-2 my-1">
          ...
        </span>
      </>
    );
  }

  for (let i = currentPage - 2; i < currentPage + 3; i++) {
    if (i > 0 && i <= pageCount) {
      if (i === currentPage) {
        rows.push(
          <span 
            onClick={() => handlePageClick(i)} 
            className="btn btn-icon btn-sm border-0 btn-hover-primary active mr-2 my-1">
            {i}
          </span>
        );
      } else {
        rows.push(
          <span 
            onClick={() => handlePageClick(i)} 
            className="btn btn-icon btn-sm border-0 btn-hover-primary mr-2 my-1">
            {i}
          </span>
        );
      }
    }
  }

  if (currentPage + 3 <= pageCount) {
    rows.push(
      <>
        <span className="btn-icon btn-sm btn-secondary disabled mr-2 my-1">
          ...
        </span>
        <span 
          onClick={() => handlePageClick(pageCount)} 
          className="btn btn-icon btn-sm btn-light-primary mr-2 my-1">
          {pageCount}
        </span>
      </>
    );
  }

  if (currentPage !== pageCount) {
    rows.push(
      <span 
        onClick={() => handlePageClick(currentPage + 1)} 
        className="btn btn-icon btn-sm btn-light-primary mr-2 my-1">
        <i className="ki ki-bold-arrow-next icon-xs"></i>
      </span>
    );
  } else {
    rows.push(
      <span className="btn-icon btn-sm btn-secondary disabled mr-2 my-1">
        <i className="ki ki-bold-arrow-next icon-xs"></i>
      </span>
    );
  }

  if (rows.length === 0) {
    return <p>Nenhum item</p>;
  }

  return <div>{rows.map(renderRow)}</div>;
};


const Usuarios = () => {
  const { addToast } = useToasts();
  const [ usuarios, setUsuarios ] = useState([]);
  const [ totalUsuarios, setTotalUsuarios ] = useState();
  const [ caProcessa, setCaProcessa ] = useState(false);
  const [ buscaNome, setBuscaNome ] = useState();
  const [ buscaEmail, setBuscaEmail ] = useState();
  const [ buscaTelefone, setBuscaTelefone ] = useState();
  const [ buscaStatus, setBuscaStatus ] = useState();
  const [ formUsuario, setFormUsuario ] = useState({});
  const [ modalUsuario, setModalUsuario ] = useState(false);
  const [ listaEndereco, setListaEndereco ] = useState([]);
  const [ listaCupons, setListaCupons ] = useState([]);
  const [ listaTickets, setListaTickets ] = useState([]);
  const [ listaPedidos, setListaPedidos ] = useState([]);
  const [ ordenacao, setOrdenacao ] = useState('id_usuario');
  const [ ordenacaoSet, setOrdenacaoSet ] = useState();
  
  const [ currentPage, setCurrentPage ] = useState(1);
  const PER_PAGE = 10;
  const pageCount = Math.ceil(totalUsuarios / PER_PAGE);

  useEffect(() => {
    getListar();
  }, []);

  function getListar() { 
    setCaProcessa(true); 
    var data = {
      limite: PER_PAGE, 
      start: 0,
      ordenacao: 'id_usuario',
      sentido: 'ASC',
    }
    
    Api.post("usuarios/listarUsuario", data).then(rps => { 
      setUsuarios(rps.data.obj);
      setTotalUsuarios(rps.data.totalRegistros);
      setCaProcessa(false);
    })
  }

  function fecharModalUsuario(){
    setModalUsuario(false);
  }
  
   function filtrar(order) {

    var sentido = 'ASC';

    if (order != null) {
      setOrdenacao(order);
    } else {
      //Valor padrão para a primeira vezs
      setOrdenacao('id_usuario');
      order = 'id_usuario';
    }

    if (order === ordenacao) {
      //Caso a orden atual seja igual a anterior
      if (ordenacaoSet === 'DESC' || ordenacaoSet === null) {
        //Verifico se estou em ordem DESC e inverto para ASC
        sentido = 'ASC';
        setOrdenacaoSet('ASC');
      } else {
        sentido = 'DESC';
        setOrdenacaoSet('DESC');
      }
    } else {
      //Caso a ordem atual não seja igual a anterior ASC
      setOrdenacaoSet('ASC');
    }

    setCaProcessa(true); 
      var data = {
        limite: PER_PAGE, 
        start: 0,
        nome: buscaNome,
        email: buscaEmail,
        telefone: buscaTelefone,
        status: buscaStatus,
        ordenacao: order,
        sentido: sentido,
      };
      console.log(data);
    
      Api.post('usuarios/listarUsuario', data).then((rps) => {

        if(rps.data.status===false){
          Swal.fire({
            title: "Atenção!",
            icon: "info",
            html: rps.data.erro,
            showCloseButton: true,
            showCancelButton: false,
          });
        } else {
          setUsuarios(rps.data.obj);
          setTotalUsuarios(rps.data.totalRegistros);
          setCurrentPage(1);
        }
      setCaProcessa(false); 
    });
  }

  function editarUsuario(id){  
    var ca = JSON.parse(JSON.stringify(id));

    setFormUsuario(ca);
    setModalUsuario(true);       
  }

  function inutilizarUsuario(id_usuario){
    var ca = JSON.parse(JSON.stringify(id_usuario));

    setCaProcessa(true);

    Swal.fire({
        icon: "question",
        title: 'Confirmação',
        html: 'Deseja inutilizar o registro?',
        showCancelButton: true,
        confirmButtonText: `Sim, Confirmar!`,
        cancelButtonText: 'Não, Cancelar.'
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        Api.post('usuarios/inutilizar', ca).then(rps => {
          
          if(rps.data.status==true){
            addToast(rps.data.mensagem, {
              appearance: "success",
              autoDismiss: true,
              autoDismissTimeout: 2000
            });
            getListar();
          } 
        })
      } 
      setCaProcessa(false);
    })
  }

  function salvarUsuario(){
    setCaProcessa(true);
    Api.post('usuarios/adicionarUsuario', formUsuario).then(rps => {
      if (rps.data.status === true) {
        addToast(rps.data.mensagem, {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 2000
        });
        getListar();
      } else {
        Swal.fire({
          title: "Erro!",
          icon: "error",
          html: rps.data.erros,
          showCloseButton: true,
          showCancelButton: false,
        });
      }
      setCaProcessa(false);
      fecharModalUsuario();
    });
  }

  function handlePageClick(pagina) {
    setCaProcessa(true);
    setCurrentPage(pagina);
    var data = {
      limite: PER_PAGE, 
      start: (pagina-1) * PER_PAGE,
      nome: buscaNome,
      email: buscaEmail,
      telefone: buscaTelefone,
      status: buscaStatus,
      ordenacao: ordenacao,
      sentido: ordenacaoSet,
    }
    console.log(pagina);
    
    Api.post("usuarios/listarUsuario", data).then(rps => { 
      setUsuarios(rps.data.obj);
      setCaProcessa(false);
    })
  }

  function enderecoUsuario(id_usuario) {
    
    var data = {
      id_usuario: id_usuario
    }

    setCaProcessa(true);

    Api.post('usuarios/usuarioEndereco', data).then((rps) => {

        if (rps.data.status === true) {
          setListaEndereco(rps.data.obj);
        } else {
          setListaEndereco([]);  
        } 
      }
    );
    setCaProcessa(false);
  }

  function cuponsUsuario(id_usuario) {
    
    var data = {
      id_usuario: id_usuario
    }

    setCaProcessa(true);

    Api.post('usuarios/usuarioCupons', data).then((rps) => {

        if (rps.data.status === true) {
          setListaCupons(rps.data.obj);
        } else {
          setListaCupons([]);
        }
      }
    );
    setCaProcessa(false);
  }

  function ticketsUsuario(id_usuario) {
    
    var data = {
      id_usuario: id_usuario
    }

    setCaProcessa(true);

    Api.post('usuarios/usuarioTickets', data).then((rps) => {

        if (rps.data.status === true) {  
          setListaTickets(rps.data.obj);
        } else {
          setListaTickets([]);
        }
      }
    );
    setCaProcessa(false);
  }

  function pedidosUsuario(id_usuario) {
    
    var data = {
      id_usuario: id_usuario
    }

    setCaProcessa(true);

    Api.post('usuarios/usuarioPedidos', data).then((rps) => {

        if (rps.data.status === true) {  
          setListaPedidos(rps.data.obj);
        } else {
          setListaPedidos([]);
        }
      }
    );
    setCaProcessa(false);
  }
 

    return (
        <>
<div>

<div className="subheader espaco-header-pedidos subheader-transparent" id="kt_subheader">
          <div className="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
            {/*begin::Details*/}
            <div className="d-flex align-items-center flex-wrap mr-2">
              {/*begin::Title*/}
              <h5 className="text-dark font-weight-bold mt-2 mb-2 mr-5">Gerenciar Usuários</h5>
              {/*end::Title*/}
              {/*begin::Separator*/}
              <div className="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200" />
              {/*end::Separator*/}
            </div>
            {/*end::Details*/}
            {/*begin::Toolbar*/}
            <div className="d-flex align-items-center">

            </div>
            {/*end::Toolbar*/}
          </div>
        </div>
        {/*begin::Entry*/}
        <div className="d-flex flex-column-fluid">
          {/*begin::Container*/}
          <div className="container">
            <div className="card card-custom">
              <div className="ml-10 mr-10 mt-10">    
                <div className="row">
                  <div className="form-group col-md-4">
                    <label>Nome</label>
                    <input type="text" 
                           value={buscaNome} 
                           onChange={e => {setBuscaNome(e.target.value)}}
                           placeholder="Digite o nome ou parte do nome" 
                           className="form-control" />
                  </div>
                  <div className="form-group col-md-3">
                    <label>E-mail</label>
                    <input type="text" 
                           value={buscaEmail} 
                           onChange={e => {setBuscaEmail(e.target.value)}}
                           placeholder="Digite o e-mail ou parte do e-mail" 
                           className="form-control" />
                  </div>
                  <div className="form-group col-md-2">
                    <label>Telefone</label>
                    <input type="text" 
                           value={buscaTelefone} 
                           onChange={e => {setBuscaTelefone(e.target.value)}}
                           placeholder="Digite o telefone" 
                           className="form-control" />
                  </div>
                  <div className="form-group col-md-1">
                    <label>Status</label>
                    <select className="form-control" value={buscaStatus} onChange={e => {setBuscaStatus(e.target.value)}}>
                      <option value="" selected>Selecione</option>    
                      <option value="S">Ativo</option>    
                      <option value="N">Desativado</option>    
                    </select>
                  </div>

                  <div className="col-md-2 form-group">
                  <label>&nbsp;</label><br />
                    <button className="btn btn-primary btn-block" onClick={e=>{filtrar()}}><i className="fas fa-filter" />Filtrar</button>
                  </div> 
                </div> 
              </div>
            </div>
                  

              <LoadingOverlay
                active={caProcessa}
                spinner
                text='Carregando...'
              >
              <table className="table table-striped">
                <thead className="bg-light-primary">
                  <tr>
                    <th className="text-primary text-center">
                      <span className="text-hover-dark" onClick={e => { filtrar('id_usuario'); }}>
                        Código {ordenacao === 'id_usuario' ? <i className="fas fa-sort-down text-primary" /> : <i className="fas fa-sort text-primary" />}
                      </span>
                    </th>
                    <th className="text-primary text-center">
                      <span className="text-hover-dark" onClick={e => { filtrar('nome'); }}>
                        Nome {ordenacao === 'nome' ? <i className="fas fa-sort-down text-primary" /> : <i className="fas fa-sort text-primary" />}
                      </span>
                    </th>  
                    <th className="text-primary text-center">
                      <span className="text-hover-dark" onClick={e => { filtrar('email'); }}>
                        E-mail {ordenacao === 'email' ? <i className="fas fa-sort-down text-primary" /> : <i className="fas fa-sort text-primary" />}
                      </span>
                    </th>
                    <th className="text-primary text-center">
                      <span className="text-hover-dark" onClick={e => { filtrar('telefone'); }}>
                        Telefone {ordenacao === 'telefone' && ordenacaoSet === 'ASC' ? <i className="fas fa-sort-down text-primary" /> : <i className="fas fa-sort text-primary" />}
                      </span>
                    </th>  
                    <th className="text-primary text-center">
                      <span className="text-hover-dark" onClick={e => { filtrar('compras'); }}>
                        Compras {ordenacao === 'compras' ? <i className="fas fa-sort-down text-primary" /> : <i className="fas fa-sort text-primary" />}
                      </span>
                    </th>  
                    <th className="text-primary text-center">
                      <span onClick={e => { filtrar('data_ultimologin'); }}>
                        Último Login {ordenacao === 'data_ultimologin' ? <i className="fas fa-sort-down text-primary" /> : <i className="fas fa-sort text-primary" />}
                      </span>
                    </th>  
                    <th className="text-primary text-center">
                      <span className="text-hover-dark" onClick={e => { filtrar('status'); }}>
                        Status {ordenacao === 'status' ? <i className="fas fa-sort-down text-primary" /> : <i className="fas fa-sort text-primary" />}
                      </span>
                    </th>  
                    <th className="text-center">Ações</th>  

                  </tr>
                </thead>
                <tbody>  
                  { usuarios.map( (item) => { 
                      return (
                      <> 
                        <tr>
                          <td className="text-center">{item.id_usuario}</td>
                          <td>{item.nome}</td>
                          <td>{item.email}</td>
                          <td className="text-center">{item.telefone}</td>
                          <td className="text-center">{item.compras}</td>
                          <td className="text-center">{item.data_ultimologin}</td>
                          <td className="text-center">{item.status === "S" ? 
                            <span class="label label-success label-inline mr-2">Ativo</span> : 
                            <span class="label label-secondary label-inline mr-2">Desativado</span>}
                          </td>
                          <td className="text-center">
                            <div className="dropdown dropdown-inline">
                              <button type="button" className="btn btn-light-primary btn-icon btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                  <i className="ki ki-bold-more-ver"></i>
                              </button>
                              <div className="dropdown-menu">
                                <span className="dropdown-item" onClick={e => { editarUsuario(item) }}>Editar</span>
                                <div className="dropdown-divider"></div>
                                <span className="dropdown-item" onClick={e => { inutilizarUsuario(item) }}>Inutilizar</span>
                              </div>
                            </div>
                          </td>
                        </tr> 
                      </>
                      )
                    })
                    }
                </tbody>
                <tfoot>
                  <tr>
                    <td colspan="8" className="text-right">
                       <Paginacao 
                          currentPage={currentPage} 
                          pageCount={pageCount}
                          handlePageClick={handlePageClick}
                        />
                    </td>
                  </tr>
                </tfoot>
              </table> 
                   
              </LoadingOverlay>                    
              
              </div>
            </div>
          </div>


      <Modal size={'xl'} show={modalUsuario} onHide={() => fecharModalUsuario()}>
      <Modal.Header>
      <Modal.Title>Histórico do Usuário</Modal.Title>
          <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={e => {fecharModalUsuario()}}>
            <i aria-hidden="true" className="ki ki-close" />
          </button>
      </Modal.Header>
      <ul className="nav nav-tabs nav-bold nav-tabs-line p-l-25">
          <li className="nav-item">
            <a className="nav-link active" data-toggle="tab" href="#dados">
              <span className="nav-icon">
                <i className="fas fa-project-diagram" />
              </span>
              <span className="nav-text">Dados</span>
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" data-toggle="tab" href="#endereco" onClick={e => { enderecoUsuario(formUsuario?.id_usuario) }}>
              <span className="nav-icon">
                <i className="fas fa-map-marker-alt" />
              </span>
              <span className="nav-text">Endereço </span>
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" data-toggle="tab" href="#cupons" onClick={e => { cuponsUsuario(formUsuario?.id_usuario) }}>
              <span className="nav-icon">
                <i className="fas fa-receipt" />
              </span>
              <span className="nav-text">Cupons</span>
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" data-toggle="tab" href="#tickets" onClick={e => { ticketsUsuario(formUsuario?.id_usuario) }}>
              <span className="nav-icon">
                <i className="fas fa-ticket-alt" />
              </span>
              <span className="nav-text">Tickets</span>
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" data-toggle="tab" href="#pedidos" onClick={e => { pedidosUsuario(formUsuario?.id_usuario) }}>
              <span className="nav-icon">
                <i className="fab fa-wpforms" />
              </span>
              <span className="nav-text">Pedidos</span>
            </a>
          </li>
      </ul>

      <div className="modal-body">    
        <div className="tab-content">      
          {/*begin::Group - Dados*/}
          <div className="tab-pane fade show active" id="dados" role="tabpanel" aria-labelledby="dados">
            <div className="row">
              <div className="form-group col-md-12">
                <label>Nome <b className="text-danger">*</b></label>
                <input type="text" maxLength="150" value={formUsuario?.nome} onChange={e => {setFormUsuario({...formUsuario, nome: e.target.value})}} className="form-control" />
              </div>
            </div>

            <div className="row">
              <div className="form-group col-md-12">
                <label>E-mail <b className="text-danger">*</b></label>
                <input type="text" maxLength="100" value={formUsuario?.email} onChange={e => {setFormUsuario({...formUsuario, email: e.target.value})}} className="form-control" />
              </div>
            </div>

            <div className="row">
              <div className="form-group col-md-4">
                <label>Telefone <b className="text-danger">*</b></label>
                <input type="text" maxLength="100" value={formUsuario?.telefone} onChange={e => {setFormUsuario({...formUsuario, telefone: e.target.value})}} className="form-control" />
              </div> 

              <div className="form-group col-md-4">
                <label>Sexo <b className="text-danger">*</b></label>
                <select className="form-control" value={formUsuario?.sexo} onChange={e => {setFormUsuario({...formUsuario, sexo: e.target.value})}}>
                  <option value="" selected>Selecione</option>    
                  <option value="F">Feminino</option>    
                  <option value="M">Masculino</option>    
                  <option value="O">Outros</option>    
                </select>
              </div>         
            </div>    
            
          </div>
          {/*end::Group - Dados*/}

          {/*begin::Group - Endereco*/}
          <div className="tab-pane fade" id="endereco" role="tabpanel" aria-labelledby="endereco">
            <LoadingOverlay
                            active={caProcessa}
                            spinner
                            text='Carregando...'
                          >                     
              <div className="row">
              
              <table className="table table-striped">
                <thead className="bg-light-primary">
                  <tr>
                    <th className="text-center">Cidade</th>
                    <th className="text-center">Bairro</th>
                    <th className="text-center">Rua</th>
                    <th className="text-center">Número</th>
                    <th className="text-center">Complemento</th>
                    <th className="text-center">Referência</th>
                    <th className="text-center">Data Cadastro</th>
                  </tr>
                </thead>
                <tbody>
                {listaEndereco.map(end => {
                  return <>            
                    <tr>
                      <td>{end.nome_cidade}</td>
                      <td>{end.nome_bairro}</td>
                      <td>{end.endereco}</td>
                      <td className="text-center">{end.numero}</td>
                      <td>{end.complemento}</td>
                      <td>{end.referencia}</td>
                      <td className="text-center">{end.data_cadastro}</td>
                    </tr>
                  </>
                  }
                )}
                </tbody>
              </table>

              </div>
              
              </LoadingOverlay>
          </div>
          {/*end::Group - Endereco*/}

          {/*begin::Group - Cupons*/}
          <div className="tab-pane fade" id="cupons" role="tabpanel" aria-labelledby="cupons">
            <LoadingOverlay
                            active={caProcessa}
                            spinner
                            text='Carregando...'
                          >                     
              <div className="row">
              
              <table className="table table-striped">
                <thead className="bg-light-primary">
                  <tr>
                    <th className="text-center">Hash Cupom</th>
                    <th className="text-center">Pagador</th>
                    <th className="text-center">Porcentagem</th>
                    <th className="text-center">Valor</th>
                    <th className="text-center">Tipo Cupom</th>
                    <th className="text-center">Data expiração</th>
                  </tr>
                </thead>
                <tbody>
                {listaCupons.map(cup => {
                  return <>            
                    <tr>
                      <td className="text-center">{cup.hash_cupom}</td>
                      <td className="text-center">{ cup.pagador == 'A' && 'Aiboo' }{ cup.pagador == 'E' && 'Estabelecimento' }</td>
                      <td className="text-center">{cup.porcentagem}</td>
                      <td className="text-center">{cup.valor}</td>
                      <td className="text-center">
                        { cup.tipo == 'F' && 'Frete' }
                        { cup.tipo == 'P' && 'Produtos' }
                        { cup.tipo == 'T' && 'Total' }
                      </td>
                      <td className="text-center">{cup.data_expiracao}</td>
                    </tr>
                  </>
                  }
                )}
                </tbody>
              </table>

              </div>
              
              </LoadingOverlay>
          </div>
          {/*end::Group - Cupons*/}

          {/*begin::Group - Tickets*/}
          <div className="tab-pane fade" id="tickets" role="tabpanel" aria-labelledby="tickets">
            <LoadingOverlay
                            active={caProcessa}
                            spinner
                            text='Carregando...'
                          >                     
              <div className="row">
              
              <table className="table table-striped">
                <thead className="bg-light-primary">
                  <tr>
                    <th className="text-center">Hash Ticket</th>
                    <th className="text-center">Nome</th>
                    <th className="text-center">Status</th>
                    <th className="text-center">Data Criação</th>
                    <th className="text-center">Data Atualização</th>
                  </tr>
                </thead>
                <tbody>
                {listaTickets.map(tic => {
                  return <>            
                    <tr>
                      <td className="text-center">{tic.id_ticket}</td>
                      <td className="text-center">{tic.nome}</td>
                      <td className="text-center">
                        { tic.status == 'A' && 'Aguardando' }
                        { tic.status == 'E' && 'Atendendo' }
                        { tic.status == 'R' && 'Resolvido' }
                      </td>
                      <td className="text-center">{tic.data_criacao}</td>
                      <td className="text-center">{tic.data_atualizacao}</td>
                    </tr>
                  </>
                  }
                )}
                </tbody>
              </table>

              </div>
              
              </LoadingOverlay>
          </div>
          {/*end::Group - Tickets*/}

          {/*begin::Group - Pedidos*/}
          <div className="tab-pane fade" id="pedidos" role="tabpanel" aria-labelledby="pedidos">
            <LoadingOverlay
                            active={caProcessa}
                            spinner
                            text='Carregando...'
                          >                     
              <div className="row">
              
              <table className="table table-striped">
                <thead className="bg-light-primary">
                  <tr>
                    <th className="text-center">Hash Pedido</th>
                    <th className="text-center">Data Criação</th>
                    <th className="text-center">Pagamento</th>
                    <th className="text-center">Estabelecimento</th>
                    <th className="text-center">Endereço</th>
                    <th className="text-center">Valor Pedido</th>
                    <th className="text-center">Taxa</th>
                    <th className="text-center">Desconto</th>
                    <th className="text-center">Desconto Aiboo</th>
                    <th className="text-center">Total</th>
                  </tr>
                </thead>
                <tbody>
                {listaPedidos.map(ped => {
                  return <>            
                    <tr>
                      <td className="text-center">{ped.hash_pedido}</td>
                      <td className="text-center">{ped.data_criacao}</td>
                      <td className="text-center">
                        { (ped.pagamento == -2) && <label style={{color:'#F64E60'}}><b>Online</b></label> }
                        { (ped.pagamento == -10) && <label style={{color:'#32BCAD'}}><b>Pix</b></label> }
                        { (ped.pagamento == -11) && <label style={{color:'#21C25E'}}><b>Picpay</b></label> }
                        { (ped.pagamento == -12) && <label style={{color:'#4C0A77'}}><b>Nupay</b></label> }
                        { (ped.pagamento == -1) && <label style={{color:'#0088ff'}}><b>Dinheiro</b></label> }
                        { (ped.pagamento != -2 && ped.pagamento != -10 && ped.pagamento != -11 && ped.pagamento != -12 && ped.pagamento != -1) && <label style={{color:'#212121'}}><b>Cartão</b></label> }
                      </td>
                      <td className="text-center">{ped.nome}</td>
                      <td className="text-center">{ped.endereco_rua}</td>
                      <td className="text-center">{ped.valor_pedido}</td>
                      <td className="text-center">{ped.valor_taxa}</td>
                      <td className="text-center">{ped.valor_desconto}</td>
                      <td className="text-center">{ped.valor_desconto_aiboo}</td>
                      <td className="text-center">{ped.valor_total}</td>
                    </tr>
                  </>
                  }
                )}
                </tbody>
              </table>

              </div>
              
              </LoadingOverlay>
          </div>
          {/*end::Group - Pedidos*/}
        </div>
      </div>
 
      <Modal.Footer>
        <button type="button" onClick={e => { fecharModalUsuario(); }} className="btn btn-light-primary font-weight-bold" data-dismiss="modal"><i className="fas fa-door-open" />Fechar</button>
        <button type="button" onClick={e => { salvarUsuario(); }} className="btn btn-success font-weight-bold"><i className="fas fa-save" />Salvar</button>
      </Modal.Footer>
    </Modal>


        </>
    );
}

export default withRouter(Usuarios);                  
import * as S from "./styles";

export function InputWrapper({ children, error, label }) {
  return (
    <S.Container error={error}>
      <label>{label}</label>

      {children}

      {error && <small>{error.message}</small>}
    </S.Container>
  );
}

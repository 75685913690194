import React, { useState, useEffect, useRef, useCallback } from 'react';
import Api from '../../../services/api';
import { withRouter } from "react-router";

import "./index.scss";

import Swal from "sweetalert2";
import LoadingOverlay from 'react-loading-overlay';
import ReactToPrint from "react-to-print";

import InputMask from 'react-input-mask';
import "react-datepicker/dist/react-datepicker.css";
import DatePicker, { registerLocale } from "react-datepicker";
import pt from "date-fns/locale/pt-BR"; 
registerLocale("pt-BR", pt);

const RepExtrato = () => {
  const [ extrato, setExtrato ] = useState([]);
  const [ caProcessa, setCaProcessa ] = useState(false);
  const [ dataInicial, setDataInicial ] = useState();
  const [ dataFinal, setDataFinal ] = useState(new Date());
  const printRef = useRef();
 
  const getListar = useCallback(() => { 
    setCaProcessa(true); 
    
    var data = {
      data_inicio: new Date('2020-01-01'),
      data_final: dataFinal,
      representante: sessionStorage.getItem('idRepresentante'),
    };

    Api.post("representantes/extrato", data).then(rps => {
      setExtrato(rps.data.obj);
      setCaProcessa(false);
    })
  }, [dataFinal]);
  
  function filtrar() {
    setCaProcessa(true); 
      var data = {
        data_inicio: dataInicial,
        data_final: dataFinal,
        representante: sessionStorage.getItem('idRepresentante'),
      };
    
      Api.post('representantes/extrato', data).then((rps) => {

        if(rps.data.status==false){
          Swal.fire({
            title: "Atenção!",
            icon: "info",
            html: rps.data.erro,
            showCloseButton: true,
            showCancelButton: false,
          });
        } else {
          setExtrato(rps.data.obj);
        }
      setCaProcessa(false); 
    });
  }

  useEffect(() => {
    getListar();
  }, [getListar]);

    
    return (
        <>
<div>

<div className="subheader espaco-header-pedidos subheader-transparent" id="kt_subheader">
          <div className="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
            {/*begin::Details*/}
            <div className="d-flex align-items-center flex-wrap mr-2">
              {/*begin::Title*/}
              <h5 className="text-dark font-weight-bold mt-2 mb-2 mr-5">Gerenciar Representante</h5>
              {/*end::Title*/}
              {/*begin::Separator*/}
              <div className="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200" />
              {/*end::Separator*/}
              <h5 className="text-dark font-weight-bold mt-2 mb-2 mr-5">Extrato</h5>
            </div>
            {/*end::Details*/}
            {/*begin::Toolbar*/}
            <div className="d-flex align-items-center">
              <ReactToPrint
                trigger={() => <button className="btn btn-lg btn-secondary mt-3 mb-3"><i className="fas fa-print" />Imprimir</button>}
                content={() => printRef.current}
              />
            </div>
            {/*end::Toolbar*/}
          </div>
        </div>
        {/*begin::Entry*/}
        <div className="d-flex flex-column-fluid">
          {/*begin::Container*/}
          <div className="container">
            <div className="card card-custom">
              <div className="ml-10 mr-10 mt-10">    
                <div className="row">
                <div className="form-group col-md-2">
                  <label>Período inicial</label>
                  <DatePicker 
                        name="dt_inicio"
                        onChange={setDataInicial}
                        isClearable
                        selectsStart
                        locale="pt-BR"
                        className="form-control"
                        placeholderText="dd/mm/aaaa" 
                        selected ={dataInicial}
                        dateFormat="dd/MM/y"
                        startDate={dataInicial}
                        endDate={dataFinal}
                        customInput={
                          <InputMask mask="99/99/9999" />}/>
                </div>
                <div className="form-group col-md-2">
                  <label>Período final</label>
                  <DatePicker 
                        name="dt_final"
                        onChange={setDataFinal}
                        isClearable
                        locale="pt-BR"
                        className="form-control"
                        placeholderText="dd/mm/aaaa" 
                        selected ={dataFinal}
                        dateFormat="dd/MM/y"
                        selectsEnd
                        startDate={dataInicial}
                        endDate={dataFinal}
                        minDate={dataInicial} 
                        customInput={
                          <InputMask mask="99/99/9999" />}/>
                </div>
                  <div className="col-md-2 form-group">
                  <label>&nbsp;</label><br />
                    <button className="btn btn-primary btn-block" onClick={e=>{filtrar()}}>Filtrar</button>
                  </div> 
                </div> 
              </div>

              <br />
              <br />
              <br />

              <LoadingOverlay
                active={caProcessa}
                spinner
                text='Carregando...'
              > 
              
              <div className="ml-5 mr-5 mt-10" ref={printRef}>
                <div className="cabecalho">
                  <table>
                    <tr>
                        <td width="40%">
                        <img alt="Logo" src="assets/media/logos/aiboo.png" className="logo-sticky max-h-45px" />
                        </td>
                        <td width="60%">
                        <h5 className="text-left"><b>Aiboo Delivery Serviços de Venda LTDA</b><br />
                                Rua Hans L. Kraus, 31 - Cohab - João Neiva/ES<br />
                                atendimento@aiboo.com.br
                            </h5>
                        </td>
                    </tr>
                  </table>
                  <br />
                  <h3 className="text-center">Extrato de comissão</h3>
                  <br />
                </div>

                <table className="table table-striped table-bordered">
                  <thead>
                      <tr>
                          <th className="text-center h4">Data</th>
                          <th className="text-center h4">Documento</th>
                          <th className="text-center h4">Histórico</th>
                          <th className="text-center h4">Valor</th>
                      </tr>
                  </thead>
                  <tbody>
                    { extrato.map( (item, i) => { 
                      var dataEsta = item.dataF;
                      var dataProxima = '';
                      
                        if(extrato[i+1]===undefined){
                          dataProxima = '';
                        } else {
                          dataProxima = extrato[i+1].dataF;
                        }

                      return (
                      <> { i === 0 && <>
                          <tr>
                            <td></td>
                            <td></td>
                            <td className="text-right"><b>SALDO ANTERIOR</b> <i className="fas fa-arrow-right" /></td>
                            <td>{ (item.saldo - item.valor) >= 0 ? 
                               <label className="text-info"><b>{(item.saldo - item.valor).toFixed(2)}C</b></label> :
                               <label className="text-info"><b>{(item.saldo - item.valor).toFixed(2)}D</b></label> 
                            }</td>
                          </tr>
                        </>
                      }
                    <tr>
                      <td className="text-center">{item.dataF}</td>
                      <td className="text-center">{item.id_representante}{item.id_representante_extrato}</td>
                      <td>{item.descricao}</td>
                      <td className="text-right">{item.tipo == 'C' ? 
                        <label className="text-info"><b>{item.valor}{item.tipo}</b></label> : 
                        <label className="text-danger"><b>{item.valor}{item.tipo}</b></label>  
                        }
                      </td>
                    </tr> 
                    { dataEsta != dataProxima && 
                      <>
                      <tr>
                        <td colspan="2"></td>
                        <td className="text-right"><b>SALDO DO DIA </b> <i className="fas fa-arrow-right" /></td>
                        <td>{item.saldo >= 0 ? 
                            <label className="text-info"><b>{item.saldo}{item.tipo}</b></label> : 
                            <label className="text-danger"><b>{item.saldo}{item.tipo}</b></label>  
                          }
                        </td>
                      </tr>
                      </>
                    }
                    </>
                    
                      )
                    })
                    }                                                                                          
                  </tbody>
                </table>
              </div>
              
              </LoadingOverlay>                    
      
                  </div>
                  </div>
                  </div>
                  </div>  
               
        </>
    );
}

export default withRouter(RepExtrato);                  
import React from "react";

import { PageHeader } from "../PageHeader";

import * as S from "./styles";

export const PageWrapper = ({
  children,
  pageStatus,
  handleSubmit,
  submitForm,
}) => {
  return (
    <S.Container>
      <PageHeader
        pageStatus={pageStatus}
        handleSubmit={handleSubmit}
        submitForm={submitForm}
      />
      <S.CardContent>{children}</S.CardContent>
    </S.Container>
  );
};

import React, { useState, useEffect } from 'react';
import PaymentsApi from '../../../services/payments-api';
import { withRouter } from "react-router";
import { Modal } from "react-bootstrap";

import { useToasts } from 'react-toast-notifications';
import LoadingOverlay from 'react-loading-overlay';

import InputMask from 'react-input-mask';
import "react-datepicker/dist/react-datepicker.css";
import DatePicker, { registerLocale } from "react-datepicker";
import pt from "date-fns/locale/pt-BR";
registerLocale("pt-BR", pt);

const Paginacao = ({ currentPage, pageCount, handlePageClick }) => {

  const renderRow = row => {
    return <span>{row}</span>;
  };

  let rows = [];

  if (currentPage !== 1) {
    rows.push(
      <span 
        onClick={() => handlePageClick(currentPage - 1)} 
        className="btn btn-icon btn-sm btn-light-primary mr-2 my-1">
        <i className="ki ki-bold-arrow-back icon-xs"></i>
      </span>
    );
  } else {
    rows.push(
      <span className="btn-icon btn-sm btn-secondary disabled mr-2 my-1">
        <i className="ki ki-bold-arrow-back icon-xs"></i>
      </span>
    );
  }

  if (currentPage > 3) {
    rows.push(
      <>
        <span 
          onClick={() => handlePageClick(1)} 
          className="btn btn-icon btn-sm btn-light-primary mr-2 my-1">
          1
        </span>
        <span className="btn-icon btn-sm btn-secondary disabled mr-2 my-1">
          ...
        </span>
      </>
    );
  }

  for (let i = currentPage - 2; i < currentPage + 3; i++) {
    if (i > 0 && i <= pageCount) {
      if (i === currentPage) {
        rows.push(
          <span 
            onClick={() => handlePageClick(i)} 
            className="btn btn-icon btn-sm border-0 btn-hover-primary active mr-2 my-1">
            {i}
          </span>
        );
      } else {
        rows.push(
          <span 
            onClick={() => handlePageClick(i)} 
            className="btn btn-icon btn-sm border-0 btn-hover-primary mr-2 my-1">
            {i}
          </span>
        );
      }
    }
  }

  if (currentPage + 3 <= pageCount) {
    rows.push(
      <>
        <span className="btn-icon btn-sm btn-secondary disabled mr-2 my-1">
          ...
        </span>
        <span 
          onClick={() => handlePageClick(pageCount)} 
          className="btn btn-icon btn-sm btn-light-primary mr-2 my-1">
          {pageCount}
        </span>
      </>
    );
  }

  if (currentPage !== pageCount) {
    rows.push(
      <span 
        onClick={() => handlePageClick(currentPage + 1)} 
        className="btn btn-icon btn-sm btn-light-primary mr-2 my-1">
        <i className="ki ki-bold-arrow-next icon-xs"></i>
      </span>
    );
  } else {
    rows.push(
      <span className="btn-icon btn-sm btn-secondary disabled mr-2 my-1">
        <i className="ki ki-bold-arrow-next icon-xs"></i>
      </span>
    );
  }

  if (rows.length === 0) {
    return <p>Nenhum item</p>;
  }

  return <div>{rows.map(renderRow)}</div>;
};


const PayoutReport = () => {
  const { addToast } = useToasts();
  const [entities, setEntities] = useState([]);
  const [loadingActive] = useState(false);
  const [totalBatch, setBatchTotal] = useState();
  const [paymentDateFilter, setPaymentDateFilter] = useState();
  const [modal, setModal] = useState(false);
  const [detailedData, setDetailedData] = useState({});
  const [currentPage, setCurrentPage] = useState(1);

  const PER_PAGE = 100;
  const pageCount = Math.ceil(totalBatch / PER_PAGE);

  let acesso = JSON.parse(localStorage.getItem('@ADM-Aiboo/perfil'));

  useEffect(() => {
    list();
  }, []);

  function closeModal() {
    setModal(false);
  }

  function list() {
    var data = {
      limit: PER_PAGE,
      page: 1
    }

    PaymentsApi.post("report/batch/complete", data).then(rps => {
      setEntities(rps.data.entities);
      setBatchTotal(rps.data.count);
    })
  }

  function moreDetails(data) {
    setDetailedData(data);
    setModal(true);
  }

  function resync(entity) {
    if (entity.is_paid == 1 || (entity.is_retained == 1 && entity.external_payer_id != null) || (entity.is_compensated == 1 && entity.external_payer_id != null)) {
      addToast("Esse pagamento não pode ser re-sincronizado!", {
        appearance: "error",
        autoDismiss: false,
        autoDismissTimeout: 2000
      });
    } else {
      var url = "batch/" + entity.token + "/resync";

      PaymentsApi.post(url).then(rps => {
        console.log(rps);

        addToast("Pagamento payout re-sincronizado com sucesso", {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 2000
        });
      });
    }
  }

  function filter() {
    var data = {
      limit: PER_PAGE,
      page: 1,
      paymentData: paymentDateFilter,
    };

    PaymentsApi.post('report/batch/complete', data).then((rps) => {
      setEntities(rps.data.entities);
      setBatchTotal(rps.data.count);
      setCurrentPage(1);
    });
  }

  function handlePageClick(page) {
    setCurrentPage(page);
    var data = {
      limit: PER_PAGE,
      page: page,
      paymentData: paymentDateFilter,
    };

    PaymentsApi.post("report/batch/complete", data).then(rps => {
      setEntities(rps.data.entities);
    })
  }

  return (
    <>
      <div>

        <div className="subheader espaco-header-pedidos subheader-transparent" id="kt_subheader">
          <div className="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
            {/*begin::Details*/}
            <div className="d-flex align-items-center flex-wrap mr-2">
              {/*begin::Title*/}
              <h5 className="text-dark font-weight-bold mt-2 mb-2 mr-5">Payout</h5>
              {/*end::Title*/}
              {/*begin::Separator*/}
              <div className="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200" />
              {/*end::Separator*/}
            </div>
            {/*end::Details*/}
            {/*begin::Toolbar*/}
            {/*end::Toolbar*/}
          </div>
        </div>
        {/*begin::Entry*/}
        <div className="d-flex flex-column-fluid">
          {/*begin::Container*/}
          <div className="container">
            <div className="card card-custom">
              <div className="ml-10 mr-10 mt-10">
                <div className="row">
                  <div className="form-group col-md-3">
                    <label>Data de Pagamento</label>
                    <div>
                      <DatePicker
                        name="dt_inicio"
                        onChange={setPaymentDateFilter}
                        isClearable
                        selectsStart
                        locale="pt-BR"
                        className="form-control"
                        placeholderText="aaaa-mm-dd"
                        selected={paymentDateFilter}
                        dateFormat="yyyy-MM-dd"
                        startDate={paymentDateFilter}
                        customInput={
                          <InputMask mask="9999-99-99" />} />
                    </div>
                  </div>

                  <div className="col-md-3 form-group">
                    <label>&nbsp;</label><br />
                    <button className="btn btn-primary btn-block" onClick={e => { filter() }}><i className="fas fa-filter" />Filtrar</button>
                  </div>
                </div>
              </div>

              <LoadingOverlay
                active={loadingActive}
                spinner
                text='Carregando...'
              >

                <table className="table table-striped">
                  <thead className="bg-light-primary">
                    <tr>
                      <th className="text-primary text-center">
                        <span className="text-hover-dark">
                          ID
                        </span>
                      </th>
                      <th className="text-primary text-center">
                        <span className="text-hover-dark">
                          Estabelecimento
                        </span>
                      </th>
                      <th className="text-primary text-center">
                        <span className="text-hover-dark">
                          Valor
                        </span>
                      </th>
                      <th className="text-primary text-center">
                        <span className="text-hover-dark">
                          Taxa
                        </span>
                      </th>
                      <th className="text-primary text-center">
                        <span className="text-hover-dark">
                          Comissão
                        </span>
                      </th>
                      <th className="text-primary text-center">
                        <span className="text-hover-dark">
                          Período
                        </span>
                      </th>
                      <th className="text-primary text-center">
                        <span className="text-hover-dark">
                          Status
                        </span>
                      </th>
                      <th className="text-center">Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    {entities !== null &&
                      entities.map((item) => {
                        return (
                          <>
                            <tr>
                              <td className="text-center">{item.id}</td>
                              <td className="text-center">#{item.establishment_id} - {item.establishment}</td>
                              <td className="text-center">R$ {item.value_final_establishment}</td>
                              <td className="text-center">R$ {item.value_tax}</td>
                              <td className="text-center">R$ {item.value_commission}</td>
                              <td className="text-center">De: <b>{item.date_from}</b> <br></br> Até <b>{item.date_to}</b></td>
                              <td className="text-center"><b>{item.status}</b></td>
                              <td className="text-center">
                                <div class="dropdown dropdown-inline">
                                  <button type="button" class="btn btn-light-primary btn-icon btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i class="ki ki-bold-more-ver"></i>
                                  </button>
                                  <div class="dropdown-menu">
                                    <span class="dropdown-item" onClick={e => { moreDetails(item) }}>Informações</span>
                                    {acesso.est_ger_cup_ei === "1" && <>
                                      {item.status !== 'I' &&
                                        <>
                                          <div class="dropdown-divider"></div>
                                          <span class="dropdown-item" onClick={e => { resync(item) }}>Resincronizar</span>
                                        </>
                                      }
                                    </>}
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </>
                        )
                      })
                    }
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colspan="9" className="text-right">
                        <Paginacao 
                          currentPage={currentPage} 
                          pageCount={pageCount}
                          handlePageClick={handlePageClick}
                        />
                      </td>
                    </tr>
                  </tfoot>
                </table>

              </LoadingOverlay>

            </div>
          </div>
        </div>
      </div>

      <Modal size={'xl'} show={modal} onHide={() => closeModal()}>
        <Modal.Header>
          <Modal.Title>Payout - Informações Completas</Modal.Title>
          <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={e => { closeModal() }}>
            <i aria-hidden="true" className="ki ki-close" />
          </button>
        </Modal.Header>

        <div className="row mt-5 ml-5 mr-5 form-group">
          <div className="col-md-12">
            <h1>Informações:</h1>

            <ul>
              <li>Lote: {detailedData.id}</li>
              <li>Token: {detailedData.token}</li>
              <li>Período: De: <b>{detailedData.date_from}</b> - Até <b>{detailedData.date_to}</b></li>
              <li>Status: {detailedData.status}</li>
              <li>Valor: R$ {detailedData.value_final_establishment}</li>
              <li>Taxa: R$ {detailedData.value_tax}</li>
              <li>Comissão: R$ {detailedData.value_commission}</li>
              <li>Valor Retido: R$ {detailedData.retained_value}</li>
              <li>Valor Compensado: R$ {detailedData.compensated_value}</li>
              <li>ID Fatura: R$ {detailedData.invoice_id}</li>
              <li>Finalizado em: <b>{detailedData.finished_at}</b></li>
              <li>Descrição Completa: {detailedData.complete_description}</li>
            </ul>

            <h1>Erros:</h1>

            <ul>
              <li>Erro para sincronizar: {detailedData.sync_error}</li>
              <li>Erro ao realizar pagamento: {detailedData.has_error}</li>
              <li>ID do Pagamento: {detailedData.external_payer_id}</li>
            </ul>

          </div>
        </div>

        <Modal.Footer>
          <button type="button" onClick={e => { closeModal(); }} className="btn btn-light-primary font-weight-bold" data-dismiss="modal"><i className="fas fa-door-open" />Fechar</button>
        </Modal.Footer>
      </Modal>

    </>
  );

}

export default withRouter(PayoutReport);
import styled from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    max-width: 1500px;
    width: 100%;
    padding-top: 70px;
    padding-bottom: 30px;
    padding-inline: 40px;
    margin: 0 auto;
`;

export const Content = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    padding: 28px 21px;
    background-color: white;
    
    width: 100%;
    margin: 0 auto;

    box-shadow: 0 0 50px 2px rgba(0, 0, 0, 0.1);
    height: 100%;
    min-height: 60vh;
    margin-top: 42px;
    border-radius: 16px;
`;

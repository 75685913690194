import { withRouter } from "react-router";

const Painel2 = () => {

    return (
        <>
            {/*begin::Content*/}
            <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                {/*begin::Entry*/}
                <div className="d-flex flex-column-fluid">
                    {/*begin::Container*/}
                    <div className="container">
                    {/*begin::Dashboard*/}
                        <div className='row'>
                            <div className='col-md-12 text-left'>
                                Bem-vindo!
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/*begin::Footer*/}
            <div className="footer bg-white py-4 d-flex flex-lg-column" id="kt_footer" style={{position: 'fixed',bottom:0,left:0}}>
                {/*begin::Container*/}
                <div className="container d-flex flex-column flex-md-row align-items-center justify-content-between">
                    {/*begin::Copyright*/}
                    <div className="text-dark order-2 order-md-1">
                    <span className="text-muted font-weight-bold mr-2">2022 - {new Date().getFullYear()} ©</span>
                    <a href="http://keenthemes.com/metronic" target="_blank" className="text-dark-75 text-hover-primary" rel="noreferrer">Aiboo</a>
                    </div>
                    {/*end::Copyright*/}
                </div>
                {/*end::Container*/}
                </div>
                {/*end::Footer*/}
                {/*end::Wrapper*/}
                {/*end::Page*/}
                {/*end::Main*/}   
            </>
    );

}



export default withRouter(Painel2);                  
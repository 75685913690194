import React, { useState, useEffect } from 'react';
import Api from '../../services/api';
import { withRouter } from "react-router";

import Swal from "sweetalert2";
import { useToasts } from 'react-toast-notifications';
import { Modal } from "react-bootstrap";

import Select from 'react-select';
import LoadingOverlay from 'react-loading-overlay';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const Avisos = () => {
  
  const { addToast } = useToasts();
  const [ aviso, setAviso ] = useState([]);
  const [ formAviso, setFormAviso ] = useState([]);
  const [ estabelecimentoSelect, setEstabelecimentoSelect ] = useState([]);
  const [ cidadeSelect, setCidadeSelect ] = useState([]);
  const [ representanteSelect, setRepresentanteSelect ] = useState([]);
  const [ buscaEstabelecimento, setBuscaEstabelecimento ] = useState();
  const [ buscaCidade, setBuscaCidade ] = useState();
  const [ buscaRepresentante, setBuscaRepresentante ] = useState();
  const [ caProcessa, setCaProcessa ] = useState(false);
  const [ modal, setModal ] = useState(false);

  let acesso = JSON.parse(localStorage.getItem('@ADM-Aiboo/perfil'));
 
  useEffect(() => {
    getCidadeSelect();
    getRepresentanteSelect();
    getEstabelecimentoSelect();
  }, []);

  function getEstabelecimentoSelect() {
    Api.get("estabelecimentos/listaNomeEstabelecimento").then(rps => {
      var rep = rps.data.obj;
      var nRep = [];
      nRep[nRep.length] = {value: '', label: 'Selecione...'};
      rep.forEach(e => {
        nRep[nRep.length] = {value: e.id_estabelecimento, label: e.nome};
      })
      setEstabelecimentoSelect(nRep);
    })
  }

  function getCidadeSelect() {
    Api.get("cidades/listar").then(rps => {
      var cid = rps.data.cidades;
      var nCid = [];
      nCid[nCid.length] = {value: '', label: 'Selecione...'};
      cid.forEach(e => {
        nCid[nCid.length] = {value: e.id_cidade, label: e.nome_cidade + ' - ' + e.estado};
      })
      setCidadeSelect(nCid);
    })
  }

  function getRepresentanteSelect() {
    Api.get("representantes/listar").then(rps => {
      var rep = rps.data.representantes;
      var nRep = [];
      nRep[nRep.length] = {value: '', label: 'Selecione...'};
      rep.forEach(e => {
        nRep[nRep.length] = {value: e.id_representante, label: e.nome};
      })
      setRepresentanteSelect(nRep);
    })
  }

  function adicionarAviso(){
    setFormAviso({});
    setModal(true);
  }

  function fecharModal(){
    setModal(false);
  }

  function editar(id_aviso){  
    var alterar = JSON.parse(JSON.stringify(id_aviso));

    setFormAviso(alterar);
    setModal(true);            
  }

  function excluir(id_aviso) {
    var ca = JSON.parse(JSON.stringify(id_aviso));

    setCaProcessa(true);

    Api.post('bancos/excluirBanco', ca).then(rps => {
      if (rps.data.status === true) {
        Swal.fire({
          title: "Confirmação!",
          icon: "info",
          html: rps.data.mensagem,
          showCloseButton: true,
          showCancelButton: false,
        });
        //getListar();
        setCaProcessa(false);
      }
    });   
  }

  function filtrar() {
    setCaProcessa(true); 
      var data = {
        estabelecimento: buscaEstabelecimento,
        cidade: buscaCidade,
        representante: buscaRepresentante,
      };
   
      Api.post('avisos/filtrar', data).then((rps) => {

        if(rps.data.status==false){
          Swal.fire({
            title: "Atenção!",
            icon: "info",
            html: rps.data.erro,
            showCloseButton: true,
            showCancelButton: false,
          });
        } else {
          setAviso(rps.data.obj);
        }
      setCaProcessa(false); 
    });
  }

  function salvar(){
    setCaProcessa(true);
    
    var nIdEstabelecimento = [];
    var nIdCidade = [];
    var nIdRepresentante = [];

    var nFormAviso = {};
    nFormAviso = JSON.parse(JSON.stringify(formAviso));

    if (formAviso.id_estabelecimento !== null && formAviso.id_estabelecimento !== undefined) {
      formAviso.id_estabelecimento.forEach(e => {
          nIdEstabelecimento[nIdEstabelecimento.length] = e.value;
      });
      nFormAviso.id_estabelecimento = nIdEstabelecimento;   
    } 
    
    if (formAviso.id_cidade !== null && formAviso.id_cidade !== undefined) {
      formAviso.id_cidade.forEach(e => {
          nIdCidade[nIdCidade.length] = e.value;
      });
      nFormAviso.id_cidade = nIdCidade;   
    } 

    if (formAviso.id_representante !== null && formAviso.id_representante !== undefined) {
      formAviso.id_representante.forEach(e => {
          nIdRepresentante[nIdCidade.length] = e.value;
      });
      nFormAviso.id_representante = nIdRepresentante;   
    } 

    Api.post('avisos/adicionar', nFormAviso).then(rps => {
      if (rps.data.status === true) {
        addToast(rps.data.mensagem, {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 2000
        });
        setModal(false);
        setAviso({});
        //getListar();
      } else {
        Swal.fire({
          title: "Erro!",
          icon: "error",
          html: rps.data.erros,
          showCloseButton: true,
          showCancelButton: false,
        });
      }
      setCaProcessa(false);
    });
  }

    
    return (
        <>
<div>

<div className="subheader espaco-header-pedidos subheader-transparent" id="kt_subheader">
          <div className="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
            {/*begin::Details*/}
            <div className="d-flex align-items-center flex-wrap mr-2">
              {/*begin::Title*/}
              <h5 className="text-dark font-weight-bold mt-2 mb-2 mr-5">Avisos</h5>
              {/*end::Title*/}
              {/*begin::Separator*/}
              <div className="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200" />
              {/*end::Separator*/}
            </div>
            {/*end::Details*/}
            {/*begin::Toolbar*/}
            <div className="d-flex align-items-center">
              <button className="btn btn-lg btn-success mt-3 mb-3" onClick={e => {adicionarAviso()}}><i className="fas fa-plus" />Adicionar</button>
            </div>
            {/*end::Toolbar*/}
          </div>
        </div>
        {/*begin::Entry*/}
        <div className="d-flex flex-column-fluid">
          {/*begin::Container*/}
          <div className="container">
            <div className="card card-custom">
              <div className="ml-10 mr-10 mt-10">    
                <div className="row">
                <div className="col-md-4 form-group">
                    <label>Estabelecimento</label>
                    <Select
                      options={estabelecimentoSelect}
                      placeholder="Selecione..." 
                      defaultValue={estabelecimentoSelect.find(
                        x => x.value === buscaEstabelecimento
                      )}
                      onChange={(e) => { setBuscaEstabelecimento(e.value) }}             
                    /> 
                  </div>
                  <div className="col-md-3 form-group">
                    <label>Cidade</label>
                    <Select
                      options={cidadeSelect}
                      placeholder="Selecione..." 
                      defaultValue={cidadeSelect.find(
                        x => x.value === buscaCidade
                      )}
                      onChange={(e) => { setBuscaCidade(e.value) }} 
                
                    /> 
                  </div>
                  <div className="col-md-3 form-group">
                    <label>Representante</label>
                    <Select
                      options={representanteSelect}
                      placeholder="Selecione..." 
                      defaultValue={representanteSelect.find(
                        x => x.value === buscaRepresentante
                      )}
                      onChange={(e) => { setBuscaRepresentante(e.value) }} 
                
                    /> 
                  </div>

                   

                  <div className="col-md-2 form-group">
                  <label>&nbsp;</label><br />
                    <button className="btn btn-block btn-primary" onClick={e=>{filtrar()}}><i className="fas fa-filter" />  Filtrar</button>
                  </div> 
                </div>     
              </div>

              <LoadingOverlay
                            active={caProcessa}
                            spinner
                            text='Carregando...'
                          >                     
              <div className="container">
                <table className="table table-striped">
                  <thead className="bg-light-primary">
                    <tr>
                      <th>Aviso</th>
                      <th>Estabelecimento</th>
                      <th>Cidade</th>
                      <th>Representate</th>
                      <th className="text-center">Ações</th>  
                    </tr>
                  </thead>
                  <tbody>
                    {aviso.map(item => {
                      return <>
                      <tr>
                        <td>{item.id_aviso}</td>
                        <td>{item.id_estabelecimento}</td>
                        <td>{item.id_cidade}</td>
                        <td>{item.id_representante}</td>
                        <td className="text-center">
                            <div class="dropdown dropdown-inline">
                              <button type="button" class="btn btn-light-primary btn-icon btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                  <i class="ki ki-bold-more-ver"></i>
                              </button>
                              <div class="dropdown-menu">
                                <span class="dropdown-item" onClick={e => { editar(item) }}>Editar</span>
                                {acesso.avisos_ei === "1" && <>
                                  <div class="dropdown-divider"></div>
                                    <span class="dropdown-item" onClick={e => { excluir(item) }}>Excluir</span>
                                </>}
                              </div>
                            </div>

                          </td>
                      </tr>
                      </>
                    })}
                  </tbody>
                </table>
              </div>              
              </LoadingOverlay> 
    
                    
      
                  </div>
                  </div>
                  </div>
                  </div>
                  
                  <Modal size={'xl'} show={modal} onHide={() => fecharModal()}>
                    <Modal.Header>
                    <Modal.Title>Avisos</Modal.Title>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={e => {fecharModal()}}>
                          <i aria-hidden="true" className="ki ki-close" />
                        </button>
                    </Modal.Header>
                    <div className="row mt-5 ml-5 mr-5">
                      <div className="form-group col-md-12">
                        <label>Estabelecimento</label>
                        <Select options={estabelecimentoSelect}
                          placeholder="Selecione..."
                          isMulti
                          defaultValue={formAviso.id_estabelecimento?.map((item) => { return(
                            estabelecimentoSelect.find(
                              x => x.value === item
                              )
                            )}
                          )}
                          onChange={(e) => { setFormAviso({...formAviso, id_estabelecimento: e}) }} 
                        />
                      </div>
                    </div>

                    <div className="row ml-5 mr-5">
                      <div className="form-group col-md-6">
                        <label>Cidade</label>
                        <Select options={cidadeSelect}
                          placeholder="Selecione..."
                          isMulti
                          defaultValue={formAviso.id_cidade?.map((item) => { return(
                            cidadeSelect.find(
                              x => x.value === item
                              )
                            )}
                          )}
                          onChange={(e) => { setFormAviso({...formAviso, id_cidade: e}) }} 
                        /> 
                      </div>

                      <div className="form-group col-md-6">
                        <label>Representante</label>
                        <Select options={representanteSelect}
                          placeholder="Selecione..."
                          isMulti
                          defaultValue={formAviso.id_representante?.map((item) => { return(
                            representanteSelect.find(
                              x => x.value === item
                              )
                            )}
                          )}
                          onChange={(e) => { setFormAviso({...formAviso, id_representante: e}) }} 
                        />  
                      </div>
                      
                      <div className="form-group col-md-12">
                        <label>Aviso</label>
                        <CKEditor
                          editor={ ClassicEditor }
                          data={formAviso.texto}
                          onChange={( event, editor ) => { setFormAviso({...formAviso, texto: editor.getData()}) }}            
                        />
                      </div>
                    
                    </div>    
                    
                    <Modal.Footer>
                      <button type="button" onClick={e => { fecharModal(); }} className="btn btn-light-primary font-weight-bold" data-dismiss="modal"><i className="fas fa-door-open" />Fechar</button>
                      <button type="button" onClick={e => { salvar(); }} className="btn btn-success font-weight-bold"><i className="fas fa-save" />Salvar</button>
                    </Modal.Footer>
                  </Modal>
        </>
    );

}



export default withRouter(Avisos);                  
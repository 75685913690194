export const useSetupAreaTableColumns = (excluirBairro) => {
  const columnsBairros = [
    {
      name: "Cidade",
      selector: "nome_cidade",
      sortable: true,
      width: "25%",
      cell: (row) => (
        <>
          {row.nome_cidade}-{row.estado}
        </>
      ),
    },
    {
      name: "Bairro",
      selector: "nome_bairro",
      sortable: true,
      width: "20%",
    },
    {
      name: "Compra Mínima",
      selector: "valor_minimo",
      sortable: true,
      width: "15%",
      right: true,
    },
    {
      name: "Taxa de Entrega",
      selector: "valor_taxa",
      sortable: true,
      width: "15%",
      right: true,
    },
    {
      name: "Frete Grátis",
      selector: "valor_frete_gratis",
      sortable: true,
      width: "15%",
      right: true,
    },
    {
      name: "Ações",
      cell: (row) => {
        return (
          <>
            <button
              type="button"
              className="btn btn-light-primary btn-icon btn-sm"
              onClick={(e) => {
                excluirBairro(row);
              }}
            >
              <i className="far fa-trash-alt"></i>
            </button>
          </>
        );
      },
      ignoreRowClick: true,
      allowOverflow: true,
    },
  ];

  return {
    columnsBairros,
  };
};

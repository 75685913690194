import React from "react";

import { Modal } from "react-bootstrap";
import ReactGoogleAutocomplete from "react-google-autocomplete";
import { GoogleMap, Marker } from "@react-google-maps/api";

export const SearchLocationModal = ({
  isOpen,
  addressLabel,
  setIsModalOpened,
  location,
  setLocation,
  updateLocation,
}) => {
  const options = {
    types: ["geocode", "establishment"],
    fields: ["formatted_address", "geometry", "geometry.location"],
  };

  const onPlaceSelected = (place) => {
    if (!place) {
      return;
    }

    const latitude = place.geometry.location.lat();
    const longitude = place.geometry.location.lng();

    setLocation({ latitude, longitude });
  };

  return (
    <Modal centered show={isOpen} onHide={() => setIsModalOpened(false)}>
      <Modal.Header>
        <Modal.Title>Pesquisar Latitude/Longitude</Modal.Title>

        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={() => {
            setIsModalOpened(false);
          }}
        >
          <i aria-hidden="true" className="ki ki-close" />
        </button>
      </Modal.Header>

      <Modal.Body>
        <p className="texto-msg-top">Endereço informado: {addressLabel}</p>

        <ReactGoogleAutocomplete
          apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
          onPlaceSelected={onPlaceSelected}
          options={options}
          defaultValue={addressLabel}
          placeholder="Digite o endereço correto aqui"
          language="pt-BR"
          style={{
            width: "100%",
            padding: "16.5px 14px",
            height: "50px",
            fontSize: "16px",
            borderColor: "red",
            margin: "0px 0px 20px 0px",
          }}
        />

        {(!location.latitude || !location.longitude) && (
          <b>ENDEREÇO NÃO ENCONTRADO, BUSQUE NOVAMENTE ACIMA!</b>
        )}

        <br />
        <br />

        <div style={{ height: "300", width: "100%" }}>
          <GoogleMap
            mapContainerStyle={{
              width: "100%",
              height: "400px",
            }}
            center={{ lat: location.latitude, lng: location.longitude }}
            zoom={18}
            onLoad={() => {}}
            onUnmount={() => {}}
          >
            <Marker
              onLoad={() => {}}
              position={{ lat: location.latitude, lng: location.longitude }}
              draggable={true}
            />
          </GoogleMap>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <button
          type="button"
          onClick={() => {
            updateLocation();
            setIsModalOpened(false);
          }}
          className="btn btn-sm btn-success mr-2"
        >
          <i className="fas fa-check" />
          Confirma o endereço
        </button>

        <button
          type="button"
          onClick={() => {
            setIsModalOpened(false);
          }}
          className="btn btn-sm btn-secondary mr-2"
        >
          <i className="fas fa-door-open" />
          Fechar
        </button>
      </Modal.Footer>
    </Modal>
  );
};

import React, { useState, useEffect, useCallback } from 'react';
import Api from '../../../services/api';
import { withRouter } from "react-router";

import Swal from "sweetalert2";
import { useToasts } from 'react-toast-notifications';

import DataTable from 'react-data-table-component';
import Checkbox from '@material-ui/core/Checkbox';
import LoadingOverlay from 'react-loading-overlay';
import Select from 'react-select';

import "react-datepicker/dist/react-datepicker.css";
import DatePicker, { registerLocale } from "react-datepicker";
import InputMask from 'react-input-mask';
import pt from "date-fns/locale/pt-BR"; 
registerLocale("pt-BR", pt);

const Pendencias = () => {
  const { addToast } = useToasts();
  const [ pendencias, setPendencias ] = useState([]);
  const [ estabelecimentos, setEstabelecimentos ] = useState([]);
  const [ estabelecimento, setEstabelecimento ] = useState();
  const [ dataInicial, setDataInicial ] = useState();
  const [ dataFinal, setDataFinal ] = useState(new Date());
  const [ status, setStatus ] = useState('Pendente');
  const [ caProcessa, setCaProcessa ] = useState(false);
  const [ valores, setValores ] = useState([]);
  const [ exibeCheck, setExibeCheck ] = useState(true);

  const state = { toggledClearRows: false }
  const handleChange = (state) => {
    setValores(state.selectedRows);
  };

  // const handleClearRows = () => {
  //   this.setState({ toggledClearRows: !this.state.toggledClearRows})
  // }

  const data = pendencias;
  const columns = [
    {
      name: <th>Pendência</th>,
      selector: 'id_pendencias',
      sortable: true,
      width: '8%',
      right: true,
    },
    {
      name: <th>Tabela</th>,
      selector: 'tabela',
      width: '12%',
      sortable: true,
    },
    {
      name: <th>Nome Campo</th>,
      selector: 'nome_campo',
      width: '15%',
      sortable: true,
    },
    {
      name: <th>Valor Anterior</th>,
      cell: row => row.nome_campo == 'logotipo' || row.nome_campo == 'banner' ? <img alt="icon valor" src={row.valor_ant} width="100px" /> : row.valor_ant,
      selector: 'valor_ant',
      sortable: false,
      width: '23%',
    },
    {
      name: <th>Valor Posterior</th>,
      cell: row => row.nome_campo == 'logotipo' || row.nome_campo == 'banner' ? <img alt="icon valor" src={row.valor_post} width="100px" /> : row.valor_post,
      selector: 'valor_post',
      sortable: false,
      width: '23%',
    },
    {
      name: <th>Data</th>,
      sortable: true,
      width: '13%',
      cell: row => row.status == 'Pendente' ? 
       <div>{row.data_inclusao}</div> : <div>{row.data_status}</div>
      },
  ];

  function getEstabelecimentoSelect() {
    var data = {
      representante: localStorage.getItem('@ADM-Aiboo/nivel') == 'R' ? localStorage.getItem('@ADM-Aiboo/id_representante') : ''
    }

    Api.post("estabelecimentos/listaNomeEstabelecimento", data).then(rps => {
      var est = rps.data.obj;
      var nEst = [];
      est.forEach(e => {
        nEst[nEst.length] = {value: e.id_estabelecimento, label: e.nome};
      })
      setEstabelecimentos(nEst);
    })
  }

  const filtrar = useCallback(() => {
    setCaProcessa(true); 
      var data = {
        data_inicio: dataInicial,
        data_final: dataFinal,
        status: status,
        representante: localStorage.getItem('@ADM-Aiboo/nivel') == 'R' ? localStorage.getItem('@ADM-Aiboo/id_representante') : '',
      };

      if(status=='Pendente') {
        setExibeCheck(true);
      } else {
        setExibeCheck(false);
      }
    
      Api.post('pendencias/pesquisar', data).then((rps) => {
        
        if(rps.data.status==false){
          Swal.fire({
            title: "Atenção!",
            icon: "info",
            html: rps.data.erro,
            showCloseButton: true,
            showCancelButton: false,
          });
        } else {
          setPendencias(rps.data.obj);
        }
      setCaProcessa(false); 
    });
  }, [dataFinal, dataInicial, status]);

  useEffect(() => {
    filtrar();
    getEstabelecimentoSelect();
  }, [filtrar]);

  function aprovarSts(){
    var data = { selecionado : valores }
          
    Api.post("pendencias/aprovar", data).then((rps) => {
      addToast(rps.data.mensagem, {
        appearance: "success",
        autoDismiss: true,
        autoDismissTimeout: 2000
      });

      //Atualizar a tabela apenas com Pendentes
      window.location.reload();
    });
  }

  function reprovarSts(){
    var data = { selecionado : valores }
          
    Api.post("pendencias/reprovar", data).then((rps) => {
      addToast(rps.data.mensagem, {
        appearance: "success",
        autoDismiss: true,
        autoDismissTimeout: 2000
      });

      //Atualizar a tabela apenas com Pendentes
      window.location.reload();
    });
  }
    
    return (
        <>
<div>

<div className="subheader espaco-header-pedidos subheader-transparent" id="kt_subheader">
          <div className="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
            {/*begin::Details*/}
            <div className="d-flex align-items-center flex-wrap mr-2">
              {/*begin::Title*/}
              <h5 className="text-dark font-weight-bold mt-2 mb-2 mr-5">Pendências</h5>
              {/*end::Title*/}
              {/*begin::Separator*/}
              <div className="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200" />
              {/*end::Separator*/}
            </div>
            {/*end::Details*/}
            {/*begin::Toolbar*/}
            <div className="d-flex align-items-center">

            </div>
            {/*end::Toolbar*/}
          </div>
        </div>
        {/*begin::Entry*/}
        <div className="d-flex flex-column-fluid">
          {/*begin::Container*/}
          <div className="container">
            <div className="card card-custom">
              <div className="ml-10 mr-10 mt-10">    
                <div className="row">
                  <div className="col-md-5 form-group">
                    <label>Estabelecimento</label>
                    <Select
                      options={estabelecimentos}
                      placeholder="Selecione..." 
                      defaultValue={estabelecimentos.find(
                        x => x.value === estabelecimento
                      )}
                      onChange={(e) => {setEstabelecimento(e) }}
                    />
                  </div>

                  <div className="col-md-2 form-group">
                    <label>Data de</label>
                      <div className="input-group">
                      <DatePicker 
                        name="dt_inicio"
                        onChange={setDataInicial}
                        isClearable
                        selectsStart
                        locale="pt-BR"
                        className="form-control"
                        placeholderText="dd/mm/aaaa" 
                        selected ={dataInicial}
                        dateFormat="dd/MM/y"
                        startDate={dataInicial}
                        endDate={dataFinal}
                        customInput={
                          <InputMask mask="99/99/9999" />}/>
                      </div>
                  </div>

                  <div className="col-md-2 form-group">
                    <label>Data até</label>
                      <div className="input-group">
                      <DatePicker 
                        name="dt_final"
                        onChange={setDataFinal}
                        isClearable
                        locale="pt-BR"
                        className="form-control"
                        placeholderText="dd/mm/aaaa" 
                        selected ={dataFinal}
                        dateFormat="dd/MM/y"
                        selectsEnd
                        startDate={dataInicial}
                        endDate={dataFinal}
                        minDate={dataInicial} 
                        customInput={
                          <InputMask mask="99/99/9999" />}/>
                      </div>
                  </div> 

                  <div className="col-md-2 form-group">
                    <label>Status</label>
                      <select className="form-control" value={status} onChange={(e) => { setStatus(e.target.value) }}>
                        <option value="Efetuado">Efetuado</option>
                        <option value="Pendente">Pendente</option>
                        <option value="Rejeitado">Rejeitado</option>
                      </select>
                  </div>  

                  <div className="col-md-1 form-group">
                  <label>&nbsp;</label><br />
                    <button className="btn btn-primary" onClick={e=>{filtrar()}}>Filtrar</button>
                  </div> 
                </div>     
              </div>
                    
              <div className="card-body">
                <div className="justify-content-center">     
                  <div className="btn-group">
                    <button type="button" className="btn btn-success font-weight-bolder">Alterar Selecionados</button>
                    <button type="button" className="btn btn-success dropdown-toggle dropdown-toggle-split" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <span className="sr-only">Toggle Dropdown</span>
                    </button>
                    <div className="dropdown-menu">
                      <span onClick={e=>{aprovarSts()}} className="dropdown-item">
                        <i className="fas fa-power-off text-success mr-3" /> Aprovar Pendências Selecionadas
                      </span>
                      <span onClick={e=>{reprovarSts()}}  className="dropdown-item">
                        <i className="fas fa-power-off text-danger mr-3" />  Reprovar Pendências Selecionadas
                      </span>
                    </div>
                  </div>             
                </div>
              </div>

              <LoadingOverlay
                active={caProcessa}
                spinner
                text='Carregando...'
              > 
              { data != null && 
              <DataTable
                title="Lista de Pendências"
                columns={columns}
                data={data}
                striped="true"
                selectableRows={exibeCheck == true ? true : false } // Apenas os que forem pendentes podem ser selecionados
                onSelectedRowsChange={handleChange}
                clearSelectedRows={state.toggledClearRows}
                selectableRowsComponent={Checkbox} 
                selectableRowsComponentProps={{ inkDisabled: true }} 
                pagination="true"
              />
            }
              </LoadingOverlay>                   
      
                  </div>
                  </div>
                  </div>
                  </div>
                  
                 
        </>
    );

}



export default withRouter(Pendencias);                  
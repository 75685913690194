import React from "react";

import * as S from "./styles";

export const DropDownCategories = ({
  register,
  selectedCategory,
  setSelectedCategory,
  categoriesOptions,
  requiredError,
  handleToggleCreateProductCategoryModal,
}) => (
  <S.Container>
    <S.DropDownInputButton
      {...register("category", {
        required: true,
      })}
      styles={{
        control: () => ({
          border: "1px solid",
          borderColor: requiredError ? "#FF3333" : "#e5eaee",
          borderRadius: 12,
          display: "flex",
          flexDirection: "row",
          height: 45,
        }),
      }}
      value={selectedCategory}
      className="w-25"
      placeholder="Selecione"
      options={categoriesOptions}
      onChange={setSelectedCategory}
    />

    {requiredError && <span>Preencha o campo "Categoria"</span>}

    <S.AddCategoryTextButton onClick={handleToggleCreateProductCategoryModal}>
      <i className="ki ki-plus " />
      <label>Adicionar nova categoria</label>
    </S.AddCategoryTextButton>
  </S.Container>
);

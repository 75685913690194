import styled from "styled-components";

const statusBadgeBackground = {
  pending: "#d9d9d9",
  signed: "#00B333",
  rejected: "#ff3333",
};

export const SubscriberWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: space-between;
`;

export const SubscriberTitleWrapper = styled.div`
  gap: 5px;
  display: flex;
  align-items: center;

  i {
    color: #333333;
    width: 24px;
  }

  label {
    font-size: 16px;
    font-weight: 600;
    margin: 0;
  }
`;

export const SubscriberEmail = styled.small`
  color: #555555;
  font-size: 12px;
  font-weight: 600;
`;

export const SubscriberStatusBadge = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #d9d9d9;
  background: ${({ status }) => statusBadgeBackground[status]};
  color: ${({ status }) => (status === "pending" ? "#333333" : "#ffffff")};
  border-radius: 4px;
  width: 100px;
  height: 20px;
`;

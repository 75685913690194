import React, { useState, useEffect, useCallback } from "react";
import Api from "../../../services/api";
import PaymentsApi from "../../../services/payments-api";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";

import Swal from "sweetalert2";
import { useToasts } from "react-toast-notifications";
import LoadingOverlay from "react-loading-overlay";

import { Modal } from "react-bootstrap";
import Select from "react-select";
import ReactTooltip from "react-tooltip";

import "react-perfect-scrollbar/dist/css/styles.css";

import "./index.scss";

const Paginacao = ({ currentPage, pageCount, handlePageClick }) => {
  const renderRow = (row) => {
    return <span>{row}</span>;
  };

  let rows = [];

  if (currentPage !== 1) {
    rows.push(
      <span
        onClick={() => handlePageClick(currentPage - 1)}
        className="btn btn-icon btn-sm btn-light-primary mr-2 my-1"
      >
        <i className="ki ki-bold-arrow-back icon-xs"></i>
      </span>
    );
  } else {
    rows.push(
      <span className="btn-icon btn-sm btn-secondary disabled mr-2 my-1">
        <i className="ki ki-bold-arrow-back icon-xs"></i>
      </span>
    );
  }

  if (currentPage > 3) {
    rows.push(
      <>
        <span
          onClick={() => handlePageClick(1)}
          className="btn btn-icon btn-sm btn-light-primary mr-2 my-1"
        >
          1
        </span>
        <span className="btn-icon btn-sm btn-secondary disabled mr-2 my-1">
          ...
        </span>
      </>
    );
  }

  for (let i = currentPage - 2; i < currentPage + 3; i++) {
    if (i > 0 && i <= pageCount) {
      if (i === currentPage) {
        rows.push(
          <span
            onClick={() => handlePageClick(i)}
            className="btn btn-icon btn-sm border-0 btn-hover-primary active mr-2 my-1"
          >
            {i}
          </span>
        );
      } else {
        rows.push(
          <span
            onClick={() => handlePageClick(i)}
            className="btn btn-icon btn-sm border-0 btn-hover-primary mr-2 my-1"
          >
            {i}
          </span>
        );
      }
    }
  }

  if (currentPage + 3 <= pageCount) {
    rows.push(
      <>
        <span className="btn-icon btn-sm btn-secondary disabled mr-2 my-1">
          ...
        </span>
        <span
          onClick={() => handlePageClick(pageCount)}
          className="btn btn-icon btn-sm btn-light-primary mr-2 my-1"
        >
          {pageCount}
        </span>
      </>
    );
  }

  if (currentPage !== pageCount) {
    rows.push(
      <span
        onClick={() => handlePageClick(currentPage + 1)}
        className="btn btn-icon btn-sm btn-light-primary mr-2 my-1"
      >
        <i className="ki ki-bold-arrow-next icon-xs"></i>
      </span>
    );
  } else {
    rows.push(
      <span className="btn-icon btn-sm btn-secondary disabled mr-2 my-1">
        <i className="ki ki-bold-arrow-next icon-xs"></i>
      </span>
    );
  }

  if (rows.length === 0) {
    return <p>Nenhum item</p>;
  }
  return <div>{rows.map(renderRow)}</div>;
};

const Estabelecimentos = () => {
  const { addToast } = useToasts();
  const [estabelecimentos, setEstabelecimentos] = useState([]);
  const [totalRegistros, setTotalRegistros] = useState();
  const [caProcessa, setCaProcessa] = useState(false);
  const [buscaID, setBuscaID] = useState();
  const [buscaEstabelecimento, setBuscaEstabelecimento] = useState();
  const [buscaCidade, setBuscaCidade] = useState();
  const [buscaRepresentante, setBuscaRepresentante] = useState();
  const [buscaStatus, setBuscaStatus] = useState("S");
  const [buscaPgOnline, setBuscaPgOnline] = useState();
  const [buscaExclusivo, setBuscaExclusivo] = useState();
  const [cidadeSelect, setCidadeSelect] = useState([]);
  const [modalValidaRE, setModalValidaRE] = useState(false);
  const [representanteSelect, setRepresentanteSelect] = useState([]);
  const [exibeValidacao, setExibeValidacao] = useState();
  const [ordenacao, setOrdenacao] = useState("id_estabelecimento");
  const [ordenacaoSet, setOrdenacaoSet] = useState();
  const [currentPage, setCurrentPage] = useState(1);

  const PER_PAGE = 10;
  const pageCount = Math.ceil(totalRegistros / PER_PAGE);

  let acesso = JSON.parse(localStorage.getItem("@ADM-Aiboo/perfil"));

  function fecharModalValidaRE() {
    setModalValidaRE(false);
  }

  function validarRE(id_estabelecimento) {
    setModalValidaRE(true);

    var data = {
      id_estabelecimento: id_estabelecimento,
    };

    Api.post("financeiro/habilitaFinanceiro", data).then((rps) => {
      setExibeValidacao(rps.data);
    });
  }

  async function updateStoreStatus(idEstabelecimento, status) {
    const response = await Api.post("estabelecimentos/mudaAberturaFechamento", {
      id_estabelecimento: idEstabelecimento,
      fechado: status ? "N" : "S",
    });
    if (response.data.status !== undefined && response.data.status === true) {
      addToast(response.data.mensagem, {
        appearance: "success",
        autoDismiss: true,
        autoDismissTimeout: 2000,
      });
      getListar();
    } else {
      Swal.fire({
        title: "Atenção!",
        icon: "info",
        html: response.data.mensagem,
        showCloseButton: true,
        showCancelButton: false,
      });
    }
  }

  const getListar = useCallback(() => {
    setCaProcessa(true);

    var data = {
      limite: PER_PAGE,
      start: 0,
      ordenacao: "id_estabelecimento",
      sentido: "ASC",
      status: buscaStatus,
      representante:
        localStorage.getItem("@ADM-Aiboo/nivel") == "R"
          ? localStorage.getItem("@ADM-Aiboo/id_representante")
          : "",
    };

    Api.post("estabelecimentos/filtrar", data).then((rps) => {
      setEstabelecimentos(rps.data.obj);
      setTotalRegistros(rps.data.totalRegistros);
      //Verifico se existe session
      if (sessionStorage.getItem("estabelecimento") > 0) {
        var data = {
          limite: PER_PAGE,
          start: 0,
          ordenacao: "id_estabelecimento",
          sentido: "ASC",
          id_estabelecimento: sessionStorage.getItem("estabelecimento"),
        };

        Api.post("estabelecimentos/filtrar", data).then((rps) => {
          sessionStorage.setItem("estabelecimento", 0);
        });
      }
      setCaProcessa(false);
    });
  }, [buscaStatus]);

  function handlePageClick(pagina) {
    setCaProcessa(true);
    setCurrentPage(pagina);
    var data = {
      limite: PER_PAGE,
      start: (pagina - 1) * PER_PAGE,
      id_estabelecimento: buscaID,
      estabelecimento: buscaEstabelecimento,
      cidade: buscaCidade,
      representante:
        localStorage.getItem("@ADM-Aiboo/nivel") == "R"
          ? localStorage.getItem("@ADM-Aiboo/id_representante")
          : buscaRepresentante,
      status: buscaStatus,
      aceita_pgto_online: buscaPgOnline,
      exclusivo_aiboo: buscaExclusivo,
      ordenacao: ordenacao,
      sentido: ordenacaoSet,
    };

    Api.post("estabelecimentos/filtrar", data).then((rps) => {
      setEstabelecimentos(rps.data.obj);
      setCaProcessa(false);
    });
  }

  function painelNovoNovo(idEstabelecimento) {
    var data = {
      idEstabelecimento: idEstabelecimento,
    };

    Api.post("estabelecimentos/logarComoNovo", data).then((rps) => {
      window.open(
        `${process.env.REACT_APP_PARTNER_PANEL_URL}` +
          rps.data.p1 +
          "/" +
          rps.data.p2,
        "_blank"
      );
    });
  }

  function disablePayments(entity) {
    var url = entity.id_estabelecimento + "/payments/disable-forced";

    PaymentsApi.post(url).then((rps) => {
      var message =
        "Pagamento Online desativado com sucesso para o estabelecimento " +
        entity.nome;
      addToast(message, {
        appearance: "success",
        autoDismiss: true,
        autoDismissTimeout: 2000,
      });
    });
  }

  function excluirSessao(id_estabelecimento) {
    var ca = JSON.parse(JSON.stringify(id_estabelecimento));

    setCaProcessa(true);

    Swal.fire({
      icon: "question",
      title: "Confirmação",
      html: "Deseja excluir a sessão?",
      showCancelButton: true,
      confirmButtonText: `Sim, Confirmar!`,
      cancelButtonText: "Não, Cancelar.",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        Api.post("estabelecimentos/limparSessao", ca).then((rps) => {
          if (rps.data.status == true) {
            addToast(rps.data.mensagem, {
              appearance: "success",
              autoDismiss: true,
              autoDismissTimeout: 2000,
            });
            getListar();
          }
        });
      }
      setCaProcessa(false);
    });
  }

  function filtrar(order, fechou) {
    var sentido = "ASC";

    if (order != null || order != undefined) {
      setOrdenacao(order);
    } else {
      //Valor padrão para a primeira vez
      setOrdenacao("id_estabelecimento");
      order = "id_estabelecimento";
    }

    if (order === ordenacao) {
      //Caso a ordem atual seja igual a anterior e não veio do modal
      if (fechou == "N") {
        ordenacaoSet === "ASC"
          ? setOrdenacaoSet("DESC")
          : setOrdenacaoSet("ASC");
        ordenacaoSet === "ASC" ? (sentido = "DESC") : (sentido = "ASC");
      }
    } else {
      //Caso a ordem atual não seja igual a anterior DESC
      setOrdenacaoSet("DESC");
      sentido = "DESC";
    }

    setCaProcessa(true);
    var data = {
      limite: PER_PAGE,
      start: 0,
      id_estabelecimento: buscaID,
      estabelecimento: buscaEstabelecimento,
      cidade: buscaCidade,
      representante:
        localStorage.getItem("@ADM-Aiboo/nivel") == "R"
          ? localStorage.getItem("@ADM-Aiboo/id_representante")
          : buscaRepresentante,
      status: buscaStatus,
      aceita_pgto_online: buscaPgOnline,
      exclusivo_aiboo: buscaExclusivo,
      ordenacao: order,
      sentido: sentido,
    };

    Api.post("estabelecimentos/filtrar", data).then((rps) => {
      if (rps.data.status == false) {
        Swal.fire({
          title: "Atenção!",
          icon: "info",
          html: rps.data.erro,
          showCloseButton: true,
          showCancelButton: false,
        });
      } else {
        setEstabelecimentos(rps.data.obj);
        setTotalRegistros(rps.data.totalRegistros);
      }
      setCaProcessa(false);
    });
  }

  function getCidadeSelect() {
    var data = {
      representante:
        localStorage.getItem("@ADM-Aiboo/nivel") == "R"
          ? localStorage.getItem("@ADM-Aiboo/id_representante")
          : "",
    };

    Api.post("cidades/listarcidaderepresentante", data).then((rps) => {
      var cid = rps.data.cidades;
      var nCid = [];
      nCid[nCid.length] = { value: "", label: "Selecione..." };
      cid.forEach((e) => {
        nCid[nCid.length] = {
          value: e.id_cidade,
          label: e.nome_cidade + " - " + e.estado,
        };
      });
      setCidadeSelect(nCid);
    });
  }

  function getRepresentanteSelect() {
    var data = {
      representante:
        localStorage.getItem("@ADM-Aiboo/nivel") == "R"
          ? localStorage.getItem("@ADM-Aiboo/id_representante")
          : "",
    };

    Api.post("estabelecimentos/listarepresentante", data).then((rps) => {
      var rep = rps.data.obj;
      var nRep = [];
      nRep[nRep.length] = { value: "", label: "Selecione..." };
      rep.forEach((e) => {
        nRep[nRep.length] = { value: e.id_representante, label: e.nome };
      });
      setRepresentanteSelect(nRep);
    });
  }

  useEffect(() => {
    getCidadeSelect();
    getRepresentanteSelect();
    getListar();
  }, [getListar]);

  return (
    <>
      <div>
        <div
          className="subheader espaco-header-pedidos subheader-transparent"
          id="kt_subheader"
        >
          <div className="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
            {/*begin::Details*/}
            <div className="d-flex align-items-center flex-wrap mr-2">
              {/*begin::Title*/}
              <h5 className="text-dark font-weight-bold mt-2 mb-2 mr-5">
                Gerenciar Estabelecimentos
              </h5>
              {/*end::Title*/}
              {/*begin::Separator*/}
              <div className="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200" />
              {/*end::Separator*/}
            </div>
            {/*end::Details*/}
            {/*begin::Toolbar*/}
            <div className="d-flex align-items-center mt-2">
              <Link
                className="btn btn-lg btn-success"
                to="/estabelecimentos/estabelecimento"
              >
                <i className="fas fa-plus" />
                Adicionar
              </Link>
            </div>
            {/*end::Toolbar*/}
          </div>
        </div>
        {/*begin::Entry*/}
        <div className="d-flex flex-column-fluid">
          {/*begin::Container*/}
          <div className="container">
            <div className="card card-custom">
              <div className="ml-10 mr-10 mt-10">
                <div className="row">
                  <div className="form-group col-md-1">
                    <label>ID</label>
                    <div>
                      <input
                        className="form-control"
                        value={buscaID}
                        onChange={(e) => {
                          setBuscaID(e.target.value);
                        }}
                        placeholder="ID"
                      />
                    </div>
                  </div>
                  <div className="form-group col-md-2">
                    <label>Estabelecimento</label>
                    <div>
                      <input
                        className="form-control"
                        value={buscaEstabelecimento}
                        onChange={(e) => {
                          setBuscaEstabelecimento(e.target.value);
                        }}
                        placeholder="Nome ou parte do nome"
                      />
                    </div>
                  </div>
                  <div className="form-group col-1">
                    <label>Pg. Online</label>
                    <select
                      className="form-control"
                      value={buscaPgOnline}
                      onChange={(e) => {
                        setBuscaPgOnline(e.target.value);
                      }}
                    >
                      <option value="">Todos</option>
                      <option value="1">Ativo</option>
                      <option value="0">Inativo</option>
                    </select>
                  </div>
                  <div className="form-group col-1">
                    <label>Exclusivo</label>
                    <select
                      className="form-control"
                      value={buscaExclusivo}
                      onChange={(e) => {
                        setBuscaExclusivo(e.target.value);
                      }}
                    >
                      <option value="">Todos</option>
                      <option value="1">Sim</option>
                      <option value="0">Não</option>
                    </select>
                  </div>
                  <div className="form-group col-md-3">
                    <label>Cidade</label>
                    <div>
                      <Select
                        options={cidadeSelect}
                        placeholder="Selecione..."
                        defaultValue={cidadeSelect.find(
                          (x) => x.value === buscaCidade
                        )}
                        onChange={(e) => {
                          setBuscaCidade(e.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="form-group col">
                    <label>Representante</label>
                    <Select
                      options={representanteSelect}
                      placeholder="Selecione..."
                      defaultValue={representanteSelect.find(
                        (x) => x.value === buscaRepresentante
                      )}
                      onChange={(e) => {
                        setBuscaRepresentante(e.value);
                      }}
                    />
                  </div>
                  <div className="form-group col-1">
                    <label>Status</label>
                    <select
                      className="form-control"
                      value={buscaStatus}
                      onChange={(e) => {
                        setBuscaStatus(e.target.value);
                      }}
                    >
                      <option value="">Todos</option>
                      <option value="S">Ativo</option>
                      <option value="N">Inativo</option>
                    </select>
                  </div>

                  <div className="col form-group">
                    <label>&nbsp;</label>
                    <br />
                    <button
                      className="btn btn-primary btn-block"
                      onClick={(e) => {
                        filtrar();
                      }}
                    >
                      <i className="fas fa-filter" /> Filtrar
                    </button>
                  </div>
                </div>
              </div>

              <LoadingOverlay active={caProcessa} spinner text="Carregando...">
                <table className="table table-striped">
                  <thead className="bg-light-primary">
                    <tr>
                      <th className="text-primary text-center" width="5%">
                        <span
                          className="text-hover-dark"
                          onClick={(e) => {
                            filtrar("id_estabelecimento", "N");
                          }}
                        >
                          ID{" "}
                          {ordenacao === "id_estabelecimento" ? (
                            <i className="fas fa-sort-down text-primary" />
                          ) : (
                            <i className="fas fa-sort text-primary" />
                          )}
                        </span>
                      </th>
                      <th className="text-primary" width="20%">
                        <span
                          className="text-hover-dark"
                          onClick={(e) => {
                            filtrar("nome", "N");
                          }}
                        >
                          Estabelecimento{" "}
                          {ordenacao === "nome" ? (
                            <i className="fas fa-sort-down text-primary" />
                          ) : (
                            <i className="fas fa-sort text-primary" />
                          )}
                        </span>
                      </th>
                      <th width="10%">Icones</th>
                      <th className="text-primary" width="15%">
                        <span
                          className="text-hover-dark"
                          onClick={(e) => {
                            filtrar("nome_cidade", "N");
                          }}
                        >
                          Cidade - UF{" "}
                          {ordenacao === "nome_cidade" &&
                          ordenacaoSet === "ASC" ? (
                            <i className="fas fa-sort-down text-primary" />
                          ) : (
                            <i className="fas fa-sort text-primary" />
                          )}
                        </span>
                      </th>
                      <th className="text-primary text-center" width="8%">
                        <span
                          className="text-hover-dark"
                          onClick={(e) => {
                            filtrar("fechado", "N");
                          }}
                        >
                          Abertura{" "}
                          {ordenacao === "fechado" ? (
                            <i className="fas fa-sort-down text-primary" />
                          ) : (
                            <i className="fas fa-sort text-primary" />
                          )}
                        </span>
                      </th>
                      <th className="text-primary text-center" width="10%">
                        <span
                          className="text-hover-dark"
                          onClick={(e) => {
                            filtrar("nota", "N");
                          }}
                        >
                          Nota{" "}
                          {ordenacao === "nota" ? (
                            <i className="fas fa-sort-down text-primary" />
                          ) : (
                            <i className="fas fa-sort text-primary" />
                          )}
                        </span>
                      </th>
                      <th className="text-primary text-center" width="10%">
                        <span
                          className="text-hover-dark"
                          onClick={(e) => {
                            filtrar("pagamento_dados", "N");
                          }}
                        >
                          Dados OK?{" "}
                          {ordenacao === "pagamento_dados" ? (
                            <i className="fas fa-sort-down text-primary" />
                          ) : (
                            <i className="fas fa-sort text-primary" />
                          )}
                        </span>
                      </th>
                      <th className="text-primary text-center" width="15%">
                        <span
                          className="text-hover-dark"
                          onClick={(e) => {
                            filtrar("pagamento_status", "N");
                          }}
                        >
                          Status Pgto/Status{" "}
                          {ordenacao === "pagamento_status" ? (
                            <i className="fas fa-sort-down text-primary" />
                          ) : (
                            <i className="fas fa-sort text-primary" />
                          )}
                        </span>
                      </th>
                      <th className="text-center">Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    {estabelecimentos.map((item) => {
                      return (
                        <>
                          <tr>
                            <td className="text-center">
                              {item.id_estabelecimento}
                            </td>
                            <td>{item.nome}</td>
                            <td>
                              <ReactTooltip />
                              {item.auto_fechar === "1" && (
                                <>
                                  <span
                                    className="svg-icon svg-icon-danger svg-icon-2x"
                                    data-tip="Apenas fecha sozinho"
                                  >
                                    {/*begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo5\dist/../src/media/svg/icons\Electric\Shutdown.svg*/}
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      xmlnsXlink="http://www.w3.org/1999/xlink"
                                      width="24px"
                                      height="24px"
                                      viewBox="0 0 24 24"
                                      version="1.1"
                                    >
                                      <g
                                        stroke="none"
                                        strokeWidth={1}
                                        fill="none"
                                        fillRule="evenodd"
                                      >
                                        <rect
                                          x={0}
                                          y={0}
                                          width={24}
                                          height={24}
                                        />
                                        <path
                                          d="M7.62302337,5.30262097 C8.08508802,5.000107 8.70490146,5.12944838 9.00741543,5.59151303 C9.3099294,6.05357769 9.18058801,6.67339112 8.71852336,6.97590509 C7.03468892,8.07831239 6,9.95030239 6,12 C6,15.3137085 8.6862915,18 12,18 C15.3137085,18 18,15.3137085 18,12 C18,9.99549229 17.0108275,8.15969002 15.3875704,7.04698597 C14.9320347,6.73472706 14.8158858,6.11230651 15.1281448,5.65677076 C15.4404037,5.20123501 16.0628242,5.08508618 16.51836,5.39734508 C18.6800181,6.87911023 20,9.32886071 20,12 C20,16.418278 16.418278,20 12,20 C7.581722,20 4,16.418278 4,12 C4,9.26852332 5.38056879,6.77075716 7.62302337,5.30262097 Z"
                                          fill="#000000"
                                          fillRule="nonzero"
                                        />
                                        <rect
                                          fill="#000000"
                                          opacity="0.3"
                                          x={11}
                                          y={3}
                                          width={2}
                                          height={10}
                                          rx={1}
                                        />
                                      </g>
                                    </svg>
                                    {/*end::Svg Icon*/}
                                  </span>
                                </>
                              )}
                              <ReactTooltip />
                              {item.auto_abrir === "1" && (
                                <>
                                  <span
                                    className="svg-icon svg-icon-success svg-icon-2x"
                                    data-tip="Apenas abre sozinho"
                                  >
                                    {/*begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo5\dist/../src/media/svg/icons\Electric\Shutdown.svg*/}
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      xmlnsXlink="http://www.w3.org/1999/xlink"
                                      width="24px"
                                      height="24px"
                                      viewBox="0 0 24 24"
                                      version="1.1"
                                    >
                                      <g
                                        stroke="none"
                                        strokeWidth={1}
                                        fill="none"
                                        fillRule="evenodd"
                                      >
                                        <rect
                                          x={0}
                                          y={0}
                                          width={24}
                                          height={24}
                                        />
                                        <path
                                          d="M7.62302337,5.30262097 C8.08508802,5.000107 8.70490146,5.12944838 9.00741543,5.59151303 C9.3099294,6.05357769 9.18058801,6.67339112 8.71852336,6.97590509 C7.03468892,8.07831239 6,9.95030239 6,12 C6,15.3137085 8.6862915,18 12,18 C15.3137085,18 18,15.3137085 18,12 C18,9.99549229 17.0108275,8.15969002 15.3875704,7.04698597 C14.9320347,6.73472706 14.8158858,6.11230651 15.1281448,5.65677076 C15.4404037,5.20123501 16.0628242,5.08508618 16.51836,5.39734508 C18.6800181,6.87911023 20,9.32886071 20,12 C20,16.418278 16.418278,20 12,20 C7.581722,20 4,16.418278 4,12 C4,9.26852332 5.38056879,6.77075716 7.62302337,5.30262097 Z"
                                          fill="#000000"
                                          fillRule="nonzero"
                                        />
                                        <rect
                                          fill="#000000"
                                          opacity="0.3"
                                          x={11}
                                          y={3}
                                          width={2}
                                          height={10}
                                          rx={1}
                                        />
                                      </g>
                                    </svg>
                                    {/*end::Svg Icon*/}
                                  </span>
                                </>
                              )}
                              <ReactTooltip />
                              {item.online_tipo === "L" && (
                                <>
                                  <span
                                    className="svg-icon svg-icon-info svg-icon-2x"
                                    data-tip="Last seen"
                                  >
                                    {/*begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo5\dist/../src/media/svg/icons\Electric\Shutdown.svg*/}
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      xmlnsXlink="http://www.w3.org/1999/xlink"
                                      width="24px"
                                      height="24px"
                                      viewBox="0 0 24 24"
                                      version="1.1"
                                    >
                                      <g
                                        stroke="none"
                                        strokeWidth={1}
                                        fill="none"
                                        fillRule="evenodd"
                                      >
                                        <rect
                                          x={0}
                                          y={0}
                                          width={24}
                                          height={24}
                                        />
                                        <path
                                          d="M12,16.381038 L12,6.47213595 L5.99460483,18.4829263 L12,16.381038 Z M2.88230018,20.2353996 L11.2844582,3.43108351 C11.4820496,3.03590071 11.9625881,2.87572123 12.3577709,3.07331263 C12.5125928,3.15072359 12.6381308,3.27626158 12.7155418,3.43108351 L21.1176998,20.2353996 C21.3152912,20.6305824 21.1551117,21.1111209 20.7599289,21.3087123 C20.5664522,21.4054506 20.3420471,21.4197165 20.1378777,21.3482572 L12,18.5 L3.86212227,21.3482572 C3.44509941,21.4942152 2.98871325,21.2744737 2.84275525,20.8574509 C2.77129597,20.6532815 2.78556182,20.4288764 2.88230018,20.2353996 Z"
                                          fill="#000000"
                                          fill-rule="nonzero"
                                        />
                                        <rect
                                          fill="#000000"
                                          opacity="0.3"
                                          x={11}
                                          y={3}
                                          width={2}
                                          height={10}
                                          rx={1}
                                        />
                                      </g>
                                    </svg>
                                    {/*end::Svg Icon*/}
                                  </span>
                                </>
                              )}
                              <ReactTooltip />
                              {item.soon === "1" && (
                                <>
                                  <span
                                    className="svg-icon svg-icon-warning svg-icon-2x"
                                    data-tip="Em breve"
                                  >
                                    {/*begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo5\dist/../src/media/svg/icons\Electric\Shutdown.svg*/}
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      xmlnsXlink="http://www.w3.org/1999/xlink"
                                      width="24px"
                                      height="24px"
                                      viewBox="0 0 24 24"
                                      version="1.1"
                                    >
                                      <g
                                        stroke="none"
                                        strokeWidth={1}
                                        fill="none"
                                        fillRule="evenodd"
                                      >
                                        <rect
                                          x={0}
                                          y={0}
                                          width={24}
                                          height={24}
                                        />
                                        <path
                                          d="M12.3740377,19.9389434 L18.2226499,11.1660251 C18.4524142,10.8213786 18.3592838,10.3557266 18.0146373,10.1259623 C17.8914367,10.0438285 17.7466809,10 17.5986122,10 L13,10 L13,4.47708173 C13,4.06286817 12.6642136,3.72708173 12.25,3.72708173 C11.9992351,3.72708173 11.7650616,3.85240758 11.6259623,4.06105658 L5.7773501,12.8339749 C5.54758575,13.1786214 5.64071616,13.6442734 5.98536267,13.8740377 C6.10856331,13.9561715 6.25331908,14 6.40138782,14 L11,14 L11,19.5229183 C11,19.9371318 11.3357864,20.2729183 11.75,20.2729183 C12.0007649,20.2729183 12.2349384,20.1475924 12.3740377,19.9389434 Z"
                                          fill="#000000"
                                        />
                                        <rect
                                          fill="#000000"
                                          opacity="0.3"
                                          x={11}
                                          y={3}
                                          width={2}
                                          height={10}
                                          rx={1}
                                        />
                                      </g>
                                    </svg>
                                    {/*end::Svg Icon*/}
                                  </span>
                                </>
                              )}
                              <ReactTooltip />
                              {item.aceita_pgto_online === "1" && (
                                <>
                                  <span
                                    className="svg-icon svg-icon-info svg-icon-2x"
                                    data-tip="Pagamento Online"
                                  >
                                    {/*begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo5\dist/../src/media/svg/icons\Electric\Shutdown.svg*/}
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      xmlnsXlink="http://www.w3.org/1999/xlink"
                                      width="24px"
                                      height="24px"
                                      viewBox="0 0 24 24"
                                      version="1.1"
                                    >
                                      <g
                                        stroke="none"
                                        stroke-width="1"
                                        fill="none"
                                        fill-rule="evenodd"
                                      >
                                        <rect
                                          x="0"
                                          y="0"
                                          width="24"
                                          height="24"
                                        />
                                        <polygon
                                          fill="#9c6060"
                                          opacity="0.3"
                                          points="8 21 16 21 12 16"
                                        />
                                        <path
                                          d="M17.4029496,14.5491021 L15.8599014,15.8215022 C14.9149052,14.675499 13.5137472,14 12,14 C10.4912085,14 9.09418404,14.6710418 8.14910121,15.8106159 L6.60963188,14.533888 C7.93073905,12.9409064 9.88958759,12 12,12 C14.1173586,12 16.0819686,12.9471394 17.4029496,14.5491021 Z M20.4681628,11.9788888 L18.929169,13.2561898 C17.2286725,11.2072964 14.7140097,10 12,10 C9.28974232,10 6.77820732,11.2039334 5.07766256,13.2479685 L3.54017812,11.968851 C5.61676443,9.47281829 8.68922234,8 12,8 C15.3153667,8 18.3916375,9.47692603 20.4681628,11.9788888 Z M23.2904427,9.72048884 L21.678507,10.9044074 C19.4302828,7.84339199 15.8698431,6 12,6 C8.0766912,6 4.47282622,7.89509192 2.2325887,11.0270624 L0.605887503,9.86351476 C3.21772389,6.21202937 7.42430358,4 12,4 C16.5132783,4 20.6693179,6.15175957 23.2904427,9.72048884 Z"
                                          fill="#000000"
                                          fill-rule="nonzero"
                                        />
                                      </g>
                                    </svg>
                                    {/*end::Svg Icon*/}
                                  </span>
                                </>
                              )}
                              <ReactTooltip />
                              {item.exclusivo_aiboo === "1" && (
                                <>
                                  <img
                                    alt="logo"
                                    src="assets/imgs/logo-b.png"
                                    style={{ height: "24px" }}
                                  />
                                </>
                              )}
                            </td>
                            <td>
                              {item.nome_cidade} - {item.estado}
                            </td>
                            <td className="text-center">
                              <span className="switch switch-danger switch-md switch-icon">
                                <label>
                                  <input
                                    checked={item.fechado === "0"}
                                    type="checkbox"
                                    onClick={(e) =>
                                      updateStoreStatus(
                                        item.id_estabelecimento,
                                        e.target.checked
                                      )
                                    }
                                    name="select"
                                  />
                                  <span></span>
                                </label>
                              </span>
                            </td>
                            <td className="text-center">{item.nota}</td>
                            <td className="text-center">
                              {item.pagamento_dados == "S" ? (
                                <div className="text-success">
                                  <b>SIM</b>
                                </div>
                              ) : (
                                <div className="text-danger">
                                  <b>NÃO</b>
                                  <button
                                    className="btn btn-info btn-sm ml-3"
                                    onClick={(e) => {
                                      validarRE(item.id_estabelecimento);
                                    }}
                                  >
                                    RE
                                  </button>
                                </div>
                              )}
                            </td>
                            <td className="text-center">
                              {item.pagamento_status == "P" && (
                                <>
                                  <span class="label label-warning label-inline mr-2">
                                    Em Aberto
                                  </span>
                                </>
                              )}
                              {item.pagamento_status == "V" && (
                                <>
                                  <span class="label label-danger label-inline mr-2">
                                    Vencido
                                  </span>
                                </>
                              )}
                              {item.pagamento_status == "O" && (
                                <>
                                  <span class="label label-success label-inline mr-2">
                                    Pago
                                  </span>
                                </>
                              )}
                              {item.pagamento_status == "B" && (
                                <>
                                  <span class="label label-dark label-inline mr-2">
                                    Suspenso
                                  </span>
                                </>
                              )}

                              {item.status == "S" && (
                                <>
                                  <span class="label label-success label-inline mr-2">
                                    Ativo
                                  </span>
                                </>
                              )}
                              {item.status == "N" && (
                                <>
                                  <span class="label label-danger label-inline mr-2">
                                    Inativo
                                  </span>
                                </>
                              )}
                            </td>
                            <td className="text-center">
                              <div class="dropdown dropdown-inline">
                                <button
                                  type="button"
                                  class="btn btn-light-primary btn-icon btn-sm"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  <i class="ki ki-bold-more-ver"></i>
                                </button>
                                <div class="dropdown-menu">
                                  <Link
                                    to={`estabelecimentos/estabelecimento/${item.id_estabelecimento}`}
                                  >
                                    <span class="dropdown-item">Editar</span>
                                  </Link>
                                  {acesso.est_ger_est_ei === "1" && (
                                    <>
                                      <div class="dropdown-divider"></div>
                                      <span
                                        class="dropdown-item"
                                        onClick={(e) => {
                                          disablePayments(item);
                                        }}
                                      >
                                        Desativar Payments
                                      </span>

                                      <span
                                        class="dropdown-item"
                                        onClick={(e) => {
                                          excluirSessao(item);
                                        }}
                                      >
                                        Excluir sessões
                                      </span>
                                    </>
                                  )}
                                  <div class="dropdown-divider"></div>
                                  <span
                                    class="dropdown-item"
                                    onClick={(e) => {
                                      painelNovoNovo(item.id_estabelecimento);
                                    }}
                                  >
                                    Painel Parceiro
                                  </span>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </>
                      );
                    })}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colspan="9" className="text-right">
                        <Paginacao
                          currentPage={currentPage}
                          pageCount={pageCount}
                          handlePageClick={handlePageClick}
                        />
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </LoadingOverlay>
            </div>
          </div>
        </div>
      </div>

      <Modal
        size={"lg"}
        centered
        show={modalValidaRE}
        onHide={() => fecharModalValidaRE()}
      >
        <Modal.Header>
          <Modal.Title>Validar Financeiro</Modal.Title>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => {
              fecharModalValidaRE();
            }}
          >
            <i aria-hidden="true" className="ki ki-close" />
          </button>
        </Modal.Header>

        <div className="row row ml-3 mr-3">
          <div className="col-md-12">
            <label dangerouslySetInnerHTML={{ __html: exibeValidacao }} />
          </div>
        </div>

        <Modal.Footer>
          <button
            type="button"
            onClick={() => {
              fecharModalValidaRE();
            }}
            className="btn btn-light-primary font-weight-bold"
            data-dismiss="modal"
          >
            <i className="fas fa-door-open" />
            Fechar
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default withRouter(Estabelecimentos);

import React, { useState, useEffect } from 'react';
import Api from '../../../services/api';
import { withRouter } from "react-router";

import Swal from "sweetalert2";

import Select from 'react-select';
import LoadingOverlay from 'react-loading-overlay';
import ReactTooltip from 'react-tooltip';

import "react-datepicker/dist/react-datepicker.css";
import DatePicker, { registerLocale } from "react-datepicker";
import InputMask from 'react-input-mask';
import pt from "date-fns/locale/pt-BR"; 
registerLocale("pt-BR", pt);

const RelVendaPeriodo = () => {
  var d = new Date();
  d.setDate(d.getDate() - 30);
  

  const [ pedidos, setPedidos ] = useState([]);
  const [ resumo, setResumo ] = useState();
  const [ sumario, setSumario ] = useState();
  const [ cidadeSelect, setCidadeSelect ] = useState([]);
  const [ cidade, setCidade ] = useState([]);
  const [ estabelecimentoSelect, setEstabelecimentoSelect ] = useState([]);
  const [ estabelecimento, setEstabelecimento ] = useState([]);
  const [ dataInicial, setDataInicial ] = useState(d);
  const [ dataFinal, setDataFinal ] = useState(new Date());
  const [ mostrar, setMostrar ] = useState();
  const [ caProcessa, setCaProcessa ] = useState(false);
  const [ caProcessaEstabelecimento, setCaProcessaEstabelecimento ] = useState(false);
  
  const [ ordenacao ] = useState('id_estabelecimento');
  const [ ordenacaoSet ] = useState();
  
  // const PER_PAGE = 10;
 
  useEffect(() => {
    getCidadeSelect();
  }, []);

  useEffect(() => {
    if (cidade)
      getEstabelecimentoSelect(cidade);
  }, [cidade]);

  function getCidadeSelect() {
    var data = {
      representante: localStorage.getItem('@ADM-Aiboo/nivel') == 'R' ? localStorage.getItem('@ADM-Aiboo/id_representante') : ''
    }

    Api.post("cidades/listarcidaderepresentante", data).then(rps => {
      var cid = rps.data.cidades;
      var nCid = [];
      nCid[nCid.length] = { value: '', label: 'Selecione...' };
      cid.forEach(e => {
        nCid[nCid.length] = { value: e.id_cidade, label: e.nome_cidade + ' - ' + e.estado };
      })
      setCidadeSelect(nCid);
    })
  }

  function getEstabelecimentoSelect(cidade) {
    setCaProcessaEstabelecimento(true);
    var data = {
      representante: localStorage.getItem('@ADM-Aiboo/nivel') == 'R' ? localStorage.getItem('@ADM-Aiboo/id_representante') : '',
      'cidade': cidade
    }

    Api.post("estabelecimentos/listaNomeEstabelecimento", data).then(rps => {
      var rep = rps.data.obj;
      var nRep = [];
      nRep[nRep.length] = {value: '', label: 'Selecione...'};
      rep.forEach(e => {
        nRep[nRep.length] = {value: e.id_estabelecimento, label: e.nome};
      })
      setEstabelecimentoSelect(nRep);
      setCaProcessaEstabelecimento(false); 
    })
  }

  function filtrar() {
    setCaProcessa(true); 
      var data = {
        cidade: cidade,
        data_inicio: dataInicial,
        data_final: dataFinal,
        estabelecimento: estabelecimento,
        mostrar: mostrar,
        representante: localStorage.getItem('@ADM-Aiboo/nivel') == 'R' ? localStorage.getItem('@ADM-Aiboo/id_representante') : ''
      };
   
      Api.post('relatorios/geraRelatorioPeriodo', data).then((rps) => {

        if(rps.data.status === false){
          Swal.fire({
            title: "Atenção!",
            icon: "info",
            html: rps.data.erro,
            showCloseButton: true,
            showCancelButton: false,
          });
        } else {
          setPedidos(rps.data.obj);
          setResumo(rps.data.resumo);
          setSumario(rps.data.sumario);
        }
      setCaProcessa(false); 
    });
  }

  // function handlePageClick(pagina) {
  //   setCaProcessa(true);
  //   setCurrentPage(pagina);
  //   var data = {
  //     limite: PER_PAGE, 
  //     start: (pagina-1) * PER_PAGE,
  //     //estabelecimento: buscaEstabelecimento,
  //     //cidade: buscaCidade,
  //     //representante: buscaRepresentante,
  //     //status: buscaStatus,
  //     ordenacao: ordenacao,
  //     sentido: ordenacaoSet,
  //   }
    
  //   Api.post("estabelecimentos/filtrar", data).then(rps => { 
  //     //setEstabelecimentos(rps.data.obj);
  //     setCaProcessa(false);
  //   })
  // }
    
    return (
        <>
<div>

<div className="subheader espaco-header-pedidos subheader-transparent" id="kt_subheader">
          <div className="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
            {/*begin::Details*/}
            <div className="d-flex align-items-center flex-wrap mr-2">
              {/*begin::Title*/}
              <h5 className="text-dark font-weight-bold mt-2 mb-2 mr-5">Relatório</h5>
              {/*end::Title*/}
              {/*begin::Separator*/}
              <div className="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200" />
              {/*end::Separator*/}
              {/*begin::Title*/}
              <h5 className="text-dark font-weight-bold mt-2 mb-2 mr-5">Vendas por período</h5>
              {/*end::Title*/}
              {/*begin::Separator*/}
              <div className="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200" />
              {/*end::Separator*/}
            </div>
            {/*end::Details*/}
            {/*begin::Toolbar*/}
            <div className="d-flex align-items-center">

            </div>
            {/*end::Toolbar*/}
          </div>
        </div>
        {/*begin::Entry*/}
        <div className="d-flex flex-column-fluid">
          {/*begin::Container*/}
          <div className="container">
            <div className="card card-custom">
              <div className="ml-10 mr-10 mt-10">    
                <div className="row">
                <div className="col form-group">
                    <label>Data de</label>
                      <div className="input-group">
                      <DatePicker 
                        name="dt_inicio"
                        onChange={setDataInicial}
                        isClearable
                        selectsStart
                        locale="pt-BR"
                        className="form-control"
                        placeholderText="dd/mm/aaaa" 
                        selected ={dataInicial}
                        dateFormat="dd/MM/y"
                        startDate={dataInicial}
                        endDate={dataFinal}
                        customInput={
                          <InputMask mask="99/99/9999" />}/>
                      </div>
                  </div>

                  <div className="col form-group">
                    <label>Data até</label>
                      <div className="input-group">
                      <DatePicker 
                        name="dt_final"
                        onChange={setDataFinal}
                        isClearable
                        locale="pt-BR"
                        className="form-control"
                        placeholderText="dd/mm/aaaa" 
                        selected ={dataFinal}
                        dateFormat="dd/MM/y"
                        selectsEnd
                        startDate={dataInicial}
                        endDate={dataFinal}
                        minDate={dataInicial} 
                        customInput={
                          <InputMask mask="99/99/9999" />}/>
                      </div>
                    </div>

                  <div className="col-md-2 form-group">
                    <label>Cidade</label>
                    <Select
                      options={cidadeSelect}
                      placeholder="Selecione..." 
                      defaultValue={cidadeSelect.find(
                        x => x.value === cidade
                      )}
                      onChange={(e) => { setCidade(e.value) }} 
                
                    /> 
                  </div> 

                  <div className="col-md-3 form-group">
                    <LoadingOverlay
                      active={caProcessaEstabelecimento}
                      spinner
                      text='Carregando...'
                    >
                    <label>Estabelecimento</label>
                    <Select
                      options={estabelecimentoSelect}
                      placeholder="Selecione..." 
                      defaultValue={estabelecimentoSelect.find(
                        x => x.value === estabelecimento
                      )}
                      onChange={(e) => { setEstabelecimento(e.value) }} 
                    />
                    </LoadingOverlay>
                  </div>

                  <div className="form-group col-md-2">
                  <ReactTooltip />
                  <label>Mostrar Vendas?</label><i className="fas fa-fw fa-exclamation-triangle ml-2" data-tip="Nota: caso selecione SIM aguarde o navegador destravar!!"/>
                  <select className="form-control" 
                        value={mostrar} 
                        onChange={(e) => { setMostrar(e.target.value) }}>
                      <option value="">Selecione...</option>
                      <option value="S">Sim</option>
                      <option value="N">Não</option>
                    </select>
                </div>

                  <div className="col form-group">
                  <label>&nbsp;</label><br />
                    <button className="btn btn-block btn-primary" onClick={e=>{filtrar()}}><i className="fas fa-filter" />Filtrar</button>
                  </div> 
                </div>     
              </div>

              <LoadingOverlay
                            active={caProcessa}
                            spinner
                            text='Carregando...'
                          >                     

              { mostrar === 'S' && 
              <table className="table table-striped">
                <thead className="bg-light-primary">
                  <tr>
                    <th className="text-primary text-center">
                      <span className="text-hover-dark" onClick={e => { filtrar('hash_pedido'); }}>
                        Data {ordenacao === 'hash_pedido' ? <i className="fas fa-sort-down text-primary" /> : <i className="fas fa-sort text-primary" />}
                      </span>
                    </th>
                    <th className="text-primary text-center">
                      <span className="text-hover-dark" onClick={e => { filtrar('hash_pedido'); }}>
                        Hash {ordenacao === 'hash_pedido' ? <i className="fas fa-sort-down text-primary" /> : <i className="fas fa-sort text-primary" />}
                      </span>
                    </th>  
                    <th className="text-primary">
                      <span className="text-hover-dark" onClick={e => { filtrar('nome_cidade'); }}>
                        Estabelecimento {ordenacao === 'nome_cidade' && ordenacaoSet === 'ASC' ? <i className="fas fa-sort-down text-primary" /> : <i className="fas fa-sort text-primary" />}
                      </span>
                    </th>  
                    <th className="text-primary">
                      <span className="text-hover-dark" onClick={e => { filtrar('fechado'); }}>
                        Nome usuário e telefone {ordenacao === 'fechado' ? <i className="fas fa-sort-down text-primary" /> : <i className="fas fa-sort text-primary" />}
                      </span>
                    </th>  
                    <th className="text-primary text-center">
                      <span className="text-hover-dark" onClick={e => { filtrar('nota'); }}>
                        Comissão {ordenacao === 'nota' ? <i className="fas fa-sort-down text-primary" /> : <i className="fas fa-sort text-primary" />}
                      </span>
                    </th><th className="text-primary text-center">
                      <span className="text-hover-dark" onClick={e => { filtrar('pagamento_dados'); }}>
                        Desconto Aiboo {ordenacao === 'pagamento_dados' ? <i className="fas fa-sort-down text-primary" /> : <i className="fas fa-sort text-primary" />}
                      </span>
                    </th>  
                    <th className="text-primary text-center">
                      <span className="text-hover-dark" onClick={e => { filtrar('pagamento_status'); }}>
                        Status {ordenacao === 'pagamento_status' ? <i className="fas fa-sort-down text-primary" /> : <i className="fas fa-sort text-primary" />}
                      </span>
                    </th>  
                    
                  </tr>
                </thead>
                <tbody>  
                  { pedidos !== null &&
                    pedidos.map( (item) => { 
                      return (
                      <> 
                        <tr>
                          <td className="text-center">{item.data_criacao}</td>
                          <td>{item.hash_pedido}</td>
                          <td>{item.estabelecimento_nome}</td>
                          <td>{item.usuario_nome} / {item.usuario_telefone}</td>
                          <td className="text-center">{item.comissao}</td>
                          <td className="text-center">{item.valor_desconto_aiboo}</td>
                          <td className="text-center">
                            {item.status == "PD" && <>          
                              <span class="label label-success label-inline mr-2">PD</span>
                            </>}
                            {item.status == "PC" && <>          
                              <span class="label label-danger label-inline mr-2">PC</span>
                            </>}
                          </td>
                        </tr> 
                      </>
                      )
                    })
                    }
                </tbody>
                <tfoot>
                  <tr>
                    <td colspan="9" className="text-right">
                       {/*<Paginacao />*/}
                    </td>
                  </tr>
                </tfoot>
              </table>
              }

              <h3 className='ml-5'><b>Dados por Cidade:</b></h3>
              <table className="table table-striped">
                <thead className="bg-light-primary">
                  <tr>
                    <td width="20%">Cidade</td>
                    <td className="text-center">Vendas</td>
                    <td className="text-right">Total vendas</td>
                    <td className="text-right">Descontos</td>
                    <td className="text-right">Comissão</td>
                    <td className="text-center">Q. Online</td>
                    <td className="text-right">V. Online</td>
                    <td className="text-center">Qtde. Can.</td>
                    <td className="text-right">Valor Can.</td>
                    <td className="text-center">Novos usuários</td>
                  </tr>
                </thead>
                  <tbody className="content" dangerouslySetInnerHTML={{__html: resumo}}></tbody>
              </table>  

              <div className="sumario ml-5 mb-10" dangerouslySetInnerHTML={{__html: sumario}}></div>

              </LoadingOverlay> 

                    
      
                  </div>
                  </div>
                  </div>
                  </div>
                  
             
        </>
    );

}



export default withRouter(RelVendaPeriodo);                  
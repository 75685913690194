import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  padding: 15px;

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const InformationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const PhotoContainer = styled.div`
  img {
    width: 70px;
    height: 70px;

    border-radius: 8px;
    margin-right: 15px;
  }
`;

export const ImagePlaceholder = styled.div`
  width: 70px;
  height: 70px;

  border-radius: 8px;
  margin-right: 15px;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: #dcdcdc;
`;

export const CardTitle = styled.h3`
  max-width: 280px;
  font-size: 16px;
  font-weight: 600;
  color: #333;
`;

export const CardLabel = styled.span`
  display: block;
  font-weight: 500;
  font-size: 12px;

  color: #333333;
`;

export const ActionButton = styled.button`
  background: none;
  border: none;

  display: flex;
  align-items: center;
  justify-content: center;

  gap: 9px;

  i {
    font-size: 20px;
    color: #ff3333;
  }
`;

export const ActionButtonLabel = styled.span`
  font-size: 14px;
  font-weight: 500;
  color: #ff3333;
`;

export const AddedEntityLabelContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;

  i {
    font-size: 20px;
    color: #00b333;
  }
`;

export const AddedEntityLabel = styled.span`
  color: #00b333;
`;

import React from 'react';
import { ClipLoader } from "react-spinners";

import { Container } from './styles';

function Loading() {
  return (
        <Container>
            <ClipLoader color="#F64E60" />
        </Container>
    );
}

export default Loading;
import styled from "styled-components";

const headerButtonColorsMap = {
  generate: "#00b333",
  show: "#5691EA",
  delete: "#FF3333",
};

export const HeaderButtonsWrapper = styled.div`
  display: flex;
  gap: 16px;
`;

export const HeaderButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  width: 180px;
  height: 40px;
  border-radius: 8px;
  border: 1px solid ${({ buttonAction }) => headerButtonColorsMap[buttonAction]};
  background: none;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};

  i {
    color: ${({ buttonAction }) => headerButtonColorsMap[buttonAction]};
    font-size: 20px;
    cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  }

  label {
    margin: 0;
    color: ${({ buttonAction }) => headerButtonColorsMap[buttonAction]};
    font-size: 16px;
    font-weight: 600;
    cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  }
`;

import styled from 'styled-components';

export const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const Title = styled.div`
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 33px;

    display: flex;
    align-items: center;

    color: #333333;
`;

export const SubTitle = styled.div`
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;

    display: flex;
    align-items: center;

    color: #333333;
`;

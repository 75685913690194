import React, { forwardRef } from "react";

import * as S from "./styles";

export const Input = forwardRef(
  (
    {
      label = "",
      labelSize = 16,
      maxWidth,
      isMandatory = false,
      error,
      rightSideElement = null,
      ...rest
    },
    ref
  ) => {
    return (
      <S.Container>
        <S.Label labelSize={labelSize}>
          {label}
          {isMandatory && <span>*</span>}
        </S.Label>

        <S.InputContainer error={error} rightSideElement={rightSideElement}>
          <S.Input ref={ref} maxWidth={maxWidth} {...rest} />
          {rightSideElement}
        </S.InputContainer>

        {error && <strong>{error.message}</strong>}
      </S.Container>
    );
  }
);

import React, { useState } from "react";
import apiLaravelv2 from "../../../../services/api-laravelv2";

import { useToasts } from "react-toast-notifications";

import { Modal } from "../../../../components";
import * as S from "./styles";

export const AddCategoryModal = ({
  handleToggleCreateProductCategoryModal,
  modalVisibility,
  pageStatus,
  setPageStatus,
  fetchCategories,
  setSelectedCategory,
}) => {
  const [categoryName, setCategoryName] = useState("");
  const { addToast } = useToasts();

  const handleCreateCategory = async () => {
    try {
      setPageStatus("loading");
      const newCategoryData = new FormData();
      newCategoryData.append("nome", categoryName);
      const response = await apiLaravelv2.post("products/categories", newCategoryData);
      setPageStatus("success");
      addToast("Categoria cadastrada", {
        appearance: "success",
        autoDismiss: true,
        autoDismissTimeout: 5000,
      });
      fetchCategories();

      setSelectedCategory({
        value: response.data.category.id_produto_categoria,
        label: response.data.category.nome,
      });
      handleToggleCreateProductCategoryModal();
    } catch {
      setPageStatus("error");
      addToast(
        "Ops! Ocorreu um erro inesperado ao cadastrar esta categoria, tente novamente mais tarde ",
        {
          appearance: "error",
          autoDismiss: true,
          autoDismissTimeout: 5000,
        }
      );
    }
  };
  return (
    <Modal
      toggleModalVisibility={handleToggleCreateProductCategoryModal}
      visible={modalVisibility}
      showCloseButton={true}
      insideModal
    >
      <S.AddCategoryModalContent>
        <h2>Adicionar categoria</h2>
        <S.CategoryInputContainer>
          <label>
            Nome da categoria <span>*</span>
          </label>

          <input
            type="text"
            maxLength={42}
            value={categoryName}
            onChange={(event) => setCategoryName(event.target.value)}
          />
        </S.CategoryInputContainer>
        <S.SubmitButtonContainer>
          <button
            onClick={handleCreateCategory}
            disabled={pageStatus === "loading" || categoryName.length === 0}
          >
            {pageStatus === "loading" ? (
              <span className="spinner" />
            ) : (
              <>
                <label>Salvar</label>
              </>
            )}
          </button>
        </S.SubmitButtonContainer>
      </S.AddCategoryModalContent>
    </Modal>
  );
};

import api from "../api";

const getAddressByIBGECode = async (IBGECode) => {
  const response = await api.post("cidades/pesquisaIBGE", { IBGE: IBGECode });

  return response.data;
};

const getCitiesByAgentId = async () => {
  const agent =
    localStorage.getItem("@ADM-Aiboo/nivel") == "R"
      ? localStorage.getItem("@ADM-Aiboo/id_representante")
      : "";

  const response = await api.post("cidades/listarcidaderepresentante", {
    representante: agent,
  });

  return response.data;
};

const verifyCoordinateByAddressLabel = async (addressLabel) => {
  const response = await api.post("enderecos/checkHasLatLng", {
    address: addressLabel,
  });

  return response.data;
};

export const addressService = {
  getAddressByIBGECode,
  getCitiesByAgentId,
  verifyCoordinateByAddressLabel,
};

import React from 'react';
import { Link } from 'react-router-dom'

export function SubMenu({ permission, onClick, text, to }) {
    if (!permission) {
        return null;
    }

    return (
        <li className="menu-item">
            <Link to={to} className="menu-link" onClick={onClick}>
                <i className="menu-bullet menu-bullet-dot">
                    <span />
                </i>
                <span className="menu-text">{text}</span>
            </Link>
        </li>
    );
}
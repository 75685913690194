import React, { useState, useEffect, useRef } from 'react';
import Api from '../../../services/api';
import { withRouter } from "react-router";

import "./index.scss";

import Swal from "sweetalert2";
import LoadingOverlay from 'react-loading-overlay';
import { TagsInput } from "react-tag-input-component";
import ReactToPrint from "react-to-print";

import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import pt from "date-fns/locale/pt-BR"; 
registerLocale("pt-BR", pt);

const RepExtrato = () => {
  const [ termos, setTermos ] = useState(['whats','zap','ifood','goormer','menudino','aiqfome','site','pix','picpay','pic pay']);
  const [ extrato, setExtrato ] = useState([]);
  const [ caProcessa, setCaProcessa ] = useState(false);
  const printRef = useRef();
 
  useEffect(() => {
   
  }, []);
  
  function filtrar() {
    setCaProcessa(true); 
      var data = {
        termos: JSON.stringify(termos),
      };
    
      Api.post('fraudes/pesquisar', data).then((rps) => {

        if(rps.data.status==false){
          Swal.fire({
            title: "Atenção!",
            icon: "error",
            html: rps.data.erro,
            showCloseButton: true,
            showCancelButton: false,
          });
          setExtrato([]);
        } else {
          setExtrato(rps.data.obj);
        }
      setCaProcessa(false); 
    });
  }
    
    return (
        <>
<div>

<div className="subheader espaco-header-pedidos subheader-transparent" id="kt_subheader">
          <div className="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
            {/*begin::Details*/}
            <div className="d-flex align-items-center flex-wrap mr-2">
              {/*begin::Title*/}
              <h5 className="text-dark font-weight-bold mt-2 mb-2 mr-5">Procurar Fraudes</h5>
              {/*end::Title*/}
              {/*begin::Separator*/}
              <div className="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200" />
              {/*end::Separator*/}
            </div>
            {/*end::Details*/}
            {/*begin::Toolbar*/}
            <div className="d-flex align-items-center">
              <ReactToPrint
                trigger={() => <button className="btn btn-lg btn-secondary mt-3 mb-3"><i className="fas fa-print" />Imprimir</button>}
                content={() => printRef.current}
              />
            </div>
            {/*end::Toolbar*/}
          </div>
        </div>
        {/*begin::Entry*/}
        <div className="d-flex flex-column-fluid">
          {/*begin::Container*/}
          <div className="container">
            <div className="card card-custom">
              <div className="ml-10 mr-10 mt-10">    
                <div className="row">
                  <div className="form-group col-md-10">
                    <label>Digite os termos da pesquisa.</label>
                    <TagsInput
                      value={termos}
                      onChange={setTermos}
                      name="palavra"
                      placeHolder="Digite o(s) termo(s) e pressione enter."
                    />
                  </div>
                
                  <div className="col-md-2">
                    <label>&nbsp;</label>
                    <button className="btn btn-primary btn-block" onClick={e=>{filtrar()}}><i className="fas fa-filter" />Filtrar</button>
                  </div> 
                </div> 
              </div>

              <br />
              <br />
              <br />

              <LoadingOverlay
                active={caProcessa}
                spinner
                text='Carregando...'
              > 
              
              <div className="ml-5 mr-5 mt-10" ref={printRef}>
                <div className="cabecalho">
                  <table>
                    <tr>
                        <td width="40%">
                        <img alt="Logo" src="assets/media/logos/aiboo.png" className="logo-sticky max-h-45px" />
                        </td>
                        <td width="60%">
                        <h5 className="text-left"><b>Aiboo Delivery Serviços de Venda LTDA</b><br />
                                Rua Hans L. Kraus, 31 - Cohab - João Neiva/ES<br />
                                atendimento@aiboo.com.br
                            </h5>
                        </td>
                    </tr>
                  </table>
                  <br />
                  <h3 className="text-center">Extrato de comissão</h3>
                  <br />
                </div>

                <table className="table table-striped table-bordered">
                  <thead>
                      <tr>
                          <th className="text-center h4">Estabelecimento</th>
                          <th className="text-center h4">Texto App</th>
                          <th className="text-center h4">Frase Promocional</th>
                          <th className="text-center h4">Categoria</th>
                          <th className="text-center h4">Nome Produto</th>
                          <th className="text-center h4">Descricao</th>
                      </tr>
                  </thead>
                  <tbody>
                    { extrato.map( (item) => { 
                      return (
                      <> 
                        <tr>
                          <td>{item.id_estabelecimento}-{item.nome}</td>
                          <td><span className="content" dangerouslySetInnerHTML={{__html: item.texto_app}}></span></td>
                          <td><span className="content" dangerouslySetInnerHTML={{__html: item.frase_promocional}}></span></td>
                          <td><span className="content" dangerouslySetInnerHTML={{__html: item.nome_categoria}}></span></td>
                          <td><span className="content" dangerouslySetInnerHTML={{__html: item.nome_prod}}></span></td>
                          <td><span className="content" dangerouslySetInnerHTML={{__html: item.descricao}}></span></td>
                        </tr> 
                      </>
                      )
                    })
                    }                                                                                          
                  </tbody>
                </table>
              </div>
              
              </LoadingOverlay>                    
      
                  </div>
                  </div>
                  </div>
                  </div>  
               
        </>
    );
}

export default withRouter(RepExtrato);                  
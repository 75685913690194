import { merchantService } from "services";

export const useFetchAgents = () => {
  const formatAgent = (agent) => ({
    label: agent.nome,
    value: agent.id_representante,
  });

  const fetchAgents = async () => {
    const response = await merchantService.listAgents();

    return response.obj?.map(formatAgent);
  };

  return { fetchAgents };
};

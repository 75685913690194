import styled from "styled-components";

export const Container = styled.label`
  position: relative;
  display: inline-block;
  cursor: pointer;
  height: 100%;
  width: 100%;

  span {
    text-align: center;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;

    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;

    background-color: #f1f3f4;
    color: #ff3333;

    border-radius: 8px;
    user-select: none;

    &::after {
      content: "Selecionar imagem";
    }
  }
`;

export const Input = styled.input`
  margin: 0;
  filter: alpha(opacity=0);
  opacity: 0;
`;

export const ImagePreview = styled.img`
  height: 100%;
  width: 100%;

  border-radius: 8px;
`;

export const SwitchImageTag = styled.div`
  background-color: #616161;
  width: 100%;
  position: absolute;
  bottom: 0;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  font-size: 12px;
  color: #fff;
  text-transform: uppercase;
  height: 24px !important;
  transition: height 0.2s linear;
  overflow: hidden;
  opacity: 0.9;

  &:hover {
    transition: height 0.2s linear;
    height: 36px !important;
    padding: 3px;
  }
`;

const getErrorMessage = (error) => {
  try {
    const errors = Object.values(error?.response?.data?.errors);

    const [message] = errors;

    return message;
  } catch (error) {
    return "";
  }
};

export const helpers = { getErrorMessage };

import React, { useState, useEffect } from 'react';
import Api from '../../../services/api';
import { withRouter } from "react-router";

import Swal from "sweetalert2";
import { useToasts } from 'react-toast-notifications';
import { Modal } from "react-bootstrap";
import LoadingOverlay from 'react-loading-overlay';

import Select from 'react-select';

import InputMask from 'react-input-mask';
import "react-datepicker/dist/react-datepicker.css";
import DatePicker, { registerLocale } from "react-datepicker";
import pt from "date-fns/locale/pt-BR";
registerLocale("pt-BR", pt);

const AtComissao = () => {
  
  const { addToast } = useToasts();
  const [ formAtualizar, setFormAtualizar ] = useState([]);
  const [ estabelecimentosSelect, setEstabelecimentoSelect ] = useState([]);
  const [ caProcessa, setCaProcessa ] = useState(false);
  const [ modal, setModal ] = useState(false);
  const [ retorno, setRetorno ] = useState([]);

  useEffect(() => {
    getEstabelecimentoSelect();
  }, []);

  function fecharModal(){
    setModal(false);
  }

  function getEstabelecimentoSelect() {
    Api.get("estabelecimentos/listaNomeEstabelecimento").then(rps => {
      var rep = rps.data.obj;
      var nRep = [];
      nRep[nRep.length] = {value: 'T', label: 'Todos...'};
      rep.forEach(e => {
        nRep[nRep.length] = {value: e.id_estabelecimento, label: e.id_estabelecimento + ' - ' + e.nome};
      })
      setEstabelecimentoSelect(nRep);
    })
  }

  function alterar() {
    setCaProcessa(true);
    Api.post('ferramentas/comissao', formAtualizar).then(rps => {
      if (rps.data.status === true) {
        addToast(rps.data.mensagem, {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 2000
        });
        setRetorno(rps.data.obj);
        setModal(true);
      } else {
        Swal.fire({
          title: "Erro!",
          icon: "error",
          html: rps.data.erros,
          showCloseButton: true,
          showCancelButton: false,
        });
      }
      setCaProcessa(false);
    });
  }
    
    return (
        <> 
<div>

<div className="subheader espaco-header-pedidos subheader-transparent" id="kt_subheader">
          <div className="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
            {/*begin::Details*/}
            <div className="d-flex align-items-center flex-wrap mr-2">
              {/*begin::Title*/}
              <h5 className="text-dark font-weight-bold mt-2 mb-2 mr-5">Ferramentas</h5>
              {/*end::Title*/}
              {/*begin::Separator*/}
              <div className="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200" />
              {/*end::Separator*/}
              {/*begin::Title*/}
              <h5 className="text-dark font-weight-bold mt-2 mb-2 mr-5">Atualiza Comissão</h5>
              {/*end::Title*/}
            </div>
            {/*end::Details*/}
            {/*begin::Toolbar*/}
            <div className="d-flex align-items-center">

            </div>
            {/*end::Toolbar*/}
          </div>
        </div>
        {/*begin::Entry*/}
        <div className="d-flex flex-column-fluid">
          {/*begin::Container*/}
          <div className="container">
            <LoadingOverlay
              active={caProcessa}
              spinner
              text='Carregando...'
            > 
            <div className="card card-custom">
              <div className="row ml-5 mr-5 mt-5">
                <div className="form-group col-md-12">
                  <label>Estabelecimento <b className="text-danger">*</b></label>
                  <Select 
                    options={estabelecimentosSelect}
                    placeholder="Selecione..." 
                    defaultValue={estabelecimentosSelect.find(
                      x => x.value === formAtualizar?.id_estabelecimento
                    )}
                    onChange={(e) => { setFormAtualizar({...formAtualizar, id_estabelecimento: e.value}) }} 
                  />
                </div>
              </div>

              <div className="row ml-5 mr-5">
                <div className="form-group col-md-3">
                  <label>Data Início</label>
                  <div>
                    <DatePicker
                      name="dt_inicio"
                      onChange={(e) => { setFormAtualizar({...formAtualizar, data_de: e}) }}
                      isClearable
                      showTimeSelect
                      selectsStart
                      locale="pt-BR"
                      className="form-control"
                      placeholderText="dd/mm/aaaa hh:mm:ss"
                      selected={formAtualizar.data_de ? formAtualizar.data_de : ''}
                      dateFormat="dd/MM/y HH:mm:ss"
                      startDate={formAtualizar.data_de ? formAtualizar.data_de : ''}
                      endDate={formAtualizar.data_ate ? formAtualizar.data_ate : ''}
                      customInput={
                        <InputMask mask="99/99/9999 99:99:99" />} />
                  </div>
                  </div>
                  <div className="form-group col-md-3">
                    <label>Data Final</label>
                    <div>
                      <DatePicker
                        name="dt_final"
                        onChange={(e) => { setFormAtualizar({...formAtualizar, data_ate: e}) }}
                        isClearable
                        showTimeSelect
                        locale="pt-BR"
                        className="form-control"
                        placeholderText="dd/mm/aaaa hh:mm:ss"
                        selected={formAtualizar.data_ate ? formAtualizar.data_ate : ''}
                        dateFormat="dd/MM/y HH:mm:ss"
                        selectsEnd
                        startDate={formAtualizar.data_de ? formAtualizar.data_de : ''}
                        endDate={formAtualizar.data_ate ? formAtualizar.data_ate : ''}
                        minDate={formAtualizar.data_de ? formAtualizar.data_de : ''}
                        customInput={
                          <InputMask mask="99/99/9999 99:99:99" />} />
                    </div>
                  </div>
              
                <div className="form-group col-md-3">
                  <label>Pedido de:</label>
                  <input type="text" className="form-control" 
                    value={formAtualizar?.pedido_de} 
                    onChange={e => {setFormAtualizar({...formAtualizar, pedido_de: e.target.value})}}
                  />
                </div>

                <div className="form-group col-md-3">
                  <label>Pedido até:</label>
                  <input type="text" className="form-control" 
                    value={formAtualizar?.pedido_ate} 
                    onChange={e => {setFormAtualizar({...formAtualizar, pedido_ate: e.target.value})}}
                  />
                </div>
              </div> 

              <div className="row ml-5 mr-5">
                <div className="form-group col-md-3">
                  <h4>Comissão <b className="text-danger">*</b></h4>
                  <input type="text" className="form-control" 
                    value={formAtualizar?.comissao} 
                    onChange={e => {setFormAtualizar({...formAtualizar, comissao: e.target.value})}}
                  />
                </div>
              </div>

              <div className="row ml-5 mr-5">
                  <div className='col-md-12 mb-10 text-right'>
                    <button className="btn btn-primary font-weight-bold" onClick={e=> { alterar() }}><i className="fas fa-pencil-alt" />Alterar Comissão</button>
                  </div>
                </div>            
            </div>
            </LoadingOverlay>
          </div>
        </div>
      </div>

      <Modal size={'lg'} centered show={modal} onHide={() => fecharModal()}>
      <Modal.Header>
      <Modal.Title>Alterado</Modal.Title>
          <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={e => {fecharModal()}}>
            <i aria-hidden="true" className="ki ki-close" />
          </button>
      </Modal.Header> 

        <div className='row ml-5 mr-5 mt-3'>
          <table className="table table-striped table-bordered">
            <thead>
                <tr>
                    <th className="text-center">Pedido Nº</th>
                    <th className="text-center">ID Estabelecimento</th>
                    <th className="text-center">Comissão Anterior</th>
                    <th className="text-center">Comissão Atual</th>
                </tr>
            </thead>
            <tbody>
              { retorno.map( (item) => { 
                return (
                <> 
                  <tr>
                    <td>{item.id_pedido}</td>
                    <td>{item.id_estabelecimento}</td>
                    <td>{item.comissao}</td>
                    <td>{item.comissaoNova}</td>
                  </tr> 
                </>
                )
              })
              }                                                                                          
            </tbody>
          </table>
        </div>
      <Modal.Footer>
        <button type="button" onClick={e => { fecharModal(); }} className="btn btn-light-primary font-weight-bold" data-dismiss="modal"><i className="fas fa-door-open" />Fechar</button>
      </Modal.Footer>
    </Modal>  

        </>
    );

}



export default withRouter(AtComissao);                  
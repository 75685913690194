import React, { useState, useEffect } from 'react';
import Api from '../../../services/api';
import { withRouter } from "react-router";

import Swal from "sweetalert2";

import DataTable from 'react-data-table-component';
import Checkbox from '@material-ui/core/Checkbox';
import { Button } from '@material-ui/core';
import { Modal } from "react-bootstrap";

const Sugestoes = () => {
  const [ sugerir, setSugerir ] = useState([]);
  const [ sugerirDetalhes, setSugerirDetalhes ] = useState([]);
  const [ , setCaProcessa ] = useState(false);
  const [ exibeCheck ] = useState(true);
  const [ buscaNome, setBuscaNome ] = useState('');
  const [ buscaCidade, setBuscaCidade ] = useState('');
  const [ , setValores ] = useState([]);
  const [ modalSugestoes, setModalSugestoes ] = useState(false);
  let acesso = JSON.parse(localStorage.getItem('@ADM-Aiboo/perfil'));

  const state = { toggledClearRows: false }
  const handleChange = (state) => {
    // You can use setState or dispatch with something like Redux so we can use the retrieved data
    console.log('Selected Rows: ', state.selectedRows);
    setValores(state.selectedRows);
  };
  // Toggle the state so React Table Table changes to `clearSelectedRows` are triggered
  // const handleClearRows = () => {
  //   this.setState({ toggledClearRows: !this.state.toggledClearRows})
  // }

  function fecharModal(){
    setModalSugestoes(false);
  }

  //DataTable principal
  const data = sugerir;
  const columns = [
    {
      name: <th>Estabelecimento</th>,
      selector: 'nome_estabelecimento',
      sortable: true,
      width: '40%',
    },
    {
      name: <th>Cidade</th>,
      selector: 'cidade',
      width: '30%',
      sortable: true,
    },
    {
      name: <th>Quantidade</th>,
      selector: 'qtde',
      width: '15%',
      sortable: true,
      right: true,
    },
    acesso.est_ger_sug_ei === "1" &&
    {
      name: <th>Ação</th>,
      cell: (row) => <Button className="btn btn-success" onClick={e => {abrirModal(row.nome_estabelecimento, row.cidade)}}>Detalhes</Button>,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      center: true,
    },
  ];

    //DataTable Detalhes
    const dataDet = sugerirDetalhes;
    const columnsDet = [
      {
        name: <th>ID Usuário</th>,
        selector: 'id_usuario',
        sortable: true,
        width: '10%',
      },
      {
        name: <th>Nome</th>,
        selector: 'nome',
        sortable: true,
        width: '30%',
      },
      {
        name: <th>Estabelecimento</th>,
        selector: 'nome_estabelecimento',
        sortable: true,
        width: '30%',
      },
      {
        name: <th>Contato</th>,
        selector: 'contato_estabelecimento',
        sortable: true,
        width: '15%',
      },
      {
        name: <th>Data Criação</th>,
        selector: 'data_criacao',
        sortable: true,
        width: '15%',
      }
    ];

  useEffect(() => {
    getListar();
  }, []);

  function getListar() {  
    Api.get("sugerir/listar").then(rps => {
      setSugerir(rps.data.obj);
    })
  }

  function abrirModal(nome, cidade){  
    var data = {
      nome: nome,
      cidade: cidade,
  }
    Api.post('sugerir/listarDetalhado/', data).then(rps => {
      if(rps.data.status === true){
         setModalSugestoes(true);
         setSugerirDetalhes(rps.data.obj);
      }
    });
  }

  function filtrar() {
    setCaProcessa(true); 
      var data = {
        nome: buscaNome,
        cidade: buscaCidade,
      };
    
      Api.post('sugerir/listar', data).then((rps) => {

        if(rps.data.status==false){
          Swal.fire({
            title: "Atenção!",
            icon: "info",
            html: rps.data.erro,
            showCloseButton: true,
            showCancelButton: false,
          });
        } else {
          setSugerir(rps.data.obj);
        }
      setCaProcessa(false); 
    });
  }
    
    return (
        <>
<div>

<div className="subheader espaco-header-pedidos subheader-transparent" id="kt_subheader">
          <div className="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
            {/*begin::Details*/}
            <div className="d-flex align-items-center flex-wrap mr-2">
              {/*begin::Title*/}
              <h5 className="text-dark font-weight-bold mt-2 mb-2 mr-5">Sugestões</h5>
              {/*end::Title*/}
              {/*begin::Separator*/}
              <div className="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200" />
              {/*end::Separator*/}
            </div>
            {/*end::Details*/}
            {/*begin::Toolbar*/}
            <div className="d-flex align-items-center">

            </div>
            {/*end::Toolbar*/}
          </div>
        </div>
        {/*begin::Entry*/}
        <div className="d-flex flex-column-fluid">
          {/*begin::Container*/}
          <div className="container">
            <div className="card card-custom">
              <div className="ml-10 mr-10 mt-10">    
                <div className="row">
                  <div className="col-md-6 form-group">
                    <label>Nome</label>
                    <input onChange={(e)=> setBuscaNome(e.target.value)} className="form-control" />                      
                  </div>

                  <div className="col-md-5 form-group">
                    <label>Cidade</label>
                    <input value={buscaCidade} onChange={(e)=> setBuscaCidade(e.target.value)} className="form-control" />    
                  </div>  

                  <div className="col-md-1 form-group">
                  <label>&nbsp;</label><br />
                    <button className="btn btn-primary" onClick={e=>{filtrar()}}>Filtrar</button>
                  </div> 
                </div>     
              </div>
                                
              <DataTable
                title="Lista de Sugestões de Estabelecimentos"
                columns={columns}
                data={data}
                selectableRows={exibeCheck == true ? true : false } // Apenas os que forem pendentes podem ser selecionados
                onSelectedRowsChange={handleChange}
                clearSelectedRows={state.toggledClearRows}
                selectableRowsComponent={Checkbox} 
                selectableRowsComponentProps={{ inkDisabled: true }} 
                pagination="true"
              />

                    
      
                  </div>
                  </div>
                  </div>
                  </div>
                  
      <Modal size={'xl'} show={modalSugestoes} onHide={() => fecharModal()}>
      <Modal.Header>
      <Modal.Title>Pessoas que indicaram</Modal.Title>
          <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={e => {fecharModal()}}>
            <i aria-hidden="true" className="ki ki-close" />
          </button>
      </Modal.Header>
      <div className="row">
        <div className="col-md-12">
        <DataTable
                title="Lista de Sugestões de Estabelecimentos"
                columns={columnsDet}
                data={dataDet}
                pagination="true"
              />
        </div>
      </div>
      
      <Modal.Footer>
        <button type="button" onClick={e => { fecharModal(); }} className="btn btn-light-primary font-weight-bold" data-dismiss="modal">Fechar</button>
      </Modal.Footer>
    </Modal>        
                 
        </>
    );

}



export default withRouter(Sugestoes);                  
import React, { memo, useRef } from "react";

import Swal from "sweetalert2";
import axios from "axios";
import { useToasts } from "react-toast-notifications";

import api from "../../../../../services/api";

const FilesComponent = ({
  merchantId,
  envioImagemPagOn,
  est_imgIdentificacao,
  est_imgResidencia,
  est_imgAtividade,
  id_pagon_cadastro,
  setPagOnCadastro,
}) => {
  const refImgIdentificacao = useRef(null);
  const refImgResidencia = useRef(null);
  const refImgAtividade = React.useRef(null);

  const { addToast } = useToasts();

  async function handleCadIdentificacaoChange(e) {
    const form_data = new FormData();
    form_data.append("arquivo", e.target.files[0]);
    form_data.append("id_estabelecimento", id_pagon_cadastro);

    const cf = {
      baseURL: api.defaults.baseURL,
      headers: {
        "content-type": "multipart/form-data",
        Authorization: api.defaults.headers["Authorization"],
      },
    };
    const response = await axios.post(
      "pagamentoonline/imgidentificacao",
      form_data,
      cf
    );
    if (response.data.status !== undefined && response.data.status === true) {
      addToast(response.data.mensagem, {
        appearance: "success",
        autoDismiss: true,
        autoDismissTimeout: 5000,
      });
      setPagOnCadastro((prevState) => ({
        ...prevState,
        est_imgIdentificacao: response.data.url_imagem,
      }));
    } else {
      Swal.fire({
        title: "Atenção!",
        icon: "info",
        html: response.data.erros,
        showCloseButton: true,
        showCancelButton: false,
      });
    }
  }

  async function handleCadResidenciaChange(e) {
    const form_data = new FormData();
    form_data.append("arquivo", e.target.files[0]);
    form_data.append("id_estabelecimento", merchantId);

    const cf = {
      baseURL: api.defaults.baseURL,
      headers: {
        "content-type": "multipart/form-data",
        Authorization: api.defaults.headers["Authorization"],
      },
    };
    const response = await axios.post(
      "pagamentoonline/imgresidencia",
      form_data,
      cf
    );
    if (response.data.status !== undefined && response.data.status === true) {
      addToast(response.data.mensagem, {
        appearance: "success",
        autoDismiss: true,
        autoDismissTimeout: 5000,
      });
      setPagOnCadastro((prevState) => ({
        ...prevState,
        est_imgResidencia: response.data.url_imagem,
      }));
    } else {
      Swal.fire({
        title: "Atenção!",
        icon: "info",
        html: response.data.erros,
        showCloseButton: true,
        showCancelButton: false,
      });
    }
  }

  async function handleCadAtividadeChange(e) {
    const form_data = new FormData();
    form_data.append("arquivo", e.target.files[0]);
    form_data.append("id_estabelecimento", merchantId);

    const cf = {
      baseURL: api.defaults.baseURL,
      headers: {
        "content-type": "multipart/form-data",
        Authorization: api.defaults.headers["Authorization"],
      },
    };
    const response = await axios.post(
      "pagamentoonline/imgatividade",
      form_data,
      cf
    );
    if (response.data.status !== undefined && response.data.status === true) {
      addToast(response.data.mensagem, {
        appearance: "success",
        autoDismiss: true,
        autoDismissTimeout: 5000,
      });
      setPagOnCadastro((prevState) => ({
        ...prevState,
        est_imgAtividade: response.data.url_imagem,
      }));
    } else {
      Swal.fire({
        title: "Atenção!",
        icon: "info",
        html: response.data.erros,
        showCloseButton: true,
        showCancelButton: false,
      });
    }
  }

  return (
    <div
      className="tab-pane fade"
      id="pag_arquivos"
      role="tabpanel"
      aria-labelledby="pag_arquivos"
    >
      <h6 className="font-weight-boldest">Identificação</h6>
      <div className="row">
        <div className="col-md-6">
          Documento com foto do proprietário do estabelecimento (RG, CPF ou CNH)
          ou Cartão CNPJ (no caso de PJ)
        </div>
        <div className="col-md-6">
          <div className="area-imagem dropzone--squared menu-category-drawer-item-details-tab__image">
            <div
              className="dropzone__area"
              aria-disabled="false"
              style={{ position: "relative" }}
              onClick={(e) => {
                if (envioImagemPagOn == true) {
                  refImgIdentificacao.current.click();
                } else {
                  Swal.fire({
                    title: "Erro!",
                    icon: "error",
                    html: "Salve o cadastro do Pagamento Online antes de cadastrar uma imagem!",
                    showCloseButton: true,
                    showCancelButton: false,
                  });
                }
              }}
            >
              <div className="dropzone-preview dropzone__preview">
                <img
                  className="dropzone-preview__image"
                  src={
                    est_imgIdentificacao
                      ? est_imgIdentificacao
                      : "assets/media/no-img2.jpg"
                  }
                  alt=""
                />
              </div>
              <div className="dropzone-placeholder dropzone__placeholder">
                <span>Trocar imagem</span>
              </div>
              <input
                type="file"
                ref={refImgIdentificacao}
                name="imagemLogo"
                onChange={(e) => handleCadIdentificacaoChange(e)}
                accept=".png, .jpg, .jpeg"
                className="classeInputImage"
              />
            </div>
          </div>
        </div>
      </div>

      <hr />

      <h6 className="font-weight-boldest">Residência</h6>
      <div className="row">
        <div className="col-md-6">
          Conta de luz, telefone ou gás no nome do proprietário do
          estabelecimento ou de familiares
        </div>
        <div className="col-md-6">
          <div className="area-imagem dropzone--squared menu-category-drawer-item-details-tab__image">
            <div
              className="dropzone__area"
              aria-disabled="false"
              style={{ position: "relative" }}
              onClick={(e) => {
                if (envioImagemPagOn == true) {
                  refImgResidencia.current.click();
                } else {
                  Swal.fire({
                    title: "Erro!",
                    icon: "error",
                    html: "Salve o cadastro do Pagamento Online antes de cadastrar uma imagem!",
                    showCloseButton: true,
                    showCancelButton: false,
                  });
                }
              }}
            >
              <div className="dropzone-preview dropzone__preview">
                <img
                  className="dropzone-preview__image"
                  src={
                    est_imgResidencia
                      ? est_imgResidencia
                      : "assets/media/no-img2.jpg"
                  }
                  alt=""
                />
              </div>
              <div className="dropzone-placeholder dropzone__placeholder">
                <span>Trocar imagem</span>
              </div>
              <input
                type="file"
                ref={refImgResidencia}
                name="imagemLogo"
                onChange={(e) => handleCadResidenciaChange(e)}
                accept=".png, .jpg, .jpeg"
                className="classeInputImage"
              />
            </div>
          </div>
        </div>
      </div>

      <hr />

      <h6 className="font-weight-boldest">Atividade</h6>
      <div className="row">
        <div className="col-md-6">
          Alvará, nota fiscal de compras de produtos ou cartão de visitas do
          estabelecimento
        </div>
        <div className="col-md-6">
          <div className="area-imagem dropzone--squared menu-category-drawer-item-details-tab__image">
            <div
              className="dropzone__area"
              aria-disabled="false"
              style={{ position: "relative" }}
              onClick={() => {
                if (envioImagemPagOn == true) {
                  refImgAtividade.current.click();
                } else {
                  Swal.fire({
                    title: "Erro!",
                    icon: "error",
                    html: "Salve o cadastro do Pagamento Online antes de cadastrar uma imagem!",
                    showCloseButton: true,
                    showCancelButton: false,
                  });
                }
              }}
            >
              <div className="dropzone-preview dropzone__preview">
                <img
                  className="dropzone-preview__image"
                  src={
                    est_imgAtividade
                      ? est_imgAtividade
                      : "assets/media/no-img2.jpg"
                  }
                  alt=""
                />
              </div>
              <div className="dropzone-placeholder dropzone__placeholder">
                <span>Trocar imagem</span>
              </div>
              <input
                type="file"
                ref={refImgAtividade}
                name="imagemLogo"
                onChange={(e) => handleCadAtividadeChange(e)}
                accept=".png, .jpg, .jpeg"
                className="classeInputImage"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const Files = memo(FilesComponent);

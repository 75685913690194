import React from "react";
import * as S from "./styles";

export function Button({ children, variant, isLoading = false, ...rest }) {
  return (
    <S.Container {...rest} variant={variant}>
      {isLoading ? <S.Spinner /> : children}
    </S.Container>
  );
}

export default Button;

import { useState } from "react";
import { addressService } from "services";
import { useMerchantManager } from "./useMerchantManager";
import { useToasts } from "react-toast-notifications";

export const useSearchLocationOnMap = ({
  addressStreet,
  addressNumber,
  addressArea,
  city,
}) => {
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [location, setLocation] = useState({ latitude: 0, longitude: 0 });

  const { setValue } = useMerchantManager();
  const { addToast } = useToasts();

  const addressLabel = `${addressStreet}, ${addressNumber}, ${addressArea} - ${city?.label}`;

  const searchLocationOnMap = async () => {
    try {
      setIsLoading(true);

      const response = await addressService.verifyCoordinateByAddressLabel(
        addressLabel
      );

      if (!response?.list) {
        return setIsModalOpened(true);
      }

      const [storedLocation] = response.list;

      if (storedLocation.lat && storedLocation.lng) {
        setLocation({
          latitude: storedLocation.lat,
          longitude: storedLocation.lng,
        });
      }

      setIsModalOpened(true);
    } catch (error) {
      addToast("Não foi possível buscar no mapa, tente novamente mais tarde.", {
        appearance: "error",
        autoDismiss: true,
        autoDismissTimeout: 4000,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const updateLocation = () => {
    setValue("lat", location.latitude);
    setValue("lng", location.longitude);
  };

  return {
    searchLocationOnMap,
    addressLabel,
    isModalOpened,
    setIsModalOpened,
    isLoading,
    location,
    setLocation,
    updateLocation,
  };
};

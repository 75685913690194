import { useCallback, useEffect, useState } from "react";

import { useWatch } from "react-hook-form";

import { paymentService } from "services";

import { useMerchantManager } from "./useMerchantManager";

export const usePaymentMethods = (merchantId) => {
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [status, setStatus] = useState("loading");

  const { setValue, control } = useMerchantManager();

  const selectedPaymentMethods = useWatch({ control, name: "payments" });

  const fetchPaymentMethods = useCallback(async () => {
    try {
      const response = await paymentService.listPaymentMethodsByMerchantId(
        merchantId
      );

      setPaymentMethods(response.obj);
      setValue(
        "payments",
        response.obj.filter((paymentMethod) => paymentMethod.selected)
      );
    } catch (error) {
      setStatus("error");
    } finally {
      setStatus("idle");
    }
  }, [merchantId, setValue]);

  const formatPaymentMethodNameByKey = (key = "") => {
    const REGEX_TO_REMOVE_ALL_BLANK_SPACES = /\s/g;

    return key
      .toLowerCase()
      .split("|")[0]
      ?.replace(REGEX_TO_REMOVE_ALL_BLANK_SPACES, "");
  };

  const togglePaymentMethod = (paymentMethod) => {
    const isSelected = selectedPaymentMethods?.find(
      (method) => method.id === paymentMethod.id
    );

    if (isSelected) {
      setValue(
        "payments",
        selectedPaymentMethods.filter(
          (method) => method.id !== paymentMethod.id
        )
      );
    } else {
      setValue("payments", [...(selectedPaymentMethods || []), paymentMethod]);
    }
  };

  useEffect(() => {
    fetchPaymentMethods();
  }, [fetchPaymentMethods]);

  return {
    paymentMethods,
    isLoading: status === "loading",
    isError: status === "error",
    formatPaymentMethodNameByKey,
    togglePaymentMethod,
  };
};

export const dispInitialState = {
  checked: false,
  initAt: "",
  endAt: "",
  initAt2: "",
  endAt2: "",
};

export const getDefaultAgent = () => {
  if (localStorage.getItem("@ADM-Aiboo/nivel") == "R") {
    return {
      label: localStorage.getItem("@ADM-Aiboo/nome"),
      value: localStorage.getItem("@ADM-Aiboo/id_representante"),
    };
  }
};

export const maritalStatusOptions = [
  { label: "Solteiro(a)", value: "single" },
  { label: "Casado(a)", value: "married" },
  { label: "União estável", value: "stable_union" },
  { label: "Divorciado(a)", value: "divorced" },
  { label: "Viúvo(a)", value: "widowed" },
];

export const statusOptions = [
  { label: "Ativo", value: "S" },
  { label: "Inativo", value: "N" },
];

export const openingWayOptions = [
  { label: "Manual | Auto open/close", value: "M" },
  { label: "Last seen (impressora)", value: "L" },
];

export const calculationWayOptions = [
  { label: "Produtos", value: "P" },
  { label: "Produtos + Frete", value: "T" },
];

export const paymentStatusOptions = [
  { label: "Liberado", value: "P" },
  { label: "Bloqueado", value: "B" },
];

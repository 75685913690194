import React, { memo } from "react";

import LoadingOverlay from "react-loading-overlay";
import Swal from "sweetalert2";
import { useToasts } from "react-toast-notifications";

import api from "../../../../../services/api";

const PlansComponent = ({
  merchantId,
  caProcessa,
  setCaProcessa,
  id_pagon_planos_taxas,
  id_pagon_cadastro,
  pagOnPlanosTaxas,
  getPagOnCadastro,
}) => {
  const { addToast } = useToasts();

  function ativarPlano(idPlano) {
    var data = {
      id_pagon_cadastro,
      id_pagon_planos_taxas: idPlano,
    };
    setCaProcessa(true);
    api.post("pagamentoonline/ativarPagOnPlano", data).then((rps) => {
      if (rps.data.status === true) {
        addToast(rps.data.mensagem, {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 2000,
        });
        getPagOnCadastro(merchantId);
        setCaProcessa(false);
      } else {
        Swal.fire({
          title: "Erro!",
          icon: "error",
          html: rps.data.erros,
          showCloseButton: true,
          showCancelButton: false,
        });
      }
    });
  }

  return (
    <div
      className="tab-pane fade"
      id="pag_planos"
      role="tabpanel"
      aria-labelledby="pag_planos"
    >
      <h6 className="font-weight-boldest">Planos de taxas</h6>
      <small>
        Conheça as condições de prazos de recebimento e taxas disponíveis para
        você.
      </small>

      <LoadingOverlay active={caProcessa} spinner text="Carregando...">
        <div className="card-body">
          <div
            className="accordion accordion-solid accordion-toggle-plus"
            id="accordionExample6"
          >
            <div className="card">
              <div className="card-header" id="headingOne6">
                <div
                  className="card-title"
                  data-toggle="collapse"
                  data-target="#collapseOne6"
                >
                  <i className="flaticon-pie-chart-1" />
                  Plano Standard Padrão&nbsp;&nbsp;
                  {id_pagon_planos_taxas == 1 && (
                    <>
                      <span class="label label-success label-inline mr-2">
                        Plano Ativo
                      </span>
                    </>
                  )}
                </div>
              </div>
              <div
                id="collapseOne6"
                className="collapse show"
                data-parent="#accordionExample6"
              >
                <div className="row">
                  <div className="col-xl-6">
                    <div className="card card-custom bg-light-danger gutter-b my-1">
                      <div className="card-body">
                        <label className="card-title font-weight-bolder text-danger font-size-h6 mb-0 text-hover-state-dark d-block">
                          Débito
                        </label>
                        <label>Recebimento em 1 dia</label>

                        <div className="row">
                          <div className="col-xl-6">
                            <b>Bandeiras</b>
                          </div>
                          <div className="col-xl-6">
                            <b>Taxas</b>
                          </div>
                        </div>

                        {pagOnPlanosTaxas.map((pt) => {
                          if (
                            pt.id_pagon_plano === "1" &&
                            pt.tipo == "Débito"
                          ) {
                            return (
                              <>
                                <div className="row">
                                  <div className="col-xl-6">{pt.bandeira}</div>
                                  <div className="col-xl-6">{pt.taxas}</div>
                                </div>
                              </>
                            );
                          } else {
                            return null;
                          }
                        })}
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-6">
                    <div className="card card-custom bg-light-success gutter-b my-1">
                      <div className="card-body">
                        <label className="card-title font-weight-bolder text-success font-size-h6 mb-0 text-hover-state-dark d-block">
                          Crédito
                        </label>
                        <label>Recebimento em 30 dia</label>

                        <div className="row">
                          <div className="col-xl-4 ">
                            <b>Parcelas</b>
                          </div>
                          <div className="col-xl-4">
                            <b>Bandeiras</b>
                          </div>
                          <div className="col-xl-4">
                            <b>Taxas</b>
                          </div>
                        </div>

                        {pagOnPlanosTaxas.map((pt) => {
                          if (
                            pt.id_pagon_plano === "1" &&
                            pt.tipo == "Crédito"
                          ) {
                            return (
                              <>
                                <div className="row">
                                  <div className="col-xl-4">{pt.parcelas}</div>
                                  <div className="col-xl-4">{pt.bandeira}</div>
                                  <div className="col-xl-4">{pt.taxas}</div>
                                </div>
                              </>
                            );
                          } else {
                            return null;
                          }
                        })}
                      </div>
                    </div>
                  </div>
                </div>
                {id_pagon_planos_taxas != 1 && (
                  <>
                    <div className="text-right">
                      <button
                        className="btn btn-primary"
                        onClick={() => {
                          ativarPlano(1);
                        }}
                      >
                        Ativar este plano
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="card">
              <div className="card-header" id="headingTwo6">
                <div
                  className="card-title collapsed"
                  data-toggle="collapse"
                  data-target="#collapseTwo6"
                >
                  <i className="flaticon2-notification" />
                  Plano Pro Padrão&nbsp;&nbsp;
                  {id_pagon_planos_taxas == 2 && (
                    <>
                      <span class="label label-success label-inline mr-2">
                        Plano Ativo
                      </span>
                    </>
                  )}
                </div>
              </div>
              <div
                id="collapseTwo6"
                className="collapse"
                data-parent="#accordionExample6"
              >
                <div className="row">
                  <div className="col-xl-6">
                    <div className="card card-custom bg-light-danger gutter-b my-1">
                      <div className="card-body">
                        <label className="card-title font-weight-bolder text-danger font-size-h6 mb-0 text-hover-state-dark d-block">
                          Débito
                        </label>
                        <label>Recebimento em 1 dia</label>

                        <div className="row">
                          <div className="col-xl-6">
                            <b>Bandeiras</b>
                          </div>
                          <div className="col-xl-6">
                            <b>Taxas</b>
                          </div>
                        </div>

                        {pagOnPlanosTaxas.map((pt) => {
                          if (
                            pt.id_pagon_plano === "2" &&
                            pt.tipo == "Débito"
                          ) {
                            return (
                              <>
                                <div className="row">
                                  <div className="col-xl-6">{pt.bandeira}</div>
                                  <div className="col-xl-6">{pt.taxas}</div>
                                </div>
                              </>
                            );
                          } else {
                            return null;
                          }
                        })}
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-6">
                    <div className="card card-custom bg-light-success gutter-b my-1">
                      <div className="card-body">
                        <label className="card-title font-weight-bolder text-success font-size-h6 mb-0 text-hover-state-dark d-block">
                          Crédito
                        </label>
                        <label>Recebimento em 30 dia</label>

                        <div className="row">
                          <div className="col-xl-4 ">
                            <b>Parcelas</b>
                          </div>
                          <div className="col-xl-4">
                            <b>Bandeiras</b>
                          </div>
                          <div className="col-xl-4">
                            <b>Taxas</b>
                          </div>
                        </div>

                        {pagOnPlanosTaxas.map((pt) => {
                          if (
                            pt.id_pagon_plano === "2" &&
                            pt.tipo == "Crédito"
                          ) {
                            return (
                              <>
                                <div className="row">
                                  <div className="col-xl-4">{pt.parcelas}</div>
                                  <div className="col-xl-4">{pt.bandeira}</div>
                                  <div className="col-xl-4">{pt.taxas}</div>
                                </div>
                              </>
                            );
                          } else {
                            return null;
                          }
                        })}
                      </div>
                    </div>
                  </div>
                </div>
                {id_pagon_planos_taxas != 2 && (
                  <>
                    <div className="text-right">
                      <button
                        className="btn btn-primary"
                        onClick={() => {
                          ativarPlano(2);
                        }}
                      >
                        Ativar este plano
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </LoadingOverlay>
    </div>
  );
};

export const Plans = memo(PlansComponent);

import React, { useState, useEffect, useRef } from "react";

import Api from "../../../services/api";

import { useToasts } from "react-toast-notifications";
import Swal from "sweetalert2";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
//import impressoras from '../../../utils/impressora'

//import ReactToPrint from "react-to-print";
import "./index.scss";
import apiLaravelv2 from "../../../services/api-laravelv2";

const Details = (props) => {
  const { addToast } = useToasts();
  const { pedido, pedidoAutoAceiteImprimir } = props;
  const [, setDisableButtons] = useState(false);
  const [modalCancelarPedido, setModalCancelarPedido] = useState(false);
  const [idPedido, setIdPedido] = useState("");
  const [observacao, setObservacao] = useState("");
  const [motivo, setMotivo] = useState("");
  const [motivationError, setMotivationError] = useState("");

  const [telefonesEst, setTelefonesEst] = useState([]);
  const [telSelecionado, setTelSelecionado] = useState(null);

  const printRef = useRef();

  const hasErrorInCancelMotivation =
    (motivo === 0 || motivo === "0") && observacao.length <= 5;

  useEffect(() => {
    if (pedidoAutoAceiteImprimir?.hash_pedido) {
      updateOrderStatus(pedidoAutoAceiteImprimir.id_pedido, "PI", "auto");
      //impressoras.imprimir(pedidoAutoAceiteImprimir);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pedidoAutoAceiteImprimir]);

  useEffect(() => {
    if (pedido.telefonesEst != undefined) {
      var nTelefones = [];
      if (pedido.telefonesEst.length > 0) {
        pedido.telefonesEst.forEach((t) => {
          nTelefones[nTelefones.length] = {
            value: t.telefone,
            label: t.telefone,
          };
        });
        setTelSelecionado(pedido.telefonesEst[0].telefone);
      }
      setTelefonesEst(nTelefones);
      console.log(nTelefones);
    }
  }, [pedido]);

  function ligaLoja() {
    Api.post("pedidos/clickToCall", {
      pedido: pedido.id_pedido,
      numero: telSelecionado,
    }).then((rps) => {
      alert("ok");
    });
  }

  // function printOrder(order, printerSelected){
  //     updateOrderStatus(order.id_pedido, 'PI')
  //     //impressoras.imprimir(order);
  // }

  if (process.env.REACT_APP_USE_ELECTRON == true) {
    console.log("Elect " + process.env.REACT_APP_USE_ELECTRON);
  } else {
    console.log("WEB2 " + process.env.REACT_APP_USE_ELECTRON);
  }

  async function updateOrderStatus(id_pedido, status, obs) {
    setDisableButtons(true);

    try {
      const response = await apiLaravelv2.put("orders/" + id_pedido, {
        new_status: status,
        obs: obs,
      });

      addToast("Status do pedido alterado", {
        appearance: "success",
        autoDismiss: true,
        autoDismissTimeout: 2000,
      });

      setDisableButtons(false);
    } catch {
      Swal.fire({
        title: "Atenção!",
        icon: "info",
        html: "Ocorreu um erro ao mudar o status do pedido. Tente novamente mais tarde",
        showCloseButton: true,
        showCancelButton: false,
      });
    }
    props.orders();
  }

  const ButtonActionsComponent = (params) => {
    return (
      <>
        <button
          type="submit"
          className="btn btn-sm btn-success mr-2"
          onClick={() => updateOrderStatus(params.id_pedido, "PA")}
        >
          Aceitar
        </button>

        {pedido.pedidoObj?.retirada_local ? (
          <button
            type="submit"
            class="btn btn-sm btn-facebook mr-2"
            onClick={() => updateOrderStatus(params.id_pedido, "PICKUP_READY")}
          >
            Pronto para retirada
          </button>
        ) : (
          <button
            type="submit"
            class="btn btn-sm btn-facebook mr-2"
            onClick={() => updateOrderStatus(params.id_pedido, "PE")}
          >
            Enviado
          </button>
        )}

        <button
          type="submit"
          class="btn btn-sm btn-info mr-2"
          onClick={() => updateOrderStatus(params.id_pedido, "PD")}
        >
          Entregue
        </button>
        <button
          type="submit"
          className="btn btn-sm btn-danger mr-2"
          onClick={() => handleOpenModalCancelarPedido(params.id_pedido, "PC")}
        >
          Cancelar Pedido
        </button>
        {/* <button type="submit" className="btn btn-sm btn-secondary" onClick={() => console.log(params.id_pedido, 'DENUNCIAR')}>Denunciar</button> */}
      </>
    );
  };

  function handleCloseModalCancelarPedido() {
    setModalCancelarPedido(false);
    setMotivo("Cliente indisponível para contato");
    setObservacao("");
    setMotivationError("");
  }

  function handleOpenModalCancelarPedido(id) {
    setModalCancelarPedido(true);
    setIdPedido(id);
  }

  async function cancelOrder() {
    try {
      if (hasErrorInCancelMotivation) {
        setMotivationError(
          "Descrição de motivo muito curta."
        );

        return;
      }

      await apiLaravelv2.put("orders/" + pedido.id_pedido, {
        new_status: "PC",
        motivo_cancelamento: handleMotivo(),
      });

      addToast("Status do pedido alterado", {
        appearance: "success",
        autoDismiss: true,
        autoDismissTimeout: 2000,
      });
    } catch {
      Swal.fire({
        title: "Atenção!",
        icon: "info",
        html: "Ocorreu um erro ao mudar o status do pedido. Tente novamente mais tarde",
        showCloseButton: true,
        showCancelButton: false,
      });
    }

    handleCloseModalCancelarPedido();
  }

  function handleMotivo() {
    if (motivo === 0 || motivo === "0") {
      return observacao;
    }

    return motivo;
  }

  return (
    <>
      <div className="cabecalho" ref={printRef}>
        <p
          className="content"
          dangerouslySetInnerHTML={{ __html: pedido.pedido }}
        ></p>
      </div>
      <div>
        <div className="list-group-item print-none">
          <div className="row">
            <div className="col-md-2">
              <p>HASH</p>
              <h5>
                <b>
                  {/*<span class="badge badge-inverse"><i class="fa fa-laptop"></i></span> */}
                  #{pedido.hash_pedido}
                </b>
              </h5>
            </div>

            <div className="col-md-4">
              <p>CLIENTE</p>
              <h5>
                <b>
                  <span className="label label-dark label-inline mr-2">
                    {pedido.compraGlobal}º
                  </span>
                  <span className="label label-light-danger label-pill label-inline mr-2">
                    {pedido.compraEst}º
                  </span>
                  {pedido.usuario_nome}
                </b>
              </h5>

              <div id="classificacaoCompra"></div>
            </div>

            <div className="col-md-4 form-group">
              <p>GERENCIAR</p>
              <div class="input-group">
                <select
                  className="form-control"
                  value={telSelecionado}
                  onChange={(e) => {
                    setTelSelecionado(e.value);
                  }}
                >
                  {telefonesEst.map((item) => {
                    return <option value={item.value}>{item.label}</option>;
                  })}
                </select>
                <div class="input-group-append">
                  <button
                    onClick={(e) => {
                      ligaLoja();
                    }}
                    className="btn waves-effect waves-light btn-primary"
                    type="button"
                  >
                    <i className="fa fa-phone" />
                  </button>
                </div>
              </div>
            </div>

            <div className="col-md-2">
              <p>STATUS</p>
              {pedido.status == "PI" && (
                <h5 className="impresso">
                  <b>IMPRESSO</b>
                </h5>
              )}
              {pedido.status == "PA" && (
                <h5 className="text-warning">
                  <b>ACEITO</b>
                </h5>
              )}
              {pedido.status == "PP" && (
                <h5 className="text-danger">
                  <b>PENDENTE</b>
                </h5>
              )}
              {pedido.status == "PD" && (
                <h5 className="entregue">
                  <b>ENTREGUE</b>
                </h5>
              )}
              {pedido.status == "PC" && (
                <h5 className="text-danger">
                  <b>CANCELADO</b>
                </h5>
              )}
              {pedido.status == "PE" && (
                <h5 className="text-info">
                  <b>ENVIADO</b>
                </h5>
              )}
              {pedido.status == "PICKUP_READY" && (
                <h5 className="text-info">
                  <b>PRONTO PARA RETIRADA</b>
                </h5>
              )}
            </div>
          </div>
        </div>
      </div>

      <div role="toolbar" className="btn-toolbar botoes-acoes-area">
        <ButtonActionsComponent
          status={pedido.status}
          id_pedido={pedido.id_pedido}
        />
      </div>
      <hr className="port-hr mb-0" />

      {/*<div className="pedido-area" id="style-1">
                    <ReactMarkdown source={pedido.pedido} escapeHtml={false}/>
                </div>*/}
      {pedido.pedido && (
        <div className="list-group-item print-none">
          <strong>REGISTRO DE ATIVIDADES - LOGS</strong>
          <div
            id="logsPedido"
            name="logsPedido"
            style={{
              height: "100px",
              border: "1px solid #ccc",
              overflowY: "scroll",
              overflowX: "hidden",
              padding: "10px",
              fontFamily: "arial",
            }}
          >
            {pedido.logs.html.map((linha, index) => {
              return <div dangerouslySetInnerHTML={{ __html: linha }} />;
            })}
          </div>

          <hr className="port-hr mb-0" />

          <div className="pedido-area" id="style-1">
            <div className="row">
              <div dangerouslySetInnerHTML={{ __html: pedido.pedido }} />
            </div>
          </div>
        </div>
      )}

      <Modal
        show={modalCancelarPedido}
        onHide={() => handleCloseModalCancelarPedido()}
      >
        <Modal.Header closeButton>
          <Modal.Title>Cancelar Pedido {idPedido}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            className="alert alert-custom alert-outline-2x alert-outline-warning fade show mb-5"
            role="alert"
          >
            <div className="alert-icon">
              <i className="flaticon-warning" />
            </div>
            <div className="alert-text">
              OBS: O cliente receberá o motivo do cancelamento.
            </div>
            <div className="alert-close">
              <button
                type="button"
                className="close"
                onClick={() => handleCloseModalCancelarPedido()}
              >
                <span aria-hidden="true">
                  <i className="ki ki-close" />
                </span>
              </button>
            </div>
          </div>
          <div className="form-group">
            <label>Motivo:</label>
            <select
              value={motivo}
              onChange={(e) => setMotivo(e.target.value)}
              className="form-control"
            >
              <option value={"Cliente indisponível para contato"}>
                Cliente indisponível para contato
              </option>
              <option value={"Cliente pediu um item que não estava disponível"}>
                Cliente pediu um item que não estava disponível
              </option>
              <option value={0}>Outro motivo...</option>
            </select>
          </div>
          <div className="form-group">
            <label>Descreva o motivo:</label>
            <textarea
              className="form-control form-control-lg"
              type="text"
              onChange={(e) => setObservacao(e.target.value)}
            />
          </div>

          {hasErrorInCancelMotivation && motivationError && (
            <span className="d-block mt-4 text-red text-danger">
              {motivationError}
            </span>
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => handleCloseModalCancelarPedido()}
          >
            Cancelar
          </Button>
          <Button variant="primary" onClick={() => cancelOrder()}>
            Salvar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Details;

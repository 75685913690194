import React, { memo } from "react";

import InputMask from "react-input-mask";
import Swal from "sweetalert2";
import { useToasts } from "react-toast-notifications";

import api from "../../../../services/api";
import Button from "components/Button";

const SchedulesTabComponent = ({
  merchantId,
  dispDo,
  setDispDo,
  dispSg,
  setDispSg,
  dispTe,
  setDispTe,
  dispQa,
  setDispQa,
  dispQi,
  setDispQi,
  dispSx,
  setDispSx,
  dispSb,
  setDispSb,
  autoAbrir,
  autoFechar,
  setAutoAbrir,
  setAutoFechar,
}) => {
  const { addToast } = useToasts();

  function efetivar() {
    var objData = [];

    objData[objData.length] = {
      abreviacao: "dom",
      checked: dispDo.checked,
      abertura: dispDo.initAt,
      fechamento: dispDo.endAt,
      abertura2: dispDo.initAt2,
      fechamento2: dispDo.endAt2,
    };
    objData[objData.length] = {
      abreviacao: "seg",
      checked: dispSg.checked,
      abertura: dispSg.initAt,
      fechamento: dispSg.endAt,
      abertura2: dispSg.initAt2,
      fechamento2: dispSg.endAt2,
    };
    objData[objData.length] = {
      abreviacao: "ter",
      checked: dispTe.checked,
      abertura: dispTe.initAt,
      fechamento: dispTe.endAt,
      abertura2: dispTe.initAt2,
      fechamento2: dispTe.endAt2,
    };
    objData[objData.length] = {
      abreviacao: "qua",
      checked: dispQa.checked,
      abertura: dispQa.initAt,
      fechamento: dispQa.endAt,
      abertura2: dispQa.initAt2,
      fechamento2: dispQa.endAt2,
    };
    objData[objData.length] = {
      abreviacao: "qui",
      checked: dispQi.checked,
      abertura: dispQi.initAt,
      fechamento: dispQi.endAt,
      abertura2: dispQi.initAt2,
      fechamento2: dispQi.endAt2,
    };
    objData[objData.length] = {
      abreviacao: "sex",
      checked: dispSx.checked,
      abertura: dispSx.initAt,
      fechamento: dispSx.endAt,
      abertura2: dispSx.initAt2,
      fechamento2: dispSx.endAt2,
    };
    objData[objData.length] = {
      abreviacao: "sab",
      checked: dispSb.checked,
      abertura: dispSb.initAt,
      fechamento: dispSb.endAt,
      abertura2: dispSb.initAt2,
      fechamento2: dispSb.endAt2,
    };

    var dataPost = {
      id_estabelecimento: merchantId,
      horarios: JSON.stringify(objData),
      auto_abrir: autoAbrir.checked,
      auto_fechar: autoFechar.checked,
    };
    api
      .post("estabelecimentoshorarios/alteraHorarios2", dataPost)
      .then((rps) => {
        if (rps.data.status) {
          addToast(rps.data.mensagem, {
            appearance: "success",
            autoDismiss: true,
            autoDismissTimeout: 2000,
          });
        } else {
          Swal.fire({
            title: "Atenção!",
            icon: "error",
            html: rps.data.mensagem,
            showCloseButton: true,
            showCancelButton: false,
          });
        }
      });
  }

  return (
    <div
      className="tab-pane fade"
      id="horarios"
      role="tabpanel"
      aria-labelledby="horarios"
    >
      <div className="row justify-content-md-center">
        <label className="col-md-auto col-form-label text-right h5">
          Abrir Automaticamente?
        </label>
        <div className="col-md-auto text-center">
          <span className="switch switch-outline switch-icon switch-success">
            <label>
              <input
                type="checkbox"
                checked={autoAbrir.checked}
                onChange={(e) => {
                  setAutoAbrir((prevState) => ({
                    ...prevState,
                    checked: e.target.checked,
                  }));
                }}
              />
              <span></span>
            </label>
          </span>
        </div>
      </div>
      <div className="row justify-content-md-center">
        <label className="col-md-auto col-form-label text-right h5">
          Fechar Automaticamente?
        </label>
        <div className="col-md-auto text-center">
          <span className="switch switch-outline switch-icon switch-success">
            <label>
              <input
                type="checkbox"
                checked={autoFechar.checked}
                onChange={(e) => {
                  setAutoFechar((prevState) => ({
                    ...prevState,
                    checked: e.target.checked,
                  }));
                }}
              />
              <span></span>
            </label>
          </span>
        </div>
      </div>
      <hr />
      <div className="row">
        <div className="col-md-12">
          <table className="table">
            <thead>
              <tr>
                <th className="text-center">Dias da Semana</th>
                <th className="text-center">Aberto</th>
                <th className="text-center">Abertura Primeiro Horário</th>
                <th className="text-center">Fechamento Primeiro Horário</th>
                <th className="text-center">Abertura Segundo Horário</th>
                <th className="text-center">Fechamento Segunddo Horário</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="text-center">Domingo</td>
                <td className="text-center">
                  <label className="checkbox checkbox-square checkbox-primary">
                    <input
                      type="checkbox"
                      checked={dispDo.checked}
                      onChange={(e) => {
                        setDispDo((prevState) => ({
                          ...prevState,
                          checked: e.target.checked,
                        }));
                      }}
                    />
                    <span />
                  </label>
                </td>
                <td className="text-center">
                  <InputMask
                    mask={"99:99"}
                    disabled={!dispDo.checked}
                    value={dispDo.initAt}
                    onChange={(e) => {
                      setDispDo((prevState) => ({
                        ...prevState,
                        initAt: e.target.value,
                      }));
                    }}
                    className="form-control form-small form-disponibilidade"
                    type="text"
                  />
                </td>
                <td className="text-center">
                  <InputMask
                    mask={"99:99"}
                    disabled={!dispDo.checked}
                    value={dispDo.endAt}
                    onChange={(e) => {
                      setDispDo((prevState) => ({
                        ...prevState,
                        endAt: e.target.value,
                      }));
                    }}
                    className="form-control form-small form-disponibilidade"
                    type="text"
                  />
                </td>
                <td className="text-center">
                  <InputMask
                    mask={"99:99"}
                    disabled={!dispDo.checked}
                    value={dispDo.initAt2}
                    onChange={(e) => {
                      setDispDo((prevState) => ({
                        ...prevState,
                        initAt2: e.target.value,
                      }));
                    }}
                    className="form-control form-small form-disponibilidade"
                    type="text"
                  />
                </td>
                <td className="text-center">
                  <InputMask
                    mask={"99:99"}
                    disabled={!dispDo.checked}
                    value={dispDo.endAt2}
                    onChange={(e) => {
                      setDispDo((prevState) => ({
                        ...prevState,
                        endAt2: e.target.value,
                      }));
                    }}
                    className="form-control form-small form-disponibilidade"
                    type="text"
                  />
                </td>
              </tr>
              <tr>
                <td className="text-center">Segunda-feira</td>
                <td className="text-center">
                  <label className="checkbox checkbox-square checkbox-primary">
                    <input
                      type="checkbox"
                      checked={dispSg.checked}
                      onChange={(e) => {
                        setDispSg((prevState) => ({
                          ...prevState,
                          checked: e.target.checked,
                        }));
                      }}
                    />
                    <span />
                  </label>
                </td>
                <td className="text-center">
                  <InputMask
                    mask={"99:99"}
                    disabled={!dispSg.checked}
                    value={dispSg.initAt}
                    onChange={(e) => {
                      setDispSg((prevState) => ({
                        ...prevState,
                        initAt: e.target.value,
                      }));
                    }}
                    className="form-control form-small form-disponibilidade"
                    type="text"
                  />
                </td>
                <td className="text-center">
                  <InputMask
                    mask={"99:99"}
                    disabled={!dispSg.checked}
                    value={dispSg.endAt}
                    onChange={(e) => {
                      setDispSg((prevState) => ({
                        ...prevState,
                        endAt: e.target.value,
                      }));
                    }}
                    className="form-control form-small form-disponibilidade"
                    type="text"
                  />
                </td>
                <td className="text-center">
                  <InputMask
                    mask={"99:99"}
                    disabled={!dispSg.checked}
                    value={dispSg.initAt2}
                    onChange={(e) => {
                      setDispSg((prevState) => ({
                        ...prevState,
                        initAt2: e.target.value,
                      }));
                    }}
                    className="form-control form-small form-disponibilidade"
                    type="text"
                  />
                </td>
                <td className="text-center">
                  <InputMask
                    mask={"99:99"}
                    disabled={!dispSg.checked}
                    value={dispSg.endAt2}
                    onChange={(e) => {
                      setDispSg((prevState) => ({
                        ...prevState,
                        endAt2: e.target.value,
                      }));
                    }}
                    className="form-control form-small form-disponibilidade"
                    type="text"
                  />
                </td>
              </tr>
              <tr>
                <td className="text-center">Terça-feira</td>
                <td className="text-center">
                  <label className="checkbox checkbox-square checkbox-primary">
                    <input
                      type="checkbox"
                      checked={dispTe.checked}
                      onChange={(e) => {
                        setDispTe((prevState) => ({
                          ...prevState,
                          checked: e.target.checked,
                        }));
                      }}
                    />
                    <span />
                  </label>
                </td>
                <td className="text-center">
                  <InputMask
                    mask={"99:99"}
                    disabled={!dispTe.checked}
                    value={dispTe.initAt}
                    onChange={(e) => {
                      setDispTe((prevState) => ({
                        ...prevState,
                        initAt: e.target.value,
                      }));
                    }}
                    className="form-control form-small form-disponibilidade"
                    type="text"
                  />
                </td>
                <td className="text-center">
                  <InputMask
                    mask={"99:99"}
                    disabled={!dispTe.checked}
                    value={dispTe.endAt}
                    onChange={(e) => {
                      setDispTe((prevState) => ({
                        ...prevState,
                        endAt: e.target.value,
                      }));
                    }}
                    className="form-control form-small form-disponibilidade"
                    type="text"
                  />
                </td>
                <td className="text-center">
                  <InputMask
                    mask={"99:99"}
                    disabled={!dispTe.checked}
                    value={dispTe.initAt2}
                    onChange={(e) => {
                      setDispTe((prevState) => ({
                        ...prevState,
                        initAt2: e.target.value,
                      }));
                    }}
                    className="form-control form-small form-disponibilidade"
                    type="text"
                  />
                </td>
                <td className="text-center">
                  <InputMask
                    mask={"99:99"}
                    disabled={!dispTe.checked}
                    value={dispTe.endAt2}
                    onChange={(e) => {
                      setDispTe((prevState) => ({
                        ...prevState,
                        endAt2: e.target.value,
                      }));
                    }}
                    className="form-control form-small form-disponibilidade"
                    type="text"
                  />
                </td>
              </tr>
              <tr>
                <td className="text-center">Quarta-feira</td>
                <td className="text-center">
                  <label className="checkbox checkbox-square checkbox-primary">
                    <input
                      type="checkbox"
                      checked={dispQa.checked}
                      onChange={(e) => {
                        setDispQa((prevState) => ({
                          ...prevState,
                          checked: e.target.checked,
                        }));
                      }}
                    />
                    <span />
                  </label>
                </td>
                <td className="text-center">
                  <InputMask
                    mask={"99:99"}
                    disabled={!dispQa.checked}
                    value={dispQa.initAt}
                    onChange={(e) => {
                      setDispQa((prevState) => ({
                        ...prevState,
                        initAt: e.target.value,
                      }));
                    }}
                    className="form-control form-small form-disponibilidade"
                    type="text"
                  />
                </td>
                <td className="text-center">
                  <InputMask
                    mask={"99:99"}
                    disabled={!dispQa.checked}
                    value={dispQa.endAt}
                    onChange={(e) => {
                      setDispQa((prevState) => ({
                        ...prevState,
                        endAt: e.target.value,
                      }));
                    }}
                    className="form-control form-small form-disponibilidade"
                    type="text"
                  />
                </td>
                <td className="text-center">
                  <InputMask
                    mask={"99:99"}
                    disabled={!dispQa.checked}
                    value={dispQa.initAt2}
                    onChange={(e) => {
                      setDispQa((prevState) => ({
                        ...prevState,
                        initAt2: e.target.value,
                      }));
                    }}
                    className="form-control form-small form-disponibilidade"
                    type="text"
                  />
                </td>
                <td className="text-center">
                  <InputMask
                    mask={"99:99"}
                    disabled={!dispQa.checked}
                    value={dispQa.endAt2}
                    onChange={(e) => {
                      setDispQa((prevState) => ({
                        ...prevState,
                        endAt2: e.target.value,
                      }));
                    }}
                    className="form-control form-small form-disponibilidade"
                    type="text"
                  />
                </td>
              </tr>
              <tr>
                <td className="text-center">Quinta-feira</td>
                <td className="text-center">
                  <label className="checkbox checkbox-square checkbox-primary">
                    <input
                      type="checkbox"
                      checked={dispQi.checked}
                      onChange={(e) => {
                        setDispQi((prevState) => ({
                          ...prevState,
                          checked: e.target.checked,
                        }));
                      }}
                    />
                    <span />
                  </label>
                </td>
                <td className="text-center">
                  <InputMask
                    mask={"99:99"}
                    disabled={!dispQi.checked}
                    value={dispQi.initAt}
                    onChange={(e) => {
                      setDispQi((prevState) => ({
                        ...prevState,
                        initAt: e.target.value,
                      }));
                    }}
                    className="form-control form-small form-disponibilidade"
                    type="text"
                  />
                </td>
                <td className="text-center">
                  <InputMask
                    mask={"99:99"}
                    disabled={!dispQi.checked}
                    value={dispQi.endAt}
                    onChange={(e) => {
                      setDispQi((prevState) => ({
                        ...prevState,
                        endAt: e.target.value,
                      }));
                    }}
                    className="form-control form-small form-disponibilidade"
                    type="text"
                  />
                </td>
                <td className="text-center">
                  <InputMask
                    mask={"99:99"}
                    disabled={!dispQi.checked}
                    value={dispQi.initAt2}
                    onChange={(e) => {
                      setDispQi((prevState) => ({
                        ...prevState,
                        initAt2: e.target.value,
                      }));
                    }}
                    className="form-control form-small form-disponibilidade"
                    type="text"
                  />
                </td>
                <td className="text-center">
                  <InputMask
                    mask={"99:99"}
                    disabled={!dispQi.checked}
                    value={dispQi.endAt2}
                    onChange={(e) => {
                      setDispQi((prevState) => ({
                        ...prevState,
                        endAt2: e.target.value,
                      }));
                    }}
                    className="form-control form-small form-disponibilidade"
                    type="text"
                  />
                </td>
              </tr>
              <tr>
                <td className="text-center">Sexta-feira</td>
                <td className="text-center">
                  <label className="checkbox checkbox-square checkbox-primary">
                    <input
                      type="checkbox"
                      checked={dispSx.checked}
                      onChange={(e) => {
                        setDispSx((prevState) => ({
                          ...prevState,
                          checked: e.target.checked,
                        }));
                      }}
                    />
                    <span />
                  </label>
                </td>
                <td className="text-center">
                  <InputMask
                    mask={"99:99"}
                    disabled={!dispSx.checked}
                    value={dispSx.initAt}
                    onChange={(e) => {
                      setDispSx((prevState) => ({
                        ...prevState,
                        initAt: e.target.value,
                      }));
                    }}
                    className="form-control form-small form-disponibilidade"
                    type="text"
                  />
                </td>
                <td className="text-center">
                  <InputMask
                    mask={"99:99"}
                    disabled={!dispSx.checked}
                    value={dispSx.endAt}
                    onChange={(e) => {
                      setDispSx((prevState) => ({
                        ...prevState,
                        endAt: e.target.value,
                      }));
                    }}
                    className="form-control form-small form-disponibilidade"
                    type="text"
                  />
                </td>
                <td className="text-center">
                  <InputMask
                    mask={"99:99"}
                    disabled={!dispSx.checked}
                    value={dispSx.initAt2}
                    onChange={(e) => {
                      setDispSx((prevState) => ({
                        ...prevState,
                        initAt2: e.target.value,
                      }));
                    }}
                    className="form-control form-small form-disponibilidade"
                    type="text"
                  />
                </td>
                <td className="text-center">
                  <InputMask
                    mask={"99:99"}
                    disabled={!dispSx.checked}
                    value={dispSx.endAt2}
                    onChange={(e) => {
                      setDispSx((prevState) => ({
                        ...prevState,
                        endAt2: e.target.value,
                      }));
                    }}
                    className="form-control form-small form-disponibilidade"
                    type="text"
                  />
                </td>
              </tr>
              <tr>
                <td className="text-center">Sábado</td>
                <td className="text-center">
                  <label className="checkbox checkbox-square checkbox-primary">
                    <input
                      type="checkbox"
                      checked={dispSb.checked}
                      onChange={(e) => {
                        setDispSb((prevState) => ({
                          ...prevState,
                          checked: e.target.checked,
                        }));
                      }}
                    />
                    <span />
                  </label>
                </td>
                <td className="text-center">
                  <InputMask
                    mask={"99:99"}
                    disabled={!dispSb.checked}
                    value={dispSb.initAt}
                    onChange={(e) => {
                      setDispSb((prevState) => ({
                        ...prevState,
                        initAt: e.target.value,
                      }));
                    }}
                    className="form-control form-small form-disponibilidade"
                    type="text"
                  />
                </td>
                <td className="text-center">
                  <InputMask
                    mask={"99:99"}
                    disabled={!dispSb.checked}
                    value={dispSb.endAt}
                    onChange={(e) => {
                      setDispSb((prevState) => ({
                        ...prevState,
                        endAt: e.target.value,
                      }));
                    }}
                    className="form-control form-small form-disponibilidade"
                    type="text"
                  />
                </td>
                <td className="text-center">
                  <InputMask
                    mask={"99:99"}
                    disabled={!dispSb.checked}
                    value={dispSb.initAt2}
                    onChange={(e) => {
                      setDispSb((prevState) => ({
                        ...prevState,
                        initAt2: e.target.value,
                      }));
                    }}
                    className="form-control form-small form-disponibilidade"
                    type="text"
                  />
                </td>
                <td className="text-center">
                  <InputMask
                    mask={"99:99"}
                    disabled={!dispSb.checked}
                    value={dispSb.endAt2}
                    onChange={(e) => {
                      setDispSb((prevState) => ({
                        ...prevState,
                        endAt2: e.target.value,
                      }));
                    }}
                    className="form-control form-small form-disponibilidade"
                    type="text"
                  />
                </td>
              </tr>
              <tr>
                <td colSpan="6" className="p-0">
                  <div className="d-flex align-items-end flex-column mt-10">
                    <Button size="medium" onClick={efetivar} type="submit">
                      <span>Salvar Horários</span>
                    </Button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export const SchedulesTab = memo(SchedulesTabComponent);

import React from 'react';
import { Link } from 'react-router-dom'

export function MenuSection({ title }) {
    return (
        <li className="menu-section">
            <h4 className="menu-text">{title}</h4>
            <i className="menu-icon ki ki-bold-more-hor icon-md" />
        </li>
    );
}


export function Menu({ children, Icon, onClick, className, title, link, permission = true }) {
    if (!permission) {
        return null;
    }

    if (link) {
        return (
            <li  className="menu-item">
                <Link to={link} className={`menu-link ${className}`} onClick={onClick}>
                <Icon />
                <span className="menu-text">{title}</span>
                </Link>
            </li>
        );
    }

  return (
    <li className={`menu-item menu-item-submenu ${className}`}>
        <span onClick={onClick} className="menu-link menu-toggle">
        <Icon />
            <span className="menu-text">{title}</span>
            <i className="menu-arrow" />
        </span>
        <div className="menu-submenu">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
                {children}
            </ul>
        </div>
    </li>
  );
}
import { addressService } from "services";

export const useFetchCities = () => {
  const formatCity = (city) => ({
    label: `${city?.nome_cidade} - ${city?.estado}`,
    value: city?.id_cidade,
  });

  const fetchCities = async () => {
    const response = await addressService.getCitiesByAgentId();

    return response.cidades?.map(formatCity);
  };

  return { fetchCities, formatCity };
};

import React, { useState, useEffect } from 'react';
import Api from '../../../services/api';
import { withRouter } from "react-router";

import Swal from "sweetalert2";
import { useToasts } from 'react-toast-notifications';
import { Modal } from "react-bootstrap";

import Select from 'react-select';
import LoadingOverlay from 'react-loading-overlay';

import ReactTooltip from 'react-tooltip';

var fileDownload = require('js-file-download');

const Artes = () => {

  const { addToast } = useToasts();
  const [formArtes, setFormArtes] = useState({});
  const [estabelecimentoSelect, setEstabelecimentoSelect] = useState([]);
  const [cidadeSelect, setCidadeSelect] = useState([]);
  const [categoriaSelect, setCategoriaSelect] = useState([]);
  const [modeloSelect, setModeloSelect] = useState([]);
  const [caProcessa, setCaProcessa] = useState(false);
  const [modal, setModal] = useState(false);
  const [st, setSt] = useState();
  const [faltam, setFaltam] = useState();
  const [trabalhando, setTrabalhando] = useState();
  const [escravos, setEscravos] = useState();
  const [pendentes, setPendentes] = useState([]);

  const [inputs, setInputs] = useState(1);

  const styleHeight = "100px";
  const customStyles = {
    valueContainer: base => ({
      ...base,
      minHeight: styleHeight
    })
  };

  useEffect(() => {
    getStatus();
  }, []);

  function getStatus() {
    setCaProcessa(true);
    Api.get("https://artesdown2.aiboo.com.br:2083/v1/statusEnvio").then(rps => {

      if (rps.data.status === true && rps.data.faltam > 0)
        setSt('Enviando')
      else
        setSt('Aguardando');

      setFaltam(rps.data.faltam);
      setTrabalhando(rps.data.actWorkers);
      setEscravos(rps.data.maxWorkers);
      setPendentes(rps.data.pendentes);

      setCaProcessa(false);
    })
  }

  function getListar() {
    Api.get("https://artesdown2.aiboo.com.br:2083/v1/artes").then(rps => {
      var modelo = rps.data.artes;
      var nModelo = [];
      nModelo[nModelo.length] = { value: '', label: 'Selecione...' };
      modelo.forEach(e => {
        nModelo[nModelo.length] = { value: e.id, label: <span><img alt="titulo" src={e.imagem} width="100px" style={{ height: "100px" }} /> {e.titulo}</span> };
      })
      setModeloSelect(nModelo);
    })
  }

  function getEstabelecimentoSelect() {
    Api.get("estabelecimentos/listaNomeEstabelecimento").then(rps => {
      var rep = rps.data.obj;
      var nRep = [];
      nRep[nRep.length] = { value: 'T', label: 'Todos...' };
      rep.forEach(e => {
        nRep[nRep.length] = { value: e.id_estabelecimento, label: e.nome };
      })
      setEstabelecimentoSelect(nRep);
    })
  }

  function getCidadeSelect() {
    Api.get("cidades/listar").then(rps => {
      var cid = rps.data.cidades;
      var nCid = [];
      nCid[nCid.length] = { value: 'T', label: 'Todas' };
      cid.forEach(e => {
        nCid[nCid.length] = { value: e.id_cidade, label: e.nome_cidade + ' - ' + e.estado };
      })
      setCidadeSelect(nCid);
    })
  }

  function getCategoriaSelect() {
    Api.get("estabelecimentos/listacategoria").then(rps => {
      var cat = rps.data.obj;
      var nCat = [];
      nCat[nCat.length] = { value: 'T', label: 'Todos...' };
      cat.forEach(e => {
        nCat[nCat.length] = { value: e.id_grupo, label: e.nome };
      })
      setCategoriaSelect(nCat);
    })
  }

  function adicionarAviso() {
    setFormArtes({});
    getListar();
    getCidadeSelect();
    getEstabelecimentoSelect();
    getCategoriaSelect();
    setModal(true);
  }

  function fecharModal() {
    setModal(false);
  }

  function salvar() {
    setCaProcessa(true);
    Api.post('artes/disparar', formArtes).then(rps => {
      if (rps.data.status === true) {
        addToast(rps.data.mensagem, {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 2000
        });
        setModal(false);
        setFormArtes({});
        getStatus();
      } else {
        Swal.fire({
          title: "Erro!",
          icon: "error",
          html: rps.data.erros,
          showCloseButton: true,
          showCancelButton: false,
        });
      }
      setCaProcessa(false);
    });
  }

  function gerarCsv(){
    setCaProcessa(true);
    Api.post('artes/gerarCsv', formArtes).then(rps => {
      if (rps.data.false === true) {
        Swal.fire({
          title: "Erro!",
          icon: "error",
          html: rps.data.erros,
          showCloseButton: true,
          showCancelButton: false,
        });
      } else {
        fileDownload(rps.data, 'contatos.csv');
      }
      setCaProcessa(false);
    });
  }



  const renderMessages = () => {
    const row = [];
    for (var i = 1; i <= inputs; i++) {
      row.push(<>
        <textarea className='form-control'
          data-chave={i}
          onChange={(e) => {
            var a = "mensagem[" + e.target.dataset.chave + "]";
            setFormArtes({ ...formArtes, [a]: e.target.value })
          }}>
        </textarea>
      </>)
    }
    return row;
  };



  return (
    <>
      <div>

        <div className="subheader espaco-header-pedidos subheader-transparent" id="kt_subheader">
          <div className="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
            {/*begin::Details*/}
            <div className="d-flex align-items-center flex-wrap mr-2">
              {/*begin::Title*/}
              <h5 className="text-dark font-weight-bold mt-2 mb-2 mr-5">Artes</h5>
              {/*end::Title*/}
              {/*begin::Separator*/}
              <div className="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200" />
              {/*end::Separator*/}
            </div>
            {/*end::Details*/}
            {/*begin::Toolbar*/}
            <div className="d-flex align-items-center">
              <button className="btn btn-lg btn-success mt-3 mb-3" onClick={e => { adicionarAviso() }}><i className="fas fa-plus" />Disparar</button>
            </div>
            {/*end::Toolbar*/}
          </div>
        </div>
        {/*begin::Entry*/}
        <div className="d-flex flex-column-fluid">
          {/*begin::Container*/}
          <div className="container">
            <LoadingOverlay
              active={caProcessa}
              spinner
              text='Carregando...'
            >
              <div className="row mb-10">
                <div className="col-lg-3 mb-10">
                  <div className="card card-custom card-body">
                    <span className="font-weight-bolder text-dark-75 font-size-h5 mb-2 text-hover-primary">Status</span>
                    <div className="row">
                      <div className="col-sm-8">
                        <span className="text-dark-75 font-weight-bolder font-size-h2 mr-2">{st}</span>
                      </div>
                      <div className="col-sm-4">
                        <i className="icon-xl fas fa-spinner text-primary float-right"></i>
                      </div>
                    </div>
                    {/*<span className="d-block text-dark-50 mt-2 font-size-sm">R$ {pedidosSomaEfetivados}</span>*/}
                  </div>
                </div>

                <div className="col-lg-3 mb-10">
                  <div className="card card-custom card-body">
                    <span className="font-weight-bolder text-dark-75 font-size-h5 mb-2 text-hover-primary">Envios Pendentes</span>
                    <div className="row">
                      <div className="col-sm-8">
                        <span className="text-dark-75 font-weight-bolder font-size-h2 mr-2">{faltam}</span>
                      </div>
                      <div className="col-sm-4">
                        <i className="icon-xl fas fa-paper-plane text-primary float-right"></i>
                      </div>
                    </div>
                    {/*<span className="d-block text-dark-50 mt-2 font-size-sm">R$ {pedidosSomaEfetivados}</span>*/}
                  </div>
                </div>

                <div className="col-lg-3 mb-10">
                  <div className="card card-custom card-body">
                    <span className="font-weight-bolder text-dark-75 font-size-h5 mb-2 text-hover-primary">Trabalhando</span>
                    <div className="row">
                      <div className="col-sm-8">
                        <span className="text-dark-75 font-weight-bolder font-size-h2 mr-2">{trabalhando}</span>
                      </div>
                      <div className="col-sm-4">
                        <i className="icon-xl fas fa-hammer text-primary float-right"></i>
                      </div>
                    </div>
                    {/*<span className="d-block text-dark-50 mt-2 font-size-sm">R$ {pedidosSomaEfetivados}</span>*/}
                  </div>
                </div>

                <div className="col-lg-3 mb-10">
                  <div className="card card-custom card-body">
                    <span className="font-weight-bolder text-dark-75 font-size-h5 mb-2 text-hover-primary">Trabalhadores Disponíveis</span>
                    <div className="row">
                      <div className="col-sm-8">
                        <span className="text-dark-75 font-weight-bolder font-size-h2 mr-2">{escravos}</span>
                      </div>
                      <div className="col-sm-4">
                        <i className="icon-xl fas fa-pray text-primary float-right"></i>
                      </div>
                    </div>
                    {/*<span className="d-block text-dark-50 mt-2 font-size-sm">R$ {pedidosSomaEfetivados}</span>*/}
                  </div>
                </div>
              </div>

              <table className="table table-striped">
                <thead className="bg-light-primary">
                  <tr>
                    <th className="text-primary text-center" width="15">WhatsApp</th>
                    <th className="text-primary text-center" width="15">Estabelecimento</th>
                    <th className="text-primary">Mensagem</th>
                    <th className="text-primary text-center" width="15">Modelo</th>
                  </tr>
                </thead>
                <tbody>
                  {pendentes !== null &&
                    pendentes.map((item) => {
                      return (
                        <>
                          <tr>
                            <td className="text-center">{item.receiver}</td>
                            <td className="text-center">{item.estabelecimento_id}</td>
                            <td>{item.mensagem}</td>
                            <td className="text-center">{item.modelo}</td>
                          </tr>
                        </>
                      )
                    }
                    )}
                </tbody>
              </table>
            </LoadingOverlay>




          </div>
        </div>
      </div>

      <Modal size={'xl'} show={modal} onHide={() => fecharModal()}>
        <Modal.Header>
          <Modal.Title>Artes</Modal.Title>
          <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={e => { fecharModal() }}>
            <i aria-hidden="true" className="ki ki-close" />
          </button>
        </Modal.Header>
        <div className="row mt-5 ml-5 mr-5">
          <div className="form-group col-md-12">
            <label>Modelo</label>
            <Select
              options={modeloSelect}
              placeholder="Selecione..."
              defaultValue={modeloSelect.find(
                x => x.value === formArtes?.id_modelo
              )}
              onChange={(e) => { setFormArtes({ ...formArtes, id_modelo: e.value }) }}
              styles={customStyles}
            />
          </div>
        </div>

        <div className="row ml-5 mr-5">
          <div className="form-group col-md-12">
            <label>Estabelecimento</label>
            <Select
              options={estabelecimentoSelect}
              placeholder="Selecione..."
              isMulti
              defaultValue={formArtes.id_estabelecimento?.map((item) => {
                return (
                  estabelecimentoSelect.find(
                    x => x.value === item
                  )
                )
              }
              )}
              onChange={(e) => { setFormArtes({ ...formArtes, id_estabelecimento: e }) }}
            />
          </div>
        </div>

        <div className="row ml-5 mr-5">
          <div className="form-group col-md-6">
            <label>Cidade</label>
            <Select
              options={cidadeSelect}
              placeholder="Selecione..."
              isMulti
              defaultValue={formArtes.id_cidade?.map((item) => {
                return (
                  cidadeSelect.find(
                    x => x.value === item
                  )
                )
              }
              )}
              onChange={(e) => { setFormArtes({ ...formArtes, id_cidade: e }) }}
            />
          </div>

          <div className="form-group col-md-6">
            <label>Categoria</label>
            <Select
              options={categoriaSelect}
              placeholder="Selecione..."
              isMulti
              defaultValue={formArtes.id_categoria?.map((item) => {
                return (
                  cidadeSelect.find(
                    x => x.value === item
                  )
                )
              }
              )}
              onChange={(e) => { setFormArtes({ ...formArtes, id_categoria: e }) }}
            />
          </div>

          <div className="form-group col-md-12">
            <ReactTooltip />
            <label>Mensagem</label>
            <i className="fa fa-fw fa-question-circle ml-2" data-tip="Para inserir o nome do estabelecimento no corpo da mensagem, utilize <<nome>>" />
            <i className="fa fa-fw fa-plus ml-2" onClick={() => { setInputs(inputs + 1); }} />
            <i className="fa fa-fw fa-minus ml-2" onClick={() => { setInputs(inputs - 1); }} />

            {
              renderMessages()
            }





          </div>

        </div>

        <Modal.Footer>
          <button type="button" onClick={e => { fecharModal(); }} className="btn btn-light-primary font-weight-bold" data-dismiss="modal"><i className="fas fa-door-open" />Fechar</button>
          
          <button type="button" onClick={e => { gerarCsv(); }} className="btn btn-warning font-weight-bold"><i className="fas fa-save" />gerarCsv</button>

          <button type="button" onClick={e => { salvar(); }} className="btn btn-success font-weight-bold"><i className="fas fa-save" />Salvar</button>
        </Modal.Footer>
      </Modal>
    </>
  );

}



export default withRouter(Artes);                  
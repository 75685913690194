import React, { useState, useEffect } from 'react';
import Api from '../../services/api';
import { withRouter } from "react-router";
import { Modal } from "react-bootstrap";
import LoadingOverlay from 'react-loading-overlay';
import { Link } from 'react-router-dom';
import { Doughnut, Line } from 'react-chartjs-2';
import Swal from "sweetalert2";

import Cidade from './cidade';
import Restaurante from './restaurante';

import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import pt from "date-fns/locale/pt-BR"; 
import Representante from './representante';

registerLocale("pt-BR", pt);

  const Dashbord = () => {
  //Exibir
  const [ exibir , setExibir ] = useState({});
  //Filtro
  var d = new Date();
  d.setDate(d.getDate() - 60);
  //Painel Sem Movimentação  
  const [ estSemCompras, setEstSemCompras ] = useState([]);
  const [ casemMovimentacao, setCaSemMovimentacao ] = useState(false);
  const [ modal, setModal ] = useState(false);
  //Painel Cancelados
  const [ cancelamento, setCancelamento ] = useState([]);
  const [ caCancelamento, setCaCancelamento ] = useState(false);
  const [ modalCancelamento, setModalCancelamento ] = useState(false);
  //Painel Estabelecimento com mais pedidos
  //Painel Cidades com mais pedidos
  const [ , setMaisCidades ] = useState([]);
  const [ caMaisCidades, setCaMaisCidades ] = useState(false);
  const [ cidadeLabel , setCidadeLabel ] = useState([]);
  const [ cidadeValor , setCidadeValor ] = useState([]);
  //Painel Estabelecimentos Cadastrados
  const [ estabelecimentosCadastrados , setEstabelecimentosCadastrados ] = useState([]);
  const [ caEstabelecimentosCadastrados, setCaEstabelecimentosCadastrados ] = useState(false);
  //Painel Estabelecimentos Ativos
  const [ estabelecimentosAtivos , setEstabelecimentosAtivos ] = useState([]);
  const [ caEstabelecimentosAtivos, setCaEstabelecimentosAtivos ] = useState(false);
  //Painel Total Pedidos
  const [ totalPedidos , setTotalPedidos ] = useState([]);
  const [ caTotalPedidos, setCaTotalPedidos ] = useState(false);
  //Painel Pedidos Cancelados
  const [ pedidosCancelados , setPedidosCancelados ] = useState([]);
  const [ caPedidosCanceados, setCaPedidosCancelados ] = useState(false);
  //Painel Retenção 
  const [ retencao , setRetencao ] = useState([]);
  const [ caRetencao, setCaRetencao ] = useState(false);
  //Painel Churn 
  const [ churn , setChurn ] = useState([]);
  const [ caChurn, setCaChurn ] = useState(false);
  //Painel Taxa Geral de Conversão 
  const [ taxaGeralConversao , setTaxaGeralConversao ] = useState([]);
  const [ caTaxaGeralConversao, setCaTaxaGeralConversao ] = useState(false);
  //Painel Taxa de Conversão 30 
  const [ taxaConversao30 , setTaxaConversao30 ] = useState([]);
  const [ caTaxaConversao30, setCaTaxaConversao30 ] = useState(false);
  //Painel Usuarios Cadastrados 
  const [ usuariosCadastrados , setUsuariosCadastrados ] = useState([]);
  const [ caUsuariosCadastrados, setCaUsuariosCadastrados ] = useState(false);
  //Painel Usuarios Cadastrados com Pedidos
  const [ usuariosCadastradosCPedidos , setUsuariosCadastradosCPedidos ] = useState([]);
  const [ caUsuariosCadastradosCPedidos, setCaUsuariosCadastradosCPedidos ] = useState(false);
  //Painel Usuarios Cadastrados sem Pedidos
  const [ usuariosCadastradosSPedidos , setUsuariosCadastradosSPedidos ] = useState([]);
  const [ caUsuariosCadastradosSPedidos, setCaUsuariosCadastradosSPedidos ] = useState(false);
  //Painel Usuarios Cadastrados Pedidos Unicos
  const [ usuariosCadastradosPedidoUnico , setUsuariosCadastradosPedidoUnico ] = useState([]);
  const [ caUsuariosCadastradosPedidoUnico, setCaUsuariosCadastradosPedidoUnico ] = useState(false);
  //Painel Usuarios Inativos 
  const [ usuariosInativos , setUsuariosInativos ] = useState([]);
  const [ caUsuariosInativos, setCaUsuariosInativos ] = useState(false);
  //Painel Usuarios Inativos 
  const [ usuariosCadastradosRiscoChurn , setUsuariosCadastradosRiscoChurn ] = useState([]);
  const [ caUsuariosCadastradosRiscoChurn, setCaUsuariosCadastradosRiscoChurn ] = useState(false);
  //Painel Evolutivo Pedidos
  const [ evolutivoPedidosEntreguesTotal , setEvolutivoPedidosEntreguesTotal ] = useState([]);
  const [ evolutivoPedidosEntreguesDia , setEvolutivoPedidosEntreguesDia ] = useState([]);
  const [ evolutivoPedidosCanceladosTotal , setEvolutivoPedidosCanceladosTotal ] = useState([]);
  const [  , setEvolutivoPedidosCanceladosDia ] = useState([]);
  const [ caEvolutivoPedidos, setCaEvolutivoPedidos ] = useState(false);
  //Painel Valor Transacionado
  const [ valorTransacionadosEntreguesTotal , setValorTransacionadosEntreguesTotal ] = useState([]);
  const [ valorTransacionadosEntreguesDia , setValorTransacionadosEntreguesDia ] = useState([]);
  const [ valorTransacionadosCanceladosTotal , setValorTransacionadosCanceladosTotal ] = useState([]);
  const [  , setValorTransacionadosCanceladosDia ] = useState([]);
  const [ caValorTransacionados, setCaValorTransacionados ] = useState(false);
  //Painel Evolutivo de Usuários cadastrados (12 meses)
  const [ evolutivoUsuarioCadastrado , setEvolutivoUsuarioCadastrado ] = useState([]);
  const [ evolutivoUsuarioCadastradoMes , setEvolutivoUsuarioCadastradoMes ] = useState([]);
  const [ caEvolutivoUsuarioCadastro, setCaEvolutivoUsuarioCadastrado ] = useState(false);
  //Painel Evolutivo de Usuários cadastrados (12 meses)
  const [ evolutivoPedUsuarioCadastrado , setEvolutivoPedUsuarioCadastrado ] = useState([]);
  const [ evolutivoPedUsuarioCadastradoMes , setEvolutivoPedUsuarioCadastradoMes ] = useState([]);
  const [ caEvolutivoPedUsuarioCadastro, setCaEvolutivoPedUsuarioCadastrado ] = useState(false);
  //Painel Evolutivo de Usuários Sem Pedido cadastrados (12 meses)
  const [ evolutivoSPedUsuarioCadastrado , setEvolutivoSPedUsuarioCadastrado ] = useState([]);
  const [ evolutivoSPedUsuarioCadastradoMes , setEvolutivoSPedUsuarioCadastradoMes ] = useState([]);
  const [ caEvolutivoSPedUsuarioCadastro, setCaEvolutivoSPedUsuarioCadastrado ] = useState(false);
  //Painel Ticket Médio - Mês atual
  const [ ticketMedio , setTicketMedio ] = useState([]);
  const [ caTicketMedio, setCaTicketMedio ] = useState(false);
  //Painel Evolutivo Ticket Médio  (12 meses)
  const [ evolutivoTicketMedio , setEvolutivoTicketMedio ] = useState([]);
  const [ evolutivoTicketMedioMes , setEvolutivoTicketMedioMes ] = useState([]);
  const [ caEvolutivoTicketMedio, setCaEvolutivoTicketMedio ] = useState(false);
  //Painel Evolutivo Conversao
  const [ evolutivoConversao , setEvolutivoConversao ] = useState([]);
  const [ evolutivoConversaoMes , setEvolutivoConversaoMes ] = useState([]);
  const [ caEvolutivoConversao, setCaEvolutivoConversao ] = useState(false);
  //Painel Frequência de pedidos no primeiro mês
  // const [ frequencia , setFrequencia ] = useState([]);
  // const [ caFrequencia, setCaFrequencia ] = useState(false);
  //Painel Frequência de pedidos no primeiro mês (por mês)
  // const [ frequenciaPedido , setFrequenciaPedido ] = useState([]);
  // const [ frequenciaPedidoMes , setFrequenciaPedidoMes ] = useState([]);
  // const [ caFrequenciaPedido, setCaFrequenciaPedido ] = useState(false);
  //Painel Evolutivo de Retenção
  // const [ evolutivoRetencao , setEvolutivoRetencao ] = useState([]);
  // const [ evolutivoRetencaoMes , setEvolutivoRetencaoMes ] = useState([]);
  // const [ caEvolutivoRetencao, setCaEvolutivoRetencao ] = useState(false);

  useEffect(() => {
    //filtrar();
  }, []);

  function filtrar() {
    //setCaProcessa(true); 
      Api.get('relatorios/pesquisar').then((rps) => {

        if(rps.data.status==false){
          Swal.fire({
            title: "Atenção!",
            icon: "info",
            html: rps.data.erro,
            showCloseButton: true,
            showCancelButton: false,
          });
        } else {
          if (exibir.estabelecimentosSemMovimentacao === true) getSemCompras();
          if (exibir.taxaCancelamento === true) getCancelamento();
          if (exibir.retencao === true) getRetencao();
          if (exibir.churn === true) getChurn();
          if (exibir.taxaGeralConversao === true) getTaxaGeralConversao();
          if (exibir.taxaConversao30 === true) getTaxaConversao30();
          if (exibir.usuariosCadastrados === true) getUsuariosCadastrados();
          if (exibir.usuariosCadastradosCPedidos === true) getUsuariosCadastradosCPedidos();
          if (exibir.usuariosCadastradosSPedidos === true) getUsuariosCadastradosSPedidos();
          if (exibir.usuariosCadastradosPedidoUnico === true) getUsuariosCadastradosPedidoUnico();
          if (exibir.usuariosInativos === true) getUsuariosInativos();
          if (exibir.usuariosCadastradosRiscoChurn === true) getUsuariosCadastradosRiscoChurn();
          if (exibir.melhoresCidades === true) getMelhoresCidades();
          if (exibir.estabelecimentosAtivos === true) getEstabelecimentosAtivos();
          if (exibir.totalPedidos === true) getTotalPedidos();
          if (exibir.pedidosCancelados === true) getPedidosCancelados();
          if (exibir.estabelecimentosCadastrados === true) getEstabelecimentosCadastrados();
          if (exibir.evolutivoPedidos === true) getEvolutivoPedidos();
          if (exibir.valorTransacionados === true) getValorTransacionado();
          if (exibir.evolutivoUsuarioCadastrado === true) getEvolutivoUsuarioCadastrado();
          if (exibir.evolutivoUsuarioCadastradoCPedido === true) getEvolutivoPedUsuarioCadastrado();
          if (exibir.evolutivoUsuarioCadastradoSPedido === true) getEvolutivoSPedUsuarioCadastrado();
          if (exibir.ticketMedio === true) getTicketMedio();
          if (exibir.evolutivoTicketMedio === true)  getEvolutivoTicketMedio();
          if (exibir.evolutivoConversao === true) getEvolutivoConversao();
          //getFrequencia();  Estava muito pesado o carregamento, falei com o Daniel para retirar em 20/04.
          //getFrequenciaPedido();
          //getEvolutivoRetencao();
        }
      //setCaProcessa(false); 
    });
  }

  function redirecionaEst($id) {  
    sessionStorage.setItem('estabelecimento', $id);
    sessionStorage.setItem('redireciona', 'painel');
  }

  function getSemCompras(){
    setCaSemMovimentacao(true); 
    Api.get("painel/semCompras").then(rps => {
      setEstSemCompras(rps.data.obj);
     
      setCaSemMovimentacao(false);
    })
  }

  function verTodosSemMovimentacao() {
    setModal(true);
  }

  function fecharModal(){
    setModal(false);
  }

  function getCancelamento(){
    setCaCancelamento(true); 
    Api.get("painel/cancelamento").then(rps => {
      setCancelamento(rps.data.obj);
     
      setCaCancelamento(false);
    })
  }

  function verTodosCancelamentos() {
    setModalCancelamento(true);
  }

  function fecharModalCancelamento(){
    setModalCancelamento(false);
  }

  function getMelhoresCidades(){
    setCaMaisCidades(true); 
    Api.get("painel/melhoresCidades").then(rps => {

      //***Inicio do valor Grafico Mais Cidades
      var formataCidadeTexto = [];
      var formataCidadeValor = [];

      setMaisCidades(rps.data.obj);

      rps.data.obj.forEach( (item, i) => {
        if (i <= 10) {
          formataCidadeTexto.push(item.Cidade);
          formataCidadeValor.push(item.total);
        }
      })

      setCidadeLabel(formataCidadeTexto);
      setCidadeValor(formataCidadeValor);
     
      setCaMaisCidades(false);
    })
  }

  //Grafico
  const data = {
    labels: cidadeLabel,
    datasets: [{
      data: cidadeValor,
      backgroundColor: [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)',
        'rgba(220, 20, 60, 1)',
        'rgba(127, 255, 0, 1)',
        'rgba(189, 183, 107, 1)',
        'rgba(255, 215, 0, 1)',
        'rgba(255, 255, 0, 1)',
        'rgba(128, 0, 128, 1)',
        'rgba(175, 238, 238, 1)',
        'rgba(0, 0, 128, 1)',
        'rgba(178, 34, 34, 1)',
      ],
      hoverOffset: 4
    }]
  };

  const options = {
    legend: {
      display: true,
      position: "left"
    }
  };

  function getRetencao(){
    setCaRetencao(true); 
    Api.get("painel/retencao").then(rps => {
      setRetencao(rps.data.obj.retention_percent);
     
      setCaRetencao(false);
    })
  }

  function getChurn(){
    setCaChurn(true); 
    Api.get("painel/churn").then(rps => {
      setChurn(rps.data.obj.churn);
     
      setCaChurn(false);
    })
  }

  function getTaxaGeralConversao(){
    setCaTaxaGeralConversao(true); 
    Api.get("painel/taxaGeralConversao").then(rps => {
      setTaxaGeralConversao(rps.data.obj.convertion);
     
      setCaTaxaGeralConversao(false);
    })
  }

  function getTaxaConversao30(){
    setCaTaxaConversao30(true); 
    Api.get("painel/taxaConversao30").then(rps => {
      setTaxaConversao30(rps.data.obj.convertion);
     
      setCaTaxaConversao30(false);
    })
  }

  function getUsuariosCadastrados(){
    setCaUsuariosCadastrados(true); 
    Api.get("painel/usuariosCadastrados").then(rps => {
      setUsuariosCadastrados(rps.data.obj.qtde);
     
      setCaUsuariosCadastrados(false);
    })
  }

  function getUsuariosCadastradosCPedidos(){
    setCaUsuariosCadastradosCPedidos(true); 
    Api.get("painel/usuariosCadastradosCPedidos").then(rps => {
      setUsuariosCadastradosCPedidos(rps.data.obj.qtde);
     
      setCaUsuariosCadastradosCPedidos(false);
    })
  }

  function getUsuariosCadastradosSPedidos(){
    setCaUsuariosCadastradosSPedidos(true); 
    Api.get("painel/usuariosCadastradosSPedidos").then(rps => {
      setUsuariosCadastradosSPedidos(rps.data.obj.qtde);
     
      setCaUsuariosCadastradosSPedidos(false);
    })
  }

  function getUsuariosCadastradosPedidoUnico(){
    setCaUsuariosCadastradosPedidoUnico(true); 
    Api.get("painel/usuariosCadastradosPedidoUnico").then(rps => {
      setUsuariosCadastradosPedidoUnico(rps.data.obj.qtde);
     
      setCaUsuariosCadastradosPedidoUnico(false);
    })
  }

  function getUsuariosInativos(){
    setCaUsuariosInativos(true); 
    Api.get("painel/usuariosInativos").then(rps => {
      setUsuariosInativos(rps.data.obj.qtde);
     
      setCaUsuariosInativos(false);
    })
  }

  function getUsuariosCadastradosRiscoChurn(){
    setCaUsuariosCadastradosRiscoChurn(true); 
    Api.get("painel/usuariosCadastradosRiscoChurn").then(rps => {
      setUsuariosCadastradosRiscoChurn(rps.data.obj.qtde);
     
      setCaUsuariosCadastradosRiscoChurn(false);
    })
  }

  function getEstabelecimentosCadastrados(){
    setCaEstabelecimentosCadastrados(true); 
    Api.get("painel/estabelecimentosCadastrados").then(rps => {
      setEstabelecimentosCadastrados(rps.data.obj.qtde);
     
      setCaEstabelecimentosCadastrados(false);
    })
  }

  function getEstabelecimentosAtivos(){
    setCaEstabelecimentosAtivos(true); 
    Api.get("painel/estabelecimentosAtivos").then(rps => {
      setEstabelecimentosAtivos(rps.data.obj.qtde);
     
      setCaEstabelecimentosAtivos(false);
    })
  }

  function getTotalPedidos(){
    setCaTotalPedidos(true); 
    Api.get("painel/totalPedidos").then(rps => {
      setTotalPedidos(rps.data.obj.qtde);
     
      setCaTotalPedidos(false);
    })
  }

  function getPedidosCancelados(){
    setCaPedidosCancelados(true); 
    Api.get("painel/pedidosCancelados").then(rps => {
      setPedidosCancelados(rps.data.obj.qtde);
     
      setCaPedidosCancelados(false);
    })
  }

  function getEvolutivoPedidos(){
    setCaEvolutivoPedidos(true); 
    Api.get("painel/evolutivoPedidos").then(rps => {

      geraGrafico(rps.data.obj);
     
      setCaEvolutivoPedidos(false);
    })
  }

  function geraGrafico(dados){
    //***Inicio do Grafico Vendas por dia

    var formataTotalCancelado = [];
    var formataDiaCancelado = [];
    var formataTotalEntregue = [];
    var formataDiaEntregue = [];

    dados.map( item => {
      if (item.Status == "Pedido Cancelado") {
        formataTotalCancelado.push(item.total);
        formataDiaCancelado.push(item.date);
      } else {
        formataTotalEntregue.push(item.total);
        formataDiaEntregue.push(item.date);
      }
      return null
    })
   
    setEvolutivoPedidosCanceladosTotal(formataTotalCancelado);
    setEvolutivoPedidosCanceladosDia(formataDiaCancelado);
    setEvolutivoPedidosEntreguesTotal(formataTotalEntregue);
    setEvolutivoPedidosEntreguesDia(formataDiaEntregue);
    //Fim***
}

const dataEvolutivo = {
  labels: evolutivoPedidosEntreguesDia,
  datasets: [
    {
      label: "Pedido Entregue",
      data: evolutivoPedidosEntreguesTotal,
      fill: true,
      backgroundColor: "rgba(75,192,192,0.2)",
      borderColor: "rgba(75,192,192,1)"
    },
    {
      label: "Pedido Cancelado",
      data: evolutivoPedidosCanceladosTotal,
      fill: true,
      borderColor: "#742774",
      backgroundColor: "#d1b5cf"
    }
  ]
};

const legend = {
  display: true,
  position: "bottom",
  labels: {
    fontColor: "#323130",
    fontSize: 14
  }
};

const optionsEvolutivo = {
  scales: {
    yAxes: [
      {
        ticks: {
          suggestedMin: 0,
          suggestedMax: 100
        }
      }
    ]
  }
};

function getValorTransacionado(){
  setCaValorTransacionados(true); 
  Api.get("painel/valorTransacionado").then(rps => {

    geraGraficoTransacionado(rps.data.obj);
   
    setCaValorTransacionados(false);
  })
}

function geraGraficoTransacionado(dados){
  //***Inicio do Grafico Vendas por dia

  var formataTotalCancelado = [];
  var formataDiaCancelado = [];
  var formataTotalEntregue = [];
  var formataDiaEntregue = [];

  dados.map( item => {
    if (item.Status == "Pedido Cancelado") {
      formataTotalCancelado.push(item.total);
      formataDiaCancelado.push(item.date);
    } else {
      formataTotalEntregue.push(item.total);
      formataDiaEntregue.push(item.date);
    }
    return null
  })
 
  setValorTransacionadosCanceladosTotal(formataTotalCancelado);
  setValorTransacionadosCanceladosDia(formataDiaCancelado);
  setValorTransacionadosEntreguesTotal(formataTotalEntregue);
  setValorTransacionadosEntreguesDia(formataDiaEntregue);
  //Fim***
}

const dataTransacionado = {
labels: valorTransacionadosEntreguesDia,
datasets: [
  {
    label: "Pedido Entregue",
    data: valorTransacionadosEntreguesTotal,
    fill: true,
    backgroundColor: "rgba(75,192,192,0.2)",
    borderColor: "rgba(75,192,192,1)"
  },
  {
    label: "Pedido Cancelado",
    data: valorTransacionadosCanceladosTotal,
    fill: true,
    borderColor: "#742774",
    backgroundColor: "#d1b5cf"
  }
]
};

const legendTransacionado = {
display: true,
position: "bottom",
labels: {
  fontColor: "#323130",
  fontSize: 14
}
};

const optionsTransacionado = {
scales: {
  yAxes: [
    {
      ticks: {
        suggestedMin: 0,
        suggestedMax: 100
      }
    }
  ]
}
};

function getEvolutivoUsuarioCadastrado(){
  setCaEvolutivoUsuarioCadastrado(true); 
  Api.get("painel/evolutivoCadUsuarios12m").then(rps => {

    geraEvolutivoUsuarioCadastrado(rps.data.obj);
   
    setCaEvolutivoUsuarioCadastrado(false);
  })
}

function geraEvolutivoUsuarioCadastrado(dados){
  //***Inicio do Grafico Vendas por dia

  var formataQtde = [];
  var formataMes = [];

  dados.map( item => { 
      formataQtde.push(item.qtde);
      formataMes.push(item.mes);
    
    return null
  })
 
  setEvolutivoUsuarioCadastrado(formataQtde);
  setEvolutivoUsuarioCadastradoMes(formataMes);
  //Fim***
}

const dataUsuarioCadastrado = {
labels: evolutivoUsuarioCadastradoMes,
datasets: [
  {
    label: "Pedido Entregue",
    data: evolutivoUsuarioCadastrado,
    fill: true,
    backgroundColor: "#ffc2bb",
    borderColor: "#f42a3f"
  },
]
};

const legendUsuarioCadastrado = {
display: false,
};

const optionsUsuarioCadastrado = {
scales: {
  yAxes: [
    {
      ticks: {
        suggestedMin: 0,
        suggestedMax: 100
      }
    }
  ]
}
};

function getEvolutivoPedUsuarioCadastrado(){
  setCaEvolutivoPedUsuarioCadastrado(true); 
  Api.get("painel/evolutivoPedUsuarios12m").then(rps => {

    geraEvolutivoPedUsuarioCadastrado(rps.data.obj);
   
    setCaEvolutivoPedUsuarioCadastrado(false);
  })
}

function geraEvolutivoPedUsuarioCadastrado(dados){
  //***Inicio do Grafico Vendas por dia

  var formataQtde = [];
  var formataMes = [];

  dados.map( item => { 
      formataQtde.push(item.qtde);
      formataMes.push(item.mes);
    
    return null
  })
 
  setEvolutivoPedUsuarioCadastrado(formataQtde);
  setEvolutivoPedUsuarioCadastradoMes(formataMes);
  //Fim***
}

const dataPedUsuarioCadastrado = {
labels: evolutivoPedUsuarioCadastradoMes,
datasets: [
  {
    label: "Pedido Entregue",
    data: evolutivoPedUsuarioCadastrado,
    fill: true,
    backgroundColor: "#ffc2bb",
    borderColor: "#f42a3f"
  },
]
};

const legendPedUsuarioCadastrado = {
display: false,
};

const optionsPedUsuarioCadastrado = {
scales: {
  yAxes: [
    {
      ticks: {
        suggestedMin: 0,
        suggestedMax: 100
      }
    }
  ]
}
};

function getEvolutivoSPedUsuarioCadastrado(){
  setCaEvolutivoSPedUsuarioCadastrado(true); 
  Api.get("painel/evolutivoSPedUsuarios12m").then(rps => {

    geraEvolutivoSPedUsuarioCadastrado(rps.data.obj);
   
    setCaEvolutivoSPedUsuarioCadastrado(false);
  })
}

function geraEvolutivoSPedUsuarioCadastrado(dados){
  //***Inicio do Grafico Vendas por dia

  var formataQtde = [];
  var formataMes = [];

  dados.map( item => { 
      formataQtde.push(item.qtde);
      formataMes.push(item.mes);
    
    return null
  })
 
  setEvolutivoSPedUsuarioCadastrado(formataQtde);
  setEvolutivoSPedUsuarioCadastradoMes(formataMes);
  //Fim***
}

const dataSPedUsuarioCadastrado = {
labels: evolutivoSPedUsuarioCadastradoMes,
datasets: [
  {
    label: "Pedido Entregue",
    data: evolutivoSPedUsuarioCadastrado,
    fill: true,
    backgroundColor: "#ffc2bb",
    borderColor: "#f42a3f"
  },
]
};

const legendSPedUsuarioCadastrado = {
display: false,
};

const optionsSPedUsuarioCadastrado = {
scales: {
  yAxes: [
    {
      ticks: {
        suggestedMin: 0,
        suggestedMax: 100
      }
    }
  ]
}
};

function getTicketMedio(){
  setCaTicketMedio(true); 
  Api.get("painel/ticketmedio").then(rps => {
    setTicketMedio(rps.data.obj.ticket);
   
    setCaTicketMedio(false);
  })
}

function getEvolutivoTicketMedio(){
  setCaEvolutivoTicketMedio(true); 
  Api.get("painel/evolutivoticketmedio").then(rps => {

    geraEvolutivoTicketMedio(rps.data.obj);
   
    setCaEvolutivoTicketMedio(false);
  })
}

function geraEvolutivoTicketMedio(dados){
  //***Inicio do Grafico Vendas por dia

  var formataQtde = [];
  var formataMes = [];

  dados.map( item => { 
      formataQtde.push(item.avg);
      formataMes.push(item.date);
    
    return null
  })
 
  setEvolutivoTicketMedio(formataQtde);
  setEvolutivoTicketMedioMes(formataMes);
  //Fim***
}

const dataEvolutivoTicketMedio = {
labels: evolutivoTicketMedioMes,
datasets: [
  {
    label: "Pedido Entregue",
    data: evolutivoTicketMedio,
    fill: true,
    backgroundColor: "#ffc2bb",
    borderColor: "#f42a3f"
  },
]
};

const legendEvolutivoTicketMedio = {
display: false,
};

const optionsEvolutivoTicketMedio = {
scales: {
  yAxes: [
    {
      ticks: {
        suggestedMin: 0,
        suggestedMax: 100
      }
    }
  ]
}
};

function getEvolutivoConversao(){
  setCaEvolutivoConversao(true); 
  Api.get("painel/evolutivoconversao").then(rps => {

    geraEvolutivoConversao(rps.data.obj);
   
    setCaEvolutivoConversao(false);
  })
}

function geraEvolutivoConversao(dados){
  //***Inicio do Grafico Vendas por dia

  var formataQtde = [];
  var formataMes = [];

  dados.map( item => { 
      formataQtde.push(item.convertion);
      formataMes.push(item.date);
    
    return null
  })
 
  setEvolutivoConversao(formataQtde);
  setEvolutivoConversaoMes(formataMes);
  //Fim***
}

const dataEvolutivoConversao = {
labels: evolutivoConversaoMes,
datasets: [
  {
    label: "Pedido Entregue",
    data: evolutivoConversao,
    fill: true,
    backgroundColor: "#ffc2bb",
    borderColor: "#f42a3f"
  },
]
};

const legendEvolutivoConversao = {
display: false,
};

const optionsEvolutivoConversao = {
scales: {
  yAxes: [
    {
      ticks: {
        suggestedMin: 0,
        suggestedMax: 100
      }
    }
  ]
}
};

// function getFrequencia(){
//   setCaFrequencia(true); 
//   Api.get("painel/frequencia").then(rps => {
//     setFrequencia(rps.data.obj.freq);
   
//     setCaFrequencia(false);
//   })
// }

// function getFrequenciaPedido(){
//   setCaFrequenciaPedido(true); 
//   Api.get("painel/frequenciaprimeiromes").then(rps => {

//     geraFrequenciaPedido(rps.data.obj);
   
//     setCaFrequenciaPedido(false);
//   })
// }

// function geraFrequenciaPedido(dados){
//   //***Inicio do Grafico Vendas por dia

//   var formataQtde = [];
//   var formataMes = [];

//   dados.map( item => { 
//       formataQtde.push(item.freq);
//       formataMes.push(item.date);
    
//     return null
//   })
 
//   setFrequenciaPedido(formataQtde);
//   setFrequenciaPedidoMes(formataMes);
//   //Fim***
// }

// const dataFrequenciaPedido = {
// labels: frequenciaPedidoMes,
// datasets: [
//   {
//     label: "Pedido Entregue",
//     data: frequenciaPedido,
//     fill: true,
//     backgroundColor: "#ffc2bb",
//     borderColor: "#f42a3f"
//   },
// ]
// };

// const legendFrequenciaPedido = {
// display: false,
// };

// const optionsFrequenciaPedido = {
// scales: {
//   yAxes: [
//     {
//       ticks: {
//         suggestedMin: 0,
//         suggestedMax: 100
//       }
//     }
//   ]
// }
// };

// function getEvolutivoRetencao(){
//   setCaEvolutivoRetencao(true); 
//   Api.get("painel/evolucaoretencao").then(rps => {

//     geraEvolutivoRetencao(rps.data.obj);
   
//     setCaEvolutivoRetencao(false);
//   })
// }

// function geraEvolutivoRetencao(dados){
//   //***Inicio do Grafico Vendas por dia

//   var formataQtde = [];
//   var formataMes = [];

//   dados.map( item => { 
//       formataQtde.push(item.retention_percent);
//       formataMes.push(item.date);
    
//     return null
//   })
 
//   setEvolutivoRetencao(formataQtde);
//   setEvolutivoRetencaoMes(formataMes);
//   //Fim***
// }

// const dataEvolutivoRetencao = {
// labels: evolutivoRetencaoMes,
// datasets: [
//   {
//     label: "Pedido Entregue",
//     data: evolutivoRetencao,
//     fill: true,
//     backgroundColor: "#ffc2bb",
//     borderColor: "#f42a3f"
//   },
// ]
// };

// const legendEvolutivoRetencao = {
// display: false,
// };

// const optionsEvolutivoRetencao = {
// scales: {
//   yAxes: [
//     {
//       ticks: {
//         suggestedMin: 0,
//         suggestedMax: 100
//       }
//     }
//   ]
// }
// };


    return (
        <>

        {/*begin::Content*/}
        <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
          {/*begin::Entry*/}
          <div className="d-flex flex-column-fluid">
            {/*begin::Container*/}
            <div className="container">
              {/*begin::Dashboard*/}

{/*begin::Card*/}
<div className="card card-custom gutter-b">
  <div className="card-header">
    <div className="card-title">
      <h3 className="card-label">Aiboo - DashBord</h3>
    </div>
  </div>
  <div className="card-body">
    {/*begin::Example*/}
    <div className="example">
      <div className="example-preview">
        <ul className="nav nav-tabs" id="myTab" role="tablist">
          { localStorage.getItem('@ADM-Aiboo/nivel') == "A" &&
          <li className="nav-item">
            <a className="nav-link active" id="home-tab" data-toggle="tab" href="#home" aria-controls="home">
              <span className="nav-icon">
                <i className="flaticon2-chat-1" />
              </span>
              <span className="nav-text">Metrics</span>
            </a>
          </li> 
          }
          <li className="nav-item">
            <a className="nav-link" id="profile-tab" data-toggle="tab" href="#profile" aria-controls="profile">
              <span className="nav-icon">
                <i className="flaticon2-layers-1" />
              </span>
              <span className="nav-text">por Cidade</span>
            </a>
          </li>
          { localStorage.getItem('@ADM-Aiboo/nivel') == "A" &&
          <li className="nav-item">
            <a className="nav-link" id="contact-tab" data-toggle="tab" href="#contact" aria-controls="contact">
              <span className="nav-icon">
                <i className="flaticon2-rocket-1" />
              </span>
              <span className="nav-text">por Restaurante</span>
            </a>
          </li>
          }
          { localStorage.getItem('@ADM-Aiboo/nivel') == "A" &&
          <li className="nav-item">
            <a className="nav-link" id="rep-tab" data-toggle="tab" href="#rep" aria-controls="rep">
              <span className="nav-icon">
                <i className="flaticon2-soft-icons-1" />
              </span>
              <span className="nav-text">por Representante</span>
            </a>
          </li>
          }
        </ul>

        <div className="tab-content mt-5" id="myTabContent">
          { localStorage.getItem('@ADM-Aiboo/nivel') == "A" &&
          
          <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">  

              <div className='row'>
                <div className='col-md-12'>
                  <div className="card card-custom mb-5">
                    <div className="card-body pt-3 pb-3">
                      <div className='row'>
                        <div className='col-md-6'>
                          <label className='text-danger font-weight-boldest'>Atenção</label><br />
                          <label>Selecione os checkbox das Métricas que deseja exibir e click no botão filtrar.</label>
                        </div>
                        <div className='col-md-6'>                      
                          <button className='btn btn-primary' onClick={e => { filtrar(); }}><i className="flaticon2-search-1" />Filtrar</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
                          
              <div className="row">
                <div className="col-xl-6">
                <LoadingOverlay
                    active={casemMovimentacao}
                    spinner
                    text='Carregando...'
                  >
                {/*begin::Base Table Widget 1*/}
                <div className="card card-custom card-stretch gutter-b">
                  {/*begin::Header*/}
                  <div className="card-header border-0 pt-5">
                        <div className="checkbox-inline">
                          <label className="checkbox checkbox-square checkbox-primary font-weight-bolder text-dark font-size-h5">
                            <input type="checkbox"
                              onChange={(e) => { setExibir({ ...exibir, estabelecimentosSemMovimentacao: !exibir.estabelecimentosSemMovimentacao }) }}
                              checked={exibir?.estabelecimentosSemMovimentacao == 1 ? true : false} 
                            />
                          <span />Estabelecimentos sem movimentação</label><br />
                          <span className="text-muted mt-3 font-weight-bold font-size-sm">há mais de 30 dias</span>
                        </div>
                    <div className="card-toolbar">
                      <button className='btn btn-primary' onClick={e => { verTodosSemMovimentacao(); }}>Ver todos</button>
                    </div>
                  </div>
                  {/*end::Header*/}
                  {/*begin::Body*/}
                  <div className="card-body pt-2 pb-0 mt-n3">
                        {/*begin::Table*/}
                        
                        <div className="table-responsive">
                          <table className="table table-borderless table-vertical-center">
                            <thead>
                              <tr>
                                <th className="p-0 w-50px" />
                                <th className="p-0 min-w-200px" />
                                <th className="p-0 min-w-140px" />
                              </tr>
                            </thead>
                            <tbody>
                            { estSemCompras.map((item, i) =>
                            i <= 10 && 
                              <tr>
                                <th className="pl-0 py-5">
                                  <div className="symbol symbol-50 symbol-light mr-2">
                                    <span className="symbol-label">
                                      <img src={item.logotipo} className="h-100 w-100 align-self-center" alt="" />
                                    </span>
                                  </div>
                                </th>
                                <td className="py-6 pl-0">
                                  <Link to='/estabelecimentos/estabelecimentos' 
                                        className="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg" 
                                        onClick={e => { redirecionaEst(item.id_estabelecimento) }}>[{item.id_estabelecimento}] - {item.nome}</Link>
                                  <span className="text-muted font-weight-bold d-block">{item.nome_cidade} - {item.estado}</span>
                                </td>
                                <td>
                                  <div className="d-flex flex-column w-100 mr-2">
                                    <div className="text-dark text-right mb-1 font-size-lg">
                                      {item.data}
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            )}
                            </tbody>
                          </table>
                        </div>
                        {/*end::Table*/}
                      </div>
                      {/*end::Tap pane*/}
                </div>
                {/*end::Base Table Widget 1*/} 
                </LoadingOverlay> 
                </div>
                <div className="col-xl-6">
                <LoadingOverlay
                    active={caCancelamento}
                    spinner
                    text='Carregando...'
                  >
                {/*begin::Base Table Widget 1*/}
                <div className="card card-custom card-stretch gutter-b">
                  {/*begin::Header*/}
                  <div className="card-header border-0 pt-5">
                        <div className="checkbox-inline">
                          <label className="checkbox checkbox-square checkbox-primary font-weight-bolder text-dark font-size-h5">
                            <input type="checkbox"
                              onChange={(e) => { setExibir({ ...exibir, taxaCancelamento: !exibir.taxaCancelamento }) }}
                              checked={exibir?.taxaCancelamento == 1 ? true : false} 
                            />
                          <span />Taxa de Cancelamento</label><br />
                          <span className="text-muted mt-3 font-weight-bold font-size-sm">há mais de 30 dias</span>
                        </div>
                    <div className="card-toolbar">
                      <button className='btn btn-primary' onClick={e => { verTodosCancelamentos(); }}>Ver todos</button>
                    </div>
                  </div>
                  {/*end::Header*/}
                  {/*begin::Body*/}
                  <div className="card-body pt-2 pb-0 mt-n3">
                        {/*begin::Table*/}
                        
                        <div className="table-responsive">
                          <table className="table table-borderless table-vertical-center">
                            <thead>
                              <tr>
                                <th className="p-0 w-50px" />
                                <th className="p-0 min-w-200px" />
                                <th className="p-0 min-w-140px" />
                              </tr>
                            </thead>
                            <tbody>
                            { cancelamento.map((item, i) =>
                            (i <= 10 && item.porcetagem > 0) &&
                              <tr>
                                <th className="pl-0 py-5">
                                  <div className="symbol symbol-50 symbol-light mr-2">
                                    <span className="symbol-label">
                                      <img src={item.logotipo} className="h-100 w-100 align-self-center" alt="" />
                                    </span>
                                  </div>
                                </th>
                                <td className="py-6 pl-0">
                                  <Link to='/estabelecimentos/estabelecimentos' 
                                        className="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg" 
                                        onClick={e => { redirecionaEst(item.id_estabelecimento) }}>[{item.id_estabelecimento}] - {item.nome}</Link>
                                  <span className="text-muted font-weight-bold d-block">Efetivadas: {item.Efetivadas} - Canceladas {item.Canceladas} - <label className="text-primary">Taxa: <b>{item.porcetagem}%</b></label></span>
                                </td>
                                <td>
                                  <div className="d-flex flex-column w-100 mr-2">
                                    <div className="text-dark text-right mb-1 font-size-lg">
                                      {item.data}
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            )}
                            </tbody>
                          </table>
                        </div>
                        {/*end::Table*/}
                      </div>
                      {/*end::Tap pane*/}
                </div>
                {/*end::Base Table Widget 1*/} 
                </LoadingOverlay>  
                </div>
              </div>
              {/*end::Row*/}

              {/*begin::Row*/}
              <div className="row">
                <div className="col-md-3">
                  <LoadingOverlay
                    active={caRetencao}
                    spinner
                    text='Carregando...'
                  >
                    <div className="card card-custom gutter-b" style={{height: '160px'}}>
                    <div className="card-body">
                      <span className="svg-icon svg-icon-3x svg-icon-primary">
                        {/*begin::Svg Icon | path:assets/media/svg/icons/Communication/Group.svg*/}
                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                          <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                            <polygon points="0 0 24 0 24 24 0 24" />
                            <path d="M18,14 C16.3431458,14 15,12.6568542 15,11 C15,9.34314575 16.3431458,8 18,8 C19.6568542,8 21,9.34314575 21,11 C21,12.6568542 19.6568542,14 18,14 Z M9,11 C6.790861,11 5,9.209139 5,7 C5,4.790861 6.790861,3 9,3 C11.209139,3 13,4.790861 13,7 C13,9.209139 11.209139,11 9,11 Z" fill="#000000" fillRule="nonzero" opacity="0.3" />
                            <path d="M17.6011961,15.0006174 C21.0077043,15.0378534 23.7891749,16.7601418 23.9984937,20.4 C24.0069246,20.5466056 23.9984937,21 23.4559499,21 L19.6,21 C19.6,18.7490654 18.8562935,16.6718327 17.6011961,15.0006174 Z M0.00065168429,20.1992055 C0.388258525,15.4265159 4.26191235,13 8.98334134,13 C13.7712164,13 17.7048837,15.2931929 17.9979143,20.2 C18.0095879,20.3954741 17.9979143,21 17.2466999,21 C13.541124,21 8.03472472,21 0.727502227,21 C0.476712155,21 -0.0204617505,20.45918 0.00065168429,20.1992055 Z" fill="#000000" fillRule="nonzero" />
                          </g>
                        </svg>
                        {/*end::Svg Icon*/}
                      </span>
                      <div class="float-right">
                        <div className="checkbox-inline">
                          <label className="checkbox checkbox-square checkbox-primary">
                            <input type="checkbox"
                              onChange={(e) => { setExibir({ ...exibir, retencao: !exibir.retencao }) }}
                              checked={exibir?.retencao == 1 ? true : false} 
                            />
                          <span /></label>
                        </div>
                      </div>
                      <div className="text-dark font-weight-bolder font-size-h2 mt-3">{retencao}</div>
                      <label className="text-muted font-weight-bold font-size-lg mt-1">Retenção 40 DD</label>
                    </div>
                  </div>
                  {/*end::Tiles Widget 12*/}
                  </LoadingOverlay>
                </div>

                <div className="col-md-3">
                  <LoadingOverlay
                    active={caChurn}
                    spinner
                    text='Carregando...'
                  >
                    <div className="card card-custom gutter-b" style={{height: '160px'}}>
                    <div className="card-body">
                      <span className="svg-icon svg-icon-3x svg-icon-primary">
                        {/*begin::Svg Icon | path:assets/media/svg/icons/Communication/Group.svg*/}
                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                          <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                            <polygon points="0 0 24 0 24 24 0 24" />
                            <path d="M18,14 C16.3431458,14 15,12.6568542 15,11 C15,9.34314575 16.3431458,8 18,8 C19.6568542,8 21,9.34314575 21,11 C21,12.6568542 19.6568542,14 18,14 Z M9,11 C6.790861,11 5,9.209139 5,7 C5,4.790861 6.790861,3 9,3 C11.209139,3 13,4.790861 13,7 C13,9.209139 11.209139,11 9,11 Z" fill="#000000" fillRule="nonzero" opacity="0.3" />
                            <path d="M17.6011961,15.0006174 C21.0077043,15.0378534 23.7891749,16.7601418 23.9984937,20.4 C24.0069246,20.5466056 23.9984937,21 23.4559499,21 L19.6,21 C19.6,18.7490654 18.8562935,16.6718327 17.6011961,15.0006174 Z M0.00065168429,20.1992055 C0.388258525,15.4265159 4.26191235,13 8.98334134,13 C13.7712164,13 17.7048837,15.2931929 17.9979143,20.2 C18.0095879,20.3954741 17.9979143,21 17.2466999,21 C13.541124,21 8.03472472,21 0.727502227,21 C0.476712155,21 -0.0204617505,20.45918 0.00065168429,20.1992055 Z" fill="#000000" fillRule="nonzero" />
                          </g>
                        </svg>
                        {/*end::Svg Icon*/}
                      </span>
                      <div class="float-right">
                        <div className="checkbox-inline">
                          <label className="checkbox checkbox-square checkbox-primary">
                            <input type="checkbox"
                              onChange={(e) => { setExibir({ ...exibir, churn: !exibir.churn }) }}
                              checked={exibir?.churn == 1 ? true : false} 
                            />
                          <span /></label>
                        </div>
                      </div>
                      <div className="text-dark font-weight-bolder font-size-h2 mt-3">{churn}</div>
                      <label className="text-muted font-weight-bold font-size-lg mt-1">Churn - 40D</label>
                    </div>
                  </div>
                  {/*end::Tiles Widget 12*/}

                  </LoadingOverlay>
                </div>

                <div className="col-md-3">
                  <LoadingOverlay
                    active={caTaxaGeralConversao}
                    spinner
                    text='Carregando...'
                  >
                    <div className="card card-custom gutter-b" style={{height: '160px'}}>
                    <div className="card-body">
                      <span className="svg-icon svg-icon-3x svg-icon-primary">
                        {/*begin::Svg Icon | path:assets/media/svg/icons/Communication/Group.svg*/}
                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                          <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                            <polygon points="0 0 24 0 24 24 0 24" />
                            <path d="M18,14 C16.3431458,14 15,12.6568542 15,11 C15,9.34314575 16.3431458,8 18,8 C19.6568542,8 21,9.34314575 21,11 C21,12.6568542 19.6568542,14 18,14 Z M9,11 C6.790861,11 5,9.209139 5,7 C5,4.790861 6.790861,3 9,3 C11.209139,3 13,4.790861 13,7 C13,9.209139 11.209139,11 9,11 Z" fill="#000000" fillRule="nonzero" opacity="0.3" />
                            <path d="M17.6011961,15.0006174 C21.0077043,15.0378534 23.7891749,16.7601418 23.9984937,20.4 C24.0069246,20.5466056 23.9984937,21 23.4559499,21 L19.6,21 C19.6,18.7490654 18.8562935,16.6718327 17.6011961,15.0006174 Z M0.00065168429,20.1992055 C0.388258525,15.4265159 4.26191235,13 8.98334134,13 C13.7712164,13 17.7048837,15.2931929 17.9979143,20.2 C18.0095879,20.3954741 17.9979143,21 17.2466999,21 C13.541124,21 8.03472472,21 0.727502227,21 C0.476712155,21 -0.0204617505,20.45918 0.00065168429,20.1992055 Z" fill="#000000" fillRule="nonzero" />
                          </g>
                        </svg>
                        {/*end::Svg Icon*/}
                      </span>
                      <div class="float-right">
                        <div className="checkbox-inline">
                          <label className="checkbox checkbox-square checkbox-primary">
                            <input type="checkbox"
                              onChange={(e) => { setExibir({ ...exibir, taxaGeralConversao: !exibir.taxaGeralConversao }) }}
                              checked={exibir?.taxaGeralConversao == 1 ? true : false} 
                            />
                          <span /></label>
                        </div>
                      </div>
                      <div className="text-dark font-weight-bolder font-size-h2 mt-3">{taxaGeralConversao}</div>
                      <label className="text-muted font-weight-bold font-size-lg mt-1">Taxa Geral de Conversão</label>
                    </div>
                  </div>
                  {/*end::Tiles Widget 12*/}
                  </LoadingOverlay>
                </div>

                <div className="col-md-3">
                  <LoadingOverlay
                    active={caTaxaConversao30}
                    spinner
                    text='Carregando...'
                  >
                    <div className="card card-custom gutter-b" style={{height: '160px'}}>
                    <div className="card-body">
                      <span className="svg-icon svg-icon-3x svg-icon-primary">
                        {/*begin::Svg Icon | path:assets/media/svg/icons/Communication/Group.svg*/}
                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                          <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                            <polygon points="0 0 24 0 24 24 0 24" />
                            <path d="M18,14 C16.3431458,14 15,12.6568542 15,11 C15,9.34314575 16.3431458,8 18,8 C19.6568542,8 21,9.34314575 21,11 C21,12.6568542 19.6568542,14 18,14 Z M9,11 C6.790861,11 5,9.209139 5,7 C5,4.790861 6.790861,3 9,3 C11.209139,3 13,4.790861 13,7 C13,9.209139 11.209139,11 9,11 Z" fill="#000000" fillRule="nonzero" opacity="0.3" />
                            <path d="M17.6011961,15.0006174 C21.0077043,15.0378534 23.7891749,16.7601418 23.9984937,20.4 C24.0069246,20.5466056 23.9984937,21 23.4559499,21 L19.6,21 C19.6,18.7490654 18.8562935,16.6718327 17.6011961,15.0006174 Z M0.00065168429,20.1992055 C0.388258525,15.4265159 4.26191235,13 8.98334134,13 C13.7712164,13 17.7048837,15.2931929 17.9979143,20.2 C18.0095879,20.3954741 17.9979143,21 17.2466999,21 C13.541124,21 8.03472472,21 0.727502227,21 C0.476712155,21 -0.0204617505,20.45918 0.00065168429,20.1992055 Z" fill="#000000" fillRule="nonzero" />
                          </g>
                        </svg>
                        {/*end::Svg Icon*/}
                      </span>
                      <div class="float-right">
                        <div className="checkbox-inline">
                          <label className="checkbox checkbox-square checkbox-primary">
                            <input type="checkbox"
                              onChange={(e) => { setExibir({ ...exibir, taxaConversao30: !exibir.taxaConversao30 }) }}
                              checked={exibir?.taxaConversao30 == 1 ? true : false} 
                            />
                          <span /></label>
                        </div>
                      </div>
                      <div className="text-dark font-weight-bolder font-size-h2 mt-3">{taxaConversao30}</div>
                      <label className="text-muted font-weight-bold font-size-lg mt-1">Taxa de Conversão - 30D</label>
                    </div>
                  </div>
                  {/*end::Tiles Widget 12*/}
                  </LoadingOverlay>
                </div>

              </div>
              {/*end::Row*/}
                    
              {/*begin::Row*/}
              <div className="row">
                <div className="col-md-2">
                  <LoadingOverlay
                    active={caUsuariosCadastrados}
                    spinner
                    text='Carregando...'
                  >
                  <div className="card card-custom gutter-b" style={{height: '160px'}}>
                    <div className="card-body">
                      <span className="svg-icon svg-icon-3x svg-icon-primary">
                        {/*begin::Svg Icon | path:assets/media/svg/icons/Communication/Group.svg*/}
                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                          <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                            <polygon points="0 0 24 0 24 24 0 24" />
                            <path d="M18,14 C16.3431458,14 15,12.6568542 15,11 C15,9.34314575 16.3431458,8 18,8 C19.6568542,8 21,9.34314575 21,11 C21,12.6568542 19.6568542,14 18,14 Z M9,11 C6.790861,11 5,9.209139 5,7 C5,4.790861 6.790861,3 9,3 C11.209139,3 13,4.790861 13,7 C13,9.209139 11.209139,11 9,11 Z" fill="#000000" fillRule="nonzero" opacity="0.3" />
                            <path d="M17.6011961,15.0006174 C21.0077043,15.0378534 23.7891749,16.7601418 23.9984937,20.4 C24.0069246,20.5466056 23.9984937,21 23.4559499,21 L19.6,21 C19.6,18.7490654 18.8562935,16.6718327 17.6011961,15.0006174 Z M0.00065168429,20.1992055 C0.388258525,15.4265159 4.26191235,13 8.98334134,13 C13.7712164,13 17.7048837,15.2931929 17.9979143,20.2 C18.0095879,20.3954741 17.9979143,21 17.2466999,21 C13.541124,21 8.03472472,21 0.727502227,21 C0.476712155,21 -0.0204617505,20.45918 0.00065168429,20.1992055 Z" fill="#000000" fillRule="nonzero" />
                          </g>
                        </svg>
                        {/*end::Svg Icon*/}
                      </span>
                      <div class="float-right">
                        <div className="checkbox-inline">
                          <label className="checkbox checkbox-square checkbox-primary">
                            <input type="checkbox"
                              onChange={(e) => { setExibir({ ...exibir, usuariosCadastrados: !exibir.usuariosCadastrados }) }}
                              checked={exibir?.usuariosCadastrados == 1 ? true : false} 
                            />
                          <span /></label>
                        </div>
                      </div>
                      <div className="text-dark font-weight-bolder font-size-h2 mt-3">{usuariosCadastrados}</div>
                      <label className="text-muted font-weight-bold font-size-lg mt-1">Usuários Cadastrados</label>
                    </div>
                  </div>
                  {/*end::Tiles Widget 12*/}
                  </LoadingOverlay>
                </div>

                <div className="col-md-2">
                  <LoadingOverlay
                    active={caUsuariosCadastradosCPedidos}
                    spinner
                    text='Carregando...'
                  >
                  <div className="card card-custom gutter-b" style={{height: '160px'}}>
                    <div className="card-body">
                      <span className="svg-icon svg-icon-3x svg-icon-primary">
                        {/*begin::Svg Icon | path:assets/media/svg/icons/Communication/Group.svg*/}
                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                          <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                            <polygon points="0 0 24 0 24 24 0 24" />
                            <path d="M18,14 C16.3431458,14 15,12.6568542 15,11 C15,9.34314575 16.3431458,8 18,8 C19.6568542,8 21,9.34314575 21,11 C21,12.6568542 19.6568542,14 18,14 Z M9,11 C6.790861,11 5,9.209139 5,7 C5,4.790861 6.790861,3 9,3 C11.209139,3 13,4.790861 13,7 C13,9.209139 11.209139,11 9,11 Z" fill="#000000" fillRule="nonzero" opacity="0.3" />
                            <path d="M17.6011961,15.0006174 C21.0077043,15.0378534 23.7891749,16.7601418 23.9984937,20.4 C24.0069246,20.5466056 23.9984937,21 23.4559499,21 L19.6,21 C19.6,18.7490654 18.8562935,16.6718327 17.6011961,15.0006174 Z M0.00065168429,20.1992055 C0.388258525,15.4265159 4.26191235,13 8.98334134,13 C13.7712164,13 17.7048837,15.2931929 17.9979143,20.2 C18.0095879,20.3954741 17.9979143,21 17.2466999,21 C13.541124,21 8.03472472,21 0.727502227,21 C0.476712155,21 -0.0204617505,20.45918 0.00065168429,20.1992055 Z" fill="#000000" fillRule="nonzero" />
                          </g>
                        </svg>
                        {/*end::Svg Icon*/}
                      </span>
                      <div class="float-right">
                        <div className="checkbox-inline">
                          <label className="checkbox checkbox-square checkbox-primary">
                            <input type="checkbox"
                              onChange={(e) => { setExibir({ ...exibir, usuariosCadastradosCPedidos: !exibir.usuariosCadastradosCPedidos }) }}
                              checked={exibir?.usuariosCadastradosCPedidos == 1 ? true : false} 
                            />
                          <span /></label>
                        </div>
                      </div>
                      <div className="text-dark font-weight-bolder font-size-h2 mt-3">{usuariosCadastradosCPedidos}</div>
                      <label className="text-muted font-weight-bold font-size-lg mt-1">Usuários Cadastrados c/ pedidos</label>
                    </div>
                  </div>
                  {/*end::Tiles Widget 12*/}
                  </LoadingOverlay>
                </div>

                <div className="col-md-2">
                  <LoadingOverlay
                    active={caUsuariosCadastradosSPedidos}
                    spinner
                    text='Carregando...'
                  >
                  <div className="card card-custom gutter-b" style={{height: '160px'}}>
                    <div className="card-body">
                      <span className="svg-icon svg-icon-3x svg-icon-primary">
                        {/*begin::Svg Icon | path:assets/media/svg/icons/Communication/Group.svg*/}
                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                          <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                            <polygon points="0 0 24 0 24 24 0 24" />
                            <path d="M18,14 C16.3431458,14 15,12.6568542 15,11 C15,9.34314575 16.3431458,8 18,8 C19.6568542,8 21,9.34314575 21,11 C21,12.6568542 19.6568542,14 18,14 Z M9,11 C6.790861,11 5,9.209139 5,7 C5,4.790861 6.790861,3 9,3 C11.209139,3 13,4.790861 13,7 C13,9.209139 11.209139,11 9,11 Z" fill="#000000" fillRule="nonzero" opacity="0.3" />
                            <path d="M17.6011961,15.0006174 C21.0077043,15.0378534 23.7891749,16.7601418 23.9984937,20.4 C24.0069246,20.5466056 23.9984937,21 23.4559499,21 L19.6,21 C19.6,18.7490654 18.8562935,16.6718327 17.6011961,15.0006174 Z M0.00065168429,20.1992055 C0.388258525,15.4265159 4.26191235,13 8.98334134,13 C13.7712164,13 17.7048837,15.2931929 17.9979143,20.2 C18.0095879,20.3954741 17.9979143,21 17.2466999,21 C13.541124,21 8.03472472,21 0.727502227,21 C0.476712155,21 -0.0204617505,20.45918 0.00065168429,20.1992055 Z" fill="#000000" fillRule="nonzero" />
                          </g>
                        </svg>
                        {/*end::Svg Icon*/}
                      </span>
                      <div class="float-right">
                        <div className="checkbox-inline">
                          <label className="checkbox checkbox-square checkbox-primary">
                            <input type="checkbox"
                              onChange={(e) => { setExibir({ ...exibir, usuariosCadastradosSPedidos: !exibir.usuariosCadastradosSPedidos }) }}
                              checked={exibir?.usuariosCadastradosSPedidos == 1 ? true : false} 
                            />
                          <span /></label>
                        </div>
                      </div>
                      <div className="text-dark font-weight-bolder font-size-h2 mt-3">{usuariosCadastradosSPedidos}</div>
                      <label className="text-muted font-weight-bold font-size-lg mt-1">Usuários Cadastrados s/ pedidos</label>
                    </div>
                  </div>
                  {/*end::Tiles Widget 12*/}
                  </LoadingOverlay>
                </div>

                <div className="col-md-2">
                  <LoadingOverlay
                    active={caUsuariosCadastradosPedidoUnico}
                    spinner
                    text='Carregando...'
                  >
                  <div className="card card-custom gutter-b" style={{height: '160px'}}>
                    <div className="card-body">
                      <span className="svg-icon svg-icon-3x svg-icon-primary">
                        {/*begin::Svg Icon | path:assets/media/svg/icons/Communication/Group.svg*/}
                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                          <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                            <polygon points="0 0 24 0 24 24 0 24" />
                            <path d="M18,14 C16.3431458,14 15,12.6568542 15,11 C15,9.34314575 16.3431458,8 18,8 C19.6568542,8 21,9.34314575 21,11 C21,12.6568542 19.6568542,14 18,14 Z M9,11 C6.790861,11 5,9.209139 5,7 C5,4.790861 6.790861,3 9,3 C11.209139,3 13,4.790861 13,7 C13,9.209139 11.209139,11 9,11 Z" fill="#000000" fillRule="nonzero" opacity="0.3" />
                            <path d="M17.6011961,15.0006174 C21.0077043,15.0378534 23.7891749,16.7601418 23.9984937,20.4 C24.0069246,20.5466056 23.9984937,21 23.4559499,21 L19.6,21 C19.6,18.7490654 18.8562935,16.6718327 17.6011961,15.0006174 Z M0.00065168429,20.1992055 C0.388258525,15.4265159 4.26191235,13 8.98334134,13 C13.7712164,13 17.7048837,15.2931929 17.9979143,20.2 C18.0095879,20.3954741 17.9979143,21 17.2466999,21 C13.541124,21 8.03472472,21 0.727502227,21 C0.476712155,21 -0.0204617505,20.45918 0.00065168429,20.1992055 Z" fill="#000000" fillRule="nonzero" />
                          </g>
                        </svg>
                        {/*end::Svg Icon*/}
                      </span>
                      <div class="float-right">
                        <div className="checkbox-inline">
                          <label className="checkbox checkbox-square checkbox-primary">
                            <input type="checkbox"
                              onChange={(e) => { setExibir({ ...exibir, usuariosCadastradosPedidoUnico: !exibir.usuariosCadastradosPedidoUnico }) }}
                              checked={exibir?.usuariosCadastradosPedidoUnico == 1 ? true : false} 
                            />
                          <span /></label>
                        </div>
                      </div>
                      <div className="text-dark font-weight-bolder font-size-h2 mt-3">{usuariosCadastradosPedidoUnico}</div>
                      <label className="text-muted font-weight-bold font-size-lg mt-1">Usuários Cadastrados c/ pedido único</label>
                    </div>
                  </div>
                  {/*end::Tiles Widget 12*/}
                  </LoadingOverlay>
                </div>

                <div className="col-md-2">
                  <LoadingOverlay
                    active={caUsuariosInativos}
                    spinner
                    text='Carregando...'
                  >
                  <div className="card card-custom gutter-b" style={{height: '160px'}}>
                    <div className="card-body">
                      <span className="svg-icon svg-icon-3x svg-icon-primary">
                        {/*begin::Svg Icon | path:assets/media/svg/icons/Communication/Group.svg*/}
                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                          <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                            <polygon points="0 0 24 0 24 24 0 24" />
                            <path d="M18,14 C16.3431458,14 15,12.6568542 15,11 C15,9.34314575 16.3431458,8 18,8 C19.6568542,8 21,9.34314575 21,11 C21,12.6568542 19.6568542,14 18,14 Z M9,11 C6.790861,11 5,9.209139 5,7 C5,4.790861 6.790861,3 9,3 C11.209139,3 13,4.790861 13,7 C13,9.209139 11.209139,11 9,11 Z" fill="#000000" fillRule="nonzero" opacity="0.3" />
                            <path d="M17.6011961,15.0006174 C21.0077043,15.0378534 23.7891749,16.7601418 23.9984937,20.4 C24.0069246,20.5466056 23.9984937,21 23.4559499,21 L19.6,21 C19.6,18.7490654 18.8562935,16.6718327 17.6011961,15.0006174 Z M0.00065168429,20.1992055 C0.388258525,15.4265159 4.26191235,13 8.98334134,13 C13.7712164,13 17.7048837,15.2931929 17.9979143,20.2 C18.0095879,20.3954741 17.9979143,21 17.2466999,21 C13.541124,21 8.03472472,21 0.727502227,21 C0.476712155,21 -0.0204617505,20.45918 0.00065168429,20.1992055 Z" fill="#000000" fillRule="nonzero" />
                          </g>
                        </svg>
                        {/*end::Svg Icon*/}
                      </span>
                      <div class="float-right">
                        <div className="checkbox-inline">
                          <label className="checkbox checkbox-square checkbox-primary">
                            <input type="checkbox"
                              onChange={(e) => { setExibir({ ...exibir, usuariosInativos: !exibir.usuariosInativos }) }}
                              checked={exibir?.usuariosInativos == 1 ? true : false} 
                            />
                          <span /></label>
                        </div>
                      </div>
                      <div className="text-dark font-weight-bolder font-size-h2 mt-3">{usuariosInativos}</div>
                      <label className="text-muted font-weight-bold font-size-lg mt-1">Usuários Inativos</label>
                    </div>
                  </div>
                  {/*end::Tiles Widget 12*/}
                  </LoadingOverlay>
                </div>

                <div className="col-md-2">
                  <LoadingOverlay
                    active={caUsuariosCadastradosRiscoChurn}
                    spinner
                    text='Carregando...'
                  >
                  <div className="card card-custom gutter-b" style={{height: '160px'}}>
                    <div className="card-body">
                      <span className="svg-icon svg-icon-3x svg-icon-primary">
                        {/*begin::Svg Icon | path:assets/media/svg/icons/Communication/Group.svg*/}
                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                          <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                            <polygon points="0 0 24 0 24 24 0 24" />
                            <path d="M18,14 C16.3431458,14 15,12.6568542 15,11 C15,9.34314575 16.3431458,8 18,8 C19.6568542,8 21,9.34314575 21,11 C21,12.6568542 19.6568542,14 18,14 Z M9,11 C6.790861,11 5,9.209139 5,7 C5,4.790861 6.790861,3 9,3 C11.209139,3 13,4.790861 13,7 C13,9.209139 11.209139,11 9,11 Z" fill="#000000" fillRule="nonzero" opacity="0.3" />
                            <path d="M17.6011961,15.0006174 C21.0077043,15.0378534 23.7891749,16.7601418 23.9984937,20.4 C24.0069246,20.5466056 23.9984937,21 23.4559499,21 L19.6,21 C19.6,18.7490654 18.8562935,16.6718327 17.6011961,15.0006174 Z M0.00065168429,20.1992055 C0.388258525,15.4265159 4.26191235,13 8.98334134,13 C13.7712164,13 17.7048837,15.2931929 17.9979143,20.2 C18.0095879,20.3954741 17.9979143,21 17.2466999,21 C13.541124,21 8.03472472,21 0.727502227,21 C0.476712155,21 -0.0204617505,20.45918 0.00065168429,20.1992055 Z" fill="#000000" fillRule="nonzero" />
                          </g>
                        </svg>
                        {/*end::Svg Icon*/}
                      </span>
                      <div class="float-right">
                        <div className="checkbox-inline">
                          <label className="checkbox checkbox-square checkbox-primary">
                            <input type="checkbox"
                              onChange={(e) => { setExibir({ ...exibir, usuariosCadastradosRiscoChurn: !exibir.usuariosCadastradosRiscoChurn }) }}
                              checked={exibir?.usuariosCadastradosRiscoChurn == 1 ? true : false} 
                            />
                          <span /></label>
                        </div>
                      </div>
                      <div className="text-dark font-weight-bolder font-size-h2 mt-3">{usuariosCadastradosRiscoChurn}</div>
                      <label className="text-muted font-weight-bold font-size-lg mt-1">Usuários Cadastrados c/ risco de churn</label>
                    </div>
                  </div>
                  {/*end::Tiles Widget 12*/}
                  </LoadingOverlay>
                </div>

                
                
              </div>
              {/*end::Row*/}


              {/*begin::Row*/}
              <div className="row">
                <div className="col-xl-6">
                <LoadingOverlay
                    active={caMaisCidades}
                    spinner
                    text='Carregando...'
                  >
                {/*begin::Base Table Widget 1*/}
                <div className="card card-custom card-stretch gutter-b">
                  {/*begin::Header*/}
                  <div className="card-header border-0 pt-5">
                    <h3 className="card-title align-items-start flex-column">
                      <span className="card-label font-weight-bolder text-dark">Melhores cidades</span>
                      <span className="text-muted mt-3 font-weight-bold font-size-sm">Últimos 30 dias</span>
                    </h3>
                      <div class="float-right">
                        <div className="checkbox-inline">
                          <label className="checkbox checkbox-square checkbox-primary">
                            <input type="checkbox"
                              onChange={(e) => { setExibir({ ...exibir, melhoresCidades: !exibir.melhoresCidades }) }}
                              checked={exibir?.melhoresCidades == 1 ? true : false} 
                            />
                          <span /></label>
                        </div>
                      </div>
                  </div>
                  {/*end::Header*/}
                  {/*begin::Body*/}
                  <div className="card-body pt-2 pb-0 mt-n3">
                      {/*begin::Table*/}
                      <div className="row">
                        
                        <div className="col-md-12">
                          <Doughnut data={data} options={options} />
                        </div>
                      </div>

                        
                      </div>
                      {/*end::Tap pane*/}
                </div>
                {/*end::Base Table Widget 1*/} 
                </LoadingOverlay> 
                </div>
                <div className="col-xl-6">                
                <div className="row">
                  <div className="col-md-6">
                    <LoadingOverlay
                      active={caEstabelecimentosCadastrados}
                      spinner
                      text='Carregando...'
                    >
                    <div className="card card-custom card-body">
                      <div className="checkbox-inline">
                          <label className="checkbox checkbox-square checkbox-primary font-weight-bolder text-dark-75 font-size-h5">
                            <input type="checkbox"
                              onChange={(e) => { setExibir({ ...exibir, estabelecimentosCadastrados: !exibir.estabelecimentosCadastrados }) }}
                              checked={exibir?.estabelecimentosCadastrados == 1 ? true : false} 
                            />
                          <span />Estab. cadastrados</label>
                        </div>
                      <div className="row">
                          <div className="col-sm-8">
                          <span className="text-dark-75 font-weight-bolder font-size-h2 mr-2">{estabelecimentosCadastrados}</span>
                          </div>
                          <div className="col-sm-4">
                          <i className="icon-xl far fa-bookmark text-primary float-right"></i>
                          </div>
                      </div>
                    </div>
                    </LoadingOverlay>
                  </div>
                  <div className="col-md-6">
                    <LoadingOverlay
                      active={caEstabelecimentosAtivos}
                      spinner
                      text='Carregando...'
                    >
                    <div className="card card-custom card-body">
                      <div className="checkbox-inline">
                        <label className="checkbox checkbox-square checkbox-primary font-weight-bolder text-dark-75 font-size-h5">
                          <input type="checkbox"
                            onChange={(e) => { setExibir({ ...exibir, estabelecimentosAtivos: !exibir.estabelecimentosAtivos }) }}
                            checked={exibir?.estabelecimentosAtivos == 1 ? true : false} 
                          />
                        <span />Estabelecimentos ativos</label>
                      </div>
                      <div className="row">
                        <div className="col-sm-8">
                        <span className="text-dark-75 font-weight-bolder font-size-h2 mr-2">{estabelecimentosAtivos}</span>
                        </div>
                        <div className="col-sm-4">
                        <i className="icon-xl far fa-bookmark text-primary float-right"></i>
                        </div>
                      </div>
                    </div>
                    </LoadingOverlay>
                  </div>
                </div>

                <div className="row mt-10">
                  <div className="col-md-6">
                    <LoadingOverlay
                      active={caTotalPedidos}
                      spinner
                      text='Carregando...'
                    >
                    <div className="card card-custom card-body">
                      <div className="checkbox-inline">
                          <label className="checkbox checkbox-square checkbox-primary font-weight-bolder text-dark-75 font-size-h5">
                            <input type="checkbox"
                              onChange={(e) => { setExibir({ ...exibir, totalPedidos: !exibir.totalPedidos }) }}
                              checked={exibir?.totalPedidos == 1 ? true : false} 
                            />
                          <span />Total de pedidos</label>
                        </div>
                      <div className="row">
                          <div className="col-sm-8">
                          <span className="text-dark-75 font-weight-bolder font-size-h2 mr-2">{totalPedidos}</span>
                          </div>
                          <div className="col-sm-4">
                          <i className="icon-xl far fa-bookmark text-primary float-right"></i>
                          </div>
                      </div>
                    </div>
                    </LoadingOverlay>
                  </div>
                  <div className="col-md-6">
                    <LoadingOverlay
                      active={caPedidosCanceados}
                      spinner
                      text='Carregando...'
                    >
                    <div className="card card-custom card-body">
                      <div className="checkbox-inline">
                          <label className="checkbox checkbox-square checkbox-primary font-weight-bolder text-dark-75 font-size-h5">
                            <input type="checkbox"
                              onChange={(e) => { setExibir({ ...exibir, pedidosCancelados: !exibir.pedidosCancelados }) }}
                              checked={exibir?.pedidosCancelados == 1 ? true : false} 
                            />
                          <span />Pedidos cancelados</label>
                        </div>
                    <div className="row">
                        <div className="col-sm-8">
                        <span className="text-dark-75 font-weight-bolder font-size-h2 mr-2">{pedidosCancelados}</span>
                        </div>
                        <div className="col-sm-4">
                        <i className="icon-xl far fa-bookmark text-primary float-right"></i>
                        </div>
                    </div>
                    </div>
                    </LoadingOverlay>
                  </div>
                </div>
                {/*end::Base Table Widget 1*/} 
                  
                </div>
              </div>
              {/*end::Row*/}

              {/*begin::Row*/}
              <div className="row">
                <div className="col-xl-6">
                <LoadingOverlay
                    active={caEvolutivoPedidos}
                    spinner
                    text='Carregando...'
                  >
                {/*begin::Base Table Widget 1*/}
                <div className="card card-custom card-stretch gutter-b">
                  {/*begin::Header*/}
                  <div className="card-header border-0 pt-5">
                    <h3 className="card-title align-items-start flex-column">
                      <span className="card-label font-weight-bolder text-dark">Evolutivo de Pedidos</span>
                    </h3>
                    <div class="float-right">
                        <div className="checkbox-inline">
                          <label className="checkbox checkbox-square checkbox-primary">
                            <input type="checkbox"
                              onChange={(e) => { setExibir({ ...exibir, evolutivoPedidos: !exibir.evolutivoPedidos }) }}
                              checked={exibir?.evolutivoPedidos == 1 ? true : false} 
                            />
                          <span /></label>
                        </div>
                      </div>
                  </div>
                  {/*end::Header*/}
                  {/*begin::Body*/}
                  <div className="card-body pt-2 pb-0 mt-n3">
                      {/*begin::Table*/}
                      <div className="row">
                        
                        <div className="col-md-12">
                          <Line data={dataEvolutivo} legend={legend} options={optionsEvolutivo} />
                        </div>

                      </div>

                        
                      </div>
                      {/*end::Tap pane*/}
                </div>
                {/*end::Base Table Widget 1*/} 
                </LoadingOverlay> 
                </div>
                <div className="col-xl-6">                
                  <LoadingOverlay
                      active={caValorTransacionados}
                      spinner
                      text='Carregando...'
                    >
                  {/*begin::Base Table Widget 1*/}
                  <div className="card card-custom card-stretch gutter-b">
                    {/*begin::Header*/}
                    <div className="card-header border-0 pt-5">
                      <h3 className="card-title align-items-start flex-column">
                        <span className="card-label font-weight-bolder text-dark">Valor transacionado</span>
                      </h3>
                      <div class="float-right">
                        <div className="checkbox-inline">
                          <label className="checkbox checkbox-square checkbox-primary">
                            <input type="checkbox"
                              onChange={(e) => { setExibir({ ...exibir, valorTransacionados: !exibir.valorTransacionados }) }}
                              checked={exibir?.valorTransacionados == 1 ? true : false} 
                            />
                          <span /></label>
                        </div>
                      </div>
                    </div>
                    {/*end::Header*/}
                    {/*begin::Body*/}
                    <div className="card-body pt-2 pb-0 mt-n3">
                        {/*begin::Table*/}
                        <div className="row">
                          
                          <div className="col-md-12">
                            <Line data={dataTransacionado} legend={legendTransacionado} options={optionsTransacionado} />
                          </div>

                        </div>

                          
                        </div>
                        {/*end::Tap pane*/}
                  </div>
                  {/*end::Base Table Widget 1*/} 
                  </LoadingOverlay> 
                </div>
              </div>


              {/*begin::Row*/}
              <div className="row">
                <div className="col-xl-4">
                <LoadingOverlay
                    active={caEvolutivoUsuarioCadastro}
                    spinner
                    text='Carregando...'
                  >
                {/*begin::Base Table Widget 1*/}
                <div className="card card-custom card-stretch gutter-b">
                  {/*begin::Header*/}
                  <div className="card-header border-0 pt-5">  
                    <div class="card-title align-items-start flex-column">
                      <div className="checkbox-inline">
                        <label className="checkbox checkbox-square checkbox-primary h4 card-label font-weight-bolder text-dark">
                          <input type="checkbox"
                            onChange={(e) => { setExibir({ ...exibir, evolutivoUsuarioCadastrado: !exibir.evolutivoUsuarioCadastrado }) }}
                            checked={exibir?.evolutivoUsuarioCadastrado == 1 ? true : false} 
                          />
                        <span />Evolutivo Usuários cadastrados (12 meses)</label>
                      </div>
                    </div>                    
                  </div>
                  {/*end::Header*/}
                  {/*begin::Body*/}
                  <div className="card-body pt-2 pb-0 mt-n3">
                      {/*begin::Table*/}
                      <div className="row">
                        
                        <div className="col-md-12">
                          <Line data={dataUsuarioCadastrado} legend={legendUsuarioCadastrado} options={optionsUsuarioCadastrado} />
                        </div>

                      </div>

                        
                      </div>
                      {/*end::Tap pane*/}
                </div>
                {/*end::Base Table Widget 1*/} 
                </LoadingOverlay> 
                </div>
                <div className="col-xl-4">                
                  <LoadingOverlay
                      active={caEvolutivoPedUsuarioCadastro}
                      spinner
                      text='Carregando...'
                    >
                  {/*begin::Base Table Widget 1*/}
                  <div className="card card-custom card-stretch gutter-b">
                    {/*begin::Header*/}
                    <div className="card-header border-0 pt-5">
                    <div class="card-title align-items-start flex-column">
                      <div className="checkbox-inline">
                        <label className="checkbox checkbox-square checkbox-primary h4 card-label font-weight-bolder text-dark">
                          <input type="checkbox"
                            onChange={(e) => { setExibir({ ...exibir, evolutivoUsuarioCadastradoCPedido: !exibir.evolutivoUsuarioCadastradoCPedido }) }}
                            checked={exibir?.evolutivoUsuarioCadastradoCPedido == 1 ? true : false} 
                          />
                        <span />Evolutivo de Usuários cadastrados c/ pedido (12 meses)</label>
                      </div>
                    </div>
                    </div>
                    {/*end::Header*/}
                    {/*begin::Body*/}
                    <div className="card-body pt-2 pb-0 mt-n3">
                        {/*begin::Table*/}
                        <div className="row">
                          
                          <div className="col-md-12">
                            <Line data={dataPedUsuarioCadastrado} legend={legendPedUsuarioCadastrado} options={optionsPedUsuarioCadastrado} />
                          </div>

                        </div>

                          
                        </div>
                        {/*end::Tap pane*/}
                  </div>
                  {/*end::Base Table Widget 1*/} 
                  </LoadingOverlay> 
                </div>

                <div className="col-xl-4">                
                  <LoadingOverlay
                      active={caEvolutivoSPedUsuarioCadastro}
                      spinner
                      text='Carregando...'
                    >
                  {/*begin::Base Table Widget 1*/}
                  <div className="card card-custom card-stretch gutter-b">
                    {/*begin::Header*/}
                    <div className="card-header border-0 pt-5">
                    <div className="checkbox-inline">
                        <label className="checkbox checkbox-square checkbox-primary h4 card-label font-weight-bolder text-dark">
                          <input type="checkbox"
                            onChange={(e) => { setExibir({ ...exibir, evolutivoUsuarioCadastradoSPedido: !exibir.evolutivoUsuarioCadastradoSPedido }) }}
                            checked={exibir?.evolutivoUsuarioCadastradoSPedido == 1 ? true : false} 
                          />
                        <span />Usuários cadastrados s/ pedido</label>
                      </div>
                    </div>
                    {/*end::Header*/}
                    {/*begin::Body*/}
                    <div className="card-body pt-2 pb-0 mt-n3">
                        {/*begin::Table*/}
                        <div className="row">
                          
                          <div className="col-md-12">
                            <Line data={dataSPedUsuarioCadastrado} legend={legendSPedUsuarioCadastrado} options={optionsSPedUsuarioCadastrado} />
                          </div>

                        </div>

                          
                        </div>
                        {/*end::Tap pane*/}
                  </div>
                  {/*end::Base Table Widget 1*/} 
                  </LoadingOverlay> 
                </div>
              </div>

              {/*begin::Row*/}
              <div className="row">
                <div className="col-xl-2">
                  <LoadingOverlay
                      active={caTicketMedio}
                      spinner
                      text='Carregando...'
                    >
                    <div className="card card-custom card-body">
                      <div className="checkbox-inline">
                          <label className="checkbox checkbox-square checkbox-primary font-weight-bolder text-dark-75 font-size-h6">
                            <input type="checkbox"
                              onChange={(e) => { setExibir({ ...exibir, ticketMedio: !exibir.ticketMedio }) }}
                              checked={exibir?.ticketMedio == 1 ? true : false} 
                            />
                          <span />Ticket médio (mês atual)</label>
                        </div>
                    <div className="row">
                        <div className="col-sm-8">
                        <span className="text-dark-75 font-weight-bolder font-size-h2 mr-2">{ticketMedio}</span>
                        </div>
                        <div className="col-sm-4">
                        <i className="icon-xl fas fa-receipt text-primary float-right"></i>
                        </div>
                    </div>
                    </div>
                    </LoadingOverlay> 
                </div>
                <div className="col-xl-5">                
                  <LoadingOverlay
                      active={caEvolutivoTicketMedio}
                      spinner
                      text='Carregando...'
                    >
                  {/*begin::Base Table Widget 1*/}
                  <div className="card card-custom card-stretch gutter-b">
                    {/*begin::Header*/}
                    <div className="card-header border-0 pt-5">
                    <div className="checkbox-inline">
                        <label className="checkbox checkbox-square checkbox-primary h4 card-label font-weight-bolder text-dark">
                          <input type="checkbox"
                            onChange={(e) => { setExibir({ ...exibir, evolutivoTicketMedio: !exibir.evolutivoTicketMedio }) }}
                            checked={exibir?.evolutivoTicketMedio == 1 ? true : false} 
                          />
                        <span />Evolutivo Ticket Médio (12 meses)</label>
                      </div>
                    </div>
                    {/*end::Header*/}
                    {/*begin::Body*/}
                    <div className="card-body pt-2 pb-0 mt-n3">
                        {/*begin::Table*/}
                        <div className="row">
                          
                          <div className="col-md-12">
                            <Line data={dataEvolutivoTicketMedio} legend={legendEvolutivoTicketMedio} options={optionsEvolutivoTicketMedio} />
                          </div>

                        </div>                          
                        </div>
                        {/*end::Tap pane*/}
                  </div>
                  {/*end::Base Table Widget 1*/} 
                  </LoadingOverlay> 
                </div>

                <div className="col-xl-5">                
                  <LoadingOverlay
                      active={caEvolutivoConversao}
                      spinner
                      text='Carregando...'
                    >
                  {/*begin::Base Table Widget 1*/}
                  <div className="card card-custom card-stretch gutter-b">
                    {/*begin::Header*/}
                    <div className="card-header border-0 pt-5">
                    <div className="checkbox-inline">
                        <label className="checkbox checkbox-square checkbox-primary h4 card-label font-weight-bolder text-dark">
                          <input type="checkbox"
                            onChange={(e) => { setExibir({ ...exibir, evolutivoConversao: !exibir.evolutivoConversao }) }}
                            checked={exibir?.evolutivoConversao == 1 ? true : false} 
                          />
                        <span />Evolutivo de Conversão</label>
                      </div>
                    </div>
                    {/*end::Header*/}
                    {/*begin::Body*/}
                    <div className="card-body pt-2 pb-0 mt-n3">
                        {/*begin::Table*/}
                        <div className="row">
                          
                          <div className="col-md-12">
                            <Line data={dataEvolutivoConversao} legend={legendEvolutivoConversao} options={optionsEvolutivoConversao} />
                          </div>

                        </div>

                          
                        </div>
                        {/*end::Tap pane*/}
                  </div>
                  {/*end::Base Table Widget 1*/} 
                  </LoadingOverlay> 
                </div>
              </div>


              {/*begin::Row*/}
              {/*<div className="row">
                <div className="col-xl-2">
                  <LoadingOverlay
                      active={caFrequencia}
                      spinner
                      text='Carregando...'
                    >
                    <div className="card card-custom card-body">
                    <span className="font-weight-bolder text-dark-75 font-size-h5 mb-2">Frequência de pedidos no primeiro mês</span>
                    <div className="row">
                        <div className="col-sm-8">
                        <span className="text-dark-75 font-weight-bolder font-size-h2 mr-2">{frequencia}</span>
                        </div>
                        <div className="col-sm-4">
                        <i className="icon-xl fas fa-receipt text-primary float-right"></i>
                        </div>
                    </div>
                    </div>
                    </LoadingOverlay> 
                </div>
                <div className="col-xl-5">                
                  <LoadingOverlay
                      active={caFrequenciaPedido}
                      spinner
                      text='Carregando...'
                    >
                  {/*begin::Base Table Widget 1*/}
                  {/*<div className="card card-custom card-stretch gutter-b">
                    {/*begin::Header*/}
                    {/*<div className="card-header border-0 pt-5">
                      <h3 className="card-title align-items-start flex-column">
                        <span className="card-label font-weight-bolder text-dark">Frequência de pedidos no primeiro mês (por mês)</span>
                      </h3>
                    </div>
                    {/*end::Header*/}
                    {/*begin::Body*/}
                    {/*<div className="card-body pt-2 pb-0 mt-n3">
                        {/*begin::Table*/}
                        {/*<div className="row">
                          
                          <div className="col-md-12">
                            <Line data={dataFrequenciaPedido} legend={legendFrequenciaPedido} options={optionsFrequenciaPedido} />
                          </div>

                        </div>                          
                        </div>
                        {/*end::Tap pane*/}
                  {/*</div>
                  {/*end::Base Table Widget 1*/} 
                  {/*</LoadingOverlay> 
                </div> *

                <div className="col-xl-5">                
                  <LoadingOverlay
                      active={caEvolutivoRetencao}
                      spinner
                      text='Carregando...'
                    >
                  {/*begin::Base Table Widget 1*/}
                  {/*<div className="card card-custom card-stretch gutter-b">
                    {/*begin::Header*/}
                    {/*<div className="card-header border-0 pt-5">
                      <h3 className="card-title align-items-start flex-column">
                        <span className="card-label font-weight-bolder text-dark">Evolutivo de Retenção</span>
                      </h3>
                    </div>
                    {/*end::Header*/}
                    {/*begin::Body*/}
                    {/*<div className="card-body pt-2 pb-0 mt-n3">
                        {/*begin::Table*/}
                        {/*<div className="row">
                          
                          <div className="col-md-12">
                            <Line data={dataEvolutivoRetencao} legend={legendEvolutivoRetencao} options={optionsEvolutivoRetencao} />
                          </div>

                        </div>
                         
                        </div>
                        {/*end::Tap pane*/}
                  {/*</div>
                  {/*end::Base Table Widget 1*/} 
                 {/* </LoadingOverlay> 
                </div>
              </div>
                            
              {/*end::Dashboard*/}
          </div>
          }
          
          <div className="tab-pane fade"  id="profile" role="tabpanel" aria-labelledby="profile-tab">    
            <Cidade />
          </div>

          <div className="tab-pane fade"  id="contact" role="tabpanel" aria-labelledby="contact-tab">    
            <Restaurante />
          </div>

          <div className="tab-pane fade"  id="rep" role="tabpanel" aria-labelledby="rep-tab">    
            <Representante />
          </div>
          
        </div>

      </div>
    </div>
    {/*end::Example*/}
  </div>
</div>
{/*end::Card*/}



            
            </div>
            {/*end::Container*/}
          </div>
          {/*end::Entry*/}
        </div>
        {/*end::Content*/}
        {/*begin::Footer*/}
        <div className="footer bg-white py-4 d-flex flex-lg-column" id="kt_footer">
          {/*begin::Container*/}
          <div className="container d-flex flex-column flex-md-row align-items-center justify-content-between">
            {/*begin::Copyright*/}
            <div className="text-dark order-2 order-md-1">
              <span className="text-muted font-weight-bold mr-2">2022©</span>
              <a href="http://keenthemes.com/metronic" target="_blank" className="text-dark-75 text-hover-primary" rel="noreferrer">Aiboo</a>
            </div>
            {/*end::Copyright*/}
            {/*begin::Nav*/}
            <div className="nav nav-dark order-1 order-md-2">
              <a href="http://keenthemes.com/metronic" target="_blank" className="nav-link pr-3 pl-0" rel="noreferrer">About</a>
              <a href="http://keenthemes.com/metronic" target="_blank" className="nav-link px-3" rel="noreferrer">Team</a>
              <a href="http://keenthemes.com/metronic" target="_blank" className="nav-link pl-3 pr-0" rel="noreferrer">Contact</a>
            </div>
            {/*end::Nav*/}
          </div>
          {/*end::Container*/}
        </div>
        {/*end::Footer*/}
        {/*end::Wrapper*/}
        {/*end::Page*/}
        {/*end::Main*/}

              <Modal size={'lg'} centered show={modal} onHide={() => fecharModal()}>
                <Modal.Header>
                <Modal.Title>Estabelecimento sem movimentação</Modal.Title>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={e => {fecharModal()}}>
                      <i aria-hidden="true" className="ki ki-close" />
                    </button>
                </Modal.Header> 

               {/*begin::Table*/}
                <div className="ml-5 mr-5">
                  <table className="table table-borderless table-vertical-center">
                    <thead>
                      <tr>
                        <th className="p-0 w-50px" />
                        <th className="p-0 min-w-200px" />
                        <th className="p-0 min-w-140px" />
                      </tr>
                    </thead>
                    <tbody>
                    { estSemCompras.map((item) => 
                      <tr>
                        <th className="pl-0 py-5">
                          <div className="symbol symbol-50 symbol-light mr-2">
                            <span className="symbol-label">
                              <img src={item.logotipo} className="h-100 w-100 align-self-center" alt="" />
                            </span>
                          </div>
                        </th>
                        <td className="py-6 pl-0">
                          <span href="#" className="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg">[{item.id_estabelecimento}] - {item.nome}</span>
                          <span className="text-muted font-weight-bold d-block">{item.nome_cidade} - {item.estado}</span>
                        </td>
                        <td>
                          <div className="d-flex flex-column w-100 mr-2">
                            <div className="text-dark text-right mb-1 font-size-lg">
                              {item.data} - (Dias )
                            </div>
                          </div>
                        </td>
                      </tr>
                    )}
                    </tbody>
                  </table>
                </div>
                {/*end::Table*/}

                <Modal.Footer>
                  <button type="button" onClick={e => { fecharModal(); }} className="btn btn-light-primary font-weight-bold" data-dismiss="modal"><i className="fas fa-door-open" />Fechar</button>
                </Modal.Footer>
              </Modal>

              <Modal size={'lg'} centered show={modalCancelamento} onHide={() => fecharModalCancelamento()}>
                <Modal.Header>
                <Modal.Title>Cancelamento de pedidos</Modal.Title>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={e => {fecharModalCancelamento()}}>
                      <i aria-hidden="true" className="ki ki-close" />
                    </button>
                </Modal.Header> 

               {/*begin::Table*/}
                <div className="ml-5 mr-5">
                  <table className="table table-borderless table-vertical-center">
                    <thead>
                      <tr>
                        <th className="p-0 w-50px" />
                        <th className="p-0 min-w-200px" />
                        <th className="p-0 min-w-140px" />
                      </tr>
                    </thead>
                    <tbody>
                    { cancelamento.map((item) => 
                      <tr>
                        <th className="pl-0 py-5">
                          <div className="symbol symbol-50 symbol-light mr-2">
                            <span className="symbol-label">
                              <img src={item.logotipo} className="h-100 w-100 align-self-center" alt="" />
                            </span>
                          </div>
                        </th>
                        <td className="py-6 pl-0">
                          <Link to='/estabelecimentos/estabelecimentos' 
                                className="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg" 
                                onClick={e => { redirecionaEst(item.id_estabelecimento) }}>[{item.id_estabelecimento}] - {item.nome}</Link>
                          <span className="text-muted font-weight-bold d-block">Efetivadas: {item.Efetivadas} - Canceladas {item.Canceladas} - <label className="text-primary">Taxa: <b>{item.porcetagem}%</b></label></span>
                        </td>
                        <td>
                          <div className="d-flex flex-column w-100 mr-2">
                            <div className="text-dark text-right mb-1 font-size-lg">
                              {item.data}
                            </div>
                          </div>
                        </td>
                      </tr>
                    )}
                    </tbody>
                  </table>
                </div>
                {/*end::Table*/}

                <Modal.Footer>
                  <button type="button" onClick={e => { fecharModalCancelamento(); }} className="btn btn-light-primary font-weight-bold" data-dismiss="modal"><i className="fas fa-door-open" />Fechar</button>
                </Modal.Footer>
              </Modal>    
            </>
        );
    }
    
    export default withRouter(Dashbord);                  
import React, { useState, useEffect, useCallback } from 'react';
import Api from '../../../services/api';
import { withRouter } from "react-router";

import Swal from "sweetalert2";
import LoadingOverlay from 'react-loading-overlay';

import DataTable from 'react-data-table-component';
import Select from 'react-select';

import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import pt from "date-fns/locale/pt-BR"; 
registerLocale("pt-BR", pt);

const Categorias = () => {

  const [ avaliacoes, setAvaliacoes ] = useState([]);
  const [ estabelecimentosSelect, setEstabelecimentosSelect ] = useState([]);
  const [ caProcessa, setCaProcessa ] = useState(false);
  const [ buscaPedido, setBuscaPedido ] = useState();
  const [ buscaHash, setBuscaHash ] = useState();
  const [ buscaEstabelecimento, setBuscaEstabelecimento ] = useState();
  const [ buscaUsuario, setBuscaUsuario ] = useState();
  const [ buscaStatus, setBuscaStatus ] = useState();
  // const [ modal, setModal ] = useState(false);
  let acesso = JSON.parse(localStorage.getItem('@ADM-Aiboo/perfil'));

  // function fecharModal(){
  //   setModal(false);
  // }

  const data = avaliacoes;
  const columns = [
    {
      name: <th>Pedido</th>,
      selector: 'id_pedido',
      sortable: true,
      width: '8%',
      center: true,
    },
    {
      name: <th>Nota Comida</th>,
      selector: 'nota_comida',
      width: '5%',
      sortable: true,
      center: true,
    },
    {
      name: <th>Nota Entrega</th>,
      selector: 'nota_entrega',
      width: '5%',
      sortable: true,
      center: true,
    },
    {
      name: <th>Nota Geral</th>,
      selector: 'nota_geral',
      width: '5%',
      sortable: true,
      center: true,
    },
    {
      name: <th>Nota AIBOO / Com Aiboo</th>,
      selector: 'nota_aiboo',
      width: '5%',
      sortable: true,
      center: true,
    },
    {
      name: <th>Comentário</th>,
      selector: 'comentario_usuario',
      width: '18%',
      wrap: true, 
      sortable: true,
    },
    {
      name: <th>Status avaliação</th>,
      selector: 'status_avaliacao',
      width: '12%',
      sortable: true,
      cell: row => { if (row.status_avaliacao == "CC") {return <span class="label label-success label-inline mr-2">Computado</span>}
        else {return <span class="label label-danger label-inline mr-2">Não Computado</span>}
      }
    },
    {
      name: <th>Hash</th>,
      selector: 'hash_pedido',
      width: '7%',
      sortable: true,
    },
    {
      name: <th>Usuário</th>,
      selector: 'nome_usuario',
      width: '12%',
      wrap: true,
      sortable: true,
    },
    {
      name: <th>Estabelecimento</th>,
      selector: 'nome_estabelecimento',
      width: '15%',
      wrap: true,
      sortable: true,
    },
    acesso.est_ger_ava_ei === "1" &&
    {
      name: <th>Ações</th>,
      cell: (row) => 
      { return (<>
      <div class="dropdown dropdown-inline">
      <button type="button" class="btn btn-light-primary btn-icon btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <i class="ki ki-bold-more-ver"></i>
      </button>
      <div class="dropdown-menu">
        <span class="dropdown-item" onClick={e => { excluir(row) }}>Excluir</span>
      </div>
    </div>
    </>)
      },
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      right: true,
    },
  ];

  function getEstabelecimentoSelect() {
    var data = {
      representante: localStorage.getItem('@ADM-Aiboo/nivel') == 'R' ? localStorage.getItem('@ADM-Aiboo/id_representante') : ''
    }

    Api.post("estabelecimentos/listaNomeEstabelecimento", data).then(rps => {
      var res = rps.data.obj;
      var nRes = [];
      nRes[nRes.length] = { value: '', label: 'Selecione...' };
      res.forEach(e => {
        nRes[nRes.length] = {value: e.id_estabelecimento, label: e.id_estabelecimento + ' - ' + e.nome};
      })
      setEstabelecimentosSelect(nRes);
    })
  }
  
  const filtrar = useCallback(() => {
    setCaProcessa(true); 
      var data = {
        hash: buscaHash,
        pedido: buscaPedido,
        usuario: buscaUsuario,
        estabelecimento: buscaEstabelecimento,
        status: buscaStatus,
        representante: localStorage.getItem('@ADM-Aiboo/nivel') == 'R' ? localStorage.getItem('@ADM-Aiboo/id_representante') : '' 
      };
    
      Api.post('estabelecimentosavaliacoes/filtrar', data).then((rps) => {

        if(rps.data.status==false){
          Swal.fire({
            title: "Atenção!",
            icon: "info",
            html: rps.data.erro,
            showCloseButton: true,
            showCancelButton: false,
          });
        } else {
          setAvaliacoes(rps.data.obj);
        }
      setCaProcessa(false); 
    });
  }, [buscaEstabelecimento, buscaHash, buscaPedido, buscaStatus, buscaUsuario]);

  function excluir(avaliacao) {
    var av = JSON.parse(JSON.stringify(avaliacao));

    setCaProcessa(true);

    Api.post('estabelecimentosavaliacoes/excluir', av).then(rps => {
      if (rps.data.status === true) {
        Swal.fire({
          title: "Confirmação!",
          icon: "info",
          html: rps.data.mensagem,
          showCloseButton: true,
          showCancelButton: false,
        });
        filtrar();
        setCaProcessa(false);
      }
    });   
  }

  useEffect(() => {
    filtrar();
    getEstabelecimentoSelect();
  }, [filtrar]);
    
    return (
        <>
<div>

<div className="subheader espaco-header-pedidos subheader-transparent" id="kt_subheader">
          <div className="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
            {/*begin::Details*/}
            <div className="d-flex align-items-center flex-wrap mr-2">
              {/*begin::Title*/}
              <h5 className="text-dark font-weight-bold mt-2 mb-2 mr-5">Gerenciar Avaliações</h5>
              {/*end::Title*/}
              {/*begin::Separator*/}
              <div className="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200" />
              {/*end::Separator*/}
            </div>
            {/*end::Details*/}
          </div>
        </div>
        {/*begin::Entry*/}
        <div className="d-flex flex-column-fluid">
          {/*begin::Container*/}
          <div className="container">
            <div className="card card-custom">
              <div className="ml-10 mr-10 mt-10">    
                <div className="row">
                <div className="form-group col-md-1">
                  <label>Pedido</label>
                  <input type="text" value={buscaPedido} onChange={e => {setBuscaPedido(e.target.value)}} className="form-control" />
                </div>

                <div className="form-group col-md-3">
                  <label>Usuário</label>
                  <input type="text" value={buscaUsuario} onChange={e => {setBuscaUsuario(e.target.value)}} className="form-control" />
                </div>

                <div className="form-group col-md-1">
                  <label>Hash</label>
                  <input type="text" value={buscaHash} onChange={e => {setBuscaHash(e.target.value)}} className="form-control" />
                </div>

                <div className="form-group col-md-4">
                  <label>Estabelecimento</label>
                  <Select 
                    options={estabelecimentosSelect}
                    placeholder="Selecione..." 
                    defaultValue={estabelecimentosSelect.find(
                      x => x.value === buscaEstabelecimento
                    )}
                    onChange={(e) => { setBuscaEstabelecimento(e.value) }}
                  />
                </div>
                <div className="form-group col-md-2">
                  <label>Status</label>
                  <select className="form-control" value={buscaStatus} onChange={e => {setBuscaStatus(e.target.value)}}>
                    <option value="" selected>Selecione</option>    
                    <option value="CC">Computado</option>    
                    <option value="NC">Não Computado</option>    
                  </select>
                </div>

                  <div className="col-md-1 form-group">
                  <label>&nbsp;</label><br />
                    <button className="btn btn-primary btn-block" onClick={e=>{filtrar()}}>Filtrar</button>
                  </div> 
                </div> 
              </div>

              <LoadingOverlay
                active={caProcessa}
                spinner
                text='Carregando...'
              >                 
                <DataTable
                  title="Lista de Avaliações"
                  columns={columns}
                  data={data}
                  striped="true" 
                  pagination="true"
                />
              </LoadingOverlay>                    
      
                  </div>
                  </div>
                  </div>
                  </div>
                             
        </>
    );
}

export default withRouter(Categorias);                  
const setSessionInStorage = (data) => {
    try {
        sessionStorage.setItem('id_estabelecimento', data.usuario.id_estabelecimento)
        sessionStorage.setItem('id_usuario', data.usuario.id_usuario)
        sessionStorage.setItem('nome', data.usuario.nome)
        sessionStorage.setItem('hash', data.usuario.sessao.hash)
        sessionStorage.setItem('id_sessao', data.usuario.sessao.id_sessao)
        sessionStorage.setItem('url_socket', data.usuario.socket.url)
        sessionStorage.setItem('canal_socket', data.usuario.socket.canal)
        return true;
    } catch (error) {
        console.log('Erro ao armazenar session')
        return error
    }
}

const setLocalStorage = (data, token) => {
    try {
        localStorage.setItem('@ADM-Aiboo/id_estabelecimento', data.id_estabelecimento)
        localStorage.setItem('@ADM-Aiboo/id_usuario', data.id_usuario)
        localStorage.setItem('@ADM-Aiboo/nome', data.nome)
        localStorage.setItem('@ADM-Aiboo/nivel', data.nivel)
        localStorage.setItem('@ADM-Aiboo/perfil', (Array.isArray(data.perfil) && data.perfil.length > 0 ? JSON.stringify(data.perfil[0]) : null))
        localStorage.setItem('@ADM-Aiboo/hash', 'Basic '+token)
        localStorage.setItem('@ADM-Aiboo/token_session', data.sessao.hash)
        localStorage.setItem('@ADM-Aiboo/id_representante', data.id_estabelecimento)
        //localStorage.setItem('id_sessao', data.usuario.sessao.id_sessao)
        //localStorage.setItem('url_socket', data.usuario.socket.url)
        //localStorage.setItem('canal_socket', data.usuario.socket.canal)

        //Impressão - Valores que não podem ser enviados em Branco.
        localStorage.setItem('@ADM-Aiboo/qtdeCopia', '1')
        localStorage.setItem('@ADM-Aiboo/larguraMaximaPagina', '7,0')
        localStorage.setItem('@ADM-Aiboo/saltoLinhaInicio', '0')
        localStorage.setItem('@ADM-Aiboo/margemEsquerda', '0')
        localStorage.setItem('@ADM-Aiboo/margemDireita', '0')
        localStorage.setItem('@ADM-Aiboo/nomeFonte', 'f2')
        localStorage.setItem('@ADM-Aiboo/tamanhoFonte', 'fs16')
        localStorage.setItem('@ADM-Aiboo/impressaoItemAgrupado','false')
        localStorage.setItem('@ADM-Aiboo/impressaoCliente','false')
        localStorage.setItem('@ADM-Aiboo/impressaoEstabelecimento','false')

        return true;
    } catch (error) {
        console.log('Erro ao armazenar session')
        return error
    }
}

export { setSessionInStorage, setLocalStorage} 
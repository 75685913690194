import React from "react";

import Select from "react-select";
import CpfCnpjInput from "react-cpf-cnpj-input";
import { Modal } from "react-bootstrap";

export const RegisterReceiptBankAccount = ({
  modalBanco,
  fecharModalBanco,
  pagOnBancos,
  formCadBanco,
  setFormCadBanco,
  salvarBancoPagOn,
}) => {
  const {
    tipo_conta,
    banco,
    agencia,
    agencia_dig,
    conta,
    nome_titular,
    cnpj_cpf,
  } = formCadBanco;

  return (
    <Modal
      size={"lg"}
      centered
      show={modalBanco}
      onHide={() => fecharModalBanco()}
    >
      <Modal.Header>
        <Modal.Title>Cadastrar Conta</Modal.Title>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={(e) => {
            fecharModalBanco();
          }}
        >
          <i aria-hidden="true" className="ki ki-close" />
        </button>
      </Modal.Header>

      <div className="row row ml-3 mr-3">
        <div className="col-md-12">
          Selecione o tipo da conta
          <br />
          <div className="radio-list">
            <label className="radio text-justify">
              <input
                type="radio"
                onChange={() =>
                  setFormCadBanco((prevState) => ({
                    ...prevState,
                    tipo_conta: "CC",
                  }))
                }
                checked={tipo_conta === "CC"}
              />
              <span></span>
              <label className="font-weight-bolder">Conta Corrente</label>
            </label>
            <label className="radio text-justify">
              <input
                type="radio"
                onChange={() =>
                  setFormCadBanco((prevState) => ({
                    ...prevState,
                    tipo_conta: "CP",
                  }))
                }
                checked={tipo_conta === "CP"}
              />
              <span></span>
              <label className="font-weight-bolder">Conta Poupança</label>
            </label>
          </div>
        </div>
      </div>

      <div className="row row ml-3 mr-3">
        <div className="form-group col-md-12">
          <Select
            placeholder="Selecione o banco"
            options={pagOnBancos}
            defaultValue={pagOnBancos.find((x) => x.value === banco)}
            onChange={(e) => {
              setFormCadBanco((prevState) => ({
                ...prevState,
                banco: e.value,
              }));
            }}
          />
        </div>
      </div>

      <div className="row ml-3 mr-3">
        <div className="form-group col-md-9">
          <label>Agência</label>
          <input
            className="form-control"
            value={agencia}
            onChange={(e) => {
              setFormCadBanco((prevState) => ({
                ...prevState,
                agencia: e.target.value,
              }));
            }}
          />
        </div>

        <div className="form-group col-md-3">
          <label>Dígito (Opcional)</label>
          <input
            className="form-control"
            value={agencia_dig}
            onChange={(e) => {
              setFormCadBanco((prevState) => ({
                ...prevState,
                agencia_dig: e.target.value,
              }));
            }}
          />
        </div>
      </div>

      <div className="row ml-3 mr-3">
        <div className="form-group col-md-12">
          <label>Conta</label>
          <input
            className="form-control"
            value={conta}
            onChange={(e) => {
              setFormCadBanco((prevState) => ({
                ...prevState,
                conta: e.target.value,
              }));
            }}
          />
        </div>
      </div>

      <div className="row ml-3 mr-3">
        <div className="form-group col-md-12">
          <label>Nome do Titular</label>
          <input
            className="form-control"
            value={nome_titular}
            onChange={(e) => {
              setFormCadBanco((prevState) => ({
                ...prevState,
                nome_titular: e.target.value,
              }));
            }}
          />
        </div>
      </div>

      <div className="row ml-3 mr-3">
        <div className="form-group col-md-12">
          <label>CNPJ/CPF</label>
          <CpfCnpjInput
            className="form-control"
            maskField="_"
            value={cnpj_cpf}
            onChange={(e) => {
              setFormCadBanco((prevState) => ({
                ...prevState,
                cnpj_cpf: e.target.value,
              }));
            }}
          />
        </div>
      </div>

      <Modal.Footer>
        <button
          type="button"
          onClick={() => {
            fecharModalBanco();
          }}
          className="btn btn-light-primary font-weight-bold"
          data-dismiss="modal"
        >
          <i className="fas fa-door-open" />
          Fechar
        </button>
        <button
          type="button"
          onClick={() => {
            salvarBancoPagOn();
          }}
          className="btn btn-success font-weight-bold"
        >
          <i className="fas fa-save" />
          Salvar
        </button>
      </Modal.Footer>
    </Modal>
  );
};

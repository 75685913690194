import React, { memo } from "react";

import Swal from "sweetalert2";
import { useToasts } from "react-toast-notifications";
import PerfectScrollbar from "react-perfect-scrollbar";
import DataTable from "react-data-table-component";
import { useWatch } from "react-hook-form";

import api from "../../../../services/api";
import { useMerchantManager } from "../hooks";
import { paymentStatusOptions, statusOptions } from "../initialStates";

const GeneralTabComponent = ({ merchantId, setTrava, logs, dataFatura }) => {
  const { addToast } = useToasts();

  const { setValue, control } = useMerchantManager();

  const [name, paymentStatus, status, createdAt, logo] = useWatch({
    control,
    name: ["nome", "pagamento_status", "status", "data_criacao", "logotipo"],
  });

  const columnsFatura = [
    {
      name: "ID",
      selector: "id_fatura",
      sortable: true,
      width: "10%",
      center: true,
    },
    {
      name: "Período",
      selector: "data_criacao",
      sortable: true,
      width: "20%",
      center: true,
    },
    {
      name: "Vencimento",
      selector: "data_vencimento",
      sortable: true,
      width: "20%",
      center: true,
    },
    {
      name: "Valor",
      selector: "valor_pagar",
      sortable: true,
      width: "20%",
      right: true,
    },
    {
      name: "Situação",
      //selector: 'status',
      sortable: true,
      width: "20%",
      center: true,
      cell: (row) => {
        if (row.status == "AT") {
          return (
            <div className="label label-warning label-inline">
              <b>Em Aberto</b>
            </div>
          );
        } else if (row.status == "FA") {
          return (
            <div className="label label-secondary label-inline">
              <b>Acumulado</b>
            </div>
          );
        } else if (row.status == "PG") {
          return (
            <div className="label label-success label-inline">
              <b>Pago</b>
            </div>
          );
        } else if (row.status == "IN") {
          return (
            <div className="label label-info label-inline">
              <b>Isento</b>
            </div>
          );
        } else if (row.status == "VC") {
          return (
            <div className="label label-danger label-inline">
              <b>Vencido</b>
            </div>
          );
        }
      },
    },
  ];

  function alterarStatusPagamento(id_estabelecimento, newStatus) {
    var data = {
      id_estabelecimento: id_estabelecimento,
      status: newStatus,
    };

    api
      .post("estabelecimentos/ativarSuspenderEstabelecimento", data)
      .then((rps) => {
        if (rps.data.status === true) {
          addToast(rps.data.mensagem, {
            appearance: "success",
            autoDismiss: true,
            autoDismissTimeout: 2000,
          });

          setValue(
            "pagamento_status",
            paymentStatusOptions.find((field) => field.value === newStatus)
          );

          setTrava(true);
        } else {
          Swal.fire({
            title: "Erro!",
            icon: "error",
            html: rps.data.erros,
            showCloseButton: true,
            showCancelButton: false,
          });
        }
      });
  }

  function alterarStatus(id_estabelecimento, newStatus) {
    var data = {
      id_estabelecimento: id_estabelecimento,
      status: newStatus,
    };

    api
      .post("estabelecimentos/ativarInativarEstabelecimento", data)
      .then((rps) => {
        if (rps.data.status === true) {
          addToast(rps.data.mensagem, {
            appearance: "success",
            autoDismiss: true,
            autoDismissTimeout: 2000,
          });

          setValue(
            "status",
            statusOptions.find((field) => field.value === newStatus)
          );

          setTrava(true);
        } else {
          Swal.fire({
            title: "Erro!",
            icon: "error",
            html: rps.data.erros,
            showCloseButton: true,
            showCancelButton: false,
          });
        }
      });
  }

  function painelNovoNovo(idEstabelecimento) {
    var data = {
      idEstabelecimento: idEstabelecimento,
    };

    api.post("estabelecimentos/logarComoNovo", data).then((rps) => {
      if (!rps.data.status) {
        addToast(rps.data.erro, {
          appearance: "error",
          autoDismiss: true,
          autoDismissTimeout: 2000,
        });

        return;
      }

      window.open(
        `${process.env.REACT_APP_PARTNER_PANEL_URL}` +
          rps.data.p1 +
          "/" +
          rps.data.p2,
        "_blank"
      );
    });
  }

  return (
    <div
      className="tab-pane fade"
      id="geral"
      role="tabpanel"
      aria-labelledby="geral"
    >
      <div className="row">
        <div className="from-group col-md-6">
          <div className="row">
            <div className="from-group col-md-4">
              <img alt="logo" src={logo} className="img-thumbnail" />
            </div>
            <div className="from-group col-md-8">
              <h3>{name}</h3>
              <b>Status: </b>
              {paymentStatus?.value == "P" && (
                <>
                  <span className="label label-warning label-inline mr-2">
                    Em Aberto
                  </span>
                </>
              )}
              {paymentStatus?.value == "V" && (
                <>
                  <span className="label label-danger label-inline mr-2">
                    Vencido
                  </span>
                </>
              )}
              {paymentStatus?.value == "O" && (
                <>
                  <span className="label label-success label-inline mr-2">
                    Pago
                  </span>
                </>
              )}
              {paymentStatus?.value == "B" && (
                <>
                  <span className="label label-dark label-inline mr-2">
                    Suspenso
                  </span>
                </>
              )}
              {status?.value == "S" && (
                <>
                  <span className="label label-success label-inline mr-2">
                    Ativo
                  </span>
                </>
              )}
              {status?.value == "N" && (
                <>
                  <span className="label label-danger label-inline mr-2">
                    Inativo
                  </span>
                </>
              )}
              <br />
              <b>Data de cadastro:</b> {createdAt}
              <br />
              <b>Último Login:</b> <br />
              <b>Saldo:</b> <br />
              <br />
              {merchantId != null && (
                <>
                  {paymentStatus?.value == "P" ? (
                    <button
                      type="button"
                      className="btn btn-dark btn-sm mr-1"
                      onClick={(e) => {
                        alterarStatusPagamento(merchantId, "B");
                      }}
                    >
                      <i className="fas fa-lock" />
                      Suspender
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-success btn-sm mr-1"
                      onClick={() => {
                        alterarStatusPagamento(merchantId, "P");
                      }}
                    >
                      <i className="fas fa-lock-open" />
                      Liberar
                    </button>
                  )}
                  {status?.value === "S" ? (
                    <button
                      type="button"
                      className="btn btn-danger btn-sm mr-1"
                      onClick={() => {
                        alterarStatus(merchantId, "N");
                      }}
                    >
                      <i className="fas fa-window-close" />
                      Inativar
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-success btn-sm mr-1"
                      onClick={() => {
                        alterarStatus(merchantId, "S");
                      }}
                    >
                      <i className="fas fa-check-square" />
                      Ativar
                    </button>
                  )}
                  <button
                    type="button"
                    className="btn btn-light btn-sm mr-1"
                    onClick={() => {
                      painelNovoNovo(merchantId);
                    }}
                  >
                    <i className="fas fa-key" />
                    Logar
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="from-group col-md-6">
          <label>Registro de Atividades - Logs</label>
          <PerfectScrollbar style={{ display: "flex", maxHeight: "200px" }}>
            <div className="timeline timeline-1">
              <div className="timeline-sep bg-primary-opacity-20"></div>

              {logs.map((item) => {
                return (
                  <div className="timeline-item" key={item.id_log}>
                    <div className="timeline-label text-center">
                      {item.data}
                    </div>
                    <div className="timeline-badge">
                      {item.evento == "AUTO" && (
                        <i className="flaticon2-sms text-primary"></i>
                      )}
                      {item.evento == "ESTA" && (
                        <i className="flaticon2-paper-plane text-info"></i>
                      )}
                      {item.evento == "BKPD" && (
                        <i className="flaticon2-layers text-warning"></i>
                      )}
                      {item.evento == "FNZD" && (
                        <i className="flaticon2-pin text-success"></i>
                      )}
                    </div>
                    <div className="timeline-content text-muted font-weight-normal">
                      {item.sub_evento} - {item.descricao}
                    </div>
                  </div>
                );
              })}
            </div>
          </PerfectScrollbar>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <DataTable
            title="Faturas"
            columns={columnsFatura}
            data={dataFatura}
            striped
            pagination
          />
        </div>
      </div>
    </div>
  );
};

export const GeneralTab = memo(GeneralTabComponent);

import styled from "styled-components";

export const AddCategoryModalContent = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  padding: 35px;
  background: #ffffff;
  width: 45rem;
  gap: 35px;
  div {
    display: flex;
    justify-content: end;
  }

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 8px;
    width: 140px;
    height: 40px;
    background: #00b333;
    font-size: 16px;
    font-weight: 600;
    color: #ffffff;
  }
`;

export const CategoryInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  label {
    font-size: 16px;
    span {
      color: #ff3333;
    }
  }
  input {
    border: 1px solid #e5eaee;
    border-radius: 12px;
    font-size: 16px;
    height: 45px;
    padding: 15px;
  }
`;

export const SubmitButtonContainer = styled.div`
  label {
    color: #ffffff;
    font-weight: 600;
    margin: 0;
    font-size: 14px;
  }

  span {
    margin: 0 18px 0 0;
  }
`;

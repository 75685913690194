import React from "react";
import { useLocation } from "react-router-dom";

import { useRegisterProductEan } from "./useRegisterProductEan";
import ProductForm from "./components/ProductForm";

function RegisterProductEan() {
  const {
    checkFormMode,
    imagePreview,
    setImagePreview,
    formatCategoriesOptions,
    pageStatus,
    setPageStatus,
    modalVisibility,
    handleToggleCreateProductCategoryModal,
    selectedCategory,
    setSelectedCategory,
    categories,
    setCategories,
    fetchCategories,
    onSubmit,
    isOnEditMode,
    fetchProductFormDataToEdit,
    productToEditData,
    attemptSubmitWithoutCategory,
  } = useRegisterProductEan();

  const location = useLocation();
  const productId = location.state ? location.state.productId : null;

  return (
    <>
      <div>
        <div className="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
          <ProductForm
            productId={productId}
            checkFormMode={checkFormMode}
            imagePreview={imagePreview}
            setImagePreview={setImagePreview}
            formatCategoriesOptions={formatCategoriesOptions}
            pageStatus={pageStatus}
            setPageStatus={setPageStatus}
            categories={categories}
            setCategories={setCategories}
            fetchCategories={fetchCategories}
            modalVisibility={modalVisibility}
            handleToggleCreateProductCategoryModal={
              handleToggleCreateProductCategoryModal
            }
            isOnEditMode={isOnEditMode}
            selectedCategory={selectedCategory}
            setSelectedCategory={setSelectedCategory}
            fetchProductFormDataToEdit={fetchProductFormDataToEdit}
            onSubmit={onSubmit}
            productToEditData={productToEditData}
            attemptSubmitWithoutCategory={attemptSubmitWithoutCategory}
          />
        </div>
      </div>
    </>
  );
}

export default RegisterProductEan;

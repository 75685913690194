import React, { useState, useEffect } from 'react';
import Api from '../../../services/api';
import { withRouter } from "react-router";

import Swal from "sweetalert2";
import { useToasts } from 'react-toast-notifications';
import LoadingOverlay from 'react-loading-overlay';
import Select from 'react-select';

import DataTable from 'react-data-table-component';
import { Modal } from "react-bootstrap";

const ConfImpressao = () => {
  const { addToast } = useToasts();
  const [ configuracoes, setConfiguracoes ] = useState([]);
  const [ caProcessa, setCaProcessa ] = useState(false);
  const [ modal, setModal ] = useState(false);
  const [ formConfiguracoes, setFormConfiguracoes ] = useState({});
  const [ estabelecimentoSelect, setEstabelecimentoSelect ] = useState([]);
  const [ buscaEstabelecimento, setBuscaEstabelecimento ] = useState();

  function fecharModal(){
    setModal(false);
  }

  const data = configuracoes;
  const columns = [
    {
      name: <th>Estabelecimento</th>,
      selector: 'cod',
      sortable: true,
      width: '24%',
      cell: row => { return row.id_estabelecimento + ' - ' +row.nome }
    },
    {
      name: <th>Método</th>,
      selector: 'metodoImpressao',
      width: '10%',
      sortable: true,
      center: true,
      cell: row => { if (row.metodoImpressao == "aiboo") {return <span class="label label-danger label-inline mr-2">Aiboo Printer</span>}
        else if (row.metodoImpressao == "qz") {return <span class="label label-warning label-inline mr-2">QZ Printer</span>}
        else {return <span class="label label-success label-inline mr-2">Nativo</span>} 
      }
    },
    { 
      name: <th>Nome da Fonte</th>,
      selector: 'nomeFonte',
      sortable: true,
      width: '14%',
    },
    {
      name: <th className="text-center">Tamanho da Fonte</th>,
      selector: 'tamanhoFonte',
      sortable: true,
      width: '10%',
      center: true,
    },
    {
      name: <th className="text-center">Largura da Página</th>,
      selector: 'metodoImpressao',
      width: '8%',
      sortable: true,
      center: true,
      cell: row => { if (row.metodoImpressao == "4,7") {return <label>58mm</label>}
        else {return <label>80mm</label>}
      }
    },
    {
      name: <th className="text-center">Margem Esquerda</th>,
      selector: 'margemEsquerda',
      sortable: true,
      width: '8%',
      center: true,
    },
    {
      name: <th className="text-center">Margem Direta</th>,
      selector: 'margemDireita',
      sortable: true,
      width: '8%',
      center: true,
    },
    {
      name: <th>Sincronizado</th>,
      width: '8%',
      sortable: true,
      selector: 'sincronizado',
      center: 'true',
      cell: row => { if (row.status == "S") {return <span class="label label-success label-inline mr-2">Sim</span>}
        else {return <span class="label label-secondary label-inline mr-2">Não</span>}
      }
    },
    {
      name: <th>Ações</th>,
      cell: (row) => 
      { return (<>
      <div class="dropdown dropdown-inline">
      <button type="button" class="btn btn-light-primary btn-icon btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <i class="ki ki-bold-more-ver"></i>
      </button>
      <div class="dropdown-menu">
        <span class="dropdown-item" onClick={e => { editarConfiguracoes(row) }}>Editar</span>
      </div>
    </div>
    </>)
      },
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      right: true,
    },
  ];

   useEffect(() => {
    getEstabelecimentoSelect();
    getListar();
  }, []);

  function getListar() { 
    setCaProcessa(true); 
    Api.get("estabelecimentosimpressao/listar").then(rps => {
      setConfiguracoes(rps.data.obj);
      console.log(rps.data.obj);
      setCaProcessa(false);
    })
  }
  
  function getEstabelecimentoSelect() {
    Api.get("estabelecimentos/listaNomeEstabelecimento").then(rps => {
      var est = rps.data.obj;
      var nEst = [];
      nEst[nEst.length] = {value: '', label: 'Selecione...'};
      est.forEach(e => {
        nEst[nEst.length] = {value: e.id_estabelecimento, label: e.id_estabelecimento + ' - ' + e.nome};
      })
      setEstabelecimentoSelect(nEst);
    })
  }

  function editarConfiguracoes(id_conf){  
    var ca = JSON.parse(JSON.stringify(id_conf));
    ca.acao = "Alterar";

    setFormConfiguracoes(ca);
    setModal(true);            
  }

  function salvar(){
    setCaProcessa(true);
    Api.post('estabelecimentosimpressao/adicionar', formConfiguracoes).then(rps => {
      if (rps.data.status === true) {
        addToast(rps.data.mensagem, {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 2000
        });
        setModal(false);
        setFormConfiguracoes({});
        getListar();
      } else {
        Swal.fire({
          title: "Erro!",
          icon: "error",
          html: rps.data.erros,
          showCloseButton: true,
          showCancelButton: false,
        });
      }
      setCaProcessa(false);
    });
  }

  function filtrar() {
    setCaProcessa(true); 
      var data = {
        id_estabelecimento: buscaEstabelecimento,
      };
    
      Api.post('estabelecimentosimpressao/listar', data).then((rps) => {

        if(rps.data.status==false){
          Swal.fire({
            title: "Atenção!",
            icon: "info",
            html: rps.data.erro,
            showCloseButton: true,
            showCancelButton: false,
          });
        } else {
          setConfiguracoes(rps.data.obj);
        }
      setCaProcessa(false); 
    });
  }
    
    return (
        <>
<div>

<div className="subheader espaco-header-pedidos subheader-transparent" id="kt_subheader">
          <div className="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
            {/*begin::Details*/}
            <div className="d-flex align-items-center flex-wrap mr-2">
              {/*begin::Title*/}
              <h5 className="text-dark font-weight-bold mt-2 mb-2 mr-5">Configurações</h5>
              {/*end::Title*/}
              {/*begin::Separator*/}
              <div className="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200" />
              {/*end::Separator*/}
              {/*begin::Title*/}
              <h5 className="text-dark font-weight-bold mt-2 mb-2 mr-5">Impressão</h5>
              {/*end::Title*/}
            </div>
            {/*end::Details*/}
            {/*begin::Toolbar*/}
            <div className="d-flex align-items-center">

            </div>
            {/*end::Toolbar*/}
          </div>
        </div>
        {/*begin::Entry*/}
        <div className="d-flex flex-column-fluid">
          {/*begin::Container*/}
          <div className="container">
            <div className="card card-custom">
              <div className="ml-10 mr-10 mt-10">    
                <div className="row">
                <div className="form-group col-md-10">
                  <label>Estabelecimento</label>
                    <div>
                    <Select options={estabelecimentoSelect}
                      placeholder="Selecione..." 
                      defaultValue={estabelecimentoSelect.find(
                        x => x.value === buscaEstabelecimento
                      )}
                      onChange={(e) => { setBuscaEstabelecimento(e.value) }}
                    />  
                    </div>
                </div>

                  <div className="col-md-2 form-group">
                  <label>&nbsp;</label><br />
                    <button className="btn btn-primary btn-block" onClick={e=>{filtrar()}}>Filtrar</button>
                  </div> 
                </div> 
              </div>

              <LoadingOverlay
                active={caProcessa}
                spinner
                text='Carregando...'
              >                 
                <DataTable
                  title="Lista de Configurações"
                  columns={columns}
                  data={data}
                  striped="true" 
                  pagination="true"
                />
              </LoadingOverlay>                    
      
                  </div>
                  </div>
                  </div>
                  </div>
                  
      <Modal size={'xl'} show={modal} onHide={() => fecharModal()}>
      <Modal.Header>
      <Modal.Title>Configuração de Impressão</Modal.Title>
          <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={e => {fecharModal()}}>
            <i aria-hidden="true" className="ki ki-close" />
          </button>
      </Modal.Header>
    
                <div className="area-auto-open m-10">
                  <div className="row">
                    <div className="form-group col-md-12">
                      <h4>Impressora Padrão</h4>
                      <input className="form-control" disabled="true"  value={formConfiguracoes.impressora} />
                    </div>
                  </div>

                  <div className="row">
                    <div className="form-group col-md-6">
                    <h4>Quantidade de Cópias</h4>
                    <input className="form-control" value={formConfiguracoes.qtdeCopia} onChange={e => { setFormConfiguracoes({...formConfiguracoes, qtdeCopia: e.target.value }) }} />
                    </div>


                    <div className="form-group col-md-6">
                    <h4>Método de impressão</h4>
                    <select className="form-control" value={formConfiguracoes.metodoImpressao} onChange={(e) => { setFormConfiguracoes({...formConfiguracoes, metodoImpressao: e.target.value }) }} >
                        <option value="aiboo">Aiboo Printer</option>
                        <option value="qz">QZ Printer</option>
                        <option value="nat">Nativo</option>
                     </select>
                    </div>
                  </div>

                  <div className="row">
                    <div className="form-group col-md-6">
                      <h4>Largura Máxima da Página</h4>
                      <select className="form-control" value={formConfiguracoes.larguraMaximaPagina} onChange={(e) => { setFormConfiguracoes({...formConfiguracoes, larguraMaximaPagina: e.target.value }) }}>
                        <option value="4,8">58mm</option>
                        <option value="7,0">80mm</option>
                     </select>
                    </div>

                    <div className="form-group col-md-6">
                      <h4 className="col-md-5">Salto de linhas no Inicio</h4>
                      <input className="form-control" value={formConfiguracoes.saltoLinhaInicio} onChange={(e) => { setFormConfiguracoes({...formConfiguracoes, saltoLinhaInicio: e.target.value }) }} />
                    </div>
                 </div>

                 <div className="row">
                  <div className="form-group col-md-6">
                    <h4>Margem Esquerda</h4>
                    <input className="form-control" value={formConfiguracoes.margemEsquerda} onChange={(e) => { setFormConfiguracoes({...formConfiguracoes, margemEsquerda: e.target.value }) }} />
                  </div>

                  <div className="form-group col-md-6">
                    <h4>Margem Direita</h4>
                    <input className="form-control" value={formConfiguracoes.margemDireita} onChange={(e) => { setFormConfiguracoes({...formConfiguracoes, margemDireita: e.target.value }) }} />
                  </div>
                </div>

                <div className="row">
                  <div className="form-group col-md-6">
                    <h4>Fonte do Texto</h4>
                    <select className="form-control" value={formConfiguracoes.nomeFonte} onChange={(e) => { setFormConfiguracoes({...formConfiguracoes, nomeFonte: e.target.value }) }}>
                      <option value="Arial">Arial</option>
                      <option value="Courier New">Courier New</option>
                      <option value="Lucida Sans">Lucida Sans</option>
                      <option value="Roboto">Roboto</option>
                      <option value="Tahoma">Tahoma</option>
                      <option value="Times New Roman">Times New Roman</option>
                      <option value="Verdana">Verdana</option>
                    </select>
                  </div>

                  <div className="form-group col-md-6">
                    <h4>Tamanho da Fonte</h4>
                      <select className="form-control" value={formConfiguracoes.tamanhoFonte} onChange={(e) => { setFormConfiguracoes({...formConfiguracoes, tamanhoFonte: e.target.value }) }}>
                        <option value="8">8</option>
                        <option value="10">10</option>
                        <option value="12">12</option>
                        <option value="14">14</option>
                        <option value="16">16</option>
                      </select>
                    </div>
                  </div>

                  <hr />
                  <h4>Layout de impressão Caixa</h4>
                  <div className="form-group">
                    <select className="form-control" value={formConfiguracoes.impressaoLayout} onChange={(e) => { setFormConfiguracoes({...formConfiguracoes, impressaoLayout: e.target.value }) }}>
                      <option value="1">1 - Completo</option>
                      <option value="2">2 - Resumido</option>
                    </select>
                  </div>
                  <hr />
                  <div className="mt-10">
                    <h4>Impressão Caixa</h4>
                    <p>Escolha a(s) impressora(s) que deseja que a impressão completa do pedido seja realizada.</p>
                    <div className="form-group area-check-print">
                    
                  </div>
                  </div>
                  <hr />
                  <div className="mt-10">
                    <h4>Impressão e Aceite Automático</h4>
                    <div className="alert alert-secondary" role="alert">
                      Essa opção permite que assim que um pedido chegar, automaticamente ele seja aceito e impresso pelo sistema.
                      <div className="checkbox-inline mt-5">
                        <label className="checkbox checkbox-square checkbox-primary m-t-3">
                          <input type="checkbox" checked={formConfiguracoes.autoAceiteImprimir} onChange={e=>{ setFormConfiguracoes({...formConfiguracoes, autoAceiteImprimir: e.target.checked }) }} />
                          Desejo utilizar o auto imprimir e aceitar.
                          <span /></label>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="mt-10">
                    <h4>Impressão Produção</h4>
                    <div className="alert alert-secondary" role="alert">
                      A impressão produção permite a configuração para que cada categoria dos produtos da loja seja impressora em determinada impressora. O ticket da impressão de produção possui informações necessárias à produção.
                      <div className="checkbox-inline mt-5">
                        <label className="checkbox checkbox-square checkbox-primary m-t-4">
                          <input type="checkbox" checked={formConfiguracoes.impressaoProducao} onChange={e=>{ setFormConfiguracoes({...formConfiguracoes, impressaoProducao: e.target.checked }) }} />
                          Desejo utilizar a impressão produção
                          <span /></label>
                          <label className="checkbox checkbox-square checkbox-primary m-t-4">
                          <input type="checkbox" checked={formConfiguracoes.impressaoEstabelecimento} onChange={e=>{ setFormConfiguracoes({...formConfiguracoes, impressaoEstabelecimento: e.target.checked }) }} />
                          Desejo apresentar o nome do estabelecimento
                          <span /></label>
                          <label className="checkbox checkbox-square checkbox-primary m-t-4">
                          <input type="checkbox" checked={formConfiguracoes.impressaoCliente} onChange={e=>{ setFormConfiguracoes({...formConfiguracoes, impressaoCliente: e.target.checked }) }} />
                          Desejo apresentar o nome do cliente
                          <span /></label>
                          <label className="checkbox checkbox-square checkbox-primary m-t-4">
                          <input type="checkbox" checked={formConfiguracoes.impressaoItemAgrupado} onChange={e=>{ setFormConfiguracoes({...formConfiguracoes, impressaoItemAgrupado: e.target.checked }) }} />
                          Desejo imprimir os itens desagrupado
                          <span /></label>
                      </div>
                    </div>
      
                  </div>
                </div>  
      
      <Modal.Footer>
        <button type="button" onClick={e => { fecharModal(); }} className="btn btn-light-primary font-weight-bold" data-dismiss="modal">Fechar</button>
        <button type="button" onClick={e => { salvar(); }} className="btn btn-success font-weight-bold">Salvar</button>
      </Modal.Footer>
    </Modal>        
                 
        </>
    );
}

export default withRouter(ConfImpressao);                  
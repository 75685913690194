import React, { memo, useRef } from "react";

import axios from "axios";
import Swal from "sweetalert2";
import { useToasts } from "react-toast-notifications";
import { useWatch } from "react-hook-form";

import api from "../../../../services/api";
import { useMerchantManager } from "../hooks";

const ImageTabComponent = ({ merchantId, envioImagem }) => {
  const refLogo = useRef(null);
  const refBanner = useRef(null);

  const { addToast } = useToasts();
  const { setValue, control } = useMerchantManager();

  const [logo, banner] = useWatch({ control, name: ["logotipo", "banner"] });

  async function handleLogoChange(e) {
    const form_data = new FormData();
    form_data.append("arquivo", e.target.files[0]);
    form_data.append("id_estabelecimento", merchantId);

    const cf = {
      baseURL: api.defaults.baseURL,
      headers: {
        "content-type": "multipart/form-data",
        Authorization: api.defaults.headers["Authorization"],
      },
    };
    const response = await axios.post("estabelecimentos/logo", form_data, cf);
    if (response.data.status !== undefined && response.data.status === true) {
      addToast(response.data.mensagem, {
        appearance: "success",
        autoDismiss: true,
        autoDismissTimeout: 5000,
      });

      setValue("logotipo", response.data.url_imagem);
    } else {
      Swal.fire({
        title: "Atenção!",
        icon: "info",
        html: response.data.erros,
        showCloseButton: true,
        showCancelButton: false,
      });
    }
  }

  async function handleBannerChange(e) {
    const form_data = new FormData();
    form_data.append("arquivo", e.target.files[0]);
    form_data.append("id_estabelecimento", merchantId);

    const cf = {
      baseURL: api.defaults.baseURL,
      headers: {
        "content-type": "multipart/form-data",
        Authorization: api.defaults.headers["Authorization"],
      },
    };
    const response = await axios.post("estabelecimentos/banner", form_data, cf);
    if (response.data.status !== undefined && response.data.status === true) {
      addToast(response.data.mensagem, {
        appearance: "success",
        autoDismiss: true,
        autoDismissTimeout: 5000,
      });

      setValue("banner", response.data.url_imagem);
    } else {
      Swal.fire({
        title: "Atenção!",
        icon: "info",
        html: response.data.erros,
        showCloseButton: true,
        showCancelButton: false,
      });
    }
  }

  return (
    <div
      className="tab-pane fade"
      id="imagem_app"
      role="tabpanel"
      aria-labelledby="imagem_app"
    >
      <div className="row">
        <div className="from-group col-md-6">
          <label>Logotipo</label>
          <div className="area-imagem dropzone--squared menu-category-drawer-item-details-tab__image">
            <div
              className="dropzone__area"
              aria-disabled="false"
              style={{ position: "relative" }}
              onClick={(e) => {
                if (envioImagem == true) {
                  refLogo.current.click();
                } else {
                  Swal.fire({
                    title: "Erro!",
                    icon: "error",
                    html: "Salve o cadastro do Estabelecimento antes de cadastrar uma imagem!",
                    showCloseButton: true,
                    showCancelButton: false,
                  });
                }
              }}
            >
              <div className="dropzone-preview dropzone__preview">
                <img
                  className="dropzone-preview__image"
                  src={logo ? logo : "assets/media/no-img2.jpg"}
                  alt=""
                />
              </div>
              <div className="dropzone-placeholder dropzone__placeholder">
                <span>Trocar imagem</span>
              </div>
              <input
                type="file"
                ref={refLogo}
                name="imagemLogo"
                onChange={(e) => handleLogoChange(e)}
                accept=".png, .jpg, .jpeg"
                className="classeInputImage"
              />
            </div>
          </div>
        </div>
        <div className="from-group col-md-6">
          <label>Banner</label>
          <div className="area-imagem dropzone--squared menu-category-drawer-item-details-tab__image">
            <div
              className="dropzone__area"
              aria-disabled="false"
              style={{ position: "relative" }}
              onClick={() => {
                if (envioImagem == true) {
                  refBanner.current.click();
                } else {
                  Swal.fire({
                    title: "Erro!",
                    icon: "error",
                    html: "Salve o cadastro do Estabelecimento antes de cadastrar uma imagem!",
                    showCloseButton: true,
                    showCancelButton: false,
                  });
                }
              }}
            >
              <div className="dropzone-preview dropzone__preview">
                <img
                  className="dropzone-preview__image"
                  src={banner ? banner : "assets/media/no-img2.jpg"}
                  alt=""
                />
              </div>
              <div className="dropzone-placeholder dropzone__placeholder">
                <span>Trocar imagem</span>
              </div>
              <input
                type="file"
                ref={refBanner}
                name="imagemBanner"
                onChange={(e) => handleBannerChange(e)}
                accept=".png, .jpg, .jpeg"
                className="classeInputImage"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const ImageTab = memo(ImageTabComponent);

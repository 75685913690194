function useRoles() {
    const roles = JSON.parse(localStorage.getItem('@ADM-Aiboo/perfil'));

    const doIHaveThisRole = (role) => {
        if (!roles) {
            return false
        }

        if (roles.hasOwnProperty(role) && roles[role] == '1') {
            return true;
        }
        
        return false;
    }
  
    return { roles, doIHaveThisRole };
}

export default useRoles;
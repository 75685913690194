import { useState, useEffect, useCallback } from 'react';
import apiLaravelv2 from '../services/api-laravelv2';

const initialFilter = {
    id_produto: "",
    nome_produto: "",
    id_categoria: "",
    codigo_barras: "",
}

function usePagination(itemsPerPage) {
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(1);
    const [currentData, setCurrentData] = useState([]);
    const [perPage, setPerPage] = useState(itemsPerPage);
    const [maxPage, setMaxPage] = useState(1);
    const [filter, setFilter] = useState(initialFilter);
    const [totalItems, setTotalItems] = useState(0);

    const loadDataByFilter = useCallback((filter = {}) => {
        const params = {
            page: currentPage,
            per_page: perPage,
            ...filter,
        }

        apiLaravelv2.get("products", { params }).then((response) => {
            if (response.data) {
                setCurrentData(response.data.products.data);
                setMaxPage(response.data.products.last_page);
                setTotalItems(response.data.products.total);
            }
            setLoading(false);

        });
    }, [currentPage, perPage]);

    const loadDataByID = useCallback((id_produto) => {
        const params = {
            page: currentPage,
            per_page: perPage,
        }
        apiLaravelv2.get(`products/${id_produto}`, { params }).then((response) => {
            if (response.data) {
                setCurrentData([response.data.product]);
                setMaxPage(1);
                setTotalItems(1);
            } else {
                setCurrentData([]);
                setMaxPage(0);
                setTotalItems(0);
            }
            setLoading(false);
        });
    }, [currentPage, perPage]);

    useEffect(() => {
        setCurrentPage(1);
    }, [filter]);

    const loadData = useCallback((filter = {}) => {
        const { id_produto, ...restFilter } = filter;
        setLoading(true);

        !!id_produto ? loadDataByID(id_produto) : loadDataByFilter(restFilter);

    }, [loadDataByFilter, loadDataByID]);

    useEffect(() => {
        loadData(filter);
    }, [loadData, filter]);


    const goToNextPage = useCallback(() => {
        currentPage < maxPage && setCurrentPage((prevPageNumber) => prevPageNumber + 1);
    }, [currentPage, maxPage]);

    const goToPreviousPage = useCallback(() => {
        currentPage < 1 && setCurrentPage((prevPageNumber) => prevPageNumber + 1);
    }, [currentPage]);

    function changePage(pageNumber) {
        if (pageNumber < 1) return;
        if (pageNumber > maxPage) return;

        setCurrentPage(pageNumber);
    }

    return {
        loading,
        setPerPage,
        setFilter,
        goToNextPage,
        goToPreviousPage,
        changePage,
        currentData,
        currentPage,
        maxPage,
        totalItems
    };
}

export default usePagination;
import { useCardComponent } from "./useCardComponent";

import * as S from "./styles";

export function CardComponent({
  entity,
  type = "establishment",
  isAdded = false,
  onAdd,
  onRemove,
  isFromAddedList = false,
}) {
  const { buttonSwitcher } = useCardComponent();

  return (
    <S.Container>
      <S.InformationContainer>
        <S.PhotoContainer>
          {!!entity.image ? (
            <img src={entity.image} alt="Imagem do Card" />
          ) : (
            <S.ImagePlaceholder className=".placeholder">
              <i class="la la-file"></i>
            </S.ImagePlaceholder>
          )}
        </S.PhotoContainer>

        <div>
          <S.CardTitle className="text-truncate">{entity.name}</S.CardTitle>
          <S.CardLabel>ID {entity.id}</S.CardLabel>
          <S.CardLabel>
            {type === "establishment" ? entity.city : entity.establishment}
          </S.CardLabel>
        </div>
      </S.InformationContainer>

      {!isAdded ? (
        <S.ActionButton type="button" onClick={onAdd}>
          {buttonSwitcher[type].add.icon}
          <S.ActionButtonLabel>
            {buttonSwitcher[type].add.label}
          </S.ActionButtonLabel>
        </S.ActionButton>
      ) : isFromAddedList ? (
        <S.ActionButton type="button" onClick={onRemove}>
          {buttonSwitcher[type].remove.icon}
          <S.ActionButtonLabel>
            {buttonSwitcher[type].remove.label}
          </S.ActionButtonLabel>
        </S.ActionButton>
      ) : (
        <S.AddedEntityLabelContainer>
          <i class="la la-check"></i>

          <S.AddedEntityLabel>
            {type === "establishment"
              ? "Empresa adicionada"
              : "Item adicionado"}
          </S.AddedEntityLabel>
        </S.AddedEntityLabelContainer>
      )}
    </S.Container>
  );
}

import React, { useState, useEffect } from 'react';
import Api from '../../../services/api';
import { withRouter } from "react-router";

import Swal from "sweetalert2";
import { useToasts } from 'react-toast-notifications';
import LoadingOverlay from 'react-loading-overlay';

import DataTable from 'react-data-table-component';
import { Modal } from "react-bootstrap";

const FormaPagamento = () => {
  const { addToast } = useToasts();
  const [ formaPagamento, setFormaPagamento ] = useState([]);
  const [ caProcessa, setCaProcessa ] = useState(false);
  const [ buscaTipo, setBuscaTipo ] = useState();
  const [ buscaNome, setBuscaNome ] = useState();
  const [ buscaStatus, setBuscaStatus ] = useState();
  const [ modal, setModal ] = useState(false);
  const [ formFormaPagamento, setFormFormaPagamento ] = useState({});

  let acesso = JSON.parse(localStorage.getItem('@ADM-Aiboo/perfil'));

  function fecharModal(){
    setModal(false);
  }

  const data = formaPagamento;
  const columns = [
    {
      name: <th>ID</th>,
      selector: 'id_forma_pagamento',
      sortable: true,
      width: '10%',
      center: true,
    },
    {
      name: <th>Nome</th>,
      selector: 'nome',
      width: '30%',
      sortable: true,
    },
    {
      name: <th>Descrição</th>,
      selector: 'descricao',
      width: '30%',
      sortable: true,
    },
    {
      name: <th>Tipo</th>,
      width: '10%',
      sortable: true,
      selector: 'status',
      center: 'true',
      cell: row => { 
        if (row.tipo === "CC") {
            return <span className="label label-info label-inline mr-2">Crédido</span>
          } else if (row.tipo === "CD") {
            return <span className="label label-danger label-inline mr-2">Débito</span>
          } else {
            return <span className="label label-success label-inline mr-2">Dinheiro</span>
          }
      }
    },
    {
      name: <th>Status</th>,
      width: '10%',
      sortable: true,
      selector: 'status',
      center: 'true',
      cell: row => { if (row.status === "1") {return <span className="label label-success label-inline mr-2">Ativo</span>}
        else {return <span className="label label-secondary label-inline mr-2">Desativado</span>}
      }
    },
    {
      name: <th>Ações</th>,
      cell: (row) => 
      { return (<>
      <div className="dropdown dropdown-inline">
      <button type="button" className="btn btn-light-primary btn-icon btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <i className="ki ki-bold-more-ver"></i>
      </button>
      <div className="dropdown-menu">
        <span className="dropdown-item" onClick={e => { editar(row) }}>Editar</span>
        {acesso.est_for_pag_ei === "1" && <>
        <div className="dropdown-divider"></div>
        <span className="dropdown-item" onClick={e => { excluir(row) }}>Inutilizar</span>
        </>}
      </div>
    </div>
    </>)
      },
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      right: true,
    },
  ];

   useEffect(() => {
    getListar();
  }, []);

  function getListar() { 
    setCaProcessa(true); 
    Api.get("formaspagamentos/filtrar").then(rps => {
      setFormaPagamento(rps.data.formas);
      setCaProcessa(false);
    })
  }

  function adicionar(){
    setFormFormaPagamento({});
    setModal(true);
  }

  function editar(id_plano){  
    var ca = JSON.parse(JSON.stringify(id_plano));

    setFormFormaPagamento(ca);
    setModal(true);           
  }

  function excluir(id ) {
    var ca = JSON.parse(JSON.stringify(id));

    setCaProcessa(true);

    Swal.fire({
        icon: "question",
        title: 'Confirmação',
        html: 'Deseja inutilizar o registro?',
        showCancelButton: true,
        confirmButtonText: `Sim, Confirmar!`,
        cancelButtonText: 'Não, Cancelar.'
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        Api.post('formaspagamentos/inutilizar', ca).then(rps => {
          
          if(rps.data.status==true){
            addToast(rps.data.mensagem, {
              appearance: "success",
              autoDismiss: true,
              autoDismissTimeout: 2000
            });
            getListar();
          } 
        })
      } 
      setCaProcessa(false);
    })
  }

  function salvar(){
    setCaProcessa(true);
    Api.post('formaspagamentos/adicionar', formFormaPagamento).then(rps => {
      if (rps.data.status === true) {
        addToast(rps.data.mensagem, {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 2000
        });
        setModal(false);
        setFormFormaPagamento({});
        getListar();
      } else {
        Swal.fire({
          title: "Erro!",
          icon: "error",
          html: rps.data.erros,
          showCloseButton: true,
          showCancelButton: false,
        });
      }
      setCaProcessa(false);
    });
  }

  function filtrar() {
    setCaProcessa(true); 
      var data = {
        nome: buscaNome,
        tipo: buscaTipo,
        status: buscaStatus,
      };
    
      Api.post('formaspagamentos/filtrar', data).then((rps) => {

        if(rps.data.status===false){
          Swal.fire({
            title: "Atenção!",
            icon: "info",
            html: rps.data.erro,
            showCloseButton: true,
            showCancelButton: false,
          });
        } else {
          setFormaPagamento(rps.data.formas);
        }
      setCaProcessa(false); 
    });
  }
   
    return (
        <>
<div>

<div className="subheader espaco-header-pedidos subheader-transparent" id="kt_subheader">
          <div className="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
            {/*begin::Details*/}
            <div className="d-flex align-items-center flex-wrap mr-2">
              {/*begin::Title*/}
              <h5 className="text-dark font-weight-bold mt-2 mb-2 mr-5">Forma de Pagamento</h5>
              {/*end::Title*/}
              {/*begin::Separator*/}
              <div className="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200" />
              {/*end::Separator*/}
            </div>
            {/*end::Details*/}
            {/*begin::Toolbar*/}
            <div className="d-flex align-items-center">
              <button className="btn btn-lg btn-success mt-3 mb-3" onClick={e => {adicionar()}}><i className="fas fa-plus" />Adicionar</button>
            </div>
            {/*end::Toolbar*/}
          </div>
        </div>
        {/*begin::Entry*/}
        <div className="d-flex flex-column-fluid">
          {/*begin::Container*/}
          <div className="container">
            <div className="card card-custom">
              <div className="ml-10 mr-10 mt-10">    
                <div className="row">
                  <div className="form-group col-md-6">
                    <label>Nome</label>
                    <input type="text" value={buscaNome} onChange={e => {setBuscaNome(e.target.value)}} className="form-control" />
                  </div>
                  <div className="form-group col-md-2">
                    <label>Tipo</label>
                    <select className="form-control" value={buscaTipo} onChange={e => {setBuscaTipo(e.target.value)}}>
                      <option value="" selected>Selecione</option>    
                      <option value="CC">Crédito</option>    
                      <option value="CD">Débito</option>
                      <option value="DN">Dinheiro</option>    
                    </select>
                  </div>
                  <div className="form-group col-md-2">
                    <label>Status</label>
                    <select className="form-control" value={buscaStatus} onChange={e => {setBuscaStatus(e.target.value)}}>
                      <option value="" selected>Selecione</option>    
                      <option value="1">Ativo</option>    
                      <option value="0">Desativado</option>    
                    </select>
                  </div>

                  <div className="col-md-2 form-group">
                  <label>&nbsp;</label><br />
                    <button className="btn btn-primary btn-block" onClick={e=>{filtrar()}}><i className="fas fa-filter" />Filtrar</button>
                  </div> 
                </div> 
              </div>
            </div>                 

              <LoadingOverlay
                active={caProcessa}
                spinner
                text='Carregando...'
              >                 
                <DataTable
                  title="Lista de Formas de Pagamento"
                  columns={columns}
                  data={data}
                  striped="true" 
                  pagination="true"
                />
              </LoadingOverlay>                    
              
              </div>
            </div>
          </div>
                  
          <Modal size={'xl'} show={modal} onHide={() => fecharModal()}>
            <Modal.Header>
            <Modal.Title>Forma de Pagamento</Modal.Title>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={e => {fecharModal()}}>
                  <i aria-hidden="true" className="ki ki-close" />
                </button>
            </Modal.Header>
            <div className="row mt-5 ml-5 mr-5">
              
              <div className="form-group col-md-4">
                <label>Nome</label>
                <input type="text" value={formFormaPagamento?.nome} onChange={e => {setFormFormaPagamento({...formFormaPagamento, nome: e.target.value})}} className="form-control" />
              </div>

              <div className="form-group col-md-4">
                <label>Descrição</label>
                <input type="text" value={formFormaPagamento?.descricao} onChange={e => {setFormFormaPagamento({...formFormaPagamento, descricao: e.target.value})}} className="form-control" />
              </div>
              
              <div className="form-group col-md-2">
                <label>Tipo</label>
                <select className="form-control" value={formFormaPagamento?.tipo} onChange={e => {setFormFormaPagamento({...formFormaPagamento, tipo: e.target.value})}}>
                  <option value="" selected>Selecione</option>    
                  <option value="CC">Crédito</option>    
                  <option value="CD">Débito</option>
                  <option value="DN">Dinheiro</option>    
                </select>
              </div>

              <div className="form-group col-md-2">
                <label>Status</label>
                <select className="form-control" value={formFormaPagamento?.status} onChange={e => {setFormFormaPagamento({...formFormaPagamento, status: e.target.value})}}>
                  <option value="" selected>Selecione</option>    
                  <option value="1" >Ativo</option>    
                  <option value="0" >Desativado</option>    
                </select>
              </div>
            
            </div>    
            
            <Modal.Footer>
              <button type="button" onClick={e => { fecharModal(); }} className="btn btn-secondary font-weight-bold" data-dismiss="modal"><i className="fas fa-door-open" />Fechar</button>
              <button type="button" onClick={e => { salvar(); }} className="btn btn-success font-weight-bold"><i className="fas fa-save" />Salvar</button>
            </Modal.Footer>
          </Modal>        
                 
        </>
    );
}

export default withRouter(FormaPagamento);                  
import styled from "styled-components";

export const Container = styled.div`
  input {
    border-color: ${({ error }) => (error ? "#ff3333" : "#E5EAEE")};
  }

  input:focus,
  select:focus {
    border-color: ${({ error }) => (error ? "#ff3333" : "#aaa")};
  }

  small {
    color: #ff3333;
  }
`;

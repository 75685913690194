import React, { createContext, useContext, useState } from "react";
import { useForm } from "react-hook-form";

const MerchantManagerContext = createContext({
  handleSubmit: () => {},
});

const MerchantManagerProvider = ({ children }) => {
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    reset,
    clearErrors,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      payments: [],
      groups: [],
      storeTypes: [],
      rg: "",
      orgao_expedidor: "",
      id_representante: {
        label: localStorage.getItem("@ADM-Aiboo/nome"),
        value: localStorage.getItem("@ADM-Aiboo/id_representante"),
      },
      lat: "",
      lng: "",
      cpf_contato: null,
      estado_civil: { label: "", value: null },
    },
  });

  const [activeTab, setActiveTab] = useState("data-tab");

  return (
    <MerchantManagerContext.Provider
      value={{
        register,
        handleSubmit,
        setValue,
        clearErrors,
        getValues,
        control,
        reset,
        activeTab,
        setActiveTab,
      }}
    >
      {children}
    </MerchantManagerContext.Provider>
  );
};

const useMerchantManager = () => {
  return useContext(MerchantManagerContext);
};

export { MerchantManagerProvider, useMerchantManager };

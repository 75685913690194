import { merchantService } from "services";

export const useFetchCuisines = () => {
  const formatCuisine = (cuisine) => ({
    label: cuisine.nome,
    value: cuisine.id_tipoloja,
  });

  const fetchCuisines = async () => {
    const response = await merchantService.listCuisines();

    return response.obj?.map(formatCuisine);
  };

  return { fetchCuisines };
};

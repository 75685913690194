import { Files } from "./Files";
import { Plans } from "./Plans";
import { Receipts } from "./Receipts";
import { Register } from "./Register";

export const OnlinePaymentTab = {
  Register,
  Files,
  Plans,
  Receipts,
};

export function useCardComponent() {
  const buttonSwitcher = {
    establishment: {
      add: {
        icon: <i className="la la-plus"></i>,
        label: "Adicionar empresa",
      },
      remove: {
        icon: <i className="la la-trash"></i>,
        label: "Remover empresa",
      },
    },
    item: {
      add: {
        icon: <i className="la la-plus"></i>,
        label: "Adicionar item",
      },

      remove: {
        icon: <i className="la la-trash"></i>,
        label: "Remover item",
      },
    },
  };

  return { buttonSwitcher };
}

import React, { useState, useEffect, useCallback } from 'react';
import Api from '../../../services/api';
import { withRouter } from "react-router";

import Swal from "sweetalert2";
import { useToasts } from 'react-toast-notifications';
import LoadingOverlay from 'react-loading-overlay';

import { Modal } from "react-bootstrap";

import InputMask from 'react-input-mask';
import "react-datepicker/dist/react-datepicker.css";
import DatePicker, { registerLocale } from "react-datepicker";
import pt from "date-fns/locale/pt-BR"; 
registerLocale("pt-BR", pt);

const Paginacao = ({ currentPage, pageCount, handlePageClick }) => {
  
  const renderRow = row => {
    return <span>{row}</span>;
  };

  let rows = [];

  //Registro Anterior, caso pagina correta seja diferente de 1 o botão fica ativo
  if (currentPage !== 1) {
    rows.push(
      <span 
        onClick={() => handlePageClick(currentPage - 1)} 
        className="btn btn-icon btn-sm btn-light-primary mr-2 my-1">
        <i className="ki ki-bold-arrow-back icon-xs"></i>
      </span>
    );
  } else {
    rows.push(
      <span className="btn-icon btn-sm btn-secondary disabled mr-2 my-1">
        <i className="ki ki-bold-arrow-back icon-xs"></i>
      </span>
    );
  }

  //Quanto existe mais de 3 páginas estou exibindo o botão [1] e [...], envia para a página 1.
  if (currentPage > 3) {
    rows.push(
      <>
        <span 
          onClick={() => handlePageClick(1)} 
          className="btn btn-icon btn-sm btn-light-primary mr-2 my-1">
          1
        </span>
        <span className="btn-icon btn-sm btn-secondary disabled mr-2 my-1">
          ...
        </span>
      </>
    );
  }

  //Executo um laço de repetição para exibir os numeros de páginas, limitando a memos e mais 3, tendo como base a página atual
  for (let i = currentPage - 2; i < currentPage + 3; i++) {
    if (i > 0 && i <= pageCount) {
      if (i === currentPage) {
        rows.push(
          <span 
            onClick={() => handlePageClick(i)} 
            className="btn btn-icon btn-sm border-0 btn-hover-primary active mr-2 my-1">
            {i}
          </span>
        );
      } else {
        rows.push(
          <span 
            onClick={() => handlePageClick(i)} 
            className="btn btn-icon btn-sm border-0 btn-hover-primary mr-2 my-1">
            {i}
          </span>
        );
      }
    }
  }

  //Quanto existe mais de 6 páginas estou exibindo o botão [...] e [Maior Numero], envia para a página MaiorNumero.
  if (currentPage + 3 <= pageCount) {
    rows.push(
      <>
        <span className="btn-icon btn-sm btn-secondary disabled mr-2 my-1">
          ...
        </span>
        <span 
          onClick={() => handlePageClick(pageCount)} 
          className="btn btn-icon btn-sm btn-light-primary mr-2 my-1">
          {pageCount}
        </span>
      </>
    );
  }

  //Próximo registro, caso pagina correta seja diferente de pageCount o botão fica ativo
  if (currentPage !== pageCount) {
    rows.push(
      <span 
        onClick={() => handlePageClick(currentPage + 1)} 
        className="btn btn-icon btn-sm btn-light-primary mr-2 my-1">
        <i className="ki ki-bold-arrow-next icon-xs"></i>
      </span>
    );
  } else {
    rows.push(
      <span className="btn-icon btn-sm btn-secondary disabled mr-2 my-1">
        <i className="ki ki-bold-arrow-next icon-xs"></i>
      </span>
    );
  }

  if (rows.length === 0) {
    return <p>Nenhum item</p>;
  }
  return <div>{rows.map(renderRow)}</div>;
};


const Tickets = () => {
  const { addToast } = useToasts();
  const [ ticket, setTicket ] = useState([]);
  const [ totalRegistros, setTotalRegistros ] = useState();
  const [ formTicket, setFormTicket ] = useState({});
  const [ mensagem, setMensagem ] = useState([]);
  const [ caProcessa, setCaProcessa ] = useState(false);
  const [ buscaHash, setBuscaHash ] = useState();
  const [ buscaDataInicio, setBuscaDataInicio ] = useState();
  const [ buscaDataFinal, setBuscaDataFinal ] = useState();
  const [ buscaNome, setBuscaNome ] = useState();
  const [ buscaStatus, setBuscaStatus ] = useState();
  const [ modal, setModal ] = useState(false);

  const [ordenacao, setOrdenacao] = useState('tickets.id_ticket');
  const [ordenacaoSet, setOrdenacaoSet] = useState('DESC');
  const [currentPage, setCurrentPage] = useState(1);

  const PER_PAGE = 10;
  const pageCount = Math.ceil(totalRegistros / PER_PAGE);

  function fecharModal(){
    getListar();
    setModal(false);
  }

  function handlePageClick(pagina) {
    setCaProcessa(true);
    setCurrentPage(pagina);
    var data = {
      limite: PER_PAGE,
      start: (pagina - 1) * PER_PAGE,
      hash: buscaHash,
      dataInicio: buscaDataInicio,
      dataFinal: buscaDataFinal,
      nome: buscaNome,
      status: buscaStatus,
      //representante: localStorage.getItem('@ADM-Aiboo/nivel') == 'R' ? localStorage.getItem('@ADM-Aiboo/id_representante') : buscaRepresentante,
      ordenacao: ordenacao,
      sentido: ordenacaoSet,
    }

    Api.post("tickets/listar", data).then(rps => {
      setTicket(rps.data.obj);
    })
    setCaProcessa(false);
  }

  function getListar() { 
    var data = {
      limite: PER_PAGE,
      start: 0,
      ordenacao: 'tickets.id_ticket',
      sentido: 'DESC',
      //status: buscaStatus,
      representante: localStorage.getItem('@ADM-Aiboo/nivel') == 'R' ? localStorage.getItem('@ADM-Aiboo/id_representante') : ''
    }

    setCaProcessa(true); 
    Api.post("tickets/listar", data).then(rps => {
      setTicket(rps.data.obj);
      setTotalRegistros(rps.data.totalRegistros);
      setCaProcessa(false);
    })
  }

  function aceitarTicket(item) {
    var linha = JSON.parse(JSON.stringify(item));
    
    var data = {
      id_ticket: linha.id_ticket
    }
    
    Api.post("tickets/aceitarTicket", data).then(rps => {
      if (rps.data.status === true) {
        abrirTicket(linha);
      }
    })
  }

  function abrirTicket(item){
    var linha = JSON.parse(JSON.stringify(item));
    
    var data = {
      id_ticket: linha.id_ticket
    }
    
    Api.post("tickets/mensagem", data).then(rps => {
      setMensagem(rps.data.obj);
    })

    setFormTicket(linha);
    setModal(true);
  }

  function fecharTicket(item){
    var ticket = item;
    
    var data = {
      id_ticket: ticket
    }
    
    Api.post("tickets/fecharTicket", data).then(rps => {
      if (rps.data.status === true) {
        addToast(rps.data.mensagem, {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 2000
        });
        getListar();
        setModal(false);
      }
    })
  }

  function salvar(){
    Api.post('tickets/ticketMensagem', formTicket).then(rps => {
      if (rps.data.status === true) {
        addToast(rps.data.mensagem, {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 2000
        });
        getListar();
        setModal(false);
      } else {
        Swal.fire({
          title: "Erro!",
          icon: "error",
          html: rps.data.erros,
          showCloseButton: true,
          showCancelButton: false,
        });
      }
    });
  }

  const filtrar = useCallback((order, fechou) => {
    var sentido = 'DESC';

    if (order != null || order != undefined) {
      setOrdenacao(order);
    } else {
      //Valor padrão para a primeira vez
      setOrdenacao('tickets.id_ticket');
      order = 'tickets.id_ticket';
    }

    if (order === ordenacao) {
      //Caso a ordem atual seja igual a anterior e não veio do modal
      if (fechou == 'N') {
        ordenacaoSet === 'ASC' ? setOrdenacaoSet('DESC') : setOrdenacaoSet('ASC');
        ordenacaoSet === 'ASC' ? sentido = 'DESC' : sentido = 'ASC';
      }
    } else {
      //Caso a ordem atual não seja igual a anterior DESC
      setOrdenacaoSet('DESC');
      sentido = 'DESC';
    }

    setCaProcessa(true); 
      var data = {
        hash: buscaHash,
        dataInicio: buscaDataInicio,
        dataFinal: buscaDataFinal,
        nome: buscaNome,
        status: buscaStatus,
        limite: PER_PAGE,
        start: 0,
        ordenacao: order,
        sentido: sentido,
      };
    
      Api.post('tickets/listar', data).then((rps) => {
        if(rps.data.status==false){
          Swal.fire({
            title: "Atenção!",
            icon: "info",
            html: rps.data.erro,
            showCloseButton: true,
            showCancelButton: false,
          });
        } else {
          setTicket(rps.data.obj);
          setTotalRegistros(rps.data.totalRegistros);
          setCurrentPage(1);
        }
      setCaProcessa(false); 
    });
  }, [buscaDataFinal, buscaDataInicio, buscaHash, buscaNome, buscaStatus, ordenacao, ordenacaoSet]);
    
  
  useEffect(() => {
    filtrar();
  }, [filtrar]);

    return (
        <>
<div>

<div className="subheader espaco-header-pedidos subheader-transparent" id="kt_subheader">
          <div className="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
            {/*begin::Details*/}
            <div className="d-flex align-items-center flex-wrap mr-2">
              {/*begin::Title*/}
              <h5 className="text-dark font-weight-bold mt-2 mb-2 mr-5">Tickets de Atendimento</h5>
              {/*end::Title*/}
              {/*begin::Separator*/}
              <div className="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200" />
              {/*end::Separator*/}
            </div>
            {/*end::Details*/}
            {/*begin::Toolbar*/}
            <div className="d-flex align-items-center">
            </div>
            {/*end::Toolbar*/}
          </div>
        </div>
        {/*begin::Entry*/}
        <div className="d-flex flex-column-fluid">
          {/*begin::Container*/}
          <div className="container">
            <div className="card card-custom">
              <div className="ml-10 mr-10 mt-10">    
                <div className="row">
                <div className="form-group col">
                  <label>Ticket</label>
                  <input type="text" value={buscaHash} onChange={e => {setBuscaHash(e.target.value)}} className="form-control" placeholder="EX: 12345" />
                </div>
                <div className="form-group col-md-2">
                  <label>Data Início</label>
                    <div>
                      <DatePicker 
                        name="dt_inicio"
                        onChange={setBuscaDataInicio}
                        isClearable
                        selectsStart
                        locale="pt-BR"
                        className="form-control"
                        placeholderText="dd/mm/aaaa" 
                        selected ={buscaDataInicio}
                        dateFormat="dd/MM/y"
                        startDate={buscaDataInicio}
                        endDate={buscaDataFinal}
                        customInput={
                          <InputMask mask="99/99/9999" />}/>
                    </div>
                </div>
                <div className="form-group col-md-2">
                  <label>Data Final</label>
                    <div>
                      <DatePicker 
                        name="dt_final"
                        onChange={setBuscaDataFinal}
                        isClearable
                        locale="pt-BR"
                        className="form-control"
                        placeholderText="dd/mm/aaaa" 
                        selected ={buscaDataFinal}
                        dateFormat="dd/MM/y"
                        selectsEnd
                        startDate={buscaDataInicio}
                        endDate={buscaDataFinal}
                        minDate={buscaDataInicio} 
                        customInput={
                          <InputMask mask="99/99/9999" />}/>
                    </div>
                </div> 
                <div className="form-group col-md-4">
                  <label>Nome</label>
                  <input type="text" value={buscaNome} onChange={e => {setBuscaNome(e.target.value)}} className="form-control" />
                </div>
                <div className="form-group col">
                  <label>Status</label>
                  <select className="form-control" value={buscaStatus} onChange={e => {setBuscaStatus(e.target.value)}}>
                    <option value="" selected>Selecione</option>    
                    <option value="A">Aguardando</option>    
                    <option value="E">Atendendo</option>    
                    <option value="R">Resolvido</option>      
                  </select>
                </div>

                  <div className="col form-group">
                  <label>&nbsp;</label><br />
                    <button className="btn btn-primary btn-block" onClick={e=>{filtrar()}}><i className="fas fa-filter" /> Filtrar</button>
                  </div> 
                </div> 
              </div>

              <LoadingOverlay
                active={caProcessa}
                spinner
                text='Carregando...'
              >

                <table className="table table-striped">
                  <thead className="bg-light-primary">
                    <tr>
                      <th className="text-primary" width="20%">
                        <span className="text-hover-dark" onClick={e => { filtrar('tickets.id_ticket','N'); }}>
                          Ticket {ordenacao === 'tickets.id_ticket' ? <i className="fas fa-sort-down text-primary" /> : <i className="fas fa-sort text-primary" />}
                        </span>
                      </th>
                      <th className="text-primary" width="50%">
                        <span className="text-hover-dark" onClick={e => { filtrar('tickets.nome','N'); }}>
                          Nome {ordenacao === 'tickets.nome' ? <i className="fas fa-sort-down text-primary" /> : <i className="fas fa-sort text-primary" />}
                        </span>
                      </th>
                      <th className="text-primary text-center" width="10%">
                        <span className="text-hover-dark" onClick={e => { filtrar('tickets.status','N'); }}>
                          Status {ordenacao === 'tickets.status' ? <i className="fas fa-sort-down text-primary" /> : <i className="fas fa-sort text-primary" />}
                        </span>
                      </th>
                      <th className="text-center">Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    { ticket != null &&
                      ticket.map((item) => {
                      return (
                        <>
                          <tr>
                            <td><b>#{item.id_ticket}</b><br />{item.data_criacao}</td>
                            <td><b>{item.nome}</b><br />{item.compras} compras</td>
                            <td className="text-center">
                                { item.status == 'A' && 
                                  <span class="label label-inline label-danger mr-2">
                                    Aguardando
                                  </span> 
                                }
                                { item.status == 'E' && 
                                  <span class="label label-inline label-info mr-2">
                                    Atendendo
                                  </span> 
                                }
                                { item.status == 'R' && 
                                  <span class="label label-inline label-success mr-2">
                                    Resolvido
                                  </span> 
                                }
                              </td>
                              <td className="text-center">
                              { item.status == 'A' && 
                                  <button type="button" class="btn btn-info btn-sm" onClick={e => { aceitarTicket(item) }}>
                                    <i class="fas fa-check"></i>Aceitar
                                  </button>
                                }
                                { item.status == 'E' &&
                                  <button type="button" class="btn btn-secondary btn-sm" onClick={e => { abrirTicket(item) }}>
                                    <i class="far fa-folder-open"></i>Abrir
                                  </button>
                                }
                                { item.status == 'R' &&
                                  <span type="button" class="label label-inline label-success mr-2" onClick={e => { abrirTicket(item) }}>
                                    {item.data_criacao}
                                  </span>
                                }
                            </td>
                          </tr>
                        </>
                      )
                    })
                    }
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colspan="9" className="text-right">
                        <Paginacao 
                          currentPage={currentPage} 
                          pageCount={pageCount}
                          handlePageClick={handlePageClick}
                        />
                      </td>
                    </tr>
                  </tfoot>
                </table>

              </LoadingOverlay>

                  </div>
                  </div>
                  </div>
                  </div>
                  
      <Modal size={'xl'} show={modal} onHide={() => fecharModal()}>
      <Modal.Header>
      <Modal.Title>
        {formTicket.status === 'A' && <label className='text-warning'><b>AGUARDANDO</b></label>}
        {formTicket.status === 'E' && <label className='text-info'><b>ATENDENDO</b></label>}
        {formTicket.status === 'R' && <label className='text-success'><b>RESOLVIDO</b></label>}
      </Modal.Title>
          <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={e => {fecharModal()}}>
            <i aria-hidden="true" className="ki ki-close" />
          </button>
      </Modal.Header>
      <div className="row bg-light mb-5 ml-5 mr-5">
        <div className='col-md-2'>
          <small>HASH</small><br />
          <label>{formTicket.id_ticket}</label>
        </div>
        <div className='col-md-6'>
          <small>CLIENTE</small><br />
          <label>{formTicket.nome} ({formTicket.id_usuario})</label>
        </div>
        <div className='col-md-4'>
          <small>TELEFONE</small><br />
          <label>{formTicket.celular}</label>
        </div>
      </div>

      <div className="row mt-5 ml-5 mr-5">
        <div className="form-group col-md-12">        
            <div class="card-body">
              {mensagem.map((item) => {
                      return (
                        <>														
														<div class="timeline timeline-3 mb-3">
															<div class="timeline-items">
																<div class="timeline-item">
																	<div class="timeline-media">
                                    {
                                      item.usuario_nivel == "U" ? 
                                        <i class="fas fa-user text-danger"/> :
                                        <img alt="avatar" src="assets/imgs/avatar.png" />
                                    }
																	</div>
																	<div class="timeline-content">
																		<div class="d-flex align-items-center justify-content-between mb-3">
																			<div class="mr-2">
                                        <label>({item.id_usuario})</label>
																				<span class="text-muted ml-2">{item.data_criacao}</span>
                                        {
                                          item.status == "R" &&
																				    <label class="label label-light-success font-weight-bolder label-inline ml-2">Resolvido</label>
                                        }
                                        {
                                          item.status == "E" &&
																				    <label class="label label-light-warning font-weight-bolder label-inline ml-2">Em andamento</label>
                                        }
                                        {
                                          item.status == "A" &&
																				    <label class="label label-light-info font-weight-bolder label-inline ml-2">Aguardando</label>
                                        }
																			</div>
																			<div class="dropdown ml-2" data-toggle="tooltip" title="Quick actions" data-placement="left">
                                        <label>Visível ao usuário: {item.visivel == "S" ? <label class="label label-light-success font-weight-bolder label-inline ml-2">Sim</label> :
                                        <label class="label label-light-danger font-weight-bolder label-inline ml-2">Não</label>} </label>
																				
																			</div>
																		</div>
																		<p class="p-0">{item.mensagem}</p>
																	</div>
																</div>
																
																
																
															
														
													</div>
												</div>
                        </>
                      )
                    })
                    }
												</div>
                        
        </div>
      </div>

      {
        formTicket?.status != "R" && <>

      <div class='row ml-5 mr-5'>
        <div class="col">
          <label>&nbsp;</label>
          <div className="checkbox-inline">
            <label className="checkbox checkbox-square checkbox-primary">
              <input type="checkbox"
                value={formTicket?.visivel}
                onChange={(e) => { setFormTicket({ ...formTicket, visivel: !formTicket.visivel }) }}
                checked={formTicket?.visivel == 1 ? true : false} /> 
              Visível ao Usuário
              <span /></label>
          </div>
        </div>
          <div className="col">
            <label>&nbsp;</label>
            <div className="checkbox-inline">
              <label className="checkbox checkbox-square checkbox-primary">
                <input type="checkbox"
                  value={formTicket?.pushUsuario}
                  onChange={(e) => { setFormTicket({ ...formTicket, pushUsuario: !formTicket.pushUsuario }) }}
                  checked={formTicket?.pushUsuario == 1 ? true : false} /> 
                Enviar Notificação
                <span /></label>
            </div>
          </div>
          <div className="col">
            <label>&nbsp;</label>
            <div className="checkbox-inline">
              <label className="checkbox checkbox-square checkbox-primary">
                <input type="checkbox"
                  value={formTicket?.enviaEmail}
                  onChange={(e) => { setFormTicket({ ...formTicket, enviaEmail: !formTicket.enviaEmail }) }}
                  checked={formTicket?.enviaEmail == 1 ? true : false} /> 
                Enviar e-mail
                <span /></label>
            </div>
          </div>
          <div className="col">
            <label>&nbsp;</label>
            <div className="checkbox-inline">
              <label className="checkbox checkbox-square checkbox-primary">
                <input type="checkbox"
                  value={formTicket?.enviaWhatsapp}
                  onChange={(e) => { setFormTicket({ ...formTicket, enviaWhatsapp: !formTicket.enviaWhatsapp }) }}
                  checked={formTicket?.enviaWhatsapp == 1 ? true : false} /> 
                Enviar WhatsApp
                <span /></label>
            </div>
          </div>
          <div className="col">
            <label>&nbsp;</label>
            <div className="checkbox-inline">
              <label className="checkbox checkbox-square checkbox-primary">
                <input type="checkbox"
                  value={formTicket?.enviaSms}
                  onChange={(e) => { setFormTicket({ ...formTicket, enviaSms: !formTicket.enviaSms }) }}
                  checked={formTicket?.enviaSms == 1 ? true : false} /> 
                Enviar SMS
                <span /></label>
            </div>
        </div>
      </div>

      <div class='row ml-5 mr-5'>
        <div class="form-group col-md-12">
          <label>Mensagem</label>
          <textarea className="form-control"
                    value={formTicket?.resposta}
                    onChange={(e) => { setFormTicket({ ...formTicket, resposta: e.target.value }) }}></textarea>
        </div>
      </div>

      </>
      }
      
      <Modal.Footer>
      {
        formTicket?.status != "R" &&
        <button type="button" onClick={e => { fecharTicket(formTicket?.id_ticket); }} className="btn btn-danger font-weight-bold mr-20" data-dismiss="modal"><i className="fas fa-lock" /> Fechar Tickets</button>
      }
        <button type="button" onClick={e => { fecharModal(); }} className="btn btn-secondary font-weight-bold" data-dismiss="modal"><i className="fas fa-door-open" /> Sair</button>
      {
        formTicket?.status != "R" &&
        <button type="button" onClick={e => { salvar(); }} className="btn btn-success font-weight-bold"><i className="fas fa-save" /> Salvar</button>
      }
      </Modal.Footer>
    </Modal>        
                 
        </>
    );

}



export default withRouter(Tickets);                  
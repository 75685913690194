import React from 'react'
import * as S from './styles';

import useRemotePagination from '../../../../utils/useRemotePagination';
import useRoles from '../../../../utils/useRoles';
import Pagination from './Pagination';
import Loading from './Loading';
import apiLaravelv2 from '../../../../services/api-laravelv2';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

export function Table({ filter }) {
    const PER_PAGE = 20;
    const { doIHaveThisRole } = useRoles();
    const { goToNextPage, goToPreviousPage, changePage, loading, currentData, currentPage, maxPage, setFilter } = useRemotePagination(PER_PAGE);

    const history = useHistory();


    React.useEffect(() => {
        setFilter(filter);
    }, [filter, setFilter, changePage]);

    if (loading) {
        return <Loading>loading...</Loading>
    }


    function excluir(id_produto) {
        Swal.fire({
            title: 'Tem certeza que deseja excluir?',
            text: "",
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Sim, Excluir!'
        }).then((result) => {
            if (result.isConfirmed) {
                apiLaravelv2.delete("products/" + id_produto).then((rps) => {
                    if (rps?.status == 200) {
                        Swal.fire({
                            title: "Confirmação",
                            icon: "success",
                            html: "Produto deletado com sucesso!",
                            showCancelButton: false,
                            confirmButtonColor: '#1BC5BD',
                            confirmButtonText: 'Ok!'
                        }).then((result) => {
                            document.location.reload(true);
                        });
                    } else {
                        Swal.fire({
                            title: "Atenção!",
                            icon: "error",
                            html: rps.data.message,
                            showCloseButton: true,
                            showCancelButton: false,
                        });
                    }
                });
            }
        })
    }

    return (
        <>
            <S.Table class="border-collapse border-slate-500 w-full table-auto">
                <thead>
                    <tr>
                        <th class="border-slate-600">Id</th>
                        <th class="border-slate-600">Código de barras</th>
                        <th class="border-slate-600">Nome do produto</th>
                        <th class="border-slate-600">Categoria</th>
                        <th class="border-slate-600">Marca</th>
                        {(doIHaveThisRole('est_prod_ia') || doIHaveThisRole("est_prod_ei")) && (
                            <th class="border-slate-600" style={{ textAlign: 'center' }}>Ações</th>
                        )}
                    </tr>
                </thead>
                <tbody>
                    {currentData.length === 0 && (
                        <tr>
                            <td colSpan="6" style={{ textAlign: 'center', fontStyle: "italic", fontWeight: 'bold' }}>Nenhum registro encontrado</td>
                        </tr>
                    )}
                    {currentData.map((item) => (
                        <tr key={item.id_produto}>
                            <td class="border-slate-700 text-truncate">{item.id_produto}</td>
                            <td class="border-slate-700 text-truncate">{item.codigo_barras}</td>
                            <td class="border-slate-700 text-truncate">{item.nome_produto}</td>
                            <td class="border-slate-700 text-truncate">{item.category?.nome}</td>
                            <td class="border-slate-700 text-truncate">{item.marca}</td>
                            <td className="text-right">
                                {/* {est_prod_ia || est_prod_ei === 0 ? } */}
                                {doIHaveThisRole('est_pro_oc') && (
                                    <div class="dropdown dropdown-inline">
                                        <button
                                            type="button"
                                            class="btn btn-light-primary btn-icon btn-sm"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                        >
                                            <i class="ki ki-bold-more-ver" />
                                        </button>
                                        <div className="dropdown-menu">
                                            {doIHaveThisRole('est_pro_ia') && (
                                                <span
                                                    class="dropdown-item"
                                                
                                                    onClick={(e) => {
                                                        const productId = item.id_produto;
                                                        history.push({
                                                            pathname: 'produtos/RegisterProductEan',
                                                            state: { productId }
                                                        });
                                                    }}
                                                >
                                                    Editar
                                                </span>
                                            )}

                                            {doIHaveThisRole('est_pro_ei') && (
                                                <>
                                                    <div class="dropdown-divider" />
                                                    <span
                                                        class="dropdown-item"
                                                        onClick={() => {
                                                            excluir(item.id_produto)
                                                        }}
                                                    >
                                                        Excluir
                                                    </span>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </S.Table>
            <Pagination
                prevPage={goToPreviousPage}
                nextPage={goToNextPage}
                currentPage={currentPage}
                maxPage={maxPage}
                changePage={changePage}
            />
        </>
    )
}

export default Table;

import styled from "styled-components";

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

export const FormItem = styled.div`
  display: flex;
  flex-direction: column;

  label {
    font-size: 16px;
    font-weight: 600;
    color: #333333;
  }

  span {
    color: #ff3333;
    margin-top: 10px;
  }
`;

export const ImageUploaderContainer = styled.div`
  margin: 45px 0;
  width: fit-content;

  label {
    font-size: 16px;
    font-weight: 600;
    color: #333333;
    margin-bottom: 16px;
  }
`;

import { useEffect, useRef } from "react";
import { useParams } from "react-router";
import { useForm } from "react-hook-form";

import LoadingOverlay from "react-loading-overlay";

import {
  CardComponent,
  InputWrapper,
  CustomDraggableInputFile,
  Modal,
} from "../../../components";

import { useBanner } from "./useBanner";
import { getTodayDate, validateDate, validateHour } from "./helpers";

export function Banner() {
  const {
    imagePreview,
    handleCreateBanner,
    entitiesList,
    addedEntities,
    addEntity,
    handleSearchEntity,
    addAllEntities,
    entitySearch,
    setEntitySearch,
    findEntityById,
    handleUploadCSV,
    pageStatus,
    type,
    handleTypeChange,
    handleRemoveEntity,
    getBannerInfo,
    currentEntityToEdit,
    setImagePreview,
    setAddedEntities,
    notFoundSearchTerm,
    confirmationModal,
    closeConfirmationModal,
    confirmationModalTriggerSwitcher,
    handleRemoveAllEntities,
    setType,
  } = useBanner();

  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      status: "active",
    },
  });

  const [image, initialDate, finalDate, initialHour, finalHour] = watch([
    "image",
    "initialDate",
    "finalDate",
    "initialHour",
    "finalHour",
  ]);

  const inputCsvRef = useRef(null);

  const { id } = useParams();

  useEffect(() => {
    if (id) {
      getBannerInfo(id);
    }
  }, [getBannerInfo, id]);

  useEffect(() => {
    if (typeof image === "string") {
      setImagePreview(image);

      return;
    }

    if (image && image[0]) {
      const newUrl = URL.createObjectURL(image[0]);

      if (newUrl !== imagePreview) {
        setImagePreview(newUrl);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [image]);

  useEffect(() => {
    if (!currentEntityToEdit.banner) {
      return;
    }

    const {
      name,
      type,
      status,
      description,
      starts_at,
      ends_at,
      initial_hour,
      final_hour,
      image,
    } = currentEntityToEdit.banner;

    reset({
      name,
      type,
      status,
      description,
      initialDate: starts_at,
      finalDate: ends_at,
      initialHour: initial_hour,
      finalHour: final_hour,
      image,
    });

    setAddedEntities(currentEntityToEdit.resources);
    setType(currentEntityToEdit?.banner.type);
  }, [currentEntityToEdit, reset, setAddedEntities, setType]);

  return (
    <LoadingOverlay
      active={pageStatus === "loadingSubmit"}
      spinner
      text="Carregando..."
    >
      <div>
        <div
          className="subheader espaco-header-pedidos subheader-transparent"
          id="kt_subheader"
        >
          <div className="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
            <div className="d-flex align-items-center flex-wrap mr-2">
              <h5 className="text-dark font-weight-bold mt-2 mb-2 mr-5">
                Adicionar banner
              </h5>

              <div className="mt-2 mb-2 mr-5 bg-gray-200" />
            </div>

            <div className="d-flex align-items-center mt-2">
              <button
                className="btn btn-lg btn-success"
                onClick={() => handleSubmit(handleCreateBanner)()}
              >
                Salvar banner
              </button>
            </div>
          </div>
        </div>

        <div className="d-flex flex-column-fluid">
          <div className="container">
            <div className="card card-custom mb-12">
              <form>
                <div className="ml-10 mr-10 mt-10">
                  <h5 className="text-dark font-weight-bold mt-2 mb-2 mr-5 border-bottom pb-4">
                    Configurações
                  </h5>

                  <div className="row mt-8">
                    <div className="form-group col-md-4">
                      <InputWrapper label="Nome do banner" error={errors.name}>
                        <input
                          className="form-control"
                          {...register("name", {
                            required: "O nome do banner é obrigatório",
                          })}
                          maxLength={60}
                        />
                      </InputWrapper>
                    </div>

                    <div className="form-group col-md-2">
                      <InputWrapper label="Tipo">
                        <select
                          className="form-control"
                          onChange={handleTypeChange}
                          value={type}
                        >
                          <option value="establishment">Empresa</option>
                          <option value="item">Item</option>
                        </select>
                      </InputWrapper>
                    </div>

                    <div className="form-group col-md-2">
                      <InputWrapper label="Status">
                        <select
                          className="form-control"
                          {...register("status")}
                        >
                          <option value="active">Ativo</option>
                          <option value="inactive">Inativo</option>
                        </select>
                      </InputWrapper>
                    </div>
                  </div>

                  <div className="row">
                    <div className="form-group col-md-12">
                      <InputWrapper
                        label="Descrição do banner (opcional)"
                        error={errors.description}
                      >
                        <input
                          className="form-control"
                          {...register("description")}
                          maxLength={180}
                        />
                      </InputWrapper>
                    </div>
                  </div>

                  <div className="row">
                    <div className="form-group col-md-2">
                      <InputWrapper
                        label="Data inicial (opcional)"
                        error={errors.initialDate}
                      >
                        <input
                          className="form-control"
                          type="date"
                          min={getTodayDate()}
                          {...register("initialDate", {
                            validate: {
                              require: (value) =>
                                validateDate({
                                  initialDate: value,
                                  finalDate,
                                }),
                            },
                          })}
                        />
                      </InputWrapper>
                    </div>

                    <div className="form-group col-md-2">
                      <InputWrapper
                        label="Data final (opcional)"
                        error={errors.finalDate}
                      >
                        <input
                          className="form-control"
                          type="date"
                          {...register("finalDate", {
                            validate: {
                              require: (value) =>
                                validateDate({
                                  initialDate,
                                  finalDate: value,
                                }),
                            },
                          })}
                        />
                      </InputWrapper>
                    </div>

                    <div className="form-group col-md-2">
                      <InputWrapper
                        label="Horário inicial (opcional)"
                        error={errors.initialHour}
                      >
                        <input
                          className="form-control"
                          type="time"
                          {...register("initialHour", {
                            validate: {
                              require: (value) =>
                                validateHour({
                                  initialDate,
                                  finalDate,
                                  initialHour: value,
                                  finalHour,
                                }),
                            },
                          })}
                        />
                      </InputWrapper>
                    </div>

                    <div className="form-group col-md-2">
                      <InputWrapper
                        label="Horário final (opcional)"
                        error={errors.finalHour}
                      >
                        <input
                          className="form-control"
                          type="time"
                          {...register("finalHour", {
                            validate: {
                              require: (value) =>
                                validateHour({
                                  initialDate,
                                  finalDate,
                                  initialHour,
                                  finalHour: value,
                                }),
                            },
                          })}
                        />
                      </InputWrapper>
                    </div>
                  </div>
                </div>

                <div className="ml-10 mr-10 mb-10">
                  <h5 className="text-dark font-weight-bold mt-2 mb-2 mr-5 border-bottom pb-4">
                    Mídia
                  </h5>

                  <div className="row mt-8">
                    <div
                      className="form-group col-md-4"
                      style={{ height: 180 }}
                    >
                      <label style={{ display: "block" }}>Banner</label>
                      <label>1020x540 a 72dpi</label>
                      <CustomDraggableInputFile
                        type="file"
                        register={register("image")}
                        id="image"
                        accept=".png, .jpg, .jpeg"
                        imagePreview={imagePreview}
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="ml-10 mr-10 mt-10 col-md">
                    <h5 className="text-dark font-weight-bold mt-2 mb-2 mr-5 border-bottom pb-4">
                      {type === "establishment" ? "Empresas" : "Itens"}
                    </h5>

                    <div className="row mb-12 mt-8">
                      <div className="col-md border-right">
                        <ul className="nav nav-tabs nav-bold nav-tabs-line p-l-25">
                          <li className="nav-item">
                            <a
                              className="nav-link active"
                              data-toggle="tab"
                              href="#search"
                            >
                              <span className="nav-icon">
                                <i className="la la-search" />
                              </span>
                              <span className="nav-text">Pesquisar</span>
                            </a>
                          </li>

                          <li className="nav-item">
                            <a
                              className="nav-link"
                              data-toggle="tab"
                              href="#massiveUpload"
                            >
                              <span className="nav-icon">
                                <i class="la la-file"></i>
                              </span>
                              <span className="nav-text">Upload em massa</span>
                            </a>
                          </li>
                        </ul>

                        <br />

                        <div className="tab-content">
                          <div
                            className="tab-pane fade show active"
                            id="search"
                            role="tabpanel"
                            aria-labelledby="search"
                          >
                            <div className="row">
                              <div className="form-group col-md-9">
                                <InputWrapper label="Busca por nome ou ID">
                                  <input
                                    className="form-control"
                                    value={entitySearch}
                                    onChange={(event) => {
                                      setEntitySearch(event.target.value);
                                    }}
                                    placeholder={`Nome ou ID ${
                                      type === "establishment"
                                        ? "da empresa"
                                        : "do item"
                                    }`}
                                    onKeyDown={(event) =>
                                      handleSearchEntity({
                                        event,
                                        type,
                                        search: entitySearch,
                                      })
                                    }
                                  />
                                </InputWrapper>
                              </div>

                              <div className="col-md-3 form-group">
                                <label>&nbsp;</label>
                                <br />
                                <button
                                  type="button"
                                  className="btn btn-primary btn-block"
                                  onClick={(event) =>
                                    handleSearchEntity({
                                      event,
                                      type,
                                      search: entitySearch,
                                    })
                                  }
                                >
                                  <i className="la la-search" />
                                  Buscar
                                </button>
                              </div>
                            </div>

                            {entitiesList.length > 0 && (
                              <div className="row">
                                <div
                                  className="form-group col-md-4"
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <button
                                    type="button"
                                    className="btn btn-outline-danger btn-lg btn-block"
                                    onClick={addAllEntities}
                                  >
                                    <i className="la la-plus" />
                                    Adicionar todos
                                  </button>
                                </div>
                              </div>
                            )}

                            <LoadingOverlay
                              active={pageStatus === "loadingSearch"}
                              spinner
                              text="Carregando..."
                            >
                              {pageStatus === "loadingSearch" &&
                                entitiesList.length === 0 && (
                                  <div style={{ height: 200 }} />
                                )}

                              <div
                                className="row w-100"
                                style={{
                                  maxHeight: 500,
                                  overflow: "scroll",
                                }}
                              >
                                {entitiesList.map((entity) => (
                                  <div className="w-100" key={entity.id}>
                                    <CardComponent
                                      key={entity.id}
                                      entity={entity}
                                      type={type}
                                      isAdded={findEntityById(entity.id)}
                                      onAdd={() => addEntity(entity)}
                                    />
                                  </div>
                                ))}

                                {notFoundSearchTerm && entitySearch && (
                                  <div className="w-100 d-flex align-items-center justify-content-center mt-12">
                                    <span>
                                      Nenhum resultado encontrado para o termo:
                                      "{entitySearch}"
                                    </span>
                                  </div>
                                )}
                              </div>
                            </LoadingOverlay>
                          </div>

                          <div
                            className="tab-pane fade"
                            id="massiveUpload"
                            role="tabpanel"
                            aria-labelledby="massiveUpload"
                          >
                            <div className="row">
                              <div className="from-group">
                                <label>Upload em massa</label>
                                <ul>
                                  <li>Escolha um arquivo no formato .csv;</li>
                                  <li>
                                    O arquivo deve conter a lista de Ids na
                                    primeira coluna com título;
                                  </li>
                                  <li>
                                    A primeira linha de título será
                                    desconsiderada;
                                  </li>
                                  <li>Envie arquivos de até 10mb;</li>
                                </ul>
                                <div className="w-50 h-25">
                                  <label
                                    className="position-relative d-inline-block w-100 h-100"
                                    style={{ cursor: "pointer" }}
                                  >
                                    <style>
                                      {`
                                    .uploadInput::after {
                                      content: 'Enviar arquivo';
                                      margin-left: 5px;
                                    }
                                  `}
                                    </style>
                                    <span className="position-absolute top-0 start-0 end-0 bottom-0 w-100 h-100 z-5 d-flex justify-content-center align-items-center gap-5 rounded uploadInput btn btn-primary btn-block">
                                      <i class="la la-file"></i>
                                    </span>
                                    <input
                                      type="file"
                                      ref={inputCsvRef}
                                      accept=".csv"
                                      className="m-0 opacity-0 w-100 h-100 rounded"
                                      onChange={(event) =>
                                        handleUploadCSV({
                                          resources_csv: event.target.files[0],
                                          type,
                                          inputCsvRef,
                                        })
                                      }
                                    />
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md">
                        <div className="row ml-1">
                          <div className="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap border-bottom pb-7">
                            <label className="m-0">
                              {type === "establishment"
                                ? "Empresas selecionadas"
                                : "Itens selecionados"}
                            </label>

                            {addedEntities.length > 0 && (
                              <button
                                type="button"
                                className="d-flex align-items-center justify-content-center bg-transparent border-0 p-0 m-0"
                                style={{
                                  fontSize: 13,
                                  fontWeight: 500,
                                  color: "#ff3333",
                                  gap: 9,
                                }}
                                onClick={handleRemoveAllEntities}
                              >
                                <i
                                  className="la la-trash"
                                  style={{ fontSize: 18, color: "#ff3333" }}
                                ></i>
                                Remover tudo
                              </button>
                            )}
                          </div>

                          <LoadingOverlay
                            active={pageStatus === "uploadingCSV"}
                            spinner
                            text="Carregando..."
                            className="w-100 h-100"
                          >
                            <div
                              className="row w-100 mt-12 pl-2 pr-2"
                              style={{
                                maxHeight: 600,
                                overflowY: "scroll",
                                overflowX: "hidden",
                              }}
                            >
                              {addedEntities.map((entity) => (
                                <div className="w-100" key={entity.id}>
                                  <CardComponent
                                    key={entity.id}
                                    entity={entity}
                                    type={type}
                                    isAdded={findEntityById(entity.id)}
                                    onAdd={() => addEntity(entity)}
                                    onRemove={() =>
                                      handleRemoveEntity(entity.id)
                                    }
                                    isFromAddedList
                                  />
                                </div>
                              ))}
                            </div>
                          </LoadingOverlay>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>

              <div className="container d-flex align-items-center justify-content-end flex-wrap flex-sm-nowrap mb-12">
                <div className="d-flex align-items-center mt-2">
                  <button
                    className="btn btn-lg btn-success"
                    onClick={() => handleSubmit(handleCreateBanner)()}
                  >
                    Salvar banner
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        showCloseButton={false}
        toggleModalVisibility={closeConfirmationModal}
        visible={confirmationModal.isShowing}
      >
        <div
          className="card card-custom rounded-8 p-5"
          style={{ maxWidth: 500, width: 400 }}
        >
          <div className="mb-6">
            <h4 className="text-left">{confirmationModal.message.title}</h4>
            <span className="text-left">
              {confirmationModal.message.description}
            </span>
          </div>

          <div className="mt-12 d-flex w-100 align-items-center justify-content-end">
            <button
              className="bg-transparent border-0 mr-8"
              onClick={closeConfirmationModal}
            >
              {confirmationModal.labels.dismiss}
            </button>
            <button
              className="bg-transparent border-0 "
              onClick={confirmationModalTriggerSwitcher[confirmationModal.type]}
            >
              {confirmationModal.labels.trigger}
            </button>
          </div>
        </div>
      </Modal>
    </LoadingOverlay>
  );
}

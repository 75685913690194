import React from 'react'
import { Menu } from './components/Menu';
import { Logo } from './components/Logo';
import { UserDetail } from './components/UserDetail';

function Header({ onClick, storeDetails }) {
  return (
    <>

      <div id="kt_header" className="header header-fixed">
        <div className="container d-flex align-items-stretch justify-content-between">
          <div className="d-flex align-items-center mr-3">
            <Menu onClickMenu={onClick} />
            <Logo storeDetails={storeDetails} />
          </div>
          <UserDetail />
        </div>
      </div>

    </>
  )
};

export default Header;
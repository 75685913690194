import React from "react";

import { SubmitButton } from "../SubmitButton";

export const PageHeader = ({ pageStatus, handleSubmit, submitForm }) => {
  return (
    <div
      className="subheader espaco-header-pedidos subheader-transparent"
      id="kt_subheader"
    >
      <div className="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
        <div className="d-flex  flex-wrap mr-2 flex-column mt-10">
          <h5 className="text-dark font-weight-bold mt-2 mb-2 mr-5">
            Cadastro de produtos
          </h5>
          <p>Adicione e edite produtos com código de barras(EAN)</p>
        </div>
        <SubmitButton
          pageStatus={pageStatus}
          handleSubmit={handleSubmit}
          submitForm={submitForm}
        />
      </div>
    </div>
  );
};

import React, { useState, useEffect } from "react";
import Api from "../../../services/api";
import { withRouter } from "react-router";

import Swal from "sweetalert2";
import LoadingOverlay from "react-loading-overlay";

import { Modal } from "react-bootstrap";
import Select from "react-select";

import "react-datepicker/dist/react-datepicker.css";
import DatePicker, { registerLocale } from "react-datepicker";
import pt from "date-fns/locale/pt-BR";
registerLocale("pt-BR", pt);

const MarketingPush = () => {
  const [caProcessa, setCaProcessa] = useState(false);
  const [formPush, setFormPush] = useState({});
  const [cidadeSelect, setCidadeSelect] = useState([]);
  const [modal, setModal] = useState(false);
  const [exibe, setExibe] = useState();
  const [agendaPush, setAgendaPush] = useState(false);
  const [data, setData] = useState();


  useEffect(() => {
    getCidadeSelect();
  }, []);

  function enviar() {
    setCaProcessa(true);

    var nIdCidade = [];
    var nFormPush = {};
    nFormPush = JSON.parse(JSON.stringify(formPush));

    if (formPush.id_cidade !== null && formPush.id_cidade !== undefined) {
      formPush.id_cidade.forEach((e) => {
        if (!e.value) {
          nIdCidade[nIdCidade.length] = e;
        } else {
          nIdCidade[nIdCidade.length] = e.value;
        }
      });
      nFormPush.id_cidade = nIdCidade;
    }

    Api.post("marketing/enviapushregiao", nFormPush).then((rps) => {
      if (rps.data.status === true) {
        setModal(true);
        setExibe(rps.data.mensagem);
      } else {
        Swal.fire({
          title: "Erro!",
          icon: "error",
          html: rps.data.erros,
          showCloseButton: true,
          showCancelButton: false,
        });
      }
      setCaProcessa(false);
    });
  }

  function fecharModal() {
    setModal(false);
  }

  function setChecked() {
    setAgendaPush(!agendaPush);
  }

  function getCidadeSelect() {
    Api.get("cidades/listar").then((rps) => {
      var cid = rps.data.cidades;
      var nCid = [];
      nCid[nCid.length] = { value: "T", label: "Todas..." };
      cid.forEach((e) => {
        nCid[nCid.length] = {
          value: e.id_cidade,
          label: e.nome_cidade + " - " + e.estado,
        };
      });
      setCidadeSelect(nCid);
    });
  }

  return (
    <>
      <div>
        <div
          className="subheader espaco-header-pedidos subheader-transparent"
          id="kt_subheader"
        >
          <div className="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
            {/*begin::Details*/}
            <div className="d-flex align-items-center flex-wrap mr-2">
              {/*begin::Title*/}
              <h5 className="text-dark font-weight-bold mt-2 mb-2 mr-5">
                Enviar push por região
              </h5>
              {/*end::Title*/}
              {/*begin::Separator*/}
              <div className="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200" />
              {/*end::Separator*/}
            </div>
            {/*end::Details*/}
            {/*begin::Toolbar*/}
            <div className="d-flex align-items-center"></div>
            {/*end::Toolbar*/}
          </div>
        </div>
        {/*begin::Entry*/}
        <div className="d-flex flex-column-fluid">
          {/*begin::Container*/}
          <div className="container">
            <LoadingOverlay active={caProcessa} spinner text="Carregando...">
              <div className="card card-custom">
                <div className="ml-10 mr-10 mt-10">
                  <div className="row">
                    <div className="form-group col-md-12">
                      <label>Cidade</label>
                      <Select
                        options={cidadeSelect}
                        placeholder="Selecione..."
                        isMulti
                        defaultValue={formPush.id_cidade?.map((item) => {
                          return cidadeSelect.find((x) => x.value === item);
                        })}
                        onChange={(e) => {
                          setFormPush({ ...formPush, id_cidade: e });
                        }}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="form-group col-md-12">
                      <label>Título da Push</label>
                      <input
                        type="text"
                        value={formPush?.titulo}
                        onChange={(e) => {
                          setFormPush({ ...formPush, titulo: e.target.value });
                        }}
                        className="form-control"
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="form-group col-md-12">
                      <label>Mensagem</label>
                      <textarea
                        className="form-control"
                        onChange={(e) => {
                          setFormPush({
                            ...formPush,
                            mensagem: e.target.value,
                          });
                        }}
                      ></textarea>
                    </div>
                  </div>

                  <div className="row">
                    <div className="form-group col-md-12">
                      <h4>Agendamento de push</h4>
                      Essa opção permite que você agende o disparo de uma push
                      notification
                      <div className="checkbox-inline mt-5">
                        <label className="checkbox checkbox-square checkbox-primary m-t-5">
                          <input
                            type="checkbox"
                            checked={agendaPush}
                            onChange={setChecked}
                          />
                          Agendar push
                          <span />
                        </label>
                      </div>
                    </div>
                  </div>

                  {agendaPush && (
                    <div className="row">
                      <div className="col-md-12 form-group">
                        <label>Agendar para:</label>
                        <div className="input-group">
                          <DatePicker
                            name="dt_inicio"
                            onChange={(date) => {
                              setData(date);
                              setFormPush({ ...formPush, send_after: date });
                            }}
                            isClearable
                            selectsStart
                            showTimeSelect
                            locale="pt-BR"
                            className="form-control"
                            placeholderText="dd/mm/aaaa hh:mm"
                            timeFormat="HH:mm"
                            selected={data}
                            dateFormat="dd/MM/y HH:mm"
                          />
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="row">
                    <div className="col-md-12 mb-12 text-right">
                      <button
                        className="btn btn-primary font-weight-bold"
                        onClick={(e) => {
                          enviar();
                        }}
                      >
                        <i className="fab fa-telegram-plane" />
                        Enviar
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </LoadingOverlay>
          </div>
        </div>
      </div>

      <Modal size={"lg"} centered show={modal} onHide={() => fecharModal()}>
        <Modal.Header>
          <Modal.Title>Enviado</Modal.Title>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={(e) => {
              fecharModal();
            }}
          >
            <i aria-hidden="true" className="ki ki-close" />
          </button>
        </Modal.Header>

        <div className="row row ml-3 mr-3">
          <div className="col-md-12">
            <label dangerouslySetInnerHTML={{ __html: exibe }} />
          </div>
        </div>

        <Modal.Footer>
          <button
            type="button"
            onClick={(e) => {
              fecharModal();
            }}
            className="btn btn-light-primary font-weight-bold"
            data-dismiss="modal"
          >
            <i className="fas fa-door-open" />
            Fechar
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default withRouter(MarketingPush);

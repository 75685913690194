import React, { useState, useEffect } from 'react';
import Api from '../../../../services/api';
import { withRouter } from "react-router";

import Swal from "sweetalert2";
import { useToasts } from 'react-toast-notifications';
import LoadingOverlay from 'react-loading-overlay';

import DataTable from 'react-data-table-component';
import { Modal } from "react-bootstrap";

import { Link } from 'react-router-dom';

const Bairros = () => {
  const { addToast } = useToasts();
  const [ locais, setLocais ] = useState([]);
  const [ caProcessa, setCaProcessa ] = useState(false);
  const [ modal, setModal ] = useState(false);
  const [ formLocal, setFormLocal ] = useState({});
  const [ acao, setAcao ] = useState();
  let acesso = JSON.parse(localStorage.getItem('@ADM-Aiboo/perfil'));
  
  function fecharModal(){
    setModal(false);
  }

  const data = locais;
  const columns = [
    {
      name: <th>ID</th>,
      selector: 'id_bairro',
      sortable: true,
      width: '10%',
      center: true,
    },
    {
      name: <th>Nome do bairro</th>,
      selector: 'nome_bairro',
      width: '35%',
      sortable: true,
    },
    {
      name: <th>Data de cadastro</th>,
      selector: 'data_criacao',
      width: '15%',
      sortable: true,
      center: true,
    },
    {
      name: <th>Estabelecimentos vinculados</th>,
      selector: 'uso',
      width: '20%',
      sortable: true,
      center: true,
    },
    {
      name: <th>Status</th>,
      width: '10%',
      sortable: true,
      selector: 'status',
      center: 'true',
      cell: row => { if (row.status == "S") {return <span class="label label-success label-inline mr-2">Ativo</span>}
        else {return <span class="label label-secondary label-inline mr-2">Desativado</span>}
      }
    },
    {
      name: <th>Ações</th>,
      cell: (row) => 
      { return (<>
      <div class="dropdown dropdown-inline">
      <button type="button" class="btn btn-light-primary btn-icon btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <i class="ki ki-bold-more-ver"></i>
      </button>
      <div class="dropdown-menu">
        <span class="dropdown-item" onClick={e => { editarLocal(row) }}>Editar</span>
        {acesso.est_ger_loc_ei === "1" && <>
        <div class="dropdown-divider"></div>
        <span class="dropdown-item" onClick={e => { inutilizarLocal(row) }}>Inutilizar</span>
        </>}
      </div>
    </div>
    </>)
      },
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      right: true,
    },
  ];

   useEffect(() => {
    getListar();
  }, []);

  function getListar() { 
    var data = {
      id_cidade: sessionStorage.getItem('id_cidade')
    }

    setCaProcessa(true); 
    Api.post("bairros/listarComUso", data).then(rps => {
      setLocais(rps.data.obj);
      setCaProcessa(false);
    })
  }
  
  function adicionarLocal(){
    setFormLocal({});
    setModal(true);
    setAcao('Adicionar');
  }

  function editarLocal(local){  
    var ca = JSON.parse(JSON.stringify(local));

    setAcao('Alterar');
    setFormLocal(ca);
    setModal(true);            
  }

  function salvar(){
    setCaProcessa(true);

    formLocal.id_cidade = sessionStorage.getItem('id_cidade');

    Api.post('bairros/addBairroCidadeLote', formLocal).then(rps => {
      if (rps.data.status === true) {
        addToast(rps.data.mensagem, {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 2000
        });
        setModal(false);
        setFormLocal({});
        getListar();
      } else {
        Swal.fire({
          title: "Erro!",
          icon: "error",
          html: rps.data.erros,
          showCloseButton: true,
          showCancelButton: false,
        });
      }
      setCaProcessa(false);
    });
  }

  function salvarAlteracao(){
    setCaProcessa(true);

    Api.post('bairros/edtBairroCidade', formLocal).then(rps => {
      if (rps.data.status === true) {
        addToast(rps.data.mensagem, {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 2000
        });
        setModal(false);
        setFormLocal({});
        getListar();
      } else {
        Swal.fire({
          title: "Erro!",
          icon: "error",
          html: rps.data.erros,
          showCloseButton: true,
          showCancelButton: false,
        });
      }
      setCaProcessa(false);
    });
  }

  function inutilizarLocal(local) {
    var ca = JSON.parse(JSON.stringify(local));

    setCaProcessa(true);

    Swal.fire({
        icon: "question",
        title: 'Confirmação',
        html: 'Deseja inutilizar o registro?',
        showCancelButton: true,
        confirmButtonText: `Sim, Confirmar!`,
        cancelButtonText: 'Não, Cancelar.'
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        Api.post('bairros/inutilizar', ca).then(rps => {
          
          if(rps.data.status==true){
            addToast(rps.data.mensagem, {
              appearance: "success",
              autoDismiss: true,
              autoDismissTimeout: 2000
            });
            getListar();
          } 
        })
      } 
      setCaProcessa(false);
    })
  }
    
    return (
        <>
<div>

<div className="subheader espaco-header-pedidos subheader-transparent" id="kt_subheader">
          <div className="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
            {/*begin::Details*/}
            <div className="d-flex align-items-center flex-wrap mr-2">
              {/*begin::Title*/}
              <h5 className="text-dark font-weight-bold mt-2 mb-2 mr-5">Gerenciar Locais</h5>
              {/*end::Title*/}
              {/*begin::Separator*/}
              <div className="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200" />
              {/*end::Separator*/}
              {/*begin::Title*/}
              <h5 className="text-dark font-weight-bold mt-2 mb-2 mr-5">Bairros</h5>
              {/*end::Title*/}
            </div>
            {/*end::Details*/}
            {/*begin::Toolbar*/}
            <div className="d-flex align-items-center">
              <Link to='/estabelecimentos/locais' className="btn btn-lg btn-secondary mt-3 mb-3 mr-3"><i className="fas fa-arrow-left"/>Voltar</Link>
              <button className="btn btn-lg btn-success mt-3 mb-3" onClick={e => {adicionarLocal()}}><i className="fas fa-plus" />Adicionar</button>
            </div>
            {/*end::Toolbar*/}
          </div>
        </div>
        {/*begin::Entry*/}
        <div className="d-flex flex-column-fluid">
          {/*begin::Container*/}
          <div className="container">
            <div className="card card-custom">

              <LoadingOverlay
                active={caProcessa}
                spinner
                text='Carregando...'
              >                 
                <DataTable
                  title="Lista de Bairros"
                  columns={columns}
                  data={data}
                  striped="true" 
                  pagination="true"
                />
              </LoadingOverlay>                    
      
                  </div>
                  </div>
                  </div>
                  </div>
                  
      <Modal size={'xl'} show={modal} onHide={() => fecharModal()}>
      <Modal.Header>
      <Modal.Title>{acao} Bairros</Modal.Title>
          <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={e => {fecharModal()}}>
            <i aria-hidden="true" className="ki ki-close" />
          </button>
      </Modal.Header>
      <div className="row mt-5 ml-5 mr-5">
        <div className="form-group col-md-12">
          <label>Nome do Bairro (1 bairro por linha)</label>
          { acao == 'Alterar' ?
            <input type="text" value={formLocal?.nome_bairro} onChange={e => {setFormLocal({...formLocal, nome_bairro: e.target.value})}} className="form-control" />
          : 
            <textarea className="form-control" rows="5" value={formLocal?.bairros} onChange={e => {setFormLocal({...formLocal, bairros: e.target.value})}}></textarea>
          }
         </div>
  
      </div>    
      
      <Modal.Footer>
        <button type="button" onClick={e => { fecharModal(); }} className="btn btn-light-primary font-weight-bold" data-dismiss="modal"><i className="fas fa-door-open" />Fechar</button>
        { acao == 'Alterar' ?
        <button type="button" onClick={e => { salvarAlteracao(); }} className="btn btn-success font-weight-bold"><i className="fas fa-save" />Salvar</button>
          :
        <button type="button" onClick={e => { salvar(); }} className="btn btn-success font-weight-bold"><i className="fas fa-save" />Salvar</button>
        }
      </Modal.Footer>
    </Modal>
             
        </>
    );
}

export default withRouter(Bairros);                  
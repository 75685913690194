import React, { useState, useEffect } from 'react'

export function Menu({ onClickMenu }) {
    const [logoLoja, setLogoLoja] = useState(null);

    useEffect(() => {
        const logo = localStorage.getItem('@ADM-Aiboo/logoLoja');

        if (logo){
            setLogoLoja(logo);
        }
    }, []);

    return (
        <button onClick={onClickMenu} className="btn btn-icon aside-toggle ml-n3 mr-10">
            {logoLoja!=null && 
                <img alt="Logo" src={logoLoja} className="logo-no-menu" />
            }
            <span className="svg-icon svg-icon-xxl svg-icon-dark-75 icon-menu-ajust">
                <i className="icon-xl fas fa-align-justify" />
            </span>
        </button>
    );
}
import styled from "styled-components";

export const Container = styled.div`
  strong {
    display: block;
    font-size: 14;
    font-weight: 400;
    color: #ff3333;

    margin-top: 8px;
  }
`;

export const Label = styled.label`
  font-weight: 600;
  font-style: normal;
  font-size: 16px;
  line-height: 22px;
  min-width: 200px;

  display: flex;
  flex: 1;
  align-items: center;
  text-align: right;

  color: #333333;

  display: flex;
  gap: 4px;

  span {
    color: #ff3333;
  }
`;

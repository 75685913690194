import React, { useEffect, useCallback } from "react";
import { useForm } from "react-hook-form";

import { CustomDraggableInputFile } from "../../../../components";
import { AddCategoryModal } from "../AddCategoryModal";
import Loading from "../../../produtos/components/Table/Loading";

import * as S from "./styles";
import { PageWrapper } from "../PageWrapper";
import { SubmitButton } from "../SubmitButton";
import { TextInputs } from "../TextInputs";
import { DropDownCategories } from "../DropDownCategories";

function ProductForm({
  productId,
  imagePreview,
  setImagePreview,
  formatCategoriesOptions,
  pageStatus,
  setPageStatus,
  modalVisibility,
  handleToggleCreateProductCategoryModal,
  selectedCategory,
  setSelectedCategory,
  categories,
  fetchCategories,
  onSubmit,
  checkFormMode,
  isOnEditMode,
  fetchProductFormDataToEdit,
  productToEditData,
  attemptSubmitWithoutCategory,
}) {
  const {
    register,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      category: 0,
    },
  });

  const [image] = watch(["image"]);

  const categoriesOptions = formatCategoriesOptions(categories);
  const categoryRequiredBeforeSubmit =
    Object.keys(errors).length > 0 && !selectedCategory.value;

  const populateForm = useCallback(() => {
    if (productToEditData) {
      const categoryToSelect = {
        value: productToEditData.category?.id_produto_categoria,
        label: productToEditData.category?.nome,
      };
      setSelectedCategory(categoryToSelect);
      reset({
        eanCode: productToEditData.codigo_barras,
        productName: productToEditData.nome_produto,
        description: productToEditData.descricao,
        brand: productToEditData.marca,
        image: productToEditData.imagem_original,
        category: categoryToSelect,
      });
    }
  }, [productToEditData, reset, setSelectedCategory]);

  const setupImagePreview = useCallback(() => {
    if (image && typeof image === "string") {
      setImagePreview(image);

      return;
    }

    if (image && image[0]) {
      const newUrl = URL.createObjectURL(image[0]);

      setImagePreview((prevState) =>
        newUrl !== prevState ? newUrl : prevState
      );
    }
  }, [image, setImagePreview]);

  useEffect(() => {
    setupImagePreview();
  }, [setupImagePreview]);

  useEffect(() => {
    fetchCategories();
    checkFormMode(productId);
  }, [checkFormMode, fetchCategories, productId]);

  useEffect(() => {
    if (isOnEditMode) {
      fetchProductFormDataToEdit(productId);
    }
  }, [fetchProductFormDataToEdit, isOnEditMode, productId]);

  useEffect(() => {
    populateForm();
  }, [populateForm]);

  const submitForm = (data) => {
    onSubmit(data, productId);
  };

  if (pageStatus === "loading" && categories.length === 0) {
    return (
      <PageWrapper pageStatus={pageStatus}>
        <Loading />;
      </PageWrapper>
    );
  }

  return (
    <PageWrapper
      pageStatus={pageStatus}
      handleSubmit={handleSubmit}
      submitForm={submitForm}
    >
      <S.FormWrapper>
        <TextInputs errors={errors} register={register} />
        <S.FormItem>
          <label>
            Categoria <span> *</span>
          </label>
          <DropDownCategories
            register={register}
            selectedCategory={selectedCategory}
            setSelectedCategory={setSelectedCategory}
            categoriesOptions={categoriesOptions}
            requiredError={
              categoryRequiredBeforeSubmit || attemptSubmitWithoutCategory
            }
            handleToggleCreateProductCategoryModal={
              handleToggleCreateProductCategoryModal
            }
          />

          <S.ImageUploaderContainer>
            <label>Imagem do produto</label>
            <div className="form-group" style={{ height: 260, width: 260 }}>
              <CustomDraggableInputFile
                type="file"
                register={register("image")}
                id="image"
                accept=".png, .jpg, .jpeg"
                imagePreview={imagePreview}
              />
            </div>
          </S.ImageUploaderContainer>

          <SubmitButton
            pageStatus={pageStatus}
            handleSubmit={handleSubmit}
            submitForm={submitForm}
          />
        </S.FormItem>
      </S.FormWrapper>

      <AddCategoryModal
        handleToggleCreateProductCategoryModal={
          handleToggleCreateProductCategoryModal
        }
        modalVisibility={modalVisibility}
        pageStatus={pageStatus}
        setPageStatus={setPageStatus}
        fetchCategories={fetchCategories}
        categoriesOptions={categoriesOptions}
        setSelectedCategory={setSelectedCategory}
        setValue={setValue}
      />
    </PageWrapper>
  );
}

export default ProductForm;

import React, { memo } from "react";

import LoadingOverlay from "react-loading-overlay";

const ExternalDeliveryTabComponent = ({
  statusIntegracaoLeveAi,
  loadingIntegracaoLeveAi,
  changeIntegracaoLeveai,
  canCallExternalDeliveryWithCode,
  handleChangeCallExternalDeliveryWithCode,
}) => {
  return (
    <div
      className="tab-pane fade"
      id="integracao"
      role="tabpanel"
      aria-labelledby="integracao"
    >
      <div className="row">
        <div className="col-md-2">
          <h5>Ativar Integração</h5>
          <div>
            <LoadingOverlay
              active={loadingIntegracaoLeveAi}
              spinner
              text="Carregando..."
            >
              <span className="switch switch-lg switch-icon">
                <label>
                  <input
                    defaultChecked={statusIntegracaoLeveAi}
                    checked={statusIntegracaoLeveAi}
                    type="checkbox"
                    onClick={(e) => changeIntegracaoLeveai(e.target.checked)}
                    name="select"
                  />
                  <span></span>
                </label>
              </span>
            </LoadingOverlay>
          </div>
        </div>
      </div>

      {statusIntegracaoLeveAi && (
        <div className="row col-md-12 w-100">
          <div className="w-100">
            <h5>Configurações</h5>

            <div className="mt-6 d-flex align-items-center w-100">
              <div className="checkbox-inline">
                <label className="checkbox checkbox-square checkbox-primary mb-0">
                  <input
                    type="checkbox"
                    // value={callExternalDeliveryWithCode}
                    onChange={(e) =>
                      handleChangeCallExternalDeliveryWithCode(e.target.checked)
                    }
                    checked={canCallExternalDeliveryWithCode}
                  />
                  <p style={{ marginLeft: 8 }}>
                    Vincular entregador via código
                  </p>
                  <span />
                </label>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export const ExternalDeliveryTab = memo(ExternalDeliveryTabComponent);

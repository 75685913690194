import React, { useState, useEffect } from 'react';
import Api from '../../../services/api';
import { withRouter } from "react-router";

import Swal from "sweetalert2";

import { Table, Column, HeaderCell, Cell } from 'rsuite-table';
import 'rsuite-table/dist/css/rsuite-table.css';

import Select from 'react-select';
import LoadingOverlay from 'react-loading-overlay';
import ReactTooltip from 'react-tooltip';

import "react-datepicker/dist/react-datepicker.css";
import DatePicker, { registerLocale } from "react-datepicker";
import InputMask from 'react-input-mask';
import pt from "date-fns/locale/pt-BR"; 
registerLocale("pt-BR", pt);

const RelVendasCidade = () => {
  var d = new Date();
  d.setDate(d.getDate() - 30);
  const [sortColumn, setSortColumn] = React.useState();
  const [sortType, setSortType] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const [ sumario, setSumario ] = useState();
  const [ tabela, setTabela ] = useState();
  const [ cidadeSelect, setCidadeSelect ] = useState([]);
  const [ cidade, setCidade ] = useState([]);
  const [ estabelecimentoSelect, setEstabelecimentoSelect ] = useState([]);
  const [ estabelecimento, setEstabelecimento ] = useState([]);
  const [ representanteSelect, setRepresentanteSelect ] = useState([]);
  const [ representante, setRepresentante ] = useState([]);
  const [ dataInicial, setDataInicial ] = useState(d);
  const [ dataFinal, setDataFinal ] = useState(new Date());
  const [ mostrar, setMostrar ] = useState('Não');
  const [ caProcessa, setCaProcessa ] = useState(false);
  const [ caProcessaEstabelecimento, setCaProcessaEstabelecimento ] = useState(false);
  
  const getData = () => {
    if (sortColumn && sortType) {
      return tabela.sort((a, b) => {
        let x = a[sortColumn];
        let y = b[sortColumn];
        if (typeof x === 'string') {
          x = x.charCodeAt();
        }
        if (typeof y === 'string') {
          y = y.charCodeAt();
        }
        if (sortType === 'asc') {
          return x - y;
        } else {
          return y - x;
        }
      });
    }
    return tabela;
  };

  const handleSortColumn = (sortColumn, sortType) => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setSortColumn(sortColumn);
      setSortType(sortType);
    }, 500);
  };
 
  useEffect(() => {
    getCidadeSelect();
    getRepresentanteSelect();
  }, []);

  useEffect(() => {
    if (cidade)
      getEstabelecimentoSelect(cidade);
  }, [cidade]);

  function getCidadeSelect() {
    var data = {
      representante: localStorage.getItem('@ADM-Aiboo/nivel') == 'R' ? localStorage.getItem('@ADM-Aiboo/id_representante') : ''
    }

    Api.post("cidades/listarcidaderepresentante", data).then(rps => {
      var cid = rps.data.cidades;
      var nCid = [];
      nCid[nCid.length] = { value: '', label: 'Selecione...' };
      cid.forEach(e => {
        nCid[nCid.length] = { value: e.id_cidade, label: e.nome_cidade + ' - ' + e.estado };
      })
      setCidadeSelect(nCid);
    })
  }

  function getEstabelecimentoSelect(cidade) {
    setCaProcessaEstabelecimento(true);
    var data = {
      representante: localStorage.getItem('@ADM-Aiboo/nivel') == 'R' ? localStorage.getItem('@ADM-Aiboo/id_representante') : '',
      'cidade': cidade
    }

    Api.post("estabelecimentos/listaNomeEstabelecimento", data).then(rps => {
      var rep = rps.data.obj;
      var nRep = [];
      nRep[nRep.length] = {value: '', label: 'Selecione...'};
      rep.forEach(e => {
        nRep[nRep.length] = {value: e.id_estabelecimento, label: e.nome};
      })
      setEstabelecimentoSelect(nRep);
      setCaProcessaEstabelecimento(false); 
    })
  }

  function getRepresentanteSelect() {
    var data = {
      representante: localStorage.getItem('@ADM-Aiboo/nivel') == 'R' ? localStorage.getItem('@ADM-Aiboo/id_representante') : '',
    }

    Api.post("representantes/listarRepresentante", data).then(rps => {
      var rep = rps.data.representantes;
      var nRep = [];
      nRep[nRep.length] = {value: '', label: 'Selecione...'};
      rep.forEach(e => {
        nRep[nRep.length] = {value: e.id_representante, label: e.nome};
      })
      setRepresentanteSelect(nRep);
    })
  }

  function filtrar() {
    setCaProcessa(true); 
      var data = {
        cidade: cidade,
        data_inicio: dataInicial,
        data_final: dataFinal,
        estabelecimento: estabelecimento,
        representante: localStorage.getItem('@ADM-Aiboo/nivel') == 'R' ? localStorage.getItem('@ADM-Aiboo/id_representante') : representante
      };
   
      Api.post('relatorios/geraRelatorioCidade', data).then((rps) => {

        if(rps.data.status === false){
          Swal.fire({
            title: "Atenção!",
            icon: "info",
            html: rps.data.erro,
            showCloseButton: true,
            showCancelButton: false,
          });
        } else {
          //setResumo(rps.data.resumo);
          setSumario(rps.data.sumario);
          if (rps.data.obj !== null)
            setTabela(rps.data.obj);
          else
            setTabela({});
          setMostrar('Sim');
        }
      setCaProcessa(false); 
    });
  }
    
    return (
        <>
<div>

<div className="subheader espaco-header-pedidos subheader-transparent" id="kt_subheader">
          <div className="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
            {/*begin::Details*/}
            <div className="d-flex align-items-center flex-wrap mr-2">
              {/*begin::Title*/}
              <h5 className="text-dark font-weight-bold mt-2 mb-2 mr-5">Relatório</h5>
              {/*end::Title*/}
              {/*begin::Separator*/}
              <div className="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200" />
              {/*end::Separator*/}
              {/*begin::Title*/}
              <h5 className="text-dark font-weight-bold mt-2 mb-2 mr-5">Vendas por cidade</h5>
              {/*end::Title*/}
              {/*begin::Separator*/}
              <div className="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200" />
              {/*end::Separator*/}
            </div>
            {/*end::Details*/}
            {/*begin::Toolbar*/}
            <div className="d-flex align-items-center">

            </div>
            {/*end::Toolbar*/}
          </div>
        </div>
        {/*begin::Entry*/}
        <div className="d-flex flex-column-fluid">
          {/*begin::Container*/}
          <div className="container">
            <div className="card card-custom">
              <div className="ml-10 mr-10 mt-10">    
                <div className="row">
                <div className="col form-group">
                    <label>Data de</label>
                      <div className="input-group">
                      <DatePicker 
                        name="dt_inicio"
                        onChange={(e) => { setDataInicial(e); setMostrar('Não') }}
                        isClearable
                        selectsStart
                        locale="pt-BR"
                        className="form-control"
                        placeholderText="dd/mm/aaaa" 
                        selected ={dataInicial}
                        dateFormat="dd/MM/y"
                        startDate={dataInicial}
                        endDate={dataFinal}
                        customInput={
                          <InputMask mask="99/99/9999" />}/>
                      </div>
                  </div>

                  <div className="col form-group">
                    <label>Data até</label>
                      <div className="input-group">
                      <DatePicker 
                        name="dt_final"
                        onChange={(e) => { setDataFinal(e); setMostrar('Não') }}
                        isClearable
                        locale="pt-BR"
                        className="form-control"
                        placeholderText="dd/mm/aaaa" 
                        selected ={dataFinal}
                        dateFormat="dd/MM/y"
                        selectsEnd
                        startDate={dataInicial}
                        endDate={dataFinal}
                        minDate={dataInicial} 
                        customInput={
                          <InputMask mask="99/99/9999" />}/>
                      </div>
                    </div>

                  <div className="col-md-2 form-group">
                    <label>Cidade</label>
                    <Select
                      options={cidadeSelect}
                      placeholder="Selecione..." 
                      defaultValue={cidadeSelect.find(
                        x => x.value === cidade
                      )}
                      onChange={(e) => { setCidade(e.value); setMostrar('Não') }} 
                    /> 
                  </div> 

                  <div className="col-md-3 form-group">
                    <LoadingOverlay
                      active={caProcessaEstabelecimento}
                      spinner
                      text='Carregando...'
                    >
                    <label>Estabelecimento</label>
                    <Select
                      options={estabelecimentoSelect}
                      placeholder="Selecione..." 
                      defaultValue={estabelecimentoSelect.find(
                        x => x.value === estabelecimento
                      )}
                      onChange={(e) => { setEstabelecimento(e.value); setMostrar('Não') }} 
                    />
                    </LoadingOverlay>
                  </div>

                  <div className="form-group col-md-2">
                  <ReactTooltip />
                  <label>Representante</label>
                    <Select
                      options={representanteSelect}
                      placeholder="Selecione..." 
                      defaultValue={representanteSelect.find(
                        x => x.value === representante
                      )}
                      onChange={(e) => { setRepresentante(e.value); setMostrar('Não') }} 
                    /> 
                </div>

                  <div className="col form-group">
                  <label>&nbsp;</label><br />
                    <button className="btn btn-block btn-primary" onClick={e=>{filtrar()}}><i className="fas fa-filter" />Filtrar</button>
                  </div> 
                </div>     
              </div>

              <LoadingOverlay
                            active={caProcessa}
                            spinner
                            text='Carregando...'
                          >
                            
              <h3 className='text-dark font-weight-bold ml-5 mt-10'>Dados por Cidade</h3>
              
              { mostrar === 'Sim' && <>
              <Table height={1000} headerHeight={60} resizable
                     data={getData()}
                     sortColumn={sortColumn}
                     sortType={sortType}
                     onSortColumn={handleSortColumn}
                     loading={loading}
              >
                <Column width={150} sortable resizable>
                  <HeaderCell><b>Cidade</b></HeaderCell>
                  <Cell dataKey="cidade" />
                </Column>
                <Column width={120} sortable resizable align="center">
                  <HeaderCell><b>População</b></HeaderCell>
                  <Cell dataKey="populacao" />
                </Column>
                <Column width={120} sortable resizable align="right">
                  <HeaderCell><b>GMV</b></HeaderCell>
                  <Cell dataKey="gmv">
                    {(rowData => { 
                        return rowData.gmv }
                    )}
                  </Cell>
                </Column>
                <Column width={120} sortable resizable align="center">
                  <HeaderCell><b>Pedidos</b></HeaderCell>
                  <Cell dataKey="pedidos" />
                </Column>
                <Column width={120} sortable resizable align="center">
                  <HeaderCell><b>Empresas <br />Cadastradas</b></HeaderCell>
                  <Cell dataKey="empresas_cadastradas" />
                </Column>
                <Column width={120} sortable resizable align="center">
                  <HeaderCell><b>Empresas <br />Ativas</b></HeaderCell>
                  <Cell dataKey="empresas_ativas" />
                </Column>
                <Column width={120} sortable resizable align="center">
                  <HeaderCell><b>Empresas por <br />População</b></HeaderCell>
                  <Cell dataKey="empresas_populacao">
                    {(rowData => { if (rowData.empresas_populacao < 1) {
                        return <span class="label label-danger label-inline mr-2">{rowData.empresas_populacao.toFixed(2)}</span> }
                      else {
                        return <span class="label label-success label-inline mr-2">{rowData.empresas_populacao.toFixed(2)}</span> }
                      }
                    )}
                  </Cell>
                </Column>
                <Column width={120} sortable resizable align="center">
                  <HeaderCell><b>GMV por <br />Habitante</b></HeaderCell>
                  <Cell dataKey="gmv_habitantes">
                    {(rowData => { if (rowData.gmv_habitantes < 4) {
                        return <span class="label label-danger label-inline mr-2">{rowData.gmv_habitantes.toFixed(2)}</span> }
                      else {
                        return <span class="label label-success label-inline mr-2">{rowData.gmv_habitantes.toFixed(2)}</span> }
                      }
                    )}
                  </Cell>
                </Column>
                <Column width={300} sortable resizable>
                  <HeaderCell><b>Concorrentes</b></HeaderCell>
                  <Cell dataKey="concorrentes" />
                </Column>

              </Table>
                  
              <div className="sumario ml-5 mb-10" dangerouslySetInnerHTML={{__html: sumario}}></div>
              </>
              }
              </LoadingOverlay> 

                    
      
                  </div>
                  </div>
                  </div>
                  </div>
                  
             
        </>
    );

}



export default withRouter(RelVendasCidade);                  
import React, { useEffect } from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import Routes from "./routes";
import { usePersonalUserInfo } from "infra/usePersonalUserInfo";

const queryClient = new QueryClient();

function App() {
  const { initializePersonalUserHeaders } = usePersonalUserInfo();

  useEffect(() => {
    initializePersonalUserHeaders();
  }, [initializePersonalUserHeaders]);

  return (
    <QueryClientProvider client={queryClient}>
      <Routes />
    </QueryClientProvider>
  );
}

export default App;

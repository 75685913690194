import React from 'react';

// import { Container } from './styles';

function ModalEsqueci() {
  return (
    <div className="modal fade" id="ModalEsqueci" tabIndex={-1} role="dialog" aria-hidden="true">
        <div className="modal-dialog" role="document">
        <div className="modal-content">
            <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">Esqueci minha senha</h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <i aria-hidden="true" className="ki ki-close" />
            </button>
            </div>
            <div className="modal-body">
            <p className="font-size-h6">Enviaremos um link por e-mail com instruções para você redefinir sua senha.</p>
            <div className="form-group mt-10">
                <label className="font-size-h6 font-weight-bolder text-dark">Digite abaixo o seu Email</label>
                <input className="form-control form-control-solid h-auto py-5 px-6 rounded-lg" type="text" name="username" autoComplete="off" />
            </div>
            </div>
            <div className="modal-footer">
            <button type="button" className="btn btn-light-primary font-weight-bold" data-dismiss="modal">Cancelar</button>
            <button type="button" className="btn btn-primary font-weight-bold">Redefinir senha</button>
            </div>
        </div>
        </div>
    </div>
  );
}

export default ModalEsqueci;
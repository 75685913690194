import styled from "styled-components";

export const FormField = styled.div`
  display: flex;
  flex-direction: column;

  label {
    font-size: 16px;
    font-weight: 600;
    color: #333333;
  }

  input,
  textarea {
    border-style: solid;
    border-width: 1px;
    border-color: ${({ error }) => (error ? "#FF3333" : "#e5eaee")};
    border-radius: 12px;
    font-size: 16px;
    padding: 10px 15px;
  }

  span {
    color: #ff3333;
    margin-top: 10px;
  }
`;

import { useState } from "react";
import { useToasts } from "react-toast-notifications";

import { merchantService } from "services";
import { addressService } from "services/addressService";

export const useZipCode = () => {
  const [isLoading, setIsLoading] = useState(false);

  const { addToast } = useToasts();

  const fetchAddressByZipeCode = async (zipCode) => {
    setIsLoading(true);

    const zipCodeData = await merchantService.getZipCodeData(zipCode);
    const address = await addressService.getAddressByIBGECode(zipCodeData.ibge);

    setIsLoading(false);

    if (!address.status) {
      addToast("CEP não encontrado, tente novamente.", {
        appearance: "error",
        autoDismiss: true,
        autoDismissTimeout: 4000,
      });

      return;
    }

    return {
      ...zipCodeData,
      ...address,
    };
  };

  return { fetchAddressByZipeCode, isLoading };
};

const REGEX_TO_REMOVE_ALL_NOT_DIGIT = /\D/g;

const maskToRealCurrency = (value, discount = false) => {
  if (!value) {
    return "";
  }

  const REGEX_TO_ADD_CENTS = /(\d+)(\d{2})$/;
  const REGEX_TO_ADD_DOTS_TO_EVERY_THREE_DIGITS = /(\d)(?=(\d{3})+(?!\d))/g;

  const formattedValue = value
    .replace(REGEX_TO_REMOVE_ALL_NOT_DIGIT, "")
    .replace(REGEX_TO_ADD_CENTS, "$1,$2")
    .replace(REGEX_TO_ADD_DOTS_TO_EVERY_THREE_DIGITS, "$1.");

  return formattedValue ? `${discount ? "-R$" : "R$"} ${formattedValue}` : "";
};

const removeRealCurrencyMask = (value) => {
  const formattedValue = value
    .replace(/-R\$\s|R\$\s/, "")
    .replace(/\.(?=\d)/g, "")
    .replace(",", ".");

  return formattedValue;
};

const maskToCpfOrCnpj = (value) => {
  value = value.replace(REGEX_TO_REMOVE_ALL_NOT_DIGIT, "");

  if (value.length <= 11) {
    return maskToCpf(value);
  }

  value = value.substring(0, 14);
  value = value.replace(/^(\d{2})(\d)/, "$1.$2");
  value = value.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3");
  value = value.replace(/\.(\d{3})(\d)/, ".$1/$2");
  value = value.replace(/(\d{4})(\d)/, "$1-$2");

  return value;
};

const maskToRg = (value) => {
  value = value.substring(0, 14);
  value = value.replace(REGEX_TO_REMOVE_ALL_NOT_DIGIT, "");
  value = value.replace(/(\d{1,2})(\d{3})(\d{2,3})(\d{1})$/, "$1.$2.$3-$4");

  return value;
};

const maskToCpf = (value) => {
  const formattedValue = value
    .replace(REGEX_TO_REMOVE_ALL_NOT_DIGIT, "")
    .substring(0, 11)
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d{1,2})$/, "$1-$2");

  return formattedValue;
};

export const stringUtil = {
  maskToRealCurrency,
  maskToCpfOrCnpj,
  maskToRg,
  maskToCpf,
  removeRealCurrencyMask,
};

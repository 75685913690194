import { useMutation } from "@tanstack/react-query";
import { areaService } from "services";
import { stringUtil } from "utils";

export const useAddNewArea = ({ onSuccess, onError }) => {
  const mutation = useMutation({
    mutationFn: (formattedData) => areaService.addNewArea(formattedData),

    onSuccess: (data) => {
      onSuccess?.(data);
    },

    onError: (error) => {
      onError?.(error);
    },
  });

  const addNewArea = (data) => {
    const formattedData = {
      ...data,
      id_bairro: data.id_bairro.value,
      valor_minimo: data.valor_minimo
        ? stringUtil.removeRealCurrencyMask(data.valor_minimo)
        : 0,
      valor_taxa: data.valor_taxa
        ? stringUtil.removeRealCurrencyMask(data.valor_taxa)
        : 0,
      valor_frete_gratis: data.valor_frete_gratis
        ? stringUtil.removeRealCurrencyMask(data.valor_frete_gratis)
        : 0,
    };

    mutation.mutate(formattedData);
  };

  return {
    addNewArea,
    isLoading: mutation.isPending,
    isError: mutation.isError,
  };
};

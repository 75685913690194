import api from "../api";

const listPaymentMethodsByMerchantId = async (merchantId) => {
  const response = await api.post("formaspagamentos/listarpagamentos", {
    id_estabelecimento: merchantId,
  });

  return response.data;
};

export const paymentService = { listPaymentMethodsByMerchantId };

import * as S from "./styles";

export function CustomDraggableInputFile({ register, imagePreview }) {
  return (
    <S.Container>
      {imagePreview ? (
        <div className="area-imagem dropzone--squared menu-category-drawer-item-details-tab__image">
          <div
            className="dropzone__area"
            aria-disabled="false"
            style={{ position: "relative" }}
          >
            <div className="dropzone-preview dropzone__preview">
              <img
                className="dropzone-preview__image mw-100 h-auto d-block"
                src={imagePreview}
                alt="Prévio da imagem"
              />
              <S.SwitchImageTag>
                <p>trocar imagem</p>
              </S.SwitchImageTag>
            </div>
          </div>
        </div>
      ) : (
        <span>
          <i class="la la-image" style={{ color: "#ff3333", fontSize: 20 }}></i>
        </span>
      )}

      <S.Input type="file" {...register} />
    </S.Container>
  );
}

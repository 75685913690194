import React, { useEffect } from 'react';
import { withRouter } from "react-router";

  const Painel = () => {

  useEffect(() => {
  }, []);

    return (
        <>

        {/*begin::Content*/}
        <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
          {/*begin::Entry*/}
          <div className="d-flex flex-column-fluid">
            {/*begin::Container*/}
            <div className="container">
              {/*begin::Dashboard*/}
                <div className='row'>
                  <div className='col-md-12 text-center'>
                    <img alt="aiboo imagem" src="assets/imgs/aiboo2.png" className='img-fluid' />
                  </div>
                </div>
                
              </div>
          </div>
      </div>

        {/*begin::Footer*/}
        <div className="footer bg-white py-4 d-flex flex-lg-column" id="kt_footer">
          {/*begin::Container*/}
          <div className="container d-flex flex-column flex-md-row align-items-center justify-content-between">
            {/*begin::Copyright*/}
            <div className="text-dark order-2 order-md-1">
              <span className="text-muted font-weight-bold mr-2">2022©</span>
              <a href="http://keenthemes.com/metronic" target="_blank" className="text-dark-75 text-hover-primary" rel="noreferrer">Aiboo</a>
            </div>
            {/*end::Copyright*/}
            {/*begin::Nav*/}
            <div className="nav nav-dark order-1 order-md-2">
              <a href="http://keenthemes.com/metronic" target="_blank" className="nav-link pr-3 pl-0" rel="noreferrer">About</a>
              <a href="http://keenthemes.com/metronic" target="_blank" className="nav-link px-3" rel="noreferrer">Team</a>
              <a href="http://keenthemes.com/metronic" target="_blank" className="nav-link pl-3 pr-0" rel="noreferrer">Contact</a>
            </div>
            {/*end::Nav*/}
          </div>
          {/*end::Container*/}
        </div>
        {/*end::Footer*/}
        {/*end::Wrapper*/}
        {/*end::Page*/}
        {/*end::Main*/}

                  
            </>
        );
    }
    
    export default withRouter(Painel);                  
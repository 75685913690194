import React, { useState, useEffect } from 'react';
import Api from '../../../services/api';
import { withRouter } from "react-router";

import Swal from "sweetalert2";

import Select from 'react-select';
import LoadingOverlay from 'react-loading-overlay';

const RelMapa = () => {
  
  const [ mapa, setMapa ] = useState([]);
  const [ estabelecimento, setEstabelecimento ] = useState([]);
  const [ estabelecimentoSelect, setEstabelecimentoSelect ] = useState([]);
  const [ cidadeSelect, setCidadeSelect ] = useState([]);
  const [ cidade, setCidade ] = useState([]);
  const [ representanteSelect, setRepresentanteSelect ] = useState([]);
  const [ buscaEstabelecimento, setBuscaEstabelecimento ] = useState();
  const [ buscaCidade, ] = useState();
  const [ buscaRepresentante, setBuscaRepresentante ] = useState();
  const [ caProcessa, setCaProcessa ] = useState(false);
  const [ caProcessaEstabelecimento, setCaProcessaEstabelecimento ] = useState(false);
 
  useEffect(() => {
    getCidadeSelect();
    getRepresentanteSelect();
  }, []);

  useEffect(() => {
    if (cidade)
      getEstabelecimentoSelect(cidade);
  }, [cidade]);

  function getEstabelecimentoSelect(cidade) {
    setCaProcessaEstabelecimento(true);
    var data = {
      representante: localStorage.getItem('@ADM-Aiboo/nivel') == 'R' ? localStorage.getItem('@ADM-Aiboo/id_representante') : '',
      'cidade': cidade
    }

    Api.post("estabelecimentos/listaNomeEstabelecimento", data).then(rps => {
      var rep = rps.data.obj;
      var nRep = [];
      nRep[nRep.length] = {value: '', label: 'Selecione...'};
      rep.forEach(e => {
        nRep[nRep.length] = {value: e.id_estabelecimento, label: e.nome};
      })
      setEstabelecimentoSelect(nRep);
      setCaProcessaEstabelecimento(false); 
    })
  }

  function getCidadeSelect() {
    var data = {
      representante: localStorage.getItem('@ADM-Aiboo/nivel') == 'R' ? localStorage.getItem('@ADM-Aiboo/id_representante') : ''
    }

    Api.post("cidades/listarcidaderepresentante", data).then(rps => {
      var cid = rps.data.cidades;
      var nCid = [];
      nCid[nCid.length] = { value: '', label: 'Selecione...' };
      cid.forEach(e => {
        nCid[nCid.length] = { value: e.id_cidade, label: e.nome_cidade + ' - ' + e.estado };
      })
      setCidadeSelect(nCid);
    })
  }

  function getRepresentanteSelect() {
    var data = {
      representante: localStorage.getItem('@ADM-Aiboo/nivel') == 'R' ? localStorage.getItem('@ADM-Aiboo/id_representante') : '',
    }

    Api.post("representantes/listarRepresentante", data).then(rps => {
      var rep = rps.data.representantes;
      var nRep = [];
      nRep[nRep.length] = {value: '', label: 'Selecione...'};
      rep.forEach(e => {
        nRep[nRep.length] = {value: e.id_representante, label: e.nome};
      })
      setRepresentanteSelect(nRep);
    })
  }

  function filtrar() {
    setCaProcessa(true); 
      var data = {
        estabelecimento: buscaEstabelecimento,
        cidade: buscaCidade,
        representante: localStorage.getItem('@ADM-Aiboo/nivel') == 'R' ? localStorage.getItem('@ADM-Aiboo/id_representante') : buscaRepresentante
      };
   
      Api.post('relatorios/mapa', data).then((rps) => {

        if(rps.data.status==false){
          Swal.fire({
            title: "Atenção!",
            icon: "info",
            html: rps.data.erro,
            showCloseButton: true,
            showCancelButton: false,
          });
        } else {
          setMapa(rps.data.obj);
          setEstabelecimento(rps.data.est);
        }
      setCaProcessa(false); 
    });
  }

    
    return (
        <>
<div>

<div className="subheader espaco-header-pedidos subheader-transparent" id="kt_subheader">
          <div className="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
            {/*begin::Details*/}
            <div className="d-flex align-items-center flex-wrap mr-2">
              {/*begin::Title*/}
              <h5 className="text-dark font-weight-bold mt-2 mb-2 mr-5">Relatório</h5>
              {/*end::Title*/}
              {/*begin::Separator*/}
              <div className="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200" />
              {/*end::Separator*/}
              {/*begin::Title*/}
              <h5 className="text-dark font-weight-bold mt-2 mb-2 mr-5">Mapa Estabelecimento</h5>
              {/*end::Title*/}
            </div>
            {/*end::Details*/}
            {/*begin::Toolbar*/}
            <div className="d-flex align-items-center">

            </div>
            {/*end::Toolbar*/}
          </div>
        </div>
        {/*begin::Entry*/}
        <div className="d-flex flex-column-fluid">
          {/*begin::Container*/}
          <div className="container">
            <div className="card card-custom">
              <div className="ml-10 mr-10 mt-10">    
                <div className="row">
                  <div className="col-md-4 form-group">
                    <label>Cidade</label>
                      <Select
                        options={cidadeSelect}
                        placeholder="Selecione..." 
                        defaultValue={cidadeSelect.find(
                          x => x.value === cidade
                        )}
                        onChange={(e) => { setCidade(e.value) }} 
                      /> 
                  </div>
                  <div className="col-md-4 form-group">
                    <label>Estabelecimento</label>
                    <LoadingOverlay
                        active={caProcessaEstabelecimento}
                        spinner
                        text='Carregando...'
                      >
                    <Select
                      options={estabelecimentoSelect}
                      placeholder="Selecione..." 
                      defaultValue={estabelecimentoSelect.find(
                        x => x.value === buscaEstabelecimento
                      )}
                      onChange={(e) => { setBuscaEstabelecimento(e.value) }} 
                    /> 
                    </LoadingOverlay>
                  </div>
                  <div className="col-md-3 form-group">
                    <label>Representante</label>
                    <Select
                      options={representanteSelect}
                      placeholder="Selecione..." 
                      defaultValue={representanteSelect.find(
                        x => x.value === buscaRepresentante
                      )}
                      onChange={(e) => { setBuscaRepresentante(e.value) }} 
                
                    /> 
                  </div>

                   

                  <div className="col-md-1 form-group">
                  <label>&nbsp;</label><br />
                    <button className="btn btn-block btn-primary" onClick={e=>{filtrar()}}>Filtrar</button>
                  </div> 
                </div>     
              </div>

              <LoadingOverlay
                            active={caProcessa}
                            spinner
                            text='Carregando...'
                          >                     
              <div className="container">
              
              {estabelecimento.map( (est, i) => {
                var estAtual = est.id;
                var estProximo = '';
                
                  if(estabelecimento[i+1]===undefined){
                    estProximo = '';
                  } else {
                    estProximo = estabelecimento[i+1].id;
                  }

                return <>
                { estAtual !== estProximo &&
                <>
                <label>Estabelecimento: {est.id}</label>
              

              <div className="row">
              <div className="col-md-1 border text-center bg-secondary">
                <label><b>Mês</b></label><br />
                <label><b>Valor</b></label><br />
                <label><b>Qtde</b></label><br />
                <label><b>TM</b></label><br />
              </div>
                {mapa.map(item => {
                  return <>
                  { item.id_estabelecimento == est.id &&
                    <div className="col border text-center">
                      <label><b>{item.mes}</b></label><br />
                      <label>{item.valor}</label><br />
                      <label>{item.qtde}</label><br />
                      <label>{item.tm}</label><br />
                    </div>
                  }
                  </>
                })}
                </div>
                </>
              }
              </>
              }
              )}

              </div>
              
              </LoadingOverlay> 
    
                    
      
                  </div>
                  </div>
                  </div>
                  </div>
                  
        </>
    );

}



export default withRouter(RelMapa);                  
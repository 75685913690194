import { useCallback, useState, useMemo } from "react";
import { useToasts } from "react-toast-notifications";
import { useHistory } from "react-router-dom";

import Api from "../../../services/api";
import axios from "axios";

export function useBanner() {
  const [entitiesList, setEntitiesList] = useState([]);
  const [addedEntities, setAddedEntities] = useState([]);
  const [imagePreview, setImagePreview] = useState(null);
  const [entitySearch, setEntitySearch] = useState("");
  const [pageStatus, setPageStatus] = useState("idle");
  const [currentEntityToDelete, setCurrentEntityToDelete] = useState("");
  const [currentEntityToEdit, setCurrentEntityToEdit] = useState({});
  const [type, setType] = useState("establishment");
  const [notFoundSearchTerm, setNotFoundSearchTerm] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState({
    type: "",
    isShowing: false,
    message: {
      title: "",
      description: "",
    },
    labels: {
      dismiss: "",
      trigger: "",
    },
  });

  const history = useHistory();

  const { addToast } = useToasts();

  const defaultConfigs = useMemo(
    () => ({
      baseURL: Api.defaults.baseURL,
      headers: {
        "content-type": "multipart/form-data",
        Authorization: Api.defaults.headers["Authorization"],
      },
    }),
    []
  );

  const access = JSON.parse(localStorage.getItem("@ADM-Aiboo/perfil"));

  function addEntity(entity) {
    setAddedEntities((prevEntity) => [...prevEntity, entity]);
  }

  const removeEntity = useCallback(
    (entityId) => {
      const filteredEntities = addedEntities.filter(
        (entity) => entity.id !== entityId
      );

      setAddedEntities(filteredEntities);
    },
    [addedEntities]
  );

  function addAllEntities() {
    const filteredEntities = entitiesList.filter(
      (entity) => !findEntityById(entity.id)
    );

    setAddedEntities((prevAddedEntity) => [
      ...prevAddedEntity,
      ...filteredEntities,
    ]);
  }

  const removeAllEntities = useCallback(() => {
    setAddedEntities([]);
    closeConfirmationModal();
  }, []);

  const findEntityById = useCallback(
    (id) => {
      const isEntityAlreadyAdded = addedEntities.find(
        (entity) => entity.id === id
      );

      return isEntityAlreadyAdded;
    },
    [addedEntities]
  );

  async function handleCreateBanner(values) {
    setPageStatus("loadingSubmit");

    if (!access.admin_banner) {
      addToast("Você não possui permissão para realizar essa ação", {
        appearance: "warning",
        autoDismiss: true,
        autoDismissTimeout: 4000,
      });

      setPageStatus("idle");
      return;
    }

    if (values.image.length === 0) {
      addToast("Por favor, selecione uma imagem para o banner", {
        appearance: "warning",
        autoDismiss: true,
        autoDismissTimeout: 4000,
      });

      setPageStatus("idle");
      return;
    }

    if (addedEntities.length === 0) {
      addToast("Não é possível salvar a lista vazia, escolha itens/empresas", {
        appearance: "warning",
        autoDismiss: true,
        autoDismissTimeout: 4000,
      });

      setPageStatus("idle");
      return;
    }

    const createBannerPayload = new FormData();

    const entitiesId = addedEntities.map((entity) => Number(entity.id));

    createBannerPayload.append("name", values.name);
    createBannerPayload.append("type", type);
    createBannerPayload.append("status", values.status === "active" ? 1 : 0);
    createBannerPayload.append("description", values.description);

    if (entitiesId.length > 0) {
      createBannerPayload.append("resource_ids", JSON.stringify(entitiesId));
    }

    if (typeof values.image !== "string") {
      createBannerPayload.append("image", values.image[0]);
    }

    if (values.initialDate) {
      createBannerPayload.append("starts_at", values.initialDate);
    }

    if (values.finalDate) {
      createBannerPayload.append("ends_at", values.finalDate);
    }

    if (values.initialHour) {
      createBannerPayload.append("initial_hour", values.initialHour);
    }

    if (values.finalHour) {
      createBannerPayload.append("final_hour", values.finalHour);
    }

    if (currentEntityToEdit.banner) {
      try {
        await axios.post(
          `/banners/update/${currentEntityToEdit.banner.id}`,
          createBannerPayload,
          defaultConfigs
        );

        history.push("/marketing/BannerList");
        setPageStatus("idle");
      } catch {
        addToast(
          "Ocorreu um erro ao atualizar o banner, tente novamente mais tarde.",
          {
            appearance: "warning",
            autoDismiss: true,
            autoDismissTimeout: 4000,
          }
        );

        setPageStatus("idle");
      }

      return;
    }

    try {
      await axios.post("/banners/store", createBannerPayload, defaultConfigs);

      history.push("/marketing/BannerList");

      setPageStatus("idle");
    } catch {
      addToast(
        "Ocorreu um erro ao criar o banner, tente novamente mais tarde.",
        {
          appearance: "warning",
          autoDismiss: true,
          autoDismissTimeout: 4000,
        }
      );

      setPageStatus("idle");
    }
  }

  const handleSearchEntity = useCallback(async ({ event, type, search }) => {
    if (!search || search.length < 3) {
      setNotFoundSearchTerm(false);

      return;
    }

    if (event.code === "Enter" || event.type === "click") {
      setPageStatus("loadingSearch");

      const response = await Api.post("/banners/getAvailableResources", {
        type,
        search,
      });

      if (response.data.resources.length === 0) {
        setNotFoundSearchTerm(true);
      } else {
        setNotFoundSearchTerm(false);
      }

      setEntitiesList(response.data.resources);
      setPageStatus("idle");
    }
  }, []);

  const handleUploadCSV = useCallback(
    async ({ resources_csv, type, inputCsvRef }) => {
      try {
        const maxAllowedFileSize = 10 * 1024 * 1024;

        if (resources_csv.size > maxAllowedFileSize) {
          throw new Error();
        }

        const csvPayload = new FormData();

        csvPayload.append("type", type);
        csvPayload.append("resources_csv", resources_csv);

        setPageStatus("uploadingCSV");

        const response = await axios.post(
          "/banners/retrieveResourcesViaCsv",
          csvPayload,
          defaultConfigs
        );

        const filteredEntities = response.data.resources.filter(
          (entity) => !findEntityById(entity.id)
        );

        setAddedEntities((prevAddedEntity) => [
          ...prevAddedEntity,
          ...filteredEntities,
        ]);
        setPageStatus("idle");
        inputCsvRef.current.value = null;
      } catch (error) {
        addToast(
          "Ocorreu um erro, ao enviar o CSV, por favor, tente novamente mais tarde.",
          {
            appearance: "warning",
            autoDismiss: true,
            autoDismissTimeout: 4000,
          }
        );
        setPageStatus("idle");
        inputCsvRef.current.value = null;
      }
    },
    [addToast, defaultConfigs, findEntityById]
  );

  const getBannerInfo = useCallback(
    async (id) => {
      try {
        const response = await axios.post(
          `/banners/show/${id}`,
          {},
          defaultConfigs
        );

        setCurrentEntityToEdit(response.data);
      } catch (error) {
        addToast(
          "Banner não encontrado, por favor, tente novamente mais tarde.",
          {
            appearance: "warning",
            autoDismiss: true,
            autoDismissTimeout: 4000,
          }
        );
      }
    },
    [addToast, defaultConfigs]
  );

  function handleTypeChange(event) {
    if (addedEntities.length > 0) {
      setConfirmationModal({
        type: "changeType",
        isShowing: true,
        message: {
          title: "Tem certeza?",
          description:
            "Ao alterar o tipo do banner você perderá suas configurações atuais.",
        },
        labels: {
          dismiss: "Cancelar",
          trigger: "Alterar tipo",
        },
      });

      return;
    }

    setType(event.target.value);
    clearAllControlledFields();
  }

  function clearAllControlledFields() {
    setAddedEntities([]);
    setEntitiesList([]);
    setEntitySearch("");
  }

  const handleConfirmationChangeType = useCallback(() => {
    setType((prevType) =>
      prevType === "establishment" ? "item" : "establishment"
    );

    clearAllControlledFields();
    closeConfirmationModal();
  }, []);

  const handleConfirmationRemoveEntity = useCallback(() => {
    removeEntity(currentEntityToDelete);
    closeConfirmationModal();
  }, [currentEntityToDelete, removeEntity]);

  function handleRemoveEntity(entityId) {
    setCurrentEntityToDelete(entityId);

    setConfirmationModal({
      type: "removeEntity",
      isShowing: true,
      message: {
        title: "Tem certeza que deseja remover?",
        description: "Essa ação não poderá ser desfeita.",
      },
      labels: {
        dismiss: "Cancelar",
        trigger: "Excluir",
      },
    });
  }

  function handleRemoveAllEntities() {
    setConfirmationModal({
      type: "removeAllEntities",
      isShowing: true,
      message: {
        title: "Tem certeza?",
        description: `${
          type === "establishment"
            ? "Todas as empresas adicionadas serão excluídas"
            : "Todos os itens adicionados serão excluídos"
        }`,
      },
      labels: {
        dismiss: "Cancelar",
        trigger: "Excluir",
      },
    });
  }

  function closeConfirmationModal() {
    setConfirmationModal({
      type: "",
      isShowing: false,
      message: {
        title: "",
        description: "",
      },
      labels: {
        dismiss: "",
        trigger: "",
      },
    });
  }

  const confirmationModalTriggerSwitcher = useMemo(
    () => ({
      removeEntity: () => handleConfirmationRemoveEntity(),
      changeType: () => handleConfirmationChangeType(),
      removeAllEntities: () => removeAllEntities(),
    }),
    [
      handleConfirmationChangeType,
      handleConfirmationRemoveEntity,
      removeAllEntities,
    ]
  );

  return {
    imagePreview,
    handleCreateBanner,
    entitiesList,
    addedEntities,
    handleSearchEntity,
    addEntity,
    removeEntity,
    addAllEntities,
    removeAllEntities,
    entitySearch,
    setEntitySearch,
    findEntityById,
    handleUploadCSV,
    pageStatus,
    type,
    handleTypeChange,
    handleConfirmationChangeType,
    currentEntityToDelete,
    setCurrentEntityToDelete,
    handleRemoveEntity,
    handleConfirmationRemoveEntity,
    getBannerInfo,
    currentEntityToEdit,
    setImagePreview,
    setAddedEntities,
    notFoundSearchTerm,
    confirmationModal,
    closeConfirmationModal,
    confirmationModalTriggerSwitcher,
    handleRemoveAllEntities,
    setType,
  };
}

import React, { memo } from "react";

import Swal from "sweetalert2";
import LoadingOverlay from "react-loading-overlay";
import DataTable from "react-data-table-component";
import { useToasts } from "react-toast-notifications";

import api from "../../../../../services/api";

const ReceiptsComponent = ({
  merchantId,
  caProcessa,
  setCaProcessa,
  getListarBancoRecebimentos,
  listaBancoRecebimentos,
  setModalBanco,
  setFormCadBanco,
  getListarBanco,
  id_pagon_cadastro,
  editarPagOnBanco,
  excluirPagOnBanco,
}) => {
  const { addToast } = useToasts();

  const columnsBancoRecebimento = [
    {
      name: <th>Banco</th>,
      selector: "banco",
      sortable: true,
      width: "20%",
      cell: (row) => (
        <>
          {row.banco} - {row.nome}
        </>
      ),
    },
    {
      name: <th>Tipo de conta</th>,
      selector: "nome_bairro",
      sortable: true,
      width: "15%",
      center: true,
      cell: (row) => {
        if (row.tipo_conta == "CC") {
          return (
            <div className="label label-primary label-inline">
              <b>Conta Corrente</b>
            </div>
          );
        } else if (row.tipo_conta == "CP") {
          return (
            <div className="label label-info label-inline">
              <b>Conta Poupança</b>
            </div>
          );
        }
      },
    },
    {
      name: <th>Nome do titular</th>,
      selector: "nome_titular",
      sortable: true,
      width: "20%",
    },
    {
      name: <th>CNPJ/CPF</th>,
      selector: "cnpj_cpf",
      sortable: true,
      width: "15%",
      center: true,
    },
    {
      name: <th>Agência</th>,
      selector: "agencia",
      sortable: true,
      width: "10%",
      center: true,
      cell: (row) => (
        <>
          {row.agencia} {row.agencia_dig}
        </>
      ),
    },
    {
      name: <th>Conta</th>,
      selector: "conta",
      sortable: true,
      width: "10%",
      center: true,
    },
    {
      name: <th>Ações</th>,
      cell: (row) => {
        if (row.status == "S") {
          return (
            <div className="label label-success label-inline">
              <b>Conta Ativa</b>
            </div>
          );
        } else {
          return (
            <>
              <div class="dropdown dropdown-inline">
                <button
                  type="button"
                  class="btn btn-light-primary btn-icon btn-sm"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i class="ki ki-bold-more-ver"></i>
                </button>
                <div class="dropdown-menu">
                  <span
                    class="dropdown-item"
                    onClick={() => {
                      ativarPagOnBanco(row);
                    }}
                  >
                    Ativar
                  </span>
                  <div class="dropdown-divider"></div>
                  <span
                    class="dropdown-item"
                    onClick={(e) => {
                      editarPagOnBanco(row);
                    }}
                  >
                    Editar
                  </span>
                  <div class="dropdown-divider"></div>
                  <span
                    class="dropdown-item"
                    onClick={() => {
                      excluirPagOnBanco(row);
                    }}
                  >
                    Excluir
                  </span>
                </div>
              </div>
            </>
          );
        }
      },
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      right: true,
    },
  ];

  function ativarPagOnBanco(idPagOnBanco) {
    setCaProcessa(true);
    var banco = JSON.parse(JSON.stringify(idPagOnBanco));

    api.post("pagamentoonline/ativarPagOnBanco", banco).then((rps) => {
      if (rps.data.status === true) {
        addToast(rps.data.mensagem, {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 2000,
        });
        getListarBancoRecebimentos(merchantId);
        setCaProcessa(false);
      } else {
        Swal.fire({
          title: "Erro!",
          icon: "error",
          html: rps.data.erros,
          showCloseButton: true,
          showCancelButton: false,
        });
      }
    });
  }

  return (
    <div
      className="tab-pane fade"
      id="pag_recebimentos"
      role="tabpanel"
      aria-labelledby="pag_recebimentos"
    >
      <h6 className="font-weight-boldest">
        Como deseja receber seus pagamentos?
      </h6>
      <br />
      <div className="row">
        <div className="col-md-6">
          <div className="radio-list">
            <label className="radio text-justify">
              <input type="radio" name="radios1" defaultChecked />
              <span></span>
              <label className="font-weight-bolder">Pagamento Automático</label>
              <br />
              Receba o valor de suas vendas automaticamente na sua conta
              bancária em 30 dias após a venda.
              <br />
              Você poderá acompanhar os recebimentos, as vendas realizadas
              através do pagamento online, possuem uma taxa de processamento de
              2,99%.
            </label>
          </div>
        </div>

        <div className="col-md-6">
          <div className="align-items-center mb-9 bg-light-success rounded p-5">
            <label className="font-weight-bolder text-success font-size-lg">
              Meio de recebimento ativo
            </label>

            {listaBancoRecebimentos.map((br) => {
              if (br.status === "S") {
                return (
                  <>
                    <div className="row">
                      <div className="col-md-8">
                        <label>Banco</label>
                        <br />
                        <b>
                          {br.banco} {br.nome}
                        </b>
                      </div>
                      <div className="col-md-4">
                        <label>Tipo de conta</label>
                        <br />
                        <b>{br.tipo_conta}</b>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-4">
                        <label>Agência</label>
                        <br />
                        <b>{br.agencia}</b>
                      </div>
                      <div className="col-md-4">
                        <label>Digito</label>
                        <br />
                        <b>{br.agencia_dig}</b>
                      </div>
                      <div className="col-md-4">
                        <label>Conta</label>
                        <br />
                        <b>{br.conta}</b>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-8">
                        <label>Titular</label>
                        <br />
                        <b>{br.nome_titular}</b>
                      </div>
                      <div className="col-md-4">
                        <label>CNPJ</label>
                        <br />
                        <b>{br.cnpj_cpf}</b>
                      </div>
                    </div>
                  </>
                );
              } else {
                return null;
              }
            })}
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12 text-right">
          <button
            className="btn btn-primary"
            onClick={() => {
              setModalBanco(true);
              setFormCadBanco();
              getListarBanco();
              setFormCadBanco({
                id_pagon_cadastro: id_pagon_cadastro,
                id_estabelecimento: merchantId,
              });
            }}
          >
            Cadastrar conta
          </button>
        </div>
      </div>

      <LoadingOverlay active={caProcessa} spinner text="Carregando...">
        <DataTable
          title="Contas Cadastradas"
          columns={columnsBancoRecebimento}
          data={listaBancoRecebimentos}
          striped
          pagination
        />
      </LoadingOverlay>
    </div>
  );
};

export const Receipts = memo(ReceiptsComponent);

import React, { useState, useEffect } from 'react';
import Api from '../../../services/api';
import { withRouter } from "react-router";

import Swal from "sweetalert2";

import Select from 'react-select';
import LoadingOverlay from 'react-loading-overlay';

const RelVendaPeriodo = () => {
  const [ pedidos, setPedidos ] = useState([]);
  const [ sumario, setSumario ] = useState();
  const [ cidadeSelect, setCidadeSelect ] = useState([]);
  const [ cidade, setCidade ] = useState([]);
  const [ status, setStatus ] = useState();
  const [ caProcessa, setCaProcessa ] = useState(false);
 
  useEffect(() => {
    getCidadeSelect();
  }, []);

  function getCidadeSelect() {
    var data = {
      representante: localStorage.getItem('@ADM-Aiboo/nivel') == 'R' ? localStorage.getItem('@ADM-Aiboo/id_representante') : ''
    }

    Api.post("cidades/listarcidaderepresentante", data).then(rps => {
      var cid = rps.data.cidades;
      var nCid = [];
      nCid[nCid.length] = { value: '', label: 'Selecione...' };
      cid.forEach(e => {
        nCid[nCid.length] = { value: e.id_cidade, label: e.nome_cidade + ' - ' + e.estado };
      })
      setCidadeSelect(nCid);
    })
  }

  function filtrar() {
    setCaProcessa(true); 
      var data = {
        cidade: cidade,
        status: status,
        representante: localStorage.getItem('@ADM-Aiboo/nivel') == 'R' ? localStorage.getItem('@ADM-Aiboo/id_representante') : ''
      };
   
      Api.post('relatorios/geraRelatorioFatura', data).then((rps) => {

        if(rps.data.status === false){
          Swal.fire({
            title: "Atenção!",
            icon: "info",
            html: rps.data.erro,
            showCloseButton: true,
            showCancelButton: false,
          });
        } else {
          setPedidos(rps.data.obj);
          setSumario(rps.data.sumario);
        }
      setCaProcessa(false); 
    });
  }
    
    return (
        <>
<div>

<div className="subheader espaco-header-pedidos subheader-transparent" id="kt_subheader">
          <div className="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
            {/*begin::Details*/}
            <div className="d-flex align-items-center flex-wrap mr-2">
              {/*begin::Title*/}
              <h5 className="text-dark font-weight-bold mt-2 mb-2 mr-5">Relatório</h5>
              {/*end::Title*/}
              {/*begin::Separator*/}
              <div className="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200" />
              {/*end::Separator*/}
              {/*begin::Title*/}
              <h5 className="text-dark font-weight-bold mt-2 mb-2 mr-5">Faturas</h5>
              {/*end::Title*/}
              {/*begin::Separator*/}
              <div className="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200" />
              {/*end::Separator*/}
            </div>
            {/*end::Details*/}
            {/*begin::Toolbar*/}
            <div className="d-flex align-items-center">

            </div>
            {/*end::Toolbar*/}
          </div>
        </div>
        {/*begin::Entry*/}
        <div className="d-flex flex-column-fluid">
          {/*begin::Container*/}
          <div className="container">
            <div className="card card-custom">
              <div className="ml-10 mr-10 mt-10">    
                <div className="row">
                
                  <div className="col-md-8 form-group">
                    <label>Cidade</label>
                    <Select
                      options={cidadeSelect}
                      placeholder="Selecione..." 
                      defaultValue={cidadeSelect.find(
                        x => x.value === cidade
                      )}
                      onChange={(e) => { setCidade(e.value) }} 
                
                    /> 
                  </div> 

                  <div className="form-group col-md-2">
                  <label>Status</label>
                  <select className="form-control" 
                        value={status} 
                        onChange={(e) => { setStatus(e.target.value) }}>
                      <option value="">Selecione...</option>
                      <option value="S">Ativo</option>
                      <option value="N">Inativo</option>
                    </select>
                </div>

                  <div className="col form-group">
                  <label>&nbsp;</label><br />
                    <button className="btn btn-block btn-primary" onClick={e=>{filtrar()}}><i className="fas fa-filter" />Filtrar</button>
                  </div> 
                </div>     
              </div>

              <LoadingOverlay
                            active={caProcessa}
                            spinner
                            text='Carregando...'
                          >                     

              <h3 className='ml-5'><b>Faturas:</b></h3>
              <table className="table table-striped">
                <thead className="bg-light-primary">
                  <tr>
                    <td width='75%'>Estabelecimento</td>
                    <td width='10%'className='text-center'>Pg. Online</td>
                    <td width='15%'className='text-center'>Status Pgto/Status</td>
                  </tr>
                </thead>
                  <tbody className="content" dangerouslySetInnerHTML={{__html: pedidos}}></tbody>
                
                <tfoot className='bg-light-primary' dangerouslySetInnerHTML={{__html: sumario}}></tfoot>
              </table>  

              
              </LoadingOverlay> 

                    
      
                  </div>
                  </div>
                  </div>
                  </div>
                  
             
        </>
    );

}



export default withRouter(RelVendaPeriodo);                  
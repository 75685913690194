import React, { useState, forwardRef } from "react";
import Select from "react-select";

import * as S from "./styles";

const AsyncSelectComponent = (
  { label, isMandatory, error, loadOptions, ...rest },
  ref
) => {
  const [isLoading, setIsLoading] = useState(false);
  const [optionsLoaded, setOptionsLoaded] = useState(false);
  const [options, setOptions] = useState([]);

  const getBorderColor = (isFocused) => {
    if (error) {
      return "#ff3333";
    }

    return isFocused ? "#AAAAAA" : "#E5EAEE";
  };

  const handleLoadOptions = async () => {
    setIsLoading(true);

    const options = await loadOptions();

    setOptions(options);
    setOptionsLoaded(true);
    setIsLoading(false);
  };

  const verifySelectOption = () => {
    if (!optionsLoaded) {
      handleLoadOptions();
    }
  };

  return (
    <S.Container>
      <S.Label>
        {label} {isMandatory && <span>*</span>}
      </S.Label>

      <Select
        ref={ref}
        isSearchable
        onBlurResetsInput={false}
        onCloseResetsInput={false}
        isLoading={isLoading}
        options={options}
        onFocus={verifySelectOption}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            borderRadius: 12,
            borderColor: getBorderColor(state.isFocused),
            "&:hover": {
              borderColor: getBorderColor(state.isFocused),
            },
            boxShadow: "none",
            padding: 6,
          }),
          option: (styles, state) => ({
            ...styles,
            color: state.isDisabled ? "#888" : "#333",
            background: state.isSelected ? "#ddd" : "#fff",
            "&:hover": {
              background: "#deebff",
            },
          }),
          dropdownIndicator: (baseStyles) => ({
            ...baseStyles,
            paddingTop: 5,
            paddingBottom: 5,
          }),
          clearIndicator: (baseStyles) => ({
            ...baseStyles,
            paddingTop: 5,
            paddingBottom: 5,
          }),
        }}
        {...rest}
      />

      {error && <strong>{error.message}</strong>}
    </S.Container>
  );
};

export const AsyncSelect = forwardRef(AsyncSelectComponent);

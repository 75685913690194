import React, { useState, useEffect } from 'react';
import Api from '../../../services/api';
import { withRouter } from "react-router";
import LoadingOverlay from 'react-loading-overlay';
import { Doughnut, Line, Bar } from 'react-chartjs-2';
import Select from 'react-select';

import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import pt from "date-fns/locale/pt-BR"; 
registerLocale("pt-BR", pt);

  const Painel = () => {
  //Filtro
  var d = new Date();
  d.setDate(d.getDate() - 60);
  const [ representanteSelect, setRepresentanteSelect ] = useState([]);
  const [ representante, setRepresentante ] = useState('0');
  // const [ dataInicial, setDataInicial ] = useState(d);
  // const [ dataFinal, setDataFinal ] = useState(new Date());
  //Painel Total de pedidos / mês atual 
  const [ pedidoMes , setPedidoMes ] = useState([]);
  const [ caPedidoMes, setCaPedidoMes ] = useState(false);
  //Painel Ticket Médio / Mês atual
  const [ ticketMedio , setTicketMedio ] = useState([]);
  const [ caTicketMedio, setCaTicketMedio ] = useState(false);
  //Painel Comissão 
  const [ comissao , setComissao ] = useState([]);
  const [ caComissao, setCaComissao ] = useState(false);
  //Painel Transação Projetada 
  const [ transacaoProjetada , setTransacaoProjetada ] = useState([]);
  const [ caTransacaoProjetada, setCaTransacaoProjetada ] = useState(false);
  //Painel Total de Cupons Usados 
  const [ cuponsUsados , setCuponsUsados ] = useState([]);
  const [ caCuponsUsados, setCaCuponsUsados ] = useState(false);
  //Painel Pedidos - Ultimos 30 dias
  const [ pedidosUltimos30 , setPedidosUltimos30 ] = useState([]);
  const [ pedidosUltimos30Dias , setPedidosUltimos30Dias ] = useState([]);
  const [ caPedidosUltimos30, setCaPedidosUltimos30 ] = useState(false);
  //Painel Valores - Ultimos 30 dias
  const [ valoresUltimos30 , setValoresUltimos30 ] = useState([]);
  const [ valoresUltimos30Dias , setValoresUltimos30Dias ] = useState([]);
  const [ caValoresUltimos30, setCaValoresUltimos30 ] = useState(false);
  //Painel Cupons - Ultimos 30 dias
  const [ cuponsUltimos30 , setCuponsUltimos30 ] = useState([]);
  const [ cuponsUltimos30Dias , setCuponsUltimos30Dias ] = useState([]);
  const [ caCuponsUltimos30, setCaCuponsUltimos30 ] = useState(false);
  //Painel Transacionado Mes
  const [ transacionadoMes, setTransacionadoMes ] = useState([]);
  const [ caTransacionadoMes, setCaTransacionadoMes ] = useState(false);
  const [ transacionadoMesValor , setTransacionadoMesValor ] = useState([]);
  //Painel Bairros com mais pedidos
  const [ , setMelhoresBairros ] = useState([]);
  const [ caMelhoresBairros, setCaMelhoresBairros ] = useState(false);
  const [ bairroLabel , setBairroLabel ] = useState([]);
  const [ bairroValor , setBairroValor ] = useState([]);
  //Painel Usuários Cadastrados
  const [ usuariosCadastrados , setUsuariosCadastrados ] = useState([]);
  const [ caUsuariosCadastrados, setCaUsuariosCadastrados ] = useState(false);
  //Painel Usuários Sem Compras
  const [ usuariosSemCompras , setUsuariosSemCompras ] = useState([]);
  const [ caUsuariosSemCompras, setCaUsuariosSemCompras ] = useState(false);
  //Painel Usuários Compra Unica
  const [ usuariosCompraUnica , setUsuariosCompraUnica ] = useState([]);
  const [ caUsuariosCompraUnica, setCaUsuariosCompraUnica ] = useState(false);
  //Painel Usuários Pedido Unica
  const [ usuariosPedidosMaisQ1 , setUsuariosPedidosMaisQ1 ] = useState([]);
  const [ caUsuariosPedidosMaisQ1, setCaUsuariosPedidosMaisQ1 ] = useState(false);
  //Painel Usuários em Risco
  const [ usuariosEmRisco , setUsuariosEmRisco ] = useState([]);
  const [ caUsuariosEmRisco, setCaUsuariosEmRisco ] = useState(false);
  //Painel Usuários 60 Dias +
  const [ usuarios60dias , setUsuarios60dias ] = useState([]);
  const [ caUsuarios60dias, setCaUsuarios60dias ] = useState(false);
  //Painel Crescimento Usuários Cidade
  const [ usuariosMes, setUsuariosMes ] = useState([]);
  const [ caCrescimentoUsuarios, setCaCrescimentoUsuarios ] = useState(false);
  const [ usuariosMesValor , setUsuariosMesValor ] = useState([]);
  //Painel Evolução Ticket Médio
  const [ ticketEvolucaoMedioMes, setEvolucaoTicketMedioMes ] = useState([]);
  const [ caEvolucaoTicketMedio, setCaEvolucaoTicketMedio ] = useState(false);
  const [ ticketEvolucaoMedioMesValor , setEvolucaoTicketMedioMesValor ] = useState([]);
  //Painel Estabelecimentos Cadastrados
  const [ estabelecimentosCadastrados , setEstabelecimentosCadastrados ] = useState([]);
  const [ caEstabelecimentosCadastrados, setCaEstabelecimentosCadastrados ] = useState(false);
  //Painel Estabelecimentos Ativos
  const [ estabelecimentosAtivos , setEstabelecimentosAtivos ] = useState([]);
  const [ caEstabelecimentosAtivos, setCaEstabelecimentosAtivos ] = useState(false);
  //Painel Frequência Pedidos
  const [ frequenciaPedidoMes, setFrequenciaPedidoMes ] = useState([]);
  const [ , setCaFrequenciaPedido ] = useState(false);
  const [ frequenciaPedidoValor , setFrequenciaPedidoValor ] = useState([]);
  
  useEffect(() => {
    getRepresentanteSelect();
  }, []);

  function getRepresentanteSelect() {
    Api.get("representantes/listarRepresentante").then(rps => {
      var rep = rps.data.representantes;
      var nRep = [];
      nRep[nRep.length] = {value: '', label: 'Selecione...'};
      nRep[nRep.length] = {value: '0', label: 'Todos'};
      rep.forEach(e => {
        nRep[nRep.length] = {value: e.id_representante, label: e.nome};
      })
      setRepresentanteSelect(nRep);
    })
  }

  function getPedidoMes(){
    setCaPedidoMes(true); 

    var data = {
      representante: representante.value,
    };

    Api.post("painelrepresentante/totalpedidos", data).then(rps => {
      setPedidoMes(rps.data.obj.qtde);
     
      setCaPedidoMes(false);
    })
  }

  function getTicketMedio(){
    setCaTicketMedio(true); 

    var data = {
      representante: representante.value,
    };

    Api.post("painelrepresentante/ticketmedio", data).then(rps => {
      setTicketMedio(rps.data.obj.qtde);
     
      setCaTicketMedio(false);
    })
  }

  function getComissao(){
    setCaComissao(true); 

    var data = {
      representante: representante.value,
    };

    Api.post("painelrepresentante/comissao", data).then(rps => {
      setComissao(rps.data.obj.valor);
     
      setCaComissao(false);
    })
  }

  function getTransacaoProjetada(){
    setCaTransacaoProjetada(true); 

    var data = {
      representante: representante.value,
    };

    Api.post("painelrepresentante/transacaoprojetada", data).then(rps => {
      setTransacaoProjetada(rps.data.obj.valor);
     
      setCaTransacaoProjetada(false);
    })
  }

  function getCuponsUsados(){
    setCaCuponsUsados(true); 

    var data = {
      representante: representante.value,
    };

    Api.post("painelrepresentante/cuponsusados", data).then(rps => {
      setCuponsUsados(rps.data.obj.qtde);
     
      setCaCuponsUsados(false);
    })
  }

    function filtrar() {
      getPedidoMes();
      getTicketMedio();
      getComissao();
      getTransacaoProjetada();
      getCuponsUsados();
      getPedidosUltimos30();
      getValoresUltimos30();
      getCuponsUltimos30();
      getTransacionadoMes();
      getMelhoresBairros();
      getUsuariosCadastrados();
      getUsuariosSemCompras();
      getUsuariosCompraUnica();
      getUsuariosPedidosMaisQ1();
      getUsuariosEmRisco();
      getUsuarios60dias();
      getCrescimentoUsuarios();
      getEvolucaoTicketMedio();
      getEstabelecimentosCadastrados();
      getEstabelecimentosAtivos();
      getFrequenciaPedido();
    }

  function getPedidosUltimos30(){
    setCaPedidosUltimos30(true); 

    var data = {
      representante: representante.value,
    };

    Api.post("painelrepresentante/pedidosultimos30d", data).then(rps => {
  
      geraPedidosUltimos30(rps.data.obj);
     
      setCaPedidosUltimos30(false);
    })
  }
  
  function geraPedidosUltimos30(dados){
    //***Inicio do Grafico Ultimos 30 dias
  
    var formataQtde = [];
    var formataMes = [];
  
    dados.map( item => { 
        formataQtde.push(item.total);
        formataMes.push(item.data);
      
      return null
    })
   
    setPedidosUltimos30(formataQtde);
    setPedidosUltimos30Dias(formataMes);
    //Fim***
  }
  
  const dataPedidoUltimos = {
  labels: pedidosUltimos30Dias,
  datasets: [
    {
      data: pedidosUltimos30,
      fill: true,
      backgroundColor: "#ffec5b",
      borderColor: "#f4c430"
    },
  ]
  };
  
  const legenda = {
  display: false,
  };
  
  const options = {
  scales: {
    yAxes: [
      {
        ticks: {
          suggestedMin: 0,
          suggestedMax: 100
        }
      }
    ]
  }
  };

  function getValoresUltimos30(){
    setCaValoresUltimos30(true); 

    var data = {
      representante: representante.value,
    };

    Api.post("painelrepresentante/valoresultimos30d", data).then(rps => {
  
      geraValoresUltimos30(rps.data.obj);
     
      setCaValoresUltimos30(false);
    })
  }
  
  function geraValoresUltimos30(dados){
    //***Inicio do Grafico Ultimos 30 dias
  
    var formataQtde = [];
    var formataMes = [];
  
    dados.map( item => { 
        formataQtde.push(item.count);
        formataMes.push(item.data);
      
      return null
    })
   
    setValoresUltimos30(formataQtde);
    setValoresUltimos30Dias(formataMes);
    //Fim***
  }

  const dataUltimos30 = {
    labels: valoresUltimos30Dias,
    datasets: [
      {
        data: valoresUltimos30,
        fill: true,
        backgroundColor: "#acd1fc",
        borderColor: "#153cb2"
      },
    ]
    };

  function getCuponsUltimos30(){
    setCaCuponsUltimos30(true); 

    var data = {
      representante: representante.value,
    };

    Api.post("painelrepresentante/cuponsultimos30d", data).then(rps => {
  
      geraCuponsUltimos30(rps.data.obj);
      
      setCaCuponsUltimos30(false);
    })
  }
    
    function geraCuponsUltimos30(dados){
      //***Inicio do Grafico Ultimos 30 dias
    
      var formataQtde = [];
      var formataMes = [];
    
      dados.map( item => { 
          formataQtde.push(item.cupons);
          formataMes.push(item.data);
        
        return null
      })
     
      setCuponsUltimos30(formataQtde);
      setCuponsUltimos30Dias(formataMes);
      //Fim***
    }

  const dataCuponsUltimos = {
    labels: cuponsUltimos30Dias,
    datasets: [
      {
        data: cuponsUltimos30,
        fill: true,
        backgroundColor: "#e9d8e6",
        borderColor: "#8f4487"
      },
    ]
    };

  function getTransacionadoMes(){
    setCaTransacionadoMes(true); 

    var data = {
      representante: representante.value,
    };

    Api.post("painelrepresentante/transacionadomes", data).then(rps => {
  
      geraTransacionadoMes(rps.data.obj);
      
      setCaTransacionadoMes(false);
    })
  }
      
  function geraTransacionadoMes(dados){
    //***Inicio do Grafico Transacionado Mes
  
    var formataQtde = [];
    var formataMes = [];
  
    dados.map( item => { 
        formataQtde.push(item.total);
        formataMes.push(item.mes);
      
      return null
    })
    
    setTransacionadoMesValor(formataQtde);
    setTransacionadoMes(formataMes);
    //Fim***
  }
    
  const dataTransacionado = {
    labels: transacionadoMes,
    datasets: [
      {
        data: transacionadoMesValor,
        fill: true,
        backgroundColor: "#acd1fc",
        borderColor: "#153cb2"
      },
    ]
  };

  function getMelhoresBairros(){
    setCaMelhoresBairros(true); 

    var data = {
      representante: representante.value,
    };

    Api.post("painelrepresentante/melhoresbairros", data).then(rps => {

      //***Inicio do valor Grafico Mais Cidades
      var formataBairrosTexto = [];
      var formataBairrosValor = [];

      setMelhoresBairros(rps.data.obj);

      rps.data.obj.forEach( (item, i) => {
        if (i <= 10) {
          formataBairrosTexto.push(item.cidade);
          formataBairrosValor.push(item.total);
        }
      })

      setBairroLabel(formataBairrosTexto);
      setBairroValor(formataBairrosValor);
     
      setCaMelhoresBairros(false);
    })
  }

  //Grafico
  const dataBairros = {
    labels: bairroLabel,
    datasets: [{
      data: bairroValor,
      backgroundColor: [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)',
        'rgba(220, 20, 60, 1)',
        'rgba(127, 255, 0, 1)',
        'rgba(189, 183, 107, 1)',
        'rgba(255, 215, 0, 1)',
        'rgba(255, 255, 0, 1)',
        'rgba(128, 0, 128, 1)',
        'rgba(175, 238, 238, 1)',
        'rgba(0, 0, 128, 1)',
        'rgba(178, 34, 34, 1)',
      ],
      hoverOffset: 4
    }]
  };

  const optionsBairros = {
    legend: {
      display: true,
      position: "left"
    }
  };

  function getUsuariosCadastrados(){
    setCaUsuariosCadastrados(true); 

    var data = {
      representante: representante.value,
    };

    Api.post("painelrepresentante/usuarioscadastrados", data).then(rps => {
      setUsuariosCadastrados(rps.data.obj.qtde);
     
      setCaUsuariosCadastrados(false);
    })
  }

  function getUsuariosSemCompras(){

    var data = {
      representante: representante.value,
    };
    
    setCaUsuariosSemCompras(true); 
    Api.post("painelrepresentante/usuariossemcompras", data).then(rps => {
      setUsuariosSemCompras(rps.data.obj.qtde);
     
      setCaUsuariosSemCompras(false);
    })
  }

  function getUsuariosCompraUnica(){

    var data = {
      representante: representante.value,
    };
    
    setCaUsuariosCompraUnica(true); 
    Api.post("painelrepresentante/usuarioscompraunica", data).then(rps => {
      setUsuariosCompraUnica(rps.data.obj.qtde);
     
      setCaUsuariosCompraUnica(false);
    })
  }

  function getUsuariosPedidosMaisQ1(){

    var data = {
      representante: representante.value,
    };
    
    setCaUsuariosPedidosMaisQ1(true); 
    Api.post("painelrepresentante/usuariospedidosmaisq1", data).then(rps => {
      setUsuariosPedidosMaisQ1(rps.data.obj.qtde);
     
      setCaUsuariosPedidosMaisQ1(false);
    })
  }

  function getUsuariosEmRisco(){

    var data = {
      representante: representante.value,
    };
    
    setCaUsuariosEmRisco(true); 
    Api.post("painelrepresentante/usuariosemrisco", data).then(rps => {
      setUsuariosEmRisco(rps.data.obj.qtde);
     
      setCaUsuariosEmRisco(false);
    })
  }

  function getUsuarios60dias(){

    var data = {
      representante: representante.value,
    };
    
    setCaUsuarios60dias(true); 
    Api.post("painelrepresentante/usuarios60dias", data).then(rps => {
      setUsuarios60dias(rps.data.obj.qtde);
     
      setCaUsuarios60dias(false);
    })
  }

  function getCrescimentoUsuarios(){
    setCaCrescimentoUsuarios(true); 

    var data = {
      representante: representante.value,
    };

    Api.post("painelrepresentante/crescimentousuarios", data).then(rps => {
  
      geraCrescimentoUsuarios(rps.data.obj);
      
      setCaCrescimentoUsuarios(false);
    })
  }
      
  function geraCrescimentoUsuarios(dados){
    //***Inicio do Grafico Crescimento Usuários
  
    var formataQtde = [];
    var formataMes = [];
  
    dados.map( item => { 
        formataQtde.push(item.total);
        formataMes.push(item.month);
      
      return null
    })
    
    setUsuariosMesValor(formataQtde);
    setUsuariosMes(formataMes);
    //Fim***
  }
    
  const dataUsuarios = {
    labels: usuariosMes,
    datasets: [
      {
        data: usuariosMesValor,
        fill: false,
        borderColor: "red"
      },
    ]
  };

  function getEvolucaoTicketMedio(){
    setCaEvolucaoTicketMedio(true); 

    var data = {
      representante: representante.value,
    };

    Api.post("painelrepresentante/evolucaoticket", data).then(rps => {
  
      geraEvolucaoTicketMedio(rps.data.obj);
      
      setCaEvolucaoTicketMedio(false);
    })
  }
      
  function geraEvolucaoTicketMedio(dados){
    //***Inicio do Grafico Crescimento Usuários
  
    var formataQtde = [];
    var formataMes = [];
  
    dados.map( item => { 
        formataQtde.push(item.avg);
        formataMes.push(item.date);
      
      return null
    })
    
    setEvolucaoTicketMedioMesValor(formataQtde);
    setEvolucaoTicketMedioMes(formataMes);
    //Fim***
  }
    
  const dataEvolucaoTicketMedio = {
    labels: ticketEvolucaoMedioMes,
    datasets: [
      {
        data: ticketEvolucaoMedioMesValor,
        fill: true,
        borderColor: "green",
        backgroundColor: "#77c897",
      },
    ]
  };

  function getFrequenciaPedido(){
    setCaFrequenciaPedido(true); 

    var data = {
      representante: representante.value,
    };

    Api.post("painelrepresentante/frequenciapedidos", data).then(rps => {
  
      geraFrequenciaPedido(rps.data.obj);
      
      setCaFrequenciaPedido(false);
    })
  }
      
  function geraFrequenciaPedido(dados){
    //***Inicio do Grafico Transacionado Mes
  
    var formataQtde = [];
    var formataMes = [];
  
    dados.map( item => { 
        formataQtde.push(item.total);
        formataMes.push(item.date);
      
      return null
    })
    
    setFrequenciaPedidoValor(formataQtde);
    setFrequenciaPedidoMes(formataMes);
    //Fim***
  }

  const dataFrequeciaPedido = {
    labels: frequenciaPedidoMes,
    datasets: [
      {
        data: frequenciaPedidoValor,
        fill: true,
        borderColor: "#803e85",
        backgroundColor: "#A04EA6"
      },
    ]
  };

  function getEstabelecimentosCadastrados(){
    setCaEstabelecimentosCadastrados(true); 

    var data = {
      representante: representante.value,
    };

    Api.post("painelrepresentante/estabelecimentoscadastrados", data).then(rps => {
      setEstabelecimentosCadastrados(rps.data.obj.qtde);
     
      setCaEstabelecimentosCadastrados(false);
    })
  }

  function getEstabelecimentosAtivos(){
    setCaEstabelecimentosAtivos(true); 

    var data = {
      representante: representante.value,
    };

    Api.post("painelrepresentante/estabelecimentosativos", data).then(rps => {
      setEstabelecimentosAtivos(rps.data.obj.qtde);
     
      setCaEstabelecimentosAtivos(false);
    })
  }
  
  
  
    return (
        <>    
            {/*begin::Row*/}
            <div className="card card-custom">
              <div className="ml-10 mr-10 mt-10">    
                <div className="row">
                  <div className="col-md-10 form-group">
                    <label>Representante</label>
                    <Select
                      options={representanteSelect}
                      placeholder="Selecione..." 
                      defaultValue={representanteSelect.find(
                        x => x.value === representante
                      )}
                      onChange={(e) => { setRepresentante(e) }}                 
                    /> 
                  </div>

                  <div className="col-md-2 form-group">
                  <label>&nbsp;</label><br />
                    <button className="btn btn-block btn-primary" onClick={e=>{filtrar()}}><i className="fas fa-filter" />Filtrar</button>
                  </div> 
                </div>     
              </div>
            </div>

            <br />

            {/*begin::Row*/}
            <div className="row">
                <div className="col">
                  <LoadingOverlay
                    active={caPedidoMes}
                    spinner
                    text='Carregando...'
                  >
                    <div className="card card-custom gutter-b" style={{height: '160px'}}>
                    <div className="card-body">
                      <span className="svg-icon svg-icon-3x svg-icon-primary">
                        {/*begin::Svg Icon | path:assets/media/svg/icons/Communication/Group.svg*/}
                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                          <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                            <polygon points="0 0 24 0 24 24 0 24" />
                            <path d="M18,14 C16.3431458,14 15,12.6568542 15,11 C15,9.34314575 16.3431458,8 18,8 C19.6568542,8 21,9.34314575 21,11 C21,12.6568542 19.6568542,14 18,14 Z M9,11 C6.790861,11 5,9.209139 5,7 C5,4.790861 6.790861,3 9,3 C11.209139,3 13,4.790861 13,7 C13,9.209139 11.209139,11 9,11 Z" fill="#000000" fillRule="nonzero" opacity="0.3" />
                            <path d="M17.6011961,15.0006174 C21.0077043,15.0378534 23.7891749,16.7601418 23.9984937,20.4 C24.0069246,20.5466056 23.9984937,21 23.4559499,21 L19.6,21 C19.6,18.7490654 18.8562935,16.6718327 17.6011961,15.0006174 Z M0.00065168429,20.1992055 C0.388258525,15.4265159 4.26191235,13 8.98334134,13 C13.7712164,13 17.7048837,15.2931929 17.9979143,20.2 C18.0095879,20.3954741 17.9979143,21 17.2466999,21 C13.541124,21 8.03472472,21 0.727502227,21 C0.476712155,21 -0.0204617505,20.45918 0.00065168429,20.1992055 Z" fill="#000000" fillRule="nonzero" />
                          </g>
                        </svg>
                        {/*end::Svg Icon*/}
                      </span>
                      <div className="text-dark font-weight-bolder font-size-h2 mt-3">{pedidoMes}</div>
                      <label className="text-muted font-weight-bold font-size-lg mt-1">Total de pedidos / mês atual</label>
                    </div>
                  </div>
                  {/*end::Tiles Widget 12*/}
                  </LoadingOverlay>
                </div>

                <div className="col">
                  <LoadingOverlay
                    active={caTicketMedio}
                    spinner
                    text='Carregando...'
                  >
                    <div className="card card-custom gutter-b" style={{height: '160px'}}>
                    <div className="card-body">
                      <span className="svg-icon svg-icon-3x svg-icon-primary">
                        {/*begin::Svg Icon | path:assets/media/svg/icons/Communication/Group.svg*/}
                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                          <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                            <polygon points="0 0 24 0 24 24 0 24" />
                            <path d="M18,14 C16.3431458,14 15,12.6568542 15,11 C15,9.34314575 16.3431458,8 18,8 C19.6568542,8 21,9.34314575 21,11 C21,12.6568542 19.6568542,14 18,14 Z M9,11 C6.790861,11 5,9.209139 5,7 C5,4.790861 6.790861,3 9,3 C11.209139,3 13,4.790861 13,7 C13,9.209139 11.209139,11 9,11 Z" fill="#000000" fillRule="nonzero" opacity="0.3" />
                            <path d="M17.6011961,15.0006174 C21.0077043,15.0378534 23.7891749,16.7601418 23.9984937,20.4 C24.0069246,20.5466056 23.9984937,21 23.4559499,21 L19.6,21 C19.6,18.7490654 18.8562935,16.6718327 17.6011961,15.0006174 Z M0.00065168429,20.1992055 C0.388258525,15.4265159 4.26191235,13 8.98334134,13 C13.7712164,13 17.7048837,15.2931929 17.9979143,20.2 C18.0095879,20.3954741 17.9979143,21 17.2466999,21 C13.541124,21 8.03472472,21 0.727502227,21 C0.476712155,21 -0.0204617505,20.45918 0.00065168429,20.1992055 Z" fill="#000000" fillRule="nonzero" />
                          </g>
                        </svg>
                        {/*end::Svg Icon*/}
                      </span>
                      <div className="text-dark font-weight-bolder font-size-h2 mt-3">{ticketMedio}</div>
                      <label className="text-muted font-weight-bold font-size-lg mt-1">Ticket Médio / Mês atual</label>
                    </div>
                  </div>
                  {/*end::Tiles Widget 12*/}
                  </LoadingOverlay>
                </div>

                <div className="col">
                  <LoadingOverlay
                    active={caComissao}
                    spinner
                    text='Carregando...'
                  >
                    <div className="card card-custom gutter-b" style={{height: '160px'}}>
                    <div className="card-body">
                      <span className="svg-icon svg-icon-3x svg-icon-primary">
                        {/*begin::Svg Icon | path:assets/media/svg/icons/Communication/Group.svg*/}
                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                          <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                            <polygon points="0 0 24 0 24 24 0 24" />
                            <path d="M18,14 C16.3431458,14 15,12.6568542 15,11 C15,9.34314575 16.3431458,8 18,8 C19.6568542,8 21,9.34314575 21,11 C21,12.6568542 19.6568542,14 18,14 Z M9,11 C6.790861,11 5,9.209139 5,7 C5,4.790861 6.790861,3 9,3 C11.209139,3 13,4.790861 13,7 C13,9.209139 11.209139,11 9,11 Z" fill="#000000" fillRule="nonzero" opacity="0.3" />
                            <path d="M17.6011961,15.0006174 C21.0077043,15.0378534 23.7891749,16.7601418 23.9984937,20.4 C24.0069246,20.5466056 23.9984937,21 23.4559499,21 L19.6,21 C19.6,18.7490654 18.8562935,16.6718327 17.6011961,15.0006174 Z M0.00065168429,20.1992055 C0.388258525,15.4265159 4.26191235,13 8.98334134,13 C13.7712164,13 17.7048837,15.2931929 17.9979143,20.2 C18.0095879,20.3954741 17.9979143,21 17.2466999,21 C13.541124,21 8.03472472,21 0.727502227,21 C0.476712155,21 -0.0204617505,20.45918 0.00065168429,20.1992055 Z" fill="#000000" fillRule="nonzero" />
                          </g>
                        </svg>
                        {/*end::Svg Icon*/}
                      </span>
                      <div className="text-dark font-weight-bolder font-size-h2 mt-3">{comissao}</div>
                      <label className="text-muted font-weight-bold font-size-lg mt-1">Comissão</label>
                    </div>
                  </div>
                  {/*end::Tiles Widget 12*/}

                  </LoadingOverlay>
                </div>

                <div className="col">
                  <LoadingOverlay
                    active={caTransacaoProjetada}
                    spinner
                    text='Carregando...'
                  >
                    <div className="card card-custom gutter-b" style={{height: '160px'}}>
                    <div className="card-body">
                      <span className="svg-icon svg-icon-3x svg-icon-primary">
                        {/*begin::Svg Icon | path:assets/media/svg/icons/Communication/Group.svg*/}
                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                          <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                            <polygon points="0 0 24 0 24 24 0 24" />
                            <path d="M18,14 C16.3431458,14 15,12.6568542 15,11 C15,9.34314575 16.3431458,8 18,8 C19.6568542,8 21,9.34314575 21,11 C21,12.6568542 19.6568542,14 18,14 Z M9,11 C6.790861,11 5,9.209139 5,7 C5,4.790861 6.790861,3 9,3 C11.209139,3 13,4.790861 13,7 C13,9.209139 11.209139,11 9,11 Z" fill="#000000" fillRule="nonzero" opacity="0.3" />
                            <path d="M17.6011961,15.0006174 C21.0077043,15.0378534 23.7891749,16.7601418 23.9984937,20.4 C24.0069246,20.5466056 23.9984937,21 23.4559499,21 L19.6,21 C19.6,18.7490654 18.8562935,16.6718327 17.6011961,15.0006174 Z M0.00065168429,20.1992055 C0.388258525,15.4265159 4.26191235,13 8.98334134,13 C13.7712164,13 17.7048837,15.2931929 17.9979143,20.2 C18.0095879,20.3954741 17.9979143,21 17.2466999,21 C13.541124,21 8.03472472,21 0.727502227,21 C0.476712155,21 -0.0204617505,20.45918 0.00065168429,20.1992055 Z" fill="#000000" fillRule="nonzero" />
                          </g>
                        </svg>
                        {/*end::Svg Icon*/}
                      </span>
                      <div className="text-dark font-weight-bolder font-size-h2 mt-3">{transacaoProjetada}</div>
                      <label className="text-muted font-weight-bold font-size-lg mt-1">Transação Projetada</label>
                    </div>
                  </div>
                  {/*end::Tiles Widget 12*/}
                  </LoadingOverlay>
                </div>

                <div className="col">
                  <LoadingOverlay
                    active={caCuponsUsados}
                    spinner
                    text='Carregando...'
                  >
                    <div className="card card-custom gutter-b" style={{height: '160px'}}>
                    <div className="card-body">
                      <span className="svg-icon svg-icon-3x svg-icon-primary">
                        {/*begin::Svg Icon | path:assets/media/svg/icons/Communication/Group.svg*/}
                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                          <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                            <polygon points="0 0 24 0 24 24 0 24" />
                            <path d="M18,14 C16.3431458,14 15,12.6568542 15,11 C15,9.34314575 16.3431458,8 18,8 C19.6568542,8 21,9.34314575 21,11 C21,12.6568542 19.6568542,14 18,14 Z M9,11 C6.790861,11 5,9.209139 5,7 C5,4.790861 6.790861,3 9,3 C11.209139,3 13,4.790861 13,7 C13,9.209139 11.209139,11 9,11 Z" fill="#000000" fillRule="nonzero" opacity="0.3" />
                            <path d="M17.6011961,15.0006174 C21.0077043,15.0378534 23.7891749,16.7601418 23.9984937,20.4 C24.0069246,20.5466056 23.9984937,21 23.4559499,21 L19.6,21 C19.6,18.7490654 18.8562935,16.6718327 17.6011961,15.0006174 Z M0.00065168429,20.1992055 C0.388258525,15.4265159 4.26191235,13 8.98334134,13 C13.7712164,13 17.7048837,15.2931929 17.9979143,20.2 C18.0095879,20.3954741 17.9979143,21 17.2466999,21 C13.541124,21 8.03472472,21 0.727502227,21 C0.476712155,21 -0.0204617505,20.45918 0.00065168429,20.1992055 Z" fill="#000000" fillRule="nonzero" />
                          </g>
                        </svg>
                        {/*end::Svg Icon*/}
                      </span>
                      <div className="text-dark font-weight-bolder font-size-h2 mt-3">{cuponsUsados}</div>
                      <label className="text-muted font-weight-bold font-size-lg mt-1">Total de cupons usados</label>
                    </div>
                  </div>
                  {/*end::Tiles Widget 12*/}
                  </LoadingOverlay>
                </div>

              </div>
              {/*end::Row*/}


              {/*begin::Row*/}
              <div className="row">
                <div className="col-xl-4">
                <LoadingOverlay
                    active={caPedidosUltimos30}
                    spinner
                    text='Carregando...'
                  >
                {/*begin::Base Table Widget 1*/}
                <div className="card card-custom card-stretch gutter-b">
                  {/*begin::Header*/}
                  <div className="card-header border-0 pt-5">
                    <h3 className="card-title align-items-start flex-column">
                      <span className="card-label font-weight-bolder text-dark">Pedidos - Últimos 30 dias</span>
                    </h3>
                  </div>
                  {/*end::Header*/}
                  {/*begin::Body*/}
                    <div className="card-body pt-2 pb-0 mt-n3">
                      {/*begin::Table*/}
                      <div className="row">
                        
                        <div className="col-md-12">
                          <Line data={dataUltimos30} legend={legenda} options={options} />
                        </div>

                      </div>
    
                    </div>
                    {/*end::Tap pane*/}
                </div>
                {/*end::Base Table Widget 1*/} 
                </LoadingOverlay> 
                </div>
                
                <div className="col-xl-4">                
                  <LoadingOverlay
                      active={caValoresUltimos30}
                      spinner
                      text='Carregando...'
                    >
                  {/*begin::Base Table Widget 1*/}
                  <div className="card card-custom card-stretch gutter-b">
                    {/*begin::Header*/}
                    <div className="card-header border-0 pt-5">
                      <h3 className="card-title align-items-start flex-column">
                        <span className="card-label font-weight-bolder text-dark">R$ - Últimos 30 dias</span>
                      </h3>
                    </div>
                    {/*end::Header*/}
                    {/*begin::Body*/}
                    <div className="card-body pt-2 pb-0 mt-n3">
                        {/*begin::Table*/}
                        <div className="row">
                          
                          <div className="col-md-12">
                            <Line data={dataPedidoUltimos} legend={legenda} options={options} />
                          </div>

                        </div>

                          
                        </div>
                        {/*end::Tap pane*/}
                  </div>
                  {/*end::Base Table Widget 1*/} 
                  </LoadingOverlay> 
                </div>

                <div className="col-xl-4">                
                  <LoadingOverlay
                      active={caCuponsUltimos30}
                      spinner
                      text='Carregando...'
                    >
                  {/*begin::Base Table Widget 1*/}
                  <div className="card card-custom card-stretch gutter-b">
                    {/*begin::Header*/}
                    <div className="card-header border-0 pt-5">
                      <h3 className="card-title align-items-start flex-column">
                        <span className="card-label font-weight-bolder text-dark">Cupons - Últimos 30 dias</span>
                      </h3>
                    </div>
                    {/*end::Header*/}
                    {/*begin::Body*/}
                    <div className="card-body pt-2 pb-0 mt-n3">
                        {/*begin::Table*/}
                        <div className="row">
                          
                          <div className="col-md-12">
                            <Line data={dataCuponsUltimos} legend={legenda} options={options} />
                          </div>

                        </div>

                          
                        </div>
                        {/*end::Tap pane*/}
                  </div>
                  {/*end::Base Table Widget 1*/} 
                  </LoadingOverlay> 
                </div>

              </div>
              {/*end::Row*/}


              {/*begin::Row*/}
              <div className="row">
                <div className="col-xl-6">
                <LoadingOverlay
                    active={caTransacionadoMes}
                    spinner
                    text='Carregando...'
                  >
                {/*begin::Base Table Widget 1*/}
                <div className="card card-custom card-stretch gutter-b">
                  {/*begin::Header*/}
                  <div className="card-header border-0 pt-5">
                    <h3 className="card-title align-items-start flex-column">
                      <span className="card-label font-weight-bolder text-dark">Valor Transacionado mês a mês</span>
                    </h3>
                  </div>
                  {/*end::Header*/}
                  {/*begin::Body*/}
                    <div className="card-body pt-2 pb-0 mt-n3">
                      {/*begin::Table*/}
                      <div className="row">
                        
                        <div className="col-md-12">
                          <Line data={dataTransacionado} options={options} />
                        </div>

                      </div>
    
                    </div>
                    {/*end::Tap pane*/}
                </div>
                {/*end::Base Table Widget 1*/} 
                </LoadingOverlay> 
                </div>
                
                <div className="col-xl-6">                
                  <LoadingOverlay
                      active={caMelhoresBairros}
                      spinner
                      text='Carregando...'
                    >
                  {/*begin::Base Table Widget 1*/}
                  <div className="card card-custom card-stretch gutter-b">
                    {/*begin::Header*/}
                    <div className="card-header border-0 pt-5">
                      <h3 className="card-title align-items-start flex-column">
                        <span className="card-label font-weight-bolder text-dark">Venda por cidade</span>
                      </h3>
                    </div>
                    {/*end::Header*/}
                    {/*begin::Body*/}
                    <div className="card-body pt-2 pb-0 mt-n3">
                        {/*begin::Table*/}
                        <div className="row">
                          
                          <div className="col-md-12">
                            <Doughnut data={dataBairros} options={optionsBairros} />
                          </div>

                        </div>

                          
                        </div>
                        {/*end::Tap pane*/}
                  </div>
                  {/*end::Base Table Widget 1*/} 
                  </LoadingOverlay> 
                </div>

              </div>
              {/*end::Row*/}

              {/*begin::Row*/}
              <div className="row">
                <div className="col-md-2">
                  <LoadingOverlay
                    active={caUsuariosCadastrados}
                    spinner
                    text='Carregando...'
                  >
                  <div className="card card-custom gutter-b" style={{height: '160px'}}>
                    <div className="card-body">
                      <span className="svg-icon svg-icon-3x svg-icon-primary">
                        {/*begin::Svg Icon | path:assets/media/svg/icons/Communication/Group.svg*/}
                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                          <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                            <polygon points="0 0 24 0 24 24 0 24" />
                            <path d="M18,14 C16.3431458,14 15,12.6568542 15,11 C15,9.34314575 16.3431458,8 18,8 C19.6568542,8 21,9.34314575 21,11 C21,12.6568542 19.6568542,14 18,14 Z M9,11 C6.790861,11 5,9.209139 5,7 C5,4.790861 6.790861,3 9,3 C11.209139,3 13,4.790861 13,7 C13,9.209139 11.209139,11 9,11 Z" fill="#000000" fillRule="nonzero" opacity="0.3" />
                            <path d="M17.6011961,15.0006174 C21.0077043,15.0378534 23.7891749,16.7601418 23.9984937,20.4 C24.0069246,20.5466056 23.9984937,21 23.4559499,21 L19.6,21 C19.6,18.7490654 18.8562935,16.6718327 17.6011961,15.0006174 Z M0.00065168429,20.1992055 C0.388258525,15.4265159 4.26191235,13 8.98334134,13 C13.7712164,13 17.7048837,15.2931929 17.9979143,20.2 C18.0095879,20.3954741 17.9979143,21 17.2466999,21 C13.541124,21 8.03472472,21 0.727502227,21 C0.476712155,21 -0.0204617505,20.45918 0.00065168429,20.1992055 Z" fill="#000000" fillRule="nonzero" />
                          </g>
                        </svg>
                        {/*end::Svg Icon*/}
                      </span>
                      <div className="text-dark font-weight-bolder font-size-h2 mt-3">{usuariosCadastrados}</div>
                      <label className="text-muted font-weight-bold font-size-lg mt-1">Total de usuários cadastrados</label>
                    </div>
                  </div>
                  {/*end::Tiles Widget 12*/}
                  </LoadingOverlay>
                </div>

                <div className="col-md-2">
                  <LoadingOverlay
                    active={caUsuariosSemCompras}
                    spinner
                    text='Carregando...'
                  >
                  <div className="card card-custom gutter-b" style={{height: '160px'}}>
                    <div className="card-body">
                      <span className="svg-icon svg-icon-3x svg-icon-primary">
                        {/*begin::Svg Icon | path:assets/media/svg/icons/Communication/Group.svg*/}
                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                          <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                            <polygon points="0 0 24 0 24 24 0 24" />
                            <path d="M18,14 C16.3431458,14 15,12.6568542 15,11 C15,9.34314575 16.3431458,8 18,8 C19.6568542,8 21,9.34314575 21,11 C21,12.6568542 19.6568542,14 18,14 Z M9,11 C6.790861,11 5,9.209139 5,7 C5,4.790861 6.790861,3 9,3 C11.209139,3 13,4.790861 13,7 C13,9.209139 11.209139,11 9,11 Z" fill="#000000" fillRule="nonzero" opacity="0.3" />
                            <path d="M17.6011961,15.0006174 C21.0077043,15.0378534 23.7891749,16.7601418 23.9984937,20.4 C24.0069246,20.5466056 23.9984937,21 23.4559499,21 L19.6,21 C19.6,18.7490654 18.8562935,16.6718327 17.6011961,15.0006174 Z M0.00065168429,20.1992055 C0.388258525,15.4265159 4.26191235,13 8.98334134,13 C13.7712164,13 17.7048837,15.2931929 17.9979143,20.2 C18.0095879,20.3954741 17.9979143,21 17.2466999,21 C13.541124,21 8.03472472,21 0.727502227,21 C0.476712155,21 -0.0204617505,20.45918 0.00065168429,20.1992055 Z" fill="#000000" fillRule="nonzero" />
                          </g>
                        </svg>
                        {/*end::Svg Icon*/}
                      </span>
                      <div className="text-dark font-weight-bolder font-size-h2 mt-3">{usuariosSemCompras}</div>
                      <label className="text-muted font-weight-bold font-size-lg mt-1">Usuários que fizeram 0 compra no app</label>
                    </div>
                  </div>
                  {/*end::Tiles Widget 12*/}
                  </LoadingOverlay>
                </div>

                <div className="col-md-2">
                  <LoadingOverlay
                    active={caUsuariosCompraUnica}
                    spinner
                    text='Carregando...'
                  >
                  <div className="card card-custom gutter-b" style={{height: '160px'}}>
                    <div className="card-body">
                      <span className="svg-icon svg-icon-3x svg-icon-primary">
                        {/*begin::Svg Icon | path:assets/media/svg/icons/Communication/Group.svg*/}
                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                          <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                            <polygon points="0 0 24 0 24 24 0 24" />
                            <path d="M18,14 C16.3431458,14 15,12.6568542 15,11 C15,9.34314575 16.3431458,8 18,8 C19.6568542,8 21,9.34314575 21,11 C21,12.6568542 19.6568542,14 18,14 Z M9,11 C6.790861,11 5,9.209139 5,7 C5,4.790861 6.790861,3 9,3 C11.209139,3 13,4.790861 13,7 C13,9.209139 11.209139,11 9,11 Z" fill="#000000" fillRule="nonzero" opacity="0.3" />
                            <path d="M17.6011961,15.0006174 C21.0077043,15.0378534 23.7891749,16.7601418 23.9984937,20.4 C24.0069246,20.5466056 23.9984937,21 23.4559499,21 L19.6,21 C19.6,18.7490654 18.8562935,16.6718327 17.6011961,15.0006174 Z M0.00065168429,20.1992055 C0.388258525,15.4265159 4.26191235,13 8.98334134,13 C13.7712164,13 17.7048837,15.2931929 17.9979143,20.2 C18.0095879,20.3954741 17.9979143,21 17.2466999,21 C13.541124,21 8.03472472,21 0.727502227,21 C0.476712155,21 -0.0204617505,20.45918 0.00065168429,20.1992055 Z" fill="#000000" fillRule="nonzero" />
                          </g>
                        </svg>
                        {/*end::Svg Icon*/}
                      </span>
                      <div className="text-dark font-weight-bolder font-size-h2 mt-3">{usuariosCompraUnica}</div>
                      <label className="text-muted font-weight-bold font-size-lg mt-1">Compra única</label>
                    </div>
                  </div>
                  {/*end::Tiles Widget 12*/}
                  </LoadingOverlay>
                </div>

                <div className="col-md-2">
                  <LoadingOverlay
                    active={caUsuariosPedidosMaisQ1}
                    spinner
                    text='Carregando...'
                  >
                  <div className="card card-custom gutter-b" style={{height: '160px'}}>
                    <div className="card-body">
                      <span className="svg-icon svg-icon-3x svg-icon-primary">
                        {/*begin::Svg Icon | path:assets/media/svg/icons/Communication/Group.svg*/}
                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                          <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                            <polygon points="0 0 24 0 24 24 0 24" />
                            <path d="M18,14 C16.3431458,14 15,12.6568542 15,11 C15,9.34314575 16.3431458,8 18,8 C19.6568542,8 21,9.34314575 21,11 C21,12.6568542 19.6568542,14 18,14 Z M9,11 C6.790861,11 5,9.209139 5,7 C5,4.790861 6.790861,3 9,3 C11.209139,3 13,4.790861 13,7 C13,9.209139 11.209139,11 9,11 Z" fill="#000000" fillRule="nonzero" opacity="0.3" />
                            <path d="M17.6011961,15.0006174 C21.0077043,15.0378534 23.7891749,16.7601418 23.9984937,20.4 C24.0069246,20.5466056 23.9984937,21 23.4559499,21 L19.6,21 C19.6,18.7490654 18.8562935,16.6718327 17.6011961,15.0006174 Z M0.00065168429,20.1992055 C0.388258525,15.4265159 4.26191235,13 8.98334134,13 C13.7712164,13 17.7048837,15.2931929 17.9979143,20.2 C18.0095879,20.3954741 17.9979143,21 17.2466999,21 C13.541124,21 8.03472472,21 0.727502227,21 C0.476712155,21 -0.0204617505,20.45918 0.00065168429,20.1992055 Z" fill="#000000" fillRule="nonzero" />
                          </g>
                        </svg>
                        {/*end::Svg Icon*/}
                      </span>
                      <div className="text-dark font-weight-bolder font-size-h2 mt-3">{usuariosPedidosMaisQ1}</div>
                      <label className="text-muted font-weight-bold font-size-lg mt-1">Clientes com +1 compra em 30D</label>
                    </div>
                  </div>
                  {/*end::Tiles Widget 12*/}
                  </LoadingOverlay>
                </div>

                <div className="col-md-2">
                  <LoadingOverlay
                    active={caUsuariosEmRisco}
                    spinner
                    text='Carregando...'
                  >
                  <div className="card card-custom gutter-b" style={{height: '160px'}}>
                    <div className="card-body">
                      <span className="svg-icon svg-icon-3x svg-icon-primary">
                        {/*begin::Svg Icon | path:assets/media/svg/icons/Communication/Group.svg*/}
                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                          <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                            <polygon points="0 0 24 0 24 24 0 24" />
                            <path d="M18,14 C16.3431458,14 15,12.6568542 15,11 C15,9.34314575 16.3431458,8 18,8 C19.6568542,8 21,9.34314575 21,11 C21,12.6568542 19.6568542,14 18,14 Z M9,11 C6.790861,11 5,9.209139 5,7 C5,4.790861 6.790861,3 9,3 C11.209139,3 13,4.790861 13,7 C13,9.209139 11.209139,11 9,11 Z" fill="#000000" fillRule="nonzero" opacity="0.3" />
                            <path d="M17.6011961,15.0006174 C21.0077043,15.0378534 23.7891749,16.7601418 23.9984937,20.4 C24.0069246,20.5466056 23.9984937,21 23.4559499,21 L19.6,21 C19.6,18.7490654 18.8562935,16.6718327 17.6011961,15.0006174 Z M0.00065168429,20.1992055 C0.388258525,15.4265159 4.26191235,13 8.98334134,13 C13.7712164,13 17.7048837,15.2931929 17.9979143,20.2 C18.0095879,20.3954741 17.9979143,21 17.2466999,21 C13.541124,21 8.03472472,21 0.727502227,21 C0.476712155,21 -0.0204617505,20.45918 0.00065168429,20.1992055 Z" fill="#000000" fillRule="nonzero" />
                          </g>
                        </svg>
                        {/*end::Svg Icon*/}
                      </span>
                      <div className="text-dark font-weight-bolder font-size-h2 mt-3">{usuariosEmRisco}</div>
                      <label className="text-muted font-weight-bold font-size-lg mt-1">Usuários que fizeram 0 compra no app - 30D</label>
                    </div>
                  </div>
                  {/*end::Tiles Widget 12*/}
                  </LoadingOverlay>
                </div>

                <div className="col-md-2">
                  <LoadingOverlay
                    active={caUsuarios60dias}
                    spinner
                    text='Carregando...'
                  >
                  <div className="card card-custom gutter-b" style={{height: '160px'}}>
                    <div className="card-body">
                      <span className="svg-icon svg-icon-3x svg-icon-primary">
                        {/*begin::Svg Icon | path:assets/media/svg/icons/Communication/Group.svg*/}
                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                          <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                            <polygon points="0 0 24 0 24 24 0 24" />
                            <path d="M18,14 C16.3431458,14 15,12.6568542 15,11 C15,9.34314575 16.3431458,8 18,8 C19.6568542,8 21,9.34314575 21,11 C21,12.6568542 19.6568542,14 18,14 Z M9,11 C6.790861,11 5,9.209139 5,7 C5,4.790861 6.790861,3 9,3 C11.209139,3 13,4.790861 13,7 C13,9.209139 11.209139,11 9,11 Z" fill="#000000" fillRule="nonzero" opacity="0.3" />
                            <path d="M17.6011961,15.0006174 C21.0077043,15.0378534 23.7891749,16.7601418 23.9984937,20.4 C24.0069246,20.5466056 23.9984937,21 23.4559499,21 L19.6,21 C19.6,18.7490654 18.8562935,16.6718327 17.6011961,15.0006174 Z M0.00065168429,20.1992055 C0.388258525,15.4265159 4.26191235,13 8.98334134,13 C13.7712164,13 17.7048837,15.2931929 17.9979143,20.2 C18.0095879,20.3954741 17.9979143,21 17.2466999,21 C13.541124,21 8.03472472,21 0.727502227,21 C0.476712155,21 -0.0204617505,20.45918 0.00065168429,20.1992055 Z" fill="#000000" fillRule="nonzero" />
                          </g>
                        </svg>
                        {/*end::Svg Icon*/}
                      </span>
                      <div className="text-dark font-weight-bolder font-size-h2 mt-3">{usuarios60dias}</div>
                      <label className="text-muted font-weight-bold font-size-lg mt-1">0 compras - +60D</label>
                    </div>
                  </div>
                  {/*end::Tiles Widget 12*/}
                  </LoadingOverlay>
                </div>      
              </div>
              {/*end::Row*/}


              {/*begin::Row*/}
              <div className="row">
                <div className="col-xl-6">
                <LoadingOverlay
                    active={caCrescimentoUsuarios}
                    spinner
                    text='Carregando...'
                  >
                {/*begin::Base Table Widget 1*/}
                <div className="card card-custom card-stretch gutter-b">
                  {/*begin::Header*/}
                  <div className="card-header border-0 pt-5">
                    <h3 className="card-title align-items-start flex-column">
                      <span className="card-label font-weight-bolder text-dark">Crescimento usuários - representante</span>
                    </h3>
                  </div>
                  {/*end::Header*/}
                  {/*begin::Body*/}
                    <div className="card-body pt-2 pb-0 mt-n3">
                      {/*begin::Table*/}
                      <div className="row">
                        
                        <div className="col-md-12">
                          <Line data={dataUsuarios} options={options} />
                        </div>

                      </div>
    
                    </div>
                    {/*end::Tap pane*/}
                </div>
                {/*end::Base Table Widget 1*/} 
                </LoadingOverlay> 
                </div>
                
                <div className="col-xl-6">                
                  <LoadingOverlay
                      active={caEvolucaoTicketMedio}
                      spinner
                      text='Carregando...'
                    >
                  {/*begin::Base Table Widget 1*/}
                  <div className="card card-custom card-stretch gutter-b">
                    {/*begin::Header*/}
                    <div className="card-header border-0 pt-5">
                      <h3 className="card-title align-items-start flex-column">
                        <span className="card-label font-weight-bolder text-dark">Evolução ticket médio - representante</span>
                      </h3>
                    </div>
                    {/*end::Header*/}
                    {/*begin::Body*/}
                    <div className="card-body pt-2 pb-0 mt-n3">
                        {/*begin::Table*/}
                        <div className="row">
                          
                          <div className="col-md-12">
                            <Line data={dataEvolucaoTicketMedio} options={options} />
                          </div>

                        </div>

                          
                        </div>
                        {/*end::Tap pane*/}
                  </div>
                  {/*end::Base Table Widget 1*/} 
                  </LoadingOverlay> 
                </div>

              </div>
              {/*end::Row*/}

              {/*begin::Row*/}
              <div className="row">
                <div className="col-xl-3">
                  <LoadingOverlay
                      active={caEstabelecimentosCadastrados}
                      spinner
                      text='Carregando...'
                    >
                    <div className="card card-custom card-body">
                      <span className="font-weight-bolder text-dark-75 font-size-h5 mb-2">Estabelecimentos cadastrados</span>
                      <div className="row">
                          <div className="col-sm-8">
                          <span className="text-dark-75 font-weight-bolder font-size-h2 mr-2">{estabelecimentosCadastrados}</span>
                          </div>
                          <div className="col-sm-4">
                          <i className="icon-xl far fa-bookmark text-primary float-right"></i>
                          </div>
                      </div>
                    </div>
                    </LoadingOverlay>
                    <br />
                    <LoadingOverlay
                      active={caEstabelecimentosAtivos}
                      spinner
                      text='Carregando...'
                    >
                    <div className="card card-custom card-body">
                      <span className="font-weight-bolder text-dark-75 font-size-h5 mb-2">Estabelecimentos ativos</span>
                      <div className="row">
                          <div className="col-sm-8">
                          <span className="text-dark-75 font-weight-bolder font-size-h2 mr-2">{estabelecimentosAtivos}</span>
                          </div>
                          <div className="col-sm-4">
                          <i className="icon-xl far fa-bookmark text-primary float-right"></i>
                          </div>
                      </div>
                    </div>
                    </LoadingOverlay>
                </div>
                
                <div className="col-xl-9">                
                  <LoadingOverlay
                      active={caEvolucaoTicketMedio}
                      spinner
                      text='Carregando...'
                    >
                  {/*begin::Base Table Widget 1*/}
                  <div className="card card-custom card-stretch gutter-b">
                    {/*begin::Header*/}
                    <div className="card-header border-0 pt-5">
                      <h3 className="card-title align-items-start flex-column">
                        <span className="card-label font-weight-bolder text-dark">Frequência pedidos - representante</span>
                      </h3>
                    </div>
                    {/*end::Header*/}
                    {/*begin::Body*/}
                    <div className="card-body pt-2 pb-0 mt-n3">
                        {/*begin::Table*/}
                        <div className="row">
                          
                          <div className="col-md-12">
                            <Bar data={dataFrequeciaPedido} options={options} />
                          </div>

                        </div>

                          
                        </div>
                        {/*end::Tap pane*/}
                  </div>
                  {/*end::Base Table Widget 1*/} 
                  </LoadingOverlay> 
                </div>

              </div>
              {/*end::Row*/}




        </>
        );
    }
    
    export default withRouter(Painel);                  
import styled, { css, keyframes } from "styled-components";

const sizes = {
  small: css`
    font-size: 12px;
    line-height: 16px;
    height: 24px;
    padding-inline: 10px;
  `,
  medium: css`
    font-size: 16px;
    line-height: 22px;
    height: 40px;
    padding-inline: 25px;
  `,
  large: css`
    font-size: 20px;
    line-height: 27px;
    height: 48px;
    padding-inline: 30px;
  `,
};

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const Container = styled.button`
  width: 120px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;

  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  color: #ffffff;
  border: 1px solid #00b333;
  background: #00b333;
  border-radius: 8px;
  height: 40px;
  padding-inline: 25px;

  &:disabled {
    background-color: gray;
    border-color: gray;
  }

  ${(props) => props.size && sizes[props.size]}

  ${(props) =>
    props.variant === "danger" &&
    css`
      color: #ffffff;
      border: 1px solid #ff3333;
      background: #ff3333;
    `}

    ${(props) =>
    props.variant === "danger-ghost" &&
    css`
      color: #ff3333;
      border: 1px solid #ff3333;
      background: #fff;
    `}

    ${(props) =>
    props.fullwidth &&
    css`
      width: 100%;
    `}
`;

export const Spinner = styled.i.attrs({
  className: "fas fa-circle-notch",
})`
  animation: ${rotate} 800ms linear infinite;
`;

import { useQuery } from "@tanstack/react-query";
import { areaService } from "services";
import { areaQueryKeys } from "./areaQueryKeys";

export const useFetchAreasList = (city_id) => {
  const { data, isLoading, isError, isSuccess, refetch } = useQuery({
    queryKey: [areaQueryKeys.listAreas, city_id],
    queryFn: () => areaService.listAreas(city_id),
    retry: false,
    enabled: !!city_id,
  });

  return {
    availableAreas: data,
    isLoading,
    isError,
    isSuccess,
    refetch,
  };
};

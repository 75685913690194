import styled from "styled-components";

export const Container = styled.main`
  width: 100%;
  max-width: 1370px;
  margin: 42px auto 90px;
  padding-inline: 12px;
`;

export const Content = styled.article`
  margin-top: 32px;
  padding: 42px 36px;

  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 4px 24px 10px rgba(0, 0, 0, 0.05);
`;

export const Footer = styled.footer`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const Icon = styled.i`
  margin-right: ${({ className }) => (className ? "10" : "0")}px;
  color: #ffffff;
`;

import React, { memo } from "react";

import { useToasts } from "react-toast-notifications";
import Swal from "sweetalert2";

import api from "../../../../services/api";
import Button from "components/Button";

const AccessTabComponent = ({ formUsuario, setFormUsuario, merchantId }) => {
  const { id_usuario, email } = formUsuario;

  const { addToast } = useToasts();

  function salvarUsuario(idEstabelecimento) {
    formUsuario.id_estabelecimentousu = idEstabelecimento;

    api.post("usuarios/addUsuarioEstabelecimento", formUsuario).then((rps) => {
      if (rps.data.status !== undefined && rps.data.status === true) {
        addToast(rps.data.mensagem, {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 2000,
        });
      } else {
        Swal.fire({
          title: "Erro!",
          icon: "error",
          html: rps.data.erros,
          showCloseButton: true,
          showCancelButton: false,
        });
      }
    });
  }

  return (
    <div
      className="tab-pane fade"
      id="acesso"
      role="tabpanel"
      aria-labelledby="acesso"
    >
      {id_usuario != null ? (
        <div className="alert alert-custom alert-light-success" role="alert">
          <div className="alert-text">
            <h4>
              Usuário ({id_usuario}) já existe, para alterar a senha, digite uma
              nova abaixo.
            </h4>
          </div>
        </div>
      ) : (
        <div className="alert alert-custom alert-light-warning" role="alert">
          <div className="alert-text">
            <h4>Cadastre um usuário abaixo.</h4>
          </div>
        </div>
      )}

      <div className="row">
        <div className="form-group col-md-12">
          <label>
            Usuário <b className="text-danger">*</b>
          </label>
          <input
            type="text"
            maxLength="100"
            value={email}
            onChange={(e) => {
              setFormUsuario((prevState) => ({
                ...prevState,
                email: e.target.value,
              }));
            }}
            className="form-control"
          />
        </div>
      </div>
      <div className="row">
        <div className="form-group col-md-6">
          <label>
            Senha <b className="text-danger">*</b>
          </label>
          <input
            type="password"
            onChange={(e) => {
              setFormUsuario((prevState) => ({
                ...prevState,
                senha: e.target.value,
              }));
            }}
            className="form-control"
          />
        </div>
        <div className="form-group col-md-6">
          <label>
            Confirmar Senha <b className="text-danger">*</b>
          </label>
          <input
            type="password"
            onChange={(e) => {
              setFormUsuario((prevState) => ({
                ...prevState,
                con_senha: e.target.value,
              }));
            }}
            className="form-control"
          />
        </div>
      </div>

      <div className="col-md-2row d-flex align-items-end flex-column mt-10">
        <Button size="medium" onClick={() => salvarUsuario(merchantId)}>
          <span>Salvar Usuário</span>
        </Button>
      </div>
    </div>
  );
};

export const AccessTab = memo(AccessTabComponent);

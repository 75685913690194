/* eslint-disable jsx-a11y/aria-role */
import React, { useState, useEffect } from 'react';
import Api from '../../../services/api';
import { withRouter } from "react-router";
import { Doughnut  } from 'react-chartjs-2';

import Swal from "sweetalert2";
import LoadingOverlay from 'react-loading-overlay';

import ReactTooltip from 'react-tooltip';

import Select from 'react-select';

import DatePicker, { registerLocale } from "react-datepicker";
import InputMask from 'react-input-mask';
import pt from "date-fns/locale/pt-BR";
registerLocale("pt-BR", pt);

const FVHistorico = () => {
  const [ caProcessa, setCaProcessa ] = useState(false);
  const [ pedidos, setPedidos ] = useState([]);
  // const [ periodo, setPeriodo ] = useState(0);
  const [ dataInicial, setDataInicial ] = useState();
  const [ dataFinal, setDataFinal ] = useState(new Date());
  const [ estabelecimentoSelect, setEstabelecimentoSelect ] = useState([]);
  const [ cidadeSelect, setCidadeSelect ] = useState([]);
  const [ cidade, setCidade ] = useState([]);
  const [ representanteSelect, setRepresentanteSelect ] = useState([]);
  const [ buscaEstabelecimento, setBuscaEstabelecimento ] = useState();
  // const [ buscaCidade, setBuscaCidade ] = useState();
  const [ buscaRepresentante, setBuscaRepresentante ] = useState();
  const [ mostrar, setMostrar ] = useState();
  const [ caProcessaEstabelecimento, setCaProcessaEstabelecimento ] = useState(false);

  const [ pedidosCountTotal, setPedidosCountTotal ] = useState(0);
  const [ pedidosSomaTotal, setPedidosSomaTotal ] = useState(0);
  const [ pedidosCountTotalOffline, setPedidosCountTotalOffline ] = useState(0);
  const [ pedidosSomaTotalOffline, setPedidosSomaTotalOffline ] = useState(0);
  const [ pedidosCountTotalOnline, setPedidosCountTotalOnline ] = useState(0);
  const [ pedidosSomaTotalOnline, setPedidosSomaTotalOnline ] = useState(0);
  const [ pedidosCountDinheiro, setPedidosCountDinheiro ] = useState(0);
  const [ pedidosSomaDinheiro, setPedidosSomaDinheiro ] = useState(0);
  const [ pedidosCountCartao, setPedidosCountCartao ] = useState(0);
  const [ pedidosSomaCartao, setPedidosSomaCartao ] = useState(0);
  const [ pedidosCountOnline, setPedidosCountOnline ] = useState(0);
  const [ pedidosSomaOnline, setPedidosSomaOnline ] = useState(0);
  const [ pedidosCountPix, setPedidosCountPix ] = useState(0);
  const [ pedidosSomaPix, setPedidosSomaPix ] = useState(0);
  const [ pedidosCountPicpay, setPedidosCountPicpay ] = useState(0);
  const [ pedidosSomaPicpay, setPedidosSomaPicpay ] = useState(0);
  const [ pedidosCountNupay, setPedidosCountNupay ] = useState(0);
  const [ pedidosSomaNupay, setPedidosSomaNupay ] = useState(0);

   useEffect(() => {
    getCidadeSelect();
    getRepresentanteSelect();
  }, []);

  useEffect(() => {
    if (cidade)
      getEstabelecimentoSelect(cidade);
  }, [cidade]);

  function getEstabelecimentoSelect(cidade) {
    setCaProcessaEstabelecimento(true);
    var data = {
      representante: localStorage.getItem('@ADM-Aiboo/nivel') == 'R' ? localStorage.getItem('@ADM-Aiboo/id_representante') : '',
      'cidade': cidade
    }

    Api.post("estabelecimentos/listaNomeEstabelecimento", data).then(rps => {
      var rep = rps.data.obj;
      var nRep = [];
      nRep[nRep.length] = {value: '', label: 'Selecione...'};
      rep.forEach(e => {
        nRep[nRep.length] = {value: e.id_estabelecimento, label: e.nome};
      })
      setEstabelecimentoSelect(nRep);
      setCaProcessaEstabelecimento(false); 
    })
  }

  function getCidadeSelect() {
    var data = {
      representante: localStorage.getItem('@ADM-Aiboo/nivel') == 'R' ? localStorage.getItem('@ADM-Aiboo/id_representante') : ''
    }

    Api.post("cidades/listarcidaderepresentante", data).then(rps => {
      var cid = rps.data.cidades;
      var nCid = [];
      nCid[nCid.length] = { value: '', label: 'Selecione...' };
      cid.forEach(e => {
        nCid[nCid.length] = { value: e.id_cidade, label: e.nome_cidade + ' - ' + e.estado };
      })
      setCidadeSelect(nCid);
    })
  }

  function getRepresentanteSelect() {
    var data = {
      representante: localStorage.getItem('@ADM-Aiboo/nivel') == 'R' ? localStorage.getItem('@ADM-Aiboo/id_representante') : '',
    }

    Api.post("representantes/listarRepresentante", data).then(rps => {
      var rep = rps.data.representantes;
      var nRep = [];
      nRep[nRep.length] = {value: '', label: 'Selecione...'};
      rep.forEach(e => {
        nRep[nRep.length] = {value: e.id_representante, label: e.nome};
      })
      setRepresentanteSelect(nRep);
    })
  }
  
  function filtrar() {
    setCaProcessa(true); 
    var data = {
      data_inicio: dataInicial,
      data_final: dataFinal,
      estabelecimento: buscaEstabelecimento,
      cidade,
      representante: buscaRepresentante,
      mostra: mostrar
    };
    
    Api.post("pagamentoonline/gerahistorico", data).then(rps => {

        if(rps.data.status==false){
          Swal.fire({
            title: "Atenção!",
            icon: "info",
            html: rps.data.erro,
            showCloseButton: true,
            showCancelButton: false,
          });
        } else {
          if (mostrar === 'S') { 
            setPedidos(rps.data.retorno);
          }
          processaDados(rps.data.retorno);
        }
      setCaProcessa(false); 
    });
  }

  function processaDados(dados){
    var countPedidosTotal = 0;
    var sumPedidosTotal = 0;
    var countPedidosTotalOffline = 0;
    var sumPedidosTotalOffline = 0;
    var countPedidosTotalOnline = 0;
    var sumPedidosTotalOnline = 0;
    var countPedidosCartao = 0;
    var sumPedidosCartao = 0;
    var countPedidosDinheiro = 0;
    var sumPedidosDinheiro = 0;
    var countPedidosOnline = 0;
    var sumPedidosOnline = 0;
    var countPedidosPix = 0;
    var sumPedidosPix = 0;
    var countPedidosPicpay = 0;
    var sumPedidosPicpay = 0;
    var countPedidosNupay = 0;
    var sumPedidosNupay = 0;

    dados.map( pedido => {
        if(pedido?.status==='PD'){
            countPedidosTotal++;
            sumPedidosTotal+= parseFloat(pedido?.valor_total);

            if (pedido?.pagamento == -2 || pedido?.pagamento == -10 || pedido?.pagamento == -11 || pedido?.pagamento == -12) {
              countPedidosTotalOnline++;
              sumPedidosTotalOnline+= parseFloat(pedido?.valor_total);
            } else {
              countPedidosTotalOffline++;
              sumPedidosTotalOffline+= parseFloat(pedido?.valor_total);
            }

            if (pedido?.pagamento == -2){
              countPedidosOnline++;
              sumPedidosOnline+= parseFloat(pedido?.valor_total);
            } else if (pedido?.pagamento == -10){
              countPedidosPix++;
              sumPedidosPix+= parseFloat(pedido?.valor_total);
            } else if (pedido?.pagamento == -11){
              countPedidosPicpay++;
              sumPedidosPicpay+= parseFloat(pedido?.valor_total);
            } else if (pedido?.pagamento == -12){
              countPedidosNupay++;
              sumPedidosNupay+= parseFloat(pedido?.valor_total);
            } else if (pedido?.pagamento == -1) {
              countPedidosDinheiro++;
              sumPedidosDinheiro+= parseFloat(pedido?.valor_total);
            } else {
              countPedidosCartao++;
              sumPedidosCartao+= parseFloat(pedido?.valor_total);
            }
        }
      return null
    });
    setPedidosCountTotal(countPedidosTotal);
    setPedidosSomaTotal(sumPedidosTotal.toLocaleString('pt-br', {minimumFractionDigits: 2}));
    setPedidosCountTotalOffline(countPedidosTotalOffline);
    setPedidosSomaTotalOffline(sumPedidosTotalOffline.toLocaleString('pt-br', {minimumFractionDigits: 2}));
    setPedidosCountTotalOnline(countPedidosTotalOnline);
    setPedidosSomaTotalOnline(sumPedidosTotalOnline.toLocaleString('pt-br', {minimumFractionDigits: 2}));
    setPedidosCountOnline(countPedidosOnline);
    setPedidosSomaOnline(sumPedidosOnline);
    setPedidosCountPix(countPedidosPix);
    setPedidosSomaPix(sumPedidosPix);
    setPedidosCountPicpay(countPedidosPicpay);
    setPedidosSomaPicpay(sumPedidosPicpay);
    setPedidosCountNupay(countPedidosNupay);
    setPedidosSomaNupay(sumPedidosNupay);
    setPedidosCountCartao(countPedidosCartao);
    setPedidosSomaCartao(sumPedidosCartao);
    setPedidosCountDinheiro(countPedidosDinheiro);
    setPedidosSomaDinheiro(sumPedidosDinheiro);
}

const data = {
  labels: ['Cartão', 'Dinheiro', 'Online', 'Pix', 'Picpay', 'Nupay' ],
  datasets: [
      {
          data: [pedidosCountCartao, pedidosCountDinheiro, pedidosCountOnline, pedidosCountPix, pedidosCountPicpay, pedidosCountNupay],
          backgroundColor: ['#212121', '#0088ff', '#F64E60', '#32BCAD', '#21C25E', '#4C0A77'],
          hoverBackgroundColor: ['#212121', '#0088ff', '#F64E60', '#32BCAD', '#21C25E', '#4C0A77'],
      }
  ]
};

const dataValor = {
  labels: ['Cartão', 'Dinheiro', 'Online', 'Pix', 'Picpay', 'Nupay' ],
  datasets: [
      {
          data: [pedidosSomaCartao, pedidosSomaDinheiro, pedidosSomaOnline, pedidosCountPix, pedidosCountPicpay, pedidosCountNupay],
          backgroundColor: ['#212121', '#0088ff', '#F64E60', '#32BCAD', '#21C25E', '#4C0A77'],
          hoverBackgroundColor: ['#212121', '#0088ff', '#F64E60', '#32BCAD', '#21C25E', '#4C0A77'],
      }
  ]
};

const options = {
  legend: {
    display: true,
    position: "left"
  }
};
    
    return (
        <>
<div>

<div className="subheader espaco-header-pedidos subheader-transparent" id="kt_subheader">
          <div className="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
            {/*begin::Details*/}
            <div className="d-flex align-items-center flex-wrap mr-2">
              {/*begin::Title*/}
              <h5 className="text-dark font-weight-bold mt-2 mr-5">Relatório</h5>
              {/*end::Title*/}
              {/*begin::Separator*/}
              <div className="subheader-separator subheader-separator-ver mt-2 mb-2 bg-gray-200" />
              {/*end::Separator*/}
              {/*begin::Title*/}
              <h6 className="text-dark mt-2 mr-5 ml-5">Histórico</h6>
              {/*end::Title*/}
            </div>
            {/*end::Details*/}
            {/*begin::Toolbar*/}
            <div className="d-flex align-items-center">
              
            </div>
            {/*end::Toolbar*/}
          </div>
        </div>
        {/*begin::Entry*/}
        <div className="d-flex flex-column-fluid">
          {/*begin::Container*/}
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="card card-custom mr-5 pt-5 pl-5 pr-5 pb-5">
                  <div className="row border-bottom">                                         
                    <div className="col form-group">
                      <label>Data de</label>
                        <div className="input-group">
                        <DatePicker 
                          name="dt_inicio"
                          onChange={setDataInicial}
                          isClearable
                          selectsStart
                          locale="pt-BR"
                          className="form-control"
                          placeholderText="dd/mm/aaaa" 
                          selected ={dataInicial}
                          dateFormat="dd/MM/y"
                          startDate={dataInicial}
                          endDate={dataFinal}
                          customInput={
                            <InputMask mask="99/99/9999" />}/>
                        </div>
                    </div>

                    <div className="col form-group">
                      <label>Data até</label>
                        <div className="input-group">
                        <DatePicker 
                          name="dt_final"
                          onChange={setDataFinal}
                          isClearable
                          locale="pt-BR"
                          className="form-control"
                          placeholderText="dd/mm/aaaa" 
                          selected ={dataFinal}
                          dateFormat="dd/MM/y"
                          selectsEnd
                          startDate={dataInicial}
                          endDate={dataFinal}
                          minDate={dataInicial} 
                          customInput={
                            <InputMask mask="99/99/9999" />}/>
                        </div>
                    </div>

                    <div className="col-md-2 form-group">
                      <label>Cidade</label>
                      <Select
                        options={cidadeSelect}
                        placeholder="Selecione..." 
                        defaultValue={cidadeSelect.find(
                          x => x.value === cidade
                        )}
                        onChange={(e) => { setCidade(e.value); setMostrar('Não') }} 
                      /> 
                    </div> 

                    <div className="col-md-2 form-group">
                      <label>Estabelecimento</label>
                      <LoadingOverlay
                        active={caProcessaEstabelecimento}
                        spinner
                        text='Carregando...'
                      >
                      <Select
                        options={estabelecimentoSelect}
                        placeholder="Selecione..." 
                        defaultValue={estabelecimentoSelect.find(
                          x => x.value === buscaEstabelecimento
                        )}
                        onChange={(e) => { setBuscaEstabelecimento(e.value) }} 
                      /> 
                      </LoadingOverlay>
                    </div>

                    <div className="col-md-2 form-group">
                      <label>Representante</label>
                      <Select
                        options={representanteSelect}
                        placeholder="Selecione..." 
                        defaultValue={representanteSelect.find(
                          x => x.value === buscaRepresentante
                        )}
                        onChange={(e) => { setBuscaRepresentante(e.value) }} 
                      /> 
                    </div>

                    <div className="form-group col">
                      <ReactTooltip />
                      <label>Mostrar?</label><i className="fas fa-fw fa-exclamation-triangle ml-2" data-tip="Nota: caso selecione SIM aguarde o navegador destravar!!"/>
                      <select className="form-control" 
                            value={mostrar} 
                            onChange={(e) => { setMostrar(e.target.value) }}>
                          <option value="">Selecione...</option>
                          <option value="S">Sim</option>
                          <option value="N" selected>Não</option>
                        </select>
                    </div>

                    <div className="col-md-2 form-group">
                    <label>&nbsp;</label><br />
                      <button className="btn btn-block btn-primary" onClick={e=>{filtrar()}}><i className="fas fa-filter" />Filtrar</button>
                    </div>
                  </div>

                  <LoadingOverlay
                    active={caProcessa}
                    spinner
                    text='Carregando...'
                  >  
                  <div className="row mt-4">
                    <div className="col-md-4">
                      <div className="row">
                        <div className="col text-center">
                        </div>
                        <div className="col text-center">
                          <label><b>Quantidade</b></label>
                        </div>
                        <div className="col text-center">
                          <label><b>Valor total</b></label>
                        </div>
                      </div>

                      <div className="row mt-2">
                        <div className="col text-center">
                          <h3><b><font color="red">Offline</font></b></h3>
                        </div>
                        <div className="col text-center">
                          <h3><font color="red">{pedidosCountTotalOffline}</font></h3>
                        </div>
                        <div className="col text-center">
                          <h3><font color="red"><small>R$ </small>{pedidosSomaTotalOffline}</font></h3>
                        </div>
                      </div>

                      <div className="row mt-2">
                        <div className="col text-center">
                          <h3><b><font color="blue">Online</font></b></h3>
                        </div>
                        <div className="col text-center">
                          <h3><font color="blue">{pedidosCountTotalOnline}</font></h3>
                        </div>
                        <div className="col text-center">
                          <h3><font color="blue"><small>R$ </small>{pedidosSomaTotalOnline}</font></h3>
                        </div>
                      </div>

                      <div className="row mt-2">
                        <div className="col text-center" style={{ backgroundColor: "#F2F2F2" }}>
                          <h3><b>Geral</b></h3>
                        </div>
                        <div className="col text-center" style={{ backgroundColor: "#F2F2F2" }}>
                          <h3>{pedidosCountTotal}</h3>
                        </div>
                        <div className="col text-center" style={{ backgroundColor: "#F2F2F2" }} >
                          <h3><small>R$ </small>{pedidosSomaTotal}</h3>
                        </div>
                      </div>
              
                    </div>

                    <div className="col-md-4 border-left">
                      <div className="row">
                        <div className="col text-center">
                          <label><b>Tipo de venda</b></label>
                        </div>
                        <div className="col text-center">
                          <label><b>Nº de vendas</b></label>
                        </div>
                        <div className="col text-center">
                          <label><b>Total</b></label>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col text-center">
                          <label>Cartão</label>
                        </div>
                        <div className="col text-center">
                          <label>{pedidosCountCartao}</label>
                        </div>
                        <div className="col text-right">
                          <label>{pedidosSomaCartao.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</label>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col text-center">
                          <label>Dinheiro</label>
                        </div>
                        <div className="col text-center">
                          <label>{pedidosCountDinheiro}</label>
                        </div>
                        <div className="col text-right">
                          <label>{pedidosSomaDinheiro.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</label>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col text-center">
                          <label>Online</label>
                        </div>
                        <div className="col text-center">
                          <label>{pedidosCountOnline}</label>
                        </div>
                        <div className="col text-right">
                          <label>{pedidosSomaOnline.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</label>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col text-center">
                          <label>Pix</label>
                        </div>
                        <div className="col text-center">
                          <label>{pedidosCountPix}</label>
                        </div>
                        <div className="col text-right">
                          <label>{pedidosSomaPix.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</label>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col text-center">
                          <label>Picpay</label>
                        </div>
                        <div className="col text-center">
                          <label>{pedidosCountPicpay}</label>
                        </div>
                        <div className="col text-right">
                          <label>{pedidosSomaPicpay.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</label>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col text-center">
                          <label>Nupay</label>
                        </div>
                        <div className="col text-center">
                          <label>{pedidosCountNupay}</label>
                        </div>
                        <div className="col text-right">
                          <label>{pedidosSomaNupay.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</label>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-3 border-left">
                      <ul class="nav nav-tabs" id="myTab" role="tablist">
                        <li class="nav-item">
                          <a class="nav-link active" id="qtde-tab" data-toggle="tab" href="#qtde">
                            <span class="nav-text">Nº de Vendas</span>
                          </a>
                        </li>
                        <li class="nav-item">
                          <a class="nav-link" id="valor-tab" data-toggle="tab" href="#valor">
                            <span class="nav-text">Valor</span>
                          </a>
                        </li>				
										  </ul>

                      <div class="tab-content mt-5" id="myTabContent">
                        <div class="tab-pane fade show active" id="qtde" role="tabpanel" aria-labelledby="qtde-tab">
                          <Doughnut data={data} options={options} />
                        </div>
                        <div class="tab-pane fade" id="valor" role="valor" aria-labelledby="valor-tab">
                          <Doughnut data={dataValor} options={options} />
                        </div>
                      </div>


                      
                    </div>
                  </div>
                </LoadingOverlay>

                </div>
              </div>
            </div>
            <br />

            <div className="card card-custom">
              <div className="ml-10 mr-10 mt-10">    

              <LoadingOverlay
                active={caProcessa}
                spinner
                text='Carregando...'
              >      
                { mostrar === 'S' &&           
                <table className="table table-striped">
                        <thead>                       
                            <tr>
                                <th className="text-center">Hash</th>
                                <th>Cliente</th>
                                <th className="text-center">Data</th>
                                <th className="text-center">Valor em Produtos</th>
                                <th className="text-center">Desconto</th>
                                <th className="text-center">Taxa Entrega</th>
                                <th className="text-center">Pagamento</th>
                            </tr>
                        </thead>

                        <tbody>

                        {
                        pedidos !== null &&
                        pedidos.map( pedido => {
                            var pagamento = '';
                            var imagemPagamento = '';

                            if (pedido?.pagamento == -2 || pedido?.pagamento == -10 || pedido?.pagamento == -11 || pedido?.pagamento == -12) {
                                pagamento = 'Online'
                                imagemPagamento = 'fas fa-link text-danger';
                            } else if(pedido?.pagamento == -1){
                                pagamento = 'Dinheiro';
                                imagemPagamento = 'far fa-money-bill-alt text-success';
                            } else {
                                pagamento = 'Cartão';
                                imagemPagamento = 'far fa-credit-card text-dark';
                            }

                            return(
                            <>
                            <ReactTooltip />
                            { pedido?.status === 'PD' &&
                            <tr>
                                <td>{pedido.hash_pedido}</td>
                                <td>{pedido.usuario_nome}</td>
                                <td className="text-center">{pedido.data_criacao}</td>
                                <td className="text-right">{pedido.valor_total}</td>
                                <td className="text-right">{pedido.valor_desconto}</td>
                                <td className="text-right">{pedido.valor_taxa}</td>
                                <td className="text-center">
                                  <i className={""+imagemPagamento+""} data-tip={""+pagamento+""} />
                                </td>
                            </tr>
                            }
                            </>)
                        })}

                        </tbody>
                    </table>
                  }
                </LoadingOverlay>                    
              </div>
            </div>
          </div>
        </div>            
      </div>           
    </>
    );
}

export default withRouter(FVHistorico);                  
import React, { useState, useEffect } from 'react';
import Api from '../../../services/api';
import { withRouter } from "react-router";

import Swal from "sweetalert2";
import { useToasts } from 'react-toast-notifications';
import LoadingOverlay from 'react-loading-overlay';

import Select from 'react-select';

import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import pt from "date-fns/locale/pt-BR";
registerLocale("pt-BR", pt);

const AtComissao = () => {
  
  const { addToast } = useToasts();
  // const [ formAtualizar, setFormAtualizar ] = useState([]);
  const [ formEstabelecimento, setFormEstabelecimento ] = useState({});
  const [ estabelecimentosSelect, setEstabelecimentoSelect ] = useState([]);
  const [ estabelecimento, setEstabelecimento ] = useState([]);
  const [ caProcessa, setCaProcessa ] = useState(false);
  const [ pesquisou, setPesquisou ] = useState(false);
  const [ , setRetorno ] = useState([]);

  useEffect(() => {
    getEstabelecimentoSelect();
  }, []);

  function filtrar() {
    setCaProcessa(true); 
      var data = {
        estabelecimento: estabelecimento,
      };
  
      Api.post('estabelecimentos/filtrarestformapagamento', data).then((rps) => {
        if(rps.data.status==false){
          Swal.fire({
            title: "Atenção!",
            icon: "info",
            html: rps.data.erro,
            showCloseButton: true,
            showCancelButton: false,
          });
          setPesquisou(false);
        } else {
          setFormEstabelecimento(rps.data.obj);
        }
      setCaProcessa(false); 
    });
  }

  function getEstabelecimentoSelect() {
    Api.get("estabelecimentos/listaNomeEstabelecimento").then(rps => {
      var rep = rps.data.obj;
      var nRep = [];
      nRep[nRep.length] = {value: '', label: 'Selecione...'};
      rep.forEach(e => {
        nRep[nRep.length] = {value: e.id_estabelecimento, label: e.id_estabelecimento + ' - ' + e.nome};
      })
      setEstabelecimentoSelect(nRep);
    })
  }

  function alterar() {
    setCaProcessa(true);
    
    Api.post('estabelecimentos/alteraEstFormaPagamento', formEstabelecimento).then(rps => {
      if (rps.data.status === true) {
        addToast(rps.data.mensagem, {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 2000
        });
        setRetorno(rps.data.obj);
      } else {
        Swal.fire({
          title: "Erro!",
          icon: "error",
          html: rps.data.erros,
          showCloseButton: true,
          showCancelButton: false,
        });
      }
      setCaProcessa(false);
    });
  }
    
    return (
        <> 
<div>

<div className="subheader espaco-header-pedidos subheader-transparent" id="kt_subheader">
          <div className="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
            {/*begin::Details*/}
            <div className="d-flex align-items-center flex-wrap mr-2">
              {/*begin::Title*/}
              <h5 className="text-dark font-weight-bold mt-2 mb-2 mr-5">Ferramentas</h5>
              {/*end::Title*/}
              {/*begin::Separator*/}
              <div className="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200" />
              {/*end::Separator*/}
              {/*begin::Title*/}
              <h5 className="text-dark font-weight-bold mt-2 mb-2 mr-5">Retirar do Pagamento Online</h5>
              {/*end::Title*/}
            </div>
            {/*end::Details*/}
            {/*begin::Toolbar*/}
            <div className="d-flex align-items-center">

            </div>
            {/*end::Toolbar*/}
          </div>
        </div>
        {/*begin::Entry*/}
        <div className="d-flex flex-column-fluid">
          {/*begin::Container*/}
          <div className="container">
            <LoadingOverlay
              active={caProcessa}
              spinner
              text='Carregando...'
            > 
            <div className="card card-custom">
              <div className="row ml-5 mr-5 mt-5">
                <div className="form-group col-md-10">
                  <label>Estabelecimento <b className="text-danger">*</b></label>
                  <Select
                      options={estabelecimentosSelect}
                      placeholder="Selecione..." 
                      defaultValue={estabelecimentosSelect.find(
                        x => x.value === estabelecimento
                      )}
                      onChange={(e) => { setEstabelecimento(e.value); setPesquisou(false) }} 
                    />
                </div>

                <div className="col-md-2 form-group">
                  <label>&nbsp;</label><br />
                    <button className="btn btn-block btn-primary" onClick={e=>{filtrar(); setPesquisou(true) }}><i className="fas fa-filter" />Filtrar</button>
                </div> 
              </div>            
            </div>
            </LoadingOverlay>
            <br /><br />
            { pesquisou === true &&
            <LoadingOverlay
              active={caProcessa}
              spinner
              text='Carregando...'
            >
            <div className="card card-custom">
              <div className="row ml-5 mr-5">
                <div className='col-md-12 mt-5'>
                  <label>Formas de Pagamentos do estabelecimento: <b>{formEstabelecimento?.id_estabelecimento}</b></label>
                </div>
              </div>

              <div className="row ml-5 mr-5">
                <div className='col-md-12 mt-5'>
                  <div className="checkbox-inline">
                    <label className="checkbox checkbox-square checkbox-primary m-t-3">
                      <input type="checkbox"
                        value={formEstabelecimento?.aceita_pgto_pix}
                        onChange={(e) => { setFormEstabelecimento({ ...formEstabelecimento, aceita_pgto_pix: !formEstabelecimento.aceita_pgto_pix }) }}
                        checked={formEstabelecimento?.aceita_pgto_pix == 1 ? true : false} />
                      Pix
                      <span /></label>
                  </div>
                  <div className="checkbox-inline">
                    <label className="checkbox checkbox-square checkbox-primary m-t-3">
                      <input type="checkbox"
                        value={formEstabelecimento?.aceita_pgto_online}
                        onChange={(e) => { setFormEstabelecimento({ ...formEstabelecimento, aceita_pgto_online: !formEstabelecimento.aceita_pgto_online }) }}
                        checked={formEstabelecimento?.aceita_pgto_online == 1 ? true : false} />
                      Pagamento Online
                      <span /></label>
                  </div>
                  <div className="checkbox-inline">
                    <label className="checkbox checkbox-square checkbox-primary m-t-3">
                      <input type="checkbox"
                        value={formEstabelecimento?.aceita_pgto_nupay}
                        onChange={(e) => { setFormEstabelecimento({ ...formEstabelecimento, aceita_pgto_nupay: !formEstabelecimento.aceita_pgto_nupay }) }}
                        checked={formEstabelecimento?.aceita_pgto_nupay == 1 ? true : false} />
                      Nupay
                      <span /></label>
                  </div>
                  <div className="checkbox-inline">
                    <label className="checkbox checkbox-square checkbox-primary m-t-3">
                      <input type="checkbox"
                        value={formEstabelecimento?.aceita_pgto_picpay}
                        onChange={(e) => { setFormEstabelecimento({ ...formEstabelecimento, aceita_pgto_picpay: !formEstabelecimento.aceita_pgto_picpay }) }}
                        checked={formEstabelecimento?.aceita_pgto_picpay == 1 ? true : false} />
                      PicPay
                      <span /></label>
                  </div>
                </div>
              </div>

              <div className="row ml-5 mr-5">
                <div className='col-md-12 mb-10 text-right'>
                  <button className="btn btn-primary font-weight-bold" onClick={e=> { alterar(); setPesquisou(false) }}><i className="fas fa-pencil-alt" />Alterar</button>
                </div>
              </div>            
            </div>
                </LoadingOverlay> 
            }
          </div>

          </div>
        </div>
        </>
    );

}



export default withRouter(AtComissao);                  
import React from "react";
import { createPortal } from "react-dom";
import * as S from "./styles";

export const Modal = ({
  children,
  toggleModalVisibility,
  visible,
  showCloseButton,
  insideModal,
}) => {
  return createPortal(
    <S.Container visible={visible}>
      <S.Overlay onClick={toggleModalVisibility} />
      <S.ModalContainer>
        {children}
        {showCloseButton && (
          <S.CloseModalButton insideModal={insideModal}>
            <S.CloseModalIcon
              className="la la-close"
              onClick={toggleModalVisibility}
              insideModal={insideModal}
            />
          </S.CloseModalButton>
        )}
      </S.ModalContainer>
    </S.Container>,
    document.getElementById("modal-root")
  );
};

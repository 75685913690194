import React, { forwardRef } from "react";
import ReactSelect from "react-select";

import * as S from "./styles";

export const Select = forwardRef(
  ({ label = "", options, error, isMandatory, ...rest }, ref) => {
    const getBorderColor = (isFocused) => {
      if (error) {
        return "#ff3333";
      }

      return isFocused ? "#AAAAAA" : "#E5EAEE";
    };

    const customStyles = {
      control: (baseStyles, state) => ({
        ...baseStyles,
        maxHeight: 44,
        borderRadius: 12,
        borderColor: getBorderColor(state.isFocused),
        "&:hover": {
          borderColor: getBorderColor(state.isFocused),
        },
        boxShadow: "none",
        padding: 6,
      }),
      option: (styles, state) => ({
        ...styles,
        color: state.isDisabled ? "#888" : "#333",
        background: state.isSelected ? "#ddd" : "#fff",
        "&:hover": {
          background: "#deebff",
        },
      }),
      placeholder: (baseStyles) => ({
        ...baseStyles,
        fontFamily: "Nunito",
      }),
      dropdownIndicator: (baseStyles) => ({
        ...baseStyles,
        paddingTop: 5,
        paddingBottom: 5,
      }),
      clearIndicator: (baseStyles) => ({
        ...baseStyles,
        paddingTop: 5,
        paddingBottom: 5,
      }),
    };

    return (
      <S.Container>
        <S.Label>
          {label} {isMandatory && <span>*</span>}
        </S.Label>

        <ReactSelect
          options={options}
          ref={ref}
          styles={customStyles}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary50: "transparent",
              primary: "#E8ECF0",
            },
          })}
          {...rest}
        />

        {error && <strong>{error.message}</strong>}
      </S.Container>
    );
  }
);

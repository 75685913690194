import React from "react";

import * as S from "./styles";

export const SubmitButton = ({ pageStatus, handleSubmit, submitForm }) => {
  return (
    <S.SaveProductContainer>
      <div className="d-flex align-items-center mt-2">
        <button
          className="btn btn-lg btn-success"
          onClick={handleSubmit?.(submitForm)}
          disabled={pageStatus === "loading"}
        >
          {pageStatus === "loading" ? (
            <span className="spinner" />
          ) : (
            <label>Salvar produto</label>
          )}
        </button>
      </div>
    </S.SaveProductContainer>
  );
};

import React, { useState, useEffect } from "react";
import { withRouter } from "react-router";

import Swal from "sweetalert2";
import LoadingOverlay from "react-loading-overlay";

import Select from "react-select";

import InputMask from "react-input-mask";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker, { registerLocale } from "react-datepicker";
import pt from "date-fns/locale/pt-BR";
import Apiv2 from "../../../services/apiv2";
registerLocale("pt-BR", pt);

const Nfse = () => {
    // const [cidadeSelect, setCidadeSelect] = useState([]);
    // const [pgOnlineSelect, setPgOnlineSelect] = useState([]);
    const [frmBusca, setFrmBusca] = useState({});
    const [loadingNfse, setLoadingNfse] = useState(false);

    // function getFirstDayOfMonth(year, month) {
    //     return new Date(year, month, 1);
    // }

    useEffect(() => {
        // const date = new Date();
        //const firstDayCurrentMonth = getFirstDayOfMonth(date.getFullYear(), date.getMonth(),);
        getStatusSelect();

    }, []);

    const [faturas, setFaturas] = useState([]);
    function searchNfse() {
        setLoadingNfse(true);
        Apiv2.post("/fiscal/nfse/listByFatura", frmBusca).then((rps) => {
            setLoadingNfse(false);

            if(rps.data.status!==true){
                alert(rps.data.message);
                return;
            }

            setFaturas(rps.data.faturas);

        });
    }
    function timeConverter(UNIX_timestamp, full){
        var a = new Date(UNIX_timestamp * 1000);
        //var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
        var year = a.getFullYear();
        var month = a.getMonth()+1;
        var date = a.getDate();
        var hour = a.getHours();
        var min = a.getMinutes();
        var sec = a.getSeconds();
        if(full===true){
            var time = date + '/' + month + '/' + year + ' ' + hour + ':' + min + ':' + sec ;
        } else {
            time = date + '/' + month + '/' + year;
        }
        return time;
      }
    
    const [statusSelect, setStatusSelect] = useState([]);
    function getStatusSelect() {
        var nCid = [];
        nCid[nCid.length] = { value: "T", label: "Todas" };
        nCid[nCid.length] = { value: "autorizado", label: "Autorizado" };
        nCid[nCid.length] = { value: "dif-autorizado", label: "Não autorizado" };
        setStatusSelect(nCid);
    }

    const [globalLoading, setGlobalLoading] = useState(false);
    function conferir(fatura){
        setGlobalLoading(true);
        Apiv2.post("/fiscal/nfse/checkNfse", {'id_fatura': fatura.id_fatura}).then((rps) => {
            setGlobalLoading(false);
            Swal.fire({
                title: '<strong>Busca da NFSe</strong>',
                icon: 'info',
                html: rps.data.message,
                showCloseButton: true,
                showCancelButton: true,
            })
        })
    }

    function emitir(fatura){
        setGlobalLoading(true);
        Apiv2.post("/fiscal/nfse/emitirNfse", {'id_fatura': fatura.id_fatura}).then((rps) => {
            setGlobalLoading(false);
            Swal.fire({
                title: '<strong>Emissão da NFSe</strong>',
                icon: 'info',
                html: rps.data.message,
                showCloseButton: true,
                showCancelButton: true,
            })
        })
    }


    return (
        <>
            
                <div className="subheader espaco-header-pedidos subheader-transparent" id="kt_subheader">
                    <div className="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
                        <div className="d-flex align-items-center flex-wrap mr-2">
                            <h5 className="text-dark font-weight-bold mt-2 mb-2 mr-5">Buscar notas por DATA DE PAGAMENTO DA FATURA</h5>
                            <div className="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200" />
                        </div>
                    </div>
                </div>
                <div className="d-flex flex-column-fluid">
                    <div className="container">
                        <div className="card card-custom">
                            <div className="ml-12 mr-12 mt-12">
                                        <LoadingOverlay
                                            active={globalLoading}
                                            spinner
                                            text='Loading your content...'
                                        >
                                            <div className="row">
                                                <div>
                                                    <label  style={{paddingRight: 5}}>De</label>
                                                    <DatePicker
                                                        name="dt_final"
                                                        onChange={e => { setFrmBusca({ ...frmBusca, de: e }) }}
                                                        isClearable
                                                        locale="pt-BR"
                                                        className="form-control"
                                                        placeholderText="dd/mm/aaaa"
                                                        selected={frmBusca.de}
                                                        dateFormat="dd/MM/y"
                                                        selectsEnd
                                                        customInput={
                                                            <InputMask mask="99/99/9999" />} />
                                                </div>
                                                <div>
                                                    <label style={{padding: 5}}> Até </label>
                                                    <DatePicker
                                                        name="dt_final"
                                                        onChange={e => { setFrmBusca({ ...frmBusca, ate: e }) }}
                                                        isClearable
                                                        locale="pt-BR"
                                                        className="form-control"
                                                        placeholderText="dd/mm/aaaa"
                                                        selected={frmBusca.ate}
                                                        minDate={frmBusca.de}
                                                        dateFormat="dd/MM/y"
                                                        selectsEnd
                                                        customInput={
                                                            <InputMask mask="99/99/9999" />} />
                                                </div>
                                                <div className="form-group col-md-4">
                                                    <Select
                                                        options={statusSelect}
                                                        placeholder="Selecione..."
                                                        defaultValue={statusSelect.find(
                                                            x => x.value === frmBusca.status
                                                        )}
                                                        onChange={(e) => { setFrmBusca({ ...frmBusca, status: e.value }) }}

                                                    />
                                                </div>
                                                <div className="form-group col-md-3">
                                                    <button className="btn btn-primary btn-block" onClick={e => { searchNfse(e) }}>Buscar</button>
                                                </div>
                                            </div> 

                                            <LoadingOverlay
                                                active={loadingNfse}
                                                spinner
                                                text='Loading your content...'
                                            >
                                                <table ng-show="receipts.length" className="table table-hover ng-scope" ng-init="propertyName='value';reverse=false;" style={{}}>
                                                    <thead>
                                                        <tr>
                                                            <th role="button"></th>
                                                            <th role="button">HASH</th>
                                                            <th role="button">Nome loja</th>
                                                            <th role="button">Data</th>
                                                            <th role="button">Valor pago</th>
                                                            <th role="button">Status<br />Ref</th>
                                                            <th role="button">Ações</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {faturas.map(fatura => {
                                                            return (
                                                                <tr>
                                                                    <td>

                                                                    </td>
                                                                    <td>{fatura.hash_fatura}</td>
                                                                    <td>{fatura.nome}</td>
                                                                    <td>{timeConverter(fatura.data_pagamento, false)}</td>
                                                                    <td>{fatura.valor_pago}</td>
                                                                    <td>
                                                                        {fatura.focus_nfse_status}<br />
                                                                        {fatura.focus_nfse_ref}
                                                                    </td>
                                                                    <td>
                                                                        <div className="row">
                                                                            <div style={{paddingRight: 5}}>
                                                                                <button className="btn btn-success" onClick={e => conferir(fatura)}>Conferir</button>
                                                                            </div>
                                                                            <div>
                                                                                <button className="btn btn-info" onClick={e => emitir(fatura)}>Reemitir</button>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>
                                                </table>
                                            </LoadingOverlay>
                                                    
                                        </LoadingOverlay>
                               
                            </div>
                        </div>
                    </div>
                </div>
        </>
    );
};

export default withRouter(Nfse);

import React, { useEffect } from 'react'

import { Filter } from './components/Filter';
import { Table } from './components/Table';

import * as S from './styles';
import useRoles from '../../utils/useRoles';
import apiLaravelv2 from '../../services/api-laravelv2';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

function Produtos() {
    const { doIHaveThisRole } = useRoles();
    const [options, setOptions] = React.useState([]);
    const [filter, setFilter] = React.useState({});

    const history = useHistory();


    useEffect(() => {
        apiLaravelv2.get("products/categories").then((response) => {
            if (response.data.categories) {
                setOptions(response.data.categories.map(category => ({
                    value: category.id_produto_categoria,
                    label: category.nome
                })));
            }
        });
    }, []);

    return (
        <>
            <div>
                <div
                    className="subheader espaco-header-pedidos subheader-transparent"
                    id="kt_subheader"
                >
                    <div className="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
                        <div className="d-flex align-items-center flex-wrap mr-2">
                            <h5 className="text-dark font-weight-bold mt-2 mb-2 mr-5">
                                Gerenciar Produtos com EAN
                            </h5>
                            <div className="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200" />
                        </div>
                        {doIHaveThisRole('est_pro_ia') && (
                            <div className="d-flex align-items-center mt-2">
                                <button
                                    className="btn btn-lg btn-success"
                                    onClick={(e) => {
                                        history.push('produtos/RegisterProductEan')
                                    }}
                                >
                                    <i className="fas fa-plus" />
                                    Adicionar
                                </button>
                            </div>
                        )}
                    </div>
                </div>
                <div className="container">
                    <S.Content>
                        <Filter onChange={setFilter} options={options} />
                        <Table filter={filter} />
                    </S.Content>
                </div>
            </div >
        </>
    )
}

export default Produtos;

import styled from "styled-components";

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  h2 {
    font-size: 16px;
    font-weight: 600;
    color: #333333;
  }
`;

export const SubscribersWrapper = styled.div`
  display: flex;
  gap: 70px;
  border: 1px #dcdcdc solid;
  border-radius: 8px;
  padding: 30px 24px;
  flex-wrap: wrap;
`;

export const LogsDownloadWrapper = styled.div`
  width: fit-content;
  margin-top: 20px;
`;

export const LogsDownloadTextButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;
  gap: 5px;
`;

export const LogsDownloadLabel = styled.label`
  color: #ff3333;
  font-size: 14px;
  margin: 0;
  cursor: pointer;
`;

export const Icon = styled.i`
  color: #ff3333;
  font-size: 22px;
`;

export const ModalContent = styled.div`
  width: 650px;
  height: 260px;
  padding: 25px;
  border-radius: 8px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  h2 {
    font-size: 24px;
    font-weight: 600;
    color: #333333;
  }

  p {
    font-size: 16px;
    color: #333333;
    margin-top: 25px;
  }
`;

export const ModalSubmitButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;

  color: ${({ action }) => (action === "generate" ? "#00b333" : "#ff3333")};
`;

export const ModalButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 30px;
  color: #ff2222;

  button,
  ${ModalSubmitButton} {
    text-decoration: none;
    border: none;
    background: none;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
  }

  button {
    color: #333333;
  }
`;

export const ErrorFeedbackWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 70px;
  h3,
  p {
    text-align: center;
  }
`;

import React from "react";

import "./index.css";

import LoginForm from "./components/LoginForm";
import ModalEsqueci from "./components/ModalEsqueci";
import ModalCadastro from "./components/ModalCadastro";

const Login = () => {
  return (
    <>
      <div
        className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-row-fluid bg-white full-lg"
        id="kt_login"
      >
        <div
          className="login-aside d-flex flex-row-auto px-lg-0 px-5 pb-sm-40 flex-grow-1"
          style={{
            height: "100%",
            minHeight: "100%",
            backgroundSize: "cover",
            maxWidth: "50%",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundImage: "url(assets/bg.png)",
          }}
        ></div>
        <LoginForm />
      </div>

      <ModalEsqueci />
      <ModalCadastro />
    </>
  );
};

export default Login;

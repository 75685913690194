import React, { useState, useEffect, useCallback } from 'react';
import Api from '../../../services/api';
import CouponApi from '../../../services/coupon-api';
import { withRouter } from "react-router";

import Swal from "sweetalert2";
import { useToasts } from 'react-toast-notifications';
import LoadingOverlay from 'react-loading-overlay';

import { Modal } from "react-bootstrap";
import Select from 'react-select';

import InputMask from 'react-input-mask';
import "react-datepicker/dist/react-datepicker.css";
import DatePicker, { registerLocale } from "react-datepicker";
import pt from "date-fns/locale/pt-BR";
registerLocale("pt-BR", pt);

const Paginacao = ({currentPage, pageCount, handlePageClick}) => {

  const renderRow = (row) => {
    return (<span>{row}</span>)
  }

  let rows = []

  if (currentPage !== 1) {
    rows.push(
      <span onClick={() => handlePageClick(currentPage - 1)} className="btn btn-icon btn-sm btn-light-primary mr-2 my-1">
        <i className="ki ki-bold-arrow-back icon-xs"></i>
      </span>
    );
  } else {
    rows.push(
      <span className="btn-icon btn-sm btn-secondary disabled mr-2 my-1">
        <i className="ki ki-bold-arrow-back icon-xs"></i>
      </span>
    )
  }

  if (currentPage > 3) {
    rows.push(
      <>
        <span onClick={() => handlePageClick(1)} className="btn btn-icon btn-sm btn-light-primary mr-2 my-1">
          1
        </span>
        <span className="btn-icon btn-sm btn-secondary disabled mr-2 my-1">
          ...
        </span>
      </>
    );
  }

  for (let i = currentPage - 2; i < currentPage + 3; i++) {
    if (i > 0 && i <= pageCount) {
      if (i === currentPage) {
        rows.push(<span onClick={() => handlePageClick(i)} className="btn btn-icon btn-sm border-0 btn-hover-primary active mr-2 my-1">{i}</span>)
      } else {
        rows.push(<span onClick={() => handlePageClick(i)} className="btn btn-icon btn-sm border-0 btn-hover-primary mr-2 my-1">{i}</span>)
      }
    }
  }

  if (currentPage + 3 <= pageCount) {
    rows.push(
      <>
        <span className="btn-icon btn-sm btn-secondary disabled mr-2 my-1">
          ...
        </span>
        <span onClick={() => handlePageClick(pageCount)} className="btn btn-icon btn-sm btn-light-primary mr-2 my-1">
          {pageCount}
        </span>
      </>
    );
  }

  if (currentPage !== pageCount) {
    rows.push(
      <span onClick={() => handlePageClick(currentPage + 1)} className="btn btn-icon btn-sm btn-light-primary mr-2 my-1">
        <i className="ki ki-bold-arrow-next icon-xs"></i>
      </span>
    );
  } else {
    rows.push(
      <span className="btn-icon btn-sm btn-secondary disabled mr-2 my-1">
        <i className="ki ki-bold-arrow-next icon-xs"></i>
      </span>
    )
  }

  if (rows.length === 0) {
    return (<p>Nenhum item</p>)
  }

  return (
    <div>
      {rows.map(renderRow)}
    </div>
  )
}

const Cupons = () => {
  const { addToast } = useToasts();
  const [cupons, setCupons] = useState([]);
  const [totalRegistros, setTotalRegistros] = useState();
  const [caProcessa, setCaProcessa] = useState(false);
  const [buscaHash, setBuscaHash] = useState();
  const [buscaDataInicio, setBuscaDataInicio] = useState();
  const [buscaDataFinal, setBuscaDataFinal] = useState();
  const [buscaPagador, setBuscaPagador] = useState();
  const [buscaDesconto, setBuscaDesconto] = useState();
  const [modal, setModal] = useState(false);
  const [cidadeFiltra, setCidadeFiltra] = useState([]);
  const [estabelecimentosLista, setEstabelecimentosLista] = useState([]);
  const [paymentTypes] = useState([{
    label: "PIX",
    value: "PIX"
  }, {
    label: "PicPay",
    value: "PICPAY"
  }, {
    label: "Cartão de Crédito",
    value: "CARD"
  }, {
    label: "Presencial",
    value: "IN_PERSON"
  }]);
  const [formCupom, setFormCupom] = useState({});
  const [ordenacao, setOrdenacao] = useState('id_cupom');
  const [ordenacaoSet, setOrdenacaoSet] = useState();
  const [currentPage, setCurrentPage] = useState(1);

  const PER_PAGE = 10;
  const pageCount = Math.ceil(totalRegistros / PER_PAGE);

  let acesso = JSON.parse(localStorage.getItem('@ADM-Aiboo/perfil'));

  function fecharModal() {
    setModal(false);
  }

  function getListar() {
    setCaProcessa(true);

    var data = {
      limite: PER_PAGE,
      start: 0,
      ordenacao: 'id_usuario',
      sentido: 'ASC',
    }

    CouponApi.post("list", data).then(rps => {
      setCupons(rps.data.obj);
      setTotalRegistros(rps.data.total);
      setCaProcessa(false);
    })
  }

  const getCidade = useCallback(() => {
    Api.get("cidades/listar").then(rps => {
      setCidadeFiltra(rps.data.cidades);
    })
  }, []);

  /*Inicio - Dados externos*/
  async function getEstabelecimento() {
    const rps = await Api.get("estabelecimentos/listaNomeEstabelecimento");
    if (rps.data.status !== undefined && rps.data.status === true) {
      var est = rps.data.obj;
      var nEst = [];
      est.forEach(e => {
        nEst[nEst.length] = { value: e.id_estabelecimento, label: e.nome + " [Cidade: " + e.nome_cidade + "]" };
      })
      setEstabelecimentosLista(nEst);
    }
  }

  function adicionarCupom() {
    setFormCupom({});
    setModal(true);
  }

  function editarCupom(id_cupom) {
    var cu = JSON.parse(JSON.stringify(id_cupom));

    var ArrayEst = [];
    if (cu.id_estabelecimento.length > 1) {
      var est = cu.id_estabelecimento.replace("[", "").replace("]", "");
      ArrayEst = est.split(',');
    }

    var parsePaymentType = [];
    if ((cu.payment_type || []).length > 1)
      parsePaymentType = JSON.parse(cu.payment_type)

    cu.id_estabelecimento = ArrayEst;
    cu.payment_type = parsePaymentType;
    cu.data_inicio = new Date(cu.data_inicio * 1000);
    cu.data_expiracao = new Date(cu.data_expiracao * 1000);

    setFormCupom(cu);
    setModal(true);
  }

  function reutilizarCupom(id_cupom) {
    var ca = JSON.parse(JSON.stringify(id_cupom));

    setCaProcessa(true);

    let today = new Date();
    let date = today.getDate() + "-" + parseInt(today.getMonth() + 1) + "-" + today.getFullYear();

    Swal.fire({
      icon: "question",
      title: 'Confirmação',
      html: 'Deseja reutilizar a Hash ' + ca.hash_cupom + '? <br /><br />Esta ação irá alterar a Hash dos cupons já utilizados para ' + ca.hash_cupom + '_' + date,
      showCancelButton: true,
      confirmButtonText: `Sim, Confirmar!`,
      cancelButtonText: 'Não, Cancelar.'
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {

        CouponApi.post('reuse', ca).then(rps => {
          if (rps.data.status == true) {
            addToast(rps.data.mensagem, {
              appearance: "success",
              autoDismiss: true,
              autoDismissTimeout: 2000
            });
            getListar();
          }
        })
      }
      setCaProcessa(false);
    })
  }

  function inutilizarCupom(id_cupom) {
    var ca = JSON.parse(JSON.stringify(id_cupom));

    setCaProcessa(true);

    Swal.fire({
      icon: "question",
      title: 'Confirmação',
      html: 'Deseja inutilizar o cupom?',
      showCancelButton: true,
      confirmButtonText: `Sim, Confirmar!`,
      cancelButtonText: 'Não, Cancelar.'
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {

        Api.post('cupons/inutilizar', ca).then(rps => {
          if (rps.data.status == true) {
            addToast(rps.data.mensagem, {
              appearance: "success",
              autoDismiss: true,
              autoDismissTimeout: 2000
            });
            getListar();
          }
        })
      }
      setCaProcessa(false);
    })
  }

  function salvar() {
    var nIdEstabelecimento = [];
    var parsePaymentType = [];
    var nFormEnvia = {};
    nFormEnvia = JSON.parse(JSON.stringify(formCupom));

    if (formCupom.id_estabelecimento !== null && formCupom.id_estabelecimento !== undefined) {
      formCupom.id_estabelecimento.forEach(e => {
        if (!e.value) {
          nIdEstabelecimento[nIdEstabelecimento.length] = e;
        }
        else {
          nIdEstabelecimento[nIdEstabelecimento.length] = e.value;
        }
      });
      nFormEnvia.id_estabelecimento = nIdEstabelecimento;
    }

    if (formCupom.payment_type !== null && formCupom.payment_type !== undefined) {
      formCupom.payment_type.forEach(e => {
        if (!e.value) {
          parsePaymentType[parsePaymentType.length] = e;
        }
        else {
          parsePaymentType[parsePaymentType.length] = e.value;
        }
      });
      nFormEnvia.payment_type = parsePaymentType;
    } else {
      formCupom.payment_type = null;
    }

    CouponApi.post('create-and-edit', nFormEnvia).then(rps => {
      if (rps.data.status === true) {
        addToast(rps.data.mensagem, {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 2000
        });
        setModal(false);
        setFormCupom({});
      } else {
        Swal.fire({
          title: "Erro!",
          icon: "error",
          html: rps.data.erros,
          showCloseButton: true,
          showCancelButton: false,
        });
      }
    });
  }

  function filtrar(order) {

    var sentido = 'ASC';

    if (order != null) {
      setOrdenacao(order);
    } else {
      //Valor padrão para a primeira vez
      setOrdenacao('id_cupom');
      order = 'id_cupom';
    }

    if (order === ordenacao) {
      //Caso a orden atual seja igual a anterior
      if (ordenacaoSet === 'DESC' || ordenacaoSet === null) {
        //Verifico se estou em ordem DESC e inverto para ASC
        sentido = 'ASC';
        setOrdenacaoSet('ASC');
      } else {
        sentido = 'DESC';
        setOrdenacaoSet('DESC');
      }
    } else {
      //Caso a ordem atual não seja igual a anterior ASC
      setOrdenacaoSet('ASC');
    }

    setCaProcessa(true);
    var data = {
      limite: PER_PAGE,
      start: 0,
      hash: buscaHash,
      dataInicio: buscaDataInicio,
      dataFinal: buscaDataFinal,
      pagador: buscaPagador,
      desconto: buscaDesconto,
      ordenacao: order,
      sentido: sentido,
    };

    CouponApi.post('list', data).then((rps) => {

      if (rps.data.status == false) {
        Swal.fire({
          title: "Atenção!",
          icon: "info",
          html: rps.data.erro,
          showCloseButton: true,
          showCancelButton: false,
        });
      } else {
        setCupons(rps.data.obj);
        setTotalRegistros(rps.data.total);
        setCurrentPage(1);
      }
      setCaProcessa(false);
    });
  }

  function handlePageClick(pagina) {
    setCaProcessa(true);
    setCurrentPage(pagina);
    var data = {
      limite: PER_PAGE,
      start: (pagina - 1) * PER_PAGE,
      hash: buscaHash,
      dataInicio: buscaDataInicio,
      dataFinal: buscaDataFinal,
      pagador: buscaPagador,
      desconto: buscaDesconto,
      ordenacao: ordenacao,
      sentido: ordenacaoSet,
    }

    Api.post("cupons/listarCupons", data).then(rps => {
      setCupons(rps.data.obj);
      setCaProcessa(false);
    })
  }

  useEffect(() => {
    getListar();
    getCidade();
    getEstabelecimento();
  }, [getCidade]);

  return (
    <>
      <div>

        <div className="subheader espaco-header-pedidos subheader-transparent" id="kt_subheader">
          <div className="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
            {/*begin::Details*/}
            <div className="d-flex align-items-center flex-wrap mr-2">
              {/*begin::Title*/}
              <h5 className="text-dark font-weight-bold mt-2 mb-2 mr-5">Cupons</h5>
              {/*end::Title*/}
              {/*begin::Separator*/}
              <div className="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200" />
              {/*end::Separator*/}
            </div>
            {/*end::Details*/}
            {/*begin::Toolbar*/}
            <div className="d-flex align-items-center">
              <button className="btn btn-lg btn-success mt-3 mb-3" onClick={e => { adicionarCupom() }}><i className="fas fa-plus" />Adicionar</button>
            </div>
            {/*end::Toolbar*/}
          </div>
        </div>
        {/*begin::Entry*/}
        <div className="d-flex flex-column-fluid">
          {/*begin::Container*/}
          <div className="container">
            <div className="card card-custom">
              <div className="ml-10 mr-10 mt-10">
                <div className="row">
                  <div className="form-group col-md-2">
                    <label>Hash</label>
                    <input type="text" value={buscaHash} onChange={e => { setBuscaHash(e.target.value) }} className="form-control" placeholder="EX: HAAHUSUHSAY1" />
                  </div>
                  <div className="form-group col-md-3">
                    <label>Data Início</label>
                    <div>
                      <DatePicker
                        name="dt_inicio"
                        onChange={setBuscaDataInicio}
                        isClearable
                        showTimeSelect
                        selectsStart
                        locale="pt-BR"
                        className="form-control"
                        placeholderText="dd/mm/aaaa hh:mm"
                        selected={buscaDataInicio}
                        dateFormat="dd/MM/y HH:mm"
                        startDate={buscaDataInicio}
                        endDate={buscaDataFinal}
                        customInput={
                          <InputMask mask="99/99/9999 99:99" />} />
                    </div>
                  </div>
                  <div className="form-group col-md-3">
                    <label>Data Final</label>
                    <div>
                      <DatePicker
                        name="dt_final"
                        onChange={setBuscaDataFinal}
                        isClearable
                        showTimeSelect
                        locale="pt-BR"
                        className="form-control"
                        placeholderText="dd/mm/aaaa hh:mm"
                        selected={buscaDataFinal}
                        dateFormat="dd/MM/y HH:mm"
                        selectsEnd
                        startDate={buscaDataInicio}
                        endDate={buscaDataFinal}
                        minDate={buscaDataInicio}
                        customInput={
                          <InputMask mask="99/99/9999 99:99" />} />
                    </div>
                  </div>
                  <div className="form-group col">
                    <label>Pagador</label>
                    <select className="form-control" value={buscaPagador} onChange={e => { setBuscaPagador(e.target.value) }}>
                      <option value="" selected>Selecione</option>
                      <option value="A" >Aiboo</option>
                      <option value="E" >Estabelecimento</option>
                      <option value="M" >Ambos</option>
                    </select>
                  </div>
                  <div className="form-group col">
                    <label>Tipo do Desconto</label>
                    <select className="form-control" value={buscaDesconto} onChange={e => { setBuscaDesconto(e.target.value) }}>
                      <option value="" selected>Selecione</option>
                      <option value="F" >Frete</option>
                      <option value="T" >Total</option>
                      <option value="P" >Produtos</option>
                    </select>
                  </div>

                  <div className="col form-group">
                    <label>&nbsp;</label><br />
                    <button className="btn btn-primary btn-block" onClick={e => { filtrar() }}><i className="fas fa-filter" />Filtrar</button>
                  </div>
                </div>
              </div>

              <LoadingOverlay
                active={caProcessa}
                spinner
                text='Carregando...'
              >

                <table className="table table-striped">
                  <thead className="bg-light-primary">
                    <tr>
                      <th className="text-primary text-center">
                        <span className="text-hover-dark" onClick={e => { filtrar('id_cupom'); }}>
                          ID {ordenacao === 'id_cupom' ? <i className="fas fa-sort-down text-primary" /> : <i className="fas fa-sort text-primary" />}
                        </span>
                      </th>
                      <th className="text-primary text-center">
                        <span className="text-hover-dark" onClick={e => { filtrar('hash_cupom'); }}>
                          Hash {ordenacao === 'hash_cupom' ? <i className="fas fa-sort-down text-primary" /> : <i className="fas fa-sort text-primary" />}
                        </span>
                      </th>
                      <th className="text-primary">
                        <span className="text-hover-dark" onClick={e => { filtrar('tipo'); }}>
                          Benefício {ordenacao === 'tipo' ? <i className="fas fa-sort-down text-primary" /> : <i className="fas fa-sort text-primary" />}
                        </span>
                      </th>
                      <th className="text-primary">
                        <span className="text-hover-dark" onClick={e => { filtrar('pagador'); }}>
                          Pagador {ordenacao === 'pagador' && ordenacaoSet === 'ASC' ? <i className="fas fa-sort-down text-primary" /> : <i className="fas fa-sort text-primary" />}
                        </span>
                      </th>
                      <th className="text-primary text-center">
                        <span className="text-hover-dark" onClick={e => { filtrar('quantidade_uso'); }}>
                          Usos {ordenacao === 'quantidade_uso' ? <i className="fas fa-sort-down text-primary" /> : <i className="fas fa-sort text-primary" />}
                        </span>
                      </th>
                      <th className="text-primary text-center">
                        <span className="text-hover-dark" onClick={e => { filtrar('id_estabelecimento'); }}>
                          Estabelecimento {ordenacao === 'id_estabelecimento' ? <i className="fas fa-sort-down text-primary" /> : <i className="fas fa-sort text-primary" />}
                        </span>
                      </th><th className="text-primary text-center">
                        <span className="text-hover-dark" onClick={e => { filtrar('data_inicio'); }}>
                          Data Início/Fim {ordenacao === 'data_inicio' ? <i className="fas fa-sort-down text-primary" /> : <i className="fas fa-sort text-primary" />}
                        </span>
                      </th>
                      <th className="text-primary text-center">
                        <span className="text-hover-dark" onClick={e => { filtrar('status'); }}>
                          Status {ordenacao === 'status' ? <i className="fas fa-sort-down text-primary" /> : <i className="fas fa-sort text-primary" />}
                        </span>
                      </th>
                      <th className="text-center">Ações</th>

                    </tr>
                  </thead>
                  <tbody>
                    {cupons !== null &&
                      cupons.map((item) => {
                        return (
                            <tr key={item.hash_cupom}>
                              <td className="text-center">{item.id_cupom}</td>
                              <td>{item.hash_cupom}</td>
                              <td>
                                {item.tipo == "F" && <>Frete</>}
                                {item.tipo == "T" && <>Total</>}
                                {item.tipo == "P" && <>Produtos</>}
                                {item.porcentagem > 0 && <>{item.porcentagem * 100}%</>}
                              </td>
                              <td>{item.pagador === 'A' &&
                                    <div>Aiboo<br />{item.observacao}</div> }
                                  {item.pagador === 'E' &&
                                    <div>Estabelecimento<br />{item.observacao}</div>}
                                  {item.pagador === 'M' &&
                                    <div>Ambos<br />{item.observacao}</div>}
                              </td>
                              <td className="text-center">{item.uso_maximo != null ?
                                <div>{item.quantidade_uso} de {item.uso_maximo}</div> :
                                <div>{item.quantidade_uso}</div>}
                              </td>
                              <td className="text-center">{item.id_estabelecimento == 0 && "S/D EST"}</td>
                              <td className="text-center">
                                {(item.data_inicio_form != '31/12/1969 21:00:00' && item.data_expiracao_form != '31/12/1969 21:00:00')
                                  ? <span><b>I:</b> {item.data_inicio_form}<br /><b>F:</b> {item.data_expiracao_form}</span>
                                  : item.data_inicio_form != '31/12/1969 21:00:00'
                                    ? <span><b>I:</b> {item.data_inicio_form}</span>
                                    : item.data_expiracao_form != '31/12/1969 21:00:00'
                                      ? <span><b>F:</b> {item.data_expiracao_form}</span>
                                      : ''}
                              </td>
                              <td className="text-center">
                                {item.status === 'R' && <span className="label label-warning label-inline">Reutilizado</span>}
                                {item.status === 'I' && <span className="label label-danger label-inline">Inutilizado</span>}
                              </td>
                              <td className="text-center">
                                <div className="dropdown dropdown-inline">
                                  <button type="button" className="btn btn-light-primary btn-icon btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i className="ki ki-bold-more-ver"></i>
                                  </button>
                                  <div className="dropdown-menu">
                                    <span className="dropdown-item" onClick={e => { editarCupom(item) }}>Editar</span>
                                    {acesso.est_ger_cup_ei === "1" && <>
                                      {item.status !== 'I' &&
                                        <>
                                          <div className="dropdown-divider"></div>
                                          <span className="dropdown-item" onClick={e => { inutilizarCupom(item) }}>Inutilizar</span>
                                        </>
                                      }
                                      {item.status !== 'R' &&
                                        <>
                                          <div className="dropdown-divider"></div>
                                          <span className="dropdown-item" onClick={e => { reutilizarCupom(item) }}>Reutilizar Hash</span>
                                        </>
                                      }
                                    </>}
                                  </div>
                                </div>
                              </td>
                            </tr>
                        )
                      })
                    }
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colspan="9" className="text-right">
                        <Paginacao 
                          currentPage={currentPage} 
                          pageCount={pageCount}
                          handlePageClick={handlePageClick}
                        />
                      </td>
                    </tr>
                  </tfoot>
                </table>

              </LoadingOverlay>

            </div>
          </div>
        </div>
      </div>

      <Modal size={'xl'} show={modal} onHide={() => fecharModal()}>
        <Modal.Header>
          <Modal.Title>Cupom</Modal.Title>
          <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={e => { fecharModal() }}>
            <i aria-hidden="true" className="ki ki-close" />
          </button>
        </Modal.Header>
        <div className="row mt-5 ml-5 mr-5">
          <div className="form-group col-md-4">
            <label>Hash <span className="text-danger">*</span></label>
            <input type="text" value={formCupom?.hash_cupom} onChange={e => { setFormCupom({ ...formCupom, hash_cupom: e.target.value }) }} className="form-control" placeholder="EX: HAAHUSUHSAY1" />
          </div>
          <div className="form-group col-md-4">
            <label>Data Início <span className="text-danger">*</span></label>
            <div>
              <DatePicker
                name="dt_inicio"
                onChange={(e) => { setFormCupom({ ...formCupom, data_inicio: e }); }}
                isClearable
                showTimeSelect
                locale="pt-BR"
                className="form-control"
                placeholderText="dd/mm/aaaa hh:mm"
                selected={formCupom.data_inicio}
                dateFormat="dd/MM/y HH:mm"
                customInput={<InputMask mask="99/99/9999 99:99" />}
              />
            </div>
          </div>
          <div className="form-group col-md-4">
            <label>Data Final <span className="text-danger">*</span></label>
            <div>
              <DatePicker
                name="dt_final"
                onChange={(e) => { setFormCupom({ ...formCupom, data_expiracao: e }); }}
                isClearable
                showTimeSelect
                locale="pt-BR"
                className="form-control"
                placeholderText="dd/mm/aaaa hh:mm"
                selected={formCupom.data_expiracao}
                dateFormat="dd/MM/y HH:mm"
                selectsEnd
                startDate={formCupom.data_inicio}
                endDate={formCupom.data_expiracao}
                minDate={formCupom.data_inicio}
                customInput={<InputMask mask="99/99/9999 99:99" />}
              />
            </div>
          </div>
        </div>

        <div className="row ml-5 mr-5">
          <div className="form-group col-md-3">
            <label>Pagador <span className="text-danger">*</span></label>
            <select className="form-control" value={formCupom?.pagador} onChange={e => { setFormCupom({ ...formCupom, pagador: e.target.value }) }}>
              <option value="" selected>Selecione</option>
              <option value="A" >Aiboo</option>
              <option value="E" >Estabelecimento</option>
              <option value="M" >Ambos</option>
            </select>
          </div>
          <div className="form-group col-md-3">
            <label>Distribuição Estabelecimento</label>
            <input type="text" value={formCupom?.payer_distribution_establishment} onChange={e => { setFormCupom({ ...formCupom, payer_distribution_establishment: e.target.value }) }} className="form-control" placeholder="Caso pagador seja AMBOS" />
          </div>
          <div className="form-group col-md-3">
            <label>Distribuição Aiboo</label>
            <input type="text" value={formCupom?.payer_distribution_aiboo} onChange={e => { setFormCupom({ ...formCupom, payer_distribution_aiboo: e.target.value }) }} className="form-control" placeholder="Caso pagador seja AMBOS" />
          </div>
          <div className="form-group col-md-3">
            <label>Tipo do Desconto <span className="text-danger">*</span></label>
            <select className="form-control" value={formCupom?.tipo} onChange={e => { setFormCupom({ ...formCupom, tipo: e.target.value }) }}>
              <option value="" selected>Selecione</option>
              <option value="F" >Frete</option>
              <option value="T" >Total</option>
              <option value="P" >Produtos</option>
            </select>
          </div>
        </div>

        <div className="row ml-5 mr-5">
          <div className="form-group col-md-3">
            <label>Aparecer na carteira de todos? <span className="text-danger">*</span></label>
            <select className="form-control" value={formCupom?.origem_cupom} onChange={e => { setFormCupom({ ...formCupom, origem_cupom: e.target.value }) }}>
              <option value="" selected>Selecione</option>
              <option value="-100" >Sim</option>
              <option value="0" >Não</option>
            </select>
          </div>
          <div className="form-group col-md-9">
            <label>Observação </label>
            <input type="text" value={formCupom?.observacao} onChange={e => { setFormCupom({ ...formCupom, observacao: e.target.value }) }} className="form-control" placeholder="Ex: Loucura Total (essa mensagem aparecerá na carteira do usuário)" />
          </div>
        </div>

        <div className="row ml-5 mr-5">
          <div className="form-group col-md-2">
            <label>DESCONTO EM % <span className="text-danger">*</span></label>
            <input type="text" value={formCupom?.porcentagem} onChange={e => { setFormCupom({ ...formCupom, porcentagem: e.target.value }) }} className="form-control" placeholder="Ex: SENDO 1.00 = 100% E 0.10 = 10%!!! PREENCHER COM 0 CASO SEJA DESCONTO EM VALOR" />
          </div>
          <div className="form-group col-md-2">
            <label>DESCONTO EM R$ <span className="text-danger">*</span></label>
            <input type="text" value={formCupom?.valor} onChange={e => { setFormCupom({ ...formCupom, valor: e.target.value }) }} className="form-control" placeholder="Ex: 10,00 PREENCHER COM 0 CASO SEJA DESCONTO EM %" />
          </div>
          <div className="form-group col-md-2">
            <label>Uso máximo <span className="text-danger">*</span></label>
            <input type="text" value={formCupom?.uso_maximo} onChange={e => { setFormCupom({ ...formCupom, uso_maximo: e.target.value }) }} className="form-control" placeholder="Ex: 2" />
          </div>
          <div className="form-group col-md-2">
            <label>Valor mínimo <span className="text-danger">*</span></label>
            <input type="text" value={formCupom?.valor_minimo} onChange={e => { setFormCupom({ ...formCupom, valor_minimo: e.target.value }) }} className="form-control" placeholder="Ex: 15,00 OU 0" />
          </div>
          <div className="form-group col-md-2">
            <label>Valor máximo <span className="text-danger">*</span></label>
            <input type="text" value={formCupom?.valor_maximo} onChange={e => { setFormCupom({ ...formCupom, valor_maximo: e.target.value }) }} className="form-control" placeholder="Ex: 150,00 OU 0" />
          </div>
          <div className="form-group col-md-2">
            <label>Custo Máximo <span className="text-danger">*</span></label>
            <input type="text" value={formCupom?.custo_maximo} onChange={e => { setFormCupom({ ...formCupom, custo_maximo: e.target.value }) }} className="form-control" placeholder="Ex: 15,00 OU 0" />
          </div>
        </div>

        <div className="row ml-5 mr-5">
          <div className="form-group col-md-8">
            <label>Cidade <span className="text-danger">*</span></label>
            <select className="form-control" value={formCupom?.id_cidade} onChange={e => { setFormCupom({ ...formCupom, id_cidade: e.target.value }) }}>
              <option value="0">Todas</option>
              {cidadeFiltra.map((item) => {
                return (
                  <option key={item.id_cidade} value={item.id_cidade}>{item.nome_cidade}</option>
                )
              })}
            </select>
          </div>
          <div className="form-group col-md-4">
            <label>Tipo do Uso <span className="text-danger">*</span></label>
            <select className="form-control" value={formCupom?.tipo_cupom} onChange={e => { setFormCupom({ ...formCupom, tipo_cupom: e.target.value }) }}>
              <option value="" selected>Ilimitado</option>
              <option value="UU" >Unico</option>
              <option value="PC" >Primeira Compra</option>
            </select>
          </div>
        </div>

        <div className="row ml-5 mr-5">
          <div className="form-group col-md-12">
            <label>Tipo de Pagamento</label>
            <Select
              options={paymentTypes}
              isMulti
              placeholder="Selecione..."
              defaultValue={formCupom.payment_type?.map((item) => {
                return (
                  paymentTypes.find(
                    x => x.value === item
                  )
                )
              }
              )}
              onChange={(e) => {
                setFormCupom({
                  ...formCupom,
                  payment_type: e
                });
              }}
            />

          </div>
        </div>

        <div className="row ml-5 mr-5">
          <div className="form-group col-md-12">
            <label>Estabelecimentos <span className="text-danger">*</span></label>
            <Select
              options={estabelecimentosLista}
              isMulti
              placeholder="Selecione..."
              defaultValue={formCupom.id_estabelecimento?.map((item) => {
                return (
                  estabelecimentosLista.find(
                    x => x.value === item
                  )
                )
              }
              )}
              onChange={(e) => {
                setFormCupom({
                  ...formCupom,
                  id_estabelecimento: e
                });
              }}
            />

          </div>
        </div>

        <Modal.Footer>
          <button type="button" onClick={e => { fecharModal(); }} className="btn btn-light-primary font-weight-bold" data-dismiss="modal"><i className="fas fa-door-open" />Fechar</button>
          <button type="button" onClick={e => { salvar(); }} className="btn btn-success font-weight-bold"><i className="fas fa-save" />Salvar</button>
        </Modal.Footer>
      </Modal>

    </>
  );

}



export default withRouter(Cupons);
import React, { memo, useCallback, useEffect, useState } from "react";

import CpfCnpjInput from "react-cpf-cnpj-input";
import DatePicker from "react-datepicker";
import InputMask from "react-input-mask";
import Select from "react-select";
import LoadingOverlay from "react-loading-overlay";
import Swal from "sweetalert2";

import api from "../../../../../services/api";
import { useMerchantManager, useZipCode } from "../../hooks";
import { useToasts } from "react-toast-notifications";

const RegisterComponent = ({
  pagOnCadastro,
  setPagOnCadastro,
  setEnvioImagemPagOn,
}) => {
  const [dataCriacao, setDataCriacao] = useState();
  const [pagOnMCCs, setPagOnMCCs] = useState([]);
  const [dataNascimento, setDataNascimento] = useState();

  const { addToast } = useToasts();

  const { fetchAddressByZipeCode, isLoading: isLoadingZipCode } = useZipCode();

  const { getValues } = useMerchantManager();

  const [
    cnpjCpf,
    companyName,
    email,
    name,
    zipCode,
    street,
    addressNumber,
    neighborhood,
    city,
  ] = getValues([
    "cnpj_cpf",
    "razao_social",
    "email",
    "nome",
    "endereco_cep",
    "endereco_rua",
    "endereco_numero",
    "endereco_bairro",
    "id_cidade",
  ]);

  const handleMerchantZipCode = async () => {
    const response = await fetchAddressByZipeCode(pagOnCadastro?.est_cep);

    if (!response) {
      return;
    }

    setPagOnCadastro((prevState) => ({
      ...prevState,
      est_endereco: response.logradouro,
      est_cidade: response.localidade,
      est_estado: response.uf,
      est_bairro: response.bairro,
      est_complemento: response.complemento,
    }));
  };

  const handleOwnerZipCode = async () => {
    const response = await fetchAddressByZipeCode(pagOnCadastro?.pro_cep);

    if (!response) {
      return;
    }

    setPagOnCadastro((prevState) => ({
      ...prevState,
      pro_endereco: response.logradouro,
      pro_cidade: response.localidade,
      pro_estado: response.uf,
      pro_bairro: response.bairro,
      pro_complemento: response.complemento,
    }));
  };

  function importarDados() {
    if (cnpjCpf.length > 13) {
      setPagOnCadastro((prevState) => ({
        ...prevState,
        est_cnpj: cnpjCpf,
        est_razao_social: companyName,
        est_email: email,
        est_nome_fantasia: name,
        est_cep: zipCode,
        est_endereco: street,
        est_numero: addressNumber,
        est_bairro: neighborhood,
        est_cidade: city.value,
      }));
    }
  }

  function salvarCadPagOn() {
    const pagCadastro = JSON.parse(JSON.stringify(pagOnCadastro));

    pagCadastro.pro_nascimento = dataNascimento;
    pagCadastro.dataCriacao = dataCriacao;

    api.post("pagamentoonline/adicionar", pagCadastro).then((rps) => {
      if (rps.data.status === true) {
        addToast(rps.data.mensagem, {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 2000,
        });
        setPagOnCadastro({
          ...pagOnCadastro,
          id_pagon_cadastro: rps.data.id_pagon_cadastro,
        });
        setEnvioImagemPagOn(true);
      } else {
        Swal.fire({
          title: "Erro!",
          icon: "error",
          html: rps.data.erros,
          showCloseButton: true,
          showCancelButton: false,
        });
      }
    });
  }

  const getListarMCCs = useCallback(() => {
    api.get("pagamentoonline/listarMccs").then((rps) => {
      var mccs = rps.data.obj;
      var nMCCs = [];
      mccs.forEach((e) => {
        nMCCs[nMCCs.length] = {
          value: e.code,
          label: e.category + " => " + e.description,
        };
      });
      setPagOnMCCs(nMCCs);
    });
  }, []);

  useEffect(() => {
    getListarMCCs();
  }, [getListarMCCs]);

  return (
    <div
      className="tab-pane fade"
      id="pag_cadastro"
      role="tabpanel"
      aria-labelledby="pag_cadastro"
    >
      <div className="row">
        <div className="col-md-12">
          <h4 className="bg-secondary pt-2 pl-2 pb-2">Tipo do Cadastro</h4>
        </div>
      </div>

      <div className="row">
        <div className="form-group col-md-6">
          <label>
            Tipo de Pessoa <span className="text-danger">*</span>
          </label>
          <select
            className="form-control"
            value={pagOnCadastro?.tipo ?? ""}
            onChange={(e) => {
              setPagOnCadastro((prevState) => ({
                ...prevState,
                tipo: e.target.value,
              }));
            }}
          >
            <option value=""></option>
            <option value="F">Física</option>
            <option value="J">Jurídica</option>
          </select>
        </div>
        <div className="col-md-6 text-right">
          <button
            className="btn btn-sm btn-success"
            onClick={(e) => importarDados()}
          >
            Importar Dados
          </button>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <h4 className="bg-secondary pt-2 pl-2 pb-2">Estabelecimento</h4>
        </div>
      </div>

      <div className="row">
        <div className="form-group col-md-6">
          <h6>
            <b>Dados Cadastrais</b>
          </h6>
          <label>
            CNPJ{" "}
            {pagOnCadastro?.tipo == "J" && (
              <span className="text-danger">*</span>
            )}
          </label>
          <CpfCnpjInput
            type="cnpj"
            maskfield="_"
            className="form-control"
            value={pagOnCadastro?.est_cnpj ?? ""}
            onChangeText={(e) => {
              setPagOnCadastro((prevState) => ({ ...prevState, est_cnpj: e }));
            }}
            disabled={pagOnCadastro?.tipo === "F" ? true : false}
          />

          <label>
            Razão Social/Nome Completo <span className="text-danger">*</span>
          </label>
          <input
            className="form-control"
            value={pagOnCadastro?.est_razao_social ?? ""}
            onChange={(e) => {
              setPagOnCadastro((prevState) => ({
                ...prevState,
                est_razao_social: e.target.value,
              }));
            }}
          />

          <label>Data de criação</label>
          <br />
          <DatePicker
            name="dt_inicio"
            onChange={setDataCriacao}
            isClearable
            selectsStart
            locale="pt-BR"
            className="form-control"
            placeholderText="dd/mm/aaaa"
            selected={dataCriacao}
            dateFormat="dd/MM/y"
            customInput={<InputMask mask="99/99/9999" />}
          />
          <br />

          <label>
            Categoria do Negócio <span className="text-danger">*</span>
          </label>
          <Select
            options={pagOnMCCs}
            placeholder="Selecione..."
            defaultValue={pagOnMCCs.find(
              (x) => x.value === pagOnCadastro?.est_cat_negocio
            )}
            onChange={(e) => {
              setPagOnCadastro((prevState) => ({
                ...prevState,
                est_cat_negocio: e.value,
              }));
            }}
          />

          <label>
            E-mail <span className="text-danger">*</span>
          </label>
          <input
            className="form-control"
            value={pagOnCadastro?.est_email ?? ""}
            onChange={(e) => {
              setPagOnCadastro((prevState) => ({
                ...prevState,
                est_email: e.target.value,
              }));
            }}
          />

          <label>
            Nome Fantasia (usado na identificação na fatura do cartão)
          </label>
          <input
            className="form-control"
            value={pagOnCadastro?.est_nome_fantasia ?? ""}
            onChange={(e) => {
              setPagOnCadastro((prevState) => ({
                ...prevState,
                est_nome_fantasia: e.target.value,
              }));
            }}
          />

          <label>
            Telefone <span className="text-danger">*</span>
          </label>
          <InputMask
            mask="(99)99999-9999"
            className="form-control"
            value={pagOnCadastro?.est_telefone ?? ""}
            onChange={(e) => {
              setPagOnCadastro((prevState) => ({
                ...prevState,
                est_telefone: e.target.value,
              }));
            }}
          />
        </div>

        <div className="form-group col-md-6">
          <h6>
            <b>Endereço</b>
          </h6>
          <label>
            CEP <span className="text-danger">*</span>
          </label>
          <LoadingOverlay
            active={isLoadingZipCode}
            spinner
            text="Carregando..."
          >
            <div className="input-group">
              <input
                type="text"
                className="form-control"
                placeholder="CEP"
                onChange={(e) => {
                  setPagOnCadastro((prevState) => ({
                    ...prevState,
                    est_cep: e.target.value,
                  }));
                }}
                value={pagOnCadastro?.est_cep ?? ""}
                maxLength="8"
              />
              <div className="input-group-append">
                <button
                  className="btn btn-primary"
                  type="button"
                  onClick={handleMerchantZipCode}
                >
                  Pesquisar
                </button>
              </div>
            </div>
          </LoadingOverlay>
          <label>
            Endereço <span className="text-danger">*</span>
          </label>
          <input
            className="form-control"
            value={pagOnCadastro?.est_endereco ?? ""}
            onChange={(e) => {
              setPagOnCadastro((prevState) => ({
                ...prevState,
                est_endereco: e.target.value,
              }));
            }}
          />

          <label>
            Número <span className="text-danger">*</span>
          </label>
          <input
            className="form-control"
            value={pagOnCadastro?.est_numero ?? ""}
            onChange={(e) => {
              setPagOnCadastro((prevState) => ({
                ...prevState,
                est_numero: e.target.value,
              }));
            }}
          />

          <label>Complemento</label>
          <input
            className="form-control"
            value={pagOnCadastro?.est_complemento ?? ""}
            onChange={(e) => {
              setPagOnCadastro((prevState) => ({
                ...prevState,
                est_complemento: e.target.value,
              }));
            }}
          />

          <label>
            Bairro <span className="text-danger">*</span>
          </label>
          <input
            className="form-control"
            value={pagOnCadastro?.est_bairro ?? ""}
            onChange={(e) => {
              setPagOnCadastro((prevState) => ({
                ...prevState,
                est_bairro: e.target.value,
              }));
            }}
          />

          <label>
            Cidade <span className="text-danger">*</span>
          </label>
          <input
            className="form-control"
            value={pagOnCadastro?.est_cidade ?? ""}
            onChange={(e) => {
              setPagOnCadastro((prevState) => ({
                ...prevState,
                est_cidade: e.target.value,
              }));
            }}
          />

          <label>
            Estado <span className="text-danger">*</span>
          </label>
          <input
            className="form-control"
            value={pagOnCadastro?.est_estado ?? ""}
            onChange={(e) => {
              setPagOnCadastro((prevState) => ({
                ...prevState,
                est_estado: e.target.value,
              }));
            }}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <h4 className="bg-secondary pt-2 pl-2 pb-2">Redes Sociais</h4>
        </div>
      </div>

      <div className="row">
        <div className="form-group col-md-6">
          <label>Site</label>
          <input
            className="form-control"
            value={pagOnCadastro?.est_site ?? ""}
            onChange={(e) => {
              setPagOnCadastro((prevState) => ({
                ...prevState,
                est_site: e.target.value,
              }));
            }}
          />

          <label>Facebook</label>
          <input
            className="form-control"
            value={pagOnCadastro?.est_facebook ?? ""}
            onChange={(e) => {
              setPagOnCadastro((prevState) => ({
                ...prevState,
                est_facebook: e.target.value,
              }));
            }}
          />
        </div>

        <div className="form-group col-md-6">
          <label>Twitter</label>
          <input
            className="form-control"
            value={pagOnCadastro?.est_twitter ?? ""}
            onChange={(e) => {
              setPagOnCadastro((prevState) => ({
                ...prevState,
                est_twitter: e.target.value,
              }));
            }}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <h4 className="bg-secondary pt-2 pl-2 pb-2">Proprietário</h4>
        </div>
      </div>

      <div className="row">
        <div className="form-group col-md-6">
          <h6>
            <b>Dados Cadastrais</b>
          </h6>
          <label>
            CPF <span className="text-danger">*</span>
          </label>
          <CpfCnpjInput
            type="cpf"
            maskfield="_"
            className="form-control"
            value={pagOnCadastro?.pro_cpf ?? ""}
            onChangeText={(e) => {
              setPagOnCadastro((prevState) => ({ ...prevState, pro_cpf: e }));
            }}
          />

          <label>
            E-mail <span className="text-danger">*</span>
          </label>
          <input
            className="form-control"
            value={pagOnCadastro?.pro_email ?? ""}
            onChange={(e) => {
              setPagOnCadastro((prevState) => ({
                ...prevState,
                pro_email: e.target.value,
              }));
            }}
          />

          <label>
            Nome <span className="text-danger">*</span>
          </label>
          <input
            className="form-control"
            value={pagOnCadastro?.pro_nome ?? ""}
            onChange={(e) => {
              setPagOnCadastro((prevState) => ({
                ...prevState,
                pro_nome: e.target.value,
              }));
            }}
          />

          <label>
            Data de nascimento <span className="text-danger">*</span>
          </label>
          <br />
          <DatePicker
            name="dt_inicio"
            onChange={setDataNascimento}
            isClearable
            selectsStart
            locale="pt-BR"
            className="form-control"
            placeholderText="dd/mm/aaaa"
            selected={dataNascimento}
            dateFormat="dd/MM/y"
            customInput={<InputMask mask="99/99/9999" />}
          />
          <br />

          <label>
            Telefone <span className="text-danger">*</span>
          </label>
          <InputMask
            mask="(99)99999-9999"
            className="form-control"
            value={pagOnCadastro?.pro_telefone ?? ""}
            onChange={(e) => {
              setPagOnCadastro((prevState) => ({
                ...prevState,
                pro_telefone: e.target.value,
              }));
            }}
          />
        </div>

        <div className="form-group col-md-6">
          <h6>
            <b>Endereço</b>
          </h6>
          <label>
            CEP <span className="text-danger">*</span>
          </label>
          <LoadingOverlay
            active={isLoadingZipCode}
            spinner
            text="Carregando..."
          >
            <div className="input-group">
              <input
                type="text"
                className="form-control"
                placeholder="CEP"
                onChange={(e) => {
                  setPagOnCadastro((prevState) => ({
                    ...prevState,
                    pro_cep: e.target.value,
                  }));
                }}
                value={pagOnCadastro?.pro_cep ?? ""}
                maxLength="8"
              />
              <div className="input-group-append">
                <button
                  className="btn btn-primary"
                  type="button"
                  onClick={handleOwnerZipCode}
                >
                  Pesquisar
                </button>
              </div>
            </div>
          </LoadingOverlay>

          <label>
            Endereço <span className="text-danger">*</span>
          </label>
          <input
            className="form-control"
            value={pagOnCadastro?.pro_endereco ?? ""}
            onChange={(e) => {
              setPagOnCadastro((prevState) => ({
                ...prevState,
                pro_endereco: e.target.value,
              }));
            }}
          />

          <label>
            Número <span className="text-danger">*</span>
          </label>
          <input
            className="form-control"
            value={pagOnCadastro?.pro_numero ?? ""}
            onChange={(e) => {
              setPagOnCadastro((prevState) => ({
                ...prevState,
                pro_numero: e.target.value,
              }));
            }}
          />

          <label>Complemento</label>
          <input
            className="form-control"
            value={pagOnCadastro?.pro_complemento ?? ""}
            onChange={(e) => {
              setPagOnCadastro((prevState) => ({
                ...prevState,
                pro_complemento: e.target.value,
              }));
            }}
          />

          <label>
            Bairro <span className="text-danger">*</span>
          </label>
          <input
            className="form-control"
            value={pagOnCadastro?.pro_bairro ?? ""}
            onChange={(e) => {
              setPagOnCadastro((prevState) => ({
                ...prevState,
                pro_bairro: e.target.value,
              }));
            }}
          />

          <label>
            Cidade <span className="text-danger">*</span>
          </label>
          <input
            className="form-control"
            value={pagOnCadastro?.pro_cidade ?? ""}
            onChange={(e) => {
              setPagOnCadastro((prevState) => ({
                ...prevState,
                pro_cidade: e.target.value,
              }));
            }}
          />

          <label>
            Estado <span className="text-danger">*</span>
          </label>
          <input
            className="form-control"
            value={pagOnCadastro?.pro_estado ?? ""}
            onChange={(e) => {
              setPagOnCadastro((prevState) => ({
                ...prevState,
                pro_estado: e.target.value,
              }));
            }}
          />
        </div>
      </div>
      <button
        type="button"
        onClick={(e) => {
          salvarCadPagOn();
        }}
        className="btn btn-success font-weight-bold"
      >
        Salvar Cadastro Pagamento Online
      </button>
    </div>
  );
};

export const Register = memo(RegisterComponent);

import React, { useState, useCallback } from "react";
import apiLaravelv2 from "../../services/api-laravelv2";

import { useToasts } from "react-toast-notifications";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export const useRegisterProductEan = () => {
  const [imagePreview, setImagePreview] = useState(null);
  const [pageStatus, setPageStatus] = useState("idle");
  const [modalVisibility, setModalVisibility] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState({
    value: null,
    label: "",
  });
  const [categories, setCategories] = useState([]);
  const [isOnEditMode, setIsOnEditMode] = useState(false);
  const [productToEditData, setProductToEditData] = useState({});
  const [attemptSubmitWithoutCategory, setAttemptSubmitWithoutCategory] =
    useState(false);

  const history = useHistory();

  const { addToast } = useToasts();

  const checkFormMode = useCallback((productId) => {
    if (productId) {
      setIsOnEditMode(true);
      return;
    }
  }, []);

  const handleToggleCreateProductCategoryModal = useCallback(() => {
    setModalVisibility((prevState) => !prevState);
  }, []);

  const formatCategoriesOptions = useCallback((categories) => {
    return categories.map((item) => {
      return { value: item.id_produto_categoria, label: item.nome };
    });
  }, []);

  const getServerErrorMessage = useCallback((errors) => {
    if (Object.keys(errors).length > 0) {
      const firstKey = Object.keys(errors)[0];
      const errorToRender = errors[firstKey];

      return errorToRender;
    }
    return;
  }, []);

  const fetchCategories = useCallback(async () => {
    setPageStatus("loading");
    try {
      const response = await apiLaravelv2.get("products/categories");
      setCategories(response.data.categories);
      setPageStatus("success");
    } catch {
      setPageStatus("error");
      addToast(
        "Ops! Ocorreu um erro inesperado ao carregar as categorias, tente novamente mais tarde ",
        {
          appearance: "error",
          autoDismiss: true,
          autoDismissTimeout: 5000,
        }
      );
      history.goBack();
    }

    return;
  }, [addToast, history]);

  const fetchProductFormDataToEdit = useCallback(
    async (productId) => {
      setPageStatus("loading");
      try {
        const response = await apiLaravelv2.get(`products/${productId}`);
        setProductToEditData(response.data.product);
        setPageStatus("success");
      } catch {
        setPageStatus("error");
        addToast(
          "Ops! Ocorreu um erro inesperado ao carregar as informações do produto escolhido, tente novamente mais tarde",
          {
            appearance: "error",
            autoDismiss: true,
            autoDismissTimeout: 5000,
          }
        );
      }

      return;
    },
    [addToast]
  );

  const successMessage = () => {
    const message = isOnEditMode ? "Produto Editado" : "Produto Cadastrado";

    return message;
  };

  const saveProductEndPoint = (productId) => {
    const endPoint = isOnEditMode
      ? `products/${productId}?_method=PUT`
      : "products";

    return endPoint;
  };

  const onSubmit = async (data, productId) => {
    const hasNotSelectedCategory = !selectedCategory.value;
    if (hasNotSelectedCategory) {
      setAttemptSubmitWithoutCategory(true);
      return;
    }

    try {
      setPageStatus("loading");

      const productFormData = new FormData();
      productFormData.append("codigo_barras", data.eanCode);
      productFormData.append("nome_produto", data.productName);
      productFormData.append("descricao", data.description);
      productFormData.append("marca", data.brand);
      productFormData.append("id_categoria", selectedCategory.value);

      if (data.image.length > 0 && typeof data.image !== "string") {
        productFormData.append("imagem_original", data.image[0]);
      }

      const { response } = await apiLaravelv2.post(
        saveProductEndPoint(productId),
        productFormData
      );

      if (
        response?.data?.errors &&
        Object.keys(response.data?.errors).length > 0
      ) {
        throw response.data?.errors;
      }
      addToast(successMessage(), {
        appearance: "success",
        autoDismiss: true,
        autoDismissTimeout: 5000,
      });

      history.goBack();

      setPageStatus("success");
    } catch (error) {
      setPageStatus("error");

      if (error) {
        const errorMessage = getServerErrorMessage(error);
        addToast(errorMessage, {
          appearance: "error",
          autoDismiss: true,
          autoDismissTimeout: 5000,
        });
        return;
      }
      addToast(
        "Ops! Ocorreu um erro inesperado ao salvar este produto, tente novamente mais tarde ",
        {
          appearance: "error",
          autoDismiss: true,
          autoDismissTimeout: 5000,
        }
      );
      return;
    }
  };

  return {
    imagePreview,
    setImagePreview,
    formatCategoriesOptions,
    pageStatus,
    setPageStatus,
    modalVisibility,
    handleToggleCreateProductCategoryModal,
    selectedCategory,
    setSelectedCategory,
    categories,
    setCategories,
    fetchCategories,
    onSubmit,
    checkFormMode,
    isOnEditMode,
    fetchProductFormDataToEdit,
    productToEditData,
    attemptSubmitWithoutCategory,
  };
};

import { useQuery } from "@tanstack/react-query";

import { merchantService } from "services";

import { merchantQueryKeys } from "./merchantQueryKeys";

export const useGetMerchantById = (merchantId) => {
  const { data, isLoading, isError, refetch } = useQuery({
    queryKey: [merchantQueryKeys.getMerchantById, merchantId],
    queryFn: () => merchantService.getMerchantById(merchantId),
    retry: false,
    enabled: !!merchantId,
  });

  return {
    merchantData: data,
    isLoading,
    isError,
    refetch,
  };
};

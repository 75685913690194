import React, { memo, useState } from "react";

import InputMask from "react-input-mask";
import { useToasts } from "react-toast-notifications";
import LoadingOverlay from "react-loading-overlay";
import Swal from "sweetalert2";
import DataTable from "react-data-table-component";

import api from "../../../../services/api";

const ContactsTabComponent = ({
  merchantId,
  setCaProcessa,
  caProcessa,
  listaContatos,
  listarContatos,
}) => {
  const [formContatos, setFormContatos] = useState({});

  const { addToast } = useToasts();

  const columnsContatos = [
    {
      name: "Exibe App",
      selector: "exibir",
      sortable: true,
      width: "8%",
      center: true,
      cell: (row) => {
        if (row.exibir === "1") {
          return (
            <div className="label label-success label-inline">
              <b>Sim</b>
            </div>
          );
        } else {
          return (
            <div className="label label-danger label-inline">
              <b>Não</b>
            </div>
          );
        }
      },
    },
    {
      name: "Tipo",
      selector: "tipo",
      sortable: true,
      width: "15%",
      cell: (row) => {
        if (row.tipo === "CP") {
          return <div>Celular pedidos</div>;
        } else if (row.tipo === "FP") {
          return <div>Fixo pedidos</div>;
        } else if (row.tipo === "W") {
          return <div>Whatsapp</div>;
        } else if (row.tipo === "C") {
          return <div>Celular do Proprietário</div>;
        } else if (row.tipo === "F") {
          return <div>Fixo do Proprietário</div>;
        } else if (row.tipo === "CF") {
          return <div>Celular/Email financeiro</div>;
        } else if (row.tipo === "FF") {
          return <div>Fixo/Email financeiro</div>;
        }
      },
    },
    {
      name: "Telefone",
      selector: "telefone",
      sortable: true,
      width: "12%",
      center: true,
    },
    {
      name: "Nome do Contato",
      selector: "nome_contato",
      sortable: true,
      width: "25%",
    },
    {
      name: "E-mail do Contato",
      selector: "email",
      sortable: true,
      width: "30%",
    },
    {
      name: "Ações",
      cell: (row) => {
        return (
          <>
            <button
              type="button"
              className="btn btn-light-primary btn-icon btn-sm"
              onClick={(e) => {
                excluirContato(row);
              }}
            >
              <i className="far fa-trash-alt"></i>
            </button>
          </>
        );
      },
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      right: true,
    },
  ];

  function excluirContato(c) {
    var contato = JSON.parse(JSON.stringify(c));
    setCaProcessa(true);

    api.post("estabelecimentoscontatos/excluir", contato).then((rps) => {
      if (rps.data.status === true) {
        Swal.fire({
          title: "Confirmação!",
          icon: "info",
          html: rps.data.mensagem,
          showCloseButton: true,
          showCancelButton: false,
        });
        listarContatos(merchantId);
        setCaProcessa(false);
      }
    });
  }

  function salvarContatos() {
    setCaProcessa(true);

    api.post("Estabelecimentoscontatos/adicionar", formContatos).then((rps) => {
      if (rps.data.status === true) {
        addToast(rps.data.mensagem, {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 2000,
        });

        console.log({ formContatos });

        listarContatos(merchantId);

        setFormContatos({
          ...formContatos,
          id_estabelecimento: "",
          exibir: "",
          tipo: "",
          telefone: "",
          nome_contato: "",
          email: "",
        });
        setCaProcessa(false);
      } else {
        Swal.fire({
          title: "Erro!",
          icon: "error",
          html: rps.data.erros,
          showCloseButton: true,
          showCancelButton: false,
        });
        setCaProcessa(false);
      }
    });
  }

  return (
    <div
      className="tab-pane fade"
      id="contatos"
      role="tabpanel"
      aria-labelledby="contatos"
    >
      <div className="row">
        <div className="col">
          <label>
            Exibe App <b className="text-danger">*</b>
          </label>
          <select
            className="form-control"
            value={formContatos.exibir}
            onChange={(e) => {
              setFormContatos((prevState) => ({
                ...prevState,
                exibir: e.target.value,
                id_estabelecimento: merchantId,
              }));
            }}
          >
            <option value="" defaultValue="">
              Selecione
            </option>
            <option value="1">Sim</option>
            <option value="0">Não</option>
          </select>
        </div>
        <div className="col-md-2">
          <label>
            Tipo <b className="text-danger">*</b>
          </label>
          <select
            className="form-control"
            value={formContatos.tipo}
            onChange={(e) => {
              setFormContatos((prevState) => ({
                ...prevState,
                tipo: e.target.value,
              }));
            }}
          >
            <option value="" defaultValue="">
              Selecione
            </option>
            <option value="CP">Contato Pedidos</option>
            <option value="C">Contato do Proprietário</option>
          </select>
        </div>
        <div className="col">
          <label>
            Telefone <b className="text-danger">*</b>
          </label>
          <InputMask
            mask="(99)99999-9999"
            className="form-control"
            value={formContatos?.telefone}
            onChange={(e) => {
              setFormContatos((prevState) => ({
                ...prevState,
                telefone: e.target.value,
              }));
            }}
          />
        </div>
        <div className="col">
          <label>
            Nome <b className="text-danger">*</b>
          </label>
          <input
            className="form-control"
            value={formContatos?.nome_contato}
            onChange={(e) => {
              setFormContatos((prevState) => ({
                ...prevState,
                nome_contato: e.target.value,
              }));
            }}
          />
        </div>
        <div className="col">
          <label>
            E-mail do contato <b className="text-danger">*</b>
          </label>
          <input
            className="form-control"
            value={formContatos?.email}
            onChange={(e) => {
              setFormContatos({
                ...formContatos,
                email: e.target.value,
              });
            }}
          />
        </div>

        <div className="col">
          <label>&nbsp;</label>
          <br />
          <button
            className="btn btn-block btn-success"
            onClick={(e) => {
              salvarContatos();
            }}
          >
            <i className="fas fa-plus" />
            Adicionar
          </button>
        </div>
      </div>
      <LoadingOverlay active={caProcessa} spinner text="Carregando...">
        <DataTable
          title="Contatos Cadastrados"
          columns={columnsContatos}
          data={listaContatos}
          striped
          pagination
        />
      </LoadingOverlay>
    </div>
  );
};

export const ContactsTab = memo(ContactsTabComponent);

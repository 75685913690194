import styled, { css } from 'styled-components';

export const Container = styled.td`
    display: flex;
    width: 100%;
    height: 60px;
    justify-content: center;
    align-items: center;
    gap: 10px;

    span {
        margin: 0 10px;
    }
`;

export const Button = styled.button`
    display: flex;
    padding-inline: 5px;
    height: 30px;
    min-width: 24px;
    border: none;
    background-color: transparent;
    justify-content: center;
    align-items: center;

    ${props => props.$selected && css`
        border-radius: 4px;
        border: 1.5px solid #F64E60;
        color: #F64E60;
    `}
`;

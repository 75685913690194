import React, { useState, useEffect, useCallback } from 'react';
import Api from '../../../services/api';
import { withRouter } from "react-router";

import Swal from "sweetalert2";
import { useToasts } from 'react-toast-notifications';
import LoadingOverlay from 'react-loading-overlay';

import DataTable from 'react-data-table-component';
import { Modal } from "react-bootstrap";
import Select from 'react-select';

const Bancos = () => {
  const { addToast } = useToasts();
  const [ planos, setPlanos ] = useState([]);
  const [ taxas, setTaxas ] = useState([]);
  const [ listaBandeiras, setListaBandeiras ] = useState([]);
  const [ listaEstabelecimentos, setListaEstabelecimentos ] = useState([]);
  const [ caProcessa, setCaProcessa ] = useState(false);
  const [ buscaExibir, setBuscaExibir ] = useState();
  const [ buscaNome, setBuscaNome ] = useState();
  const [ buscaStatus, setBuscaStatus ] = useState();
  const [ modal, setModal ] = useState(false);
  const [ formPlano, setFormPlano ] = useState({});
  const [ formTaxa, setFormTaxa ] = useState({});
  const [ trava, setTrava ] = useState(false);
  let acesso = JSON.parse(localStorage.getItem('@ADM-Aiboo/perfil'));

  function fecharModal(){
    setModal(false);
  }

  const data = planos;
  const columns = [
    {
      name: <th>Código</th>,
      selector: 'id_pagon_plano',
      sortable: true,
      width: '10%',
      center: true,
    },
    {
      name: <th>Nome</th>,
      selector: 'plano',
      width: '60%',
      sortable: true,
    },
    {
      name: <th>Exibir para</th>,
      width: '10%',
      sortable: true,
      selector: 'status',
      center: 'true',
      cell: row => { if (row.exibir === "E") {return <span className="label label-primary label-inline mr-2">Especifícos</span>}
        else {return <span className="label label-info label-inline mr-2">Todos</span>}
      }
    },
    {
      name: <th>Status</th>,
      width: '10%',
      sortable: true,
      selector: 'status',
      center: 'true',
      cell: row => { if (row.status === "S") {return <span className="label label-success label-inline mr-2">Ativo</span>}
        else {return <span className="label label-secondary label-inline mr-2">Desativado</span>}
      }
    },
    {
      name: <th>Ações</th>,
      cell: (row) => 
      { return (<>
      <div className="dropdown dropdown-inline">
      <button type="button" className="btn btn-light-primary btn-icon btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <i className="ki ki-bold-more-ver"></i>
      </button>
      <div className="dropdown-menu">
        <span className="dropdown-item" onClick={e => { editarPlano(row); listaTaxas(row) }}>Editar</span>
        {acesso.pag_tax_ei === "1" && <>
        <div className="dropdown-divider"></div>
        <span className="dropdown-item" onClick={e => { excluirPlano(row) }}>Excluir</span>
        </>}
      </div>
    </div>
    </>)
      },
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      right: true,
    },
  ];

  const dataTaxas = taxas;
  const columnsTaxas = [
    {
      name: <th>Tipo</th>,
      selector: 'tipo',
      sortable: true,
      width: '18%',
      center: true,
      cell: row => { if (row.tipo === "Débito") {return <span className="label label-danger label-inline mr-2">{row.tipo}</span>}
        else {return <span className="label label-success label-inline mr-2">{row.tipo}</span>}
      }
    },
    {
      name: <th>Taxas</th>,
      selector: 'taxas',
      width: '18%',
      sortable: true,
      right: true,
    },
    {
      name: <th>Bandeira</th>,
      width: '18%',
      sortable: true,
      selector: 'bandeira',
      center: 'true',
    },
    {
      name: <th>Parcelas</th>,
      width: '18%',
      sortable: true,
      selector: 'parcelas',
      center: 'true',
    },
    {
      name: <th>Recebimento em dia(s)</th>,
      width: '18%',
      sortable: true,
      selector: 'dias_recebimento',
      center: 'true',
    },
    {
      name: <th>Ações</th>,
      cell: (row) => 
      { return (<>
      <div className="dropdown dropdown-inline">
      <button type="button" className="btn btn-light-primary btn-icon btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <i className="ki ki-bold-more-ver"></i>
      </button>
      <div className="dropdown-menu">
        <span className="dropdown-item" onClick={e => { excluirTaxa(row) }}>Excluir</span>
      </div>
    </div>
    </>)
      },
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      right: true,
    },
  ];



  const getListar = useCallback(() => { 
    setCaProcessa(true); 
    Api.get("planostaxas/listar").then(rps => {
      setPlanos(rps.data.planos);
      setCaProcessa(false);
      getEstabelecimentoSelect();
    })
  }, []);

  useEffect(() => {
    getBandeiraSelect();
    getListar();
  }, [getListar]);

  function getEstabelecimentoSelect() {
    Api.get("estabelecimentos/listaNomeEstabelecimento").then(rps => {
      var est = rps.data.obj;
      var nEst = [];
      est.forEach(e => {
        nEst[nEst.length] = {value: e.id_estabelecimento, label: e.nome};
      })
      setListaEstabelecimentos(nEst);
    })
  }

  function getBandeiraSelect() {
    Api.get("planostaxas/listaBandeira").then(rps => {
     
      var band = rps.data.band;
      var nBand = [];
      nBand[nBand.length] = {value: '', label: 'Selecione...'};
      band.forEach(e => {
        nBand[nBand.length] = {value: e.id_pagon_bandeira, label: e.nome};
      })
      setListaBandeiras(nBand);
    })
  }

  function adicionarPlano(){
    getEstabelecimentoSelect();
    setFormPlano({});
    setTaxas({});
    setModal(true);
    setTrava(false);
  }

  function editarPlano(id_plano){  
    var ca = JSON.parse(JSON.stringify(id_plano));

    setFormPlano(ca);
    setModal(true);
    setTrava(true);            
  }

  function excluirPlano(id_banco) {
    var ca = JSON.parse(JSON.stringify(id_banco));

    setCaProcessa(true);

    Api.post('planostaxas/excluirPlano', ca).then(rps => {
      if (rps.data.status === true) {
        Swal.fire({
          title: "Confirmação!",
          icon: "info",
          html: rps.data.mensagem,
          showCloseButton: true,
          showCancelButton: false,
        });
        getListar();
        setCaProcessa(false);
      }
    });   
  }

  function salvar(){
    var nIdPlano = [];

    if (formPlano.id_planos != null){
      formPlano.id_planos.forEach(e => {
        nIdPlano[nIdPlano.length] = e.value;
    });
  }

    var nPlanoEnvia = {};
    nPlanoEnvia = JSON.parse(JSON.stringify(formPlano));
    nPlanoEnvia.id_planos = nIdPlano;

    setCaProcessa(true);
    Api.post('planostaxas/adicionarPlano', nPlanoEnvia).then(rps => {
      if (rps.data.status === true) {
        addToast(rps.data.mensagem, {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 2000
        });
        setFormPlano({id_pagon_plano: rps.data.plano});
        getListar();
        setTrava(true);
      } else {
        Swal.fire({
          title: "Erro!",
          icon: "error",
          html: rps.data.erros,
          showCloseButton: true,
          showCancelButton: false,
        });
      }
      setCaProcessa(false);
    });
  }

  function incluirTaxa(){
    setCaProcessa(true);
    Api.post('planostaxas/adicionarTaxa', formTaxa).then(rps => {
      if (rps.data.status === true) {
        addToast(rps.data.mensagem, {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 2000
        });
        setFormTaxa({id_pagon_plano: formPlano.id_pagon_plano,
                     tipo: "", taxas: "", bandeira: "", parcelas: "", dias_recebimento: ""});

        listaTaxas(formTaxa);
      } else {
        Swal.fire({
          title: "Erro!",
          icon: "error",
          html: rps.data.erros,
          showCloseButton: true,
          showCancelButton: false,
        });
      }
      setCaProcessa(false);
    });
  }

  function filtrar() {
    setCaProcessa(true); 
      var data = {
        nome: buscaNome,
        exibir: buscaExibir,
        status: buscaStatus,
      };
    
      Api.post('planostaxas/filtrar', data).then((rps) => {

        if(rps.data.status===false){
          Swal.fire({
            title: "Atenção!",
            icon: "info",
            html: rps.data.erro,
            showCloseButton: true,
            showCancelButton: false,
          });
        } else {
          setPlanos(rps.data.obj);
        }
      setCaProcessa(false); 
    });
  }

  function listaTaxas(id) {
    var ca = JSON.parse(JSON.stringify(id));
    setCaProcessa(true);    
      Api.post('planostaxas/listarTaxa', ca).then((rps) => {

        if(rps.data.status===false){
          Swal.fire({
            title: "Atenção!",
            icon: "info",
            html: rps.data.erro,
            showCloseButton: true,
            showCancelButton: false,
          });
        } else {
          setTaxas(rps.data.obj);
        }
      setCaProcessa(false); 
    });
  }
    
  function excluirTaxa(id_taxa) {
    var ca = JSON.parse(JSON.stringify(id_taxa));

    setCaProcessa(true);

    Api.post('planostaxas/deletarTaxa', ca).then(rps => {
      if (rps.data.status === true) {
        Swal.fire({
          title: "Confirmação!",
          icon: "info",
          html: rps.data.mensagem,
          showCloseButton: true,
          showCancelButton: false,
        });
        listaTaxas(formTaxa);
        setCaProcessa(false);
      }
    });   
  }
    return (
        <>
<div>

<div className="subheader espaco-header-pedidos subheader-transparent" id="kt_subheader">
          <div className="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
            {/*begin::Details*/}
            <div className="d-flex align-items-center flex-wrap mr-2">
              {/*begin::Title*/}
              <h5 className="text-dark font-weight-bold mt-2 mb-2 mr-5">Planos de taxas</h5>
              {/*end::Title*/}
              {/*begin::Separator*/}
              <div className="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200" />
              {/*end::Separator*/}
            </div>
            {/*end::Details*/}
            {/*begin::Toolbar*/}
            <div className="d-flex align-items-center">
              <button className="btn btn-lg btn-success mt-3 mb-3" onClick={e => {adicionarPlano()}}>Adicionar</button>
            </div>
            {/*end::Toolbar*/}
          </div>
        </div>
        {/*begin::Entry*/}
        <div className="d-flex flex-column-fluid">
          {/*begin::Container*/}
          <div className="container">
            <div className="card card-custom">
              <div className="ml-10 mr-10 mt-10">    
                <div className="row">
                  <div className="form-group col-md-6">
                    <label>Nome</label>
                    <input type="text" value={buscaNome} onChange={e => {setBuscaNome(e.target.value)}} className="form-control" />
                  </div>
                  <div className="form-group col-md-2">
                    <label>Exibir para</label>
                    <select className="form-control" value={buscaExibir} onChange={e => {setBuscaExibir(e.target.value)}}>
                      <option value="" selected>Selecione</option>    
                      <option value="E">Especificos</option>    
                      <option value="T">Todos</option>    
                    </select>
                  </div>
                  <div className="form-group col-md-2">
                    <label>Status</label>
                    <select className="form-control" value={buscaStatus} onChange={e => {setBuscaStatus(e.target.value)}}>
                      <option value="" selected>Selecione</option>    
                      <option value="S">Ativo</option>    
                      <option value="N">Desativado</option>    
                    </select>
                  </div>

                  <div className="col-md-2 form-group">
                  <label>&nbsp;</label><br />
                    <button className="btn btn-primary btn-block" onClick={e=>{filtrar()}}>Filtrar</button>
                  </div> 
                </div> 
              </div>
            </div>
                  

              <LoadingOverlay
                active={caProcessa}
                spinner
                text='Carregando...'
              >                 
                <DataTable
                  title="Lista de Planos de Taxas"
                  columns={columns}
                  data={data}
                  striped="true" 
                  pagination="true"
                />
              </LoadingOverlay>                    
              
              </div>
            </div>
          </div>
                  
      <Modal size={'xl'} show={modal} onHide={() => fecharModal()}>
      <Modal.Header>
      <Modal.Title>Planos de Taxas</Modal.Title>
          <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={e => {fecharModal()}}>
            <i aria-hidden="true" className="ki ki-close" />
          </button>
      </Modal.Header>
      <ul className="nav nav-tabs nav-bold nav-tabs-line p-l-25">
          <li className="nav-item">
            <a className="nav-link active" data-toggle="tab" href="#planos">
              <span className="nav-icon">
                <i className="fab fa-wpforms" />
              </span>
              <span className="nav-text">Planos</span>
            </a>
          </li>
          <li className="nav-item">
            <a className={trava === true ? "nav-link" : "nav-link disabled"} data-toggle="tab" href="#taxas" onClick={e => {setFormTaxa({...formTaxa, id_pagon_plano: formPlano.id_pagon_plano})}}>
              <span className="nav-icon">
                <i className="fas fa-project-diagram" />
              </span>
              <span className="nav-text">Taxas</span>
            </a>
          </li>
        </ul>

        <div className="modal-body">
          <div className="tab-content">

            {/*begin::Group - Planos*/}
            <div className="tab-pane fade show active" id="planos" role="tabpanel" aria-labelledby="planos">
              <div className="row">
                <div className="form-group col-md-6">
                <label>Nome</label>
                  <input type="text" value={formPlano?.plano} onChange={e => {setFormPlano({...formPlano, plano: e.target.value})}} className="form-control" />
                </div>
            
                <div className="form-group col-md-3">
                  <label>Exibir para</label>
                  <select className="form-control" value={formPlano?.exibir} onChange={e => {setFormPlano({...formPlano, exibir: e.target.value})}}>
                    <option value="" selected>Selecione</option>    
                    <option value="E">Especifícos</option>    
                    <option value="T">Todos</option>    
                  </select>
                </div>

                <div className="form-group col-md-3">
                  <label>Status</label>
                  <select className="form-control" value={formPlano?.status} onChange={e => {setFormPlano({...formPlano, status: e.target.value})}}>
                    <option value="" selected>Selecione</option>    
                    <option value="S" >Ativo</option>    
                    <option value="N" >Desativado</option>    
                  </select>
                </div>
              </div>

              {formPlano.exibir === 'E' &&
              <div className="row">
                <div className="form-group col-md-12">
                  <label>Estabelecimentos</label>
                  <Select
                    options={listaEstabelecimentos}
                    placeholder="Selecione..." 
                    isMulti
                    defaultValue={formPlano.id_plano?.map((item) => { return(
                        listaEstabelecimentos.find(
                          x => x.value === item
                        )
                      )}
                    )} 
                    onChange={(e) => {
                      setFormPlano({
                        ...formPlano,
                        id_plano: e
                      })
                    }}
                  />
                </div>
              </div>
              }
            </div>
            {/*end::Group - Planos*/}

            {/*begin::Group - Taxas*/}
            <div className="tab-pane fade" id="taxas" role="tabpanel" aria-labelledby="taxas">
              <div className="row">
                
                <div className="form-group col-md-2">
                  <label>Tipo</label>
                  <select className="form-control" value={formTaxa?.tipo} onChange={e => {setFormTaxa({...formTaxa, tipo: e.target.value})}}>
                    <option value="" selected>Selecione</option>    
                    <option value="Crédito" >Crédito</option>    
                    <option value="Débito" >Débito</option>    
                  </select>
                </div>

                <div className="form-group col-md-2">
                  <label>Taxas</label>
                  <input type="text" value={formTaxa?.taxas} onChange={e => {setFormTaxa({...formTaxa, taxas: e.target.value})}} className="form-control" />
                </div>

                <div className="form-group col-md-2">
                  <label>Bandeira</label>
                  <Select
                      options={listaBandeiras}
                      placeholder="Selecione..." 
                      value={listaBandeiras.find(
                        x => x.value === formTaxa?.bandeira
                      )}
                      onChange={(e) => { setFormTaxa({...formTaxa, bandeira: e.value}) }} 
                    />
                </div>

                <div className="form-group col-md-2">
                  <label>Parcelas</label>
                  <input type="text" value={formTaxa?.parcelas} onChange={e => {setFormTaxa({...formTaxa, parcelas: e.target.value})}} className="form-control" />
                </div>

                <div className="form-group col-md-2">
                  <label>Recebimento em dia(s)</label>
                  <input type="text" value={formTaxa?.dias_recebimento} onChange={e => {setFormTaxa({...formTaxa, dias_recebimento: e.target.value})}} className="form-control" />
                </div>

                <div className="form-group col-md-2">
                  <br />
                  <button className="btn btn-primary btn-block" onClick={e => { incluirTaxa() }}>Incluir</button>
                </div>
               
              </div>

                 <DataTable
                    title="Taxas por plano"
                    columns={columnsTaxas}
                    data={dataTaxas}
                    striped="true" 
                    pagination="true"
                  />
              
            </div>
            {/*end::Group - Taxas*/}
          </div>
        </div>
      <Modal.Footer>
        <button type="button" onClick={e => { fecharModal(); }} className="btn btn-light-primary font-weight-bold" data-dismiss="modal">Fechar</button>
        <button type="button" onClick={e => { salvar(); }} className="btn btn-success font-weight-bold">Salvar</button>
      </Modal.Footer>
    </Modal>        
                 
        </>
    );
}

export default withRouter(Bancos);                  
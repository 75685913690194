import React from "react";
import * as S from "./styles";
export const TextInputs = ({ errors, register }) => {
  const productFormTextInputs = [
    {
      label: "Código de barras",
      widthClassName: "w-25",
      register: "eanCode",
      required: true,
      maxLength: 13,
    },
    {
      label: "Nome do produto",
      widthClassName: "w-50",
      register: "productName",
      required: true,
      maxLength: 42,
    },
    {
      label: "Descrição",
      widthClassName: "w-75",
      register: "description",
      required: false,
      maxLength: 400,
    },
    {
      label: "Marca",
      widthClassName: "w-50",
      register: "brand",
      required: true,
      maxLength: 42,
    },
  ];
  return (
    <>
      {productFormTextInputs.map((field) => {
        const isFieldRequired = errors[field.register]?.type === "required";
        const hasMinLengthError = errors[field.register]?.type === "minLength";
        return (
          <S.FormField error={isFieldRequired}>
            <label>
              {field.label}
              {field.required && <span> *</span>}
            </label>
            {field.register === "description" ? (
              <textarea
                className={field.widthClassName}
                {...register(`${field.register}`, {
                  required: field.required,
                })}
                maxLength={field.maxLength}
              />
            ) : (
              <input
                className={field.widthClassName}
                {...register(`${field.register}`, {
                  required: field.required,
                  minLength: {
                    value: field.register === "eanCode" ? 13 : undefined,
                  },
                })}
                maxLength={field.maxLength}
                onInput={(e) => {
                  if (field.register === "eanCode") {
                    e.target.value = e.target.value.replace(/[^0-9]/g, "");
                  }
                  return;
                }}
              />
            )}

            {isFieldRequired && <span>Preencha o campo "{field.label}"</span>}
            {hasMinLengthError && (
              <span>O campo "Código de Barras" deve conter 13 caracteres</span>
            )}
          </S.FormField>
        );
      })}
    </>
  );
};

import React from "react";
import { useMerchantManager } from "../hooks";

export const TabNavigator = ({
  merchantId,
  trava,
  setPagOnCadastro,
  getListarPlanosTaxas,
  getListarBancoRecebimentos,
}) => {
  const { setActiveTab } = useMerchantManager();

  return (
    <nav>
      <ul className="nav nav-tabs nav-bold nav-tabs-line p-l-25 mb-8">
        <li className="nav-item">
          <a
            className="nav-link"
            data-toggle="tab"
            href="#geral"
            onClick={() => setActiveTab("general-tab")}
          >
            <span className="nav-icon">
              <i className="fab fa-wpforms" />
            </span>
            <span className="nav-text">Geral</span>
          </a>
        </li>

        <li className="nav-item">
          <a
            className="nav-link active"
            id="data-tab"
            data-toggle="tab"
            href="#dados"
            onClick={() => setActiveTab("data-tab")}
          >
            <span className="nav-icon">
              <i className="fas fa-project-diagram" />
            </span>
            <span className="nav-text">Dados</span>
          </a>
        </li>

        <li className="nav-item">
          <a
            className="nav-link"
            data-toggle="tab"
            href="#imagem_app"
            onClick={() => setActiveTab("image-tab")}
          >
            <span className="nav-icon">
              <i className="fas fa-photo-video" />
            </span>
            <span className="nav-text">Imagem App</span>
          </a>
        </li>

        <li className="nav-item">
          <a
            className={trava === true ? "nav-link" : "nav-link disabled"}
            data-toggle="tab"
            href="#contatos"
            onClick={() => setActiveTab("contacts-tab")}
          >
            <span className="nav-icon">
              <i className="fas fa-users" />
            </span>
            <span className="nav-text">Contatos</span>
          </a>
        </li>

        <li className="nav-item">
          <a
            className={trava === true ? "nav-link" : "nav-link disabled"}
            data-toggle="tab"
            href="#bairros"
            onClick={() => setActiveTab("areas-tab")}
          >
            <span className="nav-icon">
              <i className="fas fa-route" />
            </span>
            <span className="nav-text">Bairros</span>
          </a>
        </li>

        <li className="nav-item">
          <a
            className="nav-link"
            data-toggle="tab"
            href="#horarios"
            onClick={() => setActiveTab("schedules-tab")}
          >
            <span className="nav-icon">
              <i className="far fa-clock" />
            </span>
            <span className="nav-text">Horários</span>
          </a>
        </li>

        <li className="nav-item">
          <a
            className={trava === true ? "nav-link" : "nav-link disabled"}
            data-toggle="tab"
            href="#acesso"
            onClick={() => setActiveTab("access-tab")}
          >
            <span className="nav-icon">
              <i className="fas fa-user-lock" />
            </span>
            <span className="nav-text">Acesso</span>
          </a>
        </li>

        <li className="nav-item dropdown" style={{ cursor: "pointer" }}>
          <span
            className={
              trava === true
                ? "nav-link dropdown-toggle"
                : "nav-link dropdown-toggle disabled"
            }
            data-toggle="dropdown"
            href="#"
            role="button"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <span className="nav-icon">
              <i className="fas fa-money-check-alt" />
            </span>
            <span className="nav-text">Pagamento Online</span>
          </span>
          <div className="dropdown-menu">
            <a
              className="dropdown-item"
              data-toggle="tab"
              href="#pag_cadastro"
              onClick={() => {
                setActiveTab("online-payment-register-tab");
                setPagOnCadastro((prevState) => ({
                  ...prevState,
                  merchantId,
                }));
              }}
            >
              Cadastro
            </a>
            <a
              className="dropdown-item"
              data-toggle="tab"
              href="#pag_arquivos"
              onClick={() => setActiveTab("online-payment-files-tab")}
            >
              Arquivos
            </a>
            <a
              className="dropdown-item"
              data-toggle="tab"
              href="#pag_planos"
              onClick={() => {
                setActiveTab("online-payment-plans-tab");
                getListarPlanosTaxas();
              }}
            >
              Planos de taxas
            </a>
            <a
              className="dropdown-item"
              data-toggle="tab"
              href="#pag_recebimentos"
              onClick={() => {
                setActiveTab("online-payment-receipts-tab");
                getListarBancoRecebimentos(merchantId);
              }}
            >
              Meios de recebimento
            </a>
          </div>
        </li>

        <li className="nav-item">
          <a
            className={trava === true ? "nav-link" : "nav-link disabled"}
            data-toggle="tab"
            href="#integracao"
            onClick={() => {
              setActiveTab("external-delivery-tab");
            }}
          >
            <span className="nav-icon">
              <i className="fas fa-people-arrows" />
            </span>
            <span className="nav-text">Integração leve.ai</span>
          </a>
        </li>

        {/* <li className="nav-item">
          <a
            className={trava === true ? "nav-link" : "nav-link disabled"}
            data-toggle="tab"
            href="#contract"
            onClick={() => {
              setActiveTab("contract-tab");
            }}
          >
            <span className="nav-icon">
              <i className="fas fa-signature" />
            </span>
            <span className="nav-text">Contrato</span>
          </a>
        </li> */}
      </ul>
    </nav>
  );
};

import React from "react";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import Layout from "../layout";

import Swal from "sweetalert2";

import Produtos from "../pages/produtos";
import RegisterProductEan from "../pages/RegisterProductEan";
import Login from "../pages/login";
import Painel from "../pages/painel";
import Dashbord from "../pages/dashbord";

//Menu Estabelecimento
import Estabelecimentos from "../pages/estabelecimentos/estabelecimentos";
import Sugestoes from "../pages/estabelecimentos/sugerir";
import Avaliacoes from "../pages/estabelecimentos/avaliacoes";
import Cupons from "../pages/estabelecimentos/cupons";
import Locais from "../pages/estabelecimentos/locais";
import Bairros from "../pages/estabelecimentos/locais/bairros";
import Categorias from "../pages/estabelecimentos/categorias";
import Pendencias from "../pages/estabelecimentos/pendencias";
import FormaPagamento from "../pages/estabelecimentos/formapagamento";
//Menu Pagamento Online
import Bancos from "../pages/pagon/bancos";
import Bandeira from "../pages/pagon/bandeira";
import Mccs from "../pages/pagon/mccs";
import Planos from "../pages/pagon/planos";
import PayoutReport from "../pages/pagon/payout_report";
//Menu Representantes
import Representantes from "../pages/representantes/cadastro";
import RepExtrato from "../pages/representantes/extrato";
//Menu Relatorio
import RelAbertura from "../pages/relatorios/abertura";
import RelPedidos from "../pages/relatorios/pedidos";
import RelMapa from "../pages/relatorios/mapa";
import RelMapaCidade from "../pages/relatorios/mapa_cidade";
import RelVendaPeriodo from "../pages/relatorios/vendas_periodo";
import RelVendasCidade from "../pages/relatorios/vendas_cidade";
import RelVendaEstPeriodo from "../pages/relatorios/vendas_est_periodo";
import RelFaturas from "../pages/relatorios/faturas";
import RelHistorico from "../pages/relatorios/historico";
//Menu Contato
import Tickets from "../pages/contatos/tickets";
//Menu Marketing
import MarketingPush from "../pages/marketing/push";
import { Banner } from "../pages/marketing/Banner";

import BannerList from "../pages/marketing/BannersList";
//Menu Usuários
import Usuarios from "../pages/usuarios/usuarios";
//Menu Configurações
import ConfImpressao from "../pages/configuracoes/impressao";
import ConfUsuario from "../pages/configuracoes/usuario";

//Menu pedidos
import Pedidos from "../pages/pedidos/index";

//Menu Avisos
import Avisos from "../pages/avisos";

//Menu Ferramentas
import Artes from "../pages/ferramentas/artes";
import AtComissao from "../pages/ferramentas/atualizacomissao";
import Fraudes from "../pages/ferramentas/fraudes";
import RetPagOnline from "../pages/ferramentas/ret_pag_online";
import Painel2 from "../pages/painel2";

import MassMail from "../pages/marketing/massmail";
import Nfse from "../pages/fiscal/nfse";

import { Merchant, MerchantManagerProvider } from "pages";

// Verificar se o usuário está autenticado
const isAuthenticated = () => {
  if ("@ADM-Aiboo/hash" in localStorage) {
    return true;
  } else {
    Swal.fire("Erro!", "Você não está logado, tente novamente.", "error");
    return false;
  }
};
// A função abaixo é para proteger uma rota
const AuthenticatedRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isAuthenticated() ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: "/login" }} />
      )
    }
  />
);

const Routes = (props) => (
  <HashRouter>
    <Switch>
      {/* Rotas de Login não necessitam de Auth */}
      <Route exact path="/" component={Login}></Route>
      <Route exact path="/login" component={Login}></Route>
      <Route exact path="/login/:usuario/:hash" component={Login}></Route>
      <Route exact path="/auth" component={Login}></Route>
      {/* ------------------------------------- */}
      <Layout>
        <AuthenticatedRoute exact path="/painel" component={Painel} />
        <AuthenticatedRoute exact path="/painel2" component={Painel2} />
        <AuthenticatedRoute exact path="/produtos" component={Produtos} />
        <AuthenticatedRoute
          exact
          path="/produtos/RegisterProductEan"
          component={RegisterProductEan}
        />

        <AuthenticatedRoute exact path="/dashbord" component={Dashbord} />

        <AuthenticatedRoute
          exact
          path="/estabelecimentos"
          component={Estabelecimentos}
        />
        <MerchantManagerProvider>
          <AuthenticatedRoute
            exact
            path="/estabelecimentos/estabelecimento/:merchantId?"
            component={Merchant}
          />
        </MerchantManagerProvider>

        <AuthenticatedRoute
          exact
          path="/estabelecimentos/sugestoes"
          component={Sugestoes}
        />
        <AuthenticatedRoute
          exact
          path="/estabelecimentos/avaliacoes"
          component={Avaliacoes}
        />
        <AuthenticatedRoute
          exact
          path="/estabelecimentos/cupons"
          component={Cupons}
        />
        <AuthenticatedRoute
          exact
          path="/estabelecimentos/locais"
          component={Locais}
        />
        <AuthenticatedRoute
          exact
          path="/estabelecimentos/locais/bairros"
          component={Bairros}
        />
        <AuthenticatedRoute
          exact
          path="/estabelecimentos/categorias"
          component={Categorias}
        />
        <AuthenticatedRoute
          exact
          path="/estabelecimentos/pendencias"
          component={Pendencias}
        />
        <AuthenticatedRoute
          exact
          path="/estabelecimentos/formapagamento"
          component={FormaPagamento}
        />

        <AuthenticatedRoute exact path="/pagon/bancos" component={Bancos} />
        <AuthenticatedRoute exact path="/pagon/bandeira" component={Bandeira} />
        <AuthenticatedRoute exact path="/pagon/mccs" component={Mccs} />
        <AuthenticatedRoute exact path="/pagon/planos" component={Planos} />
        <AuthenticatedRoute
          exact
          path="/pagon/report-payout"
          component={PayoutReport}
        />

        <AuthenticatedRoute
          exact
          path="/representantes/cadastro"
          component={Representantes}
        />
        <AuthenticatedRoute
          exact
          path="/representantes/extrato"
          component={RepExtrato}
        />

        <AuthenticatedRoute
          exact
          path="/relatorios/abertura"
          component={RelAbertura}
        />
        <AuthenticatedRoute
          exact
          path="/relatorios/pedidos"
          component={RelPedidos}
        />
        <AuthenticatedRoute exact path="/relatorios/mapa" component={RelMapa} />
        <AuthenticatedRoute
          exact
          path="/relatorios/mapacidade"
          component={RelMapaCidade}
        />
        <AuthenticatedRoute
          exact
          path="/relatorios/vendaperiodo"
          component={RelVendaPeriodo}
        />
        <AuthenticatedRoute
          exact
          path="/relatorios/vendascidade"
          component={RelVendasCidade}
        />
        <AuthenticatedRoute
          exact
          path="/relatorios/vendaestperiodo"
          component={RelVendaEstPeriodo}
        />
        <AuthenticatedRoute
          exact
          path="/relatorios/faturas"
          component={RelFaturas}
        />
        <AuthenticatedRoute
          exact
          path="/relatorios/historico"
          component={RelHistorico}
        />

        <AuthenticatedRoute
          exact
          path="/contatos/tickets"
          component={Tickets}
        />

        <AuthenticatedRoute
          exact
          path="/marketing/push"
          component={MarketingPush}
        />
        <AuthenticatedRoute
          exact
          path="/marketing/banners/:id?"
          component={Banner}
        />
        <AuthenticatedRoute
          exact
          path="/marketing/BannerList"
          component={BannerList}
        />
        <AuthenticatedRoute
          exact
          path="/marketing/massmail"
          component={MassMail}
        />

        <AuthenticatedRoute exact path="/fiscal/listaNfse" component={Nfse} />

        <AuthenticatedRoute
          exact
          path="/usuarios/usuarios"
          component={Usuarios}
        />

        <AuthenticatedRoute
          exact
          path="/configuracoes/impressao"
          component={ConfImpressao}
        />
        <AuthenticatedRoute
          exact
          path="/configuracoes/usuarios"
          component={ConfUsuario}
        />

        <AuthenticatedRoute exact path="/pedidos" component={Pedidos} />

        <AuthenticatedRoute exact path="/avisos" component={Avisos} />

        <AuthenticatedRoute exact path="/ferramentas/artes" component={Artes} />
        <AuthenticatedRoute
          exact
          path="/ferramentas/atualizacomissao"
          component={AtComissao}
        />
        <AuthenticatedRoute
          exact
          path="/ferramentas/fraudes"
          component={Fraudes}
        />
        <AuthenticatedRoute
          exact
          path="/ferramentas/ret_pag_online"
          component={RetPagOnline}
        />
      </Layout>
    </Switch>
  </HashRouter>
);

export default Routes;

import styled from 'styled-components';

export const Table = styled.table`
    width: 100%;
    border-top: 1px solid #E8ECF0;

        tr, thead, tbody, th, td {
            border-bottom: 1px solid #E8ECF0;
            height: 62px;
        }

        tr {
            &:nth-child(2n) {
                background-color: #F1F3F4;
            }
        }

        th, td {
            padding: 10px;
            max-width: 50px;
        }
`;

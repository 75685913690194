import React, { useState, useEffect } from 'react';
import Api from '../../../services/api';
import { withRouter } from "react-router";

import Swal from "sweetalert2";
import { useToasts } from 'react-toast-notifications';
import LoadingOverlay from 'react-loading-overlay';
import CpfCnpjInput from 'react-cpf-cnpj-input';
import { Link  } from 'react-router-dom';

import DataTable from 'react-data-table-component';
import { Modal } from "react-bootstrap";
import Select from 'react-select';
import Input from 'react-phone-number-input/input';

import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import pt from "date-fns/locale/pt-BR"; 
registerLocale("pt-BR", pt);

const Representantes = () => {
  const { addToast } = useToasts();
  const [ representes, setRepresentantes ] = useState([]);
  const [ formRepresentante, setFormRepresentante ] = useState({});
  const [ caProcessa, setCaProcessa ] = useState(false);
  const [ buscaCidade, setBuscaCidade ] = useState();
  const [ buscaRepresentante, setBuscaRepresentante ] = useState();
  const [ buscaStatus, setBuscaStatus ] = useState();
  const [ modal, setModal ] = useState(false);
  const [ cidAtendidas, setCidAtendidas ] = useState([]);
  const [ formCidAtendidas, setFormCidAtendidas ] = useState({});
  const [ cidadeSelect, setCidadeSelect ] = useState([]);
  const [ trava, setTrava ] = useState(false);
  const [ formUsuarios, setFormUsuarios ] = useState({});
  let acesso = JSON.parse(localStorage.getItem('@ADM-Aiboo/perfil'));

  function fecharModal(){
    setModal(false);
  }

  const data = representes;
  const columns = [
    {
      name: <th>ID</th>,
      selector: 'id_representante',
      sortable: true,
      width: '8%',
      center: true,
    },
    {
      name: <th>Saldo CC</th>,
      selector: 'saldo',
      width: '8%',
      sortable: true,
      right: true,
    },
    {
      name: <th>Representante</th>,
      selector: 'nome',
      width: '30%',
      sortable: true,
    },
    {
      name: <th>Comissão</th>,
      selector: 'comissao',
      width: '10%',
      sortable: true,
      right: true,
    },
    {
      name: <th>Cidade</th>,
      selector: 'id_cidade',
      width: '25%',
      sortable: true,
      cell: row => { return (row.id_cidade) + ' - ' + (row.nome_cidade) + '-' + (row.estado) }
    },
    {
      name: <th>Status</th>,
      width: '10%',
      sortable: true,
      selector: 'status',
      center: 'true',
      cell: row => { if (row.status == "S") {return <span class="label label-success label-inline mr-2">Ativo</span>}
        else {return <span class="label label-danger label-inline mr-2">Inutilizado</span>}
      }
    },
    {
      name: <th>Ações</th>,
      cell: (row) => 
      { return (<>
      <div class="dropdown dropdown-inline">
      <button type="button" class="btn btn-light-primary btn-icon btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <i class="ki ki-bold-more-ver"></i>
      </button>
      <div class="dropdown-menu">
        {acesso.rep_ger_rep_ac === "1" && <>
          <Link to="/representantes/extrato" class="dropdown-item" onClick={e => { extratoRepresentante(row) }}>Extrato</Link>
          <div class="dropdown-divider"></div>
        </>}
        <span class="dropdown-item" onClick={e => { editarRepresentante(row) }}>Editar</span>
        {acesso.rep_ger_rep_ei === "1" && <>
          <div class="dropdown-divider"></div>
          { 
            row.status === 'S' && 
              <span class="dropdown-item" onClick={e => { excluirRepresentante(row) }}>Inutilizar</span>
          }
          { 
            row.status !== 'S' && 
              <span class="dropdown-item" onClick={e => { ativarRepresentante(row) }}>Ativar</span>
          }
        </>}
      </div>
    </div>
    </>)
      },
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      right: true,
    },
  ];

  const dataCidAtendidas = cidAtendidas;
  const columnsCidAtendidas = [
    {
      name: <th>ID</th>,
      selector: 'id_cidade',
      sortable: true,
      width: '10%',
      center: true,
    },
    {
      name: <th>Nome</th>,
      selector: 'nome_cidade',
      width: '70%',
      sortable: true,
    },
    {
      name: <th>Comissão</th>,
      selector: 'comissao',
      width: '10%',
      sortable: true,
      right: true,
    },
    {
      name: <th>Ações</th>,
      cell: (row) => 
      { return (<>
        <button type="button" class="btn btn-light-primary btn-icon btn-sm" onClick={e => { excluirCidRepresentante(row) }}>
          <i class="far fa-trash-alt"></i>
        </button> 
    </>)
      },
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      right: true,
    },
  ];

  useEffect(() => {
    getListar();
    getCidadeSelect();
  }, []);

  function getListar() { 
    setCaProcessa(true); 
    Api.get("representantes/listar").then(rps => {
      setRepresentantes(rps.data.representantes);
      setCaProcessa(false);
    })
  }
  
  function adicionarRepresentante(){
    setFormRepresentante({});
    setFormCidAtendidas({});
    setTrava(false);
    setModal(true);
  }

  function extratoRepresentante(representante){
    var ca = JSON.parse(JSON.stringify(representante));

    sessionStorage.setItem('idRepresentante', ca.id_representante);
  }

  function editarRepresentante(id_representante){  
    var ca = JSON.parse(JSON.stringify(id_representante));

    setFormRepresentante(ca);
    getListarRepresentanteCid(ca.id_representante);
    getListarUsuario(ca.id_representante);
    setTrava(true);
    setModal(true);            
  }

  function salvarRepresentante(){
    setCaProcessa(true);
    Api.post('representantes/adicionarrepresentante', formRepresentante).then(rps => {
      if (rps.data.status === true) {
        addToast(rps.data.mensagem, {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 2000
        });
        setTrava(false);
        setModal(false);
        setFormRepresentante({});
        getListar();
      } else {
        Swal.fire({
          title: "Erro!",
          icon: "error",
          html: rps.data.erros,
          showCloseButton: true,
          showCancelButton: false,
        });
      }
      setCaProcessa(false);
    });
  }

  function salvarCidAtendida(){
    setCaProcessa(true);
    formCidAtendidas.id_representante = formRepresentante.id_representante;
    Api.post('representantescid/adicionar', formCidAtendidas).then(rps => {
      if (rps.data.status === true) {
        addToast(rps.data.mensagem, {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 2000
        });
        setFormCidAtendidas({});
        getListarRepresentanteCid(formCidAtendidas.id_representante);
      } else {
        Swal.fire({
          title: "Erro!",
          icon: "error",
          html: rps.data.erros,
          showCloseButton: true,
          showCancelButton: false,
        });
      }
      setCaProcessa(false);
    });
  }

  function salvarUsuario(){
    setCaProcessa(true);
    
    formUsuarios.id_representante = formRepresentante.id_representante;
    
    Api.post('representantes/edtSenhaRepresentante', formUsuarios).then(rps => {
      if (rps.data.status === true) {
        addToast(rps.data.mensagem, {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 2000
        });
      } else {
        Swal.fire({
          title: "Erro!",
          icon: "error",
          html: rps.data.erros,
          showCloseButton: true,
          showCancelButton: false,
        });
      }
      setCaProcessa(false);
    });
  }

  function excluirRepresentante(id_representante) {
    var ca = JSON.parse(JSON.stringify(id_representante));

    setCaProcessa(true);

    Swal.fire({
        icon: "question",
        title: 'Confirmação',
        html: 'Deseja inutilizar o registro?',
        showCancelButton: true,
        confirmButtonText: `Sim, Confirmar!`,
        cancelButtonText: 'Não, Cancelar.'
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        Api.post('representantes/inutilizar', ca).then(rps => {
          
          if(rps.data.status==true){
            addToast(rps.data.mensagem, {
              appearance: "success",
              autoDismiss: true,
              autoDismissTimeout: 2000
            });
            getListar();
          } 
        })
      } 
      setCaProcessa(false);
    })
  }

  function ativarRepresentante(id_representante) {
    var ca = JSON.parse(JSON.stringify(id_representante));

    setCaProcessa(true);

    Swal.fire({
        icon: "question",
        title: 'Confirmação',
        html: 'Deseja ativar o registro?',
        showCancelButton: true,
        confirmButtonText: `Sim, Confirmar!`,
        cancelButtonText: 'Não, Cancelar.'
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        Api.post('representantes/ativar', ca).then(rps => {
          
          if(rps.data.status==true){
            addToast(rps.data.mensagem, {
              appearance: "success",
              autoDismiss: true,
              autoDismissTimeout: 2000
            });
            getListar();
          } 
        })
      } 
      setCaProcessa(false);
    })
  }

  function excluirCidRepresentante(id_representante) {
    var ca = JSON.parse(JSON.stringify(id_representante));

    setCaProcessa(true);

    Swal.fire({
        icon: "question",
        title: 'Confirmação',
        html: 'Deseja excluir o registro?',
        showCancelButton: true,
        confirmButtonText: `Sim, Confirmar!`,
        cancelButtonText: 'Não, Cancelar.'
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        Api.post('representantescid/excluir', ca).then(rps => {
          
          if(rps.data.status==true){
            addToast(rps.data.mensagem, {
              appearance: "success",
              autoDismiss: true,
              autoDismissTimeout: 2000
            });
            getListarRepresentanteCid(ca.id_representante);
          } 
        })
      } 
      setCaProcessa(false);
    })
  }

  function getCidadeSelect() {
    Api.get("cidades/listar").then(rps => {
      var cid = rps.data.cidades;
      var nCid = [];
      nCid[nCid.length] = {value: '', label: 'Selecione...'};
      cid.forEach(e => {
        nCid[nCid.length] = {value: e.id_cidade, label: e.nome_cidade + ' - ' + e.estado};
      })
      setCidadeSelect(nCid);
    })
  }

  function getListarRepresentanteCid(idRepresentante){  
    var data = {
      id_representante: idRepresentante
    };

    Api.post("representantescid/listar", data ).then((rps) =>{

      if (rps.data.obj !== null) {
        setCidAtendidas(rps.data.obj);
      } else {
        setCidAtendidas([]);
      }
    });
  }

  function getListarUsuario(idRepresentante){  
    var data = {
      id_representante: idRepresentante
    };

    Api.post("usuarios/listarPorId", data ).then((rps) =>{

      console.log(rps.data.obj);
      if (rps.data.obj !== null) {
        setFormUsuarios(rps.data.obj);
      } else {
        setFormUsuarios([]);
      }
    });
  }

  function filtrar() {
    setCaProcessa(true); 
      var data = {
        cidade: buscaCidade,
        representante: buscaRepresentante,
        status: buscaStatus,
      };
    
      Api.post('representantes/filtrar', data).then((rps) => {

        if(rps.data.status==false){
          Swal.fire({
            title: "Atenção!",
            icon: "info",
            html: rps.data.erro,
            showCloseButton: true,
            showCancelButton: false,
          });
        } else {
          setRepresentantes(rps.data.obj);
        }
      setCaProcessa(false); 
    });
  }
    
    return (
        <>
<div>

<div className="subheader espaco-header-pedidos subheader-transparent" id="kt_subheader">
          <div className="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
            {/*begin::Details*/}
            <div className="d-flex align-items-center flex-wrap mr-2">
              {/*begin::Title*/}
              <h5 className="text-dark font-weight-bold mt-2 mb-2 mr-5">Gerenciar Representantes</h5>
              {/*end::Title*/}
              {/*begin::Separator*/}
              <div className="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200" />
              {/*end::Separator*/}
            </div>
            {/*end::Details*/}
            {/*begin::Toolbar*/}
            <div className="d-flex align-items-center">
              <button className="btn btn-lg btn-success mt-3 mb-3" onClick={e => {adicionarRepresentante()}}>Adicionar</button>
            </div>
            {/*end::Toolbar*/}
          </div>
        </div>
        {/*begin::Entry*/}
        <div className="d-flex flex-column-fluid">
          {/*begin::Container*/}
          <div className="container">
            <div className="card card-custom">
              <div className="ml-10 mr-10 mt-10">    
                <div className="row">
                <div className="form-group col-md-5">
                  <label>Representante</label>
                  <input type="text" value={buscaRepresentante} onChange={e => {setBuscaRepresentante(e.target.value)}} className="form-control" />
                </div>
                <div className="form-group col-md-3">
                  <label>Cidade</label>
                  <Select options={cidadeSelect}
                  placeholder="Selecione..."
                  defaultValue={cidadeSelect.find(
                    x => x.value === setBuscaCidade
                  )}
                  onChange={(e) => { setBuscaCidade(e.value) }} 
                /> 
                </div>
                <div className="form-group col-md-2">
                  <label>Status</label>
                  <select className="form-control" value={buscaStatus} onChange={e => {setBuscaStatus(e.target.value)}}>
                    <option value="" selected>Selecione</option>    
                    <option value="S">Ativo</option>    
                    <option value="N">Desativado</option>    
                  </select>
                </div>

                  <div className="col-md-2 form-group">
                  <label>&nbsp;</label><br />
                    <button className="btn btn-primary btn-block" onClick={e=>{filtrar()}}>Filtrar</button>
                  </div> 
                </div> 
              </div>

              <LoadingOverlay
                active={caProcessa}
                spinner
                text='Carregando...'
              >                 
                <DataTable
                  title="Lista de Representantes"
                  columns={columns}
                  data={data}
                  striped="true" 
                  pagination="true"
                />
              </LoadingOverlay>                    
      
                  </div>
                  </div>
                  </div>
                  </div>
                  
      <Modal size={'xl'} show={modal} onHide={() => fecharModal()}>
      <Modal.Header>
      <Modal.Title>Representante</Modal.Title>
          <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={e => {fecharModal()}}>
            <i aria-hidden="true" className="ki ki-close" />
          </button>
      </Modal.Header>
      <ul className="nav nav-tabs nav-bold nav-tabs-line p-l-25">
          <li className="nav-item">
            <a className="nav-link active" data-toggle="tab" href="#cadastro">
              <span className="nav-icon">
                <i className="fab fa-wpforms" />
              </span>
              <span className="nav-text">Cadastro</span>
            </a>
          </li>
          <li className="nav-item">
            <a className={trava === true ? "nav-link" : "nav-link disabled"} data-toggle="tab" href="#cidade">
              <span className="nav-icon">
                <i className="fas fa-project-diagram" />
              </span>
              <span className="nav-text">Cidades Atendidas</span>
            </a>
          </li>
          <li className="nav-item">
            <a className={trava === true ? "nav-link" : "nav-link disabled"} data-toggle="tab" href="#acesso">
              <span className="nav-icon">
                <i className="fas fa-key" />
              </span>
              <span className="nav-text">Acesso</span>
            </a>
          </li>
      </ul>

      <div className="modal-body">    
        <div className="tab-content">
          {/*begin::Cadastro*/}
          <div className="tab-pane fade show active" id="cadastro" role="tabpanel" aria-labelledby="cadastro">
            <div className="row">
              <div className="form-group col-md-10">    
                <label>Nome do representante <b className="text-danger">*</b></label>
                <input className="form-control"
                  placeholder="Nome"
                  value={formRepresentante?.nome} 
                  onChange={(e) => { setFormRepresentante({...formRepresentante, nome: e.target.value}) }}/>
              </div>

              <div className="form-group col-md-2">
                <label>Status</label>
                <select className="form-control" value={formRepresentante?.status} onChange={(e) => { setFormRepresentante({...formRepresentante, status: e.target.value}) }}>
                  <option value="" selected>Selecione</option>    
                  <option value="S">Ativo</option>    
                  <option value="N">Desativado</option>    
                </select>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12 form-group">
                <label>E-mail</label>
                <input className="form-control"
                  placeholder="E-mail"
                  value={formRepresentante?.email} 
                  onChange={(e) => { setFormRepresentante({...formRepresentante, email: e.target.value}) }}/>
              </div>
            </div>

            <div className="row">
              <div className="col-md-4 form-group">
                <label>CNPJ/CPF <b className="text-danger">*</b></label>
                <CpfCnpjInput
                  maskField="_"
                  className="form-control"
                  value={formRepresentante?.cnpj_cpf}
                  onChangeText={(e) => { setFormRepresentante({...formRepresentante, cnpj_cpf: e}) }} 
                  placeholder="CNPJ ou CPF (caso representante não tenha CNPJ)"
                  /> 
              </div>

              <div className="col-md-4 form-group">
                <label>Telefone 1 <b className="text-danger">*</b></label>
                <Input
                  className="form-control"
                  international={true}
                  defaultCountry="BR"
                  placeholder="Telefone 1"
                  maxLength="15"
                  value={formRepresentante.telefone1}
                  onChange={(e) => { setFormRepresentante({...formRepresentante, telefone1: e}) }}/>
              </div>

              <div className="col-md-4 form-group">
                <label>Telefone 2</label>
                <Input
                  className="form-control"
                  international={false}
                  defaultCountry="BR"
                  placeholder="Telefone 2"
                  maxLength="15"
                  value={formRepresentante?.telefone2}
                  onChange={(e) => { setFormRepresentante({...formRepresentante, telefone2: e}) }}/>
              </div>
            </div>

            <div className="row">
              <div className="form-group col-md-2">
                <label>CEP <b className="text-danger">*</b></label>
                <input className="form-control"
                  value={formRepresentante?.endereco_cep} 
                  onChange={(e) => { setFormRepresentante({...formRepresentante, endereco_cep: e.target.value}) }}/>
              </div>
              <div className="col-md-5 form-group">
                <label>Cidade <b className="text-danger">*</b></label>
                <Select options={cidadeSelect}
                  placeholder="Selecione..."
                  defaultValue={cidadeSelect.find(
                    x => x.value === formRepresentante.id_cidade
                  )}
                  onChange={(e) => { setFormRepresentante({...formRepresentante, id_cidade: e.value}) }} 
                />  
              </div>

              <div className="col-md-5 form-group">
                <label>Bairro <b className="text-danger">*</b></label>
                <input className="form-control"
                  placeholder="Ex: Bairro dos alemões"
                  value={formRepresentante?.endereco_bairro} 
                  onChange={(e) => { setFormRepresentante({...formRepresentante, endereco_bairro: e.target.value}) }}/>
              </div>
            </div>

            <div className="row">
              <div className="col-md-10 form-group">
                <label>Endereço <b className="text-danger">*</b></label>
                <input className="form-control"
                  placeholder="Ex: Rua Professor"
                  value={formRepresentante?.endereco_rua} 
                  onChange={(e) => { setFormRepresentante({...formRepresentante, endereco_rua: e.target.value}) }}/>
              </div>

              <div className="col-md-2 form-group">
                <label>Número <b className="text-danger">*</b></label>
                <input className="form-control"
                  placeholder="Ex: 260"
                  value={formRepresentante?.endereco_numero} 
                  onChange={(e) => { setFormRepresentante({...formRepresentante, endereco_numero: e.target.value}) }}/>
              </div>
            </div>

            <div className="row">
              <div className="col-md-3 form-group">
                <label>Comissão <b className="text-danger">*</b></label>
                <input className="form-control"
                  placeholder="0.00"
                  value={formRepresentante?.comissao} 
                  onChange={(e) => { setFormRepresentante({...formRepresentante, comissao: e.target.value}) }}/>
              </div>

              <div className="col-md-3 form-group">
                <label>Valor da cobrança <b className="text-danger">*</b></label>
                <input className="form-control"
                  placeholder="0.00"
                  value={formRepresentante?.valor_cobranca} 
                  onChange={(e) => { setFormRepresentante({...formRepresentante, valor_cobranca: e.target.value}) }}/>
              </div>
            </div>
          </div>
          {/*end::Cadastro*/}

          {/*begin::Cidade*/}
          <div className="tab-pane fade" id="cidade" role="tabpanel" aria-labelledby="cidade">
            <div className="row">
              <div className="col-md-6">
                <label>Cidade <b className="text-danger">*</b></label>
                <Select options={cidadeSelect}
                  placeholder="Selecione..."
                  defaultValue={cidadeSelect.find(
                    x => x.value === formCidAtendidas.id_cidade
                  )}
                  onChange={(e) => { setFormCidAtendidas({...formCidAtendidas, id_cidade: e.value}) }} 
                />    
              </div>
                <div className="col">
                  <label>Comissão <b className="text-danger">*</b></label>
                  <input className="form-control" 
                    value={formCidAtendidas?.comissao} 
                    onChange={(e) => { setFormCidAtendidas({...formCidAtendidas, comissao: e.target.value}) }}/>
                </div>

                <div className="col">
                  <label>&nbsp;</label><br />
                  <button className="btn btn-block btn-success" onClick={e => { salvarCidAtendida(); }}><i className="flaticon2-plus icon-sm"/>Adicionar</button>
                </div>
              </div>
              <LoadingOverlay
                active={caProcessa}
                spinner
                text='Carregando...'
                >                 
                <DataTable
                  title="Cidades Atendidas"
                  columns={columnsCidAtendidas}
                  data={dataCidAtendidas}
                  striped="true" 
                  pagination="true"
                />
              </LoadingOverlay>
          </div>
          {/*end::Cidade*/}

          {/*begin::Acesso*/}
          <div className="tab-pane fade" id="acesso" role="tabpanel" aria-labelledby="acesso">
            <div className="row">
              <div class="form-group col-md-12">
                <label>Usuário</label>
                <input className="form-control" 
                       value={formUsuarios?.email} 
                       onChange={(e) => { setFormUsuarios({...formUsuarios, email: e.target.value}) }}
                />
              </div>
            </div>

            <div className="row">
              <div className="form-group col-md-6">
                <label>Senha</label>
                <input className="form-control" type="password" 
                       value={formUsuarios?.senha} 
                       onChange={(e) => { setFormUsuarios({...formUsuarios, senha: e.target.value}) }}/>
              </div>
              <div className="form-group col-md-6">
                <label>Confirmar Senha</label>
                <input className="form-control" type="password" 
                       value={formUsuarios?.csenha} 
                       onChange={(e) => { setFormUsuarios({...formUsuarios, csenha: e.target.value}) }}/>
              </div>
            </div>

            <button className="btn btn-success" onClick={e => { salvarUsuario(); }}>Salvar Usuário</button>
            
          </div>
          {/*end::Acesso*/}
        </div>
      </div> 
      
      <Modal.Footer>
        <button type="button" onClick={e => { fecharModal(); }} className="btn btn-light-primary font-weight-bold" data-dismiss="modal">Fechar</button>
        <button type="button" onClick={e => { salvarRepresentante(); }} className="btn btn-success font-weight-bold">Salvar</button>
      </Modal.Footer>
    </Modal>        
                 
        </>
    );
}

export default withRouter(Representantes);                  
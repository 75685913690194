import axios from "axios";
import { authService } from "services";

const API_FY_BASE_URL = "https://api.ipify.org?format=json";

export const usePersonalUserInfo = () => {
  const initializePersonalUserHeaders = async () => {
    const cachedUserIp = localStorage.getItem("@ADM-Aiboo/user-ip");

    if (cachedUserIp) {
      authService.setUserIpHeader(cachedUserIp);

      return;
    }

    const response = await axios.get(API_FY_BASE_URL);

    authService.setUserIpHeader(response.data.ip);
    localStorage.setItem("@ADM-Aiboo/user-ip", response.data.ip);
  };

  return { initializePersonalUserHeaders };
};

import Api from "services/api";

const deleteArea = async (bairro) => {
  const response = await Api.post("bairros/excluir", bairro);
  return response.data;
};

const addNewArea = async (formattedData) => {
  const response = await Api.post("bairros/adicionar", formattedData);
  return response.data;
};

const listAreas = async (id_cidade) => {
  const response = await Api.get(`bairros/listar/${null}/${id_cidade}/S`);
  return response.data;
};

export const areaService = {
  deleteArea,
  addNewArea,
  listAreas,
};

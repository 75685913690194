import React from "react";
import { useState, useEffect, useCallback } from "react";
import { withRouter } from "react-router";

import Api from "../../../services/api";
import axios from "axios";

import { Modal } from "../../../components/Modal";
import { Pagination } from "../../../components/Pagination";

import LoadingOverlay from "react-loading-overlay";
import { useToasts } from "react-toast-notifications";
import { Link } from "react-router-dom";

const defaultConfigs = {
  baseURL: Api.defaults.baseURL,
  headers: {
    "content-type": "application/x-www-form-urlencoded",
    Authorization: Api.defaults.headers["Authorization"],
  },
};

const BannersList = () => {
  const [banners, setBanners] = useState([]);

  const [idSearchTerm, setIdSearchTerm] = useState("");
  const [nameSearchTerm, setNameSearchTerm] = useState("");
  const [typeSearchTerm, setTypeSearchTerm] = useState("");
  const [sortBy, setSortBy] = useState("");
  const [orderBy, setOrderBy] = useState("desc");
  const [bannerImage, setBannerImage] = useState("");

  const [isVisible, setIsVisible] = useState(false);
  const [isFiltered, setIsFiltered] = useState(false);
  const [isOrdered, setIsOrdered] = useState(false);
  const [modalType, setModalType] = useState("message");

  const [pageStatus, setPageStatus] = useState("idle");

  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [bannersCount, setBannersCount] = useState(0);

  const [bannerIdForDelete, setBannerIdForDelete] = useState(0);

  const { addToast } = useToasts();

  const access = JSON.parse(localStorage.getItem("@ADM-Aiboo/perfil"));

  const bannerTypeLabel = {
    establishment: "Empresa",
    item: "Item",
  };

  const bannerStatusLabel = {
    active: "Ativado",
    inactive: "Desativado",
  };

  const bannerToDelete = React.useMemo(() => ({
    banner_id: bannerIdForDelete,
  }), [bannerIdForDelete]);

  const dateFormatter = (date) => {
    if (!date) {
      return;
    }

    return date.split("-").reverse().join("/");
  };

  const noTypedFilters = useCallback(() => {
    return (
      idSearchTerm === "" && nameSearchTerm === "" && typeSearchTerm === ""
    );
  }, [idSearchTerm, nameSearchTerm, typeSearchTerm]);

  const handleKeyDownToSubmitFilter = (event) => {
    if (event.code === "Enter") {
      setIsFiltered(true);
      setCurrentPage(1);
    }
  };

  const handleButtonToSubmitFilter = () => {
    if (noTypedFilters()) {
      return;
    }
    setIsFiltered(true);
    setCurrentPage(1);
  };

  const toggleModalVisibility = useCallback((image) => {
    setIsVisible((prevState) => !prevState);

    if (image) {
      setModalType("image");
      setBannerImage(image);
      return;
    }
    
    setModalType("message");
  }, []);

  const handleOpenBannerIMage = (image) => {
    if (!image) {
      addToast("Não foi encontrada uma imagem para esse banner.", {
        appearance: "warning",
        autoDismiss: true,
        autoDismissTimeout: 2000,
      });

      return;
    }

    toggleModalVisibility(image);
  };

  const getBanners = useCallback(async () => {
    setPageStatus("loading");

    const bannersListData = new URLSearchParams();
    bannersListData.append("page", currentPage);
    bannersListData.append("per_page", 15);

    try {
      const response = await axios.post(
        "banners/index",
        bannersListData,
        defaultConfigs
      );
      setPageStatus("success");
      setBanners(response.data.banners);
      setTotalPages(response.data.qts_page);
      setBannersCount(response.data.total_count_banners);
    } catch {
      setBanners([]);
      if (noTypedFilters()) {
        setPageStatus("error");
        return;
      }
      setPageStatus("notFound");
    }
  }, [currentPage, noTypedFilters]);

  const filterBanners = useCallback(async () => {
    setPageStatus("loading");
    try {
      const FilteredBannersListData = new URLSearchParams();
      FilteredBannersListData.append("banner_id", idSearchTerm);
      FilteredBannersListData.append("name", nameSearchTerm);
      FilteredBannersListData.append("type", typeSearchTerm);
      FilteredBannersListData.append("page", currentPage);
      FilteredBannersListData.append("per_page", 15);
      FilteredBannersListData.append("sort_by", sortBy);
      FilteredBannersListData.append("order", orderBy);

      const response = await axios.post(
        "banners/index",
        FilteredBannersListData,
        defaultConfigs
      );
      setPageStatus("success");
      setBanners(response.data.banners);
      setTotalPages(response.data.qts_page);
      setBannersCount(response.data.total_count_banners);
    } catch {
      setBanners([]);
      if (noTypedFilters()) {
        setPageStatus("error");
        return;
      }
      setPageStatus("notFound");
    }
  }, [idSearchTerm, nameSearchTerm, typeSearchTerm, currentPage, sortBy, orderBy, noTypedFilters]);

  const deleteBanner = useCallback(async () => {
    setPageStatus("loading");

    if (!access.admin_banner) {
      addToast("Você não possui permissão para exclusão de banners.", {
        appearance: "warning",
        autoDismiss: true,
        autoDismissTimeout: 2000,
      });
      setPageStatus("success");
      return;
    }

    try {
      await Api.post("banners/delete", bannerToDelete);
      setPageStatus("success");
      setCurrentPage(1);
      getBanners();
      toggleModalVisibility();

      addToast("Banner excluído com sucesso.", {
        appearance: "success",
        autoDismiss: true,
        autoDismissTimeout: 2000,
      });
    } catch {
      setPageStatus("error");
    }
  }, [access.admin_banner, addToast, bannerToDelete, getBanners, toggleModalVisibility]);

  const handleClickInOrderBy = (column) => {
    setIsOrdered(true);
    setCurrentPage(1);
    setSortBy(column);
    setOrderBy((prevState) => (prevState === "asc" ? "desc" : "asc"));
  };

  const renderEmptyBannersList = () => {
    if (pageStatus === "loading") {
      return;
    }
    return (
      <div
        className="w-100 d-flex justify-content-center align-items-center flex-column"
        style={{ gap: 75, position: "absolute", padding: 70 }}
      >
        <div
          className="d-flex flex-column align-items-center"
          style={{ gap: 75 }}
        >
          <div>
            <img
              alt="Nenhum banner encontrado"
              src="assets/imgs/no-results.svg"
              style={{ width: "20rem", height: "auto" }}
            />
          </div>

          <div className="text-center">
            <h3>Nenhum banner encontrado</h3>
            <p>
              Tente pesquisar por outro termo
              <br />
              ou crie um novo banner
            </p>
          </div>
        </div>
      </div>
    );
  };

  const renderBannersListError = () => (
    <div
      className="w-100 d-flex justify-content-center align-items-center flex-column"
      style={{ gap: 75, position: "absolute", padding: 70 }}
    >
      <div
        className="d-flex flex-column align-items-center"
        style={{ gap: 75 }}
      >
        <div>
          <img
            alt="Erro"
            src="assets/imgs/page-error.svg"
            style={{ width: "20rem", height: "auto" }}
          />
        </div>

        <div className="text-center">
          <h3>Houve um erro ao carregar essa página</h3>
          <p>Tente recarregá-la ou volte mais tarde</p>
        </div>
      </div>
    </div>
  );

  useEffect(() => {
    if (isOrdered || isFiltered) {
      filterBanners();
      return;
    }
    setIsOrdered(false);
    setIsFiltered(false);
    return;
  }, [currentPage, orderBy, isOrdered, isFiltered, sortBy, filterBanners]);

  useEffect(() => {
    if (noTypedFilters()) {
      setIsFiltered(false);
      getBanners();
      return;
    }
  }, [noTypedFilters, currentPage, getBanners]);

  return (
    <div>
      <div
        className="subheader espaco-header-pedidos subheader-transparent"
        id="kt_subheader"
      >
        <div className="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
          <div className="d-flex align-items-center flex-wrap mr-2">
            <h5 className="text-dark font-weight-bold mt-2 mb-2 mr-5">
              Gerenciar banners
            </h5>
            <div className="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200" />
          </div>
          <div className="d-flex align-items-center">
            <Link to="/marketing/banners">
              <button className="btn btn-lg btn-success mt-3 mb-3">
                <i className="fas fa-plus" />
                Criar banner
              </button>
            </Link>
          </div>
        </div>
      </div>

      <div className="d-flex flex-column-fluid">
        <div className="container">
          <div className="card card-custom">
            <div className="ml-10 mr-10 mt-10">
              <div className="row">
                <div className="form-group col-md-1">
                  <label>ID</label>
                  <input
                    type="number"
                    min="1"
                    value={idSearchTerm}
                    onChange={(item) => setIdSearchTerm(item.target.value)}
                    onKeyDown={handleKeyDownToSubmitFilter}
                    className="form-control"
                    placeholder="ID"
                  />
                </div>

                <div className="form-group col-md-2">
                  <label>Nome do banner</label>
                  <input
                    type="text"
                    value={nameSearchTerm}
                    onChange={(item) => setNameSearchTerm(item.target.value)}
                    onKeyDown={handleKeyDownToSubmitFilter}
                    className="form-control"
                    placeholder="Nome ou parte do nome"
                  />
                </div>

                <div className="form-group col-md-2">
                  <label>Tipo</label>
                  <select
                    className="form-control"
                    value={typeSearchTerm}
                    onChange={(item) => setTypeSearchTerm(item.target.value)}
                    onKeyDown={handleKeyDownToSubmitFilter}
                  >
                    <option value="" selected>
                      Tipo do banner
                    </option>
                    <option value="establishment">Empresa</option>
                    <option value="item">Item</option>
                  </select>
                </div>

                <div className="col form-group">
                  <label>&nbsp;</label>
                  <br />
                  <button
                    className="btn btn-block btn-outline-primary"
                    onClick={handleButtonToSubmitFilter}
                    style={{
                      width: 150,
                    }}
                  >
                    <i className="la la-filter" />
                    Filtrar
                  </button>
                </div>
                <LoadingOverlay
                  className="w-100"
                  active={pageStatus === "loading"}
                  spinner
                  text="Carregando..."
                >
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th className="text-left">
                          <button
                            className="bg-transparent border-0 d-flex align-items-center"
                            onClick={() => handleClickInOrderBy("banner_id")}
                          >
                            <span style={{ color: "#333333" }}>ID</span>
                            <i
                              className="fas fa-sort mx-2"
                              style={{ color: "#333333" }}
                            />
                          </button>
                        </th>
                        <th className="text-left">
                          <button
                            className="bg-transparent border-0 d-flex align-items-center"
                            onClick={() => handleClickInOrderBy("name")}
                          >
                            <span style={{ color: "#333333" }}>
                              Nome do banner
                            </span>
                            <i
                              className="fas fa-sort mx-2"
                              style={{ color: "#333333" }}
                            />
                          </button>
                        </th>
                        <th className="text-left ">
                          <span style={{ color: "#333333", fontWeight: 400 }}>
                            Descrição
                          </span>
                        </th>
                        <th className="text-left">
                          <button
                            className="bg-transparent border-0 d-flex align-items-center"
                            onClick={() => handleClickInOrderBy("type")}
                          >
                            <span style={{ color: "#333333" }}>Tipo</span>
                            <i
                              className="fas fa-sort mx-2"
                              style={{ color: "#333333" }}
                            />
                          </button>
                        </th>
                        <th className="text-left">
                          <button
                            className="bg-transparent border-0 d-flex align-items-center"
                            onClick={() => handleClickInOrderBy("period")}
                          >
                            <span style={{ color: "#333333" }}>Período</span>
                            <i
                              className="fas fa-sort mx-2"
                              style={{ color: "#333333" }}
                            />
                          </button>
                        </th>
                        <th className="text-left ">
                          <span
                            style={{ color: "#333333", fontWeight: 400 }}
                            onClick={() => {}}
                          >
                            Banner
                          </span>
                        </th>

                        <th className="text-left">
                          <button
                            className="bg-transparent border-0 d-flex align-items-center"
                            onClick={() => handleClickInOrderBy("status")}
                          >
                            <span style={{ color: "#333333" }}>Status</span>
                            <i
                              className="fas fa-sort mx-2"
                              style={{ color: "#333333" }}
                            />
                          </button>
                        </th>
                        <th className="text-left">
                          <span
                            style={{ color: "#333333", fontWeight: 400 }}
                            onClick={() => {}}
                          >
                            Ações
                          </span>
                        </th>
                      </tr>
                    </thead>
                    {pageStatus === "error" && renderBannersListError()}
                    {((!banners.length && pageStatus === "success") ||
                      pageStatus === "notFound") &&
                      renderEmptyBannersList()}

                    <tbody>
                      {banners?.map((item) => {
                        return (
                          <>
                            <tr>
                              <td className="text-center align-middle">
                                {item.banner_id}
                              </td>
                              <td className="text-left align-middle text-truncate">
                                {item.name}
                              </td>
                              <td
                                className="text-left align-middle text-truncate "
                                style={{ maxWidth: "12rem" }}
                              >
                                {item.description}
                              </td>
                              <td className="text-left align-middle">
                                {bannerTypeLabel[item.type]}
                              </td>
                              <td
                                className="text-left align-middle"
                                style={{ width: "15rem" }}
                              >
                                {item.ends_at
                                  ? `De ${dateFormatter(item.starts_at)} à 
                                ${dateFormatter(item.ends_at)}`
                                  : `A partir de ${dateFormatter(
                                      item.starts_at
                                    )}`}
                              </td>
                              <td className="text-center align-middle ">
                                <button
                                  className="text-primary bg-transparent border-0 d-flex align-items-center"
                                  onClick={() =>
                                    handleOpenBannerIMage(item.image)
                                  }
                                >
                                  <i className="la la-image text-primary mx-1" />
                                  Ver banner
                                </button>
                              </td>
                              <td
                                className="text-left align-middle"
                                style={{
                                  color: item.status === "active" && "#00B333",
                                }}
                              >
                                {bannerStatusLabel[item.status]}
                              </td>
                              <td className="text-center align-middle">
                                <div className="dropdown dropdown-inline">
                                  <button
                                    type="button"
                                    className="btn btn-light-primary btn-icon btn-sm"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  >
                                    <i className="ki ki-bold-more-ver"></i>
                                  </button>
                                  <div className="dropdown-menu">
                                    <Link
                                      to={`/marketing/banners/${item.banner_id}`}
                                    >
                                      <span
                                        className="dropdown-item"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {}}
                                      >
                                        Editar
                                      </span>
                                    </Link>

                                    <div className="dropdown-divider"></div>
                                    <span
                                      className="dropdown-item"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        toggleModalVisibility();
                                        setBannerIdForDelete(item.banner_id);
                                      }}
                                    >
                                      Excluir
                                    </span>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </>
                        );
                      })}
                    </tbody>
                  </table>
                </LoadingOverlay>

                {!!banners.length && (
                  <div
                    className="w-100 d-flex justify-content-between align-middle"
                    style={{ marginTop: 10, marginBottom: 10 }}
                  >
                    <div style={{ width: 100 }}>
                      <p>{bannersCount} resultados</p>
                    </div>
                    <Pagination
                      totalPages={totalPages}
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                    />
                    <div style={{ width: 100 }}></div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        toggleModalVisibility={toggleModalVisibility}
        visible={isVisible}
        showCloseButton={modalType === "image"}
      >
        {modalType === "image" && (
          <img
            src={bannerImage}
            className="img-fluid"
            alt="Imagem referente ao banner"
          />
        )}

        {modalType === "message" && (
          <div
            className="bg-light rounded d-flex flex-column justify-content-between"
            style={{ height: 160, width: 450, padding: 20 }}
          >
            <div>
              <h5>Tem certeza que deseja excluir o banner?</h5>
              <p>Essa ação não poderá ser desfeita.</p>
            </div>

            <div
              className="w-100 d-flex justify-content-end"
              style={{ gap: 45 }}
            >
              <button
                className="bg-transparent border-0"
                onClick={() => toggleModalVisibility()}
              >
                Cancelar
              </button>
              <button
                className=" text-primary bg-transparent border-0"
                onClick={deleteBanner}
              >
                Excluir
              </button>
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default withRouter(BannersList);

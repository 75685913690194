import React, { useCallback, useRef, useState } from "react";
import { useHistory } from "react-router";

import { decodeToken } from "react-jwt";
import LoadingOverlay from "react-loading-overlay";
import Swal from "sweetalert2";

import { authService } from "services";
import { TwoFactorAuthModal } from "../TwoFactorAuthModal";

import { setLocalStorage } from "../../helpers";
import { useTwoFactorAuth } from "../../useTwoFactorAuth";

LoadingOverlay.propTypes = undefined;

function LoginForm() {
  const history = useHistory();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const inputCodeRef = useRef(null);

  const {
    code,
    setCode,
    twoFactorAuthData,
    setTwoFactorAuthData,
    isTwoFactorAuthModalVisible,
    setIsTwoFactorAuthModalVisible,
    handleVerifyOtpCode,
    isLoadingCodeVerification,
    handleAutoSubmit,
    USER_ALREADY_HAS_TWO_FACTOR_AUTH_INSTALLED,
  } = useTwoFactorAuth();

  const handleAuthentication = useCallback(
    async (username, password) => {
      try {
        setIsLoading(true);

        const response = await authService.signIn(username, password);

        if (!response.status) {
          Swal.fire("Algo deu errado.", response.erro, "error");

          return;
        }

        const token = response.usuario;
        const decodedUserData = decodeToken(token);

        setLocalStorage(decodedUserData, token);

        authService.updateAuthenticationToken({
          apiV1Token: token,
          apiV2Token: decodedUserData.sessao.hash,
        });

        if (!decodedUserData["2fa_otp_uri"]) {
          Swal.fire(
            "Esse usuário não tem permissão para fazer login.",
            "Contate o administrador do sistema.",
            "error"
          );

          return;
        }

        const isAdm = localStorage.getItem("@ADM-Aiboo/nivel") == "R";
        const isRepresentative =
          localStorage.getItem("@ADM-Aiboo/nivel") == "A";

        const shouldAuthenticateWithTwoFactorAuth = isAdm || isRepresentative;

        if (shouldAuthenticateWithTwoFactorAuth) {
          setIsTwoFactorAuthModalVisible(true);
          setTwoFactorAuthData({
            otpUri: decodedUserData["2fa_otp_uri"],
            hasOtpSecret: decodedUserData.has_user_2fa_otp_secret,
          });

          inputCodeRef.current?.focus();

          return;
        }

        history.push("/painel2");
      } catch {
        Swal.fire(
          "Algo deu errado",
          "Não foi possível fazer login, verifique seus dados e tente novamente.",
          "error"
        );
      } finally {
        setIsLoading(false);
      }
    },
    [history, setIsTwoFactorAuthModalVisible, setTwoFactorAuthData]
  );

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleAuthentication(username, password);
    }
  };

  return (
    <>
      <div className="login-area-scroll">
        <LoadingOverlay active={isLoading} spinner text="Carregando...">
          <div className="d-flex flex-column-fluid flex-center mt-6 mt-lg-0 login-area-center">
            <form
              className="form login-area-form"
              noValidate="novalidate"
              id="kt_login_signin_form"
            >
              <div className="mt-lg-10 pb-15">
                <a href="index.html">
                  <center>
                    <img
                      alt="Logo"
                      src="assets/media/logos/aiboo-by-pedeai.png"
                      className="logo-sticky mb-15"
                    />
                  </center>
                </a>

                <h3 className="font-weight-bolder text-dark display5">
                  <i className="icon-xl fas fa-lock" /> Painel Administrativo
                </h3>

                <span className="text-muted font-size-h6">
                  Gerenciamento do Aiboo
                </span>
              </div>

              <div className="form-group">
                <label className="font-size-h6 font-weight-bolder text-dark">
                  Usuário
                </label>

                <input
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  className="form-control form-control-solid h-auto py-5 px-6 rounded-lg"
                  type="text"
                  name="username"
                  autoComplete="off"
                />
              </div>

              <div className="form-group">
                <div className="d-flex justify-content-between mt-n5">
                  <label className="font-size-h6 font-weight-bolder text-dark pt-5">
                    Senha
                  </label>
                </div>

                <input
                  value={password}
                  onKeyDown={handleKeyDown}
                  onChange={(e) => setPassword(e.target.value)}
                  className="form-control form-control-solid h-auto py-5 px-6 rounded-lg"
                  type="password"
                  name="password"
                  autoComplete="off"
                />
              </div>

              <div className="pb-lg-0 pb-10">
                <button
                  onClick={() => handleAuthentication(username, password)}
                  type="button"
                  id="kt_login_signin_submit"
                  className="btn btn-primary btn-block font-weight-bolder btn-lg font-size-h4 px-8 py-4 my-3 mr-3"
                >
                  ACESSAR
                </button>
              </div>
            </form>
          </div>
        </LoadingOverlay>
      </div>

      <TwoFactorAuthModal
        code={code}
        setCode={setCode}
        isTwoFactorAuthModalVisible={isTwoFactorAuthModalVisible}
        setIsTwoFactorAuthModalVisible={setIsTwoFactorAuthModalVisible}
        twoFactorAuthData={twoFactorAuthData}
        handleVerifyOtpCode={handleVerifyOtpCode}
        isLoadingCodeVerification={isLoadingCodeVerification}
        handleAutoSubmit={handleAutoSubmit}
        inputCodeRef={inputCodeRef}
        userAlreadyTwoFactorAuthInstalled={
          USER_ALREADY_HAS_TWO_FACTOR_AUTH_INSTALLED
        }
      />
    </>
  );
}

export default LoginForm;

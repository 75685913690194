import React, { useState, useEffect } from "react";
import Api from "../../../services/api";
import { withRouter } from "react-router";

import Swal from "sweetalert2";
import LoadingOverlay from "react-loading-overlay";

import Select from "react-select";

import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import pt from "date-fns/locale/pt-BR";
import Apiv2 from "../../../services/apiv2";
registerLocale("pt-BR", pt);

const MassMail = () => {
    const [cidadeSelect, setCidadeSelect] = useState([]);
    const [pgOnlineSelect, setPgOnlineSelect] = useState([]);
    const [cidade, setCidade] = useState('T');
    const [pagOn, setPagOn] = useState('T');

    useEffect(() => {
        getCidadeSelect();
        getPagamentoOnline();
    }, []);


    function getCidadeSelect() {
        Api.get("cidades/listar").then((rps) => {
            var cid = rps.data.cidades;
            var nCid = [];
            nCid[nCid.length] = { value: "T", label: "Todas..." };
            cid.forEach((e) => {
                nCid[nCid.length] = {
                    value: e.id_cidade,
                    label: e.nome_cidade + " - " + e.estado,
                };
            });
            setCidadeSelect(nCid);
        });
    }

    function getPagamentoOnline() {
        var nCid = [];
        nCid[nCid.length] = { value: "T", label: "Todas (sim e não)..." };
        nCid[nCid.length] = { value: "S", label: "Sim" };
        nCid[nCid.length] = { value: "N", label: "Não" };
        setPgOnlineSelect(nCid);
    }


    const [merchants, setMerchants] = useState([]);
    const [loadingMerchants, setLoadingMerchants] = useState(false);
    function selectMerchants() {
        var data = {
            cidade: cidade,
            pagOn: pagOn
        };
        setLoadingMerchants(true);
        
        Apiv2.post("/marketing/massMail/listMerchants", data).then((rps) => {
            setLoadingMerchants(false);
            if (rps.data.status !== true) {
                alert(rps.data.message);
            }
            setMerchants(rps.data.estabelecimentos);
            setSelectedMerchants([]);
        })
    }

    const [idTemplate, setIdTemplate] = useState('');
    const [loadingSender, setLoadingSender] = useState(false);
    const [selectedMerchants, setSelectedMerchants] = useState([]);
    function sender() {
        var data = {
            merchants: JSON.stringify(selectedMerchants),
            idTemplate: idTemplate
        };

        setLoadingSender(true)
        
        Apiv2.post("/marketing/massMail/send", data).then((rps) => {
            setLoadingSender(false)
            if (rps.data.status !== true) {
                alert(rps.data.message);
            } else {
                Swal.fire({
                    title: "Sucesso!",
                    icon: "success",
                    html: rps.data.message,
                    showCloseButton: true,
                    showCancelButton: false,
                  });
                setSelectedMerchants([]);
                setMerchants([]);
            }
            setMerchants([]);
            setSelectedMerchants([]);
        })

    }





    return (
        <>            <LoadingOverlay
              active={loadingMerchants}
              spinner
              text='Loading your content...'
              >
            <div className="d-flex flex-column-fluid">

                <div className="col-md-4 form-group">
                    <label>Cidade</label>
                    <Select
                        options={cidadeSelect}
                        placeholder="Selecione..."
                        defaultValue={cidadeSelect.find(
                            x => x.value === cidade
                        )}
                        onChange={(e) => { setCidade(e.value) }}

                    />
                    <label>Pagamento online</label>
                    <Select
                        options={pgOnlineSelect}
                        placeholder="Selecione..."
                        defaultValue={pgOnlineSelect.find(
                            x => x.value === pagOn
                        )}
                        onChange={(e) => { setPagOn(e.value) }}

                    />
                </div>
                <button onClick={e => { selectMerchants(e) }}>Buscar</button>

            </div>
                </LoadingOverlay>

                <LoadingOverlay
              active={loadingSender}
              spinner
              text='Loading your content...'
              >
            {merchants.length > 0 &&
                <>
                    <div style={{ width: '80%', height: '200px', overflowY: 'scroll' }}>
                        <table ng-show="receipts.length" className="table table-hover ng-scope" ng-init="propertyName='value';reverse=false;" style={{}}>
                            <thead>
                                <tr>
                                    <th role="button">
                                    <input
                                        type="checkbox"
                                        checked={selectedMerchants.length===merchants.length ? true : false}
                                        onClick={e => {
                                            var se = [];
                                            if(selectedMerchants.length===merchants.length){
                                                se = [];
                                            } else {
                                                merchants.forEach(merchant => {
                                                    se.push(merchant.id_estabelecimento)
                                                });
                                            }
                                            setSelectedMerchants(se);
                                        }}
                                        />
                                    </th>
                                    <th role="button">ID</th>
                                    <th role="button">Nome</th>
                                    <th role="button">Email</th>
                                </tr>
                            </thead>
                            <tbody>
                                {merchants.map(merchant => {
                                    return (
                                        <tr>
                                            <td>
                                                <input
                                                    type="checkbox"
                                                    checked={selectedMerchants.indexOf(merchant.id_estabelecimento)!==-1 ? true : false}
                                                    onClick={e => {
                                                        var se = JSON.parse(JSON.stringify(selectedMerchants))
                                                        if(se.indexOf(merchant.id_estabelecimento)===-1){
                                                            se.push(merchant.id_estabelecimento)
                                                        } else {
                                                            const index = se.indexOf(5);
                                                            se.splice(index, 1);
                                                        }
                                                        setSelectedMerchants(se);
                                                    }}
                                                />
                                            </td>
                                            <td>{merchant.id_estabelecimento}</td>
                                            <td>{merchant.nome}</td>
                                            <td>{merchant.email}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                    Encontrados {merchants.length} estabelecimentos
                    <br />
                    <br />
                    <label>ID template SENDGRID</label>
                    <input type="text" value={idTemplate} onChange={e => {setIdTemplate(e.target.value)}}></input>
                    <br />
                    <br />
                    <br />
                    <br />
                    <button onClick={e => {sender();}}>Enviar emails</button>

                </>
            }

            </LoadingOverlay>




        </>
    );
};

export default withRouter(MassMail);

import styled from "styled-components";
import Select from "react-select";

export const Container = styled.div`
  span {
    display: block;
    color: #ff3333;
    margin-top: 10px;
  }
`;

export const DropDownInputButton = styled(Select)`
  text-align: center;
  font-size: 16px;
`;

export const AddCategoryTextButton = styled.button`
  display: flex;
  align-items: center;
  border: none;
  background: none;
  width: fit-content;
  margin-top: 18px;
  font-weight: 600;

  i {
    color: #ff3333;
    text-decoration: none;
    margin-right: 8px;
    cursor: pointer;
  }

  label {
    font-size: 14px;
    color: #ff3333;
    text-decoration: underline;
    cursor: pointer;
    margin: 0;
  }
`;

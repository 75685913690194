import React, { useState, useEffect } from 'react';
//Socket.io
import io from 'socket.io-client';

// Timer
import Countdown from "react-countdown";



import {useToasts } from 'react-toast-notifications';

import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { withRouter } from "react-router";
import Notifier from "react-desktop-notifications";

import { FormControlLabel, Checkbox } from "@material-ui/core";

import Api from '../../services/api';

// Detalhes do pedido
import Details from './details';

// MP3
import NotificationRing from '../../audio/notification-large.mp3';
import { Container } from './styles';

const Pedidos = () => {
    const { addToast } = useToasts();

    const [ loadingPrincipal, setLoadingPrincipal ] = useState(false);

    const [ orders, setOrders ] = useState([]);

    const [ , setPedidosPendentes ] = useState(0)

    let mp3 = new Audio(NotificationRing);
    const [ audio, ] = useState(mp3);

    const [ searchParameter, setSearchParameter ] = useState('');

    // const [ printerError, setPrinterError ] = useState(false);

    const [ orderSelected, setOrderSelected ] = useState({});

    const [ orderToUpdate, setOrderToUpdate ] = useState({});
    const [ orderToAdd, setOrderToAdd ] = useState({});

    // const [ countDownPedidos, setCountDownPedidos ] = useState({});

    const [ socketConnectionStatus, setSocketConnectionStatus ] = useState({});

    const [ pagamentoOnline ] = useState();

    const [ modal, setModal ] = useState(false);
    const [ avisoExibir, setAvisoExibir ] = useState(false);

    function fecharModal(){
      setModal(false);
    }

    function getAvisoAlterar(){

      Api.get('estabelecimento/alteraAviso').then(rps => { });
    }

    useEffect(() =>{
      getConnectionAndOrders();
      //getStoreInfo();
      getDefaultPrinter();
      connectToSocket();
      getConfigs();
      playMp3();

      Notification.requestPermission(/* opcional: callback */);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [ selectedPrinter, setSelectedPrinter ] = useState('');
    const [ printers ] = useState([]);


    const [ pedidoAutoAceiteImprimir, setPedidoAutoAceiteImprimir ] = useState();
    const [ autoAceiteImprimir, setAutoAceiteImprimir ] = useState(false);
    async function getConfigs(){
      var autoAceiteImprimir = localStorage.getItem('autoAceiteImprimir');
      if(autoAceiteImprimir==='true'){
        setAutoAceiteImprimir(true);
      }

    }
    async function getDefaultPrinter(){
      if (localStorage.getItem('impressora')){
        setSelectedPrinter(localStorage.getItem('impressora'))
      }
    }

    // async function getPrinters() {
    //   try {
    //     const response = await Axios.get('http://127.0.0.1:65517/impressoras');
    //     const printersResponse = response.data.impressoras;
    //     const printersArray = [];

    //     for (let index = 0; index < printersResponse.length; index++) {
    //       printersArray.push({"nome": printersResponse[index]})
    //     }

    //     setPrinters(printersArray)
    //   } catch (error) {
    //     setPrinterError(true)
    //     Swal.fire({
    //       title: "Atenção!",
    //       icon: "info",
    //       html: 'Módulo da impressora não encontrado ',
    //       showCloseButton: true,
    //       showCancelButton: false
    //     });
    //   }

    // }

    function notificacaoDesktop(texto){
      var notification = new Notification("Aiboo", {
          //icon: 'http://i.stack.imgur.com/dmHl0.png',
          body: texto
      });
      console.log(notification)
    }

    function choosePrinter(printerName){
      setSelectedPrinter(printerName);
      localStorage.setItem("impressora", printerName)
    }

    const PrintersComponent = () => {
      return (
        <>
          <select className="form-control" value={selectedPrinter} onChange={(e) => choosePrinter(e.target.value)}>
            {
              printers.map((item) =>{
                return(
                  <option key={item.nome} value={item.nome}>{item.nome}</option>
                )
              })
            }
          </select>
        </>
      )
    }

    async function connectToSocket(){
      //const channel = localStorage.getItem('canal_socket');
      //const url = localStorage.getItem('url_socket');
      const response = await Api.get('pedidos/getSocket');
      if(response.data?.status === true){
        console.log(response.data.canal);
        var channel = response.data.canal.canal;
        var url = response.data.canal.url;
        openConnectionWithSocket(channel, url);
      }
    }


    async function getConnectionAndOrders() {
      setLoadingPrincipal(true)
      const response = await Api.get('pedidos/listar');
      if(response.data?.status === true){
        //Tirei a abertura de conexão para/com o socket daqui.
        setOrders(response.data.pedidos);
        setLoadingPrincipal(false)
      }

    }

    useEffect(() => {
      orders.forEach(e => {
        if(e.hash_pedido===orderSelected?.hash_pedido){
          e.classpedidoatraso = ' pedido-novo-atraso';
          e.classpedido = ' pedido-selecionado';
          setOrderSelected(e);
        } else {
          e.classpedido = '';
          e.classpedidoatraso = '';
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orders]);

    function getDetailsOrder(id, order){
      orders.forEach(e => {
        if(e.hash_pedido===order.hash_pedido){
          e.classpedidoatraso = ' pedido-novo-atraso';
          e.classpedido = ' pedido-selecionado';
          setOrderSelected(e);
        } else {
          e.classpedidoatraso = '';
          e.classpedido = '';
        }
      });
    }



    useEffect(() =>{
      var nOrders = [];
      if(orderToAdd.hash_pedido){
        notificacaoDesktop('Novo pedido '+orderToAdd.hash_pedido);
        if(autoAceiteImprimir===true){
          setPedidoAutoAceiteImprimir(orderToAdd);
        }
        nOrders[nOrders.length] = orderToAdd;
        orders.forEach(o => {
          nOrders[nOrders.length] = o;
        });
        setOrders(nOrders);
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderToAdd]);

    function getThisNewOrder(data){
      Api.get('pedidos/listar/' + data.id_pedido).then(response => {
        if(response.data.status === true){
          setOrderToAdd(response.data.pedidos[0]);
          //orders.unshift(response.data.pedidos[0])
          // playRingMP3();
          //getConnectionAndOrders()
        }
      })
    }

    // const [ , setEstabilishmentConfig ] = useState({
    //   entrega_minimo: '', entrega_maximo: '', fechado: false, nome: ''
    // })


    // async function updateDeliveryTime(min, max){
    //   const response = await Api.post('estabelecimento/mudaPrevisao', {
    //     entrega_minimo: min,
    //     entrega_maximo: max
    //   })
    //   if (response.data.status !== undefined && response.data.status === true) {
    //     addToast(response.data.mensagem, {
    //       appearance: "success",
    //       autoDismiss: true,
    //       autoDismissTimeout: 2000
    //     });
    //   } else {
    //     Swal.fire({
    //       title: "Atenção!",
    //       icon: "info",
    //       html: response.data.mensagem,
    //       showCloseButton: true,
    //       showCancelButton: false
    //     });
    //   }
    //   //getStoreInfo();
    // }

    // async function getStoreInfo(){
    //   const response =  await Api.get('estabelecimento/getLoja')
    //   setEstabilishmentConfig({
    //     fechado: response.data.estabelecimento['fechado'],
    //     nome: response.data.estabelecimento['nome'],
    //     entrega_minimo: response.data.estabelecimento['entrega_minimo'],
    //     entrega_maximo:response.data.estabelecimento['entrega_maximo']
    //   })
    //   setPagamentoOnline(response.data.estabelecimento['zoop_status'],)

    //   if (response.data.estabelecimento['aviso'] === 'S') {
    //     setModal(true);
    //   }
    // }

    async function acceptNewOrder(){
        stopRingMp3()
    }


    useEffect(() =>{
      var nOrders = [];
      orders.forEach(o => {
        if(o.hash_pedido===orderToUpdate?.hash_pedido){
          nOrders[nOrders.length] = orderToUpdate;
        } else {
          nOrders[nOrders.length] = o;
        }
      });
      setOrders(nOrders);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderToUpdate]);

    function updateOrder(data){
      Api.get('pedidos/listar/' + data.id_pedido).then((response) => {
        if(response.data.status === true) {
          setOrderToUpdate(response.data.pedidos[0]);
          //console.log(orders);
          //orders.unshift(response.data.pedidos[0]);
        }
      });
      //getConnectionAndOrders()
    }

    function openConnectionWithSocket(channel, url) {
      console.log(channel, url);
        const socket = io(url, {
          reconnection: true,
          reconnectionDelay: 1000,
          reconnectionDelayMax : 5000,
          reconnectionAttempts: Infinity
        });
        socket.on('connect', (data) =>{
          console.log("Socket conectado");
          socket.emit('room', channel);
          setSocketConnectionStatus('conexaoOk');
        });
        socket.on('disconnect', (data)=>{
          console.log("Socket DESCONECTADO");
          setSocketConnectionStatus('conexaoNok');
          addToast("Perda de conexão com o servidor", {
            appearance: "error",
            autoDismiss: true,
            autoDismissTimeout: 5000
          });
          //Notificar
        })
        socket.on('pedido', (data) => {
            if(data.tipo === 'novo'){
              getThisNewOrder(data);
            } else if(data.tipo === 'atualiza'){
              updateOrder(data)
            } else if(data.tipo === 'f5pagina'){
              getConnectionAndOrders();
            } else if(data.tipo === 'mensagem'){
              alert(data.mensagem);
            }
        })
    }

    function playMp3(){
      var contagemPedidos = 0;
      orders.forEach(e => {
        if(e.status==='PP'){
          contagemPedidos++;
        }
      });


      if (contagemPedidos > 0){
          audio.play();
        } else {
          audio.pause();
        }
    }

    function stopRingMp3(){
      audio.pause();
    }

    const DeliveryTime = () => {
      return (
        <>
        

        </>
      )
    }

    // Modal Actions
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    // function handleShow(){
    //   getPrinters();
    //   if (printerError){
    //     Swal.fire({
    //       title: "Atenção!",
    //       icon: "info",
    //       html: 'Erro no módulo de impressão',
    //       showCloseButton: true,
    //       showCancelButton: false
    //     });
    //   } else {
    //     setShow(true)
    //   }
    // }

    function NewNotification(){
      Notifier.start("Aiboo Parceiro","Chegou um novo pedido!", "http://localhost:3000/#/pedidos");
    }

    const LoadingTheme = () => {
      if (loadingPrincipal){
        return <div className="spinner spinner-primary spinner-center mt-10"></div>
      }
      return (
        <>
          <p onClick={() => NewNotification()} className="text-center p-10">Não há nenhum pedido pendente no momento...</p>
            <center>
              <img alt="sem pedidos pendentes" src="assets/media/pedidos-img.png" style={{width: "140px", textAlign: 'center'}}></img>
            </center>
        </>
      )
    }


    const OrdersComponent =() => {
      playMp3();

      let aceitos    = [];
      let pendentes  = [];
      let enviados   = [];
      let entregues  = [];
      let cancelados = [];

      for (let order of orders) {
        if (order.status === 'PA' ||  order.status === 'PI') {
          aceitos.push(order)
        }
        if (order.status === 'PP') {
          pendentes.push(order)
          setPedidosPendentes(pendentes.length)
        }
        // if (order.status === 'PI') {
        //   impressos.push(order)
        // }
        if (order.status === 'PE' || order.status === 'PICKUP_READY') {
          enviados.push(order)
        }
        if (order.status === 'PD') {
          entregues.push(order)
        }
        if (order.status === 'PC') {
          cancelados.push(order)
        }
      }

      let filterPendentes = pendentes.filter( (item) => {
        return (item.pedido.toLowerCase()).indexOf(searchParameter.toLowerCase()) !== -1
        }
      )

      const PedidosPendentes = () => {
        if (pendentes.length > 0) {
          return filterPendentes.map( (item, index, arr) => {
            return (
              <React.Fragment key={index}>
                <div className={"pedido-novo " + item?.classpedido}>
                  <div onClick={() => getDetailsOrder(item.id_pedido, item)} key={item.hash_pedido} className="row">
                    <div className="col-md-3">
                      <p className="texto-1">{item.hash_pedido}</p>
                      <p className="texto-2">{item.data_criacao}</p>
                    </div>
                    <div className="col-md-6">
                      <p className="texto-1">{item.usuario_nome}</p>
                      { item.compraGlobal === '1' &&
                      <>
                        <p className="cliente-novo" data-toggle="tooltip" title="" data-original-title="Cliente novo no App! Não deixe de dar aquela caprichada ;)"><i className="fas fa-user-plus"></i> Cliente novo no App!</p>
                      </>
                      }
                      { item.compraGlobal !== '1' && item.compraEst === '1' &&
                        <>
                          <p className="cliente-novo-loja" data-toggle="tooltip" title="" data-original-title="Cliente novo! Primeira compra dele em sua loja. Não deixe de dar aquela caprichada ;)"><i className="fas fa-trophy"></i> Cliente novo na Loja!</p>
                        </>
                      }
                      <p className="texto-2">{item.valor_total} - {item.estabelecimento_nome}
                      {/* NAO TEM PARAMETRO PRA VER SE TEM DESCONTO */}
                      {/* <span className="label label-sm label-info label-inline ml-2" data-toggle="tooltip" title="Esse desconto será abatadido automaticamente da sua fatura, pois é concedida pelo AIBOO. Cobre do cliente SOMENTE o valor de R$XX.">DESCONTO</span> */}
                      </p>
                    </div>
                    <div className="col-md-3">
                      <p className="texto-andamento timer"><i className="far fa-clock icon-timer" />
                      <Countdown
                        renderer={({ hours, minutes, seconds, completed }) => {
                            let tempoFormatado = ("00"+minutes).slice(-2)+":"+("00"+seconds).slice(-2);
                            return <span>{tempoFormatado}</span>;
                        }}
                        date={Date.now() + (item.segundos*1000)}>
                      </Countdown>
                      </p>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            )
          })
        }
        return (
          <>
            <LoadingTheme />

          </>
        )
      }

      let filterAceitos = aceitos.filter( (item) => {
        return (item.pedido.toLowerCase()).indexOf(searchParameter.toLowerCase()) !== -1
        }
      )

      const PedidosAceitos = () => {
        if (aceitos.length > 0) {
          return filterAceitos.map( (item) => {
            return (
              <React.Fragment key={item.hash_pedido}>
                <div className={"pedido pedido-normal "  + item?.classpedido}>
                  <div  onClick={() => getDetailsOrder(item.id_pedido, item)} className="row">
                    <div className="col-md-3">
                      <p className="texto-1">{item.hash_pedido}</p>
                      <p className="texto-2">{item.data_criacao}</p>
                    </div>
                    <div className="col-md-6">
                      <p className="texto-1">{item.usuario_nome}</p>
                      { item.compraGlobal === '1' &&
                      <>
                        <p className="cliente-novo" data-toggle="tooltip" title="" data-original-title="Cliente novo no App! Não deixe de dar aquela caprichada ;)"><i className="fas fa-user-plus"></i> Cliente novo no App!</p>
                      </>
                      }
                      { item.compraGlobal !== '1' && item.compraEst === '1' &&
                        <>
                          <p className="cliente-novo-loja" data-toggle="tooltip" title="" data-original-title="Cliente novo! Primeira compra dele em sua loja. Não deixe de dar aquela caprichada ;)"><i className="fas fa-trophy"></i> Cliente novo na Loja!</p>
                        </>
                      }
                      <p className="texto-2">{item.valor_total} - {item.estabelecimento_nome} </p>
                    </div>
                    <div className="col-md-3">
                      {item.status==='PA' &&
                      <p className="texto-andamento aceito">ACEITO</p>
                      }
                      {item.status==='PI' &&
                      <p className="texto-andamento impresso">IMPRESSO</p>
                      }
                      {/* <Spinner animation="border" variant="primary" /> */}
                    </div>
                  </div>
                </div>
              </React.Fragment>
            )
          })
        }
        return (
          <>
            <p className="text-center p-10">Não há nenhum pedido aceito...</p>
          </>
        )
      }

      let filterEnviados = enviados.filter( (item) => {
        return (item.pedido.toLowerCase()).indexOf(searchParameter.toLowerCase()) !== -1
        }
      )

      const PedidosEnviados = () => {
        if (enviados.length > 0 ) {
          return filterEnviados.map( item => {

            return (
              <React.Fragment key={item.hash_pedido}>
                <div onClick={() => getDetailsOrder(item.id_pedido, item)} className={"pedido pedido-normal "  + item?.classpedido}>
                  <div className="row">
                    <div className="col-md-4">
                      <p className="texto-1">{item.hash_pedido}</p>
                      <p className="texto-2">{item.data_criacao}</p>
                    </div>
                    <div className="col-md-6">
                      <p className="texto-1">{item.usuario_nome}</p>
                      { item.compraGlobal === '1' &&
                      <>
                        <p className="cliente-novo" data-toggle="tooltip" title="" data-original-title="Cliente novo no App! Não deixe de dar aquela caprichada ;)"><i className="fas fa-user-plus"></i> Cliente novo no App!</p>
                      </>
                      }
                      { item.compraGlobal !== '1' && item.compraEst === '1' &&
                        <>
                          <p className="cliente-novo-loja" data-toggle="tooltip" title="" data-original-title="Cliente novo! Primeira compra dele em sua loja. Não deixe de dar aquela caprichada ;)"><i className="fas fa-trophy"></i> Cliente novo na Loja!</p>
                        </>
                      }
                      <p className="texto-2">{item.valor_total} - {item.estabelecimento_nome} </p>
                    </div>
                    <div className="col-md-2">
                      {item.pedidoObj?.retirada_local ? (
                        <p className="texto-andamento enviado">PRONTO PARA RETIRADA</p>
                      ) : (
                        <p className="texto-andamento enviado">ENVIADO</p>
                      )}
                    </div>
                  </div>
                </div>
              </React.Fragment>
            )
          })
        }
        return (
          <>
            <p className="text-center p-10">Não há nenhum pedido enviado ou pronto para retirada...</p>
          </>
        )
      }

      let filterEntregues = entregues.filter( (item) => {
        return (item.pedido.toLowerCase()).indexOf(searchParameter.toLowerCase()) !== -1
        }
      )


      const PedidosEntregues = () => {
        if (entregues.length > 0) {
          return filterEntregues.map( item => {
            return (
              <React.Fragment key={item.hash_pedido}>
                <div onClick={() => getDetailsOrder(item.id_pedido, item)} className={"pedido pedido-normal "  + item?.classpedido}>
                  <div className="row">
                    <div className="col-md-4">
                      <p className="texto-1">{item.hash_pedido}</p>
                      <p className="texto-2">{item.data_criacao}</p>
                    </div>
                    <div className="col-md-6">
                      <p className="texto-1">{item.usuario_nome}</p>
                      { item.compraGlobal === '1' &&
                      <>
                        <p className="cliente-novo" data-toggle="tooltip" title="" data-original-title="Cliente novo no App! Não deixe de dar aquela caprichada ;)"><i className="fas fa-user-plus"></i> Cliente novo no App!</p>
                      </>
                      }
                      { item.compraGlobal !== '1' && item.compraEst === '1' &&
                        <>
                          <p className="cliente-novo-loja" data-toggle="tooltip" title="" data-original-title="Cliente novo! Primeira compra dele em sua loja. Não deixe de dar aquela caprichada ;)"><i className="fas fa-trophy"></i> Cliente novo na Loja!</p>
                        </>
                      }
                      <p className="texto-2">{item.valor_total} - {item.estabelecimento_nome} </p>
                    </div>
                    <div className="col-md-2">
                      <p className="texto-andamento entregue">ENTREGUE</p>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            )
          })
        }
        return (
          <>
            <p className="text-center p-10">Não há nenhum pedido entregue...</p>
          </>
        )
      }

      let filterCancelados = cancelados.filter( (item) => {
        return (item.pedido.toLowerCase()).indexOf(searchParameter.toLowerCase()) !== -1
        }
      )


      const PedidosCancelados = () => {
        if (cancelados.length > 0) {
          return filterCancelados.map( item => {
            return (
              <React.Fragment key={item.hash_pedido}>
                <div onClick={() => getDetailsOrder(item.id_pedido, item)} className={"pedido pedido-normal "  + item?.classpedido}>
                  <div className="row">
                  <div className="col-md-4">
                      <p className="texto-1">{item.hash_pedido}</p>
                      <p className="texto-2">{item.data_criacao}</p>
                    </div>
                    <div className="col-md-6">
                      <p className="texto-1">{item.usuario_nome}</p>
                      { item.compraGlobal === '1' &&
                      <>
                        <p className="cliente-novo" data-toggle="tooltip" title="" data-original-title="Cliente novo no App! Não deixe de dar aquela caprichada ;)"><i className="fas fa-user-plus"></i> Cliente novo no App!</p>
                      </>
                      }
                      { item.compraGlobal !== '1' && item.compraEst === '1' &&
                        <>
                          <p className="cliente-novo-loja" data-toggle="tooltip" title="" data-original-title="Cliente novo! Primeira compra dele em sua loja. Não deixe de dar aquela caprichada ;)"><i className="fas fa-trophy"></i> Cliente novo na Loja!</p>
                        </>
                      }
                      <p className="texto-2">{item.valor_total} - {item.estabelecimento_nome} </p>
                    </div>
                    <div className="col-md-2">
                      <p className="texto-andamento cancelado">CANCELADO</p>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            )
          })
        }
        return (
          <>
            <p className="text-center p-10">Não há nenhum pedido cancelado...</p>
          </>
        )
      }

      return (
        <>
          <div className="divisor divisor-tabela"><span className="text-dark-50 font-weight-bold">Pendente</span></div>
          <PedidosPendentes />
          <div className="divisor divisor-tabela"><span className="text-dark-50 font-weight-bold">Aceito/Produção</span></div>
          <PedidosAceitos />
          <div className="divisor divisor-tabela"><span className="text-dark-50 font-weight-bold">Enviado(s)/Pronto para retirada</span></div>
          <PedidosEnviados />
          <div className="divisor divisor-tabela"><span className="text-dark-50 font-weight-bold">Entregues</span></div>
          <PedidosEntregues />
          <div className="divisor divisor-tabela"><span className="text-dark-50 font-weight-bold">Cancelados</span></div>
          <PedidosCancelados />
        </>
      )
    }

    return(
       <>
        <Container className="content d-flex flex-column flex-column-fluid" id="kt_content">
          <div className="subheader espaco-header-pedidos subheader-transparent" id="kt_subheader">
            <div className="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
              <div className="d-flex align-items-center flex-wrap mr-2">
                <h5 className="text-dark font-weight-bold mt-2 mb-2 mr-5">Pedidos</h5>
                <div className="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200" />
                <div className="d-flex align-items-center" id="kt_subheader_search">
                  <form>
                    <div className="input-group input-group-sm input-group-solid" style={{maxWidth: '220px'}}>
                      <input onChange={(e) => setSearchParameter(e.target.value)} type="text" className="form-control" id="kt_subheader_search_form" placeholder="Procurar..." />
                      <div className="input-group-append">
                        <span className="input-group-text">
                          <span className="svg-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                              <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                <rect x={0} y={0} width={24} height={24} />
                                <path d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z" fill="#000000" fillRule="nonzero" opacity="0.3" />
                                <path d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z" fill="#000000" fillRule="nonzero" />
                              </g>
                            </svg>
                          </span>
                        </span>
                      </div>
                    </div>
                  </form>
                  {/* <div className="form-group align-filtro-pedidos">
                    <div className="checkbox-inline">
                      <label className="checkbox checkbox-square checkbox-primary">
                        <input type="checkbox" defaultChecked="checked"/> Em Andamento
                        <span /></label>
                      <label className="checkbox checkbox-square checkbox-primary">
                        <input type="checkbox" />Entregues
                        <span /></label>
                      <label className="checkbox checkbox-square checkbox-primary">
                        <input type="checkbox" />Cancelados
                        <span /></label>
                    </div>
                  </div> */}
                </div>
                {/*end::Search Form*/}
              </div>
              <div className="d-flex align-items-center">
  
                {(pagamentoOnline === '' || pagamentoOnline === null) && <span class="label label-secondary label-inline mr-2">Pagamento Online - Desativado</span>}
                {pagamentoOnline === 'PENDING' && <span class="label label-warning label-inline mr-2">Pagamento Online - Pendente</span>}
                {(pagamentoOnline === 'ENABLED' || pagamentoOnline === 'ACTIVATED') && <span class="label label-success label-inline mr-2">Pagamento Online - Ativo</span>}

                <button className={"btn btn-sm"}>
                  <i className={"fa fa-plug "+socketConnectionStatus} />
                </button>


                {/*<button className={"btn btn-sm"} onClick={() => handleShow()}>
                  <i className="fa fa-print" />
                </button>*/}
                <DeliveryTime/>
              </div>
            </div>
          </div>
          <div className="d-flex flex-column-fluid">
            <div className="container">
              <div className="container-pedidos">
                <div className="column-3 kt-portlet kt-portlet--height-fluid" id="style-1">
                  <div className="table-div-mesas kt-portlet__body npm" id="style-1">
                      <OrdersComponent />
                  </div>
                </div>
                  <div className="column-6 kt-portlet kt-portlet--height-fluid">
                      <Details pedido={orderSelected} printer={selectedPrinter} accept={acceptNewOrder} pedidoAutoAceiteImprimir={pedidoAutoAceiteImprimir} />
                  </div>
              </div>
            </div>
          </div>

        </Container>

        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Selecione a impressora padrão</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            {selectedPrinter ? 'Impressora selecionada: ' + selectedPrinter : 'Nenhuma Impressora selecionada'}
            <PrintersComponent />
          </Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>Cancelar</Button>
            <Button variant="primary" onClick={handleClose}>Salvar</Button>
          </Modal.Footer>
        </Modal>

        <Modal size={'xl'} show={modal} onHide={() => fecharModal()}>
          <Modal.Header closeButton>
            <Modal.Title>Pagamento Online - Cadastro</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <p>Querido Franqueado,</p>
            <p>E com grande satisfação que o Aiboo está disponizando o Cadastro para o Pagamento Online</p>
            <p>Estamos fazendo isso para (pensamento) bla bla bla bla bla</p>
            <p>Dúvidas ligue: (27) 4042-1580</p>
            <p className="text-right"><FormControlLabel
              control={<Checkbox checked={avisoExibir} onChange={e=>{ setAvisoExibir(e.target.checked); getAvisoAlterar()}} />}
              label="Não exibir este aviso novamente."
            /></p>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="primary" onClick={fecharModal}>Fechar</Button>
          </Modal.Footer>
        </Modal>   
       </>
    )
}

export default withRouter(Pedidos);

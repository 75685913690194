import apiV1 from "../api";
import apiV2 from "../api-laravelv2";

import secureLocalStorage from "react-secure-storage";

const signIn = async (username, password) => {
  const response = await apiV1.post("login/index", {
    usuario: username,
    senha: password,
    ambiente: "ADM",
  });

  return response.data;
};

const verifyOtp = async (code) => {
  const response = await apiV2.post("users/2fa/verify-otp", { code });

  return response.data;
};

const updateAuthenticationToken = ({ apiV1Token, apiV2Token }) => {
  apiV1.defaults.headers["Authorization"] = `Basic ${apiV1Token}`;
  apiV2.defaults.headers["Authorization"] = apiV2Token;
};

const updateTwoFactorAuthToken = (token) => {
  apiV1.defaults.headers["x-2fa-token"] = token;
  apiV2.defaults.headers["x-2fa-token"] = token;

  secureLocalStorage.setItem("@ADM-Aiboo/2fa-token", token);
};

const removeAuthenticationToken = () => {
  apiV1.defaults.headers["Authorization"] = null;
  apiV2.defaults.headers["Authorization"] = null;

  secureLocalStorage.setItem("@ADM-Aiboo/2fa-token", null);
};

const removeTwoFactorAuthToken = () => {
  apiV1.defaults.headers["x-2fa-token"] = null;
  apiV2.defaults.headers["x-2fa-token"] = null;
};

const setUserIpHeader = (userIp) => {
  apiV1.defaults.headers["x-user-ip"] = userIp;
  apiV2.defaults.headers["x-user-ip"] = userIp;
};

export const authService = {
  signIn,
  verifyOtp,
  updateAuthenticationToken,
  updateTwoFactorAuthToken,
  removeAuthenticationToken,
  removeTwoFactorAuthToken,
  setUserIpHeader,
};

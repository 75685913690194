import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  strong {
    font-weight: 400;
    display: block;
    margin-top: 6px;
    color: #ff3333;
  }
`;

export const Label = styled.label`
  font-weight: 600;
  font-style: normal;
  font-size: ${({ labelSize }) => labelSize}px;

  line-height: 22px;

  display: flex;
  flex: 1;
  align-items: center;
  text-align: right;

  color: #333333;

  display: flex;
  gap: 4px;

  span {
    color: #ff3333;
  }
`;

export const InputContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ rightSideElement }) => (rightSideElement ? 10 : 0)}px;

  width: 100%;
  height: 44px;
  border: 1px solid ${({ error }) => (error ? "#ff3333" : "#E5EAEE")};
  border-radius: 12px;
  padding: 0 10px;

  &:focus {
    outline: 0;
    border-color: ${({ error }) => (error ? "#ff3333" : "#aaa")};
  }
`;

export const Input = styled.input`
  width: 100%;
  height: 100%;
  border: 0;
  outline: 0;
  max-width: ${({ maxWidth }) => (maxWidth ? `${maxWidth}px` : undefined)};

  &::placeholder {
    color: "#E5EAEE";
    font-weight: 400;
    font-size: 12px;
    opacity: 1;
  }

  &::-ms-input-placeholder {
    color: "#E5EAEE";
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type="number"] {
    appearance: unset;
    -moz-appearance: textfield;
  }
`;

import QRCode from "react-qr-code";

import { Modal } from "components";

import Button from "components/Button";
import { Header } from "./components/Header";
import { Root } from "./components/Root";

import * as Styled from "./styles";

export const TwoFactorAuthModal = ({
  code,
  setCode,
  isTwoFactorAuthModalVisible,
  setIsTwoFactorAuthModalVisible,
  twoFactorAuthData,
  handleVerifyOtpCode,
  isLoadingCodeVerification,
  handleAutoSubmit,
  inputCodeRef,
  userAlreadyTwoFactorAuthInstalled,
}) => {
  const headerTitle = userAlreadyTwoFactorAuthInstalled
    ? "Realize a autenticação de dois fatores"
    : "Configure a autenticação de dois fatores";

  const headerDescription = userAlreadyTwoFactorAuthInstalled
    ? "Insira o código disponível no seu aplicativo de autenticação. "
    : "Leia o QR Code abaixo no seu aplicativo de autenticação de dois fatores";

  return (
    <Modal
      visible={isTwoFactorAuthModalVisible}
      toggleModalVisibility={() =>
        setIsTwoFactorAuthModalVisible((prevState) => !prevState)
      }
    >
      <Root>
        <Header
          title={headerTitle}
          description={headerDescription}
          onClose={() => setIsTwoFactorAuthModalVisible(false)}
        />

        {!userAlreadyTwoFactorAuthInstalled && (
          <Styled.QRCodeContainer>
            <QRCode value={twoFactorAuthData.otpUri} size={205} />
          </Styled.QRCodeContainer>
        )}

        <Styled.Input
          placeholder="Digite o código"
          value={code}
          onChange={(event) => setCode(event.target.value)}
          maxLength={6}
          type="numeric"
          onKeyDown={handleAutoSubmit}
          ref={inputCodeRef}
        />

        <Styled.Footer>
          <Styled.ButtonsFooter>
            <a
              href="https://support.google.com/accounts/answer/1066447?hl=pt-BR&co=GENIE.Platform%3DAndroid&oco=0"
              target="_blank"
              rel="noreferrer"
            >
              <Styled.Label>Não tenho aplicativo de autenticação</Styled.Label>
            </a>
          </Styled.ButtonsFooter>

          <Styled.Buttons>
            <Button
              type="button"
              variant="danger"
              onClick={() => setIsTwoFactorAuthModalVisible(false)}
            >
              <Styled.ButtonLabel>Cancelar</Styled.ButtonLabel>
            </Button>

            <Button
              type="button"
              isLoading={isLoadingCodeVerification}
              disabled={isLoadingCodeVerification}
              onClick={handleVerifyOtpCode}
            >
              <Styled.ButtonLabel>Confirmar</Styled.ButtonLabel>
            </Button>
          </Styled.Buttons>
        </Styled.Footer>
      </Root>
    </Modal>
  );
};

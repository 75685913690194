import React from "react";
import Axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const SwalRC = withReactContent(Swal);
const Api = Axios.create({
  baseURL: process.env.REACT_APP_API_BASEURL_COUPON,
  headers: {
    "content-type": "application/json",
    Authorization: localStorage.getItem("@ADM-Aiboo/hash")
      ? localStorage.getItem("@ADM-Aiboo/hash")
      : "null",
  },
});

Api.interceptors.response.use(
  (response) => {
    const statusCode = response.data.status;
    // Verificando código se houver error
    if (!statusCode) {
      switch (response.data.code) {
        case 401:
          //case 999999:
          localStorage.clear();
          SwalRC.fire({
            type: "error",
            title: response.data.erro,
            html: "É necessário fazer o login novamente.",
            footer: (
              <a className="btn btn-primary btn-sm" href="index.html">
                Fazer Login
              </a>
            ),
            allowOutsideClick: false,
            showConfirmButton: false,
          });

          break;
        default:
          break;
      }
    }
    return response;
  },
  (error) => {
    if (
      error.config.hasOwnProperty("errorHandle") &&
      error.config.errorHandle === false
    ) {
      return Promise.reject(error);
    }
    SwalRC.fire({
      type: "error",
      title: "Ops! Ocorreu um erro inesperado " + error?.response?.status + "!",
      html: "Uma equipe de fominhas altamente treinados já foi acionada para resolver o problema!",
      footer: (
        <>
          <button
            onClick={(e) => {
              document.location.reload(true);
            }}
            class="btn btn-success"
          >
            Tentar novamente
          </button>
        </>
      ),
      allowOutsideClick: false,
      showConfirmButton: false,
    });
    return error;
  }
);

export default Api;

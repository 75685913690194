export function getTodayDate() {
  const day = new Date().getDate();
  const month = new Date().getMonth() + 1;
  const year = new Date().getFullYear();

  const formattedDay = day < 9 ? `0${day}` : day;
  const formattedMonth = month < 9 ? `0${month}` : month;

  return `${year}-${formattedMonth}-${formattedDay}`;
}

function getTimestamp(date, hour) {
  if (!hour) {
    return new Date(date).getTime();
  }

  return new Date(`${date}, ${hour}`).getTime();
}

function isSameDay(initialDate, finalDate) {
  return initialDate === finalDate;
}

function hasBothDate(initialDate, finalDate) {
  return !!initialDate && !!finalDate;
}

function hasOneOfTheHours(initialHour, finalHour) {
  return !initialHour || finalHour;
}

export function formatDate(date) {
  if (!date) {
    return;
  }

  const splittedDate = date?.split("-");

  const [year, month, day] = splittedDate;

  return `${month}-${day}-${year}`;
}

export function validateDate({ initialDate, finalDate }) {
  if (!initialDate || !finalDate) {
    return true;
  }

  const formattedInitialDate = formatDate(initialDate);
  const formattedFinalDate = formatDate(finalDate);

  const initialTimestamp = getTimestamp(formattedInitialDate);
  const finalTimestamp = getTimestamp(formattedFinalDate);

  if (initialTimestamp > finalTimestamp) {
    return "Data inicial não pode ser maior que a data final";
  }
}

export function validateHour({
  initialDate,
  finalDate,
  initialHour,
  finalHour,
}) {
  if (!isSameDay(initialDate, finalDate)) {
    return true;
  }

  if (!hasOneOfTheHours(initialHour, finalHour)) {
    return true;
  }

  let initialTimestamp = null;
  let finalTimestamp = null;

  if (!hasBothDate(initialDate, finalDate)) {
    initialTimestamp = getTimestamp(getTodayDate(), initialHour);
    finalTimestamp = getTimestamp(getTodayDate(), finalHour);
  } else {
    initialTimestamp = getTimestamp(initialDate, initialHour);
    finalTimestamp = getTimestamp(initialDate, finalHour);
  }

  if (initialTimestamp > finalTimestamp) {
    return "Horário inicial não pode ser maior que o horário final";
  }
}
